import React, { useCallback, useMemo } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { FormikHelpers, FormikValues, useFormikContext } from 'formik';
import { Row, Col } from 'antd';
import {
  patternLabelStyle,
  TextInput,
} from '../../../../Components/Inputs/TextInput';
import { Button, Card, Dropdown, Form } from '../../../../Components';
import { history } from '../../../../Store';
import styles from './StorageLocation.module.scss';
import { CreateSchema } from './CreateForm.schema';
import { ICreateStorageLocation } from '../../../../Data/Interfaces/request/StorageLocation/IStorageLocationRequest';
import {
  FETCH_LIST_STOCK_LOCATION,
  useStockLocationCreate,
  useThereIsStockLocationNameType,
} from '../../../../Hooks/Supply/SupplyHooks';
import { useCompanyGetDropdown } from '../../../../Hooks/Accountmanager/CompanyHooks';
import { messageErrorToast } from '../../../../Utils/MessageErrorToast';
import _ from 'lodash';

export const StorageLocation: React.FC = () => {
  const { t } = useTranslation();

  const createStorageLocation = useStockLocationCreate();

  const queryClient = useQueryClient();

  const { data: listCompanysDropdown, isLoading: isCompanyLoading } =
    useCompanyGetDropdown({
      PageIndex: 0,
      PageSize: 10,
    });

  const onSubmit = async (
    values: ICreateStorageLocation,
    helpers: FormikHelpers<FormikValues>
  ) => {
    var body: ICreateStorageLocation = { ...values, helpers };
    const res = await createStorageLocation.mutateAsync({
      empresaId: body?.empresaId,
      descricao: body?.descricao,
      ativo: true,
      helpers,
    });
    if (res.success === true) {
      queryClient.invalidateQueries([FETCH_LIST_STOCK_LOCATION]);
      history.push('/inventory/supply-places');
    }
    if (res.error) messageErrorToast(res.error);
  };

  return (
    <Form
      initialValues={{
        empresaId:
          listCompanysDropdown?.data?.length === 1
            ? listCompanysDropdown.data[0].externalId
            : undefined,
      }}
      onSubmit={onSubmit}
      schema={CreateSchema}
      validateOnChange
    >
      {(props) => (
        <>
          <Card
            title={t('supply.supplyPlaces.create.detail')}
            withoutCollapse={true}
          >
            <Row gutter={[16, 0]} justify="space-between">
              <Col span={12}>
                <InputDescriptionSupllyPlace />
              </Col>
              <Col span={12}>
                <Dropdown
                  items={listCompanysDropdown?.data?.map((value) => ({
                    id: value.externalId,
                    name: value.nomeFantasia,
                  }))}
                  isLoading={isCompanyLoading}
                  disabled={listCompanysDropdown?.data?.length === 1}
                  name="empresaId"
                  label={[
                    {
                      children: t('supply.supplyPlaces.create.company'),
                      ...patternLabelStyle,
                    },
                  ]}
                  required={true}
                  placeHolder={t(
                    'supply.supplyPlaces.create.companyPlaceholder'
                  )}
                />
              </Col>
            </Row>
          </Card>
          <div className={styles['footer-button']}>
            <Button
              type="secondary"
              children={t('supply.supplyPlaces.create.footerButton.cancel')}
              onClick={() => history.push('/inventory/supply-places')}
            />
            <Button
              type="primary"
              children={t('supply.supplyPlaces.create.footerButton.okButton')}
              htmlType="submit"
              disabled={!props.isValid}
              loading={createStorageLocation.isLoading}
            />
          </div>
        </>
      )}
    </Form>
  );
};

export default StorageLocation;

const InputDescriptionSupllyPlace: React.FC = () => {
  const { t } = useTranslation();
  const form: any = useFormikContext();

  const onLocationValidationError = useCallback(() => {
    form?.setErrors({
      ...form?.errors,
      descricao: t('supply.supplyPlaces.errors.errorStorageLocationNameExist'),
    });
  }, [form, t]);

  const { mutateAsync } = useThereIsStockLocationNameType({
    onError: onLocationValidationError,
  });

  const fetchStorageLocationName = useMemo(
    () => _.debounce(async (value: string) => await mutateAsync(value), 500),
    [mutateAsync]
  );

  return (
    <TextInput
      className={styles['text-input']}
      name="descricao"
      label={[
        {
          children: t('supply.supplyPlaces.create.name'),
          ...patternLabelStyle,
        },
      ]}
      placeHolder={t('supply.supplyPlaces.create.namePlaceholder')}
      required
      onChange={fetchStorageLocationName}
    />
  );
};
