import { call, put } from 'redux-saga/effects';
import { i18n } from '../../Components';
import { UserGroupAPI } from '../../Data/API/Accountmanager/UserGroupApi';
import {
  messageErrorToast,
  messageSucessToast,
} from '../../Utils/MessageErrorToast';

import {
  UserGroupActions,
  DeleteUserGroupRequestAction,
  RemoveUserInGroupRequestAction,
  ThereIsUserGroupRequestAction,
  UpdateUserGroupPermission,
} from './Group.actions';
import { openNotification } from '../../Components/Feedback/Toast';

export function* deleteUserGroup({ payload }: DeleteUserGroupRequestAction) {
  try {
    yield call(UserGroupAPI.deleteUserGroup, payload);

    yield put(UserGroupActions.setLoading(false));
    messageSucessToast(i18n.t('common.toastMessages.deletedUserGroup'));

    payload.callback && payload.callback();
  } catch (error: any) {
    messageErrorToast(error);
    yield put(UserGroupActions.deleteUserGroupFailure(error.message));
  }
}

export function* removeUserGroup({ payload }: RemoveUserInGroupRequestAction) {
  try {
    yield call(UserGroupAPI.removeUserInGroup, payload);

    yield put(UserGroupActions.removeUserInGroupSuccess());
    messageSucessToast(i18n.t('common.toastMessages.deletedUserGroup'));
  } catch (error: any) {
    messageErrorToast(error);
    yield put(UserGroupActions.removeUserInGroupFailure(error.message));
  }
}

export function* isUserGroupNameAlreadyRegistred({
  payload,
}: ThereIsUserGroupRequestAction) {
  try {
    yield call(UserGroupAPI.isUserGroupNameAlreadyRegistred, payload);

    yield put(UserGroupActions.isUserGroupAlreadyRegistredSuccess(false));
  } catch (error: any) {
    yield put(UserGroupActions.isUserGroupAlreadyRegistredSuccess(true));
  }
}

export function* updatePermission({ payload }: UpdateUserGroupPermission) {
  const formHelpers = payload.helpers;
  try {
    yield call(UserGroupAPI.updateGroupPermission, payload);

    const dependenciasLength =
      payload.depedencias && payload.depedencias.length;

    const title = payload.ativo
      ? 'activatedWithSuccess'
      : 'inactivactedWithSuccess';

    const msg = i18n
      .t(
        `admin.editUserPage.permission.description${
          dependenciasLength && dependenciasLength > 0 ? 'Many' : ''
        }${payload.ativo ? 'Activated' : 'Inactivated'}WithSuccess`
      )
      .replace('{num}', dependenciasLength?.toString() || '')
      .replace('{x}', payload.permissionName || '');

    openNotification(
      'sucess',
      i18n.t(`admin.editUserPage.permission.${title}`),
      msg,
      false
    );

    yield put(
      UserGroupActions.updateGroupModuleStatus({
        permissionId: payload.permissaoId,
        value: payload.ativo,
      })
    );

    if (payload.depedencias) {
      for (let i = 0; i < payload.depedencias.length; i++) {
        const permissionId = payload.depedencias[i];
        yield put(
          UserGroupActions.updateGroupModuleStatus({
            permissionId,
            value: payload.ativo,
          })
        );
      }
    }
  } catch (error: any) {
    if (error.response.status === 400) {
      yield put(
        UserGroupActions.showPermissionDependencyModal({
          data: error?.response?.data?.data,
          request: payload,
        })
      );
    } else {
      messageErrorToast(error);

      // RESET FORM VALUE TO THE PREVIOUS VALUE
      formHelpers &&
        formHelpers.setFieldValue(payload.permissaoId, !payload.ativo);
    }
  } finally {
    yield put(UserGroupActions.setLoading(false));
  }
}
