import React, { FC } from 'react';
import { i18n, Text } from '../../../../../Components';
import { FooterThisActionIsPermanent } from '../../../../../Components/Table/components/Modals/DeleteModal';

interface IBodyDeleteModal {
  selectedRowsOnTable: any;
}

export const BodyDeleteModal: FC<IBodyDeleteModal> = ({
  selectedRowsOnTable,
}) => (
  <div style={{ padding: '20px' }}>
    <div style={{ display: 'flex' }}>
      <Text
        color="text-50"
        type="ui-tiny-content"
        children={`${i18n.t('modal.deleteModal.single.question')} ${i18n.t(
          'common.theProduct'
        )} `}
      />
      <Text
        color="text-50"
        type="ui-tiny-bold"
        children={`${selectedRowsOnTable[0]?.descricao}`}
        style={{ marginBottom: '15px' }}
      />
      <Text type="ui-tiny-content" children={'?'} />
    </div>
    <FooterThisActionIsPermanent />
  </div>
);
