import { call, put } from 'redux-saga/effects';

import { messageErrorToast } from '../../Utils/MessageErrorToast';

import {
  UnitMeasurementActions,
  ListUnitMeasurementRequestAction,
} from './UnitMeasurement.actions';
import { Http } from '../../Utils/Http';
import { IListUnitMeasurementData } from '../../Data/Interfaces/response/UnitMeasurement/IUnitMeasurementResponse';
import { UnitMeasurementAPI } from '../../Data/API/Supply/UnitMeasurementApi';

export function* listUnitMeasurement({
  payload,
}: ListUnitMeasurementRequestAction) {
  try {
    const { data } = yield call(
      UnitMeasurementAPI.listUnitMeasurement,
      payload
    );

    const transformedData = Http.TransformODataData<IListUnitMeasurementData>(
      data,
      payload
    );

    yield put(
      UnitMeasurementActions.getListUnitMeasurementSuccess(transformedData)
    );
  } catch (error: any) {
    messageErrorToast(error);

    yield put(
      UnitMeasurementActions.getListUnitMeasurementFailure(error.message)
    );
  }
}
