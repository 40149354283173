import { FC, useState } from 'react';
import { Dropdown, i18n } from '../../../../../Components';
import { IListCompanyData } from '../../../../../Data/Interfaces/response/Company/ICompanyResponse';
import { useGetUserListType } from '../../../../../Hooks/Accountmanager/UserHooks';
import { IQueryListParams } from '../../../../../Utils/Http';

export const modalTitle = (company?: IListCompanyData[]) => {
  return (
    company &&
    `${i18n.t('common.changeResponsible')} ${
      company.length > 1
        ? `${company.length} ${i18n.t('common.companys')}`
        : `"${company[0]?.nomeFantasia}"`
    }`
  );
};

interface IModalBody {
  companyExternalId?: string[];
}

export const ModalBody: FC<IModalBody> = ({ companyExternalId }) => {
  const [pagination, setPagination] = useState<IQueryListParams>({
    PageIndex: 0,
    PageSize: 10,
    Sorter: { column: 'nome', direction: 'ASC' },
  });

  const { data: users } = useGetUserListType(pagination);

  return (
    <div style={{ width: '400px', padding: '20px' }}>
      <Dropdown
        name="usuarioResponsavelExternalId"
        label={i18n.t('common.responsible')}
        items={users?.data.map((x) => ({
          id: x.externalId,
          avatar: {
            name: x.nome,
            lastName: x.sobrenome,
            email: x.email,
          },
        }))}
        autoIncrementBy="id"
        onScrollEnd={() =>
          setPagination({
            ...pagination,
            PageIndex: pagination.PageIndex + 1,
          })
        }
        withoutMarginBottom
      />
    </div>
  );
};
