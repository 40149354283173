import * as yup from 'yup';
import i18n from '../../../../../../Components/i18n';
import { datePatternType } from '../../../../../../Data/Interfaces/response/User/IGetUserReponse';

export const DetailSchema = (datePattern?: datePatternType) =>
  yup.object().shape({
    nome: yup
      .string()
      .required(
        i18n.t('admin.editUserPage.personalDataForm.errors.requiredName')
      ),
    sobrenome: yup
      .string()
      .required(
        i18n.t('admin.editUserPage.personalDataForm.errors.requiredLastName')
      ),

    email: yup
      .string()
      .email(i18n.t('admin.editUserPage.personalDataForm.errors.invalidEmail'))
      .required(
        i18n.t('admin.editUserPage.personalDataForm.errors.requiredEmail')
      ),
    emailAlternativo: yup
      .string()
      .email(i18n.t('admin.editUserPage.personalDataForm.errors.invalidEmail'))
      .notOneOf(
        [yup.ref('email')],
        i18n.t(
          'admin.editUserPage.personalDataForm.errors.invalidAlternativeEmail'
        )
      )
      .nullable(),

    dataNascimento: yup
      .string()
      .trim()
      .matches(
        datePattern === 'mm/dd/yyyy'
          ? /^(0[1-9]|1[0-2])\/(0[1-9]|[12]\d|3[01])\/\d{4}$/
          : /^(0[1-9]|[12]\d|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/,
        i18n.t('admin.editUserPage.personalDataForm.errors.inalidBirthdate')
      ),

    grupos: yup
      .string()
      .required(
        i18n.t('admin.editUserPage.personalDataForm.errors.requiredGroup')
      ),
  });
