import { useMutation, useQuery } from '@tanstack/react-query';
import { IPagination } from '../../Components/Table';
import { LotsAPI } from '../../Data/API/Supply/LotApi';
import { IQueryListParams } from '../../Utils/Http';

export const CREATE_LOT = '[LOT] CREATE_LOT';

export const EDIT_LOT = '[LOT] EDIT_LOT';

export const FETCH_GET_LOT = '[LOT] FETCH_GET_LOT';

export const FETCH_LIST_LOTS = '[LOT] FETCH_LIST_LOTS';

export const FETCH_LIST_STOCKLOCATIONS_OF_LOTS =
  '[LOT] FETCH_LIST_STOCKLOCATIONS_OF_LOTS';

export const FETCH_LIST_STATUS_LOTS = '[LOT] FETCH_LIST_STATUS_LOTS';

export const FETCH_LIST_LOTS_DROPDOWN = '[LOT] FETCH_LIST_LOTS_DROPDOWN';

export const DELETE_LOT = '[LOT] DELETE_LOT';

const EDIT_STATUS_LOT = '[LOT] EDIT_STATUS_LOT';

export const FETCH_LIST_LOT_BY_PRODUCT_STOCKLOCATION_DROPDOWN_TYPE =
  '[SUPLLY_LOT] FETCH_LIST_LOT_BY_PRODUCT_STOCKLOCATION_DROPDOWN_TYPE';

export const GET_LOT_DETAIL_BY_STOCKLOCATION =
  '[SUPLLY_LOT] GET_LOT_DETAIL_BY_STOCKLOCATION';

export const FETCH_GET_BALANCE_ORIGIN_DESTINY =
  '[STOCK_BALANCE] FETCH_GET_BALANCE_ORIGIN_DESTINY';

export const useLotCreate = () => useMutation([CREATE_LOT], LotsAPI.createLot);

export const useLotEdit = () => useMutation([EDIT_LOT], LotsAPI.editLot);

export const useLotGet = (externalId: string) =>
  useQuery([FETCH_GET_LOT, externalId], LotsAPI.getLot);

export const useLotGetList = (pagination: IPagination) =>
  useQuery([FETCH_LIST_LOTS, pagination], LotsAPI.listLots, {
    staleTime: 60000,
  });

export const useLotGetStatusList = () =>
  useQuery([FETCH_LIST_STATUS_LOTS], LotsAPI.statusLotList, {
    staleTime: 60000,
  });

export const useLotGetDropdown = (pagination: IQueryListParams) =>
  useQuery([FETCH_LIST_LOTS_DROPDOWN, pagination], LotsAPI.getLotListDropDown);

export const useLotDelete = () => useMutation([DELETE_LOT], LotsAPI.deleteLot);

export const useLotStatusEdit = () =>
  useMutation([EDIT_STATUS_LOT], LotsAPI.patchLotStatus);

export const useLotGetDropdownByProductStockLocation = (
  pagination: IQueryListParams
) =>
  useQuery(
    [FETCH_LIST_LOT_BY_PRODUCT_STOCKLOCATION_DROPDOWN_TYPE, pagination],
    LotsAPI.getLotByProductStockLocation
  );

export const useGetLotDetailByStockLocation = (
  loteExternalId?: string,
  localEstoqueExternalId?: string
) =>
  useQuery(
    [GET_LOT_DETAIL_BY_STOCKLOCATION, loteExternalId, localEstoqueExternalId],
    LotsAPI.getLotDetailstByStockLocation
  );

export const useGetLotBalanceOriginDestiny = (
  loteExternalId?: string,
  localEstoqueOrigemExternalId?: string,
  localEstoqueDestinoExternalId?: string
) =>
  useQuery(
    [
      FETCH_GET_BALANCE_ORIGIN_DESTINY,
      loteExternalId,
      localEstoqueOrigemExternalId,
      localEstoqueDestinoExternalId,
    ],
    LotsAPI.getSaldosOriginAndDestiny
  );

export const useStockLocationsOfLotsList = (pagination: IPagination) =>
  useQuery(
    [FETCH_LIST_STOCKLOCATIONS_OF_LOTS, pagination],
    LotsAPI.stockLocationsOfLotsList
  );
