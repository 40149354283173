import { createAction, Action, ActionsUnion } from '../Base/actionHelpers';
import { IPagination } from '../../Components/Table';
import { IListUnitMeasurementResponse } from '../../Data/Interfaces/response/UnitMeasurement/IUnitMeasurementResponse';

export enum UnitMeasurementActionKeys {
  LIST_UNIT_MEASUREMENT_REQUEST = '[UNIT_MEASUREMENT] LIST_UNIT_MEASUREMENT_REQUEST',
  LIST_UNIT_MEASUREMENT_SUCCESS = '[UNIT_MEASUREMENT] LIST_UNIT_MEASUREMENT_SUCCESS',
  LIST_UNIT_MEASUREMENT_FAILURE = '[UNIT_MEASUREMENT] LIST_UNIT_MEASUREMENT_FAILURE',
}

export const UnitMeasurementActions = {
  getListUnitMeasurement: (
    body: IPagination
  ): ListUnitMeasurementRequestAction =>
    createAction(UnitMeasurementActionKeys.LIST_UNIT_MEASUREMENT_REQUEST, body),
  getListUnitMeasurementSuccess: (
    res: IListUnitMeasurementResponse
  ): ListUnitMeasurementSuccessAction =>
    createAction(UnitMeasurementActionKeys.LIST_UNIT_MEASUREMENT_SUCCESS, res),
  getListUnitMeasurementFailure: (
    error: string
  ): ListUnitMeasurementFailureAction =>
    createAction(
      UnitMeasurementActionKeys.LIST_UNIT_MEASUREMENT_FAILURE,
      error
    ),
};

export type UnitMeasurementActionUnion = ActionsUnion<
  typeof UnitMeasurementActions
>;

export type ListUnitMeasurementRequestAction = Action<
  UnitMeasurementActionKeys.LIST_UNIT_MEASUREMENT_REQUEST,
  IPagination
>;
export type ListUnitMeasurementSuccessAction = Action<
  UnitMeasurementActionKeys.LIST_UNIT_MEASUREMENT_SUCCESS,
  IListUnitMeasurementResponse
>;
export type ListUnitMeasurementFailureAction = Action<
  UnitMeasurementActionKeys.LIST_UNIT_MEASUREMENT_FAILURE,
  string
>;
