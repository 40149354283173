import { FC } from 'react';
import { Card, Dropdown, Form } from '../../../../Components';
import { AdderList } from '../../../../Components/AdderList';
import { TextArea } from '../../../../Components/Inputs/TextArea';
import { Col, Row } from 'antd';
import { useContactTypes } from '../../../../Hooks/People/PersonHooks';
import { TextInputPhoneMask } from '../../../../Components/Inputs/TextInputPhoneMask';
import { useTranslation } from 'react-i18next';

export const CreatePrescribers: FC = () => {
  const { data: contactTypes } = useContactTypes();

  const { t } = useTranslation();

  return (
    <Form onSubmit={(x) => console.log(x)}>
      {(props) => (
        <>
          <Card title={t('contact.contacts')} withoutCollapse={true}>
            <AdderList
              favoriteFormName="principal"
              onChange={(values) => props.setFieldValue('contatos', values)}
              collum={[
                {
                  label: t('contact.contactType'),
                  width: 6,
                  key: 'tipoContatoId',
                },
                { label: t('contact.contact'), width: 8, key: 'identificacao' },
                {
                  label: t('contact.observation'),
                  width: 8,
                  key: 'observacao',
                },
              ]}
              modal={{
                editTitle: t('contact.contactEditTitle'),
                addTitle: t('contact.contactAddTitle'),
                modalFormName: ['tipoContatoId', 'identificacao', 'observacao'],
                body: (
                  <div style={{ width: '560px', padding: '20px' }}>
                    <Row gutter={[16, 0]} justify="start">
                      <Col span={8}>
                        <Dropdown
                          name="tipoContatoId"
                          items={contactTypes?.data.map((x) => ({
                            id: x.id,
                            name: x.nome,
                          }))}
                          nameValue
                        />
                      </Col>
                      <Col span={12}>
                        <TextInputPhoneMask name="identificacao" />
                      </Col>
                    </Row>
                    <Row gutter={[16, 0]} justify="start">
                      <Col span={24}>
                        <TextArea
                          name="observacao"
                          label="Observações"
                          maxLength={125}
                          heightTextArea={100}
                        />
                      </Col>
                    </Row>
                  </div>
                ),
                okButtonLabel: t('common.add'),
              }}
              buttonLabel={t('contact.contactAddTitle')}
              empytDataDescription={t('contact.empytDataDescription')}
            />
          </Card>
        </>
      )}
    </Form>
  );
};
