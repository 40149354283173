import React, { useEffect, useState } from 'react';
import { Table } from '../../../../Components';
import { IPagination } from '../../../../Components/Table';
import { history } from '../../../../Store';
import { useTranslation } from 'react-i18next';
import { conglomerateCollums } from './conglomerate.columns';
import { Modal } from '../../../../Components/Modal';
import './Styles/List.override.scss';
import { useConglomerateAdminListType } from '../../../../Hooks/Accountmanager/ConglomerateHooks';
import { DeleteModalBody, getDeleteModalTitle } from './Modals/DeleteModal';
import { useUserPreferencesType } from '../../../../Hooks/Accountmanager/UserHooks';

export const List: React.FC = () => {
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [hasNoCompany, setHasNoCompany] = useState(false);
  const [selectedRowsOnTable, setSelectedRowsOnTable] = useState<any>([]);
  const [pagination, setPagination] = useState<IPagination>({
    PageIndex: 0,
    PageSize: 10,
    Sorter: { column: 'nome', direction: 'ASC' },
  });

  const { t } = useTranslation();

  const { data, isLoading } = useConglomerateAdminListType(pagination);
  const { data: userPreference } = useUserPreferencesType();

  useEffect(
    () =>
      setHasNoCompany(
        selectedRowsOnTable?.some((row: any) => row.empresas === 0)
      ),
    [selectedRowsOnTable]
  );

  return (
    <>
      <Table
        columns={conglomerateCollums(
          t,
          userPreference?.preferencias.padraoData || 'dd/MM/yyyy'
        )}
        data={
          data?.data?.map((x) => ({
            ...x,
            key: `table_conglomerate_${x.externalId}`,
          })) || []
        }
        headerOptions={{
          hasSearch: true,
          searchPlaceholder: t(
            'adminBootys.conglomerateList.searchPlaceholder'
          ),
          nameKey: 'Nome',
        }}
        loading={isLoading}
        hasSelection={true}
        onRefetch={(pagination) => setPagination(pagination)}
        pagination={
          data
            ? {
                Count: data.count,
                PageIndex: data.pageIndex,
                PageSize: data.pageSize,
              }
            : false
        }
        rowsOptions={{
          onRowDoubleClick: (x) =>
            history.push(`/admin-bootys/conglomerate/${x.externalId}/details`),
        }}
        selectedRowsOnTable={(x) => setSelectedRowsOnTable(x)}
      />
      <Modal
        visible={isDeleteModalOpen}
        onCancelClick={() => setDeleteModalOpen(false)}
        onClose={() => setDeleteModalOpen(false)}
        title={getDeleteModalTitle(t, selectedRowsOnTable, hasNoCompany)}
        okButtonColor={hasNoCompany ? 'status-danger-base' : undefined}
        okButtonName={
          hasNoCompany
            ? t('common.delete')
            : t('adminBootys.companyList.deleteModal.delete')
        }
        body={
          <DeleteModalBody
            rows={selectedRowsOnTable}
            hasCompany={!hasNoCompany}
          />
        }
        withoutOkButton={hasNoCompany}
      />
    </>
  );
};
