import { takeLatest, all, takeEvery } from 'redux-saga/effects';

import { AuthActionKeys } from './Auth/Auth.actions';
import { login, recovery, newpassword } from './Auth/Auth.sagas';
import { CompanyActionKeys } from './Company/Company.actions';
import {
  changeResponsibilytiUser,
  changeCompanyPaying,
} from './Company/Company.sagas';
import { NotificationActionKeys } from './Notification/Notification.actions';
import { getNotifications } from './Notification/Notification.sagas';

import { UserActionKeys } from './User/User.actions';
import {
  changePassword,
  getMe,
  patchMe,
  getUser,
  updatePermission,
} from './User/User.sagas';
import {
  deleteUserGroup,
  removeUserGroup,
  isUserGroupNameAlreadyRegistred,
  updatePermission as updateUserGroupPermission,
} from './Group/Group.sagas';
import { UserGroupActionKeys } from './Group/Group.actions';
import { ConglomerateActionKeys } from './Conglomerate/Conglomerate.actions';
import {
  changeConglomerateStatus,
  getCompanyForConglomerateIncrementing,
  deleteConglomerate,
  getCompanyForConglomerate,
  putConglomerate,
} from './Conglomerate/Conglomerate.sagas';
import { listUnitMeasurement } from './UnitMeasurement/UnitMeasurement.sagas';
import { UnitMeasurementActionKeys } from './UnitMeasurement/UnitMeasurement.actions';
import { CurrencyActionKeys } from './Currency/Currency.actions';
import { getCurrentCurrency } from './Currency/Currency.sagas';
import { StorageLocationActionKeys } from './StorageLocation/StorageLocation.action';
import {
  createStorageLocation,
  patchStorageLocationStatus,
  editStorageLocation,
  getStorageLocationListDropDown,
  getStorageLocationListAccountmanager,
} from './StorageLocation/StorageLocation.sagas';
import { LossesActionKeys } from './Losses/Losses.actions';
import { updateProductLoss, listProductLosses } from './Losses/Losses.sagas';
import { BalanceAdjustmentsActionKeys } from './BalanceAdjustments/BalanceAdjustments.actions';
import {
  createBalanceAdjustment,
  getBalanceAdjustments,
} from './BalanceAdjustments/BalanceAdjustments.sagas';
import { StockBalanceActionKeys } from './StockBalance/StockBalance.actions';
import {
  listManyStockBalance,
  listStockBalance,
} from './StockBalance/StockBalance.sagas';

export default function* rootSaga(): any {
  return yield all([
    takeLatest(AuthActionKeys.LOGIN_REQUEST, login),
    takeLatest(AuthActionKeys.RECOVERY_REQUEST, recovery),
    takeLatest(AuthActionKeys.NEWPASSWORD_REQUEST, newpassword),

    takeLatest(CurrencyActionKeys.GET_CURRENCY_REQUEST, getCurrentCurrency),

    takeLatest(UserActionKeys.GET_ME_REQUEST, getMe),
    takeLatest(UserActionKeys.GET_USER_REQUEST, getUser),
    takeLatest(UserActionKeys.PATCH_ME_REQUEST, patchMe),
    takeLatest(UserActionKeys.CHANGE_PASSWORD_REQUEST, changePassword),

    takeLatest(
      CompanyActionKeys.CHANGE_COMPANY_PAYING_REQUEST,
      changeCompanyPaying
    ),
    takeLatest(
      CompanyActionKeys.CHANGE_RESPONSIBILYTI_USER_REQUEST,
      changeResponsibilytiUser
    ),

    takeLatest(
      NotificationActionKeys.GET_NOTIFICATIONS_REQUEST,
      getNotifications
    ),

    takeLatest(UserGroupActionKeys.DELETE_USER_GROUP_REQUEST, deleteUserGroup),

    takeLatest(UserGroupActionKeys.REMOVE_USER_GROUP_REQUEST, removeUserGroup),
    takeLatest(
      UserGroupActionKeys.THERE_IS_USER_GROUP_NAME_REQUEST,
      isUserGroupNameAlreadyRegistred
    ),
    takeEvery(
      UserGroupActionKeys.UPDATE_USER_GROUP_PERMISSION,
      updateUserGroupPermission
    ),
    takeLatest(
      ConglomerateActionKeys.GET_COMPANY_FOR_CONGLOMERATE_REQUEST,
      getCompanyForConglomerate
    ),
    takeLatest(
      ConglomerateActionKeys.GET_COMPANY_FOR_CONGLOMERATE_REQUEST_INCREMENTING,
      getCompanyForConglomerateIncrementing
    ),
    takeLatest(
      ConglomerateActionKeys.PUT_CONGLOMERATE_REQUEST,
      putConglomerate
    ),
    takeLatest(
      ConglomerateActionKeys.DELETE_CONGLOMERATE_REQUEST,
      deleteConglomerate
    ),
    takeLatest(
      ConglomerateActionKeys.PATCH_STATUS_REQUEST,
      changeConglomerateStatus
    ),

    takeEvery(UserActionKeys.PUT_UPDATE_PERMISSION_REQUEST, updatePermission),

    takeLatest(
      StorageLocationActionKeys.CREATE_STORAGE_LOCATION_REQUEST,
      createStorageLocation
    ),
    takeLatest(
      StorageLocationActionKeys.STORAGE_LOCATION_LIST_ACCOUNTMANAGER_REQUEST,
      getStorageLocationListAccountmanager
    ),
    takeLatest(
      StorageLocationActionKeys.STORAGE_LOCATION_DROPDOWN_LIST_REQUEST,
      getStorageLocationListDropDown
    ),
    takeLatest(
      StorageLocationActionKeys.EDIT_STORAGE_LOCATION_REQUEST,
      editStorageLocation
    ),
    takeLatest(
      StorageLocationActionKeys.PATCH_STATUS_REQUEST,
      patchStorageLocationStatus
    ),

    takeLatest(LossesActionKeys.LIST_PRODUCT_LOSS_REQUEST, listProductLosses),

    takeLatest(
      BalanceAdjustmentsActionKeys.GET_SINGLE_BALANCE_ADJUSTMENT,
      getBalanceAdjustments
    ),

    takeLatest(
      UnitMeasurementActionKeys.LIST_UNIT_MEASUREMENT_REQUEST,
      listUnitMeasurement
    ),

    takeLatest(
      StockBalanceActionKeys.LIST_STOCK_BALANCE_REQUEST,
      listStockBalance
    ),

    takeLatest(
      StockBalanceActionKeys.LIST_STOCK_MANY_BALANCE_REQUEST,
      listManyStockBalance
    ),

    takeLatest(
      BalanceAdjustmentsActionKeys.CREATE_BALANCE_ADJUSTMENT,
      createBalanceAdjustment
    ),
    takeLatest(LossesActionKeys.UPDATE_LOSS_REQUEST, updateProductLoss),
  ]);
}
