import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Status } from '../../../Status';
import { CompanyActions } from '../../../../../../../Store/Company/Company.actions';
import { getCompany } from '../../../../../../../Store/Company/Company.selector';
import { MaskForCNPJ } from '../../../../../../../Utils/MasksUtils';
import { Skeleton } from '../../../../../..';
import {
  GET_COMPANY_ADMIN_TYPE,
  useCompanyAdminTypes,
  useCompanyChangeStatusMutation,
  useCompanyTypes,
} from '../../../../../../../Hooks/Accountmanager/CompanyHooks';
import { useQueryClient } from '@tanstack/react-query';
import {
  GET_CONGLOMERATE_TYPE,
  useConglomerateChangeStatusType,
  useConglomerateGetType,
} from '../../../../../../../Hooks/Accountmanager/ConglomerateHooks';
import {
  ConfirmationChangeStatusCompanyModal,
  InativeConglomerateModal,
} from './modals';
import StorageUtils from '../../../../../../../Utils/StorageUtils';
import { IPreferences } from '../../../../../../../Data/Interfaces/response/User/IGetUserReponse';

export const DynamicEditCompanyStatusBreadcrumb = ({ match }: any) => {
  const [visibleModal, setVisibleModal] = useState({
    visible: false,
    status: undefined,
  });
  const { data } = useCompanyAdminTypes(match.params.externalId);
  const changeStatus = useCompanyChangeStatusMutation();

  const queryClient = useQueryClient();

  if (!data)
    return (
      <div style={{ width: 200 }}>
        <Skeleton />
      </div>
    );

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {data?.nomeFantasia}
      <Status
        type={data?.ativa ? 'success' : 'disabled'}
        ative={data?.ativa || false}
        onClick={async (value) => {
          if (!value) {
            setVisibleModal({ status: value, visible: true });
          } else {
            const res = await changeStatus.mutateAsync({
              empresasExternalId: [data.externalId],
              ativa: value,
            });

            if (res.success)
              queryClient.invalidateQueries([GET_COMPANY_ADMIN_TYPE]);
          }
        }}
        loading={changeStatus.isLoading}
        disabled={changeStatus.isLoading}
      />
      <ConfirmationChangeStatusCompanyModal
        company={data}
        visibleModal={visibleModal.visible}
        changeVisibleModal={() =>
          setVisibleModal({ visible: false, status: undefined })
        }
        status={visibleModal.status}
      />
    </div>
  );
};

export const DynamicEditCompanyBreadcrumbCNPJ = ({ match }: any) => {
  const { data } = useCompanyAdminTypes(match.params.externalId);
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {MaskForCNPJ(data?.cnpj)}
    </div>
  );
};

export const DynamicEditCompanyBreadcrumb = ({ match }: any) => {
  const { data } = useCompanyTypes(match.params.externalId);
  return <>{data?.nomeFantasia}</>;
};

export const DynamicEditCompanyBreadcrumbFantasyName = () => {
  const company = useSelector(getCompany);
  return <span>{MaskForCNPJ(company?.nomeFantasia)}</span>;
};

export const DynamicUserBreadcrumbStatusConglomerate = ({ match }: any) => {
  const [visibleConfirmationStatusModal, setVisibleConfirmationStatusModal] =
    useState(false);

  const userPreferences: IPreferences = StorageUtils.getPreference();

  const { data: conglomerate } = useConglomerateGetType(
    match.params.externalId
  );

  const changeStatus = useConglomerateChangeStatusType();

  const queryClient = useQueryClient();

  if (!conglomerate)
    return (
      <div style={{ width: 200 }}>
        <Skeleton />
      </div>
    );

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {conglomerate?.nome}
      {conglomerate && (
        <Status
          type={conglomerate?.ativo ? 'success' : 'disabled'}
          ative={conglomerate?.ativo || false}
          onClick={async (value) => {
            if (!value) setVisibleConfirmationStatusModal(true);
            else {
              const res = await changeStatus.mutateAsync({
                conglomeradosExternalId: [match.params.externalId],
                ativa: value,
              });

              if (res.success)
                queryClient.invalidateQueries([GET_CONGLOMERATE_TYPE]);
            }
          }}
          loading={changeStatus.isLoading}
        />
      )}
      <InativeConglomerateModal
        changeStatus={changeStatus}
        conglomerate={conglomerate}
        onClose={() => setVisibleConfirmationStatusModal(false)}
        preferenceTheme={userPreferences.temaUsuario}
        queryClient={queryClient}
        visibleConfirmationStatusModal={visibleConfirmationStatusModal}
      />
    </div>
  );
};
export const DynamicUserBreadcrumbConglomerate = ({ match }: any) => {
  const { data: conglomerate } = useConglomerateGetType(
    match.params.externalId
  );

  if (!conglomerate)
    return (
      <div style={{ width: 200 }}>
        <Skeleton />
      </div>
    );
  return <span>{conglomerate?.nome}</span>;
};

export const DynamicEditCompanyByManagerBreadcrumb = ({ match }: any) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(CompanyActions.getCompany(match.params.externalId));
  }, [match.params.externalId, dispatch]);

  const company = useSelector(getCompany);

  if (!company) {
    return (
      <div style={{ width: 200 }}>
        <Skeleton />
      </div>
    );
  }

  return (
    <span style={{ display: 'flex', alignItems: 'center' }}>
      {company?.nomeFantasia}
    </span>
  );
};
