import { ClassActionKeys, ClassActionUnion } from './ClassType.actions';
import { IClassTypeState } from './interfaces/IClassTypeState';

const initialData: IClassTypeState = {
  isLoading: false,
};

export const classReducer = (
  state = initialData,
  action: ClassActionUnion
): IClassTypeState => {
  switch (action.type) {
    case ClassActionKeys.LIST_CLASS_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: undefined,
      };
    case ClassActionKeys.LIST_CLASS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: undefined,
        currentListClass: action.payload,
      };
    case ClassActionKeys.LIST_CLASS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default classReducer;
