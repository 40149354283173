import React, { FC } from 'react';
import { Check } from '../Check';
import WhiteMode from '../../../../../Static/img/themes/light-theme.svg'
import DarkMode from '../../../../../Static/img/themes/dark-theme.svg'
import Midnight from '../../../../../Static/img/themes/midnight-theme.svg'

import styles from './ThemeMode.module.scss';
import { themes } from '../../../../../Utils/ThemeUtils';

export interface IThemeMode {
	theme: themes;
	check: boolean;
	onClick: () => void;
}

const ThemeMode: FC<IThemeMode> = ({ theme, check, onClick }) => {

	return (
		<div className={`${styles['divMode']} ${theme && styles['div-' + theme]}`}>
			
			<span className={`${styles[theme + '-mode']} ${styles['theme-mode']} ${check && styles['border-check']}`} onClick={onClick} >
				<div className={styles['check']} >
					{
						check && <Check />
					}
				</div>
				<img src={theme === 'darkMode' ? DarkMode : theme === 'lightMode' ? WhiteMode : Midnight} alt='BRL' />
			</span>
		</div>
	)
}

export default ThemeMode;