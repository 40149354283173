import { createAction, Action, ActionsUnion } from '../Base/actionHelpers';
import { IQueryParams } from '../../Components/Table';
import {
  IListUserGroupData,
  IListUserGroupResponse,
  IUserGroup,
} from '../../Data/Interfaces/response/User/IGetUserReponse';
import {
  ICreateUserGroupRequest,
  IDeleteUserGroup,
  IPatchUserGroupNameAndDescription,
  IRemoveUser,
} from '../../Data/Interfaces/request/UserGroup/IUserGroupRequest';
import { IUserBasic } from '../../Data/Interfaces/request/User/IUserRequest';
import { ITransformODataResponse } from '../../Utils/Http';
import { IAddUserInGroup } from '../../Data/Interfaces/request/UserGroup/IAddUserInGroup';
import { IUpdateUserGroupPermission } from '../../Data/Interfaces/request/UserGroup/IUpdateUserGroupPermission';
import { IUpdatePermissionResponse } from '../../Data/Interfaces/response/User/IUpdatePermissionResponse';

export enum UserGroupActionKeys {
  SET_LOADING = '[USER-GROUP] SET_LOADING',

  FORM_DISABLED = '[USER-GROUP] FORM_DISABLED',

  LIST_USER_GROUP_REQUEST = '[GROUP] LIST_USER_GROUP_REQUEST',
  LIST_USER_GROUP_SUCCESS = '[GROUP] LIST_USER_GROUP_SUCCESS',
  LIST_USER_GROUP_FAILURE = '[GROUP] LIST_USER_GROUP_FAILURE',

  APPEND_LIST_USER_GROUP_REQUEST_INCREMENTING = '[GROUP] APPEND_LIST_USER_GROUP_REQUEST_INCREMENTING',
  LIST_USER_GROUP_REQUEST_INCREMENTING = '[GROUP] LIST_USER_GROUP_INCREMENTING_REQUEST',
  LIST_USER_GROUP_SUCCESS_INCREMENTING = '[GROUP] LIST_USER_GROUP_INCREMENTING_SUCCESS',
  LIST_USER_GROUP_FAILURE_INCREMENTING = '[GROUP] LIST_USER_GROUP_INCREMENTING_FAILURE',

  GET_GROUP_REQUEST = '[GROUP] GET_GROUP_REQUEST',
  GET_GROUP_SUCCESS = '[GROUP] GET_GROUP_SUCCESS',
  GET_GROUP_FAILURE = '[GROUP] GET_GROUP_FAILURE',

  RESET_USERS_FROM_GROUP_REQUEST = '[GROUP] RESET_USERS_FROM_GROUP_REQUEST',
  GET_ALL_USERS_FROM_GROUP_REQUEST = '[GROUP] GET_ALL_USERS_FROM_GROUP_REQUEST',
  GET_ALL_USERS_FROM_GROUP_SUCCESS = '[GROUP] GET_ALL_USERS_FROM_GROUP_SUCCESS',
  GET_ALL_USERS_FROM_GROUP_FAILURE = '[GROUP] GET_ALL_USERS_FROM_GROUP_FAILURE',

  PATCH_USER_GROUP_NAME_DESCRIPTION = '[GROUP] PATCH_USER_GROUP_NAME_DESCRIPTION',

  GROUP_STATUS_CHANGE_REQUEST = '[GROUP] GROUP_STATUS_CHANGE_REQUEST',

  DELETE_USER_GROUP_REQUEST = '[GROUP] DELETE_USER_GROUP_REQUEST',
  DELETE_USER_GROUP_SUCCESS = '[GROUP] DELETE_USER_GROUP_SUCCESS',
  DELETE_USER_GROUP_FAILURE = '[GROUP] DELETE_USER_GROUP_FAILURE',

  POST_USER_GROUP_REQUEST = '[GROUP] POST_USER_GROUP_REQUEST',
  POST_USER_GROUP_FAILURE = '[GROUP] POST_USER_GROUP_FAILURE',

  ADD_USER_GROUP_REQUEST = '[GROUP] ADD_USER_GROUP_REQUEST',
  ADD_USER_GROUP_SUCCESS = '[GROUP] ADD_USER_GROUP_SUCCESS',
  ADD_USER_GROUP_FAILURE = '[GROUP] ADD_USER_GROUP_FAILURE',

  REMOVE_USER_GROUP_REQUEST = '[GROUP] REMOVE_USER_GROUP_REQUEST',
  REMOVE_USER_GROUP_SUCCESS = '[GROUP] REMOVE_USER_GROUP_SUCCESS',
  REMOVE_USER_GROUP_FAILURE = '[GROUP] REMOVE_USER_GROUP_FAILURE',

  THERE_IS_USER_GROUP_NAME_REQUEST = '[EMAIL] THERE_IS_USER_GROUP_NAME_REQUEST',
  THERE_IS_USER_GROUP_NAME_SUCCESS = '[EMAIL] THERE_IS_USER_GROUP_NAME_SUCCESS',

  UPDATE_USER_GROUP_PERMISSION = '[GROUP] UPDATE_USER_GROUP_PERMISSION',

  SHOW_PERMISSION_DEPENDENCY_MODAL = '[GROUP] SHOW_PERMISSION_DEPENDENCY_MODAL',
  HIDE_PERMISSION_DEPENDENCY_MODAL = '[GROUP] HIDE_PERMISSION_DEPENDENCY_MODAL',

  UPDATE_USER_GROUP_MODULE_STATUS = '[GROUP] UPDATE_USER_GROUP_MODULE_STATUS',
}

export const UserGroupActions = {
  setLoading: (loading: boolean): SetLoadingAction =>
    createAction(UserGroupActionKeys.SET_LOADING, loading),

  getGroup: (externalId: string): GetGroupRequestAction =>
    createAction(UserGroupActionKeys.GET_GROUP_REQUEST, externalId),
  getGroupSuccess: (body: IUserGroup): GetGroupSuccessAction =>
    createAction(UserGroupActionKeys.GET_GROUP_SUCCESS, body),
  getGroupFailure: (err: string): GetGroupFailureAction =>
    createAction(UserGroupActionKeys.GET_GROUP_FAILURE, err),

  resetAllUsersFromGroup: () =>
    createAction(UserGroupActionKeys.RESET_USERS_FROM_GROUP_REQUEST),
  getAllUsersFromGroup: (query: IQueryParams): GetAllUsersFromGroupAction =>
    createAction(UserGroupActionKeys.GET_ALL_USERS_FROM_GROUP_REQUEST, query),
  getAllUsersFromGroupSuccess: (
    body: ITransformODataResponse<IUserBasic>
  ): GetAllUsersFromGroupSuccessAction =>
    createAction(UserGroupActionKeys.GET_ALL_USERS_FROM_GROUP_SUCCESS, body),
  getAllUsersFromGroupFailure: (
    err: string
  ): GetAllUsersFromGroupFailureAction =>
    createAction(UserGroupActionKeys.GET_ALL_USERS_FROM_GROUP_FAILURE, err),

  listUserGroup: (body: IQueryParams): ListUserGroupRequestAction =>
    createAction(UserGroupActionKeys.LIST_USER_GROUP_REQUEST, body),
  listUserGroupSuccess: (
    res: IListUserGroupResponse
  ): ListUserGroupSuccessAction =>
    createAction(UserGroupActionKeys.LIST_USER_GROUP_SUCCESS, res),
  listUserGroupFailure: (error: string): ListUserGroupFailureAction =>
    createAction(UserGroupActionKeys.LIST_USER_GROUP_FAILURE, error),

  appendUserGroupToIncrementingList: (
    body: IListUserGroupData
  ): AppendUserGroupToIncrementingList =>
    createAction(
      UserGroupActionKeys.APPEND_LIST_USER_GROUP_REQUEST_INCREMENTING,
      body
    ),
  listUserGroupIncrementing: (
    body: IQueryParams
  ): ListUserGroupIncrementingRequestAction =>
    createAction(
      UserGroupActionKeys.LIST_USER_GROUP_REQUEST_INCREMENTING,
      body
    ),
  listUserGroupIncrementingSuccess: (
    res: IListUserGroupResponse
  ): ListUserGroupIncrementingSuccessAction =>
    createAction(UserGroupActionKeys.LIST_USER_GROUP_SUCCESS_INCREMENTING, res),
  listUserGroupIncrementingFailure: (
    error: string
  ): ListUserGroupIncrementingFailureAction =>
    createAction(
      UserGroupActionKeys.LIST_USER_GROUP_FAILURE_INCREMENTING,
      error
    ),

  deleteUserGroup: (body: IDeleteUserGroup): DeleteUserGroupRequestAction =>
    createAction(UserGroupActionKeys.DELETE_USER_GROUP_REQUEST, body),
  deleteUserGroupFailure: (error: string): DeleteUserGroupFailureAction =>
    createAction(UserGroupActionKeys.DELETE_USER_GROUP_FAILURE, error),
  postUserGroup: (body: ICreateUserGroupRequest): PostUserGroupRequestAction =>
    createAction(UserGroupActionKeys.POST_USER_GROUP_REQUEST, body),
  postUserGroupFailure: (error: string): PostUserGroupFailureAction =>
    createAction(UserGroupActionKeys.POST_USER_GROUP_FAILURE, error),

  patchNameAndDescription: (body: IPatchUserGroupNameAndDescription) =>
    createAction(UserGroupActionKeys.PATCH_USER_GROUP_NAME_DESCRIPTION, body),

  addUserInGroup: (body: IAddUserInGroup): AddUserInGroupRequestAction =>
    createAction(UserGroupActionKeys.ADD_USER_GROUP_REQUEST, body),
  addUserInGroupSuccess: (): AddUserInGroupSuccessAction =>
    createAction(UserGroupActionKeys.ADD_USER_GROUP_SUCCESS),
  addUserInGroupFailure: (err: string): AddUserInGroupFailureAction =>
    createAction(UserGroupActionKeys.ADD_USER_GROUP_FAILURE, err),

  removeUserInGroup: (body: IRemoveUser): RemoveUserInGroupRequestAction =>
    createAction(UserGroupActionKeys.REMOVE_USER_GROUP_REQUEST, body),
  removeUserInGroupSuccess: (): RemoveUserInGroupSuccessAction =>
    createAction(UserGroupActionKeys.REMOVE_USER_GROUP_SUCCESS),
  removeUserInGroupFailure: (err: string): RemoveUserInGroupFailureAction =>
    createAction(UserGroupActionKeys.REMOVE_USER_GROUP_FAILURE, err),

  setFormDisabled: (value: boolean): SetFormDisabledAction =>
    createAction(UserGroupActionKeys.FORM_DISABLED, value),

  isUserGroupAlreadyRegistred: (
    groupName: string
  ): ThereIsUserGroupRequestAction =>
    createAction(
      UserGroupActionKeys.THERE_IS_USER_GROUP_NAME_REQUEST,
      groupName
    ),
  isUserGroupAlreadyRegistredSuccess: (
    body: boolean
  ): ThereIsUserGroupSuccessAction =>
    createAction(UserGroupActionKeys.THERE_IS_USER_GROUP_NAME_SUCCESS, body),

  updatePermission: (
    body: IUpdateUserGroupPermission
  ): UpdateUserGroupPermission =>
    createAction(UserGroupActionKeys.UPDATE_USER_GROUP_PERMISSION, body),

  showPermissionDependencyModal: (
    body: IUpdatePermissionResponse<IUpdateUserGroupPermission>
  ): ShowPermissionDependencyModal =>
    createAction(UserGroupActionKeys.SHOW_PERMISSION_DEPENDENCY_MODAL, body),
  hidePermissionDependencyModal: (): HidePermissionDependencyModal =>
    createAction(UserGroupActionKeys.HIDE_PERMISSION_DEPENDENCY_MODAL),

  updateGroupModuleStatus: (payload: {
    permissionId: string;
    value: boolean;
  }): UpdateGroupModuleStatusAction =>
    createAction(UserGroupActionKeys.UPDATE_USER_GROUP_MODULE_STATUS, payload),
};

export type UserGroupActionUnion = ActionsUnion<typeof UserGroupActions>;

export type SetLoadingAction = Action<UserGroupActionKeys.SET_LOADING, boolean>;

export type SetFormDisabledAction = Action<
  UserGroupActionKeys.FORM_DISABLED,
  boolean
>;

export type UpdateGroupModuleStatusAction = Action<
  UserGroupActionKeys.UPDATE_USER_GROUP_MODULE_STATUS,
  {
    permissionId: string;
    value: boolean;
  }
>;

export type UpdateUserGroupPermission = Action<
  UserGroupActionKeys.UPDATE_USER_GROUP_PERMISSION,
  IUpdateUserGroupPermission
>;

export type ShowPermissionDependencyModal = Action<
  UserGroupActionKeys.SHOW_PERMISSION_DEPENDENCY_MODAL,
  IUpdatePermissionResponse<IUpdateUserGroupPermission>
>;

export type HidePermissionDependencyModal =
  Action<UserGroupActionKeys.HIDE_PERMISSION_DEPENDENCY_MODAL>;

export type GetGroupRequestAction = Action<
  UserGroupActionKeys.GET_GROUP_REQUEST,
  string
>;
export type GetGroupSuccessAction = Action<
  UserGroupActionKeys.GET_GROUP_SUCCESS,
  IUserGroup
>;
export type GetGroupFailureAction = Action<
  UserGroupActionKeys.GET_GROUP_FAILURE,
  string
>;

export type GetAllUsersFromGroupAction = Action<
  UserGroupActionKeys.GET_ALL_USERS_FROM_GROUP_REQUEST,
  IQueryParams
>;
export type GetAllUsersFromGroupSuccessAction = Action<
  UserGroupActionKeys.GET_ALL_USERS_FROM_GROUP_SUCCESS,
  ITransformODataResponse<IUserBasic>
>;
export type GetAllUsersFromGroupFailureAction = Action<
  UserGroupActionKeys.GET_ALL_USERS_FROM_GROUP_FAILURE,
  string
>;

export type ListUserGroupRequestAction = Action<
  UserGroupActionKeys.LIST_USER_GROUP_REQUEST,
  IQueryParams
>;
export type ListUserGroupSuccessAction = Action<
  UserGroupActionKeys.LIST_USER_GROUP_SUCCESS,
  IListUserGroupResponse
>;
export type ListUserGroupFailureAction = Action<
  UserGroupActionKeys.LIST_USER_GROUP_FAILURE,
  string
>;

export type AppendUserGroupToIncrementingList = Action<
  UserGroupActionKeys.APPEND_LIST_USER_GROUP_REQUEST_INCREMENTING,
  IListUserGroupData
>;
export type ListUserGroupIncrementingRequestAction = Action<
  UserGroupActionKeys.LIST_USER_GROUP_REQUEST_INCREMENTING,
  IQueryParams
>;
export type ListUserGroupIncrementingSuccessAction = Action<
  UserGroupActionKeys.LIST_USER_GROUP_SUCCESS_INCREMENTING,
  IListUserGroupResponse
>;
export type ListUserGroupIncrementingFailureAction = Action<
  UserGroupActionKeys.LIST_USER_GROUP_FAILURE_INCREMENTING,
  string
>;

export type AddUserInGroupRequestAction = Action<
  UserGroupActionKeys.ADD_USER_GROUP_REQUEST,
  IAddUserInGroup
>;
export type AddUserInGroupSuccessAction =
  Action<UserGroupActionKeys.ADD_USER_GROUP_SUCCESS>;
export type AddUserInGroupFailureAction = Action<
  UserGroupActionKeys.ADD_USER_GROUP_FAILURE,
  string
>;

export type PostUserGroupRequestAction = Action<
  UserGroupActionKeys.POST_USER_GROUP_REQUEST,
  ICreateUserGroupRequest
>;
export type PostUserGroupFailureAction = Action<
  UserGroupActionKeys.POST_USER_GROUP_FAILURE,
  string
>;
export type PatchNameAndDescription = Action<
  UserGroupActionKeys.PATCH_USER_GROUP_NAME_DESCRIPTION,
  IPatchUserGroupNameAndDescription
>;

export type DeleteUserGroupRequestAction = Action<
  UserGroupActionKeys.DELETE_USER_GROUP_REQUEST,
  IDeleteUserGroup
>;
export type DeleteUserGroupFailureAction = Action<
  UserGroupActionKeys.DELETE_USER_GROUP_FAILURE,
  string
>;

export type RemoveUserInGroupRequestAction = Action<
  UserGroupActionKeys.REMOVE_USER_GROUP_REQUEST,
  IRemoveUser
>;
export type RemoveUserInGroupSuccessAction =
  Action<UserGroupActionKeys.REMOVE_USER_GROUP_SUCCESS>;
export type RemoveUserInGroupFailureAction = Action<
  UserGroupActionKeys.REMOVE_USER_GROUP_FAILURE,
  string
>;

export type ThereIsUserGroupRequestAction = Action<
  UserGroupActionKeys.THERE_IS_USER_GROUP_NAME_REQUEST,
  string
>;
export type ThereIsUserGroupSuccessAction = Action<
  UserGroupActionKeys.THERE_IS_USER_GROUP_NAME_SUCCESS,
  boolean
>;
