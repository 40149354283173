import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { HorizontalTabs } from '../../../../Components';
import { PageLoading } from '../../../../Layouts/PageLoading';
import { List as ProductList } from './ProductForm';
import { DetailsForm } from './Details';
import { DeleteSubGroupModal } from './DeleteSubGroupModal';
import { useSubGroupGet } from '../../../../Hooks/Supply/ProductHooks';
import { history } from '../../../../Store/';

export const Form: React.FC = () => {
  const [modalDeleteSubGroup, setModalDeleteSubGroup] = useState(false);
  const [disabled, setDisabled] = useState(true);

  const { externalId } = useParams<{ externalId: string }>();
  const { data, isLoading } = useSubGroupGet(externalId);

  const { t } = useTranslation();

  return (
    <PageLoading loading={isLoading}>
      <HorizontalTabs
        type="secondary"
        items={[
          {
            label: t('admin.editUserPage.tab.details'),
            key: 'details',
            children: (
              <DetailsForm
                disabled={disabled}
                onChangeDisabled={(x: boolean) => setDisabled(x)}
              />
            ),
          },
          {
            label: t('products.common.products'),
            key: 'products',
            children: <ProductList />,
          },
        ]}
        dropdownItens={[
          {
            label: t('admin.editUserPage.tab.buttonActions.delete'),
            icon: 'trash',
            onClick: () => setModalDeleteSubGroup(true),
          },
        ]}
        dropdownDirection="bottomRight"
        dropdownTitle={t('admin.editUserPage.tab.buttonActions.title')}
        leftIcon="menu-ellipsis-horizontal"
        rigthButton={{
          onClick: () => setDisabled(false),
          isVisible: disabled,
          codePermissionEdit: 3016,
          messageTooltipPermission: 'noPermission.supply.subGroups.edit',
        }}
        leftButton={{
          isVisible: true,
          onClick: () =>
            history.push(`/inventory/groups/${data?.grupoExternalId}/details`),
          leftIcon: 'tag',
          children: t('products.subGroupList.goToGroup'),
        }}
      />
      {data?.externalId ? (
        <DeleteSubGroupModal
          productSubGroup={data}
          onCancelClick={() => setModalDeleteSubGroup(false)}
          visible={modalDeleteSubGroup}
        />
      ) : null}
    </PageLoading>
  );
};
