import * as yup from 'yup';
import { i18n } from '../../../../Components';

interface ICompanyCardSchema {
  radioCompanyGroup: boolean;
  radioUser: boolean;
  radioCompanyBillings: boolean;
}

export const CompanyCardSchema = (value: ICompanyCardSchema) =>
  yup.object().shape({
    cnpj: yup
      .string()
      .matches(
        /\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}/,
        i18n.t('adminBootys.createCompany.companyDataCard.errors.invalidCNPJ')
      )
      .required(
        i18n.t('adminBootys.createCompany.companyDataCard.errors.requiredCNPJ')
      ),
    razaoSocial: yup
      .string()
      .required(
        i18n.t(
          'adminBootys.createCompany.companyDataCard.errors.requiredSocialReason'
        )
      ),
    nomeFantasia: yup
      .string()
      .required(
        i18n.t(
          'adminBootys.createCompany.companyDataCard.errors.requiredFantasyName'
        )
      ),
    inscricaoEstadual: yup
      .string()
      .min(
        12,
        i18n.t(
          'adminBootys.createCompany.companyDataCard.errors.invalidStateRegistration'
        )
      )
      .required(
        i18n.t(
          'adminBootys.createCompany.companyDataCard.errors.requiredStateRegistration'
        )
      ),
    inscricaoMunicipal: yup
      .string()
      .min(
        11,
        i18n.t(
          'adminBootys.createCompany.companyDataCard.errors.invalidMunicipalRegistration'
        )
      )
      .required(
        i18n.t(
          'adminBootys.createCompany.companyDataCard.errors.requiredMunicipalRegistration'
        )
      ),
    cnae: yup
      .string()
      .required(
        i18n.t('adminBootys.createCompany.companyDataCard.errors.requiredCNAE')
      ),
    email: yup
      .string()
      .email(
        i18n.t(
          'adminBootys.createCompany.CompanyAdressCard.errors.invalidEmail'
        )
      ),
    endereco: yup.object().shape({
      cep: yup
        .string()
        .required(
          i18n.t(
            'adminBootys.createCompany.CompanyAdressCard.errors.requiredCEP'
          )
        ),
      numero: yup
        .string()
        .required(
          i18n.t(
            'adminBootys.createCompany.CompanyAdressCard.errors.requiredNumber'
          )
        ),
      bairro: yup
        .string()
        .required(
          i18n.t(
            'adminBootys.createCompany.CompanyAdressCard.errors.requiredNeighborhood'
          )
        ),
      cidade: yup
        .string()
        .required(
          i18n.t(
            'adminBootys.createCompany.CompanyAdressCard.errors.requiredCity'
          )
        ),
      estado: yup
        .string()
        .required(
          i18n.t(
            'adminBootys.createCompany.CompanyAdressCard.errors.requiredState'
          )
        ),
      logradouro: yup
        .string()
        .required(
          i18n.t(
            'adminBootys.createCompany.CompanyAdressCard.errors.requiredStreet'
          )
        ),
    }),

    ...(value.radioUser && {
      usuario: yup.object().shape({
        email: yup
          .string()
          .required(
            i18n.t(
              'adminBootys.createCompany.responsibleUserCard.errors.invalidMail'
            )
          ),

        nome: yup
          .string()
          .required(
            i18n.t(
              'adminBootys.createCompany.responsibleUserCard.errors.requiredName'
            )
          ),

        sobrenome: yup
          .string()
          .required(
            i18n.t(
              'adminBootys.createCompany.responsibleUserCard.errors.requiredLastName'
            )
          ),

        dataNascimento: yup
          .string()
          .matches(
            /[0-9]{2}\/[0-9]{2}\/[0-9]{4}/,
            i18n.t(
              'adminBootys.createCompany.responsibleUserCard.errors.invalidBirthday'
            )
          ),

        cpf: yup
          .string()
          .matches(
            /[0-9]{3}\.[0-9]{3}\.[0-9]{3}-[0-9]{2}/,
            i18n.t(
              'adminBootys.createCompany.responsibleUserCard.errors.invalidCpf'
            )
          ),
      }),
    }),
    ...(!value.radioUser && {
      usuarioResponsavelExternalId: yup
        .string()
        .required(
          i18n.t(
            'adminBootys.createCompany.responsibleUserCard.errors.requiredUser'
          )
        ),
    }),
    ...(value.radioCompanyGroup && {
      nomeConglomerado: yup
        .string()
        .required(
          i18n.t(
            'adminBootys.createCompany.companyGroupCard.errors.requiredGroupName'
          )
        ),
    }),

    ...(!value.radioCompanyGroup && {
      conglomeradoExternalId: yup
        .string()
        .required(
          i18n.t(
            'adminBootys.createCompany.companyGroupCard.errors.requiredGroup'
          )
        ),
    }),
    ...(!value.radioCompanyBillings && {
      empresaPagadoraExternalId: yup
        .string()
        .required(
          i18n.t(
            'adminBootys.createCompany.billingCard.errors.requiredCompanyBilling'
          )
        ),
    }),
  });
