export const purchasingEN = {
  purchaseOrder: {
    newButton: 'New Purchase Order',
    list: {
      searchPlaceholder: 'Search by an order, provider, date, status, etc.',
      purchaseOrderNumber: 'Order Nº.',
      provider: 'Provider',
      releaseDate: 'Launch Date',
      deliveryForecastDate: 'Delivery Forecast',
      amount: 'Amount',
      status: 'Status',

      statusType: [
        'Waiting for Approval',
        'Disapproved',
        'Approved',
        'Confirmed with Provider',
        'Partial Received',
        'Total Received',
        'Drafit',
      ],
    },
  },

  provider: {
    create: {
      info: 'Info',
      contact: 'Contacts',
      address: 'Addresses',

      pf: 'Person',
      pj: 'Company',

      type: 'Provider type',
      typePlaceholder: 'Select a provider type',

      fantasyName: 'Fantasy Name',
      fantasyNamePlaceholder: 'Ex: Farmácia Souza e Filhos',

      social: 'Legal Name',
      socialPlaceholder: 'Ex: FARMACIA SOUZA E FILHOS LTDA',

      cnpj: 'CNPJ',
      cnpjPlaceholder: 'Ex: 42.163.509/0001-03',

      cpf: 'CPF',
      cpfPlaceholder: 'Ex: 123.123.123-02',

      obs: 'Notes',
      obsPlaceholder: 'Add extra notes...',

      contactTable: {
        type: 'Contact Type',
        contact: 'Contact',
        obs: 'Notes',
      },

      addressTable: {
        desc: 'Description',
        address: 'Address',
      },

      addressModal: {
        addAddress: 'Add address',
        save: 'Save',

        title: 'Add address',
        editTitle: 'Edit address',

        desc: 'Descriptions',
        descPlaceholder: 'Ex: Principal',

        cep: 'CEP',
        cepPlaceholder: '00000-000',

        street: 'Street',
        streetPlaceholder: 'Ex: Street 123',

        number: 'Number',
        numberPlaceholder: 'Ex: 465',

        extra: 'Extra',
        extraPlaceholder: 'Ex: Room 01',

        hood: 'Hood',
        hoodPlaceholder: 'Ex: Center',

        city: 'City',
        cityPlaceholder: 'Choose a city',

        state: 'State',
        statePlaceholder: 'Choose a state',
      },

      documentModal: {
        title: 'Add document',

        type: 'Document Type',
        typePlaceholder: 'Select a document type',

        document: 'Document',
        documentPlaceholder: 'Document',

        desc: 'Notes',
        descPlaceholder: 'Add a note...',

        add: 'Create',
        save: 'Save',
        cancel: 'Cancel',
      },

      contactModal: {
        addAddress: 'Add contacts',
        title: 'Add contact',
        editTitle: 'Edit contact',

        contactType: 'Contact Type',
        contact: 'Contact',

        obs: 'Notes',
        obsPlaceholder: 'Add a note...',

        ok: 'Add',
        save: 'Save',
        cancel: 'Cancel',

        errors: {
          required: 'This is a required field',
        },
      },
    },
  },
};

export const purchasingPT = {
  purchaseOrder: {
    newButton: 'Novo Pedido de Compra',
    list: {
      searchPlaceholder:
        'Pesquise por um pedido, fornecedor, data, status, etc',
      purchaseOrderNumber: 'Nº Pedido',
      provider: 'Fornecedor',
      releaseDate: 'Data Lançamento',
      deliveryForecastDate: 'Previsão Entrega',
      amount: 'Valor Total',
      status: 'Status',

      statusType: [
        'Aguardando Aprovação',
        'Reprovado',
        'Aprovado',
        'Confirmado com Fornecedor',
        'Recebido Parcialmente',
        'Recebido Total',
        'Rascunho',
      ],
    },
  },

  provider: {
    create: {
      info: 'Informações',
      contact: 'Contatos',
      address: 'Endereços',

      pf: 'Pessoa física',
      pj: 'Pessoa jurídica',

      type: 'Tipo de fornecedor',
      typePlaceholder: 'Selecione um tipo de fornecedor',

      fantasyName: 'Nome Fantasia',
      fantasyNamePlaceholder: 'Ex: Farmácia Souza e Filhos',

      social: 'Razão Social',
      socialPlaceholder: 'Ex: FARMACIA SOUZA E FILHOS LTDA',

      cnpj: 'CNPJ',
      cnpjPlaceholder: 'Ex: 42.163.509/0001-03',

      cpf: 'CPF',
      cpfPlaceholder: 'Ex: 123.123.123-02',

      obs: 'Observação',
      obsPlaceholder: 'Adicione uma observação...',

      contactTable: {
        type: 'Tipo de Contato',
        contact: 'Contato',
        obs: 'Observações',
      },

      addressTable: {
        desc: 'Descrição',
        address: 'Endereço',
      },

      addressModal: {
        addAddress: 'Adicionar endereço',
        save: 'Salvar',

        title: 'Adicionar endereço',
        editTitle: 'Editar endereço',

        desc: 'Descrição',
        descPlaceholder: 'Ex: Matriz',

        cep: 'CEP',
        cepPlaceholder: '00000-000',

        street: 'Logradouro',
        streetPlaceholder: 'Ex: Rua João Augusto de Moraes',

        number: 'Número',
        numberPlaceholder: 'Ex: 465',

        extra: 'Complemento',
        extraPlaceholder: 'Ex: Sala 01',

        hood: 'Bairro',
        hoodPlaceholder: 'Ex: Centro',

        city: 'Cidade',
        cityPlaceholder: 'Selecione uma cidade',

        state: 'Estado',
        statePlaceholder: 'Selecione um estado',
      },

      contactModal: {
        addAddress: 'Adicionar contato',
        title: 'Adicionar contato',
        editTitle: 'Editar contato',

        contactType: 'Tipo de Contato',
        contact: 'Contato',

        obs: 'Observações',
        obsPlaceholder: 'Adicione uma observação...',

        ok: 'Adicionar',
        save: 'Salvar',
        cancel: 'Cancelar',

        errors: {
          required: 'Este campo e obrigatório',
        },
      },

      documentModal: {
        title: 'Adicionar documento',
        editTitle: 'Editar documento',

        type: 'Tipo de Documento',
        typePlaceholder: 'Selecione um tipo de documento',

        document: 'Documento',
        documentPlaceholder: 'Documento',

        desc: 'Observações',
        descPlaceholder: 'Adicione uma observação...',

        add: 'Adicionar',
        save: 'Salvar',
        cancel: 'Cancelar',
      },

      documentTable: {
        title: 'Documentos',

        columns: {
          type: 'Tipo de Documento',
          document: 'Documento',
          desc: 'Observações',
        },
      },
    },
  },
};
