import { combineReducers } from 'redux';

import auth from './Auth/Auth.reducer';
import user from './User/User.reducer';
import company from './Company/Company.reducer';
import notification from './Notification/Notification.reducer';
import group from './Group/Group.reducer';
import CEP from './CEP/CEP.reducer';
import conglomerate from './Conglomerate/Conglomerate.reducer';
import Class from './ClassType/ClassType.reducer';
import provider from './Provider/Provider.reducer';
import unitMeasurement from './UnitMeasurement/UnitMeasurement.reducer';
import currency from './Currency/Currency.reducer';
import losses from './Losses/Losses.reducer';
import balanceAdjustment from './BalanceAdjustments/BalanceAdjustments.reducer';
import stockBalance from './StockBalance/StockBalance.reducer';
import storageLocation from './StorageLocation/StorageLocation.reducer';
import country from './Country/Country.reducer';
import purchasing from './Purchasing/Purchasing.reducer';

const reducers = combineReducers({
  auth,
  user,
  company,
  notification,
  group,
  CEP,
  conglomerate,
  Class,
  country,
  provider,
  unitMeasurement,
  currency,
  losses,
  balanceAdjustment,
  stockBalance,
  storageLocation,
  purchasing,
});

export default reducers;
