export type Color =
	| 'primary-darkest'
	| 'primary-dark'
	| 'primary-base'
	| 'primary-light'
	| 'primary-lightest'
	| 'primary-t45'
	| 'primary-t30'
	| 'primary-t15'
	| 'black'
	| 'white'
	| 'text-50'
	| 'text-100'
	| 'text-200'
	| 'text-300'
	| 'text-400'
	| 'text-500'
	| 'text-600'
	| 'text-700'
	| 'text-800'
	| 'background-00'
	| 'background-01'
	| 'background-02'
	| 'background-03'
	| 'background-04'
	| 'background-06'
	| 'background-08'
	| 'background-12'
	| 'background-16'
	| 'background-24'
	| 'status-success-base'
	| 'status-success-t45'
	| 'status-success-t30'
	| 'status-success-t15'
	| 'status-warning-base'
	| 'status-warning-t45'
	| 'status-warning-t30'
	| 'status-warning-t15'
	| 'status-danger-base'
	| 'status-danger-t45'
	| 'status-danger-t30'
	| 'status-danger-t15'
	| 'status-information-base'
	| 'status-information-t45'
	| 'status-information-t30'
	| 'status-information-t15'
	| 'status-default-base'
	| 'status-default-t45'
	| 'status-default-t30'
	| 'status-default-t15'
	| 'status-disabled-base'
	| 'status-disabled-t45'
	| 'status-disabled-t30'
	| 'status-disabled-t15'
	| 'status-primary-base'
	| 'status-primary-t45'
	| 'status-primary-t30'
	| 'status-primary-t15'
	| 'users01'
	| 'users02'
	| 'users03'
	| 'users05'
	| 'users07'
	| 'users09'
	| 'users11'
	| 'users13'

export const Colors = {
	'primary-darkest': 'var(--color-primary-darkest)',
	'primary-dark': 'var(--color-primary-dark)',
	'primary-base': 'var(--color-primary-base)',
	'primary-light': 'var(--color-primary-light)',
	'primary-lightest': 'var(--color-primary-lightest)',
	'primary-t45': 'var(--color-primary-t45)',
	'primary-t30': 'var(--color-primary-t30)',
	'primary-t15': 'var(--color-primary-t15)',
	black: 'var(--color-black)',
	white: 'var(--color-white)',
	'text-50': 'var(--color-text-50)',
	'text-100': 'var(--color-text-100)',
	'text-200': 'var(--color-text-200)',
	'text-300': 'var(--color-text-300)',
	'text-400': 'var(--color-text-400)',
	'text-500': 'var(--color-text-500)',
	'text-600': 'var(--color-text-600)',
	'text-700': 'var(--color-text-700)',
	'text-800': 'var(--color-text-800)',
	'background-00': 'var(--color-background-00)',
	'background-01': 'var(--color-background-01)',
	'background-02': 'var(--color-background-02)',
	'background-03': 'var(--color-background-03)',
	'background-04': 'var(--color-background-04)',
	'background-06': 'var(--color-background-06)',
	'background-08': 'var(--color-background-08)',
	'background-12': 'var(--color-background-12)',
	'background-16': 'var(--color-background-16)',
	'background-24': 'var(--color-background-24)',
	'status-success-base': 'var(--color-status-success-base)',
	'status-success-t45': 'var(--color-status-success-t45)',
	'status-success-t30': 'var(--color-status-success-t45)',
	'status-success-t15': 'var(--color-status-success-t45)',
	'status-warning-base': 'var(--color-status-warning-base)',
	'status-warning-t45': 'var(--color-status-warning-t45)',
	'status-warning-t30': 'var(--color-status-warning-t45)',
	'status-warning-t15': 'var(--color-status-warning-t45)',
	'status-danger-base': 'var(--color-status-danger-base)',
	'status-danger-t45': 'var(--color-status-danger-t45)',
	'status-danger-t30': 'var(--color-status-danger-t45)',
	'status-danger-t15': 'var(--color-status-danger-t45)',
	'status-information-base': 'var(--color-status-information-base)',
	'status-information-t45': 'var(--color-status-information-t45)',
	'status-information-t30': 'var(--color-status-information-t45)',
	'status-information-t15': 'var(--color-status-information-t45)',
	'status-default-base': 'var(--color-status-default-base)',
	'status-default-t45': 'var(--color-status-default-t45)',
	'status-default-t30': 'var(--color-status-default-t45)',
	'status-default-t15': 'var(--color-status-default-t45)',
	'status-disabled-base': 'var(--color-status-disabled-base)',
	'status-disabled-t45': 'var(--color-status-disabled-t45)',
	'status-disabled-t30': 'var(--color-status-disabled-t45)',
	'status-disabled-t15': 'var(--color-status-disabled-t45)',
	'status-primary-base': 'var(-color-status-primary-base)',
	'status-primary-t45': 'var(--color-status-primary-t45)',
	'status-primary-t30': 'var(--color-status-primary-t45)',
	'status-primary-t15': 'var(--color-status-primary-t45)',
	users01: 'var(--color-users01)',
	users02: 'var(--color-users02)',
	users03: 'var(--color-users03)',
	users05: 'var(--color-users05)',
	users07: 'var(--color-users07)',
	users09: 'var(--color-users09)',
	users11: 'var(--color-users11)',
	users13: 'var(--color-users13)',
	transparent: 'transparent'
}

export const AvatarColors = {
	user01: 'var(--color-users01)',
	user02: 'var(--color-users02)',
	user03: 'var(--color-users03)',
	user04: 'var(--color-users04)',
	user05: 'var(--color-users05)',
	user06: 'var(--color-users06)',
	user07: 'var(--color-users07)',
	user08: 'var(--color-users08)',
	user09: 'var(--color-users09)',
	user10: 'var(--color-users10)',
	user11: 'var(--color-users11)',
	user12: 'var(--color-users12)',
	user13: 'var(--color-users13)',
	user14: 'var(--color-users14)',
}

export function AvatarColor(initial: any) {
	var alfabeto = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
	var codigos: number[] = []
	for (var i in initial) {
		codigos.push(alfabeto.indexOf(initial[i].toUpperCase()) + 1)
	}
	const sum = codigos[0] + codigos[1]
	if (sum <= 6) return 'user01'
	else if (sum <= 9) return 'user02'
	else if (sum <= 12) return 'user03'
	else if (sum <= 15) return 'user04'
	else if (sum <= 18) return 'user05'
	else if (sum <= 21) return 'user06'
	else if (sum <= 24) return 'user07'
	else if (sum <= 28) return 'user08'
	else if (sum <= 32) return 'user09'
	else if (sum <= 36) return 'user10'
	else if (sum <= 40) return 'user11'
	else if (sum <= 44) return 'user12'
	else if (sum <= 48) return 'user13'
	else if (sum <= 52) return 'user14'
	else return 'user01'
}
