import React from 'react';
import { i18n, Link } from '../../../../Components';
import { LinkButton } from '../../../../Components/Layouts/LinkButton';
import { ConvertDate } from '../../../../Utils/DateUtils';

export const conglomerateCollums = (t: any, datePatter: string) => [
  {
    title: t('adminBootys.conglomerateList.tableTitle.name'),
    dataIndex: 'nome',
    key: 'nome',
    sortColumn: 'nome',
    sorter: true,
    isHighlightable: true,
    defaultSortOrder: 'descend',
    render: (x: any, row: any) => (
      <Link
        type="ui-tiny-bold"
        to={`/admin-bootys/conglomerate/${row.externalId}/details`}
        style={{ width: 'fit-content' }}
      >
        {x}
      </Link>
    ),
  },
  {
    title: t('adminBootys.conglomerateList.tableTitle.headquarters'),
    dataIndex: 'matriz',
    key: 'matriz',
    width: '22%',
    sortColumn: 'matriz',
    sorter: true,
    isHighlightable: true,
    render: (x: any, data: any) =>
      x && (
        <LinkButton
          link={`/admin-bootys/company/${data?.externalId}/details`}
          text={x}
        />
      ),
  },
  {
    title: t('adminBootys.conglomerateList.tableTitle.companys'),
    dataIndex: 'empresas',
    key: 'empresas',
    width: '18%',
    sortColumn: 'empresas',
    sorter: true,
    isHighlightable: true,
    render: (x: any, data: any) => (
      <LinkButton
        link={`/admin-bootys/conglomerate/${data?.externalId}/companys`}
        text={
          parseInt(x) === 1
            ? `${x} ${i18n.t('common.company')}`
            : `${x} ${i18n.t('common.companys')}`
        }
      />
    ),
  },
  {
    title: t('adminBootys.conglomerateList.tableTitle.inclusionDate'),
    dataIndex: 'dataInclusao',
    key: 'dataInclusao',
    width: '18%',
    sortColumn: 'dataInclusao',
    sorter: true,
    isHighlightable: true,
    render: (date: any) => ConvertDate(date, datePatter),
  },
];
