import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Tabs } from '../../Components/Tabs';
import { AccountDetails } from './AccountDetails';

import styles from './AccountManage.module.scss';
import AccountSecurity from './AccountSecurity';
import AccountNotification from './Notification';

export const AccountManage: FC = () => {
  const { t } = useTranslation();

  return (
    <main className={styles['container']}>
      <Tabs
        items={[
          {
            label: t('accountManager.accountDetails'),
            key: 'account-details',
            children: <AccountDetails />,
          },
          {
            label: t('accountManager.accountSecurity'),
            key: 'account-security',
            children: <AccountSecurity />,
          },
          {
            label: t('accountManager.accountNotification'),
            key: 'account-notification',
            children: <AccountNotification />,
          },
        ]}
      />
    </main>
  );
};
