import { useState } from 'react';
import { FormikHelpers, FormikValues } from 'formik';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  FETCH_GET_PRODUCT,
  FETCH_LIST_PRODUCT,
  useProductEdit,
  useProductGet,
} from '../../../../../../Hooks/Supply/ProductHooks';
import { useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router';
import { IEditProductRequest } from '../../../../../../Data/Interfaces/request/Product/IProductRequest';
import { Button, Form } from '../../../../../../Components';
import { ProductDetailsSchema } from './ProductDetails.schema';
import { ProductInfoCard } from '../../../Components/ProductCard';
import styles from './ProductDetails.module.scss';
import { DetailsInitialValues } from './InitialValues';
import { Modal } from '../../../../../../Components/Modal';
import { history } from '../../../../../../Store';

interface IProductCard {
  disabledForm: boolean;
  onDisabledForm?: () => void;
  toogleDisabled: (x: boolean) => void;
}

export const DetailsTab: FC<IProductCard> = ({
  disabledForm,
  toogleDisabled,
}) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const editProduct = useProductEdit();
  const [isBackModalOpen, setIsBackModalOpen] = useState(false);
  const { externalId } = useParams<{ externalId: string }>();
  const { data: product } = useProductGet(externalId);

  const onSubmit = async (
    values: IEditProductRequest,
    helpers: FormikHelpers<FormikValues>
  ) => {
    var body: IEditProductRequest = { ...values, helpers };

    if (product) {
      const res = await editProduct.mutateAsync({
        ...body,
        helpers,
      });
      if (res.success) {
        queryClient.invalidateQueries([FETCH_GET_PRODUCT]);
        queryClient.invalidateQueries([FETCH_LIST_PRODUCT]);
        toogleDisabled(!disabledForm);
      }
    }
  };

  return (
    <>
      <Form
        initialValues={DetailsInitialValues(product) || undefined}
        onSubmit={onSubmit}
        schema={ProductDetailsSchema}
      >
        {(props) => {
          return (
            <>
              <ProductInfoCard
                disabledForm={disabledForm}
                product={product || undefined}
              />
              <ProductInfoCard
                disabledForm={disabledForm}
                product={product || undefined}
              />
              {!disabledForm && (
                <div className={styles['footer-button']}>
                  <Button
                    type="secondary"
                    children={t('products.create.cancel')}
                    onClick={() =>
                      !!props?.dirty
                        ? setIsBackModalOpen(true)
                        : toogleDisabled(!disabledForm)
                    }
                  />
                  <Button
                    type="primary"
                    children={
                      editProduct.isLoading
                        ? t('products.create.saveLoading')
                        : t('products.create.save')
                    }
                    htmlType="submit"
                    loading={editProduct.isLoading}
                    disabled={!props.dirty}
                  />
                </div>
              )}
            </>
          );
        }}
      </Form>

      <Modal
        visible={isBackModalOpen}
        onOkClick={() => history.push('/inventory/products')}
        onCancelClick={() => setIsBackModalOpen(false)}
        onClose={() => setIsBackModalOpen(false)}
        okButtonName={t('supply.balanceAdjustment.backModal.okButton')}
        title={t('supply.balanceAdjustment.backModal.title')}
        body={
          <div style={{ padding: 20, maxWidth: 400 }}>
            {t('supply.balanceAdjustment.backModal.body')}
          </div>
        }
      />
    </>
  );
};
