import { ICountryList } from '../../Data/Interfaces/response/Country/ICountryResponse';
import { CountryActionKeys, CountryActionUnion } from './Country.actions';
import { ICountryState } from './interfaces/ICountryState';

const initialData: ICountryState = {
  isLoading: false,
  isCountryLoading: false,
};

export const countryReducer = (
  state = initialData,
  action: CountryActionUnion
): ICountryState => {
  switch (action.type) {
    case CountryActionKeys.SET_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };

    case CountryActionKeys.COUNTRY_LIST_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: undefined,
      };

    case CountryActionKeys.COUNTRY_LIST_ACCOUNTMANAGER_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: undefined,
        currentCountrylist: {
          ...(state.currentCountrylist as ICountryList),
          pageIndex: action.payload?.PageIndex || 0,
          pageSize: action.payload?.PageSize || 0,
        },
      };

    case CountryActionKeys.COUNTRY_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: undefined,
        currentCountrylist: {
          ...action.payload,
          data: [
            ...(state.currentCountrylist?.data || []),
            ...action.payload.data,
          ],
        },
      };
    case CountryActionKeys.COUNTRY_LIST_FALUIRE:
      return {
        ...state,
        isLoading: false,
        error: undefined,
      };

    default:
      return state;
  }
};

export default countryReducer;
