import React, { useEffect, FC, useState } from 'react';
import { Row, Col } from 'antd';
import styles from './CompanyGroupCard.module.scss';
import { useFormikContext } from 'formik';
import { Card, Dropdown, Form, i18n } from '../../../Components';
import {
  patternLabelStyle,
  TextInput,
} from '../../../Components/Inputs/TextInput';
import { InputWithSearch } from '../../../Components/Inputs/InputWithSearch';

import { useConglomerateAdminListForDropdownType } from '../../../Hooks/Accountmanager/ConglomerateHooks';
import { IPagination } from '../../../Components/Table';
import { IGetCompanyResponse } from '../../../Data/Interfaces/response/Company/ICompanyResponse';
import { RadioGroup } from '../../../Components/StateInputs/RadioButton/RadioGroup';
import { RadioButton } from '../../../Components/StateInputs/RadioButton';

export enum CompanyGroupTypeEnum {
  NEW = 1,
  EXISTENT = 2,
}

interface ICompanyGroups {
  editForm?: boolean;
  onChange?: (value: boolean) => void;
  radioCompany?: boolean;
  companyData?: IGetCompanyResponse;
}

export const CompanyGroupsCard: FC<ICompanyGroups> = ({
  editForm,
  onChange,
  radioCompany,
  companyData,
}) => {
  const [pagination, setPagination] = useState<IPagination>({
    PageIndex: 0,
    PageSize: 10,
  });

  const { data: listConglomerate } =
    useConglomerateAdminListForDropdownType(pagination);

  return (
    <Card
      title={i18n.t('adminBootys.createCompany.companyGroup')}
      withoutCollapse={editForm}
    >
      {!editForm && (
        <RadioGroup
          className={styles['radio-button']}
          valueChecked={radioCompany ? 1 : 2}
        >
          <RadioButton
            value={1}
            label={i18n.t(
              'adminBootys.createCompany.companyGroupCard.newCompany'
            )}
            onChange={() => {
              onChange && onChange(true);
            }}
            disabled={editForm}
          />
          <RadioButton
            value={2}
            label={i18n.t(
              'adminBootys.createCompany.companyGroupCard.existingCompany'
            )}
            onChange={() => onChange && onChange(false)}
            disabled={editForm}
          />
        </RadioGroup>
      )}
      {!radioCompany || editForm ? (
        <Row gutter={[20, 0]}>
          <Col flex={editForm ? '22%' : '34%'}>
            <InputWithSearch
              name="conglomeradoExternalId"
              withoutMarginBottom={true}
              label={[
                {
                  children: i18n.t(
                    'adminBootys.createCompany.companyGroupCard.companyGroup'
                  ),
                  ...patternLabelStyle,
                },
              ]}
              placeHolder={i18n.t(
                'adminBootys.createCompany.companyGroupCard.companyGroupPlaceholder'
              )}
              items={listConglomerate?.data.map((x) => ({
                id: x.externalId,
                label: x.nome,
              }))}
              disabled={editForm}
              required
              onScrollEnd={() =>
                setPagination({
                  ...pagination,
                  PageIndex: pagination.PageIndex + 1,
                })
              }
            />
          </Col>

          <Col flex="10.5%">
            {companyData && (
              <Dropdown
                name="tipoId"
                items={[
                  {
                    id: 1,
                    name: i18n.t(
                      'adminBootys.createCompany.companyGroupCard.headquarters'
                    ),
                  },
                  {
                    id: 2,
                    name: i18n.t(
                      'adminBootys.createCompany.companyGroupCard.branch'
                    ),
                  },
                ]}
                label={[
                  {
                    children: i18n.t(
                      'adminBootys.createCompany.companyGroupCard.roleInGroup'
                    ),
                    ...patternLabelStyle,
                  },
                ]}
                disabled={editForm}
              />
            )}
          </Col>
        </Row>
      ) : (
        <Row gutter={[8, 0]}>
          <Col flex="34%">
            <TextInput
              name="nomeConglomerado"
              withoutMarginBottom={true}
              label={[
                {
                  children: i18n.t(
                    'adminBootys.createCompany.companyGroupCard.groupName'
                  ),
                  ...patternLabelStyle,
                },
              ]}
              placeHolder={i18n.t(
                'adminBootys.createCompany.companyGroupCard.groupNamePlaceholder'
              )}
              disabled={editForm}
              required
            />
          </Col>
        </Row>
      )}
    </Card>
  );
};

interface ICompanyGroupsCard {
  onChangeRadioCompanyGroup: (value: boolean) => void;
  radioCompanyGroup: boolean;
  company?: IGetCompanyResponse;
}

export const CompanyGroupsCardWrapper: React.FC<ICompanyGroupsCard> = ({
  company,
  onChangeRadioCompanyGroup,
  radioCompanyGroup,
}) => {
  const form = useFormikContext();

  useEffect(() => {
    radioCompanyGroup &&
      form?.setFieldValue('conglomeradoExternalId', undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company, radioCompanyGroup]);

  return company ? (
    <CompanyGroupsCardEditWrapper
      onChangeRadioCompanyGroup={onChangeRadioCompanyGroup}
      radioCompanyGroup={radioCompanyGroup}
      company={company}
    />
  ) : (
    <CompanyGroupsCard
      onChange={onChangeRadioCompanyGroup}
      radioCompany={radioCompanyGroup}
    />
  );
};

interface ICompanyGroupsCardEditWrapper {
  onChangeRadioCompanyGroup: (value: boolean) => void;
  radioCompanyGroup: boolean;
  company: IGetCompanyResponse;
}

export const CompanyGroupsCardEditWrapper: React.FC<ICompanyGroupsCardEditWrapper> =
  ({ company, onChangeRadioCompanyGroup, radioCompanyGroup }) => {
    return (
      <Form initialValues={company}>
        <CompanyGroupsCard
          onChange={onChangeRadioCompanyGroup}
          radioCompany={radioCompanyGroup}
          companyData={company}
          editForm
        />
      </Form>
    );
  };
