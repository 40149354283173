import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { HorizontalTabs } from '../../../../Components';
import { history } from '../../../../Store/';
import { PageLoading } from '../../../../Layouts/PageLoading';
import { useParams } from 'react-router-dom';
import { StockLocationsList } from './StockLocation';
import { DetailsForm } from './DetailsCards/index';
import { DeleteLotModal } from './Modal';
import { useLotGet } from '../../../../Hooks/Supply/LotHooks';

export const EditLot: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [disabled, setDisabled] = useState(true);
  const [visibleModal, setVisibleModal] = useState(false);
  const params: Readonly<Partial<{ externalId: string; tab: string }>> =
    useParams();
  const { externalId } = useParams<{ externalId: string }>();

  const { data: lot, isLoading } = useLotGet(externalId);
  return (
    <PageLoading loading={isLoading}>
      <HorizontalTabs
        type="secondary"
        items={[
          {
            label: t('Detalhes'),
            key: 'details',
            children: (
              <DetailsForm
                disabled={disabled}
                toogleDisabled={(x: boolean) => setDisabled(x)}
              />
            ),
          },
          {
            label: t('Rastreabilidade'),
            key: 'traceability',
            disabled: true,
          },
          {
            label: t('Locais de Estoque'),
            key: 'stockLocations',
            children: <StockLocationsList />,
          },
        ]}
        dropdownDirection="bottomRight"
        dropdownTitle={t('admin.editUserPage.tab.buttonActions.title')}
        leftIcon="menu-ellipsis-horizontal"
        rigthButton={{
          ...(disabled &&
            params.tab === 'details' && {
              onClick: () => setDisabled(false),
              isVisible: disabled,
              codePermissionEdit: 2004,
              messageTooltipPermission: 'noPermission.supply.lot.edit',
            }),
        }}
        leftButton={{
          isVisible: true,
          onClick: () =>
            history.push(
              `/inventory/products/${lot?.produtoExternalId}/details`
            ),
          leftIcon: 'Box',
          children: t('supply.lot.details.goToProducts'),
          codePermissionEdit: 2007,
          messageTooltipPermission: 'noPermission.supply.lot.detailProducts',
        }}
        dropdownItens={[
          {
            label: 'Excluir',
            icon: 'trash',
            onClick: () => setVisibleModal(true),
            permission: {
              permission: 2006,
              tooltip: 'noPermission.supply.lot.delete',
            },
          },
        ]}
      />

      <DeleteLotModal
        dispatch={dispatch}
        lot={lot}
        visibleModal={visibleModal}
        changeVisibleModal={(value) => setVisibleModal(value)}
      />
    </PageLoading>
  );
};
