import { Col, Row } from 'antd';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Card, Form } from '../../../../../Components';
import { TextInputNumber } from '../../../../../Components/Inputs/TextInputNumber';
import { useBalanceAdjustmentsGet } from '../../../../../Hooks/Supply/BalanceAdjustmentsHooks';

export const BalanceAdjustmentCard: FC = () => {
  const { externalId } = useParams<{ externalId: string }>();
  const { data: balanceAdjustment } = useBalanceAdjustmentsGet(externalId);
  const { t } = useTranslation();

  return (
    <Form
      initialValues={{
        ...balanceAdjustment,
      }}
    >
      <Card
        title={t('supply.balanceAdjustment.priceAdjust')}
        withoutCollapse={true}
      >
        <Row gutter={[16, 0]}>
          <Col span={8}>
            <TextInputNumber
              name="saldoAnterior"
              disabled
              label={t(
                'supply.balanceAdjustment.details.balanceBeforeAdjustment'
              )}
              rightIcon={{
                titleString: balanceAdjustment?.unidadeMedida.abreviacao,
                id: balanceAdjustment?.unidadeMedidaId,
              }}
              withTooltip={{
                icon: 'exclamation-upside-down',
                title: t(
                  'supply.balanceAdjustment.details.toolTip.balanceBeforeAdjustment'
                ),
              }}
            />
          </Col>
          <Col span={8}>
            <TextInputNumber
              name={'quantidadeDoAjuste'}
              disabled
              label={t('supply.balanceAdjustment.details.adjustmentAmount')}
              rightIcon={{
                titleString: balanceAdjustment?.unidadeMedida.abreviacao,
                id: balanceAdjustment?.unidadeMedidaId,
              }}
              color={
                balanceAdjustment?.tipoOperacao === 0
                  ? 'status-success-base'
                  : 'status-danger-base'
              }
            />
          </Col>
          <Col span={8}>
            <TextInputNumber
              name="saldoFinal"
              disabled
              label={t(
                'supply.balanceAdjustment.details.balanceAfterAdjustment'
              )}
              rightIcon={{
                titleString: balanceAdjustment?.unidadeMedida.abreviacao,
                id: balanceAdjustment?.unidadeMedidaId,
              }}
              withTooltip={{
                icon: 'exclamation-upside-down',
                title: t(
                  'supply.balanceAdjustment.details.toolTip.balanceAfterAdjustment'
                ),
              }}
            />
          </Col>
        </Row>
      </Card>
    </Form>
  );
};
