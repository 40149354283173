import { Col, Row } from 'antd';
import { useFormikContext } from 'formik';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import {
  TextInput,
  Tooltip,
  InputWithSearch,
} from '../../../../../../Components';
import {
  useLotGet,
  useLotGetDropdown,
} from '../../../../../../Hooks/Supply/LotHooks';

interface IDetailsForm {
  disabled: boolean;
}

export const TechnicalInfoDiluitionsRows: FC<IDetailsForm> = ({ disabled }) => {
  const { externalId } = useParams<{ externalId: string }>();
  const { t } = useTranslation();
  const { data: lots } = useLotGet(externalId);
  const form: any = useFormikContext();
  const technicalInfos = lots?.loteInformacaoTecnica;

  const { data: lotList } = useLotGetDropdown({
    PageIndex: 0,
    PageSize: 40,
    filter: [
      { filterName: 'produtoExternalId', externalId: lots?.produtoExternalId },
    ],
  });

  return (
    <>
      <Row gutter={[16, 0]}>
        <Col span={5}>
          <Tooltip
            title={`${t('supply.lot.details.toolTips.originalDilution')} ${
              !!technicalInfos
                ? lots?.loteInformacaoTecnica?.diluicaoFornecedor
                : 0
            }%`}
            showMe={
              lots?.loteInformacaoTecnica?.diluicaoFornecedor !==
                form?.values?.loteInformacaoTecnica?.diluicaoFornecedor &&
              !disabled
            }
          >
            <TextInput
              name="loteInformacaoTecnica.diluicaoFornecedor"
              label={t('supply.lot.details.details.supplierDilution')}
              placeHolder={t('supply.lot.details.details.supplierDilution')}
              disabled={disabled}
              withTooltip={{
                icon: 'exclamation-upside-down',
                title: t('supply.lot.create.toolTips.supplierDilution'),
              }}
              withStatusIcon
              required={!disabled}
              type="number"
            />
          </Tooltip>
        </Col>
        <Col span={6}>
          <Tooltip
            title={`${t(
              'supply.lot.details.toolTips.originalDilutionFactor'
            )} ${
              !!technicalInfos
                ? lots?.loteInformacaoTecnica?.fatorDiluicaoFornecedor
                : 0
            }`}
            showMe={
              lots?.loteInformacaoTecnica?.fatorDiluicaoFornecedor !==
                form?.values?.loteInformacaoTecnica?.fatorDiluicaoFornecedor &&
              !disabled
            }
          >
            <TextInput
              name="loteInformacaoTecnica.fatorDiluicaoFornecedor"
              label={t('supply.lot.details.details.supplierDilutionFactor')}
              placeHolder={t(
                'supply.lot.details.details.supplierDilutionFactor'
              )}
              disabled={disabled}
              withTooltip={{
                icon: 'exclamation-upside-down',
                title: t('supply.lot.create.toolTips.supplierDilutionFactor'),
              }}
              withStatusIcon
              required={!disabled}
              type="number"
            />
          </Tooltip>
        </Col>
        <div
          style={{
            borderLeft: 1,
            borderLeftStyle: 'solid',
            height: 57,
            marginLeft: 10,
            marginRight: 10,
            borderLeftColor: 'var(--color-background-04)',
          }}
        />
        <Col span={6}>
          <Tooltip
            title={`${t(
              'supply.lot.details.toolTips.originalWaterConcentration'
            )} ${
              !!technicalInfos
                ? lots?.loteInformacaoTecnica?.concentracaoAgua
                : 0
            }%`}
            showMe={
              lots?.loteInformacaoTecnica?.concentracaoAgua !==
                form?.values?.loteInformacaoTecnica?.concentracaoAgua &&
              !disabled
            }
          >
            <TextInput
              name="loteInformacaoTecnica.concentracaoAgua"
              label={t('supply.lot.details.details.waterConcentration')}
              placeHolder={t('supply.lot.details.details.waterConcentration')}
              disabled={disabled}
              withTooltip={{
                icon: 'exclamation-upside-down',
                title: t('supply.lot.create.toolTips.waterConcentration'),
              }}
              withStatusIcon
              required={!disabled}
              type="number"
            />
          </Tooltip>
        </Col>
        <Col span={6}>
          <Tooltip
            title={`${t(
              'supply.lot.details.toolTips.originalWaterConcentrationFactor'
            )} ${
              !!technicalInfos
                ? lots?.loteInformacaoTecnica?.fatorConcentracaoAgua
                : 0
            }`}
            showMe={
              lots?.loteInformacaoTecnica?.fatorConcentracaoAgua !==
                form?.values?.loteInformacaoTecnica?.fatorConcentracaoAgua &&
              !disabled
            }
          >
            <TextInput
              name="loteInformacaoTecnica.fatorConcentracaoAgua"
              label={t('supply.lot.details.details.waterConcentrationFactor')}
              placeHolder={t(
                'supply.lot.details.details.waterConcentrationFactor'
              )}
              disabled={disabled}
              withTooltip={{
                icon: 'exclamation-upside-down',
                title: t('supply.lot.create.toolTips.waterConcentrationFactor'),
              }}
              withStatusIcon
              required={!disabled}
              type="number"
            />
          </Tooltip>
        </Col>
      </Row>

      <div style={{ borderTop: '1px solid var(--color-background-04)' }}>
        <Row gutter={[16, 0]} style={{ marginTop: '20px' }}>
          <Col span={6}>
            <Tooltip
              title={`${t(
                'supply.lot.details.toolTips.originalInternalDilution'
              )} ${
                !!technicalInfos
                  ? lots?.loteInformacaoTecnica?.diluicaoInterna
                  : 0
              }%`}
              showMe={
                lots?.loteInformacaoTecnica?.diluicaoInterna !==
                  form?.values?.loteInformacaoTecnica?.diluicaoInterna &&
                !disabled
              }
            >
              <TextInput
                name="loteInformacaoTecnica.diluicaoInterna"
                label={t('supply.lot.details.details.internalDilution')}
                placeHolder={t('supply.lot.details.details.internalDilution')}
                disabled={disabled}
                withTooltip={{
                  icon: 'exclamation-upside-down',
                  title: t('supply.lot.create.toolTips.internalDilution'),
                }}
                withStatusIcon
                type="number"
              />
            </Tooltip>
          </Col>
          <Col span={6}>
            <Tooltip
              title={`${t(
                'supply.lot.details.toolTips.originalInternalDilutionFactor'
              )} ${
                !!technicalInfos
                  ? lots?.loteInformacaoTecnica?.fatorDiluicaoInterna
                  : 0
              }`}
              showMe={
                !!technicalInfos &&
                lots?.loteInformacaoTecnica?.fatorDiluicaoInterna !==
                  form?.values?.loteInformacaoTecnica?.fatorDiluicaoInterna &&
                !disabled
              }
            >
              <TextInput
                name="loteInformacaoTecnica.fatorDiluicaoInterna"
                label={t('supply.lot.details.details.internalDilutionFactor')}
                placeHolder={t(
                  'supply.lot.details.details.internalDilutionFactor'
                )}
                disabled={disabled}
                withTooltip={{
                  icon: 'exclamation-upside-down',
                  title: t('supply.lot.create.toolTips.internalDilutionFactor'),
                }}
                withStatusIcon
                type="number"
              />
            </Tooltip>
          </Col>
          <Col span={6}>
            <Tooltip
              title={`${t('supply.lot.details.toolTips.originalLot')} ${
                lots?.loteInformacaoTecnica?.loteOrigemExternalId
              } / ${lots?.numeroNf}`}
              showMe={
                lots?.loteInformacaoTecnica?.loteOrigemExternalId !==
                  form?.values?.loteInformacaoTecnica?.loteOrigemExternalId &&
                !disabled
              }
            >
              <InputWithSearch
                name="loteInformacaoTecnica.loteOrigemExternalId"
                items={lotList?.data?.map((x: any) => ({
                  id: x.externalId,
                  label: x.loteNumero,
                  subLabel: `NF: ${x.numeroNf}`,
                }))}
                label={t('supply.lot.details.details.lotOrigin')}
                placeHolder={t('supply.lot.details.details.lotOrigin')}
                disabled={disabled}
                withTooltip={{
                  icon: 'exclamation-upside-down',
                  title: t('supply.lot.create.toolTips.lotOrigin'),
                }}
                withStatusIcon
              />
            </Tooltip>
          </Col>
        </Row>
      </div>
    </>
  );
};
