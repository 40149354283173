import React, { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Color } from '../../../../../Utils/ColorUtils';
import { Modal } from '../../../../Modal';
import { Text } from '../../../../Text';
import { deleteModalBody, deleteModalTitle } from './DeleteModal.helper';

import styles from './DeleteModal.module.scss';

export interface IDeleteModalTranslations {
  modalTitle: string;

  manyTitle: string;
  manyFooter: string;

  singleTitle: string;
  singleFooter: string;

  avatarSingleFooter: string;
  avatarSingleFooter2: string;
}

export interface IDeleteModal {
  visible: boolean;
  itemNames: string[][];

  okButtonName?: string;

  translations?: IDeleteModalTranslations;

  onOkClick: () => void;
  onCancelClick: () => void;
  body?: ReactNode;
  title?: string;
  customEndTitle?: string;
  okButtonColor?: Color;

  className?: string;
}

export const DeleteModal: FC<IDeleteModal> = ({
  visible,
  itemNames,
  translations,
  customEndTitle,
  okButtonName,
  onOkClick,
  onCancelClick,
  body,
  title,
  okButtonColor,
  className,
}) => {
  const { t } = useTranslation();
  const isSingleItem = itemNames.length === 1;

  return (
    <Modal
      visible={visible}
      okButtonClassName={styles['deleteOkBtn']}
      onClose={onCancelClick}
      okButtonName={okButtonName || 'modal.delete'}
      onOkClick={onOkClick}
      onCancelClick={onCancelClick}
      okButtonColor={okButtonColor}
      title={deleteModalTitle(
        isSingleItem,
        itemNames,
        t,
        customEndTitle,
        title,
        translations
      )}
      body={deleteModalBody(body, itemNames, translations)}
      className={className}
    />
  );
};

export interface IManyItemsBody {
  itemNames: string[][];
  translations?: IDeleteModalTranslations;
}

export const SingleItemBody: FC<IManyItemsBody> = ({
  itemNames,
  translations,
}) => {
  const { t } = useTranslation();
  return (
    <div className={styles['ManyItemsBody']}>
      <div style={{ display: 'flex' }}>
        <Text
          color="text-50"
          type="ui-tiny-content"
          children={
            translations?.singleTitle
              ? t(translations?.singleTitle)
              : `${t('modal.deleteModal.single.question')} ${t(
                  'common.theCompanyGroup'
                )} ${itemNames[0][0]}?`
          }
        />
      </div>
      <FooterThisActionIsPermanent />
    </div>
  );
};

export const ManyItemsBody: FC<IManyItemsBody> = ({
  itemNames,
  translations,
}) => {
  const { t } = useTranslation();
  return (
    <div className={styles['ManyItemsBody']}>
      <div>
        <Text color="text-50" type="ui-sub-content">
          {translations?.manyTitle
            ? t(translations?.manyTitle)
            : t('modal.deleteModal.many.title')}
        </Text>
        <ul>
          {itemNames.map((x) => (
            <li>
              <Text color="text-50" type="ui-tiny-bold">
                {x}
              </Text>
            </li>
          ))}
        </ul>
      </div>
      <FooterThisActionIsPermanent />
    </div>
  );
};

export const FooterThisActionIsPermanent = () => {
  const { t } = useTranslation();
  return (
    <div style={{ display: 'flex' }}>
      <Text
        color="text-50"
        type="ui-tiny-content"
        children={t('modal.deleteModal.single.footerFirst')}
      />
      <Text
        color="text-50"
        type="ui-tiny-bold"
        children={t('modal.deleteModal.single.footerSecond')}
      />
      <Text
        color="text-50"
        type="ui-tiny-content"
        children={t('modal.deleteModal.single.footerTertiary')}
      />
    </div>
  );
};

export const FooterThisActionInitialStockBalance = () => {
  const { t } = useTranslation();
  return (
    <div style={{ display: 'flex' }}>
      <Text
        color="text-50"
        type="ui-tiny-content"
        children={t('modal.deleteModal.single.footerStockBalanceFirst')}
      />
      <Text
        color="text-50"
        type="ui-tiny-content"
        children={t('modal.deleteModal.single.footerStockBalanceSecond')}
      />
      <Text
        color="text-50"
        type="ui-tiny-content"
        children={t('modal.deleteModal.single.footerStockBalanceTertiary')}
      />
    </div>
  );
};
