import React, {
  CSSProperties,
  FC,
  ReactNode,
  useEffect,
  useState,
} from 'react';
import {
  Formik,
  FormikProps,
  FormikValues,
  Form as FormFormik,
  FormikHelpers,
} from 'formik';
import { ObjectSchema } from 'yup';

export interface IFChildren extends FormikProps<FormikValues> {}

export type FChildren = (props: IFChildren) => ReactNode;

export interface IForm {
  id?: string;
  name?: string;
  schema?: ObjectSchema<any>;
  initialValues?: FormikValues;
  children: ReactNode | FChildren;
  validateOnChange?: boolean;
  validateOnBlur?: boolean;
  style?: CSSProperties;

  className?: string;
  validateOnMount?: boolean;
  onSubmit?: (values: any, formikHelpers: FormikHelpers<FormikValues>) => void;
}

export const Form: FC<IForm> = ({
  id,
  name,
  initialValues,
  schema,
  className,
  children,
  style,
  validateOnChange = false,
  validateOnBlur = false,
  validateOnMount = false,
  onSubmit,
}) => {
  const [validateOnChangeAfterSubmit, setValidateOnChangeAfterSubmit] =
    useState(false);
  return (
    <Formik
      initialValues={initialValues || {}}
      enableReinitialize={true}
      validationSchema={schema}
      validateOnMount={validateOnMount}
      validateOnChange={validateOnChange || validateOnChangeAfterSubmit}
      validateOnBlur={validateOnBlur}
      onSubmit={onSubmit || (() => {})}
    >
      {(props) => (
        <FormValidate
          children={children}
          props={props}
          className={className}
          id={id}
          name={name}
          style={style}
          onChangeValidateOnChange={() => setValidateOnChangeAfterSubmit(true)}
        />
      )}
    </Formik>
  );
};

interface IFormValidate
  extends Omit<
    IForm,
    | 'schema'
    | 'initialValues'
    | 'validateOnChange'
    | 'validateOnBlur'
    | 'validateOnMount'
    | 'onSubmit'
  > {
  props: FormikProps<FormikValues>;
  onChangeValidateOnChange: () => void;
}

const FormValidate: FC<IFormValidate> = ({
  id,
  name,
  className,
  style,
  children,
  props,
  onChangeValidateOnChange,
}) => {
  const isFunction = typeof children === 'function';
  const FChildren = children as FChildren;

  useEffect(() => {
    if (props.errors && Object.keys(props.errors).length > 0)
      onChangeValidateOnChange && onChangeValidateOnChange();
  }, [props.errors, onChangeValidateOnChange]);

  return (
    <FormFormik id={id} name={name} className={className} style={style}>
      {isFunction ? FChildren && FChildren(props) : children}
    </FormFormik>
  );
};
