import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Dropdown, Form } from '../../../Components'
import { TableLayout } from '../../../Components/Tabs/TablePreference'
import { languages, standardDate, standardTime } from './HardCode.items'
import { getAllTimezones } from '../../../Utils/DateUtils'

import styles from './PlaceAndTime.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { UserActions } from '../../../Store/User/User.actions'
import { IPatchUserRequest } from '../../../Data/Interfaces/request/User/IUserRequest'
import { CreateInitialValuesForPlaceAndTime } from '../../../Utils/CreateInitialValuesUtils'
import { getMeUser } from '../../../Store/User/User.selector'

export const PlaceAndTime: FC = () => {
	const [buttonDisabled, setButtonDesabled] = useState(true)
	const [initialValues, setInitialValues] = useState<any>()

	const { t } = useTranslation()

	const dispatch = useDispatch()

	const user = useSelector(getMeUser)

	useEffect(() => {
		setInitialValues(CreateInitialValuesForPlaceAndTime(user))
	}, [user])

	const onSubmit = (
		values: IPatchUserRequest,
	) => {
		var body: IPatchUserRequest = { ...values }

        user &&
            dispatch(
                UserActions.patchMe({
                    userExternalId: user.externalId,
                    data: body,
                })
            );
	}

	return (
		<TableLayout
			title={t('preferences.placeAndTimePage.title')}
			body={
				<Form
					initialValues={{
						...initialValues,
					}}
					onSubmit={onSubmit}
				>
					<div className={styles['container']}>
						{user ? (
							<>
								<Dropdown
									name='idioma'
									items={languages}
									label={[
										{
											typeLabel: 'ui-tiny-semibold',
											colorLabel: 'text-50',
											children: t(
												'preferences.placeAndTimePage.selectLanguage'
											),
										},
									]}
									onChange={() => setButtonDesabled(false)}
								/>
								<Dropdown
									name='padraoData'
									items={standardDate}
									label={[
										{
											typeLabel: 'ui-tiny-semibold',
											colorLabel: 'text-50',
											children: t(
												'preferences.placeAndTimePage.selectStandardDate'
											),
										},
									]}
									onChange={() => setButtonDesabled(false)}
								/>
								<Dropdown
									name='padraoHora'
									items={standardTime}
									label={[
										{
											typeLabel: 'ui-tiny-semibold',
											colorLabel: 'text-50',
											children: t(
												'preferences.placeAndTimePage.selectStandardTime'
											),
										},
									]}
									onChange={() => setButtonDesabled(false)}
								/>
								<Dropdown
									name='timeZone'
									items={getAllTimezones().map((timezone) => ({
										id: timezone.value,
										name: timezone.label,
									}))}
									label={[
										{
											typeLabel: 'ui-tiny-semibold',
											colorLabel: 'text-50',
											children: t(
												'preferences.placeAndTimePage.selectTimeZone'
											),
										},
									]}
									onChange={() => setButtonDesabled(false)}
								/>
								<Button
									className={styles['button']}
									type='primary'
									children={t('preferences.placeAndTimePage.button')}
									disabled={buttonDisabled}
									htmlType='submit'
								/>
							</>
						) : null}
					</div>
				</Form>
			}
		/>
	)
}
