import React, { FC } from 'react';

import { Icon, Text } from '../..';
import { IText } from '../../Text';
import { IInputLabel } from '../Dropdown';
import { IField } from '../interfaces/IField';

import styles from './Label.module.scss';

export interface ILabel extends IText {
  children: string;
  className?: string;

  required?: boolean;
}

export const Label: FC<ILabel> = ({
  type,
  color,
  children,
  className,
  required,
}) => (
  <div
    className={`${styles['Label']} ${className} ${required ? styles['label-required'] : ''}`}
  >
    <Text type={type} color={color} children={children} />
  </div>
);

export interface ILabelInput {
  label?: IInputLabel[] | string;
  required?: boolean;
  withStatusIcon?: boolean;
  props?: IField;
}

export const InputLabel: FC<ILabelInput> = ({
  label,
  required,
  withStatusIcon,
  props,
}) => {
  if (!label) return null;

  if (typeof label === 'string')
    return (
      <div className={styles['div-label']}>
        <>
          <Label
            className={styles['label']}
            type={'ui-tiny-semibold'}
            color={'text-50'}
            required={required}
          >
            {label}
          </Label>
          {withStatusIcon && props?.meta.value !== props?.meta.initialValue && (
            <Icon
              icon="status-attention"
              size="SS"
              className={styles['icon-status']}
            />
          )}
        </>
      </div>
    );

  return (
    <div className={styles['div-label']}>
      {label.map((lab, index) => (
        <Label
          className={styles['label']}
          type={lab.typeLabel || 'ui-tiny-semibold'}
          color={lab.colorLabel || 'text-50'}
          required={required}
          key={`input-with-mask-label-${index}`}
        >
          {lab.children}
        </Label>
      ))}
      {withStatusIcon && props?.meta.value !== props?.meta.initialValue && (
        <Icon
          icon="status-attention"
          size="SS"
          className={styles['icon-status']}
        />
      )}
    </div>
  );
};
