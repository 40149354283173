import { getCurrencySymbol } from './Currency';
import { ConvertDateForApi } from './DateUtils';

export type IMask =
  | 'dataMask'
  | 'cellMaskWithDDI'
  | 'cnpjMask'
  | 'cellMaskWithDDD'
  | 'cepMask'
  | 'cpfMask'
  | 'cnpj'
  | 'cep'
  | 'stateRegistration'
  | 'municipalRegistration';

export const Mask = (mask: IMask) => {
  switch (mask) {
    case 'dataMask':
      return '99/99/9999';

    case 'cellMaskWithDDI':
      return '+99 (99) 99999-9999';

    case 'cnpjMask':
      return '99.999.999/9999-99';

    case 'cellMaskWithDDD':
      return '(99) 99999-9999';

    case 'cepMask':
      return '99999-999';

    case 'cpfMask':
      return '999.999.999-99';

    case 'stateRegistration':
      return '999999999999';

    case 'municipalRegistration':
      return '99999999999';

    default:
      return '';
  }
};

export const MaskWithRegex = (text: string) => {
  return text.replaceAll(/R\$.+\//gi, 'I');
};

export const RemoveMask = (
  value: string | undefined,
  dataPattern?: any | undefined
) => {
  if (!value) return;
  if (value && dataPattern) return ConvertDateForApi(value, dataPattern);
  else return value?.replace(/[^\d]+/g, '');
};

export const MaskForCNPJ = (cnpj?: string) =>
  cnpj?.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');

export type IMaskInputNumber = 'currencyMask' | 'porcentageMask';

export const FormatterInputNumber = (
  value: string,
  mask?: IMaskInputNumber
) => {
  const symbol = getCurrencySymbol();

  switch (mask) {
    case 'currencyMask':
      return `${symbol} ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    case 'porcentageMask':
      return `${value}%`;

    default:
      return value;
  }
};

export const ParserInputNumber = (value?: string, mask?: IMaskInputNumber) => {
  switch (mask) {
    case 'currencyMask':
      return value!.replace(/R?\$\s?|(,*)/g, '');

    case 'porcentageMask':
      return value!.replace('%', '');

    default:
      return value;
  }
};
