import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { CompanyResponsibleUserCard } from '..';
import { Form } from '../../../../Components';
import { IChangeResponsibilityUserRequest } from '../../../../Data/Interfaces/request/Company/ICompanyRequest';
import { IGetCompanyResponse } from '../../../../Data/Interfaces/response/Company/ICompanyResponse';
import { IQueryListParams } from '../../../../Utils/Http';
import { initialValues } from '../CompanyResponsibleUserCard.InitialValues';
import { CompanyResponsibleUserCardSchema } from '../CompanyResponsibleUserCard.schema';
import { useCompanyChangeResponsibleUserMutation } from '../../../../Hooks/Accountmanager/CompanyHooks';
import { useGetActiveUserAdminListType } from '../../../../Hooks/Accountmanager/UserHooks';

interface ICompanyResponsibleUserCardWrapperAdmin {
  company: IGetCompanyResponse;
  radioCompanyGroup?: boolean;
  radioUser: boolean;
  onChangeRadioUser: (value: boolean) => void;
}

export const EditCompanyUserAdmin: React.FC<ICompanyResponsibleUserCardWrapperAdmin> =
  ({ company, radioCompanyGroup, radioUser, onChangeRadioUser }) => {
    const { mutateAsync, isLoading } =
      useCompanyChangeResponsibleUserMutation();
    return (
      <Form
        onSubmit={(values: IChangeResponsibilityUserRequest) =>
          mutateAsync(values)
        }
        initialValues={company && initialValues(company)}
        schema={CompanyResponsibleUserCardSchema}
      >
        <CompanyResponsibleUserCardWrapperAdmin
          radioCompanyGroup={!!radioCompanyGroup}
          radioUser={radioUser}
          onChangeRadioUser={onChangeRadioUser}
          isLoading={isLoading}
        />
      </Form>
    );
  };

interface IEditCompanyUserAdmin {
  radioCompanyGroup?: boolean;
  radioUser: boolean;
  onChangeRadioUser: (value: boolean) => void;

  isLoading: boolean;
}

export const CompanyResponsibleUserCardWrapperAdmin: React.FC<IEditCompanyUserAdmin> =
  ({ radioCompanyGroup, radioUser, onChangeRadioUser, isLoading }) => {
    const [pagination, setPagination] = useState<IQueryListParams>({
      PageIndex: 0,
      PageSize: 10,
    });
    const form: any = useFormikContext();
    const { data: users } = useGetActiveUserAdminListType(pagination);

    useEffect(() => {
      setPagination({
        ...pagination,

        filter: [
          {
            filterName: 'conglomeradoExternalId',
            externalId: form?.values.empresasExternalId[0],
          },
        ],
      });

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form?.values?.conglomeradoExternalId, form.values.empresasExternalId]);

    return (
      <CompanyResponsibleUserCard
        editForm
        radioCompanyGroup={!!radioCompanyGroup}
        radioUser={radioUser}
        onChangeRadioUser={onChangeRadioUser}
        isLoading={isLoading}
        users={users}
        onScrollEndUserDropdown={() =>
          setPagination({
            ...pagination,
            PageIndex: pagination.PageIndex + 1,
          })
        }
      />
    );
  };
