import { call, put } from 'redux-saga/effects';
import { AuthAPI } from '../../Data/API/Identity/AuthApi';
import {
  AuthActions,
  LoginRequestAction,
  NewPasswordRequestAction,
  RecoveryRequestAction,
} from './Auth.actions';
import { history } from '../../Store';
import StorageUtils from '../../Utils/StorageUtils';
import { CURRENT_USER } from './Auth.constants';
import { openNotification } from '../../Components/Feedback/Toast';
import i18n from '../../Components/i18n';
import { messageErrorToast } from '../../Utils/MessageErrorToast';
import { setRoles } from '../../Utils/PermissionUtils';

export function* login({ payload }: LoginRequestAction) {
  try {
    const { data } = yield call(AuthAPI.login, payload);

    StorageUtils.SetRememberMe(payload.rememberMe === true);
    StorageUtils.SetItem(CURRENT_USER, JSON.stringify(data.data));
    setRoles(data.data.access);

    yield put(AuthActions.loginSuccess(data.data));

    history.push('/');
  } catch (error: any) {
    messageErrorToast(error);

    yield put(AuthActions.loginFailure(error.message));
  } finally {
    payload.helpers.setSubmitting(false);
  }
}

export function* recovery({ payload }: RecoveryRequestAction) {
  try {
    yield call(AuthAPI.recovery, payload);

    yield put(AuthActions.RecoverySuccess());

    history.push('/forgot-password/success', { email: payload.email });
  } catch (error: any) {
    messageErrorToast(error);

    yield put(AuthActions.RecoveryFailure(error.message));
  } finally {
    payload.helpers.setSubmitting(false);
  }
}
export function* newpassword({ payload }: NewPasswordRequestAction) {
  try {
    yield call(AuthAPI.newpassword, payload);

    yield put(AuthActions.NewPasswordSuccess());

    openNotification(
      'sucess',
      'Yeah!',
      i18n.t('notifications.newPasswordChanged'),
      false
    );
    history.push('/login');
  } catch (error: any) {
    messageErrorToast(error);

    yield put(AuthActions.NewPasswordFailure(error.message));
  } finally {
    payload.helpers.setSubmitting(false);
  }
}
