import React, { useState } from 'react';
import { Table } from '../../../../Components';
import { history } from '../../../../Store';
import { useTranslation } from 'react-i18next';
import { balanceAdjustmentsColumns } from './balanceAdjustments.columns';
import { IPagination } from '../../../../Components/Table';
import { useBalanceAdjustmentsList } from '../../../../Hooks/Supply/BalanceAdjustmentsHooks';
import { useUserPreferencesType } from '../../../../Hooks/Accountmanager/UserHooks';
import { PageLoading } from '../../../../Layouts/PageLoading';
import { ContainerPage } from '../../../../Layouts/ContainerPage';

export const BalanceAdjustmentsList: React.FC = () => {
  const [pagination, setPagination] = useState<IPagination>({
    PageIndex: 0,
    PageSize: 10,
    Sorter: { column: 'dataLancamento', direction: 'ASC' },
  });
  const { data: balanceAdjustmentsList, isLoading } =
    useBalanceAdjustmentsList(pagination);

  const { data: currentMeUser } = useUserPreferencesType();

  const { t } = useTranslation();

  return (
    <PageLoading loading={isLoading}>
      <ContainerPage>
        <Table
          columns={balanceAdjustmentsColumns(t, currentMeUser)}
          data={
            balanceAdjustmentsList?.data?.map((balanceRow: any) => ({
              ...balanceRow,
              key: `table_balance_${balanceRow.externalId}`,
            })) || []
          }
          rowsOptions={{
            onRowDoubleClick: (x) =>
              history.push(
                `/inventory/balance-adjustments/${x.externalId}/details`
              ),
            permission: {
              permission: 2021,
              tooltip: 'noPermission.supply.balanceAdjustments.detail',
            },
          }}
          headerOptions={{
            hasSearch: true,
            newButton: {
              onClick: () =>
                history.push('/inventory/balance-adjustments/create'),
              label: 'supply.balanceAdjustment.list.newButton',
              permission: {
                permission: 2022,
                tooltip: 'noPermission.supply.balanceAdjustments.create',
              },
            },
            searchPlaceholder: t(
              'supply.balanceAdjustment.list.searchPlaceHolder'
            ),
            nameKey: 'DataTransferencia',
          }}
          loading={isLoading}
          onRefetch={(pagination) => setPagination(pagination)}
          pagination={
            balanceAdjustmentsList
              ? {
                  Count: balanceAdjustmentsList.count,
                  PageIndex: balanceAdjustmentsList.pageIndex,
                  PageSize: balanceAdjustmentsList.pageSize,
                }
              : false
          }
        />
      </ContainerPage>
    </PageLoading>
  );
};

export default BalanceAdjustmentsList;
