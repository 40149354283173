import { ModalFuncProps } from 'antd';
import { FC } from 'react';
import { BodyModal } from '../../../../../../Components/Modal';
import { Footer as ModalFooter } from '../../../../../../Components/Modal/Components/Footer';
import {
  ICommonError,
  IGlobalModal,
} from '../../../../../../Utils/ErrorHandlingUtils';
import { Text } from '../../../../../../Components';
import { useTranslation } from 'react-i18next';
import { history } from '../../../../../../Store';
import { DeleteModalOnTableNotFooter } from '../../../../../../Utils/DeleteModalUtils';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';

const Content: FC<ICommonError> = ({ content }) => {
  const { t } = useTranslation();

  return content?.length > 1 ? (
    <>
      <BodyModal
        body={
          <>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {DeleteModalOnTableNotFooter(
                content,
                `${t('supply.group.modal.cannotDelete.batchSubTitle')}`,
                'grupoDescricao',
                ''
              )}
              <Text type="ui-tiny-content">
                <ReactMarkdown>
                  {t('supply.group.modal.cannotDelete.batchDescription')}
                </ReactMarkdown>
              </Text>
            </div>
          </>
        }
        style={{ padding: '13px 27px' }}
      />
    </>
  ) : (
    <>
      <BodyModal
        body={
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Text type="ui-tiny-content">
              <ReactMarkdown>
                {t('supply.group.modal.cannotDelete.subTitle', {
                  group: `**${content[0].grupoDescricao}**`,
                })}
              </ReactMarkdown>
            </Text>

            <Text type="ui-tiny-content" style={{ marginTop: 25 }}>
              <ReactMarkdown>
                {t('supply.group.modal.cannotDelete.description', {
                  products: content[0].produtoCount,
                  subgroups: content[0].subGrupoCount,
                })}
              </ReactMarkdown>
            </Text>
          </div>
        }
        style={{ padding: 20 }}
      />
    </>
  );
};

const Title: FC<ICommonError> = ({ content }) => {
  const { t } = useTranslation();
  return content?.length > 1 ? (
    <>
      {t('supply.group.modal.cannotDelete.batchTitle', {
        group: content.length,
      })}
    </>
  ) : (
    <>
      {t('supply.group.modal.cannotDelete.title', {
        group: content[0].grupoDescricao,
      })}
    </>
  );
};

const Footer: FC<{
  params: { gruposExternalId: string[] };
  instance: IGlobalModal;
  content: any;
}> = ({ params, instance, content }) => {
  const { t } = useTranslation();

  return content?.length > 1 ? (
    <ModalFooter
      onCancelClick={instance.destroy}
      withoutOkButton
      cancelButtonName={t('common.close')}
    />
  ) : (
    <ModalFooter
      okButtonName={t('supply.group.modal.cannotDelete.ok')}
      onOkClick={() => {
        history.push(
          `/inventory/groups/${params.gruposExternalId[0]}/products`
        );
        instance.destroy();
      }}
      onCancelClick={instance.destroy}
    />
  );
};

export const CannotDeleteModal: (
  error: ICommonError,
  params: { gruposExternalId: string[] },
  instance: IGlobalModal
) => ModalFuncProps = (error, params, instance) => ({
  closable: true,
  icon: <></>,
  title: <Title {...error} />,
  content: <Content {...error} />,
  footer: (
    <Footer params={params} instance={instance} content={error.content} />
  ),
});
