import { call, put } from 'redux-saga/effects';
import { i18n } from '../../Components';
import { LossesAPI, ProductLossAPI } from '../../Data/API/Supply/LossesApi';
import { IUpdateLoss } from '../../Data/Interfaces/request/Loss/IUpdateLoss';
import { IProductLossListData } from '../../Data/Interfaces/response/Loss/ILossResponse';
import { Http } from '../../Utils/Http';
import {
  messageErrorToast,
  messageSucessToast,
} from '../../Utils/MessageErrorToast';
import { Action } from '../Base/actionHelpers';
import {
  LossesActions,
  ProductLossListAction,
  LossesActionKeys,
} from './Losses.actions';

export function* listProductLosses({ payload }: ProductLossListAction) {
  try {
    const { data } = yield call(ProductLossAPI.productLossList, payload);
    const transformedData = Http.TransformODataData<IProductLossListData>(
      data,
      payload.pagination
    );

    yield put(LossesActions.listProductLossListSuccess(transformedData));
  } catch (error) {
    messageErrorToast(error);
  } finally {
    yield put(LossesActions.setLoading(false));
  }
}

export function* updateProductLoss({
  payload,
}: Action<LossesActionKeys.UPDATE_LOSS_REQUEST, IUpdateLoss>) {
  try {
    yield call(LossesAPI.updateLoss, payload);

    yield put(LossesActions.updateProductLossSuccess());
    yield put(LossesActions.setIsEditMode(false));
    messageSucessToast(i18n.t('supply.edit.success'));
  } catch (error) {
    messageErrorToast(error);
  } finally {
    yield put(LossesActions.setLoading(false));
  }
}
