import React, { FC } from 'react';
import { Pagination as AntdPagination, Select } from 'antd';

import styles from './Pagination.module.scss';
import './Pagination.override.scss';
import { useTranslation } from 'react-i18next';
import { ISorter } from '../..';

export interface IPaginationInput extends IPagination {
  Search?: string;
  Count: number;
}

export interface IPagination {
  Search?: string;
  Sorter?: ISorter;
  PageSize: number;
  PageIndex: number;
  Count?: number;
  Data?: [];
  Incrementing?: any[];
}

export interface ITablePagination {
  loading: boolean;

  Search?: string;
  Count: number;
  PageSize: number;
  PageIndex: number;
  Sorter?: ISorter;

  onRefetch?: (pagination: IPagination) => void;
}

export const Pagination: FC<ITablePagination> = ({
  loading,
  PageSize,
  PageIndex,
  Count,
  Search,
  Sorter,
  onRefetch,
}) => {
  const { t } = useTranslation();
  return (
    <div className={styles['Pagination']}>
      <div className={styles['start']}>
        <p
          className={styles['text']}
          children={t('table.pagination.itemsPerPage')}
        />

        <Select
          value={PageSize}
          className="pagination-select"
          onChange={(value) => {
            onRefetch &&
              onRefetch({
                Search: Search,
                PageIndex: 0,
                PageSize: value,
              });
          }}
        >
          {[10, 20, 50, 100].map((x, index) => (
            <Select.Option key={`pageSize_${index}`} value={x}>
              {x}
            </Select.Option>
          ))}
        </Select>

        {!loading ? (
          <p className={styles['count']}>
            | {Count} {Count > 1 ? t('common.items') : t('common.item')}
          </p>
        ) : null}
      </div>

      <div className={styles['end']}>
        {Count > 0 ? (
          <AntdPagination
            total={Count}
            current={PageIndex}
            pageSize={PageSize}
            showTitle={false}
            onChange={(page) => {
              onRefetch &&
                onRefetch({
                  Search: Search,
                  PageIndex: --page,
                  PageSize: PageSize,
                  Sorter: Sorter,
                });
            }}
          />
        ) : null}
      </div>
    </div>
  );
};
