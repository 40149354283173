import { accountManagerPT as accountManager } from './partials/accountManager';
import { adminPT as admin } from './partials/admin';
import { adminBootysPT as adminBootys } from './partials/adminBootys';
import { breadCrumbPT as breadCrumb } from './partials/breadCrumb';
import { commonPT as common } from './partials/common';
import { datePT as date } from './partials/date';
import { districtPT as district } from './partials/district';
import { dropDownPT as dropDown } from './partials/dropDown';
import { forgotPasswordPT as forgotPassword } from './partials/forgotPassword';
import { forgotPasswordSuccessPT as forgotPasswordSuccess } from './partials/forgotPasswordSuccess';
import { headerPT as header } from './partials/header';
import { layoutPT as layout } from './partials/layout';
import { loginPT as login } from './partials/login';
import { modalPT as modal } from './partials/modal';
import { notificationsPT as notifications } from './partials/notifications';
import { preferencesPT as preferences } from './partials/preferences';
import { recoverPasswordPT as recoverPassword } from './partials/recoverPassword';
import { systemConfigPT as systemConfig } from './partials/systemConfig';
import { tablePT as table } from './partials/table';
import { toastPT as toast } from './partials/toast';
import { sideMenuPT as sideMenu } from './partials/sideMenu';
import { productsPT as products } from './partials/products';
import { userGroupsPT as userGroups } from './partials/userGroups';
import { supplyPT as supply } from './partials/supply';
import { noPermissionPT as noPermission } from './partials/noPermission';
import { purchasingPT as purchasing } from './partials/purchasing';
import { contactPT as contact } from './partials/contact';

const translations = {
  translations: {
    breadCrumb,
    header,
    modal,
    common,
    layout,
    table,
    toast,
    dropDown,
    district,
    forgotPassword,
    forgotPasswordSuccess,
    recoverPassword,
    login,
    preferences,
    accountManager,
    systemConfig,
    notifications,
    adminBootys,
    admin,
    date,
    adminBootysLabel: 'Admin Bootys',
    adminLabel: 'Admin',
    sideMenu,
    products,
    userGroups,
    supply,
    noPermission,
    purchasing,
    contact,
  },
};

export default translations;
