import React from 'react';
import { Col, Row } from 'antd';
import { useEffect, useState } from 'react';
import {
  Card,
  Dropdown,
  Form,
  i18n,
  TextInput,
} from '../../../../../../Components';
import { patternLabelStyle } from '../../../../../../Components/Inputs/TextInput';
import { TextInputWithMask } from '../../../../../../Components/StateInputs/TextInputWithMask';
import { IPutConglomerateRequest } from '../../../../../../Data/Interfaces/request/Conglomerate/IConglomerate';
import { useCompanyListForDropdownAdminType } from '../../../../../../Hooks/Accountmanager/CompanyHooks';
import {
  useConglomerateEditType,
  useConglomerateGetType,
} from '../../../../../../Hooks/Accountmanager/ConglomerateHooks';
import { FooterButton } from '../../../../../../Layouts/CompanyFormLayout/Components/FooterButton';
import { PageLoading } from '../../../../../../Layouts/PageLoading';
import { IQueryListParams } from '../../../../../../Utils/Http';
import { ConglomerateDetailsSchema } from './ConglomerateDetails.schema';

interface IConglomerateEditForm {
  disabled: boolean;
  externalId: string;
  onDisabledEditButton: (value: boolean) => void;
}

export const ConglomerateEditForm: React.FC<IConglomerateEditForm> = ({
  disabled,
  externalId,
  onDisabledEditButton,
}) => {
  const [pagination, setPagination] = useState<IQueryListParams>({
    PageIndex: 0,
    PageSize: 50,
  });
  const { data, isLoading } = useCompanyListForDropdownAdminType(pagination);
  const { data: conglomerate, isLoading: conglomerateLoading } =
    useConglomerateGetType(externalId);
  const editConglomerate = useConglomerateEditType();

  useEffect(() => {
    const hasChanges = pagination?.filter?.[0]?.externalId !== externalId;

    externalId &&
      hasChanges &&
      setPagination({
        ...pagination,
        filter: [
          {
            filterName: 'conglomeradoExternalId',
            externalId: externalId,
          },
        ],
      });
  }, [externalId, pagination]);

  const onSubmit = async (values: IPutConglomerateRequest) => {
    const res = await editConglomerate.mutateAsync(values);
    if (res.success) onDisabledEditButton(true);
  };

  return (
    <PageLoading loading={isLoading || conglomerateLoading}>
      <Form
        onSubmit={onSubmit}
        initialValues={{
          conglomeradoExternalId: conglomerate?.externalId,
          nome: conglomerate?.nome,
          ativo: conglomerate?.ativo,
          empresaExternalIdMatriz: conglomerate?.empresas.find(
            (x) => x.tipoEmpresaId === 1
          )?.externalId,
        }}
        schema={ConglomerateDetailsSchema}
      >
        <Card
          title={i18n.t('adminBootys.editConglomerate.conglomerateData')}
          withoutCollapse
        >
          <Row gutter={[16, 0]}>
            <Col flex="80%">
              <TextInput
                name="nome"
                label={[
                  {
                    children: i18n.t(
                      'adminBootys.editConglomerate.conglomerateDataCard.conglomerateName'
                    ),
                    ...patternLabelStyle,
                  },
                ]}
                disabled={disabled}
              />
            </Col>
            <Col flex="20%">
              <TextInputWithMask
                value={conglomerate?.dataInclusao}
                mask="dataMask"
                label={[
                  {
                    children: i18n.t(
                      'adminBootys.editConglomerate.conglomerateDataCard.inclusionDate'
                    ),
                    ...patternLabelStyle,
                  },
                ]}
                disabled={true}
              />
            </Col>
          </Row>
          <Row gutter={[16, 0]}>
            <Col flex="28%">
              <Dropdown
                name="empresaExternalIdMatriz"
                items={data?.data?.map((x) => ({
                  id: x.externalId,
                  name: x.nomeFantasia,
                  avatar: {
                    name: x.nomeFantasia,
                  },
                }))}
                label={[
                  {
                    children: i18n.t(
                      'adminBootys.editConglomerate.conglomerateDataCard.headquarters'
                    ),
                    ...patternLabelStyle,
                  },
                ]}
                withoutMarginBottom
                onScrollEnd={() =>
                  setPagination({
                    ...pagination,
                    PageIndex: ++pagination.PageIndex,
                  })
                }
                disabled={disabled}
                isLoading={isLoading}
              />
            </Col>
          </Row>
          {!disabled && (
            <FooterButton
              cancelButtonClick={() => onDisabledEditButton(true)}
              loading={editConglomerate.isLoading}
            />
          )}
        </Card>
      </Form>
    </PageLoading>
  );
};
