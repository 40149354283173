import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { IPagination, Table } from '../../../../../Components/Table';
import { IChangeProductStatusRequest } from '../../../../../Data/Interfaces/request/Product/IProductRequest';
import { getCurrentCurrency } from '../../../../../Store/Currency/Currency.selector';
import { productCollums } from './product.columns';
import { history } from '../../../../../Store';
import { editTableStatus } from '../../../../../Utils/EditModalTableUtils';
import { i18n } from '../../../../../Components';
import { DeleteModalOnTable } from '../../../../../Utils/DeleteModalUtils';
import { BodyDeleteModal } from './ProductForm.utils';
import { useParams } from 'react-router-dom';
import { ContainerPage } from '../../../../../Layouts/ContainerPage';
import {
  FETCH_LIST_PRODUCTS_GROUP,
  useGroupGetProductsList,
  useProductDelete,
  useProductStatusEdit,
} from '../../../../../Hooks/Supply/ProductHooks';
import { useQueryClient } from '@tanstack/react-query';
import { PageLoading } from '../../../../../Layouts/PageLoading';

export const List: React.FC = () => {
  const { externalId } = useParams<{ externalId: string }>();
  const [selectedRowsOnTable, setSelectedRowsOnTable] = useState<any>([]);
  const [pagination, setPagination] = useState<IPagination>({
    PageIndex: 0,
    PageSize: 10,
    Sorter: { column: 'descricao', direction: 'ASC' },
  });
  const currentCurrency = useSelector(getCurrentCurrency);
  const editProductStatus = useProductStatusEdit();

  const { t } = useTranslation();

  const { data: productList, isLoading } = useGroupGetProductsList(
    pagination,
    externalId
  );

  const queryClient = useQueryClient();

  const patchProductStatus = async (values: IChangeProductStatusRequest) => {
    var body: IChangeProductStatusRequest = { ...values };

    const res = await editProductStatus.mutateAsync({
      ...body,
    });
    if (res.success) queryClient.invalidateQueries([FETCH_LIST_PRODUCTS_GROUP]);
  };

  const deleteProduct = useProductDelete();

  return (
    <>
      <PageLoading loading={isLoading}>
        <ContainerPage>
          <Table
            columns={productCollums(t, currentCurrency)}
            data={
              productList?.data?.map((x) => ({
                key: `table_products_${x.externalId}`,
                ...x,
              })) || []
            }
            headerOptions={{
              nameKey: 'descricao',
              hasSearch: true,
              searchPlaceholder: t('products.productList.searchPlaceholder'),
              newButton: {
                label: t('products.productList.newButton'),
                onClick: () => history.push(`/inventory/products/create`),
                permission: {
                  permission: 3003,
                  tooltip: 'noPermission.supply.products.create',
                },
              },
              deleteButton: {
                permission: {
                  permission: 3012,
                  tooltip: 'noPermission.supply.groups.delete',
                },
              },
              editButton: {
                options: [
                  {
                    label: t('common.status'),
                    isStatusOnly: true,
                  },
                ],
                permission: {
                  permission: 3011,
                  tooltip: 'noPermission.supply.groups.edit',
                },
              },
            }}
            selectedRowsOnTable={(x) => setSelectedRowsOnTable(x)}
            rowsOptions={{
              onRowDoubleClick: (x) =>
                history.push(`/inventory/products/${x.externalId}/details`),
              permission: {
                permission: 3002,
                tooltip: 'noPermission.supply.products.detail',
              },
            }}
            editOptions={editTableStatus()}
            loading={isLoading}
            hasSelection={true}
            pagination={
              productList
                ? {
                    Count: productList.count,
                    PageIndex: productList.pageIndex,
                    PageSize: productList.pageSize,
                  }
                : false
            }
            onEdit={(user, status) =>
              patchProductStatus({
                produtosExternalId: user.map((x) => x.externalId),
                ativo: status.status === 'success' ? true : false,
              })
            }
            onRefetch={(pagination) => {
              setPagination(pagination);
            }}
            deleteModal={
              selectedRowsOnTable && {
                title: `${i18n.t('common.delete')} ${i18n.t(
                  'common.product'
                )} "${selectedRowsOnTable[0]?.descricao}"?`,
                body:
                  selectedRowsOnTable.length === 1 ? (
                    <BodyDeleteModal
                      selectedRowsOnTable={selectedRowsOnTable}
                    />
                  ) : (
                    DeleteModalOnTable(
                      selectedRowsOnTable,
                      `${t('modal.deleteModal.many.title')} ${t(
                        'common.theProducts'
                      )}:`,
                      'descricao',
                      t('common.products')
                    )
                  ),
                buttons: {
                  okButtonColor: 'status-danger-base',
                },
              }
            }
            onDelete={(product) =>
              product.map(async (x) => {
                const res = await deleteProduct.mutateAsync({
                  produtosExternalId: product.map((x) => x?.externalId),
                });
                if (res.success)
                  queryClient.invalidateQueries([FETCH_LIST_PRODUCTS_GROUP]);
              })
            }
          />
        </ContainerPage>
      </PageLoading>
    </>
  );
};
