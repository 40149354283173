import { uniqBy } from 'lodash';
import { ILossesState } from './interfaces/ILossesState';
import { LossesActionKeys, LossesActionUnion } from './Losses.actions';

const initialData: ILossesState = {
  isLoading: false,
  isEditMode: false,
};

export const lossesReducer = (
  state = initialData,
  action: LossesActionUnion
): ILossesState => {
  switch (action.type) {
    case LossesActionKeys.SET_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case LossesActionKeys.LIST_LOSSES_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case LossesActionKeys.LIST_LOSSES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        list: action.payload,
      };
    case LossesActionKeys.LIST_REASON_LOSS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case LossesActionKeys.LIST_REASON_LOSS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        listReasonLoss: {
          ...action.payload,
          data: uniqBy(
            [...(state?.listReasonLoss?.data || []), ...action.payload.data],
            'ExternalId'
          ),
        },
      };
    case LossesActionKeys.LIST_PRODUCT_LOSS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case LossesActionKeys.LIST_PRODUCT_LOSS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        listProductLoss: action.payload,
      };
    case LossesActionKeys.SET_IS_LOSS_EDIT_MODE:
      return {
        ...state,
        isEditMode: action.payload,
      };

    case LossesActionKeys.UPDATE_LOSS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case LossesActionKeys.UPDATE_LOSS_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default lossesReducer;
