import { Text, i18n } from '../../../Components';
import { IUserPreferencesResponse } from '../../../Data/Interfaces/response/User/IGetUserReponse';
import { ConvertDate } from '../../../Utils/DateUtils';
import { currencyFormater } from '../../../Utils/Currency';
import { ICurrencyResponse } from '../../../Data/Interfaces/response/Currency/ICurrencyResponse';
import { Status } from '../Components/Status';
import { IColumn } from '../../../Components/Table';

export const purchaseOrderColumn = (
  t: any,
  currentMeUser?: IUserPreferencesResponse,
  currentCurrency?: ICurrencyResponse
): IColumn[] => [
  {
    title: t('purchasing.purchaseOrder.list.purchaseOrderNumber'),
    width: '7%',
    dataIndex: 'sequenciaGroupTenant',
    key: 'sequenciaGroupTenant',
    sortColumn: 'sequenciaGroupTenant',
    sorter: true,
    isHighlightable: true,
    render: (x: any) => (
      <Text type="ui-tiny-bold" color="text-50">
        {`#${x}`}
      </Text>
    ),
  },

  {
    title: t('purchasing.purchaseOrder.list.provider'),
    width: '30%',
    dataIndex: 'fornecedor',
    key: 'fornecedor',
    sortColumn: 'fornecedor',
    sorter: true,
    isHighlightable: true,
    render: (x: any, row: any) => (
      <Text type="ui-tiny-content" color="text-50">
        {`${row?.fornecedor}`}
      </Text>
    ),
  },
  {
    title: t('purchasing.purchaseOrder.list.releaseDate'),
    width: '13%',
    dataIndex: 'data',
    key: 'data',
    sortColumn: 'data',
    sorter: true,
    isHighlightable: true,
    render: (x: string) => (
      <Text type="ui-tiny-content" color="text-50">
        {`${ConvertDate(x, currentMeUser?.preferencias.padraoData)} ${t(
          'common.dateBreakApart'
        )} ${ConvertDate(x, currentMeUser?.preferencias.padraoHora)}`}
      </Text>
    ),
  },
  {
    title: t('purchasing.purchaseOrder.list.deliveryForecastDate'),
    width: '10%',
    dataIndex: 'previsaoEntrega',
    key: 'previsaoEntrega',
    sortColumn: 'previsaoEntrega',
    sorter: true,
    isHighlightable: true,
    render: (x: string) => (
      <Text type="ui-tiny-content" color="text-50">
        {`${ConvertDate(x, currentMeUser?.preferencias.padraoData)}`}
      </Text>
    ),
  },

  {
    title: t('purchasing.purchaseOrder.list.amount'),
    dataIndex: 'valorTotalPedidos',
    key: 'valorTotalPedidos',
    render: (x: any, row: any) => (
      <Text type={'ui-tiny-content'}>
        {currencyFormater(x, currentCurrency?.tipoMoedaId, row?.unidadeMedida)}
      </Text>
    ),
  },
  {
    title: t('purchasing.purchaseOrder.list.status'),
    dataIndex: 'status',
    key: 'status',
    render: (status: any) => (
      <Status typePurchase={status}>
        {i18n.t(`purchasing.purchaseOrder.list.statusType.${status}`)}
      </Status>
    ),
  },
];
