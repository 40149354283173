import { IUnitMeasurementState } from './interfaces/IUnitMeasurementState';
import {
  UnitMeasurementActionKeys,
  UnitMeasurementActionUnion,
} from './UnitMeasurement.actions';

const initialData: IUnitMeasurementState = {
  isLoading: false,
};

export const UnitMeasurementReducer = (
  state = initialData,
  action: UnitMeasurementActionUnion
): IUnitMeasurementState => {
  switch (action.type) {
    case UnitMeasurementActionKeys.LIST_UNIT_MEASUREMENT_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: undefined,
      };
    case UnitMeasurementActionKeys.LIST_UNIT_MEASUREMENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: undefined,
        currentListUnitMeasurement: action.payload,
      };
    case UnitMeasurementActionKeys.LIST_UNIT_MEASUREMENT_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default UnitMeasurementReducer;
