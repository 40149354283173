import { call, put } from 'redux-saga/effects';
import { CompanyAPI } from '../../Data/API/Accountmanager/CompanyApi';
import {
  messageErrorToast,
  messageSucessToast,
} from '../../Utils/MessageErrorToast';
import {
  CompanyActions,
  DeleteCompanyRequestAction,
  ChangeResponsibilyUserRequestAction,
  ChangeCompanyPayingRequestAction,
} from './Company.actions';
import { i18n } from '../../Components';

export function* getCurrency() {
  try {
    const { data } = yield call(CompanyAPI.getCurrency);

    yield put(CompanyActions.getCurrencySuccess(data));
  } catch (error: any) {
    messageErrorToast(error);

    yield put(CompanyActions.getCurrencyFailure(error.message));
  }
}

export function* deleteCompany({ payload }: DeleteCompanyRequestAction) {
  try {
    yield call(CompanyAPI.deleteCompany, payload);
    yield put(CompanyActions.setLoading(false));
  } catch (error: any) {
    yield put(CompanyActions.toggleCouldNotDelete());
    yield put(CompanyActions.setLoading(false));
  }
}

export function* changeResponsibilytiUser({
  payload,
}: ChangeResponsibilyUserRequestAction) {
  try {
    const { data } = yield call(CompanyAPI.changeResponsibilytiUser, payload);

    payload.empresasExternalId.length > 1
      ? messageSucessToast(
          i18n.t(
            'adminBootys.createCompany.responsibleUserCard.responsiblesUserChangeSuccess'
          )
        )
      : messageSucessToast(
          i18n.t(
            'adminBootys.createCompany.responsibleUserCard.responsibleUserChangeSuccess'
          )
        );
    yield put(CompanyActions.changeResponsibilytiUserSuccess(data));
  } catch (error: any) {
    messageErrorToast(error);

    yield put(CompanyActions.changeResponsibilytiUserFailure(error.message));
  } finally {
    payload.helpers?.setSubmitting(false);
  }
}

export function* changeCompanyPaying({
  payload,
}: ChangeCompanyPayingRequestAction) {
  try {
    yield call(CompanyAPI.changeCompanyPaying, payload);

    yield put(CompanyActions.setLoading(false));
    messageSucessToast(i18n.t('adminBootys.createCompany.billingCard.success'));
  } catch (error: any) {
    messageErrorToast(error);

    yield put(CompanyActions.changeCompanyPayingFailure(error.message));
  } finally {
    payload.helpers.setSubmitting(false);
  }
}
