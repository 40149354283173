import { AxiosResponse } from 'axios';
import { Http, IQueryListParams } from '../../../Utils/Http';
import { IQueryParams } from '../../../Components/Table';
import {
  IProductListResponse,
  IProductGroupListResponse,
  IProductSubGroupListResponse,
  IProductGroupResponse,
  IProductSubGroupResponse,
  IUnitMeasureResponse,
  IProductResponse,
  ISubGroupsOfGroupListResponse,
  IProductsOfGroupListResponse,
  ISubGroupsByGroupListResponse,
} from '../../Interfaces/response/Product/IProductRespose';
import {
  IChangeProductStatusRequest,
  ICreateProductGroupRequest,
  ICreateSubGroupProductRequest,
  IDeleteProductGroupRequest,
  IEditProductGroupRequest,
  IDeleteProductSubGroupRequest,
  IEditProductSubGroupRequest,
  IEditProductRequest,
  IDeleteProductRequest,
  IEditFinancialProductRequest,
  IUnitMeasureClassProductRequest,
} from '../../Interfaces/request/Product/IProductRequest';
import { ICreateProductRequest } from '../../Interfaces/request/Product/ICreateProductRequest';
import { QueryFunctionContext } from '@tanstack/query-core';
import { IBaseResponse } from '../../Interfaces/response/Base/IBase';

export class ProductAPI {
  public static async getProduct(
    context: QueryFunctionContext
  ): Promise<IProductResponse | null> {
    if (!context.queryKey[1]) {
      return null;
    }
    return await Http.fetcher<IProductResponse>(
      `/pharma/estoque/v1/Produto/${context.queryKey[1]}`
    );
  }

  public static async productList(
    context: QueryFunctionContext
  ): Promise<IProductListResponse> {
    return await Http.fetcher<IProductListResponse>(
      Http.AddQueryParams(
        '/pharma/estoque/v1/Produto/ListarDetalhado',
        context.queryKey[1] as IQueryListParams
      )
    );
  }

  public static async deleteProduct(body: IDeleteProductRequest) {
    const res = await Http.fetcher(
      `/pharma/estoque/v1/Produto/RemoverProdutos`,
      {
        method: 'DELETE',
        body: JSON.stringify(body),
      }
    );

    body.callback && body.callback();

    if (!res) return [];

    return res;
  }

  public static async productStatus(body: IChangeProductStatusRequest) {
    const res = await Http.fetcher(
      `/pharma/estoque/v1/Produto/AtualizarStatus`,
      {
        method: 'PUT',
        body: JSON.stringify(body),
      }
    );

    body.callback && body.callback();

    if (!res) return [];

    return res;
  }

  public static async isProductNameAlreadyRegistred(
    body: IQueryParams
  ): Promise<AxiosResponse<boolean>> {
    const axios = await Http.axios();
    return axios.get<boolean>(
      Http.AddODataQueryParams('/estoque/odata/v1/Produto', body)
    );
  }

  public static async productNameValidation(body: {
    descricao: string;
  }): Promise<IBaseResponse<any>> {
    return await Http.fetcher('/pharma/estoque/v1/Produto/ValidarDescricao', {
      method: 'POST',
      body: JSON.stringify(body),
    });
  }

  public static async inventoryListIncrementing(
    body: IQueryParams
  ): Promise<AxiosResponse<IProductListResponse>> {
    const axios = await Http.axios();
    return axios.get<IProductListResponse>(
      Http.AddODataQueryParams('/estoque/odata/v1/LocalEstoque', body)
    );
  }

  public static async groupList(
    context: QueryFunctionContext
  ): Promise<IProductGroupListResponse> {
    return await Http.fetcher<IProductGroupListResponse>(
      Http.AddQueryParams(
        '/pharma/estoque/v1/Grupo/ListarDetalhado',
        context.queryKey[1] as IQueryListParams
      )
    );
  }

  public static async subGroupsOfGroupsList(
    context: QueryFunctionContext
  ): Promise<ISubGroupsOfGroupListResponse> {
    let url = Http.AddQueryParams(
      `/pharma/estoque/v1/Grupo/ListarDetalhadoGrupoSubgrupos`,
      context.queryKey[1] as IQueryListParams
    );

    url += `&grupoExternalId=${context.queryKey[2]}`;
    return await Http.fetcher<ISubGroupsOfGroupListResponse>(url);
  }

  public static async productsOfGroupsList(
    context: QueryFunctionContext
  ): Promise<IProductsOfGroupListResponse> {
    let url = Http.AddQueryParams(
      `/pharma/estoque/v1/Grupo/ListarDetalhadoGrupoProdutos`,
      context.queryKey[1] as IQueryListParams
    );

    url += `&grupoExternalId=${context.queryKey[2]}`;
    return await Http.fetcher<IProductsOfGroupListResponse>(url);
  }

  public static async getGroupListDropdown(
    context: QueryFunctionContext
  ): Promise<IProductGroupListResponse> {
    return await Http.fetcher<IProductGroupListResponse>(
      Http.AddQueryParams(
        '/pharma/estoque/v1/Grupo/Listar',
        context.queryKey[1] as IQueryListParams
      )
    );
  }

  public static async createProduct(body: ICreateProductRequest) {
    const res = await Http.fetcher('/pharma/estoque/v1/Produto', {
      method: 'POST',
      body: JSON.stringify(body),
    });

    body.callback && body.callback();

    if (!res) return [];

    return res;
  }

  public static async editProduct(body: IEditProductRequest) {
    const res = await Http.fetcher(`/pharma/estoque/v1/Produto`, {
      method: 'PUT',
      body: JSON.stringify(body),
    });

    if (!res) return [];

    return res;
  }

  public static async editProductFinancial(body: IEditFinancialProductRequest) {
    const res = await Http.fetcher(
      `/pharma/estoque/v1/Produto/AtualizarFinanceiro`,
      {
        method: 'PUT',
        body: JSON.stringify(body),
      }
    );

    if (!res) return [];

    return res;
  }

  public static async editFinancialProduct(
    body: IEditFinancialProductRequest
  ): Promise<AxiosResponse> {
    const axios = await Http.axios();
    return axios.put('/pharma/estoque/v1/Produto/AtualizarFinanceiro', body);
  }

  public static async createSubGroup(body: ICreateSubGroupProductRequest) {
    const res = await Http.fetcher('/pharma/estoque/v1/SubGrupo', {
      method: 'POST',
      body: JSON.stringify(body),
    });

    body.callback && body.callback();

    if (!res) return [];

    return res;
  }

  public static async createGroup(body: ICreateProductGroupRequest) {
    const res = await Http.fetcher('/pharma/estoque/v1/Grupo', {
      method: 'POST',
      body: JSON.stringify(body),
    });

    body.callback && body.callback();

    if (!res) return [];

    return res;
  }

  public static async subGroupList(
    context: QueryFunctionContext
  ): Promise<IProductSubGroupListResponse> {
    return await Http.fetcher<IProductSubGroupListResponse>(
      Http.AddQueryParams(
        '/pharma/estoque/v1/Subgrupo/ListarDetalhado',
        context.queryKey[1] as IQueryListParams
      )
    );
  }

  public static async productsOfSubGroupsList(
    context: QueryFunctionContext
  ): Promise<ISubGroupsOfGroupListResponse> {
    let url = Http.AddQueryParams(
      `/pharma/estoque/v1/Produto/ListarDetalhadoPorSubgrupo`,
      context.queryKey[1] as IQueryListParams
    );

    url += `&subGrupoExternalId=${context.queryKey[2]}`;
    return await Http.fetcher<ISubGroupsOfGroupListResponse>(url);
  }

  public static async getSubGroupByGroupDropdown(
    context: QueryFunctionContext
  ): Promise<ISubGroupsByGroupListResponse | undefined> {
    if (!context.queryKey[2]) return;
    let url = Http.AddQueryParams(
      `/pharma/estoque/v1/SubGrupo/Listar/`,
      context.queryKey[1] as IQueryListParams
    );

    url += `&grupoExternalId=${context.queryKey[2]}`;
    return await Http.fetcher<ISubGroupsByGroupListResponse>(url);
  }

  public static async getSubGroupListDropdown(
    context: QueryFunctionContext
  ): Promise<IProductSubGroupListResponse> {
    return await Http.fetcher<IProductSubGroupListResponse>(
      Http.AddQueryParams(
        '/pharma/estoque/v1/SubGrupo/Listar',
        context.queryKey[1] as IQueryListParams
      )
    );
  }

  public static async getproductSubGroup(
    context: QueryFunctionContext
  ): Promise<IProductSubGroupResponse> {
    return await Http.fetcher<IProductSubGroupResponse>(
      `/pharma/estoque/v1/SubGrupo/${context.queryKey[1]}`
    );
  }

  public static async getProductGroup(
    context: QueryFunctionContext
  ): Promise<IProductGroupResponse> {
    return await Http.fetcher<IProductGroupResponse>(
      `/pharma/estoque/v1/Grupo/${context.queryKey[1]}`
    );
  }

  public static async productGroupNameValidation(body: {
    descricao: string;
  }): Promise<IBaseResponse<any>> {
    return await Http.fetcher(
      '/pharma/estoque/v1/Grupo/ValidarDescricaoGrupo',
      {
        method: 'POST',
        body: JSON.stringify(body),
      }
    );
  }

  public static async deleteProductGroup(body: IDeleteProductGroupRequest) {
    const res = await Http.fetcher(`/pharma/estoque/v1/Grupo`, {
      method: 'DELETE',
      body: JSON.stringify(body),
    });

    body.callback && body.callback();

    if (!res) return [];

    return res;
  }

  public static async deleteProductSubGroup(
    body: IDeleteProductSubGroupRequest
  ) {
    const res = await Http.fetcher(`/pharma/estoque/v1/SubGrupo`, {
      method: 'DELETE',
      body: JSON.stringify(body),
    });

    body.callback && body.callback();

    if (!res) return [];

    return res;
  }

  public static async editProductGroup(body: IEditProductGroupRequest) {
    const res = await Http.fetcher(`/pharma/estoque/v1/Grupo`, {
      method: 'PUT',
      body: JSON.stringify(body),
    });

    body.callback && body.callback();

    if (!res) return [];

    return res;
  }

  public static async editProductSubGroup(body: IEditProductSubGroupRequest) {
    const res = await Http.fetcher(`/pharma/estoque/v1/SubGrupo`, {
      method: 'PUT',
      body: JSON.stringify(body),
    });

    body.callback && body.callback();

    if (!res) return [];

    return res;
  }

  public static async isProductSubgroupNameAlreadyRegistred(
    body: IQueryParams
  ): Promise<AxiosResponse<boolean>> {
    const axios = await Http.axios();
    return axios.get<boolean>(
      Http.AddODataQueryParams('/estoque/odata/v1/SubGrupo', body)
    );
  }

  public static async getListProductDropDown(
    context: QueryFunctionContext
  ): Promise<IProductGroupListResponse | null> {
    if (!context.queryKey[1]) return null;
    return await Http.fetcher<IProductGroupListResponse>(
      Http.AddQueryParams(
        '/pharma/estoque/v1/Produto/Listar',
        context.queryKey[1] as IQueryListParams
      )
    );
  }

  public static async getLisUnitMeasureDropDown(
    body: IUnitMeasureClassProductRequest
  ): Promise<AxiosResponse<IUnitMeasureResponse>> {
    const axios = await Http.axios();
    return axios.get<IUnitMeasureResponse>(
      `/pharma/estoque/v1/UnidadeMedida/ListarUnidadesPorClasseProduto/${body.classType}`
    );
  }

  public static async productSubGroupNameValidation(body: {
    descricao: string;
    grupoExternalId: string;
  }): Promise<IBaseResponse<any>> {
    return await Http.fetcher('/pharma/estoque/v1/SubGrupo/ValidarDescricao', {
      method: 'POST',
      body: JSON.stringify(body),
    });
  }
}
