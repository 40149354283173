import React from 'react';
import { ManyAvatars } from '../../../../Components/ManyAvatars';
import { Text } from '../../../../Components';
import { IListUserData } from '../../../../Data/Interfaces/response/User/IGetUserReponse';

export const deleteModalTitle = (
  selectedRowsOnTable: IListUserData[],
  t: any
) => {
  return selectedRowsOnTable.length === 1
    ? `${t('admin.userList.deleteUserModal.title.delete')} "${
        selectedRowsOnTable[0]?.nome
      } ${selectedRowsOnTable[0]?.sobrenome}"?`
    : `${t('admin.userList.deleteUserModal.title.delete')} ${
        selectedRowsOnTable.length
      } ${t('admin.userList.deleteUserModal.title.users')}?`;
};

export const deleteModalBody = (
  selectedRowsOnTable: IListUserData[],
  t: any
) => (
  <div style={{ padding: '20px' }}>
    <ManyAvatars
      users={selectedRowsOnTable.map((user) => ({
        name: user.nome,
        lastName: user.sobrenome,
      }))}
    ></ManyAvatars>
    <>
      <div style={{ display: 'flex', alignItems: 'baseline' }}>
        <Text
          color="text-50"
          type="ui-tiny-content"
          children={`${t('modal.deleteModal.single.avatar.footer')} `}
        />
        <Text
          color="status-danger-base"
          type="ui-tiny-bold"
          children={`${t('modal.deleteModal.single.avatar.footer2')} `}
        />
        {selectedRowsOnTable.length > 1 ? (
          <>
            <Text
              color="text-50"
              type="ui-tiny-bold"
              children={selectedRowsOnTable.length}
            />
            <Text
              color="text-50"
              type="ui-tiny-bold"
              children={` ${t('admin.userList.deleteUserModal.title.users')}?`}
            />
          </>
        ) : (
          <>
            <Text
              color="text-50"
              type="ui-tiny-content"
              children={`${t('modal.deleteModal.single.avatar.footer3')} `}
            />
            <Text
              color="text-50"
              type="ui-tiny-bold"
              children={`${selectedRowsOnTable[0]?.nome} ${selectedRowsOnTable[0]?.sobrenome}?`}
            />
          </>
        )}
      </div>
      <div style={{ display: 'flex' }}>
        <Text
          color="text-50"
          type="ui-tiny-content"
          children={t('modal.deleteModal.single.footer')}
        />
      </div>
    </>
  </div>
);
