import { INumberInput, NumberInput } from '../NumberInput';

export const CurrencyInput = (
  props: Omit<
    INumberInput,
    | 'fixedDecimalScale'
    | 'withCurrencyPrefix'
    | 'decimalScale'
    | 'withDecimalSeparator'
    | 'withThousandSeparator'
  >
) => (
  <NumberInput
    {...props}
    fixedDecimalScale
    decimalScale={2}
    withCurrencyPrefix
    withDecimalSeparator
    withThousandSeparator
  />
);
