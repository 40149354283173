import { IGlobalReducerState } from '../Base/Interfaces/IGlobalReducerState';

export const getListConglomerate = (state: IGlobalReducerState) =>
  state.conglomerate.currentListConglomerate;
export const getListAllConglomerate = (state: IGlobalReducerState) =>
  state.conglomerate.currentListAllConglomerate;
export const getConglomerate = (state: IGlobalReducerState) =>
  state.conglomerate.currentConglomerate;
export const getCompanyForConglomerate = (state: IGlobalReducerState) =>
  state.conglomerate.currentCompanyForConglomerate;
export const getCompanyForConglomerateIncrementing = (
  state: IGlobalReducerState
) => state.conglomerate.currentCompanyForConglomerateIncremented;
export const conglomerateLoading = (state: IGlobalReducerState) =>
  state.conglomerate.isLoading;
export const currentConglomerateParamsId = (state: IGlobalReducerState) =>
  state.conglomerate.currentConglomerateParamsId;

export const isStatusConfirmModalVisible = (state: IGlobalReducerState) =>
  state.conglomerate.isStatusConfirmModalVisible;

export const getStatusChangeNewValue = (state: IGlobalReducerState) =>
  state.conglomerate.statusChangeNewValue;
