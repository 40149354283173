import { Col, Row } from 'antd';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Dropdown } from '../../../../../../Components';
import { TextInputNumber } from '../../../../../../Components/Inputs/TextInputNumber';
import { useStockLocationtGetDropdown } from '../../../../../../Hooks/Supply/SupplyHooks';
import { useUnitMeasurementByClassGetDropdown } from '../../../../../../Hooks/Supply/UnitMeasurementHooks';
import { useProductGet } from '../../../../../../Hooks/Supply/ProductHooks';
import { useFormikContext } from 'formik';

interface IlistunitsMeasurementsBalance {
  typeClassProduct?: number | undefined;
}

export const InitialBalanceCard: FC<IlistunitsMeasurementsBalance> = ({
  typeClassProduct,
}) => {
  const { t } = useTranslation();
  const form: any = useFormikContext();

  const { data: storageLocationList } = useStockLocationtGetDropdown({
    PageIndex: 1,
    PageSize: 10,
  });

  const { data: listunitsMeasurements } = useUnitMeasurementByClassGetDropdown(
    {
      PageIndex: 0,
      PageSize: 10,
    },
    typeClassProduct
  );

  const { data: unitsMeasurementsDefault } = useProductGet(
    form.values.produtoExternalId
  );

  return (
    <Card
      title={t('supply.lot.create.titleInitialBalanceInformation')}
      withoutCollapse={true}
    >
      <Row gutter={[16, 0]}>
        <Col span={6}>
          <Dropdown
            items={storageLocationList?.data?.map((x: any) => ({
              id: x.externalId,
              name: x.descricao,
              code: x.nomeEmpresa,
            }))}
            name="saldoInicial.localEstoqueExternalId"
            label={t('supply.lot.create.stockLocation')}
            placeHolder={t('supply.lot.placeholder.stockLocation')}
          />
        </Col>

        <Col span={4}>
          <TextInputNumber
            name="saldoInicial.quantidade"
            label={t('supply.lot.create.quantityInitial')}
            placeHolder={t('supply.lot.placeholder.quantityInitial')}
            dropdownRight={{
              name: 'unitOfMeasure',
              defaultValue: unitsMeasurementsDefault?.unidadeEstoqueId,
              options:
                listunitsMeasurements?.data.map((x) => ({
                  id: x.id,
                  content: `${x.unidadeAbreviacao}(${x.descricao})`,
                })) || [],
            }}
          />
        </Col>
      </Row>
    </Card>
  );
};
