import React, { CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../../..';
import { Color, Colors } from '../../../../Utils/ColorUtils';
import { isFormError } from '../../../../Utils/ErrorUtils';
import { IFChildren } from '../../../Form';

import styles from './Footer.module.scss';

export interface IFooter {
  buttonCancelWidth?: number;
  buttonOkWidth?: number;
  okButtonClassName?: string;
  onClose?: (action: 'cancel' | 'ok') => void;
  htmlType?: 'submit' | 'button' | 'reset';
  okButtonName?: string;
  okButtonColor?: Color;
  cancelButtonName?: string;
  onOkClick?: () => void;
  onCancelClick?: () => void;
  className?: string;
  disabledButton?: boolean;
  withForm?: boolean;
  withoutOkButton?: boolean;
  style?: CSSProperties;

  propsForm?: IFChildren;

  loadingOkButton?: boolean;
  withoutOkCancel?: boolean;
}

export const Footer: React.FC<IFooter> = ({
  buttonCancelWidth,
  buttonOkWidth,
  cancelButtonName,
  okButtonName,
  okButtonColor,
  okButtonClassName,
  onClose,
  onOkClick,
  onCancelClick,
  className,
  htmlType,
  disabledButton,
  withForm,
  withoutOkButton,
  style,
  propsForm,
  loadingOkButton,
  withoutOkCancel,
}) => {
  const { t } = useTranslation();

  return (
    <div className={`${className} ${styles['footer']}`} style={style}>
      <div
        style={
          buttonCancelWidth
            ? { width: buttonCancelWidth, marginRight: 15 }
            : { marginRight: 0 }
        }
      >
        {!withoutOkCancel && (
          <Button
            className={styles['buttonCancel']}
            type="secondary"
            children={t(cancelButtonName || 'modal.cancel')}
            onClick={() => {
              if (onCancelClick) {
                onClose && onClose('cancel');
                onCancelClick && onCancelClick();
              } else !!withForm && propsForm?.resetForm();
            }}
          />
        )}
      </div>

      {!withoutOkButton && (
        <div
          style={
            buttonOkWidth
              ? { width: buttonOkWidth, marginLeft: 15 }
              : { marginLeft: 10 }
          }
        >
          <Button
            className={`${okButtonClassName} ${styles['buttonOk']}`}
            type={okButtonColor ? 'danger' : 'primary'}
            children={t(okButtonName || 'modal.save')}
            onClick={() => onOkClick && onOkClick()}
            style={
              okButtonColor && {
                backgroundColor: `${Colors[okButtonColor]} !important`,
              }
            }
            htmlType={htmlType}
            disabled={
              disabledButton ||
              (!!withForm &&
                (!propsForm?.dirty || isFormError(propsForm?.errors)))
            }
            loading={loadingOkButton}
          />
        </div>
      )}
    </div>
  );
};
