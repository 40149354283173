import React, { ReactNode, FC } from 'react';

import styles from './PageLayout.module.scss';
import BreadCrumbLayout from '../BreadCrumbLayout';

export interface IPageLayout {
	children: ReactNode;
}

export const PageLayout: FC<IPageLayout> = ({ children }) => (
	<div className={styles['container']}>
		<BreadCrumbLayout />
		{children}
	</div>
)