import { IPagination } from '../../Components/Table';
import { ICreateBalanceAdjustments } from '../../Data/Interfaces/request/BalanceAdjustments/ICreateBalanceAdjustments';
import { IBalanceAdjustmentList, IBalanceAdjustmentsResponse } from '../../Data/Interfaces/response/BalanceAdjustments/IBalanceAdjustmentsResponse';
import { createAction, ActionsUnion, Action } from '../Base/actionHelpers';

export enum BalanceAdjustmentsActionKeys {
  SET_LOADING = '[BALANCE_ADJUSTMENTS] SET_LOADING',

  BALANCE_ADJUSTMENTS_LIST_REQUEST = '[BALANCE_ADJUSTMENTS] BALANCE_ADJUSTMENTS_LIST_REQUEST',
  BALANCE_ADJUSTMENTS_LIST_SUCCESS = '[BALANCE_ADJUSTMENTS] BALANCE_ADJUSTMENTS_LIST_SUCCESS',
  BALANCE_ADJUSTMENTS_LIST_FALUIRE = '[BALANCE_ADJUSTMENTS] BALANCE_ADJUSTMENTS_LIST_FALUIRE',

  GET_SINGLE_BALANCE_ADJUSTMENT = '[BALANCE_ADJUSTMENTS] GET_SINGLE_BALANCE_ADJUSTMENT',
  GET_SINGLE_BALANCE_ADJUSTMENT_SUCCESS = '[BALANCE_ADJUSTMENTS] GET_SINGLE_BALANCE_ADJUSTMENT_SUCCESS',
  CREATE_BALANCE_ADJUSTMENT = '[BALANCE_ADJUSTMENTS] CREATE_BALANCE_ADJUSTMENT',
}

export const BalanceAdjustmentsActions = {
  setLoading: (loading: boolean) =>
    createAction(BalanceAdjustmentsActionKeys.SET_LOADING, loading),

  listBalanceAdjustment: (body: IPagination): listBalanceAdjustmentAction =>
    createAction(BalanceAdjustmentsActionKeys.BALANCE_ADJUSTMENTS_LIST_REQUEST, body),
  listBalanceAdjustmentSuccess: (res: IBalanceAdjustmentList): ListBalanceAdjustmentSuccessAction =>
    createAction(BalanceAdjustmentsActionKeys.BALANCE_ADJUSTMENTS_LIST_SUCCESS, res),
  listBalanceAdjustmentFailure: (error: string): ListBalanceAdjustmentFaluireAction =>
    createAction(BalanceAdjustmentsActionKeys.BALANCE_ADJUSTMENTS_LIST_FALUIRE, error),

  getBalanceAdjustment: (id: string) =>
    createAction(
      BalanceAdjustmentsActionKeys.GET_SINGLE_BALANCE_ADJUSTMENT,
      id
    ),
  getBalanceAdjustmentSuccess: (body: IBalanceAdjustmentsResponse) =>
    createAction(
      BalanceAdjustmentsActionKeys.GET_SINGLE_BALANCE_ADJUSTMENT_SUCCESS,
      body
    ),

  createBalanceAdjustment: (body: ICreateBalanceAdjustments) =>
    createAction(BalanceAdjustmentsActionKeys.CREATE_BALANCE_ADJUSTMENT, body),
};

export type BalanceAdjustmentsActionUnion = ActionsUnion<
  typeof BalanceAdjustmentsActions
>;

export type listBalanceAdjustmentAction = Action<
BalanceAdjustmentsActionKeys.BALANCE_ADJUSTMENTS_LIST_REQUEST,
  IPagination
>;

export type ListBalanceAdjustmentSuccessAction = Action<
BalanceAdjustmentsActionKeys.BALANCE_ADJUSTMENTS_LIST_SUCCESS,
IBalanceAdjustmentList
>;

export type ListBalanceAdjustmentFaluireAction = Action<
BalanceAdjustmentsActionKeys.BALANCE_ADJUSTMENTS_LIST_FALUIRE,
  string
>;
