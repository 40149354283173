import { isNil } from 'lodash';
import { DateTime, Settings } from 'luxon';
import { datePatternType } from '../Data/Interfaces/response/User/IGetUserReponse';
import { Timezones } from '../Static/ts/timezones';

export const getAllTimezones = () => Timezones;

export const getDateFromISO = (date: string) => DateTime.fromISO(date);

export const formatDate = (date: string, format: string) =>
  DateTime.fromISO(date).toFormat(format);

export const formatTime = (date: string) =>
  DateTime.fromISO(date).toFormat('HH:mm');

export const now = (timezone?: string) => {
  const now = DateTime.now();

  if (!isNil(timezone)) {
    return now.setZone(timezone);
  }

  return now;
};

export const setDefaultTimezone = (timezone: string) => {
  Settings.defaultZoneName = timezone;
};

export const ConvertDate = (date?: string, datePattern?: string) => {
  if (datePattern && date) {
    datePattern = datePattern.replace('mm', 'MM');
    return DateTime.fromISO(date).toFormat(datePattern);
  }
  return date;
};

export const ConvertDateForApi = (
  date: string,
  datePattern: datePatternType
) => {
  var arrDate = date.split('/');
  if (arrDate.length <= 1) return date;
  return DateTime.fromFormat(
    datePattern === 'mm/dd/yyyy'
      ? `${arrDate[1]}/${arrDate[0]}/${arrDate[2]}`
      : `${arrDate[0]}/${arrDate[1]}/${arrDate[2]}`,
    datePattern
  ).toFormat('yyyy-MM-dd');
};

export const getDatePlaceholder = (
  language: 'pt-BR' | 'en-US',
  datePattern: datePatternType
) => {
  if (language === 'pt-BR') {
    if (datePattern === 'mm/dd/yyyy') return 'mm/dd/aaaa';
    else return 'dd/mm/aaaa';
  } else {
    return datePattern.replaceAll('MM', 'mm');
  }
};
