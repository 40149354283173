import { createAction, Action, ActionsUnion } from '../Base/actionHelpers';
import { ICurrencyResponse } from '../../Data/Interfaces/response/Currency/ICurrencyResponse';

export enum CurrencyActionKeys {
  GET_CURRENCY_REQUEST = '[CURRENCY] GET_CURRENCY_REQUEST',
  GET_CURRENCY_SUCCESS = '[CURRENCY] GET_CURRENCY_SUCCESS',
  GET_CURRENCY_FAILURE = '[CURRENCY] GET_CURRENCY_FAILURE',
}

export const CurrencyActions = {
  getCurrency: (): GetCurrencyRequestAction =>
    createAction(CurrencyActionKeys.GET_CURRENCY_REQUEST),
  getCurrencySuccess: (res: ICurrencyResponse): GetCurrencySuccessAction =>
    createAction(CurrencyActionKeys.GET_CURRENCY_SUCCESS, res),
  getCurrencyFailure: (error: string): GetCurrencyFailureAction =>
    createAction(CurrencyActionKeys.GET_CURRENCY_FAILURE, error),
};

export type CurrencyActionUnion = ActionsUnion<typeof CurrencyActions>;

export type GetCurrencyRequestAction = Action<CurrencyActionKeys.GET_CURRENCY_REQUEST>;
export type GetCurrencySuccessAction = Action<
  CurrencyActionKeys.GET_CURRENCY_SUCCESS,
  ICurrencyResponse
>;
export type GetCurrencyFailureAction = Action<
  CurrencyActionKeys.GET_CURRENCY_FAILURE,
  string
>;
