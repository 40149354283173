import { Col, Row } from 'antd';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Card, Dropdown, Form, TextInput } from '../../../../../Components';
import { useBalanceAdjustmentsGet } from '../../../../../Hooks/Supply/BalanceAdjustmentsHooks';
import { useUserPreferencesType } from '../../../../../Hooks/Accountmanager/UserHooks';
import { ConvertDate } from '../../../../../Utils/DateUtils';

export const InfoBalanceAdjustmentCard: FC = () => {
  const { data: userPreferences } = useUserPreferencesType();
  const { externalId } = useParams<{ externalId: string }>();
  const { data: balanceAdjustment } = useBalanceAdjustmentsGet(externalId);

  const { t } = useTranslation();

  return (
    <Form
      initialValues={{
        ...balanceAdjustment,
        responsavel: balanceAdjustment?.usuarioExternalId,
        dataAjuste: `${ConvertDate(
          balanceAdjustment?.dataLancamento,
          userPreferences?.preferencias.padraoData
        )} ${t('common.dateBreakApart')} ${ConvertDate(
          balanceAdjustment?.dataLancamento,
          userPreferences?.preferencias.padraoData
        )}`,
      }}
    >
      <Card
        title={t('supply.transfers.detailsForm.titleInformation')}
        withoutCollapse={true}
      >
        <Row gutter={[16, 0]}>
          <Col span={4}>
            <TextInput
              name="dataAjuste"
              label={t('supply.balanceAdjustment.details.adjustmentDate')}
              disabled={true}
            />
          </Col>
          <Col span={7}>
            <Dropdown
              name="responsavel"
              items={[
                {
                  id: balanceAdjustment?.usuarioExternalId,
                  avatar: {
                    name: balanceAdjustment?.nomeResponsavel || '',
                    lastName: '',
                    email: '',
                  },
                  name: balanceAdjustment?.nomeResponsavel,
                },
              ]}
              label={t('supply.balanceAdjustment.details.responsible')}
              disabled={true}
              required
              withoutMarginBottom
            />
          </Col>
        </Row>

        <Row gutter={[16, 0]}>
          <Col span={12}>
            <TextInput
              name="descricaoProduto"
              label={t('supply.balanceAdjustment.details.product')}
              disabled={true}
            />
          </Col>

          <Col span={12}>
            <TextInput
              name="descricaoLocalEstoque"
              label={t('supply.balanceAdjustment.details.stockLocation')}
              disabled={true}
            />
          </Col>
        </Row>

        <Row gutter={[16, 0]}>
          <Col span={11}>
            <TextInput
              name="numeroLote"
              label={t('supply.balanceAdjustment.details.lot')}
              disabled={true}
            />
          </Col>
          <Col span={6}>
            <TextInput
              name="nomeFornecedor"
              label={t('supply.balanceAdjustment.details.provider')}
              disabled={true}
            />
          </Col>
          <Col span={4}>
            <TextInput
              name="numeroNotaFiscal"
              label={t('supply.balanceAdjustment.details.identifier')}
              disabled={true}
            />
          </Col>
          <Col span={3}>
            <TextInput
              name="serieNotaFiscal"
              label={t('supply.balanceAdjustment.details.nfSeries')}
              disabled={true}
            />
          </Col>
        </Row>
      </Card>
    </Form>
  );
};
