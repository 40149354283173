import { decodeToken } from 'react-jwt';
import StorageUtils from './StorageUtils';

const CURRENT_USER_ROLES = 'CURRENT_USER_ROLES';

export const setRoles = (access_token: string) => {
  const token: any = decodeToken(access_token);

  if (!token || !token.role) return;

  StorageUtils.SetItem(
    CURRENT_USER_ROLES,
    JSON.stringify(token.role.map((x: string) => parseInt(x)))
  );
};

export const getCurrentUserRoles = (): number[] => {
  return JSON.parse(StorageUtils.GetItem(CURRENT_USER_ROLES) || '[]');
};
