import React from 'react';
import { Link, Text } from '../../../../Components';
import { Avatar } from '../../../../Components/Avatar';
import { IListManagerCompanyData } from '../../../../Data/Interfaces/response/Company/ICompanyResponse';
import { MaskForCNPJ } from '../../../../Utils/MasksUtils';

export const companyCollums = (t: any) => [
  {
    title: t('admin.companyList.tableTitle.fantasyName'),
    dataIndex: 'nomeFantasia',
    key: 'nomeFantasia',
    width: '22%',
    sortColumn: 'nomeFantasia',
    sorter: true,
    defaultSortOrder: 'ascend',
    isHighlightable: true,
    render: (x: any, row: any) => (
      <Link
        type="ui-tiny-bold"
        to={`/admin/company/${row.ExternalId}`}
        style={{ width: 'fit-content' }}
      >
        {x}
      </Link>
    ),
  },
  {
    title: t('admin.companyList.tableTitle.cnpj'),
    dataIndex: 'cnpj',
    key: 'cnpj',
    sortColumn: 'cnpj',
    sorter: true,
    isHighlightable: true,
    render: (cnpj: string) => MaskForCNPJ(cnpj),
  },
  {
    title: t('admin.companyList.tableTitle.city'),
    dataIndex: 'cidadeUf',
    key: 'cidadeUf',
    sortColumn: 'cidadeUf',
    sorter: true,
    isHighlightable: true,
  },
  {
    title: t('admin.companyList.tableTitle.type'),
    dataIndex: 'tipoId',
    key: 'tipoId',
    sortColumn: 'tipoId',
    sorter: true,
    isHighlightable: true,
    render: (x: any) => (x === 1 ? 'Filial' : 'Matriz'),
  },
  {
    title: t('admin.companyList.tableTitle.responsible'),
    dataIndex: 'nomeResponsavel',
    key: 'nomeResponsavel',
    sortColumn: 'nomeResponsavel',
    sorter: true,
    isHighlightable: true,
    render: (userName: string, row: IListManagerCompanyData) => (
      <>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Avatar
            fisrtName={userName}
            lastName={row.sobrenomeResponsavel}
            style={{ marginRight: '10px' }}
            size="M"
            type="ui-tiny-bold"
          />
          <Text
            type="ui-tiny-content"
            children={`${userName} ${row.sobrenomeResponsavel}`}
          />
        </div>
      </>
    ),
  },
];
