import { useQuery } from '@tanstack/react-query';
import { IPagination } from '../../Components/Table';
import { ProviderAPI } from '../../Data/API/People/ProviderApi';

export const FETCH_LIST_PROVIDER = '[PROVIDER] FETCH_LIST_PROVIDER';
export const FETCH_GET_PROVIDER = '[PROVIDER] FETCH_GET_PROVIDER';

export const useProviderGetList = (pagination: IPagination) =>
  useQuery([FETCH_LIST_PROVIDER, pagination], ProviderAPI.listProvider);

export const useProviderGet = (externalId?: string) =>
  useQuery([FETCH_GET_PROVIDER, externalId], ProviderAPI.getProvider);
