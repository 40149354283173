import React, { FC, useMemo } from 'react';
import { useFormikContext } from 'formik';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { TextInput } from '../..';
import { useThereIsEmailTypes } from '../../../Hooks/Identity/EmailHooks';
import { emailIsValid } from '../../../Utils/ErrorHandlingUtils';
import { IInputLabel } from '../Dropdown';

interface ITextInputEmailAlreadyExists {
  name: string;
  label: string | IInputLabel[];
  placeholder: string;
  required?: boolean;
}

export const TextInputEmailAlreadyExists: FC<ITextInputEmailAlreadyExists> = ({
  name,
  label,
  placeholder,
  required,
}) => {
  const { t } = useTranslation();

  const form: any = useFormikContext();
  const { mutateAsync: getThereIsEmail } = useThereIsEmailTypes({
    onError: () => {
      form.setErrors({
        ...form.errors,
        email: t('common.error.emailAlreadyRegistred'),
      });
    },
    onSuccess: () =>
      form.setErrors({
        ...form.errors,
        email: undefined,
      }),
  });

  const thereIsEmailName = useMemo(
    () =>
      _.debounce(
        async (value: string) => await getThereIsEmail(value),

        600
      ),
    [getThereIsEmail]
  );

  return (
    <TextInput
      name={name}
      label={label}
      placeHolder={placeholder}
      required={required}
      onChange={(x) =>
        !emailIsValid(x)
          ? form.setErrors({
              ...form.errors,
              email: t('common.error.invalidEmail'),
            })
          : thereIsEmailName(x)
      }
    />
  );
};
