import { createAction, Action, ActionsUnion } from '../Base/actionHelpers';
import { IPagination } from '../../Components/Table';
import { IListClassTypeResponse } from '../../Data/Interfaces/response/ClassType/IClassTypeResponse';

export enum ClassActionKeys {
  LIST_CLASS_REQUEST = '[CLASS] LIST_CLASS_REQUEST',
  LIST_CLASS_SUCCESS = '[CLASS] LIST_CLASS_SUCCESS',
  LIST_CLASS_FAILURE = '[CLASS] LIST_CLASS_FAILURE',
}

export const ClassActions = {
  getListClass: (body: IPagination): ListClassRequestAction =>
    createAction(ClassActionKeys.LIST_CLASS_REQUEST, body),
  getListClassSuccess: (res: IListClassTypeResponse): ListClassSuccessAction =>
    createAction(ClassActionKeys.LIST_CLASS_SUCCESS, res),
  getListClassFailure: (error: string): ListClassFailureAction =>
    createAction(ClassActionKeys.LIST_CLASS_FAILURE, error),
};

export type ClassActionUnion = ActionsUnion<typeof ClassActions>;

export type ListClassRequestAction = Action<
  ClassActionKeys.LIST_CLASS_REQUEST,
  IPagination
>;
export type ListClassSuccessAction = Action<
  ClassActionKeys.LIST_CLASS_SUCCESS,
  IListClassTypeResponse
>;
export type ListClassFailureAction = Action<
  ClassActionKeys.LIST_CLASS_FAILURE,
  string
>;
