import React, { FC, ReactNode, useState } from 'react';
import { Input } from 'antd';
import { Field } from 'formik';
import { IField } from '../interfaces/IField';
import { InputLabel } from '../Label';
import { Icon, Tooltip } from '../..';
import { IconName } from '../../Icon/IconClasses';
import { LocalizaPropriedadePorPath } from '../../../Utils/FormikUtils';

import './NumberInput.module.scss';
import styles from './NumberInput.module.scss';
import { TextType } from '../../Text';
import { IInputLabel } from '../Dropdown';
import { Color } from '../../../Utils/ColorUtils';
import _ from 'lodash';
import { Divflex } from '../../../Layouts/DivWhithFlex';
import CurrencyFormat from 'react-currency-format';
import StorageUtils from '../../../Utils/StorageUtils';
import Spinner from '../../Spinner';
import { MONEY_DECIMAL_AMOUNT } from '../../../Utils/ConstantsUtils';

export interface INumberInput {
  name: string;
  label?: IInputLabel[] | string;
  maxLength?: number;
  typeLabel?: TextType;
  disabled?: boolean;
  placeHolder?: string;
  nulable?: boolean;

  rightIcon?: {
    icon?: IconName;
    copyName?: string;
    toolTipTitle?: ReactNode[];
  };

  rightIconTooltipOnly?: {
    icon?: IconName;
    toolTipTitle?: ReactNode[];
  };

  leftIcon?: IconName;

  withTooltip?: {
    title: string;
    icon: IconName;
  };

  required?: boolean;

  withoutMarginBottom?: boolean;

  success?: boolean;
  error?: boolean;
  className?: string;
  supportText?: string;

  onBlur?: (value: string) => void;
  onChange?: (value: any) => void;

  isLoading?: boolean | undefined;
  value?: string;

  withStatusIcon?: boolean;
  decimalScale?: number;
  fixedDecimalScale?: boolean;
  prefix?: string;
  suffix?: string;

  withCurrencyPrefix?: boolean;
  withThousandSeparator?: boolean;
  withDecimalSeparator?: boolean;
}

const currencySymbolTag = ['R$', '$'];

export const patternLabelStyle: {
  typeLabel: TextType;
  colorLabel: Color;
} = { typeLabel: 'ui-tiny-semibold', colorLabel: 'text-50' };

export const NumberInput: FC<INumberInput> = ({
  prefix,
  withCurrencyPrefix,
  withThousandSeparator,
  withDecimalSeparator,
  fixedDecimalScale,
  ...props
}) => {
  const preference = StorageUtils.getPreference();

  let currentPrefix = prefix;
  let thousandSeparator;
  let decimalSeparator;
  if (withCurrencyPrefix) {
    const currencySymbol = currencySymbolTag[preference?.tipoMoedaId];
    currentPrefix = `${currencySymbol} `;
  }

  if (withThousandSeparator) {
    thousandSeparator = preference?.idioma === 'pt-BR' ? '.' : ',';
  }

  if (withDecimalSeparator) {
    decimalSeparator = preference?.idioma === 'pt-BR' ? ',' : '.';
  }

  return (
    <CurrencyFormat
      decimalScale={MONEY_DECIMAL_AMOUNT}
      thousandSeparator={thousandSeparator}
      decimalSeparator={decimalSeparator}
      fixedDecimalScale={fixedDecimalScale}
      customInput={InnerNumberInput}
      prefix={currentPrefix}
      {...({
        ...props,
        currentPrefix,
        separators: {
          decimalSeparator,
          thousandSeparator,
        },
      } as any)}
    />
  );
};

const InnerNumberInput: FC<
  INumberInput & {
    currentPrefix?: string;
    separators?: {
      thousandSeparator?: string;
      decimalSeparator?: string;
    };
  }
> = ({
  name,
  label,
  maxLength,
  nulable,
  required,
  placeHolder,
  error,
  success,
  rightIcon,
  rightIconTooltipOnly,
  leftIcon,
  withTooltip,
  withoutMarginBottom,
  disabled,
  className,
  supportText,
  onBlur,
  onChange,
  isLoading,
  value,
  withStatusIcon,
  currentPrefix,
  separators,
}) => {
  const [copied, setCopied] = useState(false);

  let debounce_setCopied = _.debounce(() => {
    setCopied(false);
  }, 500);

  return (
    <div
      className={`${withoutMarginBottom ? '' : styles['margin-bottom']} ${
        styles['Container']
      }`}
    >
      <Field name={name}>
        {(props: IField) => {
          const hasError =
            error ||
            !!(
              props.form &&
              props.form.errors &&
              props.meta.touched &&
              LocalizaPropriedadePorPath(props.form.errors, name)
            );
          const classNames = className;

          const onPointerLeave = (event: any) => {
            if (nulable && (event.target === null || event.target.value === ''))
              props.form.setFieldValue(name, null);
          };

          return (
            <>
              <Divflex>
                <InputLabel
                  label={label}
                  props={props}
                  withStatusIcon={withStatusIcon}
                  required={required}
                />
                {withTooltip && (
                  <Tooltip
                    title={withTooltip.title}
                    showMe
                    children={
                      <Icon
                        className={styles['icon-with-tooltip']}
                        icon={withTooltip.icon}
                        color="text-300"
                        size="S"
                      />
                    }
                  />
                )}
              </Divflex>
              <div
                className={`
            ${styles['input-container']} 
            ${classNames} 
            ${leftIcon ? styles['with-left-icon'] : null}
            ${
              rightIcon || success || hasError
                ? styles['with-right-icon']
                : null
            } ${hasError ? styles['error'] : null}
            ${success ? styles['success'] : null}
            ${disabled ? styles['disabled'] : null}`}
              >
                {leftIcon ? (
                  <Icon
                    className={styles['Left-Icon']}
                    icon={leftIcon}
                    size="M"
                    color="text-50"
                  />
                ) : null}

                {supportText && (
                  <div className={styles['supportText']}>{supportText}</div>
                )}

                <Input
                  id={name}
                  {...props.field}
                  className={styles['TextInput']}
                  style={{ paddingRight: supportText ? '48px' : undefined }}
                  maxLength={maxLength}
                  placeholder={placeHolder}
                  onPointerLeave={onPointerLeave}
                  disabled={disabled || props.form.isSubmitting}
                  value={value || props.field.value}
                  onBlur={(x) => {
                    onBlur && onBlur(props.field.value);
                    props.field && props.field.onBlur(x);
                  }}
                  onChange={(x) => {
                    const value = x.target.value;
                    const numbers = value
                      .replaceAll(currentPrefix || '', '')
                      .replaceAll(separators?.thousandSeparator || '', '')
                      .replaceAll(separators?.decimalSeparator || '', '.');

                    onChange && onChange(x);
                    props.form &&
                      props.form.setFieldValue(name, parseFloat(numbers));
                  }}
                />
                {hasError ? (
                  <Icon
                    className={styles['Icon']}
                    size="M"
                    color="status-danger-base"
                  />
                ) : null}

                {success ? (
                  <Icon
                    className={styles['Icon']}
                    icon="check"
                    size="M"
                    color="status-success-base"
                  />
                ) : null}

                {rightIcon?.icon ? (
                  <Tooltip
                    title={
                      rightIcon.toolTipTitle &&
                      (!copied
                        ? rightIcon.toolTipTitle[0]
                        : rightIcon.toolTipTitle[1])
                    }
                    showMe={!!rightIcon.toolTipTitle}
                    placement="bottomRight"
                    copied={copied}
                  >
                    <Icon
                      className={styles['Icon']}
                      icon={rightIcon.icon}
                      size="SM"
                      color="text-400"
                      onClick={() => {
                        var copyText;
                        if (rightIcon.copyName)
                          copyText = document.getElementById(
                            rightIcon.copyName
                          ) as HTMLInputElement;

                        if (copyText) {
                          copyText.select();
                          copyText.setSelectionRange(0, 99999);
                          navigator.clipboard.writeText(copyText.value);
                        }
                        setCopied(true);
                      }}
                      onMouseLeave={debounce_setCopied}
                    />
                  </Tooltip>
                ) : null}

                {rightIconTooltipOnly?.icon ? (
                  <Tooltip
                    title={rightIconTooltipOnly.toolTipTitle}
                    showMe={!!rightIconTooltipOnly.toolTipTitle}
                    placement="bottomRight"
                  >
                    <Icon
                      className={styles['Icon']}
                      icon={rightIconTooltipOnly.icon}
                      size="SM"
                      color="text-400"
                    />
                  </Tooltip>
                ) : null}

                {isLoading && (
                  <div className={styles['spinner']}>
                    <Spinner />
                  </div>
                )}
                {props.form &&
                props.form.errors &&
                LocalizaPropriedadePorPath(props.form.errors, name) ? (
                  <p className={styles['errorText']}>
                    {LocalizaPropriedadePorPath(props.form.errors, name)}
                  </p>
                ) : null}
              </div>
            </>
          );
        }}
      </Field>
    </div>
  );
};
