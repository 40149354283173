import React from 'react';
import { Link, Text } from '../../../../Components';
import { Avatar } from '../../../../Components/Avatar';
import { IGetUserResponse } from '../../../../Data/Interfaces/response/User/IGetUserReponse';
import { ConvertDate } from '../../../../Utils/DateUtils';
import { hasPermission } from '../../../../Components/PermissionWrapper';
import { PermissionEnum } from '../../../../Data/Interfaces/model/permissionEnum';

export const transfersCollums = (t: any, currentMeUser?: IGetUserResponse) => [
  {
    title: t('supply.transfers.list.transferNumber'),
    dataIndex: 'sequenciaNumeroTransferencia',
    key: 'sequenciaNumeroTransferencia',
    width: '10%',
    sortColumn: 'sequenciaNumeroTransferencia',
    sorter: true,
    isHighlightable: true,
  },
  {
    title: t('supply.transfers.list.dateOfTransfer'),
    width: '14%',
    dataIndex: 'dataTransferencia',
    key: 'dataTransferencia',
    sortColumn: 'dataTransferencia',
    sorter: true,
    defaultSortOrder: 'ascend',
    isHighlightable: true,
    render: (x: string, row: any) =>
      !hasPermission(PermissionEnum.Estoque_Transferencias_VerDetalhes) ? (
        <Text
          type="ui-tiny-bold"
          children={`${ConvertDate(
            x,
            currentMeUser?.preferencias.padraoData
          )} ${t('common.dateBreakApart')} ${ConvertDate(
            x,
            currentMeUser?.preferencias.padraoHora
          )}`}
        />
      ) : (
        <Link
          type="ui-tiny-bold"
          to={`/inventory/transfers/${row.externalId}/details`}
          style={{ width: 'fit-content' }}
          children={`${ConvertDate(
            x,
            currentMeUser?.preferencias.padraoData
          )} ${t('common.dateBreakApart')} ${ConvertDate(
            x,
            currentMeUser?.preferencias.padraoHora
          )}`}
        />
      ),
  },

  {
    title: t('supply.transfers.list.placeOfOrigin'),
    width: '28%',
    dataIndex: 'localEstoqueOrigemDescricao',
    key: 'localEstoqueOrigemDescricao',
    sortColumn: 'localEstoqueOrigemDescricao',
    sorter: true,
    isHighlightable: true,
    render: (x: any, row: any) => `${x} (${row.localEstoqueOrigemEmpresa})`,
  },
  {
    title: t('supply.transfers.list.destination'),
    width: '28%',
    dataIndex: 'localEstoqueDestinoDescricao',
    key: 'localEstoqueDestinoDescricao',
    sortColumn: 'localEstoqueDestinoDescricao',
    sorter: true,
    isHighlightable: true,
    render: (x: any, row: any) => `${x} (${row.localEstoqueDestinoEmpresa})`,
  },
  {
    title: t('supply.transfers.list.responsible'),
    dataIndex: 'usuarioNome',
    key: 'usuarioNome',
    sortColumn: 'usuarioNome',
    sorter: true,
    isHighlightable: true,
    render: (name: string, surname: any) => (
      <Avatar
        size="M"
        type="ui-tiny-bold"
        fisrtName={name}
        lastName={surname.usuarioSobrenome}
        description={{
          description: `${name} ${surname.usuarioSobrenome}`,
          type: 'ui-tiny-content',
        }}
      />
    ),
  },
];
