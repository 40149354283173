import React, { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Switch } from '../../../Components/StateInputs/Switch';
import { UserActions } from '../../../Store/User/User.actions';
import { TableLayout } from '../../../Components/Tabs/TablePreference';
import { Themes } from '../../../Utils/ThemeUtils';
import {
  USER_PREFERENCES_CACHE,
  getUsersPreferences,
  getMeUser,
} from '../../../Store/User/User.selector';
import StorageUtils from '../../../Utils/StorageUtils';

export const Accessibility: FC = () => {
  const reduxUserPreferences = useSelector(getUsersPreferences);
  const cachedUserPreference = JSON.parse(
    StorageUtils.GetItem(USER_PREFERENCES_CACHE) || '{}'
  );
  const userPreferences = reduxUserPreferences || cachedUserPreference;

  const [switchText, setSwitchText] = useState<boolean>(
    userPreferences?.textoAmpliado || false
  );
  const [switchBold, setSwitchBold] = useState<boolean>(
    userPreferences?.textoNegrito || false
  );
  const [switchContrast, setSwitchContrast] = useState<boolean>(
    userPreferences?.contrasteAumentado || false
  );

  const user = useSelector(getMeUser);

  const { t } = useTranslation();

  const dispatch = useDispatch();

  useEffect(() => {
    if (userPreferences) {
      setSwitchText(userPreferences?.textoAmpliado);
      setSwitchBold(userPreferences?.textoNegrito);
      setSwitchContrast(userPreferences?.contrasteAumentado);
    }
  }, [userPreferences]);

  useEffect(() => {
    userPreferences && switchText
      ? document.documentElement.classList.add('amplified-text')
      : document.documentElement.classList.remove('amplified-text');
  }, [switchText, userPreferences]);

  useEffect(() => {
    userPreferences && switchBold
      ? document.documentElement.classList.add('bold-text')
      : document.documentElement.classList.remove('bold-text');
  }, [switchBold, userPreferences]);

  useEffect(() => {
    if (userPreferences && switchContrast) {
      document.documentElement.classList.remove(Themes[Themes.darkMode]);
      document.documentElement.classList.add('contrast');
    } else document.documentElement.classList.remove('contrast');
  }, [switchContrast, userPreferences]);

  const patchMe = useCallback(
    (body: any) => {
      user &&
        dispatch(
          UserActions.patchMe({
            userExternalId: user.externalId,
            data: body,
          })
        );
    },
    [dispatch, user]
  );

  return (
    <TableLayout
      title={t('preferences.accessibilityPage.title')}
      description={t('preferences.accessibilityPage.description')}
      tableConfig={[
        {
          head: t('preferences.accessibilityPage.amplifyText'),
          body: [
            {
              type: 'paragraph2-bold',
              color: 'text-200',
              children: t('preferences.accessibilityPage.amplifyBody'),
            },
          ],
          rightItem: (
            <Switch
              onChange={(check) => {
                setSwitchText(check);
                patchMe({
                  textoAmpliado: check,
                  textoNegrito: switchBold,
                  contrasteAumentado: switchContrast,
                });
              }}
              checked={switchText}
            />
          ),
          widthRightItem: 143,
        },
        {
          head: t('preferences.accessibilityPage.boldText'),
          body: [
            {
              type: 'paragraph2-bold',
              color: 'text-200',
              children: t('preferences.accessibilityPage.boldBody'),
            },
          ],
          rightItem: (
            <Switch
              onChange={(check) => {
                setSwitchBold(check);
                patchMe({
                  textoAmpliado: switchText,
                  textoNegrito: check,
                  contrasteAumentado: switchContrast,
                });
              }}
              checked={switchBold}
            />
          ),
          widthRightItem: 143,
        },
      ]}
    />
  );
};
