import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from '../../../../../Components/Modal';
import { IProductGroupResponse } from '../../../../../Data/Interfaces/response/Product/IProductRespose';
import { Text } from '../../../../../Components';
import { Divflex } from '../../../../../Layouts/DivWhithFlex';
import { FooterThisActionIsPermanent } from '../../../../../Components/Table/components/Modals/DeleteModal';
import {
  FETCH_LIST_GROUP,
  useGroupDelete,
} from '../../../../../Hooks/Supply/ProductHooks';
import { useQueryClient } from '@tanstack/react-query';
import { history } from '../../../../../Store';
import { handleModalError } from '../../../../../Utils/ErrorHandlingUtils';
import { App } from 'antd';

interface IEditModal {
  visible: boolean;
  productGroup: IProductGroupResponse;
  onCancelClick: () => void;
}

export const DeleteProductGroupModal: React.FC<IEditModal> = ({
  visible,
  productGroup,
  onCancelClick,
}) => {
  const { t } = useTranslation();
  const app = App.useApp();
  const deleteGroup = useGroupDelete();
  const queryClient = useQueryClient();

  const onDelete = async () => {
    const res = await deleteGroup.mutateAsync(
      {
        gruposExternalId: [productGroup?.externalId] as any,
      },
      {
        onError: handleModalError(app),
      }
    );
    if (res.success) {
      queryClient.invalidateQueries([FETCH_LIST_GROUP]);
      history.push(`/inventory/groups/`);
    }
  };

  return (
    <Modal
      onOkClick={() => {
        onDelete();
      }}
      title={`${t('common.delete')} ${t('common.group')} “${
        productGroup.descricao
      }”?`}
      body={
        <>
          <div style={{ padding: '20px' }}>
            <Divflex>
              <Text
                type="ui-tiny-content"
                children={`${t('modal.deleteModal.single.question')} ${t(
                  'common.theGroup'
                )} `}
                color="text-50"
              />
              <Text
                type="ui-tiny-bold"
                children={`${productGroup.descricao}`}
                color="text-50"
                style={{ marginBottom: '15px' }}
              />
              <Text type="ui-tiny-content" children={'?'} />
            </Divflex>
            <FooterThisActionIsPermanent />
          </div>
        </>
      }
      visible={visible}
      onCancelClick={onCancelClick}
      htmlType="submit"
      okButtonName={t('modal.delete')}
      okButtonColor="status-danger-base"
      onClose={onCancelClick}
    />
  );
};
