import { call, put } from 'redux-saga/effects';
import { i18n } from '../../Components';
import { StorageLocationApi } from '../../Data/API/Supply/StorageLocationApi';
import {
  messageErrorToast,
  messageSucessToast,
} from '../../Utils/MessageErrorToast';
import {
  CreateStorageLocationRequestAction,
  StorageLocationActions,
  PatchStorageLocationStatusRequestAction,
  EditStorageLocationRequestAction,
  StorageLocationListAccountmanagerRequestAction,
  getStorageLocationDropDownListAction,
} from './StorageLocation.action';
import { history } from '../../Store';

export function* createStorageLocation({
  payload,
}: CreateStorageLocationRequestAction) {
  try {
    yield call(StorageLocationApi.createStorageLocation, payload);
    messageSucessToast(i18n.t('supply.supplyPlaces.create.success'));
    history.push('/inventory/supply-places');
  } catch (error: any) {
    messageErrorToast(error);
    yield put(
      StorageLocationActions.createStorageLocationFailure(error.message)
    );
  } finally {
    payload.helpers.setSubmitting(false);
  }
}

export function* patchStorageLocationStatus({
  payload,
}: PatchStorageLocationStatusRequestAction) {
  try {
    yield call(StorageLocationApi.patchStorageLocationStatus, payload);
    yield put(
      StorageLocationActions.patchStorageLocationStatusSuccess(payload)
    );
    payload.callback && payload.callback();
  } catch (error: any) {
    messageErrorToast(error);
  }
}

export function* editStorageLocation({
  payload,
}: EditStorageLocationRequestAction) {
  try {
    yield call(StorageLocationApi.editStorageLocation, payload);
    messageSucessToast(i18n.t('products.create.success.productGroupEdited'));
  } catch (error: any) {
    messageErrorToast(error);

    yield put(StorageLocationActions.editStorageLocationFailure(error.message));
  } finally {
    payload.helpers.setSubmitting(false);
  }
}

export function* getStorageLocationListAccountmanager({
  payload,
}: StorageLocationListAccountmanagerRequestAction) {
  try {
    const { data } = yield call(
      StorageLocationApi.getStorageLocationListAccountmanager,
      payload
    );

    yield put(StorageLocationActions.getStorageLocationListSuccess(data));
  } catch (error: any) {
    messageErrorToast(error);

    yield put(
      StorageLocationActions.getStorageLocationListFailure(error.message)
    );
  }
}

export function* getStorageLocationListDropDown({
  payload,
}: getStorageLocationDropDownListAction) {
  try {
    const { data } = yield call(
      StorageLocationApi.getListStorageLocationDropDown,
      payload
    );
    yield put(
      StorageLocationActions.getStorageLocationDropDownListSuccess(data)
    );
  } catch (error: any) {
  } finally {
    yield put(StorageLocationActions.setLoading(false));
  }
}
