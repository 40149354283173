import React, { useState } from 'react';
import { Table } from '../../../../Components';
import { IPatchUserStatusRequest } from '../../../../Data/Interfaces/request/User/IUserRequest';
import { history } from '../../../../Store';
import { useTranslation } from 'react-i18next';
import { editTableStatus } from '../../../../Utils/EditModalTableUtils';
import { userCollums } from './user.columns';
import { deleteModalBody, deleteModalTitle } from './List.helpers';
import { ConfirmBody } from './Modals/ConfirmStatusModal';
import {
  GET_USER_LIST_TYPE,
  useChangeUserStatusMutation,
  useGetUserListType,
  useUserDeleteMutation,
} from '../../../../Hooks/Accountmanager/UserHooks';
import { IQueryListParams } from '../../../../Utils/Http';
import { IListUserData } from '../../../../Data/Interfaces/response/User/IGetUserReponse';
import { useQueryClient } from '@tanstack/react-query';

import './List.override.scss';

export const List: React.FC = () => {
  const [selectedRowsOnTable, setSelectedRowsOnTable] = useState<
    IListUserData[]
  >([]);
  const nameKey = 'nome';
  const [pagination, setPagination] = useState<IQueryListParams>({
    PageIndex: 0,
    PageSize: 10,
    Sorter: { column: nameKey, direction: 'ASC' },
  });

  const queryClient = useQueryClient();

  const changeUserStatus = useChangeUserStatusMutation({
    onSuccess: () => queryClient.invalidateQueries([GET_USER_LIST_TYPE]),
  });
  const { data: users, isLoading } = useGetUserListType(pagination);
  const deleteUser = useUserDeleteMutation();

  const { t } = useTranslation();

  const deleteUsers = async (externalId: string[]) => {
    await deleteUser.mutateAsync(externalId);
  };

  const patchUsersStatus = async (status: IPatchUserStatusRequest) => {
    await changeUserStatus.mutateAsync(status);
  };

  return (
    <Table
      columns={userCollums(t)}
      data={
        users?.data?.map((x) => ({
          ...x,
          key: `table_users_${x.externalId}`,
        })) || []
      }
      fieldStatus="ativo"
      headerOptions={{
        hasSearch: true,
        searchPlaceholder: t('admin.userList.searchPlaceholder'),
        newButton: {
          onClick: () => history.push('/admin/user/create'),
          permission: {
            permission: 1003,
            tooltip: 'noPermission.administrative.users.create',
          },
        },
        ...((selectedRowsOnTable?.every((x: any) => x.ativo) ||
          selectedRowsOnTable?.every((x: any) => !x.ativo)) && {
          editButton: {
            permission: {
              permission: 1005,
              tooltip: 'noPermission.administrative.users.statusEdit',
            },
            options: [
              {
                label: t('common.status'),
                isStatusOnly: true,
              },
            ],
          },
        }),
        deleteButton: {
          permission: {
            permission: 1008,
            tooltip: 'noPermission.administrative.users.delete',
          },
        },
        nameKey,
      }}
      loading={isLoading}
      hasSelection={true}
      rowsOptions={{
        permission: {
          permission: 1002,
          tooltip: 'noPermission.administrative.users.detail',
        },
        onRowDoubleClick: (x) =>
          history.push(`/admin/user/${x.externalId}/details`),
      }}
      onRefetch={setPagination}
      pagination={
        users
          ? {
              Count: users.count,
              PageIndex: users.pageIndex,
              PageSize: users.pageSize,
            }
          : false
      }
      editOptions={editTableStatus()}
      onEdit={(user, status) =>
        patchUsersStatus({
          usuariosExternalId: user.map((x) => x.externalId),
          ativa: status.status === 'success' ? true : false,
        })
      }
      onDelete={(users: IListUserData[]) =>
        deleteUsers(users.map((user) => user.externalId))
      }
      selectedRowsOnTable={(x) => setSelectedRowsOnTable(x)}
      deleteModal={
        selectedRowsOnTable && {
          title: deleteModalTitle(selectedRowsOnTable, t),
          body: deleteModalBody(selectedRowsOnTable, t),
          buttons: {
            okButtonColor: 'status-danger-base',
          },
          className: 'delete-one-user-modal',
        }
      }
      editModalLoading={changeUserStatus.isLoading}
      confirmEditModal={{
        title: `${t(`modal.editModal.single.confirm.inactivate`)} ${
          selectedRowsOnTable?.length === 1
            ? `“${selectedRowsOnTable[0].nome}”`
            : selectedRowsOnTable?.length
        }${
          selectedRowsOnTable && selectedRowsOnTable?.length > 1
            ? ` ${t('modal.deleteModal.users')}`
            : ''
        }?`,
        body: selectedRowsOnTable && (
          <ConfirmBody
            itemNames={selectedRowsOnTable.map((x) => ({
              name: x.nome,
              lastName: x.sobrenome,
            }))}
          />
        ),
        okButtonName: t('common.inactivate'),
      }}
      translations={{
        editModal: {
          customEndTitle: t('common.users'),
        },
      }}
    />
  );
};
