import { Col, Row } from 'antd';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Card, TextInput, Tooltip } from '../../../../../../Components';
import { getUserDatePattern } from '../../../../../../Store/Auth/Auth.selector';
import { formatDate } from '../../../../../../Utils/DateUtils';
import { useLotGet } from '../../../../../../Hooks/Supply/LotHooks';
import { useFormikContext } from 'formik';
import { NumberInput } from '../../../../../../Components/Inputs/NumberInput';
import { TextInputWithMask } from '../../../../../../Components/Inputs/TextInputWithMask';

interface IDetailsForm {
  disabled: boolean;
}

export const InfoCard: FC<IDetailsForm> = ({ disabled }) => {
  const form: any = useFormikContext();
  const { t } = useTranslation();
  const { externalId } = useParams<{ externalId: string }>();
  const { data: lots } = useLotGet(externalId);
  const datePattern = useSelector(getUserDatePattern);

  return (
    <Card
      title={t('supply.lot.details.details.titleInformation')}
      withoutCollapse={true}
    >
      <div style={{ width: '100%' }}>
        <Row gutter={[16, 0]}>
          <Col span={4}>
            <TextInput
              name="numero"
              label={t('supply.lot.details.details.batchCode')}
              placeHolder={t('supply.lot.details.details.batchCode')}
              disabled={!!externalId}
              rightIcon={{
                icon: 'copy',
                copyName: 'numero',
                toolTipTitle: [
                  t('supply.lot.details.toolTips.copy'),
                  t('supply.lot.details.toolTips.copySuccess'),
                ],
              }}
            />
          </Col>
          <Col span={4} style={{ marginLeft: 'auto' }}>
            <NumberInput
              name="saldoLote"
              label={t('supply.lot.details.details.availableQuantity')}
              placeHolder={t('supply.lot.details.details.availableQuantity')}
              disabled={!!externalId}
              rightIconTooltipOnly={{
                icon: 'exclamation-upside-down',
                toolTipTitle: t(
                  'supply.lot.details.toolTips.availableQuantity'
                ),
              }}
            />
          </Col>
        </Row>
      </div>
      <Row gutter={[16, 0]}>
        <Col span={7}>
          <TextInput
            name="produto"
            label={t('supply.lot.details.details.product')}
            placeHolder={t('supply.lot.details.details.product')}
            disabled={!!externalId}
          />
        </Col>
        <Col span={4}>
          <TextInput
            name="classeProduto.abreviacao"
            label={t('supply.lot.details.details.class')}
            placeHolder={t('supply.lot.details.details.class')}
            disabled={!!externalId}
          />
        </Col>
        <Col span={5}>
          <TextInput
            name="dataLancamento"
            label={t('supply.lot.details.details.launch')}
            placeHolder={t('supply.lot.details.details.launch')}
            disabled={!!externalId}
            leftIcon="calendar"
          />
        </Col>
        <Col span={4}>
          <Tooltip
            title={`${t(
              'supply.lot.details.toolTips.originalManufacturingDate'
            )} ${
              lots &&
              formatDate(lots.dataFabricacao, datePattern || 'dd/MM/yyyy')
            }`}
            showMe={
              lots &&
              formatDate(lots.dataFabricacao, datePattern || 'dd/MM/yyyy') !==
                form?.values?.dataFabricacao &&
              !disabled
            }
          >
            <TextInputWithMask
              name="dataFabricacao"
              label={t('supply.lot.details.details.manufacturing')}
              placeHolder={t('supply.lot.details.details.manufacturing')}
              disabled={disabled}
              leftIcon="calendar"
              withStatusIcon
              required={!disabled}
              mask="dataMask"
            />
          </Tooltip>
        </Col>
        <Col span={4}>
          <Tooltip
            title={`${t(
              'supply.lot.details.toolTips.originalExpirationDate'
            )} ${
              lots && formatDate(lots.dataValidade, datePattern || 'dd/MM/yyyy')
            }`}
            showMe={
              lots &&
              formatDate(lots.dataValidade, datePattern || 'dd/MM/yyyy') !==
                form?.values?.dataValidade &&
              !disabled
            }
          >
            <TextInputWithMask
              name="dataValidade"
              label={t('supply.lot.details.details.validity')}
              placeHolder={t('supply.lot.details.details.validity')}
              disabled={disabled}
              leftIcon="calendar"
              withStatusIcon
              required={!disabled}
              mask="dataMask"
            />
          </Tooltip>
        </Col>
      </Row>

      <Row gutter={[16, 0]}>
        <Col span={9}>
          <TextInput
            name="fornecedor"
            label={t('supply.lot.details.details.provider')}
            placeHolder={t('supply.lot.details.details.provider')}
            disabled={!!externalId}
          />
        </Col>
        <Col span={9}>
          <TextInput
            name="razaoSocial"
            label={t('supply.lot.details.details.corporateName')}
            placeHolder={t('supply.lot.details.details.corporateName')}
            disabled={!!externalId}
          />
        </Col>

        <Col span={6}>
          <TextInput
            name="cnpj"
            label={t('supply.lot.details.details.cnpj')}
            placeHolder={t('supply.lot.details.details.cnpj')}
            disabled={!!externalId}
          />
        </Col>
      </Row>

      <Row gutter={[16, 0]}>
        <Col span={4}>
          <TextInput
            name="numeroNf"
            label={t('supply.lot.details.details.numberInvoice')}
            placeHolder={t('supply.lot.details.details.numberInvoice')}
            disabled={!!externalId}
          />
        </Col>
        <Col span={2}>
          <TextInput
            name="serieNf"
            label={t('supply.lot.details.details.seriesInvoice')}
            placeHolder={t('supply.lot.details.details.seriesInvoice')}
            disabled={!!externalId}
          />
        </Col>
      </Row>
    </Card>
  );
};
