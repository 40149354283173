import React, { FC, useCallback, useEffect, useState } from 'react';
import { Col, List, Row } from 'antd';
import { Text } from '../Text';
import { Button } from '../Button';
import { Icon } from '../Icon';
import { Modal } from '../Modal';

import styles from './AdderList.module.scss';

type ItemsType = {
  description: any[] | string[];
  favorite?: boolean;
};
type CollumType = {
  label: string;
  width: number;
  key: string;
};

interface IAdderList {
  items?: ItemsType[];
  collum: CollumType[];
  favoriteFormName: string;
  modal: {
    body: any;
    addTitle: string;
    editTitle: string;
    okButtonLabel?: string;
    modalFormName: string[];
  };

  buttonLabel: string;

  onChange: (value: ItemsType[]) => void;

  empytDataDescription: string;
}

export const AdderList: FC<IAdderList> = ({
  items,
  collum,
  favoriteFormName,
  modal,
  buttonLabel,
  onChange,
  empytDataDescription,
}) => {
  const [dataItems, setDataItems] = useState<ItemsType[] | []>([]);
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editModalInitialValues, setEditModalInitialValues] = useState<any>();
  const [favoriteIndex, setFavoriteIndex] = useState<number>(0);

  useEffect(() => {
    items && setDataItems(items);
    const favoriteIndex = items?.findIndex((x) => x.favorite);
    favoriteIndex && favoriteIndex >= 0 && setFavoriteIndex(favoriteIndex);
  }, [items]);

  const onChangeDataItems = useCallback(() => {
    let aux: any[] = [];
    dataItems.forEach((x, indexValue) => {
      x.description.map((value, index) =>
        typeof value === 'object'
          ? (aux[indexValue] = {
              ...aux[indexValue],
              ...{ [collum[index].key]: value.id },
            })
          : (aux[indexValue] = {
              ...aux[indexValue],
              ...{ [collum[index].key]: value },
            })
      );

      aux[indexValue] = {
        ...aux[indexValue],
        ...{ [favoriteFormName]: x.favorite },
      };
    });

    onChange(aux);
  }, [collum, dataItems, onChange, favoriteFormName]);

  const addNewItem = (values: any) => {
    const valuesKeys = Object.keys(values).map((x) => x);

    let auxDataItens: any = dataItems;
    auxDataItens?.push({
      description: valuesKeys.map((x) => values[x]),
      ...(auxDataItens.length === 0 && { favorite: true }),
    });
    setDataItems(auxDataItens);
    setAddModalOpen(false);
    onChangeDataItems();
  };
  const editItem = (values: any) => {
    const valuesKeys = Object.keys(values).map((x) => x);

    let auxDataItems = dataItems;
    const indexCollum = valuesKeys.map((value: any) =>
      collum.findIndex((x) => x.key === value)
    );
    indexCollum.forEach((x) => {
      if (auxDataItems && x >= 0)
        auxDataItems[values.index].description[x] = values[valuesKeys[x]];
    });

    setDataItems(auxDataItems);
    setEditModalOpen(false);
    onChangeDataItems();
  };

  useEffect(() => {
    onChangeDataItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataItems]);

  return (
    <div>
      <Row className={styles['collum']} gutter={[16, 0]}>
        {collum?.map((collum) => (
          <Col span={collum.width} key={collum.label}>
            <Text type="ui-tiny-content" children={collum.label} />
          </Col>
        ))}
      </Row>

      <List
        className={styles['adder-list']}
        loading={!dataItems}
        itemLayout="horizontal"
        locale={{
          emptyText: (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginBottom: '16px',
                marginTop: '-5px',
              }}
            >
              <Text
                type="ui-tiny-content"
                color="text-300"
                children={empytDataDescription}
              />
            </div>
          ),
        }}
        dataSource={dataItems}
        renderItem={(item, index) => (
          <List.Item
            actions={[
              favoriteIndex !== index && (
                <Button
                  type="secondary"
                  onClick={() => {
                    let auxDataItems = dataItems;
                    if (auxDataItems) {
                      delete auxDataItems[favoriteIndex].favorite;
                      auxDataItems[index].favorite = true;
                    }
                    setFavoriteIndex(index);
                    auxDataItems && setDataItems(auxDataItems);
                    onChangeDataItems();
                  }}
                >
                  <Icon icon="favorite" size="M" />
                </Button>
              ),
              <Button
                type="secondary"
                onClick={() => {
                  const initialValueObject = Object.assign(
                    {},
                    ...modal.modalFormName.map((_: any, indexNames: any) => ({
                      [modal.modalFormName[indexNames]]:
                        item.description[indexNames],
                    }))
                  );

                  setEditModalInitialValues({ ...initialValueObject, index });
                  setEditModalOpen(true);
                }}
              >
                <Icon icon="edit" size="M" />
              </Button>,
              <Button
                type="secondary"
                onClick={() =>
                  setDataItems(
                    dataItems?.filter((dataItem, itemIndex) => {
                      if (dataItem.favorite && index === itemIndex)
                        setFavoriteIndex(0);
                      else if (index === itemIndex)
                        if (favoriteIndex > itemIndex)
                          setFavoriteIndex(favoriteIndex - 1);

                      return index !== itemIndex;
                    })
                  )
                }
              >
                <Icon icon="trash" size="M" />
              </Button>,
            ]}
          >
            <CardTransfers
              itemIndex={index}
              item={item}
              collum={collum}
              favoriteIndex={favoriteIndex}
            />
          </List.Item>
        )}
      />
      <Button
        type="secondary"
        children={buttonLabel}
        leftIcon="plus"
        onClick={() => setAddModalOpen(true)}
      />

      <Modal
        body={modal.body}
        title={editModalOpen ? modal.editTitle : modal.addTitle}
        visible={editModalOpen || addModalOpen}
        onOkClickForm={addModalOpen ? addNewItem : editItem}
        withForm
        htmlType="submit"
        initialValues={editModalOpen ? editModalInitialValues : undefined}
        onCancelClick={() => {
          setEditModalOpen(false);
          setAddModalOpen(false);
        }}
        onClose={() => {
          setEditModalOpen(false);
          setAddModalOpen(false);
        }}
        okButtonName={modal.okButtonLabel}
      />
    </div>
  );
};

interface ICardTransfers {
  itemIndex: number;
  item: ItemsType;
  favoriteIndex: number;
  collum: CollumType[];
}

const CardTransfers: FC<ICardTransfers> = ({
  collum,
  favoriteIndex,
  itemIndex,
  item,
}) => {
  return (
    <div>
      <Row gutter={[16, 0]}>
        {collum?.map((collum, collumIndex) => (
          <Col span={collum.width} key={collum.label}>
            <Text
              type={
                favoriteIndex === itemIndex ? 'ui-tiny-bold' : 'ui-tiny-content'
              }
              children={
                typeof item.description[collumIndex] === 'string'
                  ? item.description[collumIndex]
                  : item.description[collumIndex].value
              }
              favoriteIcon={favoriteIndex === itemIndex && collumIndex === 0}
            />
          </Col>
        ))}
      </Row>
    </div>
  );
};
