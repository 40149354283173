import * as yup from 'yup'
import i18n from '../../../../Components/i18n'
export const CreateSchema = yup.object().shape({
	nome: yup.string().required(i18n.t('admin.createUserPage.personalDataCard.errors.requiredName')),
	sobrenome: yup.string().required(i18n.t('admin.createUserPage.personalDataCard.errors.requiredLastName')),

	email: yup.string().email(i18n.t('admin.createUserPage.AccessDataCard.errors.invalidEmail'))
		.required(i18n.t('admin.createUserPage.AccessDataCard.errors.requiredEmail')),
	emailAlternativo: yup.string().email(i18n.t('admin.createUserPage.AccessDataCard.errors.invalidEmail'))
	.notOneOf([yup.ref('email')], i18n.t('admin.createUserPage.AccessDataCard.errors.invalidAlternativeEmail')),
	grupos: yup.string().required(i18n.t('admin.createUserPage.AccessDataCard.errors.requiredGroup')),
})