import { useMutation, useQuery } from '@tanstack/react-query';
import { PersonAPI } from '../../Data/API/People/PersonApi';

const FETCH_CONTACT_TYPE = '[PERSON] CONTACT_TYPE';
const FETCH_DOCUMENT_TYPE = '[PERSON] FETCH_DOCUMENT_TYPE';
const CREATE_CONTACT_TYPE = '[PERSON] CREATE_CONTACT_TYPE';
const CREATE_DOCUMENT_TYPE = '[PERSON] CREATE_DOCUMENT_TYPE';
const CREATE_ADDRESS_TYPE = '[PERSON] CREATE_ADDRESS_TYPE';
const FETCH_PROVIDER_TYPE = '[PERSON] FETCH_PROVIDER_TYPE';

export const useContactTypes = () =>
  useQuery([FETCH_CONTACT_TYPE], PersonAPI.listContactType, {
    staleTime: 120000,
  });

export const useDocumentTypes = () =>
  useQuery([FETCH_DOCUMENT_TYPE], PersonAPI.listDocumentTypes, {
    staleTime: 120000,
  });

export const useProviderTypes = () =>
  useQuery([FETCH_PROVIDER_TYPE], PersonAPI.listProviderTypes, {
    staleTime: 120000,
  });

export const useCreateProviderMutation = () =>
  useMutation([FETCH_DOCUMENT_TYPE], PersonAPI.createProvider);

export const useCreateContactMutation = () =>
  useMutation([CREATE_CONTACT_TYPE], PersonAPI.createContact);

export const useCreateDocumentMutation = () =>
  useMutation([CREATE_DOCUMENT_TYPE], PersonAPI.createDocument);

export const useCreateAddressMutation = () =>
  useMutation([CREATE_ADDRESS_TYPE], PersonAPI.createAddress);
