import { call, put } from 'redux-saga/effects';
import {
  ConglomerateActions,
  DeleteConglomerateRequestAction,
  PutConglomerateRequestAction,
  GetCompanyForConglomerateRequestAction,
  PatchConglomerateStatusRequestAction,
} from './Conglomerate.actions';
import { ConglomerateAPI } from '../../Data/API/Accountmanager/ConglomerateApi';
import {
  messageErrorToast,
  messageSucessToast,
} from '../../Utils/MessageErrorToast';
import { IGetCompanyForConglomerateData } from '../../Data/Interfaces/response/Conglomerate/IConglomerateResponde';
import { Http } from '../../Utils/Http';
import { history } from '..';
import { i18n } from '../../Components';

export function* getCompanyForConglomerate({
  payload,
}: GetCompanyForConglomerateRequestAction) {
  try {
    const { data } = yield call(
      ConglomerateAPI.getCompanyForConglomerate,
      payload
    );
    let transformedData;
    if (payload.expand) {
      transformedData = Http.TransformODataData<IGetCompanyForConglomerateData>(
        data,
        payload.pagination
      );
      yield put(
        ConglomerateActions.getCompanyForConglomerateSuccess(transformedData)
      );
    }
  } catch (error: any) {
    messageErrorToast(error);

    yield put(
      ConglomerateActions.getCompanyForConglomerateFailure(error.message)
    );
  }
}

export function* getCompanyForConglomerateIncrementing({
  payload,
}: GetCompanyForConglomerateRequestAction) {
  try {
    const { data } = yield call(
      ConglomerateAPI.getCompanyForConglomerate,
      payload
    );
    let transformedData;
    if (payload.expand) {
      transformedData = Http.TransformODataData<IGetCompanyForConglomerateData>(
        data,
        payload.pagination
      );
      yield put(
        ConglomerateActions.getCompanyForConglomerateIncrementingSuccess(
          transformedData
        )
      );
    }
  } catch (error: any) {
    messageErrorToast(error);

    yield put(
      ConglomerateActions.getCompanyForConglomerateIncrementingFailure(
        error.message
      )
    );
  }
}

export function* putConglomerate({ payload }: PutConglomerateRequestAction) {
  try {
    yield call(ConglomerateAPI.editConglomerate, payload);
    messageSucessToast(i18n.t('adminBootys.editConglomerate.successMessage'));

    yield put(ConglomerateActions.setLoading(false));

    history.push('/admin-bootys/conglomerate');
  } catch (error: any) {
    messageErrorToast(error);

    yield put(ConglomerateActions.putConglomerateFailure(error.message));
  }
}

export function* deleteConglomerate({
  payload,
}: DeleteConglomerateRequestAction) {
  try {
    yield call(ConglomerateAPI.deleteConglomerate, payload);

    yield put(ConglomerateActions.setLoading(false));
  } catch (error: any) {
    messageErrorToast(error);

    yield put(ConglomerateActions.deleteConglomerateFailure(error.message));
  } finally {
    yield put(ConglomerateActions.setLoading(false));
  }
}

export function* changeConglomerateStatus({
  payload,
}: PatchConglomerateStatusRequestAction) {
  try {
    yield call(ConglomerateAPI.changeStatusConglomerate, payload);

    yield put(ConglomerateActions.patchUserStatusSuccess(payload));
  } catch (error: any) {
    messageErrorToast(error);
  } finally {
    yield put(ConglomerateActions.setLoading(false));
  }
}
