import { useFormikContext } from 'formik';
import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Text } from '../../../../../../Components';
import { Content } from '../../../../../../Components/CollapseList/Content';
import { IconName } from '../../../../../../Components/Icon/IconClasses';
import { Modal } from '../../../../../../Components/Modal';
import { UserActions } from '../../../../../../Store/User/User.actions';
import { getPermissionDependencyModalData } from '../../../../../../Store/User/User.selector';

import styles from './RequiredPermissionModal.module.scss';

const ModalBody = () => {
  const { t } = useTranslation();
  const modalData = useSelector(getPermissionDependencyModalData);
  const defaultValues: any = {};

  modalData?.data?.dependencias?.forEach((dependency) => {
    defaultValues[dependency.permissaoId] = modalData.request.ativo;
  });

  return (
    <div className={styles.container}>
      <Text type="paragraph2" color="text-50" style={{ marginBottom: 12 }}>
        {t('admin.editUserPage.permission.modal.title1')}
        <b>“{modalData?.data.nome}”</b>{' '}
        {t('admin.editUserPage.permission.modal.title2')}{' '}
        {modalData?.data.dependencias?.length}{' '}
        {t('admin.editUserPage.permission.modal.permissions')}
      </Text>

      <Form initialValues={defaultValues}>
        {modalData?.data.dependencias?.map((dependency, index) => (
          <div className={styles.permissionContainer}>
            <Content
              name={dependency.permissaoId}
              displayName={dependency.nome}
              icon={{
                icon: dependency.modulo.icone as IconName,
                backgroundColor: dependency.modulo.corDeFundo,
                color: 'white',
              }}
              secondaryIcon={{
                icon: dependency.subModulo.icone as IconName,
                backgroundColor: dependency.subModulo.corDeFundo,
                color: 'text-50',
              }}
              hasInherited={false}
              maxDisplayNameCharacters={20}
              disabled
            />
          </div>
        ))}
      </Form>

      <Text type="paragraph2" color="text-50">
        {t('admin.editUserPage.permission.modal.footer')}
      </Text>
    </div>
  );
};

export const RequiredPermissionModal: FC<{
  show: boolean;
}> = ({ show }) => {
  const form = useFormikContext();
  const modalData = useSelector(getPermissionDependencyModalData);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onCloseHandler = useCallback(
    (action?: 'cancel' | 'ok') => {
      dispatch(UserActions.hideDependencyModal());

      if (action !== 'ok') {
        form.resetForm();
      }
    },
    [form, dispatch]
  );

  const onOkClick = useCallback(() => {
    if (modalData) {
      dispatch(
        UserActions.updatePermission({
          ...modalData?.request,
          dependencias: modalData?.data?.dependencias?.map(
            (dep) => dep.permissaoId
          ),
        })
      );
      modalData?.data?.dependencias?.forEach((dep) => {
        form.setFieldValue(dep.permissaoId, modalData.request.ativo);
      });
    }
  }, [dispatch, form, modalData]);

  return (
    <Modal
      visible={show}
      onClose={onCloseHandler}
      onCancelClick={onCloseHandler}
      onOkClick={onOkClick}
      body={<ModalBody />}
      title={t('admin.editUserPage.permission.modal.modalTitle')}
      okButtonColor={
        modalData?.request.ativo! ? undefined : 'status-danger-base'
      }
      okButtonName={t(
        `admin.editUserPage.permission.modal.ok${
          modalData?.request.ativo! ? 'Active' : 'Inactive'
        }Button`
      )}
    />
  );
};
