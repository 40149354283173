import { ModalFuncProps } from 'antd';
import { FC } from 'react';
import { BodyModal } from '../../../../../../Components/Modal';
import { Footer as ModalFooter } from '../../../../../../Components/Modal/Components/Footer';
import {
  ICommonError,
  IGlobalModal,
} from '../../../../../../Utils/ErrorHandlingUtils';
import { Icon, Text } from '../../../../../../Components';
import { useTranslation } from 'react-i18next';
import { Divflex } from '../../../../../../Layouts/DivWhithFlex';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';

const Content: FC<ICommonError> = ({ content }) => {
  const { t } = useTranslation();

  return (
    <>
      <BodyModal
        body={
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Text type="ui-tiny-content">
              <ReactMarkdown>
                {t('supply.lot.details.modal.cannotDelete.subTitle', {
                  lot: `**${content[0].numero}**`,
                  product: `**${content[0].produtoDescricao}**`,
                })}
              </ReactMarkdown>
            </Text>

            <Text type="ui-tiny-content" style={{ marginTop: 25 }}>
              {t('supply.lot.details.modal.cannotDelete.description')}
            </Text>

            <Divflex style={{ marginTop: 25 }}>
              <Icon
                icon={'exclamation-upside-down'}
                size={'SM'}
                color={'text-400'}
                style={{ padding: '18px 18px 0 8px' }}
              />
              <Text
                type="paragraph2"
                color={'text-400'}
                children={t(
                  'supply.lot.details.modal.cannotDelete.warningFooter'
                )}
              />
            </Divflex>
          </div>
        }
        style={{ padding: 20 }}
      />
    </>
  );
};

const Title: FC<ICommonError> = ({ content }) => {
  const { t } = useTranslation();
  return (
    <>
      {t('supply.lot.details.modal.cannotDelete.title', {
        lot: content[0].numero,
      })}
    </>
  );
};

const Footer: FC<{
  params: { loteExternalId: string[] };
  instance: IGlobalModal;
}> = ({ instance }) => {
  const { t } = useTranslation();

  return (
    <ModalFooter
      onCancelClick={instance.destroy}
      cancelButtonName={t('common.close')}
      withoutOkButton
    />
  );
};

export const CannotDeleteModal: (
  error: ICommonError,
  params: { loteExternalId: string[] },
  instance: IGlobalModal
) => ModalFuncProps = (error, params, instance) => ({
  closable: true,
  icon: <></>,
  title: <Title {...error} />,
  content: <Content {...error} />,
  footer: <Footer params={params} instance={instance} />,
});
