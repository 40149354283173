export const breadCrumbEN = {
  home: 'Home',

  bairros: 'Neighborhoods',
  createBairros: 'Create',

  preference: 'Preference',
  accessibility: 'Accessibility',
  placeAndTime: 'Place and Time',
  theme: 'Theme',

  accountManager: 'Account manager',
  accountDetails: 'Account details',
  accountSecurity: 'Account security',
  notification: 'Notifications',

  systemConfig: 'System Settings',
  personalization: 'Personalization',
  currency: 'Currency',

  adminBootys: 'Admin Bootys',
  adminBootysRoute: {
    company: 'Company',
    companyPage: {
      create: 'Company Registration',
      details: 'Details',
    },

    conglomerate: 'Grupos de Empresas',
    conglomeratePage: {
      details: 'Details',
      company: 'Companys',
    },

    status: {
      ative: 'Active',
      inative: 'Inative',
      changeStatus: 'Change status',
    },
  },

  admin: 'Administrative',
  adminRoute: {
    user: 'Users',
    userPage: {
      create: 'User registration',
      details: 'Details',
      permissions: 'Permissions',
    },

    company: 'Company',
    companyPage: {
      details: 'Details',
    },

    groupPage: {
      list: 'User Groups',
      create: 'User Group Registration',
      edit: {
        tabs: {
          detail: 'Details',
          users: 'Users',
          permission: 'Permissions',
        },
      },
    },
  },

  products: {
    list: 'Products',
    create: 'Product registration',
    group: {
      list: 'Product Groups',
    },
    subGroup: {
      list: 'Product Subgroups',
    },
    details: 'Details',
    subGroups: 'Subgroups',
    products: 'Products',

    financial: 'Financial',

    inventory: 'Inventory',
  },

  supply: 'Supply',
  supplyPlaces: {
    title: 'Stock Locations',
    create: 'Stock Location Registration',
    details: 'Stock Location',
    inventory: 'Stock',
    inventoryMovements: 'Stock Movements',

    detail: {
      title: 'Detail',
    },
  },

  balanceAdjustments: {
    list: 'Balance Adjustments',
    create: 'Balance Adjustment Registration',
  },

  supplyRoutes: {
    list: 'Supply Places',
    lossesList: 'Supply Losses',
    lots: 'Lots',
    transfers: 'Transfers',
    stockTransfers: 'Stock transfers',
  },

  losses: {
    create: 'Inventory Loss Record',
    losses: 'Loss',
    detail: {
      title: 'Lot',
      details: 'Details',
      stockLocations: 'Stock Locations',
    },
  },

  lots: {
    create: 'Batch Registration',
    detail: {
      title: 'Lot',
      details: 'Details',
      stockLocations: 'Stock Locations',
    },
  },

  transfers: {
    detail: {
      title: 'Stock Transfer',
    },
    create: {
      breadCrumb: 'Inventory Transfer Registration',
    },
  },

  purchase: 'purchase',
  purchasing: {
    purchaseList: 'Purchase Order',
    title: 'Purchasing',

    provider: {
      title: 'Provider',

      create: {
        title: 'Provider Creation',
      },
    },
  },
};

export const breadCrumbPT = {
  home: 'Início',

  bairros: 'Bairros',
  createBairros: 'Criar',

  preference: 'Preferências',
  accessibility: 'Acessibilidade',
  placeAndTime: 'Local e Hora',
  theme: 'Tema',

  accountManager: 'Gerenciar conta',
  accountDetails: 'Detalhes da conta',
  accountSecurity: 'Segurança da conta',
  notification: 'Notificações',

  systemConfig: 'Configurações do Sistema',
  personalization: 'Personalização',
  currency: 'Moeda',

  adminBootys: 'Admin Bootys',
  adminBootysRoute: {
    company: 'Empresas',
    companyPage: {
      create: 'Cadastro de Empresa',
      details: 'Detalhes',
    },

    conglomerate: 'Grupos de Empresas',
    conglomeratePage: {
      details: 'Detalhes',
      company: 'Empresas',
    },

    status: {
      ative: 'Ativo',
      inative: 'Inativo',
      changeStatus: 'Alterar status',
    },
  },
  admin: 'Administrativo',
  adminRoute: {
    user: 'Usuários',
    userPage: {
      create: 'Cadastro de Usuário',
      details: 'Detalhes',
      permissions: 'Permissões',
    },

    company: 'Empresas',
    companyPage: {
      details: 'Detalhes',
    },

    groupPage: {
      list: 'Grupos de Usuários',
      create: 'Cadastro de Grupo de Usuários',
      edit: {
        tabs: {
          detail: 'Detalhes',
          users: 'Usuários',
          permission: 'Permissões',
        },
      },
    },
  },

  products: {
    list: 'Produtos',
    create: 'Cadastro de Produto',
    group: {
      list: 'Grupos de Produtos',
    },
    subGroup: {
      list: 'Subgrupos de Produtos',
    },
    details: 'Detalhes',
    subGroups: 'Subgrupos',
    products: 'Produtos',

    financial: 'Financeiro',

    inventory: 'Estoque',
  },

  supply: 'Estoque',
  supplyPlaces: {
    title: 'Locais de Estoque',
    create: 'Cadastro de Local de Estoque',
    details: 'Local de Estoque',
    inventory: 'Estoque',
    inventoryMovements: 'Movimentações de Estoque',

    detail: {
      title: 'Detalhes',
    },
  },

  balanceAdjustments: {
    list: 'Ajustes de Saldo',
    create: 'Cadastro de Ajuste de Saldo',
  },

  supplyRoutes: {
    list: 'Locais de Estoque',
    lossesList: 'Perdas de Estoque',
    lots: 'Lotes',
    transfers: 'Transferências',
    stockTransfers: 'Transferências de Estoque',
  },

  losses: {
    create: 'Cadastro de Perda de Estoque',
    losses: 'Perdas',
    detail: {
      title: 'Lote',
      details: 'Detalhes',
      stockLocations: 'Locais de estoque',
    },
  },

  lots: {
    create: 'Cadastro de lote',
    detail: {
      title: 'Lote',
      details: 'Detalhes',
      stockLocations: 'Locais de estoque',
    },
  },

  transfers: {
    detail: {
      title: 'Transferência de estoque',
    },
    create: {
      breadCrumb: 'Cadastro de Transferência de Estoque',
    },
  },

  purchase: 'Compras',
  purchasing: {
    purchaseList: 'Pedido de Compra',
    title: 'Pedido de Compra',

    provider: {
      title: 'Fornecedores',

      create: {
        title: 'Cadastro de Fornecedor',
      },
    },
  },
};
