import React, { CSSProperties, FC } from 'react';
import { Tag as AntdTag } from 'antd';
import { Text } from '../';
import { Icon } from '../Icon';

import styles from './Tag.module.scss';
import './Tag.override.scss';
import { Tooltip } from '../Tooltip';

export type TagType =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'link-primary'
  | 'link-secondary'
  | 'link-tertiary';

const TagComponent = ({
  type,
  children,
  onClose,
  closable,
  className,
  widthAuto,
  style,
}: ITag) => (
  <AntdTag
    className={`${widthAuto ? styles['width-auto'] : ''} ${type}-tag ${
      styles['Tag']
    } ${closable ? 'Tag-with-close-x' : 'Tag-without-close-x'} ${
      className ? className : ''
    }`}
    closeIcon={
      closable && <Icon className={'icon-x-tag'} icon="close-x" size="S" />
    }
    children={<Text type="small-text-bold" children={children} style={style} />}
    closable={closable}
    onClose={onClose && onClose}
  />
);

export interface ITag {
  type: TagType;
  children: any;
  onClose?: any;
  closable?: boolean;
  className?: string;

  widthAuto?: boolean;

  tooltip?: string;

  style?: CSSProperties;

  id?: string;
}

export const Tag: FC<ITag> = ({
  type,
  children,
  onClose,
  closable,
  className,
  widthAuto,
  tooltip,
  style,
  id,
}) => (
  <div id={id}>
    <Tooltip title={tooltip} showMe={tooltip !== undefined}>
      {TagComponent({
        type,
        children,
        onClose,
        closable,
        className,
        widthAuto,
        style,
      })}
    </Tooltip>
  </div>
);
