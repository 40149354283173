import React from 'react'

import styles from './Loading.module.scss'

export interface ISpinner {
	className?: string
	borderWidth?: string
}

export const Loading: React.FC<ISpinner> = ({ className, borderWidth }) => {
	const border = `${borderWidth} solid`
	return (
		<div className={`${className} ${styles['loader']}`}>
			<div style={{ border }} />
			<div style={{ border }} />
			<div style={{ border }} />
			<div style={{ border }} />
			<div className={styles['background-loading']} style={{ border }} />
		</div>
	)
}
