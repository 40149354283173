import React from 'react';

import { Text, Icon } from '../';
import { Button as AntdButton } from 'antd';

import styles from './Status.module.scss';
import './Status.override.scss';
import { useSelector } from 'react-redux';
import { getMeUser } from '../../Store/User/User.selector';
import { Themes } from '../../Utils/ThemeUtils';
import { statusTypes } from '../../Utils/StatusUtils';
import Spinner from '../Spinner';

export interface IStatus {
  type: statusTypes;
  darkmode?: boolean;
  children: string;
  rightIcon?: boolean;
  disabled?: boolean;
  onClick?: (status: any) => void;

  loading?: boolean;
}

export const Status: React.FC<IStatus> = ({
  type,
  darkmode,
  children,
  disabled,
  rightIcon,
  onClick,
  loading,
}) => {
  const user = useSelector(getMeUser);
  return (
    <AntdButton
      className={`${type}-status ${styles['status']} ${
        rightIcon ? styles['status-with-rightIcon'] : ''
      } ${
        user?.preferencias.temaUsuario !== Themes.lightMode ? 'darkmode' : null
      }`}
      disabled={disabled}
      onClick={onClick}
    >
      <Text
        type="ui-tiny-bold"
        children={children}
        style={{ whiteSpace: 'nowrap' }}
      />
      {!loading ? (
        rightIcon && (
          <Icon
            icon="chevron-down"
            size="L"
            color={
              type === 'success'
                ? 'status-success-base'
                : type === 'danger'
                ? 'status-danger-base'
                : type === 'attention'
                ? 'status-warning-base'
                : type === 'primary'
                ? 'status-primary-base'
                : type === 'default'
                ? 'status-default-base'
                : 'status-disabled-base'
            }
          />
        )
      ) : (
        <Spinner className={styles['button-spinner']} />
      )}
    </AntdButton>
  );
};
