import React, { useState } from 'react';
import { Table } from '../../../../Components';
import { history } from '../../../../Store';
import { useTranslation } from 'react-i18next';
import { companyCollums } from './company.columns';
import { ModalBody, modalTitle } from './Modal';
import {
  IListCompanyData,
  IListManagerCompanyData,
} from '../../../../Data/Interfaces/response/Company/ICompanyResponse';
import { IQueryListParams } from '../../../../Utils/Http';
import {
  LIST_COMPANY_TYPE,
  useCompanyChangeResponsibleUserMutation,
  useCompanyListType,
} from '../../../../Hooks/Accountmanager/CompanyHooks';
import { useQueryClient } from '@tanstack/react-query';

export const List: React.FC = () => {
  const [pagination, setPagination] = useState<IQueryListParams>({
    PageIndex: 0,
    PageSize: 10,
    Sorter: { column: 'nomeFantasia', direction: 'ASC' },
  });
  const [rowSelected, setRowSelected] = useState<IListCompanyData[]>();

  const queryClient = useQueryClient();

  const { data: companys, isLoading: companysLoading } =
    useCompanyListType(pagination);
  const changeResponsibilytiUser = useCompanyChangeResponsibleUserMutation({
    onSuccess: () => queryClient.invalidateQueries([LIST_COMPANY_TYPE]),
  });

  const { t } = useTranslation();

  return (
    <Table
      columns={companyCollums(t)}
      data={
        companys?.data?.map((x) => ({
          ...x,
          key: `table_manager_companys_${x.externalId}`,
        })) || []
      }
      headerOptions={{
        hasSearch: true,
        searchPlaceholder: t('admin.companyList.searchPlaceholder'),
        nameKey: 'nomeFantasia',
        editButton: {
          permission: {
            permission: 1017,
            tooltip: 'noPermission.administrative.userGroups.statusEdit',
          },
          options: [
            {
              label: t('common.responsible'),
              isStatusOnly: true,
            },
          ],
        },
      }}
      loading={companysLoading}
      hasSelection={true}
      pagination={
        companys
          ? {
              Count: companys.count,
              PageIndex: companys.pageIndex,
              PageSize: companys.pageSize,
            }
          : false
      }
      onEdit={async (value: IListManagerCompanyData[]) =>
        rowSelected &&
        value &&
        (await changeResponsibilytiUser.mutateAsync({
          empresasExternalId: rowSelected.map((x) => x.externalId),
          usuarioResponsavelExternalId: value[0].externalIdResponsavel,
        }))
      }
      editModal={{
        title: modalTitle(rowSelected),
        body: (
          <ModalBody
            companyExternalId={rowSelected?.map((x) => x.externalId)}
          />
        ),
      }}
      editModalLoading={changeResponsibilytiUser.isLoading}
      onRefetch={(pagination) => setPagination(pagination)}
      rowsOptions={{
        permission: {
          permission: 1017,
          tooltip: 'noPermission.administrative.company.detail',
        },
        onRowDoubleClick: (x) => history.push(`/admin/company/${x.externalId}`),
      }}
      selectedRowsOnTable={(x) => setRowSelected(x)}
    />
  );
};
