export type IconName =
  | 'logo'
  | 'exclamation'
  | 'users'
  | 'check'
  | 'attach'
  | 'double-chevron-right'
  | 'chevron-down'
  | 'archive'
  | 'download'
  | 'like'
  | 'chevron-right'
  | 'call'
  | 'delete-folder'
  | 'lock'
  | 'bell'
  | 'search'
  | 'plus'
  | 'close-x'
  | 'exclamation-upside-down'
  | 'menu-elipsis'
  | 'email'
  | 'calculation'
  | 'double-chevron-left'
  | 'notext-logo'
  | 'user-mark'
  | 'question-mark'
  | 'exit'
  | 'logo-collapse'
  | 'eye'
  | 'close-eye'
  | 'multimidia-equalizer'
  | 'logo-bootys-login'
  | 'logo-bootys-login-image'
  | 'search-action'
  | 'arrow-down'
  | 'arrow-up'
  | 'trash'
  | 'edit'
  | 'user'
  | 'buildings'
  | 'document-info'
  | 'cube'
  | 'cubes'
  | 'connection'
  | 'bin-full'
  | 'document'
  | 'tag'
  | 'tags'
  | 'water-glass'
  | 'library'
  | 'basket'
  | 'shipping-truck'
  | 'cash'
  | 'invoice'
  | 'conical-flask'
  | 'setting'
  | 'shopping-bag'
  | 'gear'
  | 'Building'
  | 'invoice-list'
  | 'menu-ellipsis-horizontal'
  | 'keyhole'
  | 'account'
  | 'users-outline'
  | 'clipboard'
  | 'simple-cube'
  | 'simple-cubes'
  | 'external-link'
  | 'remove-account'
  | 'Box'
  | 'calendar'
  | 'copy'
  | 'Arrow-right'
  | 'status-attention'
  | 'favorite'
  | 'pencil';

export const iconClasses = {
  logo: 'icon-logo-bootys',
  lock: 'icon-lock',
  exclamation: 'icon-exclamation-mark',
  users: 'icon-users-outline',
  'users-outline': 'icon-users-outline',
  'simple-cube': 'icon-simple-cube',
  'simple-cubes': 'icon-simple-cubes',
  clipboard: 'icon-clipboard',
  check: 'icon-approve-tick',
  attach: 'icon-attach-outline',
  'double-chevron-right': 'icon-double-chevron-right',
  'chevron-down': 'icon-chevron-down',
  'chevron-right': 'icon-chevron-right',
  archive: 'icon-archive',
  download: 'icon-download',
  like: 'icon-like',
  call: 'icon-call',
  'delete-folder': 'icon-delete-folder',
  bell: 'icon-bell',
  search: 'icon-search',
  plus: 'icon-plus',
  'close-x': 'icon-close-x',
  'exclamation-upside-down': 'icon-exclamation-upside-down',
  'menu-elipsis': 'icon-menu-elipse',
  email: 'icon-email',
  calculation: 'icon-calculation',
  'double-chevron-left': 'icon-double-chevron-left',
  'notext-logo': 'icon-notext-logo',
  'user-mark': 'icon-user-mark',
  'question-mark': 'icon-question-mark',
  exit: 'icon-exit',
  'logo-collapse': 'icon-logo-collapse',
  eye: 'icon-eye',
  'close-eye': 'icon-close-eye',
  'multimidia-equalizer': 'icon-multimidia-equalizer',
  'logo-bootys-login': 'icon-logo-bootys-login',
  'logo-bootys-login-image': 'icon-logo-bootys-login-image',
  'search-action': 'icon-search-action',
  'arrow-down': 'icon-arrow-down',
  'arrow-up': 'icon-arrow-up',
  'Arrow-right': 'icon-Arrow-right',
  edit: 'icon-edit',
  trash: 'icon-trash',
  user: 'icon-account',
  account: 'icon-account',
  buildings: 'icon-buildings',
  'document-info': 'icon-document-info',
  cube: 'icon-cube',
  cubes: 'icon-cubes',
  connection: 'icon-connection',
  'bin-full': 'icon-bin-full',
  document: 'icon-document',
  tag: 'icon-tag',
  tags: 'icon-tags',
  'water-glass': 'icon-water-glass',
  library: 'icon-library',
  basket: 'icon-basket',
  'shipping-truck': 'icon-shipping-truck',
  cash: 'icon-cash',
  invoice: 'icon-invoice',
  'conical-flask': 'icon-conical-flask',
  setting: 'icon-setting',
  'shopping-bag': 'icon-shopping-bag',
  gear: 'icon-gear',
  Building: 'icon-Building',
  'invoice-list': 'icon-invoice-list',
  'menu-ellipsis-horizontal': 'icon-menu-ellipsis-horizontal',
  keyhole: 'icon-keyhole',
  'external-link': 'icon-external-link',
  'remove-account': 'icon-remove-account',
  Box: 'icon-Box',
  calendar: 'icon-calendar',
  copy: 'icon-copy',
  'status-attention': 'icon-status-attention',
  favorite: 'icon-favorite',
  pencil: 'icon-pencil',
};
