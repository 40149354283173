import { call, put } from 'redux-saga/effects';
import { CurrencyAPI } from '../../Data/API/Accountmanager/CurrencyApi';

import { messageErrorToast } from '../../Utils/MessageErrorToast';
import { CurrencyActions } from './Currency.actions';

export function* getCurrentCurrency() {
  try {
    const { data } = yield call(CurrencyAPI.getCurrency);
    data.tipoMoedaId--;
    yield put(CurrencyActions.getCurrencySuccess(data));
  } catch (error: any) {
    messageErrorToast(error);

    yield put(CurrencyActions.getCurrencyFailure(error.message));
  }
}
