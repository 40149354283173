import { useQuery } from '@tanstack/react-query';
import { IPagination } from '../../Components/Table';
import { UnitMeasurementAPI } from '../../Data/API/Supply/UnitMeasurementApi';
import { IQueryListParams } from '../../Utils/Http';

export const FETCH_LIST_UNITS_BY_CLASS =
  '[UNIT_MEASUREMENT] FETCH_LIST_UNITS_BY_CLASS';

export const FETCH_LIST_ALTERNATIVE_UNITS =
  '[UNIT_MEASUREMENT] FETCH_LIST_ALTERNATIVE_UNITS';

export const useUnitMeasurementByClass = (pagination: IQueryListParams) =>
  useQuery(
    [FETCH_LIST_UNITS_BY_CLASS, pagination],
    UnitMeasurementAPI.listUnitMeasurementByClassAny,
    { staleTime: 1000 * 60 * 60 } //1 hora
  );

export const useUnitMeasurementByClassGetDropdown = (
  pagination: IPagination,
  tipoClasseProduto?: number
) =>
  useQuery(
    [FETCH_LIST_UNITS_BY_CLASS, pagination, tipoClasseProduto],
    UnitMeasurementAPI.listUnitMeasurementByClass,
    { staleTime: 1000 * 60 * 60 } //1 hora
  );

export const useAlternativeUnitMeasurementDropdown = (
  pagination: IPagination
) =>
  useQuery(
    [FETCH_LIST_ALTERNATIVE_UNITS, pagination],
    UnitMeasurementAPI.listAlternativeUnitMeasurement
  );
