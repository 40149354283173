import React, { FC } from 'react';
import { PageLoading } from '../../../../Layouts/PageLoading';
import { InformationTransferForm } from './TransferCards/InfoTransferCard';
import { TransferForm } from './TransferCards/TransferCard';

export const TransfersDetails: FC = () => {
  return (
    <PageLoading>
      <InformationTransferForm />
      <TransferForm />
    </PageLoading>
  );
};

export default TransfersDetails;
