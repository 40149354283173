import { useQueryClient } from '@tanstack/react-query';
import { FormikHelpers, FormikValues } from 'formik';
import React, { useEffect } from 'react';
import { CompanyBillingsCard } from '../..';
import { Form } from '../../../../../Components';
import { IChangeCompanyPayingRequest } from '../../../../../Data/Interfaces/request/Company/ICompanyRequest';
import { IGetCompanyResponse } from '../../../../../Data/Interfaces/response/Company/ICompanyResponse';
import {
  GET_COMPANY_ADMIN_TYPE,
  useCompanyChangePayingMutation,
} from '../../../../../Hooks/Accountmanager/CompanyHooks';

interface ICompanyBillings {
  company: IGetCompanyResponse;
  radioCompanyGroup: boolean;
  radioBillings: boolean;
  onChangeRadioBillings: (value: boolean) => void;
}

export const CompanyEditBillingsCard: React.FC<ICompanyBillings> = ({
  company,
  radioCompanyGroup,
  radioBillings,
  onChangeRadioBillings,
}) => {
  const { mutateAsync, isLoading } = useCompanyChangePayingMutation();

  const queryClient = useQueryClient();

  useEffect(() => {
    !isLoading &&
      onChangeRadioBillings(
        company?.empresaPagadoraExternalId === company?.externalId
      );
  }, [company, onChangeRadioBillings, isLoading]);

  const onSubmit = async (
    values: IChangeCompanyPayingRequest,
    helpers: FormikHelpers<FormikValues>
  ) => {
    const body: IChangeCompanyPayingRequest = {
      ...values,
      helpers,
    };

    const res = await mutateAsync(body);

    if (res.success) queryClient.invalidateQueries([GET_COMPANY_ADMIN_TYPE]);
  };

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{
        externalId: company?.externalId,
        empresaPagadoraExternalId: company?.empresaPagadoraExternalId,
      }}
    >
      <CompanyBillingsCard
        editForm
        companyGroupExternalId={company?.conglomeradoExternalId}
        radioCompanyGroup={radioCompanyGroup}
        onButtonChange={onChangeRadioBillings}
        radioBillings={radioBillings}
        isLoading={isLoading}
      />
    </Form>
  );
};
