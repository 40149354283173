import { useQueryClient } from '@tanstack/react-query';
import React, { useState } from 'react';
import { Skeleton, Text } from '../../../../../..';
import {
  GET_USER_TYPE,
  useChangeUserStatusMutation,
  useGetUserType,
} from '../../../../../../../Hooks/Accountmanager/UserHooks';
import { ConfirmBody } from '../../../../../../../Pages/Administrative/User/List/Modals/ConfirmStatusModal';
import i18n from '../../../../../../i18n';
import { Modal } from '../../../../../../Modal';
import { PermissionWrapper } from '../../../../../../PermissionWrapper';
import { Status } from '../../../Status';

export const DynamicUserBreadcrumbEmail = ({ match }: any) => {
  const { data: user } = useGetUserType(match.params.externalId);
  if (user) {
    const { email } = user;
    return <span>{email}</span>;
  }
  return <span></span>;
};

export const DynamicEditUserStatusBreadcrumb = ({ match }: any) => {
  const queryClient = useQueryClient();
  const [visibleConfirmationModal, setVisibleConfirmationModal] =
    useState(false);

  const { data: user } = useGetUserType(match.params.externalId);
  const changeUserStatus = useChangeUserStatusMutation({
    onSuccess: () => {
      queryClient.invalidateQueries([GET_USER_TYPE]);
      setVisibleConfirmationModal(false);
    },
  });

  if (!user)
    return (
      <div style={{ width: 200 }}>
        <Skeleton />
      </div>
    );

  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Text
          type="heading3-bold"
          color="text-50"
          children={`${i18n.t('common.user')} | ${user.nome}`}
        />
        <PermissionWrapper
          permission={1005}
          tooltip="noPermission.administrative.users.statusEdit"
        >
          <Status
            type={user.ativo ? 'success' : 'disabled'}
            ative={user.ativo || false}
            onClick={(value) =>
              value
                ? changeUserStatus.mutateAsync({
                    usuariosExternalId: [match.params.externalId],
                    ativa: value,
                  })
                : setVisibleConfirmationModal(true)
            }
            loading={changeUserStatus.isLoading}
          />
        </PermissionWrapper>
      </div>
      {user && (
        <Modal
          title={`${i18n.t('common.inactivate')} ${i18n.t('common.user')}`}
          body={
            <ConfirmBody
              itemNames={[
                {
                  name: user.nome,
                  lastName: user.sobrenome,
                },
              ]}
            />
          }
          visible={visibleConfirmationModal}
          onOkClick={() =>
            changeUserStatus.mutateAsync({
              usuariosExternalId: [match.params.externalId],
              ativa: false,
            })
          }
          onCancelClick={() => setVisibleConfirmationModal(false)}
          onClose={() => setVisibleConfirmationModal(false)}
          okButtonName={i18n.t('common.inactivate')}
          loading={changeUserStatus.isLoading}
        />
      )}
    </>
  );
};
