import React, { CSSProperties, FC, ReactElement, useState } from 'react';

import './Button.override.scss';
import styles from './Button.module.scss';
import { Button as AntdButton } from 'antd';
import { IconName } from '../Icon/IconClasses';
import { Icon } from '../Icon';
import { Text } from '../Text';
import Spinner from './Components/Spinner';
import { Badge, BadgeType } from '../Badge';

export type ButtonType =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'link'
  | 'danger'
  | 'no-color'
  | 'primary-company'
  | 'secondary-company';

export type ButtonStatus = 'default' | 'danger' | 'success' | 'warning';

export interface IButton {
  type: ButtonType;
  status?: ButtonStatus;

  htmlType?: 'submit' | 'button' | 'reset';

  disabled?: boolean;
  contentChild?: 'center' | 'start' | 'flex-end';
  widthChild?: number;
  size?: 'xs' | 'xl';

  leftIcon?: IconName;
  rightIcon?: IconName;
  noIconPadding?: boolean;
  badge?: { amount: number; badgeType?: BadgeType };

  children?: string | ReactElement;

  className?: string;

  fullWidth?: boolean;
  style?: CSSProperties;

  onClick?: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;

  loading?: boolean;
}

export const Button: FC<IButton> = ({
  type,
  status,
  htmlType,
  disabled,
  contentChild,
  widthChild,
  leftIcon,
  rightIcon,
  badge,
  children,
  className,
  size = 'xl',
  style,
  noIconPadding,
  onClick,
  onMouseEnter,
  onMouseLeave,
  loading,
  fullWidth,
}) => {
  const [onMouseDown, setOnMouseDown] = useState(false);

  const buttonStatus = status ? status : 'default';
  return (
    <AntdButton
      style={style}
      className={`button_${type} button_status_${buttonStatus} ${
        className ? className : ''
      } ${
        children && (leftIcon || rightIcon || badge || type === 'link')
          ? styles['variable']
          : children
          ? styles['Button']
          : styles['circle']
      } ${fullWidth ? styles['fullWidth'] : ''} ${styles[`size-${size}`]} ${
        onMouseDown ? `${type}-button-mouseDown` : ''
      }`}
      htmlType={htmlType}
      onClick={onClick}
      disabled={disabled}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onMouseDown={() => setOnMouseDown(true)}
      onMouseUp={() => setOnMouseDown(false)}
    >
      {leftIcon && (
        <div
          className={`${styles['div-leftIcon']} ${
            noIconPadding ? styles['div-noPadding'] : ''
          }`}
        >
          <Icon className="button-leftIcon" size="M" icon={leftIcon} />
        </div>
      )}
      {loading && (
        <Spinner
          className="button-spinner"
          type={
            type === 'secondary'
              ? 'black'
              : type === 'tertiary'
              ? 'black'
              : type === 'primary'
              ? 'white'
              : 'brand'
          }
        />
      )}
      <Text
        className={`${contentChild && styles[contentChild]} ${
          styles['children']
        }`}
        type="ui-tiny-bold"
        children={children}
        width={widthChild}
        style={{ overflow: 'hidden' }}
      />
      {badge && (
        <Badge
          className="button-badge"
          type={badge.badgeType}
          amount={badge.amount}
        />
      )}
      {children ? (
        rightIcon && (
          <div className={styles['div-rightIcon']}>
            <Icon className="button-rightIcon" size="M" icon={rightIcon} />
          </div>
        )
      ) : loading ? (
        <Spinner
          className="spinnerAlone"
          type={
            type === 'secondary'
              ? 'black'
              : type === 'tertiary'
              ? 'black'
              : type === 'primary'
              ? 'white'
              : 'brand'
          }
        />
      ) : (
        rightIcon && (
          <div className={styles['div-rightIcon']}>
            <Icon className="button-rightIcon" size="M" icon={rightIcon} />
          </div>
        )
      )}
    </AntdButton>
  );
};
