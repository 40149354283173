import { useMutation, useQuery } from '@tanstack/react-query';
import { LossesAPI } from '../../Data/API/Supply/LossesApi';
import { IPagination } from '../../Components/Table';

export const FETCH_LIST_LOSSES = '[LOSSE] FETCH_LIST_LOSSES';
export const FETCH_CREATE_LOSSE = '[LOSSE] FETCH_CREATE_LOSSE';
export const FETCH_GET_LOSSE = '[LOSSE] FETCH_GET_LOSSE';
export const FETCH_LIST_DROPDOWN_REASON_LOSS =
  '[LOSSE] FETCH_LIST_DROPDOWN_REASON_LOSS';

export const EDIT_LOSS = '[LOSSE] EDIT_LOSS';

export const useCreateLosse = () =>
  useMutation([FETCH_CREATE_LOSSE], LossesAPI.createLosse);

export const useLosseGet = (externalId: string) =>
  useQuery([FETCH_GET_LOSSE, externalId], LossesAPI.getLoss);

export const useLossesList = (pagination: IPagination) =>
  useQuery([FETCH_LIST_LOSSES, pagination], LossesAPI.getLossesList, {
    staleTime: 60000,
  });

export const useReasonLossGetDropdown = (pagination: IPagination) =>
  useQuery(
    [FETCH_LIST_DROPDOWN_REASON_LOSS, pagination],
    LossesAPI.getReasonLossListDropdown
  );

export const useLossEdit = () => useMutation([EDIT_LOSS], LossesAPI.updateLoss);
