import { useQueryClient } from '@tanstack/react-query';
import { FormikHelpers, FormikValues, useFormikContext } from 'formik';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InputWithSearch } from '../../../../../../Components/Inputs/InputWithSearch';
import {
  patternLabelStyle,
  TextInput,
} from '../../../../../../Components/Inputs/TextInput';
import { Modal } from '../../../../../../Components/Modal';
import { IEditProductSubGroupRequest } from '../../../../../../Data/Interfaces/request/Product/IProductRequest';
import {
  FETCH_LIST_SUBGROUP,
  useGroupGetDropdown,
  useSubGroupCreate,
  useSubGroupEdit,
  useSubGroupValidationName,
} from '../../../../../../Hooks/Supply/ProductHooks';

interface IFormGroupModal {
  visible: boolean;
  changeVisible: (value: boolean) => void;
  group?: any;
  title?: string;
  selectedRowsOnTable?: any;
}
const abbreviatedI18n = 'products.subGroupList.modal';

export const FormSubGroupModal: React.FC<IFormGroupModal> = ({
  visible,
  changeVisible,
  title,
  selectedRowsOnTable,
}) => {
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const { t } = useTranslation();

  const createSubGroup = useSubGroupCreate();
  const editSubGroup = useSubGroupEdit();

  const queryClient = useQueryClient();

  const onSubmit = async (
    values: IEditProductSubGroupRequest,
    helpers: FormikHelpers<FormikValues>
  ) => {
    const body: IEditProductSubGroupRequest = { ...values, helpers };
    if (title && selectedRowsOnTable) {
      const res = await editSubGroup.mutateAsync({
        subGrupoExternalId: selectedRowsOnTable[0]?.externalId,
        descricao: body.descricao,
        grupoPaiExternalId: body.grupoPaiExternalId,
        helpers,
      });
      if (res.success) queryClient.invalidateQueries([FETCH_LIST_SUBGROUP]);
      changeVisible(!visible);
    } else {
      const res = await createSubGroup.mutateAsync({
        descricao: body?.descricao,
        grupoPaiExternalId: body.grupoPaiExternalId,
        helpers,
      });
      if (res.success) queryClient.invalidateQueries([FETCH_LIST_SUBGROUP]);
      changeVisible(!visible);
    }
  };

  return (
    <Modal
      onOkClickForm={(values, formik) => onSubmit(values, formik)}
      title={title ? title : t(`${abbreviatedI18n}.title`)}
      body={<ModalBody onDisabledButton={setButtonDisabled} />}
      visible={visible}
      onCancelClick={() => changeVisible(!visible)}
      htmlType="submit"
      okButtonName={title ? '' : t(`${abbreviatedI18n}.okButton`)}
      onClose={() => changeVisible(!visible)}
      disabledButton={!buttonDisabled}
      initialValues={{
        descricao: selectedRowsOnTable && selectedRowsOnTable[0]?.descricao,
        grupoPaiExternalId:
          selectedRowsOnTable && selectedRowsOnTable[0]?.grupoExternalId,
      }}
      withForm
    />
  );
};

interface IModalBody {
  onDisabledButton?: (value: boolean) => void;
}

const ModalBody: React.FC<IModalBody> = ({ onDisabledButton }) => {
  const form: any = useFormikContext();
  const { t } = useTranslation();
  const { data: dataGroupDropDown } = useGroupGetDropdown({
    PageIndex: 0,
    PageSize: 20,
  });

  const onSubGroupValidationError = useCallback(() => {
    form?.setErrors({
      ...form.errors,
      descricao: t('products.create.errors.errorSubGroupExist'),
    });
  }, [form, t]);

  const onSubGroupValidationSuccess = useCallback(() => {
    form?.setErrors({
      ...form.errors,
      descricao: undefined,
    });
  }, [form, t]);

  const { mutateAsync } = useSubGroupValidationName({
    onError: onSubGroupValidationError,
    onSuccess: onSubGroupValidationSuccess,
  });

  useEffect(() => {
    onDisabledButton &&
      onDisabledButton(
        form?.values?.descricao && form?.values?.grupoPaiExternalId
      );
  }, [
    onDisabledButton,
    form?.values?.grupoPaiExternalId,
    form?.values?.descricao,
  ]);

  const fetchProductSubGroupValidationName = useMemo(
    () =>
      _.debounce(async (value?: string, groupExternalId?: string) => {
        return (
          groupExternalId &&
          value &&
          (await mutateAsync({
            descricao: value,
            grupoExternalId: groupExternalId,
          }))
        );
      }, 500),
    [mutateAsync]
  );

  return (
    <div style={{ width: '359px', padding: '20px' }}>
      <TextInput
        name="descricao"
        label={[
          {
            children: t(`${abbreviatedI18n}.subGroupLabel`),
            ...patternLabelStyle,
          },
        ]}
        placeHolder={t(`${abbreviatedI18n}.subGroupPlaceholder`)}
        type="text"
        onChange={(x) => {
          fetchProductSubGroupValidationName(x, form.values.grupoPaiExternalId);
        }}
      />

      <InputWithSearch
        label={[
          {
            children: t(`${abbreviatedI18n}.groupLabel`),
            ...patternLabelStyle,
          },
        ]}
        items={dataGroupDropDown?.data.map((x: any) => ({
          id: x.externalId,
          label: x.descricao,
        }))}
        placeHolder={t(`${abbreviatedI18n}.groupPlaceholder`)}
        name="grupoPaiExternalId"
        withoutMarginBottom
        onChange={(x) => {
          fetchProductSubGroupValidationName(form.values.descricao, x);
        }}
      />
    </div>
  );
};
