import { createAction, Action, ActionsUnion } from '../Base/actionHelpers';
import {
  ICreateStorageLocation,
} from '../../Data/Interfaces/request/StorageLocation/IStorageLocationRequest';
import { IPagination, IQueryParams } from '../../Components/Table';
import {
  IStorageLocationListResponse,
  IGetStorageLocationResponse,
  IStorageLocationListDropDownResponse,
} from '../../Data/Interfaces/response/StorageLocation/IStorageLocationResponse';
import {
  IPatchStorageLocationStatusRequest,
  IEditStorageLocationRequest,
} from '../../Data/Interfaces/request/StorageLocation/IStorageLocationRequest';

export enum StorageLocationActionKeys {
  SET_LOADING = '[STORAGE_LOCATION] SET_LOADING',

  CREATE_STORAGE_LOCATION_REQUEST = '[STORAGE_LOCATION] CREATE_STORAGE_LOCATION_REQUEST',
  CREATE_STORAGE_LOCATION_FAILURE = '[STORAGE_LOCATION] CREATE_STORAGE_LOCATION_FAILURE',

  STORAGE_LOCATION_LIST_REQUEST = '[STORAGE_LOCATION] STORAGE_LOCATION_LIST_REQUEST',
  STORAGE_LOCATION_LIST_ACCOUNTMANAGER_REQUEST = '[STORAGE_LOCATION] STORAGE_LOCATION_LIST_ACCOUNTMANAGER_REQUEST',
  STORAGE_LOCATION_LIST_SUCCESS = '[STORAGE_LOCATION] STORAGE_LOCATION_LIST_SUCCESS',
  STORAGE_LOCATION_LIST_FAILURE = '[STORAGE_LOCATION] STORAGE_LOCATION_LIST_FAILURE',

  STORAGE_LOCATION_DROPDOWN_LIST_REQUEST = '[STORAGE_LOCATION] STORAGE_LOCATION_DROPDOWN_LIST_REQUEST',
  STORAGE_LOCATION_DROPDOWN_LIST_SUCCESS = '[STORAGE_LOCATION] STORAGE_LOCATION_DROPDOWN_LIST_SUCCESS',

  GET_STORAGE_LOCATION_REQUEST = '[STORAGE_LOCATION] GET_STORAGE_LOCATION_REQUEST',
  GET_STORAGE_LOCATION_SUCCESS = '[STORAGE_LOCATION] GET_STORAGE_LOCATION_SUCCESS',
  GET_STORAGE_LOCATION_FAILURE = '[STORAGE_LOCARION] GET_STORAGE_LOCATION_FAILURE',

  PATCH_STATUS_REQUEST = '[STORAGE_LOCATION] PATCH_STATUS_REQUEST',
  PATCH_STATUS_FAILURE = '[STORAGE_LOCATION] PATCH_STATUS_FAILURE',
  PATCH_STATUS_SUCCESS = '[STORAGE_LOCATION] PATCH_STATUS_SUCCESS',

  EDIT_STORAGE_LOCATION_REQUEST = '[STORAGE_LOCATION] EDIT_STORAGE_LOCATION_REQUEST',
  EDIT_STORAGE_LOCATION_FAILURE = '[STORAGE_LOCATION] EDIT_STORAGE_LOCATION_FAILURE',

  THERE_IS_STORAGE_LOCATION_NAME_REQUEST = '[STORAGE_LOCATION] THERE_IS_STORAGE_LOCATION_NAME_REQUEST',
  THERE_IS_STORAGE_LOCATION_NAME_SUCCESS = '[STORAGE_LOCATION] THERE_IS_STORAGE_LOCATION_NAME_SUCCESS',
}

export const StorageLocationActions = {
  setLoading: (loading: boolean): SetLoadingAction =>
    createAction(StorageLocationActionKeys.SET_LOADING, loading),

  createStorageLocation: (
    body: ICreateStorageLocation
  ): CreateStorageLocationRequestAction =>
    createAction(
      StorageLocationActionKeys.CREATE_STORAGE_LOCATION_REQUEST,
      body
    ),
  createStorageLocationFailure: (
    error: string
  ): CreateStorageLocationFailureAction =>
    createAction(
      StorageLocationActionKeys.CREATE_STORAGE_LOCATION_FAILURE,
      error
    ),

  getStorageLocationList: (
    body: IQueryParams
  ): StorageLocationListRequestAction =>
    createAction(StorageLocationActionKeys.STORAGE_LOCATION_LIST_REQUEST, body),
  getStorageLocationListAccountmanager: (
    body: IPagination
  ): StorageLocationListAccountmanagerRequestAction =>
    createAction(
      StorageLocationActionKeys.STORAGE_LOCATION_LIST_ACCOUNTMANAGER_REQUEST,
      body
    ),
  getStorageLocationListSuccess: (
    res: IStorageLocationListResponse
  ): StorageLocationListSuccessAction =>
    createAction(StorageLocationActionKeys.STORAGE_LOCATION_LIST_SUCCESS, res),
  getStorageLocationListFailure: (
    error: string
  ): StorageLocationListFailureAction =>
    createAction(
      StorageLocationActionKeys.STORAGE_LOCATION_LIST_FAILURE,
      error
    ),

  getStorageLocationDropDownList: (
    body: IPagination
  ): getStorageLocationDropDownListAction =>
    createAction(
      StorageLocationActionKeys.STORAGE_LOCATION_DROPDOWN_LIST_REQUEST,
      body
    ),
  getStorageLocationDropDownListSuccess: (
    data: IStorageLocationListDropDownResponse
  ): getStorageLocationDropDownListSuccessAction =>
    createAction(
      StorageLocationActionKeys.STORAGE_LOCATION_DROPDOWN_LIST_SUCCESS,
      data
    ),

  getStorageLocation: (externalId: string): GetStorageLocationRequestAction =>
    createAction(
      StorageLocationActionKeys.GET_STORAGE_LOCATION_REQUEST,
      externalId
    ),
  getStorageLocationSuccess: (
    res: IGetStorageLocationResponse
  ): GetStorageLocationSuccessAction =>
    createAction(StorageLocationActionKeys.GET_STORAGE_LOCATION_SUCCESS, res),
  getStorageLocationFailure: (error: string): GetStorageLocationFailureAction =>
    createAction(StorageLocationActionKeys.GET_STORAGE_LOCATION_FAILURE, error),

  patchStorageLocationStatus: (
    body: IPatchStorageLocationStatusRequest
  ): PatchStorageLocationStatusRequestAction =>
    createAction(StorageLocationActionKeys.PATCH_STATUS_REQUEST, body),
  patchStorageLocationStatusFailure: (
    error: string
  ): PatchStorageLocationStatusFailureAction =>
    createAction(StorageLocationActionKeys.PATCH_STATUS_FAILURE, error),
  patchStorageLocationStatusSuccess: (
    res: IPatchStorageLocationStatusRequest
  ): PatchStorageLocationSuccessAction =>
    createAction(StorageLocationActionKeys.PATCH_STATUS_SUCCESS, res),

  editStorageLocation: (
    body: IEditStorageLocationRequest
  ): EditStorageLocationRequestAction =>
    createAction(StorageLocationActionKeys.EDIT_STORAGE_LOCATION_REQUEST, body),
  editStorageLocationFailure: (
    error: string
  ): EditStorageLocationFailureAction =>
    createAction(
      StorageLocationActionKeys.EDIT_STORAGE_LOCATION_FAILURE,
      error
    ),

  isDescStorageLocationAlreadyRegistred: (
    body: IQueryParams
  ): ThereIsDescStorageLocationRequestAction =>
    createAction(
      StorageLocationActionKeys.THERE_IS_STORAGE_LOCATION_NAME_REQUEST,
      body
    ),
  isDescStorageLocationAlreadyRegistredSuccess: (
    body: boolean
  ): ThereIsDescStorageLocationSuccessAction =>
    createAction(
      StorageLocationActionKeys.THERE_IS_STORAGE_LOCATION_NAME_SUCCESS,
      body
    ),
};

export type StorageLocationActionUnion = ActionsUnion<
  typeof StorageLocationActions
>;

export type SetLoadingAction = Action<
  StorageLocationActionKeys.SET_LOADING,
  boolean
>;

export type CreateStorageLocationRequestAction = Action<
  StorageLocationActionKeys.CREATE_STORAGE_LOCATION_REQUEST,
  ICreateStorageLocation
>;
export type CreateStorageLocationFailureAction = Action<
  StorageLocationActionKeys.CREATE_STORAGE_LOCATION_FAILURE,
  string
>;

export type StorageLocationListRequestAction = Action<
  StorageLocationActionKeys.STORAGE_LOCATION_LIST_REQUEST,
  IQueryParams
>;
export type StorageLocationListAccountmanagerRequestAction = Action<
  StorageLocationActionKeys.STORAGE_LOCATION_LIST_ACCOUNTMANAGER_REQUEST,
  IPagination
>;
export type getStorageLocationDropDownListAction = Action<
  StorageLocationActionKeys.STORAGE_LOCATION_DROPDOWN_LIST_REQUEST,
  IPagination
>;
export type getStorageLocationDropDownListSuccessAction = Action<
  StorageLocationActionKeys.STORAGE_LOCATION_DROPDOWN_LIST_SUCCESS,
  IStorageLocationListDropDownResponse
>;

export type StorageLocationListSuccessAction = Action<
  StorageLocationActionKeys.STORAGE_LOCATION_LIST_SUCCESS,
  IStorageLocationListResponse
>;
export type StorageLocationListFailureAction = Action<
  StorageLocationActionKeys.STORAGE_LOCATION_LIST_FAILURE,
  string
>;

export type GetStorageLocationRequestAction = Action<
  StorageLocationActionKeys.GET_STORAGE_LOCATION_REQUEST,
  string
>;
export type GetStorageLocationSuccessAction = Action<
  StorageLocationActionKeys.GET_STORAGE_LOCATION_SUCCESS,
  IGetStorageLocationResponse
>;
export type GetStorageLocationFailureAction = Action<
  StorageLocationActionKeys.GET_STORAGE_LOCATION_FAILURE,
  string
>;

export type PatchStorageLocationStatusRequestAction = Action<
  StorageLocationActionKeys.PATCH_STATUS_REQUEST,
  IPatchStorageLocationStatusRequest
>;
export type PatchStorageLocationStatusFailureAction = Action<
  StorageLocationActionKeys.PATCH_STATUS_FAILURE,
  string
>;
export type PatchStorageLocationSuccessAction = Action<
  StorageLocationActionKeys.PATCH_STATUS_SUCCESS,
  IPatchStorageLocationStatusRequest
>;

export type EditStorageLocationRequestAction = Action<
  StorageLocationActionKeys.EDIT_STORAGE_LOCATION_REQUEST,
  IEditStorageLocationRequest
>;
export type EditStorageLocationFailureAction = Action<
  StorageLocationActionKeys.EDIT_STORAGE_LOCATION_FAILURE,
  string
>;

export type ThereIsDescStorageLocationRequestAction = Action<
  StorageLocationActionKeys.THERE_IS_STORAGE_LOCATION_NAME_REQUEST,
  IQueryParams
>;
export type ThereIsDescStorageLocationSuccessAction = Action<
  StorageLocationActionKeys.THERE_IS_STORAGE_LOCATION_NAME_SUCCESS,
  boolean
>;