import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { TableLayout } from '../../../Components/Tabs/TablePreference';
import { UserActions } from '../../../Store/User/User.actions';
import ThemeMode from './Components/ThemeMode';
import { Text } from '../../../Components/Text';

import styles from './Personalization.module.scss';
import StorageUtils from '../../../Utils/StorageUtils';
import { receiveDarkmode } from './Theme.helpers';
import { Themes } from '../../../Utils/ThemeUtils';
import { getMeUser } from '../../../Store/User/User.selector';

export const Theme: FC = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const user = useSelector(getMeUser);

  const preference = StorageUtils.getPreference();

  const [check, setCheck] = useState(Themes[receiveDarkmode(preference, user)]);

  const onSubmit = (themeMode: Themes) => {
    const body = { temaUsuario: themeMode };
    user &&
      dispatch(
        UserActions.patchMe({
          userExternalId: user.externalId,
          data: body,
        })
      );
  };

  return (
    <TableLayout
      title={t('preferences.themePage.title')}
      description={t('preferences.themePage.description')}
      body={
        <div className={styles['divThemes']}>
          <div>
            <ThemeMode
              theme="lightMode"
              onClick={() => {
                onSubmit(Themes.lightMode);
                setCheck(Themes[Themes.lightMode]);
              }}
              check={check === Themes[1]}
            />
            <Text
              className={styles['text']}
              type="paragraph2"
              color="text-100"
              children={t('preferences.themePage.lightMode')}
            />
          </div>
          <div>
            <ThemeMode
              theme="midnightMode"
              onClick={() => {
                onSubmit(Themes.midnightMode);
                setCheck(Themes[Themes.midnightMode]);
              }}
              check={check === Themes[2]}
            />
            <Text
              className={styles['text']}
              type="paragraph2"
              color="text-100"
              children={t('preferences.themePage.midnightMode')}
            />
          </div>
          <div>
            <ThemeMode
              theme="darkMode"
              onClick={() => {
                onSubmit(Themes.darkMode);
                setCheck(Themes[Themes.darkMode]);
              }}
              check={check === Themes[3]}
            />
            <Text
              className={styles['text-last']}
              type="paragraph2"
              color="text-100"
              children={t('preferences.themePage.darkMode')}
            />
          </div>
        </div>
      }
    />
  );
};
