export const stockBalanceColumns = (t: any) => [
  {
    title: t('products.productList.stockBalance.code'),
    dataIndex: 'sequenciaGroupTenant',
    key: 'sequenciaGroupTenant',
  },
  {
    title: t('products.productList.stockBalance.placeName'),
    dataIndex: 'descricao',
    key: 'descricao',
    sortColumn: 'descricao',
    width: '50%',
    sorter: true,
    isHighlightable: true,
    defaultSortOrder: 'ascend',
  },
  {
    title: t('products.productList.stockBalance.company'),
    dataIndex: 'nomeFantasia',
    key: 'nomeFantasia',
    width: '20%',
  },
  {
    title: t('products.productList.stockBalance.availableQuantity'),
    dataIndex: 'saldo',
    key: 'saldo',
    render: (x: any, row: any) => `${x} ${row?.unidadeMedidaAbreviacao}`,
  },
];
