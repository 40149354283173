import { useCallback, useEffect, useState } from 'react';
import { Col, Row } from 'antd';
import { useFormikContext } from 'formik';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Card,
  Checkbox,
  Dropdown,
  InputWithSearch,
  TextInput,
  Tooltip,
} from '../../../../../Components';
import {
  useGroupGetDropdown,
  useProductGet,
  useProductValidationName,
  useSubGroupByGroupGetDropdown,
} from '../../../../../Hooks/Supply/ProductHooks';
import { TextInputState } from '../../../../../Components/StateInputs/TextInput';
import { useParams } from 'react-router';
import { useClassTypeGetDropdown } from '../../../../../Hooks/Supply/ClassTypeHooks';
import { useUnitMeasurementByClassGetDropdown } from '../../../../../Hooks/Supply/UnitMeasurementHooks';
import { useProviderGetList } from '../../../../../Hooks/People/ProviderHooks';
import { IProductResponse } from '../../../../../Data/Interfaces/response/Product/IProductRespose';
import { Modal } from '../../../../../Components/Modal';
import { ModalBody } from '../../EditProduct/Modal/lotControlModal';
import { useStockBalanceByProductGet } from '../../../../../Hooks/Supply/StockBalanceHooks';
import _ from 'lodash';

import styles from './ProductCard.module.scss';

interface IProductCardBody {
  product?: IProductResponse;
  disabledForm?: boolean;
}

export const ProductInfoCard: FC<IProductCardBody> = ({
  disabledForm,
  product,
}) => {
  const form: any = useFormikContext();
  const { externalId } = useParams<{ externalId: string }>();
  const { data } = useProductGet(externalId);
  const { t } = useTranslation();
  const [lotControlModal, setLotControlModal] = useState(false);

  const { data: listClassTypes } = useClassTypeGetDropdown({
    PageIndex: 0,
    PageSize: 10,
  });

  const { data: listGroups } = useGroupGetDropdown({
    PageIndex: 0,
    PageSize: 10,
  });

  const { data: listSubGroups } = useSubGroupByGroupGetDropdown(
    {
      PageIndex: 0,
      PageSize: 10,
    },
    form?.values?.grupoExternalId
  );

  const { data: listunitsMeasurements } = useUnitMeasurementByClassGetDropdown(
    {
      PageIndex: 0,
      PageSize: 10,
    },
    form?.values?.classeProdutoId
  );

  const { data: listProviders } = useProviderGetList({
    PageIndex: 0,
    PageSize: 10,
  });

  useEffect(() => {
    form.values.grupoExternalId !== form.initialValues.grupoExternalId &&
      form?.setFieldValue('subGrupoExternalId', undefined);
  }, [form?.values?.grupoExternalId]);

  useEffect(() => {
    if (!disabledForm) {
      form.resetForm();
    }
  }, [disabledForm]);

  const { data: stockBalance } = useStockBalanceByProductGet(externalId);

  return (
    <>
      <Card title={t('products.create.titleInformation')} withoutCollapse>
        <Row gutter={[16, 0]}>
          {product && (
            <Col span={4}>
              <TextInputState
                label={t('products.create.productCode')}
                placeHolder={t('products.create.productCode')}
                disabled
                rightIcon={{
                  icon: 'copy',
                  copyName: 'codigoProduto',
                  toolTipTitle: [
                    `${t('common.copy')} ${t('common.code')}`,
                    t('common.copied'),
                  ],
                }}
                value={product.codigoProduto.toString()}
              />
            </Col>
          )}
          <Col span={product ? 10 : 12}>
            <Tooltip
              title={`${t('products.toolTips.products.nameOfProduct')} ${
                product?.descricao
              }`}
              showMe
            >
              <InputProductName
                disabledForm={disabledForm}
                product={!!product}
              />
            </Tooltip>
          </Col>
          <Col span={product ? 10 : 12}>
            <Tooltip
              title={`${t('products.toolTips.products.labelDescription')} ${
                product?.descricaoRotulo
              }`}
              showMe
            >
              <TextInput
                name="descricaoRotulo"
                label={[
                  {
                    children: t('products.create.descriptionLabel'),
                  },
                ]}
                placeHolder={t('products.create.descriptionLabel')}
                disabled={disabledForm}
                withStatusIcon={!!product}
                maxLength={200}
              />
            </Tooltip>
          </Col>
        </Row>

        <Row gutter={[16, 0]}>
          <Col span={6}>
            <Tooltip
              title={`${t('products.toolTips.products.class')} ${
                product?.classeProduto.abreviacao
              }`}
              showMe
            >
              <Dropdown
                items={listClassTypes?.data?.map((x: any) => ({
                  id: x.id,
                  name: x.descricao,
                }))}
                name="classeProdutoId"
                label={[
                  {
                    children: t('products.create.class'),
                  },
                ]}
                placeHolder={t('products.create.classPlaceholder')}
                disabled={disabledForm}
                required={!product}
                withStatusIcon={!!product}
                onChange={(value: any) =>
                  form.setFieldValue(
                    'unidadeEstoqueId',
                    listClassTypes?.data.find((x) => x.id === value)
                      ?.unidadePadraoVisualizacaoId
                  )
                }
              />
            </Tooltip>
          </Col>

          <Col span={6}>
            <Tooltip
              title={`${t('products.toolTips.products.nameOfGroup')} ${
                product?.grupo
              }`}
              showMe
            >
              <Dropdown
                items={listGroups?.data?.map((x) => ({
                  id: x.externalId,
                  name: x.descricao,
                }))}
                name="grupoExternalId"
                label={[
                  {
                    children: t('products.create.group'),
                  },
                ]}
                placeHolder={t('products.create.groupPlaceholder')}
                disabled={disabledForm}
                required={!product}
                withStatusIcon={!!product}
              />
            </Tooltip>
          </Col>

          <Col span={6}>
            <Tooltip
              title={`${t('products.toolTips.products.nameOfSubGroup')} ${
                product?.subGrupo
              }`}
              showMe
            >
              <Dropdown
                items={listSubGroups?.data?.map((x) => ({
                  id: x.externalId,
                  name: x.descricao,
                }))}
                name="subGrupoExternalId"
                label={[
                  {
                    children: t('products.create.subGroup'),
                  },
                ]}
                placeHolder={t('products.create.subGroupPlaceholder')}
                disabled={disabledForm || !form?.values.grupoExternalId}
                required={!product}
                withStatusIcon={!!product}
              />
            </Tooltip>
          </Col>

          <Col span={6}>
            <Tooltip
              title={`${t('products.toolTips.products.unitStock')} ${
                product?.unidadeMedida.abreviacao
              } (${product?.unidadeMedida.descricao})`}
              showMe
            >
              <Dropdown
                items={listunitsMeasurements?.data?.map((x) => ({
                  id: x.id,
                  name: `${x.descricao} (${x.unidadeAbreviacao})`,
                }))}
                name="unidadeEstoqueId"
                label={[
                  {
                    children: t('products.create.measure'),
                  },
                ]}
                placeHolder={t('products.create.measurePlaceholder')}
                disabled={disabledForm || !form?.values.classeProdutoId}
                required={!product}
                withStatusIcon={!!product}
              />
            </Tooltip>
          </Col>
        </Row>
        <Row>
          <Col span={8}>
            <Tooltip
              title={`${t('products.toolTips.products.provider')} ${
                product?.fornecedor
              }`}
              showMe
            >
              <InputWithSearch
                items={listProviders?.data?.map((x) => ({
                  id: x.externalId,
                  label: x.nome,
                  subLabel: `${x.tipoPessoaNome} - ${x.tipoFornecedor}`,
                }))}
                name="fornecedorExternalId"
                label={[
                  {
                    children: t('products.create.provider'),
                  },
                ]}
                placeHolder={t('products.create.providerPlaceholder')}
                disabled={disabledForm}
                withTooltip={{
                  icon: 'question-mark',
                  title: t('products.create.providerTooltip'),
                }}
                withStatusIcon={!!product}
              />
            </Tooltip>
          </Col>
        </Row>

        <div className={styles['divider']} />

        <Row gutter={[16, 0]}>
          <Col span={6} className={styles['checkbox']}>
            <Tooltip
              title={`${t('products.toolTips.products.originalValue')} ${
                product?.controlaLote === true
                  ? t('products.toolTips.products.enabled')
                  : t('products.toolTips.products.disabled')
              }`}
              showMe
            >
              <Checkbox
                name="controlaLote"
                label={t('products.create.partControl')}
                disabled={disabledForm}
                withStatusIcon={!!product}
                withTooltip={{
                  icon: 'question-mark',
                  title: t('products.create.batchControlTooltip'),
                }}
                onClick={() => {
                  stockBalance &&
                    stockBalance?.saldo > 0 &&
                    setLotControlModal(true);
                }}
              />
            </Tooltip>
          </Col>

          <Col span={6} className={styles['checkbox']}>
            <Tooltip
              title={`${t('products.toolTips.products.originalValue')} ${
                product?.controlaQualidade === true
                  ? t('products.toolTips.products.enabled')
                  : t('products.toolTips.products.disabled')
              }`}
              showMe
            >
              <Checkbox
                name="controlaQualidade"
                label={t('products.create.qualityControl')}
                disabled={disabledForm}
                withStatusIcon={!!product}
                withTooltip={{
                  icon: 'question-mark',
                  title: t('products.create.qualityControlTooltip'),
                }}
              />
            </Tooltip>
          </Col>

          <Col span={6} className={styles['checkbox']}>
            <Tooltip
              title={`${t('products.toolTips.products.originalValue')} ${
                product?.usoContinuo === true
                  ? t('products.toolTips.products.enabled')
                  : t('products.toolTips.products.disabled')
              }`}
              showMe
            >
              <Checkbox
                name="usoContinuo"
                label={t('products.create.continueUse')}
                disabled={disabledForm}
                withStatusIcon={!!product}
                withTooltip={{
                  icon: 'question-mark',
                  title: t('products.create.continueUseTooltip'),
                }}
              />
            </Tooltip>
          </Col>

          <Col span={6} className={styles['checkbox']}>
            <Tooltip
              title={`${t('products.toolTips.products.originalValue')} ${
                product?.etiqueta === true
                  ? t('products.toolTips.products.enabled')
                  : t('products.toolTips.products.disabled')
              }`}
              showMe
            >
              <Checkbox
                name="etiqueta"
                label={t('products.create.tag')}
                disabled={disabledForm}
                withStatusIcon={!!product}
                withTooltip={{
                  icon: 'question-mark',
                  title: t('products.create.tagTooltip'),
                }}
              />
            </Tooltip>
          </Col>
        </Row>
      </Card>

      <Modal
        visible={lotControlModal}
        onCancelClick={() => setLotControlModal(false)}
        cancelButtonName={t('common.close')}
        title={t('products.modal.products.title')}
        withoutOkButton
        body={<ModalBody />}
      />
    </>
  );
};

interface IInputProductName {
  disabledForm?: boolean;
  product?: boolean;
}

const InputProductName: FC<IInputProductName> = ({ disabledForm, product }) => {
  const { t } = useTranslation();
  const form: any = useFormikContext();

  const onProductValidationError = useCallback(() => {
    form.setErrors({
      ...form.errors,
      descricao: t('products.create.errors.errorNameExist'),
    });
  }, [form, t]);

  const { mutateAsync, error } = useProductValidationName({
    onError: onProductValidationError,
  });

  const fetchProductValidationName = useCallback(
    _.debounce(async (description: string) => {
      await mutateAsync({
        descricao: description,
      });
    }, 500),
    [mutateAsync]
  );

  return (
    <TextInput
      name="descricao"
      label={[
        {
          children: t('products.create.productName'),
        },
      ]}
      placeHolder={t('products.create.productName')}
      required={!product}
      disabled={disabledForm}
      onChange={fetchProductValidationName}
      withStatusIcon={!!product}
      rightIcon={{
        icon: 'copy',
        copyName: 'descricao',
        toolTipTitle: [
          `${t('common.copy')} ${t('common.name')}`,
          t('common.copied'),
        ],
      }}
      maxLength={100}
      error={!!error}
    />
  );
};
