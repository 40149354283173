import { createAction, Action, ActionsUnion } from '../Base/actionHelpers';
import { IGetCEPResponse } from '../../Data/Interfaces/response/CEP/ICEPResponse';

export enum CEPActionKeys {
  RESET_ADRESS = '[CEP] RESET_ADRESS',

  GET_CEP_REQUEST = '[CEP] GET_CEP_REQUEST',
  GET_CEP_SUCCESS = '[CEP] GET_CEP_SUCCESS',
  GET_CEP_FAILURE = '[CEP] GET_CEP_FAILURE',
}

export const CEPActions = {
  resetAdress: (): ResetAdressAction =>
    createAction(CEPActionKeys.RESET_ADRESS),

  getCEP: (body: string): GetCEPCurrencyRequestAction =>
    createAction(CEPActionKeys.GET_CEP_REQUEST, body),
  getCEPSuccess: (res: IGetCEPResponse): GetCEPSuccessAction =>
    createAction(CEPActionKeys.GET_CEP_SUCCESS, res),
  getCEPFailure: (error: string): GetCEPFailureAction =>
    createAction(CEPActionKeys.GET_CEP_FAILURE, error),
};

export type CEPActionUnion = ActionsUnion<typeof CEPActions>;

export type ResetAdressAction = Action<CEPActionKeys.RESET_ADRESS>;

export type GetCEPCurrencyRequestAction = Action<
  CEPActionKeys.GET_CEP_REQUEST,
  string
>;
export type GetCEPSuccessAction = Action<
  CEPActionKeys.GET_CEP_SUCCESS,
  IGetCEPResponse
>;
export type GetCEPFailureAction = Action<CEPActionKeys.GET_CEP_FAILURE, string>;
