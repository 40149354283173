import { Col, Row } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, TextInput, InputWithSearch } from '../../../../../../Components';
import { TextInputWithMask } from '../../../../../../Components/Inputs/TextInputWithMask';
import { patternLabelStyle } from '../../../../../../Components/Inputs/TextInput';
import { useFormikContext } from 'formik';
import { useProductGetDropdown } from '../../../../../../Hooks/Supply/ProductHooks';
import {
  useProviderGet,
  useProviderGetList,
} from '../../../../../../Hooks/People/ProviderHooks';

interface IInfoCardBody {
  disabledForm?: boolean;
  onClassTypeSelected: (classTypeId: number | undefined) => void;
}

export const InfoCard: FC<IInfoCardBody> = ({
  disabledForm,
  onClassTypeSelected,
}) => {
  const { t } = useTranslation();
  const form = useFormikContext();

  const [providerId, setProviderId] = useState<string>();
  const { data: provider } = useProviderGet(providerId);
  //AQUI EU VOU CHAMAR REACT QUERY USANDO PROVIDER ID COMO PARAMETRO
  useEffect(() => {
    if (provider) {
      form.setFieldValue('Cnpj', provider.cnpj);
    }
  }, [provider]);

  const { data: productList, isLoading: isProductLoading } =
    useProductGetDropdown({
      PageIndex: 0,
      PageSize: 100,
    });

  const { data: providerList, isLoading: isProviderLoading } =
    useProviderGetList({
      PageIndex: 0,
      PageSize: 100,
    });

  return (
    <Card
      title={t('supply.lot.create.titleInformation')}
      withoutCollapse={true}
    >
      <Row gutter={[16, 0]} justify="space-between">
        <Col span={7}>
          <InputWithSearch
            items={productList?.data?.map((x) => ({
              id: x.externalId,
              label: x.descricao,
              subLabel: `${t('supply.lot.create.code')}: ${
                x.sequenciaGroupTenant
              }`,
            }))}
            onChange={(x) => {
              const productSelected = productList?.data.find(
                (value) => value.externalId === x
              );
              form?.setFieldValue(
                'classe',
                productSelected && productSelected.classeProdutoDescricao
              );
              form?.setFieldValue(
                'classeId',
                productSelected && productSelected.classeProdutoId
              );
              onClassTypeSelected(productSelected?.classeProdutoId);
            }}
            name="produtoExternalId"
            label={t('supply.lot.create.product')}
            placeHolder={t('supply.lot.placeholder.product')}
            disabled={disabledForm}
            isLoading={isProductLoading}
            withTooltip={{
              icon: 'exclamation-upside-down',
              title: t('products.create.providerTooltip'),
            }}
            required={true}
          />
        </Col>
        <Col span={5}>
          <TextInput
            name="classe"
            label={[
              {
                children: t('supply.lot.create.class'),
                ...patternLabelStyle,
              },
            ]}
            withoutMarginBottom={true}
            disabled={true}
            placeHolder="-"
          />
        </Col>

        <Col span={4}>
          <TextInput
            name="numero"
            label={[
              {
                children: t('supply.lot.create.lot'),
                ...patternLabelStyle,
              },
            ]}
            placeHolder={t('supply.lot.placeholder.lot')}
            required={true}
            withoutMarginBottom={true}
          />
        </Col>

        <Col span={4}>
          <TextInputWithMask
            name="dataFabricacao"
            mask="dataMask"
            leftIcon="calendar"
            label={[
              {
                children: t('supply.lot.create.manufacturing'),
                ...patternLabelStyle,
              },
            ]}
            placeHolder={t('dd/mm/yyyy')}
            required={true}
          />
        </Col>
        <Col span={4}>
          <TextInputWithMask
            name="dataValidade"
            mask="dataMask"
            leftIcon="calendar"
            label={[
              {
                children: t('supply.lot.create.validity'),
                ...patternLabelStyle,
              },
            ]}
            placeHolder={t('dd/mm/yyyy')}
            required={true}
          />
        </Col>
      </Row>
      <Row gutter={[16, 0]} justify="space-between">
        <Col span={9}>
          <InputWithSearch
            items={providerList?.data?.map((x) => ({
              id: x.externalId,
              label: x.nome,
              subLabel: `${x.tipoPessoaNome} - ${x.tipoFornecedor}`,
            }))}
            name="fornecedorExternalId"
            label={t('products.create.provider')}
            placeHolder={t('products.create.providerPlaceholder')}
            disabled={disabledForm}
            required={true}
            onChange={(x) => {
              const providerSelected = providerList?.data.find(
                (value) => value.externalId === x
              );

              setProviderId(x);

              form?.setFieldValue(
                'razaoSocial',
                providerSelected && providerSelected.nome
              );
            }}
            isLoading={isProviderLoading}
          />
        </Col>
        <Col span={9}>
          <TextInput
            name="razaoSocial"
            label={[
              {
                children: t('supply.lot.create.corporateName'),
                ...patternLabelStyle,
              },
            ]}
            disabled={true}
            placeHolder="-"
          />
        </Col>
        <Col span={6}>
          <TextInputWithMask
            name="Cnpj"
            mask="cnpjMask"
            label={t('supply.lot.create.cnpj')}
            disabled={true}
            placeHolder="-"
          />
        </Col>
      </Row>
      <Row gutter={[16, 0]}>
        <Col span={4}>
          <TextInput
            name="numeroNf"
            type="number"
            label={[
              {
                children: t('supply.lot.create.numberInvoice'),
                ...patternLabelStyle,
              },
            ]}
            placeHolder={t('supply.lot.placeholder.numberInvoice')}
            required={true}
          />
        </Col>
        <Col span={2}>
          <TextInput
            name="serieNf"
            type="number"
            label={[
              {
                children: t('supply.lot.create.seriesInvoice'),
                ...patternLabelStyle,
              },
            ]}
            placeHolder={t('supply.lot.placeholder.seriesInvoice')}
            required={true}
          />
        </Col>
      </Row>
    </Card>
  );
};

export default InfoCard;
