import { useQuery } from '@tanstack/react-query';
import { IPagination } from '../../Components/Table';
import { StockBalanceAPI } from '../../Data/API/Supply/StockBalanceApi';
import { IQueryListParams } from '../../Utils/Http';

export const FETCH_LIST_STOCK_BALANCE_BY_PRODUCT =
  '[STOCK_BALANCE] FETCH_LIST_STOCK_BALANCE_BY_PRODUCT';

export const FETCH_LIST_STOCK_BALANCE_BY_LOT =
  '[STOCK_BALANCE] FETCH_LIST_STOCK_BALANCE_BY_LOT';

export const FETCH_GET_STOCK_BALANCE_BY_PRODUCT =
  '[STOCK_BALANCE] FETCH_GET_STOCK_BALANCE_BY_PRODUCT';

export const useStockBalanceByProductGetList = (
  pagination: IPagination,
  externalId: string
) =>
  useQuery(
    [FETCH_LIST_STOCK_BALANCE_BY_PRODUCT, pagination, externalId],
    StockBalanceAPI.stockBalanceByProductList,
    { staleTime: 60000 }
  );

export const useStockBalanceByLotGetList = (pagination: IQueryListParams) =>
  useQuery(
    [FETCH_LIST_STOCK_BALANCE_BY_LOT, pagination],
    StockBalanceAPI.stockBalanceByLotList
  );

export const useStockBalanceByProductGet = (produtoExternalId: string) =>
  useQuery(
    [FETCH_GET_STOCK_BALANCE_BY_PRODUCT, produtoExternalId],
    StockBalanceAPI.getStockBalanceByProductGet
  );
