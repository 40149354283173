/* eslint-disable react/jsx-key */
import React, { FC } from 'react';

import { Switch, Route } from 'react-router-dom';
import { UserPreference } from '../../Pages/UserPreference';
import WithSideBar from '../../Layouts/WithSideBar';
import { AccountManage } from '../../Pages/AccounManage';
import { CompanyCreate } from '../../Pages/AdminBootys/Company/CompanyCreate';
import { CompanyAdminList as CompanyList } from '../../Pages/AdminBootys/Company/List';
import { List as ConglomerateList } from '../../Pages/AdminBootys/Conglomerate/List';
import { UserForm } from '../../Pages/Administrative/User/CreateForm';
import { List as UserList } from '../../Pages/Administrative/User/List';
import { List as ProductList } from '../../Pages/Products/Product/List';
import { List as ProductGroupList } from '../../Pages/Products/Groups/List';
import { List as ProductSubGroupList } from '../../Pages/Products/SubGroups/List';
import { List as CompanyManagerList } from '../../Pages/Administrative/Company/List';
import UserGroup from '../../Pages/Administrative/UserGroups/CreateForm';
import StorageLocation from '../../Pages/Supply/SupplyPlaces/CreateForm';
import { ConglomerateForm } from '../../Pages/AdminBootys/Conglomerate/ConglomerateForm';
import { Create } from '../../Pages/Administrative/Company/CompanyForm';
import { EditProductGroupForm } from '../../Pages/Products/Groups/EditForm';
import { Form } from '../../Pages/Products/SubGroups/Form';

import { EditProduct } from '../../Pages/Products/Product/EditProduct';
import { EditLot } from '../../Pages/Supply/Lot/Details';
import CreateLot from '../../Pages/Supply/Lot/CreateForm';
import TransfersList from '../../Pages/Supply/Transfers/List';
import { BalanceAdjustmentsList } from '../../Pages/Supply/BalanceAdjustments/List';
import TransfersDetails from '../../Pages/Supply/Transfers/Details';
import BalanceAdjustmentDetails from '../../Pages/Supply/BalanceAdjustments/Details';
import { EditCompany } from '../../Pages/AdminBootys/Company/CompanyEdit';
import { EditUser } from '../../Pages/Administrative/User/EditForm';
import { PurchaseList } from '../../Pages/Purchasing/List';
import { CreatePrescribers } from '../../Pages/Sales/Prescribers/Create';

const Dashboard = React.lazy(() => import('../../Pages/Dashboard'));

const UserGroupsList = React.lazy(
  () => import('../../Pages/Administrative/UserGroups/List')
);
const ProductCreate = React.lazy(
  () => import('../../Pages/Products/Product/CreateProduct')
);

const EditUserGroup = React.lazy(
  () => import('../../Pages/Administrative/UserGroups/EditForm')
);

const SupplyPlacesList = React.lazy(
  () => import('../../Pages/Supply/SupplyPlaces/List')
);

const LossesRegistration = React.lazy(
  () => import('../../Pages/Supply/Losses/CreateForm')
);
const LossesList = React.lazy(() => import('../../Pages/Supply/Losses/List'));

const LotList = React.lazy(() => import('../../Pages/Supply/Lot/List'));
const LossesEditPage = React.lazy(
  () => import('../../Pages/Supply/Losses/EditPage')
);

const StorageLocationDetail = React.lazy(
  () => import('../../Pages/Supply/SupplyPlaces/Form')
);

const CreateTransfer = React.lazy(
  () => import('../../Pages/Supply/Transfers/createForm')
);

const BalanceAdjustmentCreateForm = React.lazy(
  () => import('../../Pages/Supply/BalanceAdjustments/Create')
);
const ProviderCreate = React.lazy(
  () => import('../../Pages/Purchasing/Providers/Create')
);

const AuthenticatedApp: FC = () => {
  return (
    <div>
      <WithSideBar>
        <Switch>
          <Route path="/" exact component={Dashboard} />

          <Route path="/user-preference/:tab" component={UserPreference} />

          <Route path="/account-manager/:tab" component={AccountManage} />

          <Route
            path="/admin-bootys/company/:externalId/:tab"
            component={EditCompany}
          />
          <Route
            path="/admin-bootys/company/create"
            component={CompanyCreate}
          />

          <Route path="/admin-bootys/company" component={CompanyList} />
          <Route
            path="/admin-bootys/conglomerate/:externalId/:tab"
            component={ConglomerateForm}
          />
          <Route
            path="/admin-bootys/conglomerate"
            component={ConglomerateList}
          />

          <Route path="/admin/user/create" component={UserForm} />
          <Route path="/admin/user/:externalId/:tab" component={EditUser} />
          <Route path="/admin/user" component={UserList} />
          <Route path="/admin/company/:externalId" component={Create} />
          <Route path="/admin/company" component={CompanyManagerList} />
          <Route
            path="/admin/user-group/:externalId/:tab"
            component={EditUserGroup}
          />
          <Route path="/admin/user-group/create" component={UserGroup} />
          <Route path="/admin/user-group" component={UserGroupsList} />

          <Route
            path="/inventory/supply-places/create"
            component={StorageLocation}
          />
          <Route
            path="/inventory/supply-places/:externalId/:tab"
            component={StorageLocationDetail}
          />
          <Route path="/inventory/supply-places" component={SupplyPlacesList} />

          <Route
            path="/inventory/groups/:externalId/:tab"
            component={EditProductGroupForm}
          />
          <Route path="/inventory/groups" component={ProductGroupList} />
          <Route
            path="/inventory/sub-groups/:externalId/:tab"
            component={Form}
          />
          <Route path="/inventory/sub-groups" component={ProductSubGroupList} />
          <Route path="/inventory/products/create" component={ProductCreate} />
          <Route
            path="/inventory/products/:externalId/:tab"
            component={EditProduct}
          />
          <Route path="/inventory/products" component={ProductList} />

          <Route
            path="/inventory/losses/create"
            component={LossesRegistration}
          />
          <Route
            path="/inventory/losses/:externalId/:tab"
            component={LossesEditPage}
          />
          <Route path="/inventory/losses" component={LossesList} />

          <Route path="/inventory/lot/create" component={CreateLot} />
          <Route path="/inventory/lot/:externalId/:tab" component={EditLot} />
          <Route path="/inventory/lot" component={LotList} />

          <Route
            path="/inventory/transfers/create"
            component={CreateTransfer}
          />
          <Route
            path="/inventory/transfers/:externalId"
            component={TransfersDetails}
          />
          <Route path="/inventory/transfers" component={TransfersList} />

          <Route
            path="/inventory/balance-adjustments/create"
            component={BalanceAdjustmentCreateForm}
          />
          <Route
            path="/inventory/balance-adjustments/:externalId/details"
            component={BalanceAdjustmentDetails}
          />
          <Route
            path="/inventory/balance-adjustments"
            component={BalanceAdjustmentsList}
          />

          <Route
            path="/purchasing/provider/create"
            component={ProviderCreate}
          />

          <Route
            path="/sales/prescribes/create"
            component={CreatePrescribers}
          />

          <Route path="/order/purchase" component={PurchaseList} />
        </Switch>
      </WithSideBar>
    </div>
  );
};

export default AuthenticatedApp;
