import React, { FC } from 'react';
import { Button } from '../../..';
import { TextInputState } from './TextInput';

import styles from './HeaderTable.module.scss';
import { useTranslation } from 'react-i18next';
import { IconName } from '../../../Icon/IconClasses';
import {
  IPermissionWrapper,
  PermissionWrapper,
} from '../../../PermissionWrapper';
import { ButtonDropdown } from '../../../Button/ButtonDropdown';
import { motion } from 'framer-motion';

export interface IOptions {
  label: string;
  icon?: IconName;
  permission?: IPermissionWrapper;
  isStatusOnly?: boolean;
  onClick?: () => void;
}

export interface IHeaderTableOptions {
  hasSearch?: boolean;
  searchPlaceholder?: string;
  nameKey?: string;

  newButton?: {
    icon?: IconName;
    label?: string;
    permission?: IPermissionWrapper;
    onClick: () => void;
  };

  editButton?: {
    permission?: IPermissionWrapper;
    options?: IOptions[];
    editButtonWithoutDropdown?: boolean;
    editButtonWithoutDropdownClick?: () => void;
  };

  deleteButton?: {
    icon?: IconName;
    label?: string;
    permission?: IPermissionWrapper;
    onClick?: () => void;
  };
}

export interface IHeader extends IHeaderTableOptions {}

export interface IHeaderTable extends IHeaderTableOptions {
  hasSomeItemSelected: boolean;
  searchPlaceholder?: string;

  onRefetch: (search: string) => void;
  loading: boolean;
}

export const TableHeader: FC<IHeaderTable> = ({
  onRefetch,
  hasSearch,
  hasSomeItemSelected,
  searchPlaceholder,
  newButton,
  editButton,
  deleteButton,
  loading,
}) => {
  const { t } = useTranslation();

  return (
    <div className={styles['Container']}>
      <div className={styles['search']}>
        {hasSearch ? (
          <TextInputState
            isLoading={loading}
            leftIcon="search"
            iconColor="text-400"
            placeHolder={searchPlaceholder}
            onChange={onRefetch}
          />
        ) : null}
      </div>

      <div className={styles['buttons']}>
        <motion.div
          style={{ overflow: 'hidden' }}
          initial={{ width: 'auto' }}
          animate={{
            width: newButton && hasSomeItemSelected ? 0 : 'auto',
          }}
        >
          {newButton && (
            <PermissionWrapper
              permission={newButton.permission?.permission}
              tooltip={newButton.permission?.tooltip || 'noPermission.default'}
            >
              <Button
                type="primary"
                leftIcon={newButton.icon ? newButton.icon : 'plus'}
                onClick={newButton.onClick}
                style={{
                  whiteSpace: 'nowrap',
                  wordBreak: 'keep-all',
                  overflow: 'hidden',
                }}
                children={
                  newButton.label
                    ? t(newButton.label)
                    : t('table.headerTable.new')
                }
              />
            </PermissionWrapper>
          )}
        </motion.div>

        {editButton && (
          <motion.div
            style={{ overflow: 'hidden' }}
            initial={{ width: 0 }}
            animate={{
              width:
                editButton && hasSomeItemSelected
                  ? editButton.editButtonWithoutDropdown
                    ? '90px'
                    : 'auto'
                  : 0,
            }}
          >
            <PermissionWrapper
              permission={editButton.permission?.permission}
              tooltip={editButton.permission?.tooltip || 'noPermission.default'}
            >
              {editButton.editButtonWithoutDropdown ? (
                <Button
                  style={{ width: '200px' }}
                  leftIcon="edit"
                  type="secondary"
                  children={t('table.headerTable.edit')}
                  onClick={editButton.editButtonWithoutDropdownClick}
                />
              ) : (
                <ButtonDropdown
                  style={{ width: '200px' }}
                  leftIcon="edit"
                  type="secondary"
                  dropdownItens={editButton.options}
                  children={t('table.headerTable.edit')}
                  rightIcon="chevron-down"
                />
              )}
            </PermissionWrapper>
          </motion.div>
        )}

        {deleteButton && (
          <motion.div
            style={{ overflow: 'hidden' }}
            initial={{ width: 0 }}
            animate={{
              width: deleteButton && hasSomeItemSelected ? 'auto' : 0,
            }}
          >
            <PermissionWrapper
              permission={deleteButton.permission?.permission}
              tooltip={
                deleteButton.permission?.tooltip || 'noPermission.default'
              }
            >
              <Button
                type="secondary"
                leftIcon={deleteButton.icon ? deleteButton.icon : 'trash'}
                onClick={deleteButton.onClick}
                children={
                  deleteButton.label
                    ? deleteButton.label
                    : t('table.headerTable.delete')
                }
              />
            </PermissionWrapper>
          </motion.div>
        )}
      </div>
    </div>
  );
};
