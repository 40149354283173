import { ILoginResponse } from '../../Data/Interfaces/response/Auth/ILoginResponse'
import StorageUtils from '../../Utils/StorageUtils'
import { AuthActionKeys, AuthActionUnion } from './Auth.actions'
import { CURRENT_USER } from './Auth.constants'
import { IAuthState } from './interfaces/IAuthState'

const currentUser: ILoginResponse = JSON.parse(StorageUtils.GetItem(CURRENT_USER) || '{}')

const isTokenValid =
    (currentUser && currentUser.access && currentUser.expiresIn && new Date() < new Date(currentUser.expiresIn)) ||
    false

const initialData: IAuthState = {
    isLoading: false,
    isAuthenticated: isTokenValid,
    currentUser,
}

export const authReducer = (state = initialData, action: AuthActionUnion): IAuthState => {
    switch (action.type) {
        case AuthActionKeys.LOGIN_REQUEST:
            return {
                ...state,
                isLoading: true,
                error: undefined,
            }
        case AuthActionKeys.LOGIN_SUCCESS:
            return {
                ...state,
                isLoading: false,
                error: undefined,
                isAuthenticated: true,
                currentUser: action.payload,
            }
        case AuthActionKeys.LOGIN_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            }

        case AuthActionKeys.RECOVERY_REQUEST:
            return {
                ...state,
                isLoading: true,
                error: undefined,
            }
        case AuthActionKeys.RECOVERY_SUCCESS:
            return {
                ...state,
                isLoading: false,
                error: undefined,
            }
        case AuthActionKeys.RECOVERY_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            }

        case AuthActionKeys.NEWPASSWORD_REQUEST:
            return {
                ...state,
                isLoading: true,
                error: undefined,
            }
        case AuthActionKeys.NEWPASSWORD_SUCCESS:
            return {
                ...state,
                isLoading: false,
                error: undefined,
            }
        case AuthActionKeys.NEWPASSWORD_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            }
        default:
            return state
    }
}

export default authReducer
