import { IGetNotificationResponse as INotification } from '../../Data/Interfaces/response/Notification/IGetNotificationResponse'
import { createAction, Action, ActionsUnion } from '../Base/actionHelpers'

export enum NotificationActionKeys {
    GET_NOTIFICATIONS_REQUEST = '[NOTIFICATION] GET_NOTIFICATIONS_REQUEST',
    GET_NOTIFICATIONS_SUCCESS = '[NOTIFICATION] GET_NOTIFICATIONS_SUCCESS',
    GET_NOTIFICATIONS_FAILURE = '[NOTIFICATION] GET_NOTIFICATIONS_FAILURE',
}

export const NotificationActions = {
    getNotifications: (onlyUnread: boolean): GetNotificationsRequestAction =>
        createAction(NotificationActionKeys.GET_NOTIFICATIONS_REQUEST, onlyUnread),
    getNotificationsSuccess: (res: INotification[]): GetNotificationsSuccessAction =>
        createAction(NotificationActionKeys.GET_NOTIFICATIONS_SUCCESS, res),
    getNotificationsFailure: (error: string): GetNotificationsFailureAction =>
        createAction(NotificationActionKeys.GET_NOTIFICATIONS_FAILURE, error),
}

export type NotificationActionUnion = ActionsUnion<typeof NotificationActions>

export type GetNotificationsRequestAction = Action<NotificationActionKeys.GET_NOTIFICATIONS_REQUEST, boolean>
export type GetNotificationsSuccessAction = Action<NotificationActionKeys.GET_NOTIFICATIONS_SUCCESS, INotification[]>
export type GetNotificationsFailureAction = Action<NotificationActionKeys.GET_NOTIFICATIONS_FAILURE, string>
