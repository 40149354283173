import React, { FC } from 'react';

import { Icon } from '..';
import { history } from '../../Store';

import styles from './HeaderMenu.module.scss';
import './HeaderMenu.override.scss';

import { IconName } from '../Icon/IconClasses';
import { UserInfo } from './Components/UserInfo';
import { useSelector } from 'react-redux';
import { ContextButton } from './Components/ContextButton';
import { getMeUser } from '../../Store/User/User.selector';

export interface IHeaderItem {
  logo: IconName;
  logoCollapsed: IconName;
  isCollapsed: boolean;
}

export const HeaderMenu: FC<IHeaderItem> = ({
  logo,
  logoCollapsed,
  isCollapsed,
}) => {
  const user = useSelector(getMeUser);

  return (
    <div className={styles['HeaderMenu']}>
      <div className={isCollapsed ? styles['collapsed'] : styles['LeftMenu']}>
        {isCollapsed ? (
          <Icon
            onClick={() => {
              history.push('/');
            }}
            icon={logoCollapsed}
            size="XXL"
            color="primary-base"
          />
        ) : (
          <div className={styles['LeftLogo']}>
            <Icon
              onClick={() => {
                history.push('/');
              }}
              icon={logo}
              size="XXL"
              color="primary-base"
            />
          </div>
        )}
      </div>
      <div className={styles['RightMenu']}>
        <div>
          <ContextButton />
          <div className={styles['line']} />
          {user && (
            <UserInfo
              name={user.nome}
              lastName={user.sobrenome}
              email={user.email}
            />
          )}
        </div>
      </div>
    </div>
  );
};
