import { Http, IQueryListParams } from '../../../Utils/Http';
import { QueryFunctionContext } from '@tanstack/react-query';
import { ICountryList } from '../../Interfaces/response/Country/ICountryResponse';

export class CountryAPI {
  public static async listCountry(
    context: QueryFunctionContext
  ): Promise<ICountryList> {
    return await Http.fetcher<ICountryList>(
      Http.AddQueryParams(
        '/pharma/estoque/v1/Pais/Listar',
        context.queryKey[1] as IQueryListParams
      )
    );
  }
}
