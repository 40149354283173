import { CurrencyActionKeys, CurrencyActionUnion } from './Currency.actions';
import { ICurrencyState } from './interfaces/ICurrencyState';

const initialData: ICurrencyState = {
  isLoading: false,
};

export const CurrencyReducer = (
  state = initialData,
  action: CurrencyActionUnion
): ICurrencyState => {
  switch (action.type) {
    case CurrencyActionKeys.GET_CURRENCY_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: undefined,
      };
    case CurrencyActionKeys.GET_CURRENCY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: undefined,
        currentCurrency: action.payload,
      };
    case CurrencyActionKeys.GET_CURRENCY_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default CurrencyReducer;
