import { useMutation, useQuery } from '@tanstack/react-query';
import { UserGroupAPI } from '../../Data/API/Accountmanager/UserGroupApi';
import { IBasicMutationOptions } from '../interfaces/IBasicOptions';
import {
  messageErrorToast,
  messageSucessToast,
} from '../../Utils/MessageErrorToast';
import { i18n } from '../../Components';
import { IQueryListParams } from '../../Utils/Http';

export const GET_USER_GROUP_TYPE = '[USER_GROUP] GET_USER_GROUP_TYPE';
const EDIT_USER_GROUP_TYPE = '[USER_GROUP] EDIT_USER_GROUP_TYPE';
const CREATE_USER_GROUP_TYPE = '[USER] CREATE_USER_GROUP_TYPE';

const CHANGE_USER_GROUP_STATUS = '[USER_GROUP] CHANGE_USER_GROUP_STATUS';
const DELETE_USER_GROUP = '[USER_GROUP] DELETE_USER_GROUP';
const PATCH_NAME_AND_DESCRIPTION_USER_GROUP =
  '[USER_GROUP] PATCH_NAME_AND_DESCRIPTION_USER_GROUP';

export const LIST_USER_GROUP_TYPE = '[USER_GROUP] LIST_USER_GROUP_TYPE';
export const GET_USER_LIST_FROM_USER_GROUP_TYPE =
  '[USER_GROUP] GET_USER_LIST_FROM_USER_GROUP_TYPE';
const USER_GROUP_NAME_ALREADY_REGISTRED_TYPE =
  '[USER_GROUP] USER_GROUP_NAME_ALREADY_REGISTRED_TYPE';

const ADD_USER_IN_USER_GROUP = '[USER_GROUP] ADD_USER_IN_USER_GROUP';
const REMOVE_USER_IN_USER_GROUP = '[USER_GROUP] REMOVE_USER_IN_USER_GROUP';

export const useGetUserGroupType = (externalId?: string) =>
  useQuery([GET_USER_GROUP_TYPE, externalId], UserGroupAPI.getUserGroup);
export const useEditUserGroupType = () =>
  useMutation([EDIT_USER_GROUP_TYPE], UserGroupAPI.editUser);
export const useCreateUserGroupType = (options?: IBasicMutationOptions) =>
  useMutation([CREATE_USER_GROUP_TYPE], UserGroupAPI.createUserGroup, options);

export const useChangeStatusUserGroup = (options?: IBasicMutationOptions) =>
  useMutation([CHANGE_USER_GROUP_STATUS], UserGroupAPI.ChangeUserGroupStatus, {
    onSuccess: (data, variables, context) => {
      messageSucessToast(i18n.t('admin.editUserGroups.message.statusSuccess'));
      options?.onSuccess && options?.onSuccess(data, variables, context);
    },
    onError: (x) => messageErrorToast(x),
  });
export const useDeleteUserGroup = (options?: IBasicMutationOptions) =>
  useMutation([DELETE_USER_GROUP], UserGroupAPI.DeleteUserGroup, {
    onSuccess: (data, variables, context) => {
      messageSucessToast(i18n.t('admin.editUserGroups.message.deleteSuccess'));
      options?.onSuccess && options?.onSuccess(data, variables, context);
    },
    onError: (x) => messageErrorToast(x),
  });
export const usePatchNameAndDescription = (options?: IBasicMutationOptions) =>
  useMutation(
    [PATCH_NAME_AND_DESCRIPTION_USER_GROUP],
    UserGroupAPI.patchNameAndDescription,
    {
      onSuccess: (data, variables, context) => {
        messageSucessToast(
          i18n.t('admin.editUserGroups.message.deleteSuccess')
        );
        options?.onSuccess && options?.onSuccess(data, variables, context);
      },

      onError: (x) => messageErrorToast(x),
    }
  );

export const useUserGroupListType = (pagination: IQueryListParams) =>
  useQuery([LIST_USER_GROUP_TYPE, pagination], UserGroupAPI.listUserGroup);
export const useGetUserListFromUserGroupType = (pagination: IQueryListParams) =>
  useQuery(
    [GET_USER_LIST_FROM_USER_GROUP_TYPE, pagination],
    UserGroupAPI.getUserListFromUserGroup
  );
export const useUserGroupNameAlreadyRegistredType = (
  options?: IBasicMutationOptions
) =>
  useMutation(
    [USER_GROUP_NAME_ALREADY_REGISTRED_TYPE],
    UserGroupAPI.isUserGroupNameAlreadyRegistred,
    options
  );

export const useAddUserInUserGroupType = (options?: IBasicMutationOptions) =>
  useMutation([ADD_USER_IN_USER_GROUP], UserGroupAPI.addUserInGroup, options);
export const useRemoveUserInUserGroupType = (options?: IBasicMutationOptions) =>
  useMutation(
    [REMOVE_USER_IN_USER_GROUP],
    UserGroupAPI.removeUserInGroup,
    options
  );
