import React, { CSSProperties, FC, useEffect, useMemo, useState } from 'react';
import { Icon } from '../..';
import { IconName } from '../../Icon/IconClasses';
import { history } from '../../../Store';

import { Text } from '../../Text';

import styles from './MenuItem.module.scss';
import { useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import { IPermissionWrapper } from '../../PermissionWrapper';
import { motion } from 'framer-motion';

const clickOnSideMenu = (link: string, children?: IMenuItem[]) => {
  if (children) {
    history.push(link);
  } else history.push(link);
};

export interface IMenuItem {
  id?: string;
  icon?: IconName;
  name: string;
  link: string;
  disabled?: boolean;
  isCollapsed?: boolean;
  permission?: IPermissionWrapper;
  style?: CSSProperties;
  children?: IMenuItem[];
}

export const MenuItem: FC<IMenuItem> = ({
  icon,
  name,
  link,
  disabled,
  isCollapsed,
  style,
  children,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const { pathname } = useLocation();

  const isActive =
    link !== '' && (pathname === link || pathname.startsWith(`${link}/`));
  const iconFragment = icon ? (
    <Icon icon={icon} size="M" color="text-50" />
  ) : (
    <div className={styles['icon-holder']}></div>
  );

  const childrens = useMemo(
    () => ['/inventory/products', '/inventory/groups', '/inventory/sub-groups'],
    []
  );

  useEffect(() => {
    setOpen(childrens.some((x) => pathname.indexOf(x) !== -1));
  }, [pathname, childrens]);

  const { t } = useTranslation();

  return (
    <div
      style={style}
      className={`${styles['container']} ${
        children ? styles['menuItem-with-children'] : ''
      } ${open ? styles['item-open'] : ''} ${
        children && childrens.some((x) => x === pathname) && !open
          ? styles['menuItem-children-active']
          : ''
      }`}
    >
      <div
        className={`${styles['menuItem']} ${
          !isCollapsed
            ? styles['menuItem-not-collapsed']
            : styles['menuItem-collapsed']
        }  ${isActive ? styles['active'] : ''} ${
          disabled ? styles['disabled'] : ''
        } ${open ? styles['menuItem-open'] : ''}`}
        onClick={() => clickOnSideMenu(link, children)}
      >
        <div className={styles['name-container']}>
          {iconFragment}
          <motion.div animate={{ width: isCollapsed ? '0px' : '100%' }}>
            <div style={{ marginLeft: 16, overflow: 'hidden' }}>
              <Text
                type="heading6"
                color="text-50"
                className={`${styles['show']} ${styles['text']}`}
              >
                {t(name)}
              </Text>
            </div>
          </motion.div>
        </div>
      </div>
      {children && !isCollapsed && (
        <Icon
          icon={open ? 'chevron-right' : 'chevron-down'}
          className={`${styles['show']} ${
            isCollapsed ? styles['hidden'] : ''
          } ${styles['icon']}`}
          size="M"
          color="text-50"
          onClick={() => setOpen(!open)}
        />
      )}
      {children ? (
        <div
          className={`${styles['expandable']} ${
            open ? styles['open'] : styles['close']
          }`}
        >
          {children.map((x, index) => (
            <MenuItem
              {...x}
              key={`menuItem-${index}`}
              isCollapsed={isCollapsed}
            />
          ))}
        </div>
      ) : null}
    </div>
  );
};
