import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Form, TextInput } from '../../../Components';
import { Avatar } from '../../../Components/Avatar';
import { patternLabelStyle } from '../../../Components/Inputs/TextInput';
import { TextInputWithMask } from '../../../Components/Inputs/TextInputWithMask';
import { TableLayout } from '../../../Components/Tabs/TablePreference';
import { IPutUserRequest } from '../../../Data/Interfaces/request/User/IUserRequest';
import { UserActions } from '../../../Store/User/User.actions';
import { getMeUser } from '../../../Store/User/User.selector';
import { RemoveMask } from '../../../Utils/MasksUtils';
import { AccountDetailsSchema } from './AccountDetails.schema';
import { TextInputPhoneMask } from '../../../Components/Inputs/TextInputPhoneMask';

import styles from './AccountDetails.module.scss';

export const AccountDetails: FC = () => {
  const [disabled, setDisebled] = useState(true);

  const { t } = useTranslation();

  const dispatch = useDispatch();

  var user = useSelector(getMeUser);

  const onSubmit = (values: IPutUserRequest) => {
    var body: IPutUserRequest = { ...values };

    body.dataNascimento = RemoveMask(
      body.dataNascimento,
      body.preferencias.padraoData
    );

    dispatch(UserActions.putUser(body));
    setDisebled(true);
  };

  return (
    <>
      <TableLayout
        title={t('accountManager.accountDetailsPage.title')}
        body={
          <Form
            initialValues={user}
            onSubmit={onSubmit}
            schema={AccountDetailsSchema}
          >
            {(form) => (
              <div className={styles['divDetails']}>
                <div className={styles['divAvatar']}>
                  {user && (
                    <Avatar
                      fisrtName={user.nome}
                      lastName={user.sobrenome}
                      size="XXL"
                      type="heading3-bold"
                      color="white"
                    />
                  )}
                  <Button
                    className={styles['button-avatar']}
                    type="secondary"
                    children={t('accountManager.accountDetailsPage.addPhoto')}
                  />
                </div>
                <div className={styles['divName']}>
                  <div className={styles['name']}>
                    <TextInput
                      className={styles['input']}
                      name="nome"
                      label={[
                        {
                          children: t('accountManager.accountDetailsPage.name'),
                          ...patternLabelStyle,
                        },
                      ]}
                      placeHolder={
                        !disabled
                          ? t('accountManager.accountDetailsPage.name')
                          : ''
                      }
                      disabled={disabled}
                    />
                  </div>
                  <div className={styles['last-name']}>
                    <TextInput
                      className={styles['input']}
                      name="sobrenome"
                      label={[
                        {
                          children: t(
                            'accountManager.accountDetailsPage.lastName'
                          ),
                          ...patternLabelStyle,
                        },
                      ]}
                      placeHolder={
                        !disabled
                          ? t('accountManager.accountDetailsPage.lastName')
                          : ''
                      }
                      disabled={disabled}
                    />
                  </div>
                </div>
                <TextInput
                  className={styles['input']}
                  name="email"
                  label={[
                    {
                      children: t('accountManager.accountDetailsPage.email'),
                      ...patternLabelStyle,
                    },
                  ]}
                  placeHolder={
                    !disabled
                      ? t('accountManager.accountDetailsPage.placeHolderEmail')
                      : ''
                  }
                  disabled={disabled}
                />
                <TextInputWithMask
                  className={styles['input']}
                  mask="dataMask"
                  name="dataNascimento"
                  label={[
                    {
                      children: t('accountManager.accountDetailsPage.birthday'),
                      ...patternLabelStyle,
                    },
                    {
                      children: user
                        ? `(${user?.preferencias.padraoData})`
                        : '',
                      colorLabel: 'text-300',
                      typeLabel: 'ui-tiny-content',
                    },
                  ]}
                  disabled={disabled}
                  placeHolder={user?.preferencias.padraoData}
                />
                <TextInputPhoneMask
                  className={styles['input']}
                  name="celular"
                  label={[
                    {
                      children: t('accountManager.accountDetailsPage.phone'),
                      ...patternLabelStyle,
                    },
                  ]}
                  placeHolder={
                    !disabled
                      ? t('accountManager.accountDetailsPage.placeHolderPhone')
                      : ''
                  }
                  disabled={disabled}
                />
                {disabled ? (
                  <Button
                    type="secondary"
                    children={t('accountManager.accountDetailsPage.edit')}
                    onClick={() => setDisebled(false)}
                  />
                ) : (
                  <div className={styles['buttons']}>
                    <Button
                      className={styles['save-button']}
                      type="primary"
                      children={t('accountManager.accountDetailsPage.save')}
                      htmlType="submit"
                    />
                    <Button
                      type="secondary"
                      children={t('accountManager.accountDetailsPage.cancel')}
                      onClick={() => {
                        setDisebled(true);
                        form.resetForm();
                      }}
                    />
                  </div>
                )}
              </div>
            )}
          </Form>
        }
      />
    </>
  );
};
