import * as yup from 'yup';
import { i18n } from '../../../../../../Components';

export const ConglomerateDetailsSchema = yup.object().shape({
  nome: yup
    .string()
    .required(
      i18n.t('adminBootys.editConglomerate.tab.conglomerateNameRequired')
    ),
  empresaExternalIdMatriz: yup
    .string()
    .required(
      i18n.t(
        'adminBootys.editConglomerate.tab.conglomerateheadquartersRequired'
      )
    ),
});
