import { useMutation, useQuery } from '@tanstack/react-query';
import { i18n } from '../../Components';
import { IPagination } from '../../Components/Table';
import { UserAPI } from '../../Data/API/Accountmanager/UserApi';
import { IQueryListParams } from '../../Utils/Http';
import {
  messageErrorToast,
  messageSucessToast,
} from '../../Utils/MessageErrorToast';
import { IBasicMutationOptions } from '../interfaces/IBasicOptions';

const CREATE_USER_TYPE = '[USER] CREATE_USER_TYPE';

const DELETE_USER_TYPE = '[USER] DELETE_USER_TYPE';
const EDIT_USER_TYPE = '[USER] EDIT_USER_TYPE';
const CHANGE_USER_STATUS_TYPE = '[USER] CHANGE_USER_STATUS_TYPE';

const GET_PREFERENCES_TYPE = '[USER] FETCH_PREFERENCES_TYPE';
export const GET_USER_TYPE = '[USER] GET_USER_TYPE';
export const GET_USER_LIST_TYPE = '[USER] GET_USER_LIST_TYPE';
const GET_ACTIVE_USER_ADMIN_TYPE = '[USER] GET_ACTIVE_USER_ADMIN_TYPE';
const GET_ACTIVE_USER_TYPE = '[USER] GET_ACTIVE_USER_ADMIN_TYPE';
const GET_USER_WITHOUT_GROUP_TYPE = '[USER] GET_USER_WITHOUT_GROUP_TYPE';

export const useCreateUserMutation = () =>
  useMutation([CREATE_USER_TYPE], UserAPI.createUser, {
    onError: (x) => messageErrorToast(x),
    onSuccess: () =>
      messageSucessToast(i18n.t('adminBootys.createCompany.messages.success')),
  });

export const useUserDeleteMutation = (options?: IBasicMutationOptions) =>
  useMutation([DELETE_USER_TYPE], UserAPI.deleteUser, {
    ...options,
    onError: (x) => messageErrorToast(x),
  });
export const useEditUserMutation = (options?: IBasicMutationOptions) =>
  useMutation([EDIT_USER_TYPE], UserAPI.editUser, options);
export const useChangeUserStatusMutation = (options?: IBasicMutationOptions) =>
  useMutation([CHANGE_USER_STATUS_TYPE], UserAPI.changeUserStatus, options);

export const useUserPreferencesType = () =>
  useQuery([GET_PREFERENCES_TYPE], UserAPI.getPreferences);
export const useGetUserType = (externalId?: string) =>
  useQuery([GET_USER_TYPE, externalId], UserAPI.getUser);
export const useGetUserListType = (pagination: IPagination) =>
  useQuery([GET_USER_LIST_TYPE, pagination], UserAPI.listUser);
export const useGetActiveUserAdminListType = (pagination: IQueryListParams) =>
  useQuery(
    [GET_ACTIVE_USER_ADMIN_TYPE, pagination],
    UserAPI.listActiveUserAdmin
  );
export const useGetActiveUserListType = (pagination: IQueryListParams) =>
  useQuery([GET_ACTIVE_USER_TYPE, pagination], UserAPI.listActiveUser);
export const useGetUserWithutGroupListType = (pagination: IQueryListParams) =>
  useQuery([GET_USER_WITHOUT_GROUP_TYPE, pagination], UserAPI.listUserWithoutGroup);
