import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Form } from '../../../../../../Components';
import {
  CollapseList,
  getPermissionInitialValues,
  IRow,
} from '../../../../../../Components/CollapseList';
import { IconName } from '../../../../../../Components/Icon/IconClasses';
import { createPermissionRequest } from '../../../../../../Data/Interfaces/request/User/IUpdatePermissionRequest';
import { IModulo } from '../../../../../../Data/Interfaces/response/User/IGetUserReponse';
import { UserActions } from '../../../../../../Store/User/User.actions';
import { getShowPermissionDependencyModal } from '../../../../../../Store/User/User.selector';
import { RequiredPermissionModal } from '../../Modals/RequiredPermissionModal';
import {
  GET_USER_TYPE,
  useGetUserType,
} from '../../../../../../Hooks/Accountmanager/UserHooks';
import { useParams } from 'react-router';
import { useQueryClient } from '@tanstack/react-query';

export const PermissionForm: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const params: Readonly<Partial<{ externalId: string | undefined }>> =
    useParams();
  const { data: user } = useGetUserType(params.externalId);

  const showPermissionDependencyModal = useSelector(
    getShowPermissionDependencyModal
  );

  useEffect(() => {
    if (!user) return;

    dispatch(UserActions.getUserSuccess(user));
  }, [dispatch, user]);

  const transformModule = useCallback((module: IModulo): IRow => {
    let itens: IRow[] | undefined;
    let totalPermissions: number | undefined = 0;
    let activePermissions: number | undefined = 0;

    if (module.itens) {
      itens = module.itens.map((x) => transformModule(x));
    }

    if (!module.permissaoId) {
      totalPermissions +=
        itens
          ?.map((x) => x.totalPermissions)
          .reduce((a, b) => (a || 0) + (b || 0), 0) || 0;
      totalPermissions += itens?.filter((x) => !!x.permissionId).length || 0;

      activePermissions +=
        itens
          ?.map((x) => x.activePermissions)
          .reduce((a, b) => (a || 0) + (b || 0), 0) || 0;
      activePermissions +=
        itens?.filter((x) => !!x.permissionId && x.active === true).length || 0;
    }

    return {
      name: module.permissaoId?.toString(),
      displayName: module.nome,
      permissionId: module.permissaoId,
      isCollapse: !module.permissaoId,
      active: module.ativo,
      inherited: module.herdado,
      inheritedFrom: module.grupos || [],
      children: itens,
      totalPermissions,
      activePermissions,
      icon: {
        backgroundColor: module.corDeFundo,
        icon: module.icone as IconName,
        color: !!module?.corDeFundo ? 'white' : 'text-50',
      },
    };
  }, []);

  const modules = useMemo(
    () => user?.modulos.map((x) => transformModule(x)),
    [user, transformModule]
  );

  const onPermissionChange = useCallback(
    (
      permission: { id: string; name: String },
      prevValue: boolean | 'not-inherited' | 'inherited',
      nextValue: boolean | 'not-inherited' | 'inherited'
    ) => {
      const params = createPermissionRequest(
        user?.externalId!,
        permission.id,
        nextValue,
        () => {
          queryClient.invalidateQueries([GET_USER_TYPE]);
        }
      );

      dispatch(UserActions.updatePermission(params));
    },
    [dispatch, queryClient, user]
  );

  return (
    <Form initialValues={getPermissionInitialValues(modules || [])}>
      <Card
        title={t('admin.editUserGroups.tab.permissions')}
        status="default"
        withoutCollapse={true}
      >
        <CollapseList rows={modules || []} onChange={onPermissionChange} />
      </Card>
      <RequiredPermissionModal show={showPermissionDependencyModal} />
    </Form>
  );
};
