import { useTranslation } from 'react-i18next';
import { ConvertDate } from '../../../../../../../Utils/DateUtils';
import { useBalanceAdjustmentsGet } from '../../../../../../../Hooks/Supply/BalanceAdjustmentsHooks';
import { useUserPreferencesType } from '../../../../../../../Hooks/Accountmanager/UserHooks';

export const DynamicEditBalanceAdjustmentBreadcrumb = ({ match }: any) => {
  const { t } = useTranslation();
  const { data: balanceAdjustment } = useBalanceAdjustmentsGet(
    match.params.externalId
  );
  const { data: datePattern } = useUserPreferencesType();
  return (
    <span style={{ display: 'flex' }}>
      {`${balanceAdjustment?.descricaoProduto} | ${ConvertDate(
        balanceAdjustment?.dataLancamento || '',
        datePattern?.preferencias.padraoData
      )} ${t('common.complementDateHour')} ${ConvertDate(
        balanceAdjustment?.dataLancamento || '',
        datePattern?.preferencias.padraoHora
      )}`}
    </span>
  );
};

export const DynamicInfoEditBalanceAdjustmentBreadcrumb = ({ match }: any) => {
  const { t } = useTranslation();
  const { data: balanceAdjustment } = useBalanceAdjustmentsGet(
    match.params.externalId
  );
  return (
    <span style={{ display: 'flex' }}>
      {`${t('breadCrumb.balanceAdjustments.list')} | ${
        balanceAdjustment?.descricaoProduto
      }`}
    </span>
  );
};
