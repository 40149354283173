import React, { FC, useEffect, useState } from 'react';
import { Tag, Tooltip } from '../../Components';

import styles from './TagsUtils.module.scss';

interface ITagsRender {
  data: string[];
  width: number;

  rowSelected?: boolean;
}

export const TagsRender = ({ data, width, rowSelected }: ITagsRender) => {
  const [widthLastTag, setWidthLastTag] = useState(0);
  const [lastTagIndex, setLastTagIndex] = useState(0);
  const [tagWidth, setTagWidth] =
    useState<{ index: number; width: number }[]>();

  useEffect(() => {
    let widthTagsSum = 0;
    let lastIndex = 0;
    let lastWidth = 0;
    tagWidth?.forEach((x, index) => {
      widthTagsSum += x.width + 8;

      if (widthTagsSum > width && lastIndex === 0) {
        lastIndex = index;
        lastWidth = width - (widthTagsSum - x.width);
        return;
      }
    });
    lastWidth > 20 && setWidthLastTag(lastWidth);
    setLastTagIndex(lastWidth > 20 ? lastIndex : lastIndex - 1);
  }, [width, tagWidth]);

  useEffect(() => {
    let width: { index: number; width: number }[] = [];
    data?.forEach((x, index) => {
      width.push({
        index: index,
        width: document?.getElementById(`${x}-${index}`)!.clientWidth,
      });
    });
    setTagWidth(width);
  }, [data]);

  return (
    <div
      className={styles['container']}
      style={{
        width: width,
      }}
    >
      <div
        className={styles['tag-container']}
        style={{
          width: width,
        }}
      >
        <div style={{ width: 'fitContent', display: 'flex' }}>
          {data?.map((x: string, index: any) => (
            <div
              style={
                index > lastTagIndex && lastTagIndex > 0
                  ? { display: 'none' }
                  : { width: 'fitContent' }
              }
              key={x}
            >
              <TagOnTable
                index={index}
                width={widthLastTag}
                groupName={x}
                lastTagIndex={lastTagIndex}
                rowSelected={rowSelected}
              />
            </div>
          ))}
        </div>
      </div>
      {lastTagIndex > 0 && (
        <Tooltip
          placement="topLeft"
          title={data?.map(
            (x: any, i: any) =>
              i > lastTagIndex && (
                <div>
                  {x}
                  <br />
                </div>
              )
          )}
          showMe
        >
          <div
            className={`${styles['ellipse']} ${
              rowSelected ? styles['row-selected'] : ''
            }`}
          >
            ...
          </div>
        </Tooltip>
      )}
    </div>
  );
};

interface ITagOnTable {
  width: number;
  index: number;
  groupName: string;
  lastTagIndex: number;

  rowSelected?: boolean;
}

const TagOnTable: FC<ITagOnTable> = ({
  width,
  index,
  groupName,
  lastTagIndex,
  rowSelected,
}) => (
  <Tag
    tooltip={lastTagIndex >= index || lastTagIndex < 0 ? groupName : undefined}
    className={`${styles['tag']} ${
      lastTagIndex > index ? styles['tag-invisible'] : ''
    } ${rowSelected ? styles['row-selected'] : ''}`}
    widthAuto
    type="secondary"
    style={lastTagIndex === index && width > 0 ? { width: width } : {}}
    id={`${groupName}-${index}`}
  >
    {groupName}
  </Tag>
);
