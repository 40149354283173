import { IQueryParams } from '../../Components/Table';
import { IUpdateLoss } from '../../Data/Interfaces/request/Loss/IUpdateLoss';
import {
  ILossesListResponse,
  IReasonLossListData,
  IProductLossListData,
} from '../../Data/Interfaces/response/Loss/ILossResponse';
import { ITransformedOdataResponse } from '../../Utils/Http';
import { createAction, Action, ActionsUnion } from '../Base/actionHelpers';

export enum LossesActionKeys {
  SET_LOADING = '[LOSSES] SET_LOADING',

  LIST_LOSSES_REQUEST = '[LOSSES] LIST_LOSSES_REQUEST',
  LIST_LOSSES_SUCCESS = '[LOSSES] LIST_LOSSES_SUCCESS',

  LIST_REASON_LOSS_REQUEST = '[LOSSES] LIST_REASON_LOSS_REQUEST',
  LIST_REASON_LOSS_SUCCESS = '[LOSSES] LIST_REASON_LOSS_SUCCESS',

  LIST_PRODUCT_LOSS_REQUEST = '[LOSSES] LIST_PRODUCT_LOSS_REQUEST',
  LIST_PRODUCT_LOSS_SUCCESS = '[LOSSES] LIST_PRODUCT_LOSS_SUCCESS',

  SINGLE_PRODUCT_LOSS_REQUEST = '[LOSSES] SINGLE_PRODUCT_LOSS_REQUEST',
  SINGLE_PRODUCT_LOSS_SUCCESS = '[LOSSES] SINGLE_PRODUCT_LOSS_SUCCESS',

  SET_IS_LOSS_EDIT_MODE = '[LOSSES] SET_IS_LOSS_EDIT_MODE',

  UPDATE_LOSS_REQUEST = '[LOSSES] UPDATE_LOSS_REQUEST',
  UPDATE_LOSS_SUCCESS = '[LOSSES] UPDATE_LOSS_SUCCESS',
}

export const LossesActions = {
  setLoading: (loading: boolean): SetLoadingAction =>
    createAction(LossesActionKeys.SET_LOADING, loading),

  list: (body: IQueryParams): LossesListAction =>
    createAction(LossesActionKeys.LIST_LOSSES_REQUEST, body),
  listSuccess: (
    body: ITransformedOdataResponse<ILossesListResponse>
  ): LossesListSuccessAction =>
    createAction(LossesActionKeys.LIST_LOSSES_SUCCESS, body),

  listReasonLoss: (body: IQueryParams): ReasonLossListAction =>
    createAction(LossesActionKeys.LIST_REASON_LOSS_REQUEST, body),
  listReasonLossListSuccess: (
    body: ITransformedOdataResponse<IReasonLossListData>
  ): ReasonLossListSuccessAction =>
    createAction(LossesActionKeys.LIST_REASON_LOSS_SUCCESS, body),

  listProductLoss: (body: IQueryParams): ProductLossListAction =>
    createAction(LossesActionKeys.LIST_PRODUCT_LOSS_REQUEST, body),
  listProductLossListSuccess: (
    body: ITransformedOdataResponse<IProductLossListData>
  ): ProductLossListSuccessAction =>
    createAction(LossesActionKeys.LIST_PRODUCT_LOSS_SUCCESS, body),

  setIsEditMode: (isEditMode: boolean) =>
    createAction(LossesActionKeys.SET_IS_LOSS_EDIT_MODE, isEditMode),

  updateProductLoss: (body: IUpdateLoss) =>
    createAction(LossesActionKeys.UPDATE_LOSS_REQUEST, body),
  updateProductLossSuccess: () =>
    createAction(LossesActionKeys.UPDATE_LOSS_SUCCESS),
};

export type LossesActionUnion = ActionsUnion<typeof LossesActions>;

export type SetLoadingAction = Action<LossesActionKeys.SET_LOADING, boolean>;

export type LossesListAction = Action<
  LossesActionKeys.LIST_LOSSES_REQUEST,
  IQueryParams
>;
export type LossesListSuccessAction = Action<
  LossesActionKeys.LIST_LOSSES_SUCCESS,
  ITransformedOdataResponse<ILossesListResponse>
>;

export type ReasonLossListAction = Action<
  LossesActionKeys.LIST_REASON_LOSS_REQUEST,
  IQueryParams
>;
export type ReasonLossListSuccessAction = Action<
  LossesActionKeys.LIST_REASON_LOSS_SUCCESS,
  ITransformedOdataResponse<IReasonLossListData>
>;

export type ProductLossListAction = Action<
  LossesActionKeys.LIST_PRODUCT_LOSS_REQUEST,
  IQueryParams
>;
export type ProductLossListSuccessAction = Action<
  LossesActionKeys.LIST_PRODUCT_LOSS_SUCCESS,
  ITransformedOdataResponse<IProductLossListData>
>;
