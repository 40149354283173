import { QueryFunctionContext } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { IPagination } from '../../../Components/Table';
import { Http, IQueryListParams } from '../../../Utils/Http';
import {
  IListAlternativeUnitMeasurementResponse,
  IListUnitMeasurementByClassResponse,
  IListUnitMeasurementResponse,
} from '../../Interfaces/response/UnitMeasurement/IUnitMeasurementResponse';

export class UnitMeasurementAPI {
  public static async listUnitMeasurement(
    body: IPagination
  ): Promise<AxiosResponse<IListUnitMeasurementResponse>> {
    const axios = await Http.axios();
    return axios.get<IListUnitMeasurementResponse>(
      Http.AddQueryParams('/pharma/estoque/v1/UnidadeMedida/Listar', body)
    );
  }

  public static async listUnitMeasurementByClassAny(
    context: any
  ): Promise<IListUnitMeasurementByClassResponse | null> {
    if (!context.queryKey[1]?.filter?.some((x: any) => x.externalId)) {
      return null;
    }

    let url = Http.AddQueryParams(
      `/pharma/estoque/v1/UnidadeMedida/ListarDetalhadoUnidadesPorClasseProduto`,
      context.queryKey[1] as IQueryListParams
    );
    return await Http.fetcher<IListUnitMeasurementByClassResponse>(url);
  }

  public static async listUnitMeasurementByClass(
    context: QueryFunctionContext
  ): Promise<IListUnitMeasurementByClassResponse | null> {
    if (!context.queryKey[2]) return null;
    let url = Http.AddQueryParams(
      `/pharma/estoque/v1/UnidadeMedida/ListarDetalhadoUnidadesPorClasseProduto`,
      context.queryKey[1] as IQueryListParams
    );

    url += `&tipoClasseProduto=${context.queryKey[2]}`;
    return await Http.fetcher<IListUnitMeasurementByClassResponse>(url);
  }

  public static async listAlternativeUnitMeasurement(
    context: QueryFunctionContext
  ): Promise<IListAlternativeUnitMeasurementResponse> {
    return await Http.fetcher<IListAlternativeUnitMeasurementResponse>(
      Http.AddQueryParams(
        '/pharma/estoque/v1/UnidadeMedida/ListarUnidadeAlternativa',
        context.queryKey[1] as IQueryListParams
      )
    );
  }
}
