import StorageUtils from './StorageUtils';

export const currencyTypes = ['pt-br', 'en-US']; //TIPO MOEDA:  1 = PT-BR, 2 = EN-US
const currencyTypesTag = ['BRL', 'USD'];
const currencySymbolTag = ['R$', '$'];

export const currencyFormater = (
  value: number,
  userCurrencyType?: number,
  unitMeasurement?: string
) =>
  userCurrencyType !== undefined &&
  `${value?.toLocaleString(currencyTypes[userCurrencyType], {
    style: 'currency',
    currency: currencyTypesTag[userCurrencyType],
  })}${unitMeasurement ? `/${unitMeasurement}` : ''}`;

export const getCurrencySymbol = () => {
  const preference = StorageUtils.getPreference();
  return currencySymbolTag[preference?.tipoMoedaId];
};
