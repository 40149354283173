import { ICEPState } from './interfaces/ICEPState';
import { CEPActionUnion, CEPActionKeys } from './CEP.actions';

const initialData: ICEPState = {
  isLoading: false,
};

export const cepReducer = (
  state = initialData,
  action: CEPActionUnion
): ICEPState => {
  switch (action.type) {
    case CEPActionKeys.RESET_ADRESS:
      return {
        ...state,
        currentCEP: undefined,
      };

    case CEPActionKeys.GET_CEP_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: undefined,
      };
    case CEPActionKeys.GET_CEP_SUCCESS:
      return {
        ...state,
        isLoading: false,
        currentCEP: action.payload,
        error: undefined,
      };

    case CEPActionKeys.GET_CEP_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default cepReducer;
