export const modalEN = {
  back: 'Close',
  cancel: 'Cancel',
  save: 'Save',
  delete: 'Delete',
  edit: 'Edit',
  inactive: 'Inactive',

  deleteModal: {
    users: 'users',
    many: {
      title: 'Are you sure you want to delete',
      items: 'items',
      footer: 'This action is permanent and cannot be undone!',
      users: 'Users',
    },
    single: {
      question:
        'Are you sure you want to delete batch {{numero}} from product {{produto}}?',
      footerFirst: 'This action is ',
      footerSecond: 'permanent ',
      footerTertiary: 'and cannot be undone!',
      footerStockBalanceFirst: 'This action will ',
      footerStockBalanceSecond: 'also exclude the ',
      footerStockBalanceTertiary: 'initial stock movement.',
      avatar: {
        footer: 'Are you sure you want to inactivate',
        footer2: 'This action will prevent the user from accessing the system!',
        footer3: 'This action also excludes the initial inventory movement.',
      },
    },
  },

  editModal: {
    many: {
      title: {
        before: 'Change Status of',
        after: 'items',
        inactive: 'Deactivate users',
      },
      confirm: {
        copy: 'Are you sure that you want to deactivate {{user}} usuários?\nThis action will prevent users from accessing the system!',
      },
    },
    single: {
      title: {
        before: 'Change Status of',
        inactive: 'Deactivate user',
      },
      confirm: {
        inactivate: 'Deactivate',
        copy: 'Are you sure that you want to deactivate {{user}}?\nThis action will block the user access to the system!',
        areYouSureLotStatus:
          'Are you sure you want to change the status of batch {{numero}} of product {{produto}} to {{status}}?',
        editStatus: 'Change status',
        editStatusLoading: 'Changing',
      },
    },
    statusPlaceHolder: 'Select a status',
  },
};

export const modalPT = {
  back: 'Voltar',
  cancel: 'Cancelar',
  save: 'Salvar',
  delete: 'Excluir',
  edit: 'Alterar',
  inactive: 'Inativar',

  deleteModal: {
    users: 'usuários',
    many: {
      title: 'Você tem certeza que deseja excluir',
      items: 'itens',
      footer: 'Essa ação é permanente e não pode ser desfeita!',
      users: 'Usuários',
    },
    single: {
      question:
        'Você tem certeza que deseja excluir o lote {{numero}} do produto {{produto}}?',
      footer: 'Essa ação é permanente e não pode ser desfeita!',
      footerFirst: 'Essa ação é ',
      footerSecond: 'permanente ',
      footerTertiary: 'e não poderá ser desfeita!',
      footerStockBalanceFirst: 'Essa ação irá ',
      footerStockBalanceSecond: 'excluir também a ',
      footerStockBalanceTertiary: 'movimentação de estoque inicial.',
      avatar: {
        footer: 'Você tem certeza que deseja',
        footer2: 'EXCLUIR',
        footer3: 'o(a) usuário(a)',
      },
    },
  },

  editModal: {
    many: {
      title: {
        before: 'Alterar Status de',
        after: 'itens',
        inactive: 'Inativar usuários',
      },
      confirm: {
        footer: 'Essa ação impedirá os usuários de acessarem o sistema!',
      },
    },
    single: {
      title: {
        inactive: 'Inativar usuário',
        before: 'Alterar Status de',
      },
      confirm: {
        inactivate: 'Inativar',
        areYouSure: 'Você tem certeza que deseja inativar ',
        footer: 'Essa ação impedirá o(a) usuário(a) de acessar o sistema!',
        areYouSureLotStatus:
          'Você tem certeza que deseja alterar o status do lote {{numero}} do produto {{produto}} para {{status}}?',
        editStatus: 'Alterar status',
        editStatusLoading: 'Alterando',
      },
    },
    statusPlaceHolder: 'Selecione um status',
  },
};
