import React, { CSSProperties, FC, ReactNode } from 'react';

interface IDivflex {
  children: ReactNode;
  style?: CSSProperties;
}

export const Divflex: FC<IDivflex> = ({ children, style }) => (
  <div style={{ display: 'flex', ...style }} children={children} />
);
