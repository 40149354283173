import { IMenuItem } from '../../Components/SideMenu/MenuItem';
import { Color } from '../../Utils/ColorUtils';
import { IconName } from '../Icon/IconClasses';

export interface IContext {
  id: string;
  icon: IconName;
  name: string;
  backgroundColor: Color;
}

export interface IMenu {
  context: IContext;
  items: IMenuItem[];
}

export const getMenuById = (contextId?: string) => {
  if (!contextId) return SideMenuItems[0];
  return SideMenuItems.find((x) => x.context.id === contextId);
};

export const SideMenuItems: IMenu[] = [
  {
    context: {
      id: 'Administrativo',
      icon: 'setting',
      name: 'sideMenu.administrative',
      backgroundColor: 'primary-base',
    },
    items: [
      {
        id: 'user',
        icon: 'user',
        name: 'sideMenu.administrativeItems.users',
        link: '/admin/user',
        permission: {
          permission: 1001,
          tooltip: 'noPermission.administrative.users.route',
        },
      },
      {
        id: 'users',
        icon: 'users',
        name: 'sideMenu.administrativeItems.userGroups',
        link: '/admin/user-group',
        permission: {
          permission: 1009,
          tooltip: 'noPermission.administrative.userGroups.route',
        },
      },
      {
        id: 'company',
        icon: 'buildings',
        name: 'sideMenu.administrativeItems.company',
        link: '/admin/company',
        permission: {
          permission: 1016,
          tooltip: 'noPermission.administrative.company.route',
        },
      },
    ],
  },
  {
    context: {
      id: 'Admin',
      icon: 'logo-collapse',
      name: 'sideMenu.adminBootys',
      backgroundColor: 'primary-base',
    },
    items: [
      {
        id: 'company',
        icon: 'Building',
        name: 'sideMenu.adminBootysItems.company',
        link: '/admin-bootys/company',
      },
      {
        id: 'companys',
        icon: 'buildings',
        name: 'sideMenu.adminBootysItems.companyGroups',
        link: '/admin-bootys/conglomerate',
      },
    ],
  },
  {
    context: {
      id: 'Estoque',
      icon: 'archive',
      name: 'sideMenu.inventory',
      backgroundColor: 'black',
    },
    items: [
      {
        id: 'Box',
        icon: 'Box',
        name: 'sideMenu.products',
        link: '/inventory/products',
        permission: {
          permission: 3001,
          tooltip: 'noPermission.supply.products.route',
        },
        children: [
          {
            id: 'tag',
            icon: 'tag',
            name: 'sideMenu.productsItems.group',
            link: '/inventory/groups',
            permission: {
              permission: 3008,
              tooltip: 'noPermission.supply.groups.route',
            },
          },
          {
            id: 'tags',
            icon: 'tags',
            name: 'sideMenu.productsItems.subGroup',
            link: '/inventory/sub-groups',
            permission: {
              permission: 3013,
              tooltip: 'noPermission.supply.subGroups.route',
            },
          },
        ],
      },
      {
        id: 'cube',
        icon: 'cube',
        name: 'sideMenu.inventoryItems.lots',
        link: '/inventory/lot',
        permission: {
          permission: 2001,
          tooltip: 'noPermission.supply.lot.route',
        },
      },
      {
        id: 'cubes',
        icon: 'cubes',
        name: 'sideMenu.inventoryItems.stockLocations',
        link: '/inventory/supply-places',
        permission: {
          permission: 2007,
          tooltip: 'noPermission.supply.supplyPlaces.route',
        },
      },
      {
        id: 'connection',
        icon: 'connection',
        name: 'sideMenu.inventoryItems.transfers',
        link: '/inventory/transfers',
        permission: {
          permission: 2013,
          tooltip: 'noPermission.supply.transfers.route',
        },
      },
      {
        id: 'bin-full',
        icon: 'bin-full',
        name: 'sideMenu.inventoryItems.losses',
        link: '/inventory/losses',
        permission: {
          permission: 2016,
          tooltip: 'noPermission.supply.losses.route',
        },
      },
      {
        id: 'document',
        icon: 'document',
        name: 'sideMenu.inventoryItems.balanceAdjustment',
        link: '/inventory/balance-adjustments',
        permission: {
          permission: 2020,
          tooltip: 'noPermission.supply.balanceAdjustments.route',
        },
      },
      {
        id: 'invoice-list',
        icon: 'invoice-list',
        name: 'sideMenu.inventoryItems.movimentation',
        link: '/inventory/movimentation',
      },
    ],
  },
  {
    context: {
      id: 'Produção',
      icon: 'conical-flask',
      name: 'sideMenu.production',
      backgroundColor: 'users02',
    },
    items: [
      {
        id: 'water-glass',
        icon: 'water-glass',
        name: 'sideMenu.productionItems.pharmaceuticalForms',
        link: '/prod',
      },
      {
        id: 'library',
        icon: 'library',
        name: 'sideMenu.productionItems.formulas',
        link: '/tst',
      },
    ],
  },
  {
    context: {
      id: 'Compras',
      icon: 'shopping-bag',
      name: 'sideMenu.purchases',
      backgroundColor: 'users03',
    },
    items: [
      {
        id: 'basket',
        icon: 'basket',
        name: 'sideMenu.purchasesItems.purchaseRequests',
        link: '/order/purchase',
      },
      {
        id: 'shipping-truck',
        icon: 'shipping-truck',
        name: 'sideMenu.purchasesItems.providers',
        link: '/purchasing/create',
      },
      {
        id: 'invoice-list',
        icon: 'invoice-list',
        name: 'sideMenu.purchasesItems.entryInvoices',
        link: '/tst',
      },
    ],
  },
  {
    context: {
      id: 'Vendas',
      icon: 'invoice',
      name: 'sideMenu.sales',
      backgroundColor: 'users13',
    },
    items: [
      {
        id: 'sales',
        icon: 'basket',
        name: 'sideMenu.purchasesItems.purchaseRequests',
        link: '/sales/prescribes',
      },
    ],
  },
  {
    context: {
      id: 'Financeiro',
      icon: 'cash',
      name: 'sideMenu.financial',
      backgroundColor: 'users11',
    },
    items: [],
  },
  {
    context: {
      id: 'Reports',
      icon: 'document',
      name: 'sideMenu.reports',
      backgroundColor: 'status-danger-base',
    },
    items: [],
  },
];
