import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Table } from '../../../../Components';
import { IPagination } from '../../../../Components/Table/components/Pagination';
import { history } from '../../../../Store';
import { useTranslation } from 'react-i18next';
import { transfersCollums } from './transfers.columns';
import { getMeUser } from '../../../../Store/User/User.selector';
import { PageLoading } from '../../../../Layouts/PageLoading';
import { useTransferGetList } from '../../../../Hooks/Supply/TransferHooks';

export const TransfersList: React.FC = () => {
  const { t } = useTranslation();
  const [pagination, setPagination] = useState<IPagination>({
    PageIndex: 0,
    PageSize: 10,
    Sorter: { column: 'sequenciaNumeroTransferencia', direction: 'DESC' },
  });
  const { data: transfersList, isLoading } = useTransferGetList(pagination);

  const currentMeUser = useSelector(getMeUser);

  return (
    <PageLoading loading={isLoading}>
      <Table
        columns={transfersCollums(t, currentMeUser)}
        data={
          transfersList?.data?.map((x) => ({
            ...x,
            key: x.sequenciaNumeroTransferencia,
          })) || []
        }
        headerOptions={{
          hasSearch: true,
          newButton: {
            onClick: () => history.push('/inventory/transfers/create'),
            label: 'supply.transfers.newButton',
            permission: {
              permission: 2015,
              tooltip: 'noPermission.supply.transfers.create',
            },
          },
          searchPlaceholder: t('supply.transfers.searchPlaceHolder'),
          nameKey: 'DataTransferencia',
        }}
        loading={isLoading}
        pagination={
          transfersList
            ? {
                Count: transfersList.count,
                PageIndex: transfersList.pageIndex,
                PageSize: transfersList.pageSize,
              }
            : false
        }
        onRefetch={(pagination) => {
          setPagination(pagination);
        }}
        rowsOptions={{
          onRowDoubleClick: (x) =>
            history.push(`/inventory/transfers/${x.externalId}`),
          permission: {
            permission: 2014,
            tooltip: 'noPermission.supply.transfers.detail',
          },
        }}
      />
    </PageLoading>
  );
};

export default TransfersList;
