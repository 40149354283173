import _ from 'lodash';
import { IListCompanyResponse } from '../../Data/Interfaces/response/Company/ICompanyResponse';
import { CompanyActionKeys } from './Company.actions';
import { CompanyActionUnion } from './Company.actions';
import { ICompanyState } from './interfaces/ICompanyState';

const initialData: ICompanyState = {
  isLoading: false,
  isCouldNotDeleteOpen: false,
};

export const companyReducer = (
  state = initialData,
  action: CompanyActionUnion
): ICompanyState => {
  switch (action.type) {
    case CompanyActionKeys.PATCH_CURRENCY_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: undefined,
      };
    case CompanyActionKeys.PATCH_CURRENCY_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    case CompanyActionKeys.GET_COMPANY_CONFIGURATIONS_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: undefined,
      };
    case CompanyActionKeys.GET_COMPANY_CONFIGURATIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: undefined,
        getCompanyConfigurations: action.payload,
      };
    case CompanyActionKeys.GET_COMPANY_CONFIGURATIONS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    case CompanyActionKeys.GET_CURRENCY_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: undefined,
      };
    case CompanyActionKeys.GET_CURRENCY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: undefined,
        currentCurrency: action.payload,
      };
    case CompanyActionKeys.GET_CURRENCY_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    case CompanyActionKeys.COMPANY_LIST_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: undefined,
      };
    case CompanyActionKeys.COMPANY_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: undefined,
        listCompanyCurrency: action.payload,
      };
    case CompanyActionKeys.COMPANY_LIST_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    case CompanyActionKeys.LIST_COMPANY_REQUEST_INCREMENTING:
      return {
        ...state,
        isLoading: true,
        error: undefined,
        listCompanyIncrementing: {
          ...(state.listCompanyIncrementing as IListCompanyResponse),
          pageIndex: action.payload.pagination?.PageIndex || 0,
          pageSize: action.payload.pagination?.PageSize || 0,
        },
      };
    case CompanyActionKeys.LIST_COMPANY_SUCCESS_INCREMENTING:
      return {
        ...state,
        isLoading: false,
        error: undefined,
        listCompanyIncrementing: {
          ...action.payload,
          data: _.uniqWith(
            [
              ...(state.listCompanyIncrementing?.data || []),
              ...action.payload.data,
            ],
            _.isEqual
          ),
        },
      };
    case CompanyActionKeys.LIST_COMPANY_FAILURE_INCREMENTING:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    case CompanyActionKeys.ALL_COMPANY_LIST_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: undefined,
      };
    case CompanyActionKeys.ALL_COMPANY_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: undefined,
        listAllCompanyCurrency: action.payload,
      };
    case CompanyActionKeys.ALL_COMPANY_LIST_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    case CompanyActionKeys.MANAGER_COMPANY_LIST_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: undefined,
      };
    case CompanyActionKeys.MANAGER_COMPANY_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: undefined,
        listManagerCompanyCurrency: action.payload,
      };
    case CompanyActionKeys.MANAGER_COMPANY_LIST_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    case CompanyActionKeys.PATCH_STATUS_COMPANY_ON_CONGLOMERATE_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: undefined,
      };
    case CompanyActionKeys.PATCH_STATUS_COMPANY_ON_CONGLOMERATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: undefined,
        listCompanyOnConglomerateCurrency: {
          ...state.currentCompany,
          ativa: action.payload.ativa,
          externalId: action.payload.empresasExternalId,
        } as any,
      };
    case CompanyActionKeys.PATCH_STATUS_COMPANY_ON_CONGLOMERATE_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    case CompanyActionKeys.GET_COMPANY_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: undefined,
      };
    case CompanyActionKeys.GET_COMPANY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: undefined,
        currentCompany: action.payload,
      };
    case CompanyActionKeys.GET_COMPANY_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    case CompanyActionKeys.GET_COMPANY_ADMIN_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: undefined,
      };
    case CompanyActionKeys.GET_COMPANY_ADMIN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: undefined,
        currentCompanyAdmin: action.payload,
      };
    case CompanyActionKeys.GET_COMPANY_ADMIN_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    case CompanyActionKeys.DELETE_COMPANY_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: undefined,
      };
    case CompanyActionKeys.DELETE_COMPANY_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    case CompanyActionKeys.CREATE_COMPANY_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: undefined,
      };
    case CompanyActionKeys.CREATE_BRANCH_COMPANY_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: undefined,
      };
    case CompanyActionKeys.CREATE_COMPANY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: undefined,
      };
    case CompanyActionKeys.CREATE_COMPANY_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    case CompanyActionKeys.CHANGE_RESPONSIBILYTI_USER_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: undefined,
      };
    case CompanyActionKeys.CHANGE_RESPONSIBILYTI_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: undefined,
      };
    case CompanyActionKeys.CHANGE_RESPONSIBILYTI_USER_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    case CompanyActionKeys.CHANGE_COMPANY_ADRESS_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: undefined,
      };
    case CompanyActionKeys.CHANGE_COMPANY_ADRESS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    case CompanyActionKeys.CHANGE_COMPANY_PAYING_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: undefined,
      };
    case CompanyActionKeys.CHANGE_COMPANY_PAYING_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    case CompanyActionKeys.RESET_COMPANY:
      return {
        ...state,
        currentCompany: undefined,
      };

    case CompanyActionKeys.SET_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };

    case CompanyActionKeys.SHOW_COULD_NOT_DELETE:
      return {
        ...state,
        isCouldNotDeleteOpen: !state.isCouldNotDeleteOpen,
      };

    default:
      return state;
  }
};

export default companyReducer;
