import React, { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { Row, Col } from 'antd';
import {
  patternLabelStyle,
  TextInput,
} from '../../../../Components/Inputs/TextInput';
import { Button, Card, Form, Text } from '../../../../Components';
import { getCardStatus } from '../../../../Components/Card';
import { history } from '../../../../Store';
import { CreateInitialValuesForUserGroups } from '../../../../Utils/CreateInitialValuesUtils';
import { InputWithSearch } from '../../../../Components/Inputs/InputWithSearch';
import { List } from '../../../../Components/List';
import { IListUserData } from '../../../../Data/Interfaces/response/User/IGetUserReponse';
import { ICreateUserGroupRequest } from '../../../../Data/Interfaces/request/UserGroup/IUserGroupRequest';
import { Avatar } from '../../../../Components/Avatar';
import { CreateSchema } from './CreateForm.schema';
import { IQueryListParams } from '../../../../Utils/Http';
import { useGetUserListType } from '../../../../Hooks/Accountmanager/UserHooks';
import {
  useCreateUserGroupType,
  useUserGroupNameAlreadyRegistredType,
} from '../../../../Hooks/Accountmanager/UserGroupHooks';
import _ from 'lodash';

import styles from './UserGroup.module.scss';

export const SelectableUserList = (
  name: string,
  lastName: string,
  email: string
) => (
  <div className={styles['avatar-list']}>
    <Avatar fisrtName={name} lastName={lastName} size="M" type="ui-tiny-bold" />
    <div>
      <Text
        type="ui-tiny-bold"
        color="text-50"
        children={`${name} ${lastName}`}
      />
      <Text type="small-text" color="text-50" children={email} />
    </div>
  </div>
);

const UserGroup: React.FC = () => {
  const [usersSelected, setUsersSelected] = useState<IListUserData[]>([]);
  const [pagination, setPagination] = useState<IQueryListParams>({
    PageIndex: 0,
    PageSize: 10,
  });

  const { data: users } = useGetUserListType(pagination);
  const createUserGroup = useCreateUserGroupType({
    onSuccess: () => history.push('/admin/user-group'),
  });

  const addOrRemoveUsers = (value: string, remove: boolean) => {
    const index = usersSelected?.findIndex(
      (user) => user.externalId.toString() === value.toString()
    );
    const tmp = usersSelected?.slice();
    if (remove) {
      tmp.splice(index, 1);
      setUsersSelected([...tmp]);
      return;
    }

    const user = users?.data.find((x) => x.externalId === value);

    user && tmp.push(user);

    setUsersSelected([...tmp]);
  };

  const { t } = useTranslation();

  const onSubmit = async (values: ICreateUserGroupRequest) => {
    var body: ICreateUserGroupRequest = { ...values };
    if (usersSelected) body.usuarios = usersSelected.map((x) => x.externalId);
    body.seAtivo = true;
    await createUserGroup.mutateAsync(body);
  };

  return (
    <Form
      initialValues={CreateInitialValuesForUserGroups()}
      onSubmit={onSubmit}
      schema={CreateSchema}
    >
      {(props) => (
        <>
          <Card
            title={t('admin.createUserGroupPage.details')}
            status={getCardStatus(['nome', 'descricao, usuarios'], props)}
            withoutCollapse={true}
          >
            <Row gutter={[16, 0]} justify="space-between">
              <Col span={12}>
                <UserGroupNameField />
              </Col>
              <Col span={12}>
                <TextInput
                  name="descricao"
                  label={[
                    {
                      children: t(
                        'admin.createUserGroupPage.detaildCard.description'
                      ),
                      ...patternLabelStyle,
                    },
                  ]}
                  placeHolder={t(
                    'admin.createUserGroupPage.detaildCard.descriptionPlaceholder'
                  )}
                />
              </Col>
            </Row>
            <Row gutter={[16, 0]} justify="space-between">
              <Col span={12}>
                <InputWithSearch
                  name="usuarios"
                  items={users?.data
                    ?.filter((user) =>
                      usersSelected.every(
                        (userSelected) =>
                          userSelected.externalId !== user.externalId
                      )
                    )
                    .map((x) => ({
                      id: x.externalId,
                      label: x.nome,
                      avatar: {
                        name: x.nome,
                        lastName: x.sobrenome,
                        email: x.email,
                      },
                    }))}
                  label={[
                    {
                      children: t(
                        'admin.createUserGroupPage.detaildCard.users'
                      ),
                      ...patternLabelStyle,
                    },
                  ]}
                  placeHolder={t(
                    'admin.createUserGroupPage.detaildCard.usersPlaceholder'
                  )}
                  withoutMarginBottom={true}
                  onChange={(value) => addOrRemoveUsers(value, false)}
                  onScrollEnd={() =>
                    setPagination({
                      ...pagination,
                      PageIndex: pagination.PageIndex++,
                    })
                  }
                  withoutSearchIcon
                  notShowSelectedItem
                  onSearch={(x) =>
                    setPagination({
                      ...pagination,
                      Search: x,
                    })
                  }
                />
              </Col>
            </Row>
            <Row gutter={[16, 0]} justify="space-between">
              <Col span={24}>
                <List
                  items={usersSelected?.map((x) => ({
                    id: x.externalId,
                    data: SelectableUserList(x.nome, x.sobrenome, x.email),
                  }))}
                  onDelete={(value) => addOrRemoveUsers(value, true)}
                />
              </Col>
            </Row>
          </Card>
          <div className={styles['footer-button']}>
            <Button
              type="secondary"
              children={t('admin.createUserGroupPage.footerButton.cancel')}
              onClick={() => history.push('/admin/user-group')}
            />
            <Button
              type="primary"
              children={t('admin.createUserGroupPage.footerButton.save')}
              htmlType="submit"
            />
          </div>
        </>
      )}
    </Form>
  );
};

export default React.memo(UserGroup);

const UserGroupNameField: FC = () => {
  const { t } = useTranslation();

  const form = useFormikContext();

  const { mutateAsync: getThereIsUserGroupName } =
    useUserGroupNameAlreadyRegistredType({
      onError: () => {
        form.setErrors({
          ...form.errors,
          nome: t('common.error.userGroupNameRegistred'),
        });
      },
      onSuccess: () =>
        form.setErrors({
          ...form.errors,
          nome: undefined,
        }),
    });

  const thereIsUserGroupName = useMemo(
    () =>
      _.debounce(
        async (value: string) => await getThereIsUserGroupName(value),

        600
      ),
    [getThereIsUserGroupName]
  );

  return (
    <TextInput
      name="nome"
      label={[
        {
          children: t('admin.createUserGroupPage.detaildCard.name'),
          ...patternLabelStyle,
        },
      ]}
      placeHolder={t('admin.createUserGroupPage.detaildCard.namePlaceholder')}
      required={true}
      onChange={(x) => thereIsUserGroupName(x)}
    />
  );
};
