import React, {
  CSSProperties,
  FC,
  ReactNode,
  useEffect,
  useState,
} from 'react';
import { Modal as AntdModal } from 'antd';
import { Icon } from '../Icon';
import { Footer, IFooter } from './Components/Footer';
import { Form } from '..';
import { Color } from '../../Utils/ColorUtils';
import { IFChildren } from '../Form';

import './Modal.override.scss';

export interface IModal extends IFooter {
  title: string;
  body: ReactNode;
  visible?: boolean;
  cancelButtonName?: string;
  okButtonName?: string;
  okButtonColor?: Color;
  okButtonClassName?: string;
  onOkClickForm?: (values: any, formik: any) => void;
  disabledButton?: boolean;
  getContainer?: string | false | HTMLElement;
  footer?: boolean;
  withForm?: boolean;
  initialValues?: any;
  maxWidth?: number;
  minWidth?: number;

  loading?: boolean;

  withoutOkButton?: boolean;
}

export const Modal: FC<IModal> = ({
  title,
  body,
  visible,
  buttonOkWidth,
  cancelButtonName,
  okButtonName,
  okButtonColor,
  buttonCancelWidth,
  okButtonClassName,
  onClose,
  onCancelClick,
  onOkClick,
  htmlType,
  onOkClickForm,
  className,
  disabledButton,
  initialValues,
  maxWidth,
  minWidth,
  getContainer,
  footer = true,
  withForm,
  loading,
  withoutOkButton,
}) => {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <AntdModal
      title={title}
      open={visible || isVisible}
      onCancel={() => onClose && onClose('cancel')}
      footer={<></>}
      getContainer={getContainer}
      closeIcon={<Icon icon="close-x" color="text-400" size="XXL" />}
      className={className ? className : ''}
    >
      {!!withForm ? (
        <Form
          onSubmit={onOkClickForm && onOkClickForm}
          initialValues={initialValues}
        >
          {(propsForm) => (
            <>
              <BodyModal
                body={body}
                visible={!!visible}
                changeVisible={(value) => setIsVisible(value)}
                maxWidth={maxWidth}
                minWidth={minWidth}
                propsForm={propsForm}
              />

              {footer && (
                <Footer
                  okButtonClassName={okButtonClassName}
                  okButtonName={okButtonName}
                  cancelButtonName={cancelButtonName}
                  buttonOkWidth={buttonOkWidth && buttonOkWidth}
                  buttonCancelWidth={buttonCancelWidth && buttonCancelWidth}
                  onClose={onClose && onClose}
                  onCancelClick={onCancelClick}
                  onOkClick={onOkClick}
                  htmlType={htmlType}
                  okButtonColor={okButtonColor}
                  disabledButton={disabledButton}
                  withForm
                  withoutOkButton={withoutOkButton}
                  propsForm={propsForm}
                  loadingOkButton={loading}
                />
              )}
            </>
          )}
        </Form>
      ) : (
        <>
          <BodyModal
            body={body}
            visible={!!visible}
            changeVisible={(value) => setIsVisible(value)}
            maxWidth={maxWidth}
            minWidth={minWidth}
          />
          {footer && (
            <Footer
              okButtonClassName={okButtonClassName}
              okButtonName={okButtonName}
              cancelButtonName={cancelButtonName}
              buttonOkWidth={buttonOkWidth && buttonOkWidth}
              buttonCancelWidth={buttonCancelWidth && buttonCancelWidth}
              onClose={onClose && onClose}
              onCancelClick={onCancelClick}
              onOkClick={onOkClick}
              htmlType={htmlType}
              okButtonColor={okButtonColor}
              disabledButton={disabledButton}
              withoutOkButton={withoutOkButton}
              loadingOkButton={loading}
            />
          )}
        </>
      )}
    </AntdModal>
  );
};

interface IBodyModal {
  body: ReactNode;
  changeVisible?: (value: boolean) => void;
  visible?: boolean;

  style?: CSSProperties;

  maxWidth?: number | string;
  minWidth?: number | string;
  propsForm?: IFChildren;
}

export const BodyModal: FC<IBodyModal> = ({
  body,
  visible,
  changeVisible,
  maxWidth,
  minWidth,
  style,
  propsForm,
}) => {
  useEffect(() => {
    if (!visible) {
      propsForm?.resetForm();
      changeVisible && changeVisible(false);
    } else changeVisible && changeVisible(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);
  return (
    <div style={{ maxWidth: maxWidth, minWidth: minWidth, ...style }}>
      {body}
    </div>
  );
};
