import { useQueryClient } from '@tanstack/react-query';
import { FormikHelpers, FormikValues, useFormikContext } from 'formik';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TextInput } from '../../../../../../Components';
import { patternLabelStyle } from '../../../../../../Components/Inputs/TextInput';
import { Modal } from '../../../../../../Components/Modal';
import { IEditProductGroupRequest } from '../../../../../../Data/Interfaces/request/Product/IProductRequest';
import {
  FETCH_LIST_GROUP,
  useGroupCreate,
  useGroupEdit,
  useGroupValidationName,
} from '../../../../../../Hooks/Supply/ProductHooks';
import _ from 'lodash';

interface ICreateGroupModal {
  visible: boolean;
  changeVisible: (value: boolean) => void;
  title?: string;
  selectedRowsOnTable?: any;
}

const FormGroupModal: FC<ICreateGroupModal> = ({
  visible,
  changeVisible,
  title,
  selectedRowsOnTable,
}) => {
  const { t } = useTranslation();
  const abbreviatedI18n = 'products.groupList.modal';
  const [buttonDisabled, setButtonDisabled] = useState(true);

  const createGroup = useGroupCreate();
  const editGroup = useGroupEdit();

  const queryClient = useQueryClient();

  const onSubmit = async (
    values: IEditProductGroupRequest,
    helpers: FormikHelpers<FormikValues>
  ) => {
    const body: IEditProductGroupRequest = {
      ...values,
      helpers,
    };

    if (title && selectedRowsOnTable) {
      const res = await editGroup.mutateAsync({
        grupoExternalId: selectedRowsOnTable[0]?.externalId,
        descricao: body?.descricao,
        helpers,
      });
      if (res.success) queryClient.invalidateQueries([FETCH_LIST_GROUP]);
    } else {
      const res = await createGroup.mutateAsync({
        descricao: body?.descricao,
        helpers,
      });
      if (res.success === false)
        queryClient.invalidateQueries([FETCH_LIST_GROUP]);
    }
  };

  return (
    <Modal
      onOkClickForm={(values, formik) => {
        onSubmit(values, formik);
        changeVisible(!visible);
      }}
      title={title ? title : t(`${abbreviatedI18n}.title`)}
      body={
        <ModalBody
          isProductGroupAlreadyRegistred={(x) => x && setButtonDisabled(x)}
          onDisabledButton={(x) => setButtonDisabled(x)}
        />
      }
      visible={visible}
      htmlType="submit"
      okButtonName={title ? '' : t(`${abbreviatedI18n}.okButton`)}
      onCancelClick={() => changeVisible(!visible)}
      onClose={() => changeVisible(!visible)}
      initialValues={{
        descricao: selectedRowsOnTable && selectedRowsOnTable[0]?.descricao,
      }}
      disabledButton={buttonDisabled}
      withForm
    />
  );
};

interface IModalBody {
  isProductGroupAlreadyRegistred?: (value: boolean) => void;
  onDisabledButton?: (value: boolean) => void;
}

const ModalBody: FC<IModalBody> = ({
  isProductGroupAlreadyRegistred,
  onDisabledButton,
}) => {
  const { t } = useTranslation();
  const abbreviatedI18n = 'products.groupList.modal';
  const form: any = useFormikContext();

  const onGroupValidationError = useCallback(() => {
    form.setErrors({
      ...form.errors,
      descricao: t('products.groupList.modal.errorGroupNameExist'),
    });
    isProductGroupAlreadyRegistred && isProductGroupAlreadyRegistred(true);
  }, [form, t, isProductGroupAlreadyRegistred]);

  const { mutateAsync } = useGroupValidationName({
    onError: onGroupValidationError,
  });

  useEffect(() => {
    onDisabledButton && onDisabledButton(!form.values.descricao);
  }, [form.values.descricao, onDisabledButton]);

  const fetchProductGroupValidationName = useMemo(
    () =>
      _.debounce(async (description: string) => {
        return await mutateAsync({
          descricao: description,
        });
      }, 500),
    [mutateAsync]
  );

  return (
    <div style={{ width: '400px', padding: '20px' }}>
      <TextInput
        name="descricao"
        label={[
          {
            children: t(`${abbreviatedI18n}.label`),
            ...patternLabelStyle,
          },
        ]}
        placeHolder={t(`${abbreviatedI18n}.placeholder`)}
        type="text"
        withoutMarginBottom
        onChange={(x) => fetchProductGroupValidationName(x)}
        maxLength={50}
      />
    </div>
  );
};

export default FormGroupModal;
