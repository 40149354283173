import React, { useState } from 'react';
import { Table } from '../../../../Components';
import { useTranslation } from 'react-i18next';
import { FormSubGroupModal } from './Modal/FormSubGroupModal';
import { subGroupCollums } from './sub-groups.columns';
import { history } from '../../../../Store';
import { TitleHandler, BodyHandler } from './subgroupListUtils';
import {
  FETCH_LIST_SUBGROUP,
  useSubGroupDelete,
  useSubGroupGetList,
} from '../../../../Hooks/Supply/ProductHooks';
import { useQueryClient } from '@tanstack/react-query';
import { IPagination } from '../../../../Components/Table';
import { PageLoading } from '../../../../Layouts/PageLoading';
import { handleModalError } from '../../../../Utils/ErrorHandlingUtils';
import { App } from 'antd';

export const List: React.FC = () => {
  const [selectedRowsOnTable, setSelectedRowsOnTable] = useState<any>([]);
  const [visibleModal, setVisibleModal] = useState(false);
  const [visibleEditModal, setVisibleEditModal] = useState(false);
  const { t } = useTranslation();
  const [pagination, setPagination] = useState<IPagination>({
    PageIndex: 0,
    PageSize: 10,
    Sorter: { column: 'descricao', direction: 'ASC' },
  });

  const app = App.useApp();

  const abbreviatedI18n = 'products.subGroupList';

  const deleteSubGroup = useSubGroupDelete();

  const queryClient = useQueryClient();

  const { data: subGroupList, isLoading } = useSubGroupGetList(pagination);

  return (
    <>
      <PageLoading loading={isLoading}>
        <Table
          columns={subGroupCollums(t)}
          data={
            subGroupList?.data?.map((x) => ({
              ...x,
              key: `table_subGroup_${x.externalId}`,
            })) || []
          }
          headerOptions={{
            hasSearch: true,
            newButton: {
              label: t(`${abbreviatedI18n}.newButton`),
              onClick: () => setVisibleModal(true),
              permission: {
                permission: 3015,
                tooltip: 'noPermission.supply.subGroups.create',
              },
            },
            deleteButton: {
              permission: {
                permission: 3017,
                tooltip: 'noPermission.supply.subGroups.delete',
              },
            },
            editButton:
              selectedRowsOnTable?.length === 1
                ? {
                    editButtonWithoutDropdown: true,
                    editButtonWithoutDropdownClick: () =>
                      setVisibleEditModal(true),
                    permission: {
                      permission: 3016,
                      tooltip: 'noPermission.supply.subGroups.edit',
                    },
                  }
                : undefined,
            searchPlaceholder: t(`${abbreviatedI18n}.placeholder`),
            nameKey: 'descricao',
          }}
          rowsOptions={{
            onRowDoubleClick: (x) =>
              history.push(`/inventory/sub-groups/${x.externalId}/details`),
            permission: {
              permission: 3014,
              tooltip: 'noPermission.supply.subGroups.detail',
            },
          }}
          selectedRowsOnTable={(x) => setSelectedRowsOnTable(x)}
          loading={isLoading}
          hasSelection={true}
          onRefetch={(pagination) => {
            setPagination(pagination);
          }}
          pagination={
            subGroupList
              ? {
                  Count: subGroupList.count,
                  PageIndex: subGroupList.pageIndex,
                  PageSize: subGroupList.pageSize,
                }
              : false
          }
          onDelete={(subGroup) =>
            subGroup.map(async (x) => {
              const res = await deleteSubGroup.mutateAsync(
                {
                  subGruposExternalId: subGroup.map((x) => x?.externalId),
                },
                {
                  onError: handleModalError(app),
                }
              );
              if (res.success)
                queryClient.invalidateQueries([FETCH_LIST_SUBGROUP]);
            })
          }
          deleteModal={{
            title: TitleHandler(selectedRowsOnTable, t),
            body: BodyHandler(selectedRowsOnTable, t),
            buttons: {
              okButtonColor: 'status-danger-base',
            },
          }}
        />

        <FormSubGroupModal
          changeVisible={(x) => setVisibleModal(x)}
          visible={visibleModal}
          group={selectedRowsOnTable}
        />

        <FormSubGroupModal
          changeVisible={(x) => setVisibleEditModal(x)}
          visible={visibleEditModal}
          group={selectedRowsOnTable}
          title={`${t('common.edit')} "${
            selectedRowsOnTable && selectedRowsOnTable[0]?.descricao
          }"`}
          selectedRowsOnTable={selectedRowsOnTable}
        />
      </PageLoading>
    </>
  );
};
