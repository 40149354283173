import { Col, Row } from 'antd';
import { FormikHelpers, FormikValues, useFormikContext } from 'formik';
import { FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Card,
  Form,
  TextInput,
  Tooltip,
} from '../../../../../Components';
import { patternLabelStyle } from '../../../../../Components/Inputs/TextInput';
import { IEditProductGroupRequest } from '../../../../../Data/Interfaces/request/Product/IProductRequest';
import {
  FETCH_GET_GROUP,
  useGroupEdit,
  useGroupGet,
  useGroupValidationName,
} from '../../../../../Hooks/Supply/ProductHooks';
import styles from './DetailsForm.module.scss';
import { useParams } from 'react-router';
import { useQueryClient } from '@tanstack/react-query';
import { Modal } from '../../../../../Components/Modal';
import { history } from '../../../../../Store';
import _ from 'lodash';

interface IDetailsForm {
  disabled: boolean;
  toogleDisabled: (x: boolean) => void;
}

export const DetailProductGroupForm: FC<IDetailsForm> = ({
  disabled,
  toogleDisabled,
}) => {
  const { t } = useTranslation();
  const { externalId } = useParams<{ externalId: string }>();
  const { data } = useGroupGet(externalId);
  const editGroup = useGroupEdit();
  const queryClient = useQueryClient();
  const [isBackModalOpen, setIsBackModalOpen] = useState(false);

  const onSubmit = async (
    values: IEditProductGroupRequest,
    helpers: FormikHelpers<FormikValues>
  ) => {
    const body: IEditProductGroupRequest = { ...values, helpers };

    if (data) {
      const res = await editGroup.mutateAsync({
        grupoExternalId: data?.externalId,
        descricao: body?.descricao,
        helpers,
      });
      if (res.success) {
        queryClient.invalidateQueries([FETCH_GET_GROUP]);
        toogleDisabled(!disabled);
      }
    }
  };

  return (
    <>
      <Form
        initialValues={{
          ...data,
        }}
        onSubmit={onSubmit}
      >
        {(props) => {
          return (
            <>
              <Card title={t('common.details')} withoutCollapse>
                <Row justify="space-between">
                  <Col span={24}>
                    <InputDescriptionGroup disabled={disabled} />
                  </Col>
                </Row>
                {!disabled && (
                  <div className={styles['footer-button']}>
                    <Button
                      type="secondary"
                      children={t('admin.editUserPage.footerButtons.cancel')}
                      onClick={() =>
                        !!props?.dirty
                          ? setIsBackModalOpen(true)
                          : toogleDisabled(!disabled)
                      }
                    />
                    <Button
                      type="primary"
                      children={t('admin.editUserPage.footerButtons.save')}
                      htmlType="submit"
                      disabled={!props.dirty}
                    />
                  </div>
                )}
              </Card>
            </>
          );
        }}
      </Form>

      <Modal
        visible={isBackModalOpen}
        onOkClick={() => history.push('/inventory/groups')}
        onCancelClick={() => setIsBackModalOpen(false)}
        okButtonName={t('supply.balanceAdjustment.backModal.okButton')}
        title={t('supply.balanceAdjustment.backModal.title')}
        body={
          <div style={{ padding: 20, maxWidth: 400 }}>
            {t('supply.balanceAdjustment.backModal.body')}
          </div>
        }
      />
    </>
  );
};

interface IInputDescriptionGroup {
  disabled: boolean;
}

const InputDescriptionGroup: FC<IInputDescriptionGroup> = ({ disabled }) => {
  const form: any = useFormikContext();
  const { t } = useTranslation();

  const onGroupValidateError = useCallback(() => {
    form.setErrors({
      ...form.errors,
      descricao: t('products.groupList.modal.errorGroupNameExist'),
    });
  }, [form, t]);

  const { mutateAsync } = useGroupValidationName({
    onError: onGroupValidateError,
  });
  const { externalId } = useParams<{ externalId: string }>();
  const { data } = useGroupGet(externalId);

  const fetchProductGroupValidationName = useMemo(
    () =>
      _.debounce(async (description: string) => {
        return await mutateAsync({
          descricao: description,
        });
      }, 500),
    [mutateAsync]
  );

  return (
    <Tooltip
      title={`${t('products.toolTips.groups.nameOfGroup')} ${data?.descricao}`}
      showMe={data?.descricao !== form?.values?.descricao && !disabled}
      placement={'topLeft'}
    >
      <TextInput
        className={styles['text-input']}
        name="descricao"
        disabled={disabled}
        withoutMarginBottom={disabled}
        label={[
          {
            children: t('products.groupList.modal.label'),
            ...patternLabelStyle,
          },
        ]}
        onChange={(x) => fetchProductGroupValidationName(x)}
        withStatusIcon
        maxLength={50}
      />
    </Tooltip>
  );
};
