import { FormikHelpers, FormikValues } from 'formik';
import React, { useEffect, useState } from 'react';
import { Form } from '../../../../Components';
import { ICreateCompanyRequest } from '../../../../Data/Interfaces/request/Company/ICompanyRequest';
import { RemoveMask } from '../../../../Utils/MasksUtils';
import { CompanyDataCard } from '../../../../Layouts/CompanyFormLayout/CompanyDataCard';
import { CompanyCard } from '../../../../Layouts/CompanyFormLayout/CompanyAdressCard';
import { CompanyGroupsCardWrapper } from '../../../../Layouts/CompanyFormLayout/CompanyGroupsCard';
import { CompanyCardSchema } from './CompanyForm.schema';
import { useUserPreferencesType } from '../../../../Hooks/Accountmanager/UserHooks';
import { useCompanyCreateMutation } from '../../../../Hooks/Accountmanager/CompanyHooks';
import { CreateCompanyBillingsCard } from '../../../../Layouts/CompanyFormLayout/CompanyBillingsCard/Components/CreateForm';
import { useConglomerateCreateMutation } from '../../../../Hooks/Accountmanager/ConglomerateHooks';
import { IPostConglomerateRequest } from '../../../../Data/Interfaces/request/Conglomerate/IConglomerate';

import { FooterButton } from '../Components/FooterButton';
import { CompanyResponsibleUserCardWrapperAdmin } from '../../../../Layouts/CompanyFormLayout/CompanyResponsibleUserCard/EditAdmin';

interface ICompanyForm {
  editForm?: boolean;
}

export const CompanyCreate: React.FC<ICompanyForm> = ({ editForm }) => {
  const [radioCompanyGroup, setRadioCompanyGroup] = useState(true);
  const [radioUser, setRadioUser] = useState(true);
  const [radioCompanyBillings, setRadioCompanyBillings] = useState(true);
  const [validateAfterSubmit, setValidateAfterSubmit] = useState(false);
  const [companyCardSchema, setCompanyCardSchema] = useState<any>();

  const createCompany = useCompanyCreateMutation();
  const createConglomerate = useConglomerateCreateMutation();
  const { data } = useUserPreferencesType();

  useEffect(() => {
    setCompanyCardSchema(
      CompanyCardSchema({
        radioCompanyGroup,
        radioUser,
        radioCompanyBillings,
      })
    );
  }, [radioCompanyBillings, radioUser, radioCompanyGroup]);

  const onSubmit = async (
    values: any,
    helpers: FormikHelpers<FormikValues>
  ) => {
    values.endereco.cep = RemoveMask(values?.endereco?.cep) || '';

    if (radioCompanyGroup) {
      var conglomerateBody: IPostConglomerateRequest = { ...values };

      const res = await createConglomerate.mutateAsync({
        ...conglomerateBody,
        cnpj: RemoveMask(values?.cnpj) || '',
        ...(conglomerateBody.usuario && {
          usuario: {
            nome: conglomerateBody.usuario.nome,
            sobrenome: conglomerateBody.usuario.sobrenome,
            email: conglomerateBody.usuario.email,
            celular: conglomerateBody.usuario.celular,
            dataNascimento:
              RemoveMask(
                values?.usuario?.dataNascimento,
                data?.preferencias?.padraoData
              ) || '',
            cpf: conglomerateBody.usuario.cpf,
            ativo: true,
          },
        }),
      });

      res.success && helpers.resetForm();
    } else {
      var companyBody: ICreateCompanyRequest = { ...values };

      const res = await createCompany.mutateAsync({
        ...companyBody,
        cnpj: RemoveMask(values?.cnpj) || '',
        ...(companyBody.usuario && {
          usuario: {
            nome: companyBody.usuario.nome,
            sobrenome: companyBody.usuario.sobrenome,
            email: companyBody.usuario.email,
            celular: companyBody.usuario.celular,
            dataNascimento:
              RemoveMask(
                values?.usuario?.dataNascimento,
                data?.preferencias?.padraoData
              ) || '',
            cpf: companyBody.usuario.cpf,
            ativo: true,
          },
        }),
      });
      res.success && helpers.resetForm();
    }
  };

  return (
    <Form
      onSubmit={onSubmit}
      schema={companyCardSchema}
      validateOnChange={validateAfterSubmit}
    >
      <>
        <CompanyDataCard />
        <CompanyCard />
        <CompanyGroupsCardWrapper
          onChangeRadioCompanyGroup={(value) => setRadioCompanyGroup(value)}
          radioCompanyGroup={radioCompanyGroup}
        />
        <CompanyResponsibleUserCardWrapperAdmin
          radioCompanyGroup={radioCompanyGroup}
          radioUser={radioUser}
          onChangeRadioUser={setRadioUser}
          isLoading={createCompany.isLoading || createConglomerate.isLoading}
        />
        <CreateCompanyBillingsCard
          radioCompanyGroup={radioCompanyGroup}
          onChangeRadioBillings={setRadioCompanyBillings}
          radioBillings={radioCompanyBillings}
        />
        <FooterButton
          onClick={setValidateAfterSubmit}
          loading={createConglomerate.isLoading || createCompany.isLoading}
        />
      </>
    </Form>
  );
};
