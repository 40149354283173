import React, { FC } from 'react';
import { Checkbox as AntCheckbox } from 'antd';

import './CheckBox.override.scss';
import styles from './CheckBox.module.scss';

import { Field } from 'formik';
import { IField } from '../interfaces/IField';
import { Icon, Tooltip } from '../..';
import { IconName } from '../../Icon/IconClasses';

export interface ICheckbox {
  name: string;
  label?: string;
  disabled?: boolean;
  indeterminate?: boolean;
  className?: string;
  withStatusIcon?: boolean;
  withTooltip?: {
    title: string;
    icon: IconName;
  };
  onClick?: (value?: string) => void;
}

export const Checkbox: FC<ICheckbox> = ({
  name,
  label,
  disabled,
  className,
  indeterminate,
  withStatusIcon,
  withTooltip,
  onClick,
}) => (
  <Field type="checkbox" name={name}>
    {(props: IField) => {
      return (
        <div className={styles['divCheckbox']}>
          <AntCheckbox
            id={`${name}-for`}
            disabled={disabled || props.form.isSubmitting}
            className={className}
            indeterminate={indeterminate}
            {...props.field}
            onChange={(e) => {
              onClick && onClick();
              props.field.onChange(e);
            }}
          />
          {label ? (
            <label
              htmlFor={`${name}-for`}
              className={`${styles['label']} ${
                disabled ? styles['disabled'] : ''
              }`}
            >
              {label}
            </label>
          ) : null}
          {withStatusIcon && props?.meta.value !== props?.meta.initialValue && (
            <Icon
              icon="status-attention"
              size="SS"
              className={styles['icon-status']}
            />
          )}
          {withTooltip && (
            <Tooltip
              title={withTooltip.title}
              showMe
              children={
                <Icon
                  className={styles['icon-with-tooltip']}
                  icon={withTooltip.icon}
                  color="text-300"
                  size="S"
                />
              }
            />
          )}
        </div>
      );
    }}
  </Field>
);
