import * as yup from 'yup';
import i18n from '../../../../../Components/i18n';

export const SubGroupDetailsSchema = yup.object().shape({
  descricao: yup
    .string()
    .required(i18n.t('products.subGroupList.modal.errorSubGroupNameExist')),
  grupoPaiExternalId: yup
    .string()
    .required(i18n.t('products.subGroupList.modal.errorSubGroupNameExist')),
});
