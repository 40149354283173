import { IPatchUserRequest } from '../Data/Interfaces/request/User/IUserRequest';
import { ILoginResponse } from '../Data/Interfaces/response/Auth/ILoginResponse';
import { IGetUserResponse } from '../Data/Interfaces/response/User/IGetUserReponse';
import StorageUtils from './StorageUtils';

export type themes = 'darkMode' | 'midnightMode' | 'lightMode';

export enum Themes {
  'darkMode' = 3,
  'midnightMode' = 2,
  'lightMode' = 1,
}

export const keepDataStorage = (payload: IPatchUserRequest) => {
  payload.data.tema !== undefined &&
    preferencesUtils.setTheme({ temaUsuario: payload.data.tema });
  payload.data.textoAmpliado !== undefined &&
    preferencesUtils.setTextoAmpliado({
      textoAmpliado: payload.data.textoAmpliado,
    });
  payload.data.textoNegrito !== undefined &&
    preferencesUtils.setTextoNegrito({
      textoNegrito: payload.data.textoNegrito,
    });
  payload.data.contrasteAumentado !== undefined &&
    preferencesUtils.setContrasteAumentado({
      contrasteAumentado: payload.data.contrasteAumentado,
    });
  payload.data.timeZone !== undefined &&
    preferencesUtils.setTimeZone({ timeZone: payload.data.timeZone });
};

export const keepDataStorageLogin = (
  login: ILoginResponse | undefined,
  user: IGetUserResponse | undefined,
  currencyType: number
) => {
  if (user) {
    preferencesUtils.setAllPreferences({
      ...user.preferencias,
      tipoMoedaId: currencyType,
    });
  } else if (login) {
    preferencesUtils.setAllPreferences(login.preferencias);
  }
};

interface ITheme {
  temaUsuario: Themes;
}
interface ITextoAmpliado {
  textoAmpliado: boolean;
}
interface ITextoNegrito {
  textoNegrito: boolean;
}
interface IContrasteAumentado {
  contrasteAumentado: boolean;
}
interface ITimeZone {
  timeZone: string;
}

interface IPreferences {
  temaUsuario?: Themes;
  textoAmpliado?: boolean;
  textoNegrito?: boolean;
  contrasteAumentado?: boolean;
  timeZone?: string;
  tipoMoedaId?: number;
}

class PreferencesUtils {
  public setTheme(payload: ITheme) {
    StorageUtils.SetPreference(this.getStoragePreference(payload));
    this.setThemeDOM(payload.temaUsuario);
  }
  public setTextoAmpliado(payload: ITextoAmpliado) {
    StorageUtils.SetPreference(this.getStoragePreference(payload));
    this.setTextoAmpliadoDOM(payload.textoAmpliado);
  }
  public setTextoNegrito(payload: ITextoNegrito) {
    StorageUtils.SetPreference(this.getStoragePreference(payload));
    this.setTextoNegritoDOM(payload.textoNegrito);
  }
  public setContrasteAumentado(payload: IContrasteAumentado) {
    StorageUtils.SetPreference(this.getStoragePreference(payload));
    this.setContrasteAumentadoDOM(payload.contrasteAumentado);
  }
  public setTimeZone(payload: ITimeZone) {
    StorageUtils.SetPreference(this.getStoragePreference(payload));
  }

  public setAllPreferences(payload: IPreferences) {
    StorageUtils.SetPreference(JSON.stringify(payload));
    payload?.temaUsuario !== undefined && this.setThemeDOM(payload.temaUsuario);
    payload?.textoAmpliado !== undefined &&
      this.setTextoAmpliadoDOM(payload.textoAmpliado);
    payload?.textoNegrito !== undefined &&
      this.setTextoNegritoDOM(payload.textoNegrito);
    payload?.contrasteAumentado !== undefined &&
      this.setContrasteAumentadoDOM(payload.contrasteAumentado);
    payload?.tipoMoedaId !== undefined &&
      this.setTipoMoedaIdDOM(payload.tipoMoedaId);
  }

  private getStoragePreference(payload: IPreferences) {
    return JSON.stringify({ ...StorageUtils.getPreference(), ...payload });
  }

  private setThemeDOM(theme: Themes) {
    document.documentElement.classList.remove(
      Themes[Themes.darkMode],
      Themes[Themes.midnightMode],
      Themes[Themes.lightMode]
    );
    document.documentElement.classList.add(Themes[theme]);
  }
  private setTextoAmpliadoDOM(textoAmpliado: boolean) {
    textoAmpliado
      ? document.documentElement.classList.add('amplified-text')
      : document.documentElement.classList.remove('amplified-text');
  }
  private setTextoNegritoDOM(textoNegrito: boolean) {
    textoNegrito
      ? document.documentElement.classList.add('bold-text')
      : document.documentElement.classList.remove('bold-text');
  }
  private setContrasteAumentadoDOM(contrasteAumentado: boolean) {
    contrasteAumentado
      ? document.documentElement.classList.add('contrast')
      : document.documentElement.classList.remove('contrast');
  }
  private setTipoMoedaIdDOM(tipoMoedaId: number) {
    tipoMoedaId
      ? document.documentElement.classList.add('currency-type')
      : document.documentElement.classList.remove('currency-type');
  }
}

const preferencesUtils = new PreferencesUtils();
export default preferencesUtils;
