import React, { Dispatch, FC, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Text, i18n, Skeleton } from '../../';
import { UserActions } from '../../../Store/User/User.actions';
import { Colors } from '../../../Utils/ColorUtils';
import { PopOver } from '../../PopOver';
import { getMenuById, SideMenuItems } from '../SideMenu.items';
import { SectorItem } from './components/SectorItem';
import { history } from '../../../Store';
import { getContextId } from '../../../Store/User/User.selector';
import { motion } from 'framer-motion';

import styles from './SectorMenu.module.scss';

export interface ISectorMenu {
  isCollapsed: boolean;
  modules?: string[];
}

export const SectorMenu: FC<ISectorMenu> = ({ isCollapsed, modules }) => {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const isSingleModule = useMemo(() => modules?.length === 1, [modules]);
  const currentContextId = useSelector(getContextId);
  const menu = getMenuById(currentContextId);

  return (
    <>
      <PopOver
        visible={open}
        onOpenChange={() => !isSingleModule && setOpen(!open)}
        placement="rightBottom"
        title={headerSelectorMenu()}
        content={contentSelectorMenu(dispatch, setOpen, modules || [])}
        trigger="click"
      >
        {!!currentContextId ? (
          <Button
            type="no-color"
            noIconPadding={true}
            leftIcon={menu?.context?.icon || 'setting'}
            rightIcon={'chevron-right'}
            className={styles['btn-color']}
            fullWidth={true}
            style={{
              backgroundColor: `${
                Colors[menu?.context.backgroundColor || 'white']
              }`,
            }}
            disabled={isSingleModule}
            contentChild="start"
          >
            <motion.div
              style={{ overflow: 'hidden' }}
              animate={{ width: isCollapsed ? '0px' : '100%' }}
            >
              <div style={{ marginLeft: 10, width: 100, display: 'flex' }}>
                <Text
                  type="ui-tiny-bold"
                  children={menu ? (i18n.t(menu.context.name) as string) : ''}
                />
              </div>
            </motion.div>
          </Button>
        ) : (
          <div style={{ width: '180px' }}>
            <Skeleton size="lg" />
          </div>
        )}
      </PopOver>
    </>
  );
};

export const headerSelectorMenu = () => {
  return (
    <div className={styles['header']}>
      <Text type="paragraph2" color="text-400" className={styles['text']}>
        Alterar para setor
      </Text>
      <div className={styles['separator']}></div>
    </div>
  );
};

export const contentSelectorMenu = (
  dispatch: Dispatch<any>,
  setOpen: (open: boolean) => void,
  modules: string[]
) => {
  const onClick = (contextName: string) => {
    dispatch(UserActions.changeCurrentContext(contextName));
    setOpen(false);
  };

  const items = SideMenuItems.filter((item) =>
    modules.includes(item.context.id)
  );

  return (
    <div className={styles['content']}>
      {items.map((item) => (
        <div
          className={styles['SectorItem']}
          key={item.context.id}
          onClick={() => {
            history.push(item.items[0].link);
            onClick(item.context.id);
          }}
        >
          <SectorItem
            icon={item.context.icon}
            backgroundColor={item.context.backgroundColor}
            text={i18n.t(item.context.name)}
          />
        </div>
      ))}
    </div>
  );
};
