import { AxiosResponse } from 'axios';
import { Http } from '../../../Utils/Http';
import { ICurrencyResponse } from '../../Interfaces/response/Currency/ICurrencyResponse';

export class CurrencyAPI {
  public static async getCurrency(): Promise<AxiosResponse<ICurrencyResponse>> {
    const axios = await Http.axios();
    return axios.get<ICurrencyResponse>(
      '/pharma/accountmanager/v1/Empresa/ObterConfiguracao'
    );
  }
}
