import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { Table } from '../../../../Components';
import { IPagination } from '../../../../Components/Table/components/Pagination';
import { history } from '../../../../Store';
import { useTranslation } from 'react-i18next';
import { productCollums } from './product.columns';
import { editTableStatus } from '../../../../Utils/EditModalTableUtils';
import { IChangeProductStatusRequest } from '../../../../Data/Interfaces/request/Product/IProductRequest';
import { DeleteModalOnTable } from '../../../../Utils/DeleteModalUtils';
import { getCurrentCurrency } from '../../../../Store/Currency/Currency.selector';
import { DeleteModal } from './Modal/deleteModal';
import {
  FETCH_LIST_PRODUCT,
  useProductDelete,
  useProductGetList,
  useProductStatusEdit,
} from '../../../../Hooks/Supply/ProductHooks';
import { useQueryClient } from '@tanstack/react-query';
import { PageLoading } from '../../../../Layouts/PageLoading';
import { handleModalError } from '../../../../Utils/ErrorHandlingUtils';
import { App } from 'antd';

export const List: FC = () => {
  const [selectedRowsOnTable, setSelectedRowsOnTable] = useState<any>([]);
  const [pagination, setPagination] = useState<IPagination>({
    PageIndex: 0,
    PageSize: 10,
    Sorter: { column: 'descricao', direction: 'ASC' },
  });
  const currentCurrency = useSelector(getCurrentCurrency);

  const app = App.useApp();

  const deleteProduct = useProductDelete();
  const editProductStatus = useProductStatusEdit();

  const { t } = useTranslation();

  const { data: productList, isLoading } = useProductGetList(pagination);
  const queryClient = useQueryClient();

  const patchProductStatus = async (values: IChangeProductStatusRequest) => {
    var body: IChangeProductStatusRequest = { ...values };

    const res = await editProductStatus.mutateAsync({
      ...body,
    });
    if (res.success) queryClient.invalidateQueries([FETCH_LIST_PRODUCT]);
  };

  const transformedData = productList?.data?.map((x) => ({
    ...x,
    key: `table_products_${x.externalId}`,
  }));

  return (
    <>
      <PageLoading loading={isLoading}>
        <Table
          columns={productCollums(t, currentCurrency)}
          data={transformedData ? transformedData : []}
          headerOptions={{
            nameKey: 'descricao',
            hasSearch: true,
            searchPlaceholder: t('products.productList.searchPlaceholder'),
            newButton: {
              label: t('products.productList.newButton'),
              onClick: () => history.push('/inventory/products/create'),
              permission: {
                permission: 3003,
                tooltip: 'noPermission.supply.products.create',
              },
            },
            deleteButton: {
              permission: {
                permission: 3007,
                tooltip: 'noPermission.supply.products.delete',
              },
            },
            editButton: {
              permission: {
                permission: 3006,
                tooltip: 'noPermission.supply.products.statusEdit',
              },
              options: [
                {
                  label: t('common.status'),
                  isStatusOnly: true,
                },
              ],
            },
          }}
          selectedRowsOnTable={(x) => setSelectedRowsOnTable(x)}
          rowsOptions={{
            onRowDoubleClick: (x) =>
              history.push(`/inventory/products/${x.externalId}/details`),
            permission: {
              permission: 3002,
              tooltip: 'noPermission.supply.products.detail',
            },
          }}
          editOptions={editTableStatus()}
          loading={isLoading}
          hasSelection={true}
          fieldStatus="ativo"
          pagination={
            productList
              ? {
                  Count: productList.count,
                  PageIndex: productList.pageIndex,
                  PageSize: productList.pageSize,
                }
              : false
          }
          onEdit={(user, values) =>
            patchProductStatus({
              produtosExternalId: user.map((x) => x.externalId),
              ativo: values.status === 'success',
            })
          }
          onRefetch={(pagination) => {
            setPagination(pagination);
          }}
          editModalLoading={editProductStatus.isLoading}
          deleteModal={
            selectedRowsOnTable && {
              title:
                selectedRowsOnTable.length === 1
                  ? `${t('common.delete')} ${t('common.product')} "${
                      selectedRowsOnTable[0]?.descricao
                    }"?`
                  : `${t('modal.delete')} ${selectedRowsOnTable?.length} ${t(
                      'common.products'
                    )}?`,
              body:
                selectedRowsOnTable.length === 1 ? (
                  <DeleteModal selectedRowsOnTable={selectedRowsOnTable} />
                ) : (
                  DeleteModalOnTable(
                    selectedRowsOnTable,
                    `${t('modal.deleteModal.many.title')} ${t(
                      'common.theProducts'
                    )}:`,
                    'descricao',
                    t('common.products')
                  )
                ),
              buttons: {
                okButtonColor: 'status-danger-base',
              },
            }
          }
          onDelete={(product) =>
            product.map(async (x) => {
              const res = await deleteProduct.mutateAsync(
                {
                  produtosExternalId: product.map((x) => x?.externalId),
                },
                {
                  onError: handleModalError(app),
                }
              );
              if (res.success)
                queryClient.invalidateQueries([FETCH_LIST_PRODUCT]);
            })
          }
        />
      </PageLoading>
    </>
  );
};
