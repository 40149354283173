import { Col, Row } from 'antd';
import { useFormikContext } from 'formik';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { Card, TextInput, Tooltip } from '../../../../../../Components';
import { CountryDropdown } from '../../../../../../Components/Inputs/CountryDropdown';
import { useCountryGetList } from '../../../../../../Hooks/Supply/CountryHooks';
import { useLotGet } from '../../../../../../Hooks/Supply/LotHooks';
import { TechnicalInfoNotRawMaterialCard } from '../../../CreateForm/CardsForm/TechnicalInfoCard';
import { TechnicalInfoDiluitionsRows } from './diluitions';
import { useAlternativeUnitMeasurementDropdown } from '../../../../../../Hooks/Supply/UnitMeasurementHooks';
import { TextInputNumber } from '../../../../../../Components/Inputs/TextInputNumber';

interface IDetailsForm {
  disabled: boolean;
}

export const TechnicalInfoCard: FC<IDetailsForm> = ({ disabled }) => {
  const { externalId } = useParams<{ externalId: string }>();
  const { t } = useTranslation();
  const { data: lots } = useLotGet(externalId);
  const form: any = useFormikContext();
  const technicalInfos = lots?.loteInformacaoTecnica;

  const { data: paisOrigemList } = useCountryGetList({
    PageIndex: 0,
    PageSize: 260,
  });

  const { data: unidadeAlternativaList } =
    useAlternativeUnitMeasurementDropdown({
      PageIndex: 0,
      PageSize: 10,
    });

  return form?.values?.classe === 1 || !form?.values?.classe ? (
    <Card
      title={t('supply.lot.details.details.technicalInformation')}
      withoutCollapse={true}
    >
      <Row gutter={[16, 0]}>
        <Col span={5}>
          <Tooltip
            title={`${t(
              'supply.lot.details.toolTips.originalCountryOfOrigin'
            )} ${lots?.loteInformacaoTecnica?.descricaoPais}`}
            showMe={
              lots?.loteInformacaoTecnica?.paisOrigemExternalId !==
                form?.values?.loteInformacaoTecnica?.paisOrigemExternalId &&
              !disabled
            }
          >
            <CountryDropdown
              name="loteInformacaoTecnica.paisOrigemExternalId"
              items={paisOrigemList?.data?.map((x: any) => ({
                id: x.externalId,
                name: x.descricao,
                flag: x.abreviacao,
              }))}
              label={t('supply.lot.create.countryOfOrigin')}
              disabled={disabled}
              withStatusIcon
              required
            />
          </Tooltip>
        </Col>
        <Col span={3}>
          <Tooltip
            title={`${t('supply.lot.details.toolTips.originalDensity')} ${
              !!technicalInfos ? lots?.loteInformacaoTecnica?.densidade : 0
            }`}
            showMe={
              !disabled &&
              lots?.loteInformacaoTecnica?.densidade !==
                form?.values?.loteInformacaoTecnica?.densidade
            }
          >
            <TextInput
              name="loteInformacaoTecnica.densidade"
              label={t('supply.lot.details.details.density')}
              placeHolder={t('supply.lot.details.details.density')}
              disabled={disabled}
              withStatusIcon
              type="number"
            />
          </Tooltip>
        </Col>
      </Row>

      <TechnicalInfoDiluitionsRows disabled={disabled} />

      <div style={{ borderTop: '1px solid var(--color-background-04)' }}>
        <Row gutter={[16, 0]} style={{ marginTop: '20px' }}>
          <Col span={6}>
            <Tooltip
              title={`${t(
                'supply.lot.details.toolTips.originalAlternateUnit'
              )} ${
                lots?.loteInformacaoTecnica?.loteUnidadeAlternativa1
                  ?.quantidadeUnidadeAlternativa
              } ${
                lots?.loteInformacaoTecnica?.loteUnidadeAlternativa1
                  ?.quantidadeUnidadeAlternativa
              }`}
              showMe={
                lots?.loteInformacaoTecnica?.loteUnidadeAlternativa1
                  ?.quantidadeUnidadeAlternativa !==
                  form?.values?.loteInformacaoTecnica?.loteUnidadeAlternativa1
                    .quantidadeUnidadeAlternativa && !disabled
              }
            >
              <TextInputNumber
                name="loteInformacaoTecnica.loteUnidadeAlternativa1.quantidadeUnidadeAlternativa"
                label={t('supply.lot.details.details.alternativeUnitOne')}
                placeHolder={t('supply.lot.details.details.alternativeUnitOne')}
                disabled={disabled}
                dropdownLeft={{
                  name: 'loteInformacaoTecnica.loteUnidadeAlternativa1.unidadeAlternativaId',
                  options:
                    unidadeAlternativaList?.data.map((x) => ({
                      id: x.id,
                      titleString: x.unidadeAbreviacao,
                    })) || [],
                }}
                rightIcon={{
                  titleString: '/g',
                  id: lots?.loteInformacaoTecnica.loteUnidadeAlternativa1
                    .unidadeAlternativaConversaoId,
                }}
                withStatusIcon
              />
            </Tooltip>
          </Col>
          <Col span={6}>
            <Tooltip
              title={`${t(
                'supply.lot.details.toolTips.originalAlternateUnit2'
              )} ${
                lots?.loteInformacaoTecnica?.loteUnidadeAlternativa2
                  ?.quantidadeUnidadeAlternativa
              }`}
              showMe={
                lots?.loteInformacaoTecnica?.loteUnidadeAlternativa2
                  ?.quantidadeUnidadeAlternativa !==
                  form?.values?.loteInformacaoTecnica?.loteUnidadeAlternativa2
                    ?.quantidadeUnidadeAlternativa && !disabled
              }
            >
              <TextInputNumber
                name="loteInformacaoTecnica.loteUnidadeAlternativa2.quantidadeUnidadeAlternativa"
                label={t('supply.lot.details.details.alternativeUnitTwo')}
                placeHolder={t('supply.lot.details.details.alternativeUnitTwo')}
                disabled={disabled}
                dropdownLeft={{
                  name: 'loteInformacaoTecnica.loteUnidadeAlternativa2.unidadeAlternativaId',
                  options:
                    unidadeAlternativaList?.data.map((x) => ({
                      id: x.id,
                      titleString: x.unidadeAbreviacao,
                    })) || [],
                }}
                rightIcon={{
                  titleString: '/g',
                  id: lots?.loteInformacaoTecnica.loteUnidadeAlternativa2
                    .unidadeAlternativaConversaoId,
                }}
                withStatusIcon
              />
            </Tooltip>
          </Col>
        </Row>
      </div>
    </Card>
  ) : (
    <TechnicalInfoNotRawMaterialCard />
  );
};
