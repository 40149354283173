import { IStorageLocationState } from './interface/IStorageLocation';
import {
  StorageLocationActionKeys,
  StorageLocationActionUnion,
} from './StorageLocation.action';

const initialData: IStorageLocationState = {
  isLoading: false,
};

export const StorageLocationReducer = (
  state = initialData,
  action: StorageLocationActionUnion
): IStorageLocationState => {
  switch (action.type) {
    case StorageLocationActionKeys.SET_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };

    case StorageLocationActionKeys.STORAGE_LOCATION_LIST_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: undefined,
      };
    case StorageLocationActionKeys.STORAGE_LOCATION_LIST_ACCOUNTMANAGER_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: undefined,
      };
    case StorageLocationActionKeys.STORAGE_LOCATION_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: undefined,
        currentStorageLocationList: action.payload,
      };

    case StorageLocationActionKeys.CREATE_STORAGE_LOCATION_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: undefined,
      };
    case StorageLocationActionKeys.CREATE_STORAGE_LOCATION_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    case StorageLocationActionKeys.GET_STORAGE_LOCATION_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: undefined,
      };
    case StorageLocationActionKeys.GET_STORAGE_LOCATION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: undefined,
        currentStorageLocation: action.payload,
      };
    case StorageLocationActionKeys.GET_STORAGE_LOCATION_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    case StorageLocationActionKeys.PATCH_STATUS_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: undefined,
      };
    case StorageLocationActionKeys.PATCH_STATUS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case StorageLocationActionKeys.PATCH_STATUS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: undefined,
        currentStorageLocation: {
          ...state.currentStorageLocation,
          ativo: action.payload.ativo,
        } as any,
      };

    case StorageLocationActionKeys.EDIT_STORAGE_LOCATION_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: undefined,
      };
    case StorageLocationActionKeys.EDIT_STORAGE_LOCATION_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    case StorageLocationActionKeys.THERE_IS_STORAGE_LOCATION_NAME_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: undefined,
      };
    case StorageLocationActionKeys.THERE_IS_STORAGE_LOCATION_NAME_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: undefined,
        thereIsStorageLocationName: action.payload,
      };
    case StorageLocationActionKeys.STORAGE_LOCATION_DROPDOWN_LIST_REQUEST: 
      return {
        ...state,
        isLoading: true,
        error: undefined,
      }
    case StorageLocationActionKeys.STORAGE_LOCATION_DROPDOWN_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        currentDropDownListStorageLocation: action.payload,
      }
    default:
      return state;
  }
};

export default StorageLocationReducer;
