import { Popover } from 'antd';
import React, { FC, ReactNode } from 'react';
import type { ActionType } from '@rc-component/trigger/lib/interface';

import './PopOver.override.scss';

export interface IPopOver {
  placement:
    | 'top'
    | 'left'
    | 'right'
    | 'bottom'
    | 'topLeft'
    | 'topRight'
    | 'bottomLeft'
    | 'bottomRight'
    | 'leftTop'
    | 'leftBottom'
    | 'rightTop'
    | 'rightBottom'
    | undefined;
  title?: any;
  content: ReactNode;
  trigger: ActionType | ActionType[] | undefined;
  className?: string;
  children: ReactNode;
  visible: boolean;
  onOpenChange: () => void;
}

export const PopOver: FC<IPopOver> = ({
  placement,
  title,
  content,
  trigger,
  className,
  children,
  visible,
  onOpenChange,
}) => {
  return (
    <Popover
      open={visible}
      onOpenChange={onOpenChange}
      className={className}
      placement={placement}
      title={title}
      content={content}
      trigger={trigger}
    >
      {children}
    </Popover>
  );
};
