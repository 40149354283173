import { call, put } from 'redux-saga/effects';
import { messageErrorToast } from '../../Utils/MessageErrorToast';
import { Http } from '../../Utils/Http';
import {
  ListStockBalanceRequestAction,
  ListStockManyBalanceRequestAction,
  StockBalanceActions,
} from './StockBalance.actions';
import { IStockBalanceListData } from '../../Data/Interfaces/response/StockBalance/IStockBalanceResponse';
import { StockBalanceAPI } from '../../Data/API/Supply/StockBalanceApi';

export function* listStockBalance({ payload }: ListStockBalanceRequestAction) {
  try {
    const { data } = yield call(StockBalanceAPI.getStockBalanceList, payload);

    const transformedData = Http.TransformODataData<IStockBalanceListData>(
      data,
      payload.pagination
    );

    yield put(StockBalanceActions.getListStockBalanceSuccess(transformedData));
  } catch (error: any) {
    messageErrorToast(error);

    yield put(StockBalanceActions.getListStockBalanceFailure(error.message));
  }
}

export function* listManyStockBalance({
  payload,
}: ListStockManyBalanceRequestAction) {
  try {
    const { data } = yield call(
      StockBalanceAPI.getStockBalanceList,
      payload.query
    );

    const transformedData = Http.TransformODataData<IStockBalanceListData>(
      data,
      payload.query.pagination
    );

    yield put(
      StockBalanceActions.getListManyStockBalanceSuccess({
        placesIds: payload.placesIds,
        productId: payload.productId,
        response: transformedData,
      })
    );
  } catch (error: any) {
    messageErrorToast(error);

    yield put(
      StockBalanceActions.getListManyStockBalanceFailure(error.message)
    );
  }
}
