import { IConglomerateState } from './interfaces/IConglomerateState';
import {
  ConglomerateActionUnion,
  ConglomerateActionKeys,
} from './Conglomerate.actions';
import { IGetCompanyForConglomerateResponse } from '../../Data/Interfaces/response/Conglomerate/IConglomerateResponde';

const initialData: IConglomerateState = {
  isLoading: false,
  isStatusConfirmModalVisible: false,
};

export const conglomerateReducer = (
  state = initialData,
  action: ConglomerateActionUnion
): IConglomerateState => {
  switch (action.type) {
    case ConglomerateActionKeys.SET_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };

    case ConglomerateActionKeys.RESET_CONGLOMERATE:
      return {
        ...state,
        currentConglomerate: undefined,
      };

    case ConglomerateActionKeys.RESET_CONGLOMERATE_INCREMENTING:
      return {
        ...state,
        currentCompanyForConglomerateIncremented: undefined,
      };

    case ConglomerateActionKeys.PARAMS_CONGLOMERATE_ID:
      return {
        ...state,
        currentConglomerateParamsId: action.payload,
      };

    case ConglomerateActionKeys.LIST_CONGLOMERATE_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: undefined,
      };
    case ConglomerateActionKeys.LIST_CONGLOMERATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: undefined,
        currentListConglomerate: action.payload,
      };
    case ConglomerateActionKeys.LIST_CONGLOMERATE_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    case ConglomerateActionKeys.ALL_LIST_CONGLOMERATE_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: undefined,
      };
    case ConglomerateActionKeys.ALL_LIST_CONGLOMERATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: undefined,
        currentListAllConglomerate: action.payload,
      };
    case ConglomerateActionKeys.ALL_LIST_CONGLOMERATE_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    case ConglomerateActionKeys.GET_CONGLOMERATE_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: undefined,
      };
    case ConglomerateActionKeys.GET_CONGLOMERATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: undefined,
        currentConglomerate: action.payload,
      };
    case ConglomerateActionKeys.GET_CONGLOMERATE_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        currentConglomerate: undefined,
      };

    case ConglomerateActionKeys.GET_COMPANY_FOR_CONGLOMERATE_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: undefined,
      };
    case ConglomerateActionKeys.GET_COMPANY_FOR_CONGLOMERATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: undefined,
        currentCompanyForConglomerate: action.payload,
      };
    case ConglomerateActionKeys.GET_COMPANY_FOR_CONGLOMERATE_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        currentConglomerate: undefined,
      };

    case ConglomerateActionKeys.GET_COMPANY_FOR_CONGLOMERATE_REQUEST_INCREMENTING:
      return {
        ...state,
        isLoading: true,
        error: undefined,
        currentCompanyForConglomerateIncremented: {
          ...(state.currentCompanyForConglomerateIncremented as IGetCompanyForConglomerateResponse),
          pageIndex: action.payload.pagination?.PageIndex || 0,
          pageSize: action.payload.pagination?.PageSize || 0,
        },
      };
    case ConglomerateActionKeys.GET_COMPANY_FOR_CONGLOMERATE_SUCCESS_INCREMENTING:
      return {
        ...state,
        isLoading: false,
        error: undefined,
        currentCompanyForConglomerateIncremented: {
          ...action.payload,
          data: [
            ...(state.currentCompanyForConglomerateIncremented?.data || []),
            ...action.payload.data,
          ],
        },
      };
    case ConglomerateActionKeys.GET_COMPANY_FOR_CONGLOMERATE_FAILURE_INCREMENTING:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        currentCompanyForConglomerateIncremented: undefined,
      };

    case ConglomerateActionKeys.PUT_CONGLOMERATE_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: undefined,
      };
    case ConglomerateActionKeys.PUT_CONGLOMERATE_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    case ConglomerateActionKeys.DELETE_CONGLOMERATE_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: undefined,
      };
    case ConglomerateActionKeys.DELETE_CONGLOMERATE_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    case ConglomerateActionKeys.PATCH_STATUS_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: undefined,
      };
    case ConglomerateActionKeys.PATCH_STATUS_SUCCESS:
      return {
        ...state,
        isLoading: true,
        error: undefined,
        currentConglomerate: {
          ...state.currentConglomerate,
          ativo: action.payload.ativa,
          usuariosExternalId: action.payload.conglomeradosExternalId,
        } as any,
      };
    case ConglomerateActionKeys.SET_CONFIRM_STATUS_MODAL_VISIBILITY:
      return {
        ...state,
        isStatusConfirmModalVisible: action.payload.visible,
        statusChangeNewValue: action.payload.value,
      };

    default:
      return state;
  }
};

export default conglomerateReducer;
