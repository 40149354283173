import { Collapse } from 'antd';
import React, { FC } from 'react';
import { Row } from './Row';
import { Content, IContentIcon } from './Content';

import styles from './CollapseList.module.scss';
import './CollapseList.override.scss';

const { Panel } = Collapse;

export interface IRow {
  name?: string;
  displayName: string;
  isCollapse?: boolean;
  children?: IRow[];
  inheritedFrom: string[];
  active?: boolean;
  inherited?: boolean;
  icon?: IContentIcon;
  permissionId?: number;
  hasInherited?: boolean;
  totalPermissions?: number;
  activePermissions?: number;
}

export interface ICollapseList {
  rows: IRow[];
  onChange?: (
    permission: { id: string; name: string },
    prevValue: boolean | 'inherited' | 'not-inherited',
    newValue: boolean | 'inherited' | 'not-inherited'
  ) => void;
}

const getFormState = (row: IRow) => {
  let result: any = {};

  if (row.children) {
    const children = row.children
      .map((x) => getFormState(x))
      .reduce((a, b) => ({ ...a, ...b }), {});
    result = { ...result, ...children };
  }

  if (row.name) {
    if (row.inherited) {
      if (row.active) result[row.name] = 'inherited';
      else result[row.name] = 'not-inherited';
    } else {
      result[row.name] = row.active;
    }
  }

  return result;
};

export const getPermissionInitialValues = (rows: IRow[]) => {
  return rows
    .map((x) => getFormState(x))
    .reduce((a, b) => ({ ...a, ...b }), {});
};

export const CollapseList: FC<ICollapseList> = ({ rows, onChange }) => {
  return (
    <>
      {rows.map((row) =>
        row.isCollapse ? (
          <Collapse
            className={`collapse ${styles['CollapseList']}`}
            defaultActiveKey={['1']}
            ghost
            key={row.permissionId}
          >
            <Panel header={<Content {...row} />} key="1" showArrow={false}>
              <CollapseList rows={row.children || []} onChange={onChange} />
            </Panel>
          </Collapse>
        ) : (
          <Row key={row.permissionId?.toString()}>
            <Content {...row} onChange={onChange} />
          </Row>
        )
      )}
    </>
  );
};
