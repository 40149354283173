export const sideMenuEN = {
  administrative: 'Administrative',
  administrativeItems: {
    users: 'Users',
    userGroups: 'User Groups',
    company: 'Companys',
    Audit: 'Audit',
  },
  adminBootys: 'Admin Bootys',
  adminBootysItems: {
    company: 'Companys',
    companyGroups: 'Company Groups',
    Audit: 'Audit',
  },
  inventory: 'Inventory',
  inventoryItems: {
    lots: 'Lots',
    stockLocations: 'Stock Locations',
    transfers: 'Transfers',
    losses: 'Losses',
    balanceAdjustment: 'Balance Adjustment',
    movimentation: 'Movimentation',
  },
  products: 'Products',
  productsItems: {
    products: 'Products',
    group: 'Group',
    subGroup: 'SubGroups',
  },
  production: 'Production',
  productionItems: {
    pharmaceuticalForms: 'Pharmaceutical Forms',
    formulas: 'Formulas',
  },
  purchases: 'Purchases',
  purchasesItems: {
    purchaseRequests: 'Purchase Requests',
    providers: 'Providers',
    entryInvoices: 'Entry Invoices',
  },
  sales: 'Sales',
  financial: 'Financial',
  reports: 'Reports',
};

export const sideMenuPT = {
  administrative: 'Administrativo',
  administrativeItems: {
    users: 'Usuários',
    userGroups: 'Grupos de Usuários',
    company: 'Empresas',
    Audit: 'Auditoria',
  },
  adminBootys: 'Admin Bootys',
  adminBootysItems: {
    company: 'Empresas',
    companyGroups: 'Grupos de Empresas',
    Audit: 'Auditoria',
  },
  inventory: 'Estoque',
  inventoryItems: {
    lots: 'Lotes',
    stockLocations: 'Locais de Estoque',
    transfers: 'Transferências',
    losses: 'Perdas',
    balanceAdjustment: 'Ajuste de Saldo',
    movimentation: 'Movimentações',
  },
  products: 'Produtos',
  productsItems: {
    products: 'Produtos',
    group: 'Grupos',
    subGroup: 'Subgrupos',
  },
  production: 'Produção',
  productionItems: {
    pharmaceuticalForms: 'Formas Farmacêuticas',
    formulas: 'Fórmulas',
  },
  purchases: 'Compras',
  purchasesItems: {
    purchaseRequests: 'Pedidos de Compra',
    providers: 'Fornecedores',
    entryInvoices: 'Notas Fiscais de Entrada',
  },
  sales: 'Vendas',
  financial: 'Financeiro',
  reports: 'Relatórios',
};
