import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IPagination, Table } from '../../../../../Components/Table';
import { ContainerPage } from '../../../../../Layouts/ContainerPage';
import { stockLocationColumns } from './stockLocation.columns';
import {
  useLotGet,
  useStockLocationsOfLotsList,
} from '../../../../../Hooks/Supply/LotHooks';
import { useParams } from 'react-router';
import { PageLoading } from '../../../../../Layouts/PageLoading';
import { IQueryListParams } from '../../../../../Utils/Http';

export const StockLocationsList: FC = () => {
  const { t } = useTranslation();
  const { externalId } = useParams<{ externalId: string }>();
  const { data: lot } = useLotGet(externalId);
  const [pagination, setPagination] = useState<IQueryListParams>({
    PageIndex: 0,
    PageSize: 10,
    Sorter: { column: 'localEstoqueDescricao', direction: 'ASC' },
    filter: [
      { filterName: 'produtoExternalId', externalId: lot?.produtoExternalId },
      { filterName: 'loteExternalId', externalId: lot?.externalId },
    ],
  });

  const { data: stockLocationList, isLoading: isLoadingList } =
    useStockLocationsOfLotsList(pagination);

  return (
    <PageLoading loading={isLoadingList}>
      <ContainerPage>
        <Table
          columns={stockLocationColumns(t)}
          data={
            stockLocationList?.data?.map((x: any) => ({
              ...x,
              key: x.sequenciaGroupTenant,
            })) || []
          }
          hasSelection={false}
          headerOptions={{
            hasSearch: true,
            searchPlaceholder: t(
              'supply.lot.details.stockLocation.searchPlaceholder'
            ),
          }}
          pagination={
            stockLocationList
              ? {
                  Count: stockLocationList.count,
                  PageIndex: stockLocationList.pageIndex,
                  PageSize: stockLocationList.pageSize,
                }
              : false
          }
          onRefetch={(pagination) => {
            setPagination(pagination);
          }}
          loading={isLoadingList}
        />
      </ContainerPage>
    </PageLoading>
  );
};

export default StockLocationsList;
