import React, { FC, useCallback, useState } from 'react';
import { Select, Spin } from 'antd';
import { Field } from 'formik';
import { IField } from '../interfaces/IField';
import { InputLabel } from '../Label';
import { LocalizaPropriedadePorPath } from '../../../Utils/FormikUtils';

import styles from './Dropdown.module.scss';
import './Dropdown.override.scss';
import { Icon, Text, Tooltip } from '../..';
import { useTranslation } from 'react-i18next';
import Spinner from '../../Spinner';
import { TextType } from '../../Text';
import { Color } from '../../../Utils/ColorUtils';
import { getScrollPercentage } from '../../../Utils/ScrollUtils';
import { Divflex } from '../../../Layouts/DivWhithFlex';
import { IconName } from '../../Icon/IconClasses';
import flags from 'react-phone-number-input/flags';

export interface IInputLabel {
  children: string;
  typeLabel?: TextType;
  colorLabel?: Color;
}

interface IItems {
  id: any;
  name?: string;
  flag?: string;
}

export interface IDropdownCountry {
  items: IItems[] | undefined;
  name: string;
  label?: IInputLabel[] | string;
  placeHolder?: string;

  onFocus?: (event: React.FocusEvent<HTMLElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLElement>) => void;
  onChange?: (value: string) => void;
  onScrollEnd?: () => void;

  className?: string;

  withoutMarginBottom?: boolean;

  withTooltip?: {
    title: string;
    icon: IconName;
  };

  required?: boolean;
  autoIncrementBy?: string;

  width?: string;
  disabled?: boolean;
  isLoading?: boolean | undefined;

  withStatusIcon?: boolean;
  value?: string;
}

const { Option } = Select;

export const CountryDropdown: FC<IDropdownCountry> = ({
  items,
  name,
  label,
  withoutMarginBottom,
  withTooltip,
  required,
  width,
  placeHolder,
  isLoading,
  disabled,
  onChange,
  onScrollEnd,
  withStatusIcon,
  value,
}) => {
  const [open, setOpen] = useState(false);

  const { t } = useTranslation();

  const filtrarDados = (input: any, option: any): boolean => {
    return option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  };

  const onScroll = useCallback(
    (event: React.UIEvent<HTMLElement>) => {
      const scrollPercentage = getScrollPercentage(event);
      const scrollPercentageThreshold = 80;

      // Call hook when you scroll to 80% or more
      if (scrollPercentage > scrollPercentageThreshold && !isLoading) {
        onScrollEnd && onScrollEnd();
      }
    },
    [onScrollEnd, isLoading]
  );

  return (
    <div className={styles['Dropdown']}>
      <Field name={name}>
        {(props: IField) => {
          const onChangeFunc = (value: string, option: any) => {
            props.form.setFieldValue(name, value);
            if (onChange) onChange(value);
          };

          const onMouseLeave = (event: any) => {
            if (
              event.target &&
              event.target.classList.contains('ant-select-item-option')
            ) {
              setOpen(false);
            }
          };

          return (
            <>
              <Divflex>
                <InputLabel
                  label={label}
                  required={required}
                  withStatusIcon={withStatusIcon}
                  props={props}
                />
                {withTooltip && (
                  <Tooltip
                    title={withTooltip.title}
                    showMe
                    children={
                      <Icon
                        className={styles['icon-with-tooltip']}
                        icon={withTooltip.icon}
                        color="text-300"
                        size="S"
                      />
                    }
                  />
                )}
              </Divflex>
              <div style={{ display: 'flex' }}>
                <div
                  style={{
                    maxWidth: width,
                    minWidth: width,
                  }}
                  className={` ${styles['divDropdown']} ${
                    withoutMarginBottom ? '' : styles['margin-bottom']
                  }`}
                >
                  <Select
                    onPopupScroll={onScroll}
                    disabled={disabled || props.form.isSubmitting}
                    value={props.field.value || value}
                    style={{ width: width || 200 }}
                    placeholder={placeHolder || t('dropDown.placeHolder')}
                    open={open}
                    onChange={onChangeFunc}
                    onKeyUp={() => setOpen(true)}
                    onClick={() => setOpen(!open)}
                    onBlur={(e) => {
                      setOpen(false);
                      props.field.onBlur(e);
                    }}
                    onSelect={() => setOpen(false)}
                    onMouseLeave={onMouseLeave}
                    filterOption={filtrarDados}
                    className={`bootys-dropdown ${
                      isLoading ? 'dropdown-is-loading' : ''
                    }`}
                    allowClear={true}
                    suffixIcon={
                      <Icon icon="chevron-down" color="text-50" size="M" />
                    }
                    notFoundContent={
                      isLoading ? <Spin tip="Buscando..."></Spin> : undefined
                    }
                    getPopupContainer={(trigger) => trigger.parentElement}
                  >
                    {!isLoading && items && items.length === 0 ? (
                      <Text
                        className={'dropdown-empty'}
                        type="small-text"
                        color="text-400"
                        children={t('dropDown.empty')}
                      />
                    ) : (
                      items?.map(
                        (x, index) =>
                          x.flag && (
                            <Option
                              className={`${styles['option']} ${styles['option-avatar']}`}
                              key={`dropdown-${index}`}
                              value={x.id}
                            >
                              <div className={`${styles['divContent']}`}>
                                <Flags flagCode={x.flag} />
                                <div className={styles['div-avatar']}>
                                  <Text
                                    type="ui-sub-content"
                                    color="text-50"
                                    children={`${x.name} `}
                                  />
                                </div>
                              </div>
                            </Option>
                          )
                      )
                    )}
                  </Select>
                  {isLoading && (
                    <div className={styles['spinner']}>
                      <Spinner />
                    </div>
                  )}

                  {props.form &&
                  props.form.errors &&
                  LocalizaPropriedadePorPath(props.form.errors, name) ? (
                    <p className={styles['errorText']}>
                      {LocalizaPropriedadePorPath(props.form.errors, name)}
                    </p>
                  ) : null}
                </div>
              </div>
            </>
          );
        }}
      </Field>
    </div>
  );
};

interface IFlags {
  flagCode: string;
}

const Flags: FC<IFlags> = ({ flagCode }) => {
  const flag = flags as any;
  const Flag = flag[flagCode];
  return (
    <Flag
      className={`${styles['flag']}`}
      image={<div key={flagCode}>{flagCode}</div>}
    />
  );
};
