import { useQueryClient } from '@tanstack/react-query';
import { FormikHelpers, FormikValues, useFormikContext } from 'formik';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { Dropdown } from '../../../../../../Components';
import {
  patternLabelStyle,
  TextInput,
} from '../../../../../../Components/Inputs/TextInput';
import { Modal } from '../../../../../../Components/Modal';
import { ICreateSubGroupProductRequest } from '../../../../../../Data/Interfaces/request/Product/IProductRequest';
import {
  FETCH_LIST_SUBGROUPS_GROUP,
  useGroupGet,
  useGroupGetDropdown,
  useSubGroupCreate,
  useSubGroupEdit,
  useSubGroupValidationName,
} from '../../../../../../Hooks/Supply/ProductHooks';

interface IFormGroupModal {
  visible: boolean;
  changeVisible: (value: boolean) => void;
  group?: any;
  title?: string;
  selectedRowsOnTable?: any;
}
const abbreviatedI18n = 'products.subGroupList.modal';

export const CreateSubGroupModal: React.FC<IFormGroupModal> = ({
  visible,
  changeVisible,
  title,
  selectedRowsOnTable,
}) => {
  const { t } = useTranslation();

  const createSubGroup = useSubGroupCreate();
  const editSubGroup = useSubGroupEdit();

  const queryClient = useQueryClient();

  const { externalId } = useParams<{ externalId: string }>();
  const { data: productGroup } = useGroupGet(externalId);

  const onSubmit = async (
    values: ICreateSubGroupProductRequest,
    helpers: FormikHelpers<FormikValues>
  ) => {
    const body: ICreateSubGroupProductRequest = { ...values, helpers };
    if (title && selectedRowsOnTable) {
      const res = await editSubGroup.mutateAsync({
        subGrupoExternalId: selectedRowsOnTable[0]?.externalId,
        descricao: body.descricao,
        grupoPaiExternalId: body.grupoPaiExternalId,
        helpers,
      });
      if (res.success) {
        queryClient.invalidateQueries([FETCH_LIST_SUBGROUPS_GROUP]);
      }
    } else {
      const res = await createSubGroup.mutateAsync({
        descricao: body?.descricao,
        grupoPaiExternalId: body.grupoPaiExternalId,
        helpers,
      });
      if (res.success === false)
        queryClient.invalidateQueries([FETCH_LIST_SUBGROUPS_GROUP]);
    }
  };

  return (
    <Modal
      onOkClickForm={(values, formik) => onSubmit(values, formik)}
      title={title ? title : t(`${abbreviatedI18n}.title`)}
      body={<ModalBody />}
      visible={visible}
      onCancelClick={() => changeVisible(!visible)}
      htmlType="submit"
      okButtonName={title ? '' : t(`${abbreviatedI18n}.okButton`)}
      onClose={() => changeVisible(!visible)}
      initialValues={{
        grupoPaiExternalId: productGroup?.externalId,
        descricao: selectedRowsOnTable && selectedRowsOnTable[0]?.descricao,
      }}
      withForm
    />
  );
};

interface IModalBody {
  onDisabledButton?: (value: boolean) => void;
}

const ModalBody: React.FC<IModalBody> = ({ onDisabledButton }) => {
  const [groupExternalId, setGroupExternalId] = useState<string>();
  const form: any = useFormikContext();
  const { t } = useTranslation();
  const { data } = useGroupGetDropdown({ PageIndex: 0, PageSize: 20 });

  const onSubGroupValidationError = useCallback(() => {
    form.setErrors({
      ...form.errors,
      descricao: t('products.create.errors.errorSubGroupExist'),
    });
  }, [form, t]);

  const { mutateAsync } = useSubGroupValidationName({
    onError: onSubGroupValidationError,
  });

  useEffect(() => {
    onDisabledButton &&
      onDisabledButton(
        form?.values?.descricao && form?.values?.grupoPaiExternalId
      );
  }, [
    onDisabledButton,
    form?.values?.descricao,
    form?.values?.grupoPaiExternalId,
  ]);

  const fetchProductSubGroupValidationName = useMemo(
    () =>
      _.debounce(async (value?: string, groupExternalId?: string) => {
        return (
          groupExternalId &&
          value &&
          (await mutateAsync({
            descricao: value,
            grupoExternalId: groupExternalId,
          }))
        );
      }, 500),
    [mutateAsync]
  );

  return (
    <div style={{ width: '359px', padding: '20px' }}>
      <TextInput
        name="descricao"
        label={[
          {
            children: t(`${abbreviatedI18n}.subGroupLabel`),
            ...patternLabelStyle,
          },
        ]}
        placeHolder={t(`${abbreviatedI18n}.subGroupPlaceholder`)}
        type="text"
        onChange={(x) => {
          fetchProductSubGroupValidationName(x, form.values.grupoPaiExternalId);
        }}
      />

      <Dropdown
        label={[
          {
            children: t(`${abbreviatedI18n}.groupLabel`),
            ...patternLabelStyle,
          },
        ]}
        items={data?.data.map((x: any) => ({
          id: x.externalId,
          name: x.descricao,
        }))}
        name="grupoPaiExternalId"
        withoutMarginBottom
        disabled
        onChange={(x) => {
          setGroupExternalId(x);
          fetchProductSubGroupValidationName(x, groupExternalId);
        }}
      />
    </div>
  );
};
