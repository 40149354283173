import { Col, Row } from 'antd';
import { FieldArray } from 'formik';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { Card, Form, TextInput } from '../../../../../Components';
import { TextInputNumber } from '../../../../../Components/Inputs/TextInputNumber';
import { TextInputWithMask } from '../../../../../Components/Inputs/TextInputWithMask';
import { useTransferGet } from '../../../../../Hooks/Supply/TransferHooks';

export const TransferForm: FC = () => {
  const { externalId } = useParams<{ externalId: string }>();
  const { data: transfers } = useTransferGet(externalId);
  const { t } = useTranslation();

  return (
    <Form
      initialValues={{
        ...transfers,
        produtoResponse: transfers
          ? transfers?.transferenciaLoteItens?.map(
              (x) => x.produtoResponse.descricao
            )
          : '',

        loteResponse: transfers
          ? transfers?.transferenciaLoteItens?.map(
              (x) => `${x.loteResponse.numero} / NF: ${x.loteResponse.numeroNf}`
            )
          : '',

        movimentoEstoqueOrigem: transfers
          ? transfers?.transferenciaLoteItens?.map(
              (x) => x.movimentoEstoqueOrigem.saldoOrigem
            )
          : '',

        quantidadeTransferida: transfers
          ? transfers?.transferenciaLoteItens?.map(
              (x) => x.quantidadeTransferida
            )
          : '',

        movimentoEstoqueDestino: transfers
          ? transfers?.transferenciaLoteItens?.map(
              (x) => x.movimentoEstoqueDestino.saldoDestino
            )
          : '',

        unidadeMedidaEstoqueDestino: transfers
          ? transfers?.transferenciaLoteItens?.map(
              (x) => x.movimentoEstoqueDestino.unidadeMedidaDestino.abreviacao
            )
          : '',
      }}
    >
      {(props) => (
        <Card
          title={t('supply.transfers.detailsForm.titleTransfer')}
          withoutCollapse={true}
        >
          <FieldArray
            name="transferenciaLoteItens"
            render={(arrayHelpers) => (
              <div>
                {props.values.transferenciaLoteItens &&
                  props.values.transferenciaLoteItens.map(
                    (x: any, index: any) => <CardTransfers index={index} />
                  )}
              </div>
            )}
          />
        </Card>
      )}
    </Form>
  );
};

interface ICardTransfers {
  index: number;
}

const CardTransfers: FC<ICardTransfers> = ({ index }) => {
  const { t } = useTranslation();

  return (
    <Row gutter={[16, 0]}>
      <Col span={8}>
        <TextInput
          name={`produtoResponse.${index}`}
          label={
            index === 0 ? t('supply.transfers.detailsForm.product') : t('')
          }
        />
      </Col>
      <Col span={7}>
        <TextInputWithMask
          name={`loteResponse.${index}`}
          label={index === 0 ? t('supply.transfers.detailsForm.lot') : t('')}
        />
      </Col>
      <Col span={3}>
        <TextInputNumber
          name={`movimentoEstoqueOrigem.${index}`}
          label={
            index === 0
              ? t('supply.transfers.detailsForm.originBalance')
              : t('')
          }
          rightIcon={{ titleString: 'g', id: 1 }}
          withTooltip={
            index === 0
              ? {
                  icon: 'exclamation-upside-down',
                  title: t('supply.transfers.tooltips.originBalance'),
                }
              : {}
          }
        />
      </Col>
      <Col span={3}>
        <TextInputNumber
          name={`quantidadeTransferida.${index}`}
          label={
            index === 0
              ? t('supply.transfers.detailsForm.transferAmount')
              : t('')
          }
          rightIcon={{ titleString: 'g', id: 1 }}
        />
      </Col>
      <Col span={3}>
        <TextInputNumber
          name={`movimentoEstoqueDestino.${index}`}
          label={
            index === 0
              ? t('supply.transfers.detailsForm.destinationBalance')
              : t('')
          }
          rightIcon={{ titleString: 'g', id: 1 }}
          withTooltip={
            index === 0
              ? {
                  icon: 'exclamation-upside-down',
                  title: t('supply.transfers.tooltips.originBalance'),
                }
              : {}
          }
        />
      </Col>
    </Row>
  );
};
