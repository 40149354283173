import React, { useState } from 'react';
import { Table } from '../../../Components';
import { useTranslation } from 'react-i18next';
import { PageLoading } from '../../../Layouts/PageLoading';
import { ContainerPage } from '../../../Layouts/ContainerPage';
import { usePurchaseOrderList } from '../../../Hooks/Purchase/PurchaseOrderHooks';
import { IPagination } from '../../../Components/Table';
import { purchaseOrderColumn } from './purchaseOrder.columns';
import { useUserPreferencesType } from '../../../Hooks/Accountmanager/UserHooks';
import { getCurrentCurrency } from '../../../Store/Currency/Currency.selector';
import { useSelector } from 'react-redux';
import { history } from '../../../Store';

export const PurchaseList: React.FC = () => {
  const { t } = useTranslation();
  const { data: currentMeUser } = useUserPreferencesType();
  const currentCurrency = useSelector(getCurrentCurrency);

  const [pagination, setPagination] = useState<IPagination>({
    PageIndex: 0,
    PageSize: 10,
    Sorter: { column: 'sequenciaGroupTenant', direction: 'ASC' },
  });

  const { data: purchaseOrderList, isLoading: isLoadingList } =
    usePurchaseOrderList(pagination);

  return (
    <PageLoading loading={isLoadingList}>
      <ContainerPage>
        <Table
          columns={purchaseOrderColumn(t, currentMeUser, currentCurrency)}
          data={
            purchaseOrderList?.data?.map((purchaseRow: any) => ({
              ...purchaseRow,
              key: `table_purchase_${purchaseRow.externalId}`,
            })) || []
          }
          headerOptions={{
            hasSearch: true,
            searchPlaceholder: t(
              'purchasing.purchaseOrder.list.searchPlaceholder'
            ),
            newButton: {
              onClick: () => history.push('/order/purchase/create'),
              label: 'purchasing.purchaseOrder.newButton',
              permission: {
                permission: 2018,
                tooltip: 'noPermission.purchasing.purchase.create',
              },
            },
            editButton: {},
            deleteButton: {},
            nameKey: 'sequenciaGroupTenant',
          }}
          loading={isLoadingList}
          hasSelection={false}
          rowsOptions={{
            onRowDoubleClick: (x) =>
              history.push(`/order/purchase-order/${x.externalId}/details`),
          }}
          pagination={
            purchaseOrderList
              ? {
                  Count: purchaseOrderList.count,
                  PageIndex: purchaseOrderList.pageIndex,
                  PageSize: purchaseOrderList.pageSize,
                }
              : false
          }
          onRefetch={(pagination) => setPagination(pagination)}
        ></Table>
      </ContainerPage>
    </PageLoading>
  );
};

export default PurchaseList;
