import React from 'react';
import { Modal } from '../../../../../../Components/Modal';
import { useTranslation } from 'react-i18next';
import Arrow from '../../../../../../Static/img/Arrow.svg';
import { Status } from '../../../../../../Components';
import { useDispatch, useSelector } from 'react-redux';
import {
  getStatusChangeNewValue,
  isStatusConfirmModalVisible,
} from '../../../../../../Store/Conglomerate/Conglomerate.selector';
import { useRouteMatch } from 'react-router-dom';
import { ConglomerateActions } from '../../../../../../Store/Conglomerate/Conglomerate.actions';

import styles from './ConfirmStatusChange.module.scss';

interface IEditModal {
  groupName: string;
}

export const ChangePasswordModal: React.FC<IEditModal> = ({ groupName }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const match = useRouteMatch<{ externalId: string }>();
  const visible = useSelector(isStatusConfirmModalVisible);
  const newValue = useSelector(getStatusChangeNewValue);

  const onOk = () => {
    dispatch(
      ConglomerateActions.patchUserStatus({
        conglomeradosExternalId: [match.params.externalId],
        ativa: newValue,
      })
    );
  };

  const onCancel = () =>
    dispatch(
      ConglomerateActions.setConfirmStatusModalVisibility({
        visible: false,
        value: undefined,
      })
    );

  return (
    <Modal
      onOkClick={onOk}
      title={`${t(
        'adminBootys.editConglomerate.conflictModal.title'
      )} “${groupName}”?`}
      body={
        <div className={styles.content}>
          {t('adminBootys.editConglomerate.conflictModal.content')}{' '}
          <b>{groupName}</b>?
          <div className={styles.statusContainer}>
            {newValue && <Status type="disabled">Inativo</Status>}
            {!newValue && <Status type="success">Ativo</Status>}

            <img src={Arrow} className={styles.arrow} alt={Arrow} />

            {newValue && <Status type="success">Ativo</Status>}
            {!newValue && <Status type="disabled">Inativo</Status>}
          </div>
        </div>
      }
      visible={visible}
      onCancelClick={onCancel}
      okButtonName={t('adminBootys.editConglomerate.conflictModal.okButton')}
      onClose={onCancel}
    />
  );
};
