import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { HorizontalTabs } from '../../../../Components';
import { PageLoading } from '../../../../Layouts/PageLoading';
import { List as ProductList } from './ProductForm';
import { DeleteProductGroupModal } from './DeleteProductGroupModal';
import { DetailProductGroupForm } from './DetailsForm';
import { SubGroupForm } from './SubGroupForm';
import { useGroupGet } from '../../../../Hooks/Supply/ProductHooks';

export const EditProductGroupForm: React.FC = () => {
  const [modalDeleteProductGroup, setModalDeleteProductGroup] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const { externalId } = useParams<{ externalId: string }>();
  const { data, isLoading } = useGroupGet(externalId);

  const params: Readonly<Partial<{ externalId: string; tab: string }>> =
    useParams();

  const { t } = useTranslation();

  useEffect(() => {
    params.tab && setDisabled(true);
  }, [params.tab]);

  return (
    <PageLoading loading={isLoading}>
      <HorizontalTabs
        type="secondary"
        items={[
          {
            label: t('admin.editUserPage.tab.details'),
            key: 'details',
            children: (
              <DetailProductGroupForm
                disabled={disabled}
                toogleDisabled={(x: boolean) => setDisabled(x)}
              />
            ),
          },
          {
            label: t('products.common.subGroups'),
            key: 'subgroups',
            children: <SubGroupForm />,
          },
          {
            label: t('products.common.products'),
            key: 'products',
            children: <ProductList />,
          },
        ]}
        dropdownItens={[
          {
            label: t('admin.editUserPage.tab.buttonActions.delete'),
            icon: 'trash',
            permission: {
              permission: 3012,
              tooltip: 'noPermission.supply.groups.delete',
            },
            onClick: () => setModalDeleteProductGroup(true),
          },
        ]}
        dropdownDirection="bottomRight"
        dropdownTitle={t('admin.editUserPage.tab.buttonActions.title')}
        leftIcon="menu-ellipsis-horizontal"
        rigthButton={{
          ...(disabled &&
            params.tab === 'details' && {
              onClick: () => setDisabled(false),
              isVisible: disabled,
              codePermissionEdit: 3011,
              messageTooltipPermission: 'noPermission.supply.groups.edit',
            }),
        }}
      />
      {data?.externalId ? (
        <DeleteProductGroupModal
          productGroup={data}
          onCancelClick={() => setModalDeleteProductGroup(false)}
          visible={modalDeleteProductGroup}
        />
      ) : null}
    </PageLoading>
  );
};
