import React, { useState } from 'react';
import { CompanyResponsibleUserCard } from '..';
import { Form } from '../../../../Components';
import { IChangeResponsibilityUserRequest } from '../../../../Data/Interfaces/request/Company/ICompanyRequest';
import { IGetCompanyResponse } from '../../../../Data/Interfaces/response/Company/ICompanyResponse';
import { IQueryListParams } from '../../../../Utils/Http';
import { initialValues } from '../CompanyResponsibleUserCard.InitialValues';
import { CompanyResponsibleUserCardSchema } from '../CompanyResponsibleUserCard.schema';
import { useGetActiveUserListType } from '../../../../Hooks/Accountmanager/UserHooks';
import { useCompanyChangeResponsibleUserMutation } from '../../../../Hooks/Accountmanager/CompanyHooks';

interface ICompanyResponsibleUserCard {
  company: IGetCompanyResponse;
  radioCompanyGroup?: boolean;
  radioUser: boolean;
  onChangeRadioUser: (value: boolean) => void;
}

export const CompanyResponsibleUserCardWrapper: React.FC<ICompanyResponsibleUserCard> =
  ({ company, radioCompanyGroup, radioUser, onChangeRadioUser }) => {
    const [pagination, setPagination] = useState<IQueryListParams>({
      PageIndex: 0,
      PageSize: 10,
    });
    const { data: users } = useGetActiveUserListType(pagination);
    const { mutateAsync, isLoading } =
      useCompanyChangeResponsibleUserMutation();
    return (
      <Form
        onSubmit={(values: IChangeResponsibilityUserRequest) =>
          mutateAsync(values)
        }
        initialValues={company && initialValues(company)}
        schema={CompanyResponsibleUserCardSchema}
      >
        <CompanyResponsibleUserCard
          radioCompanyGroup={!!radioCompanyGroup}
          radioUser={radioUser}
          onChangeRadioUser={onChangeRadioUser}
          users={users}
          isLoading={isLoading}
          onScrollEndUserDropdown={() =>
            setPagination({
              ...pagination,
              PageIndex: pagination.PageIndex + 1,
            })
          }
          editForm
        />
      </Form>
    );
  };
