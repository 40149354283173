import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { PageLoading } from '../../../../Layouts/PageLoading';
import { useCompanyTypes } from '../../../../Hooks/Accountmanager/CompanyHooks';
import { CompanyDataCardWrapper } from '../../../../Layouts/CompanyFormLayout/CompanyDataCard';
import { CompanyAdressCard } from '../../../../Layouts/CompanyFormLayout/CompanyAdressCard';
import { CompanyResponsibleUserCardWrapper } from '../../../../Layouts/CompanyFormLayout/CompanyResponsibleUserCard/Edit';

export const Create: React.FC = () => {
  const [radioUser, setRadioUser] = useState(true);
  const { externalId } = useParams<{ externalId: string }>();
  const isEditForm = useMemo(() => !!externalId, [externalId]);

  const { data: company } = useCompanyTypes(externalId);

  return (
    <PageLoading>
      {company && <CompanyDataCardWrapper company={company} />}
      {company && <CompanyAdressCard editForm={isEditForm} company={company} />}
      {company && (
        <CompanyResponsibleUserCardWrapper
          company={company}
          radioUser={radioUser}
          onChangeRadioUser={setRadioUser}
        />
      )}
    </PageLoading>
  );
};
