import React, { FC } from 'react';
import CurrencyFormat from 'react-currency-format';
import StorageUtils from '../../Utils/StorageUtils';

export interface ICurrencyText {
  value: number;
  decimalScale?: number;
  suffix?: string;
  noPrefix?: boolean;
}

const currencySymbolTag = ['R$', '$'];

export const CurrencyText: FC<ICurrencyText> = ({
  value,
  suffix,
  noPrefix,
  decimalScale,
}) => {
  const preference = StorageUtils.getPreference();

  const currencySymbol = currencySymbolTag[preference?.tipoMoedaId];
  const thousandSeparator = preference.idioma === 'pt-BR' ? '.' : ',';
  const decimalSeparator = preference.idioma === 'pt-BR' ? ',' : '.';

  return (
    <CurrencyFormat
      value={value}
      displayType="text"
      decimalScale={decimalScale}
      fixedDecimalScale
      thousandSeparator={thousandSeparator}
      decimalSeparator={decimalSeparator}
      prefix={noPrefix ? undefined : `${currencySymbol} `}
      suffix={suffix}
    />
  );
};
