import { Col, Row } from 'antd';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  Card,
  Dropdown,
  Form,
  i18n,
  Icon,
  TextInput,
} from '../../../../../Components';
import { TextArea } from '../../../../../Components/Inputs/TextArea';
import { useTransferGet } from '../../../../../Hooks/Supply/TransferHooks';
import { getUsersPreferences } from '../../../../../Store/User/User.selector';
import { ConvertDate } from '../../../../../Utils/DateUtils';

export const InformationTransferForm: FC = () => {
  const userPreferences = useSelector(getUsersPreferences);
  const { externalId } = useParams<{ externalId: string }>();
  const { data: transfers } = useTransferGet(externalId);
  const { t } = useTranslation();

  return (
    <Form
      initialValues={{
        ...transfers,
        localDeEstoqueOrigem: transfers
          ? `${transfers?.localDeEstoqueOrigem?.localEstoqueOrigemDescricao} (${transfers?.localDeEstoqueOrigem?.empresaOrigemDescricao})`
          : '',
        localEstoqueDestino: transfers
          ? `${transfers?.localEstoqueDestino?.localEstoqueDestinoDescricao} (${transfers?.localEstoqueDestino?.empresaDestinoDescricao})`
          : '',
        responsavel: transfers ? transfers?.responsavel?.usuarioExternalId : '',
        sequenciaNumeroTransferencia: transfers
          ? transfers?.sequenciaNumeroTransferencia
          : '',
        dataTransferencia: transfers
          ? `${ConvertDate(
              transfers?.dataTransferencia,
              userPreferences?.padraoData
            )} ${t('common.dateBreakApart')} ${ConvertDate(
              transfers?.dataTransferencia,
              userPreferences?.padraoHora
            )}`
          : '',
      }}
    >
      {(props) => (
        <>
          <Card
            title={t('supply.transfers.detailsForm.titleInformation')}
            withoutCollapse={true}
          >
            <Row gutter={[16, 0]}>
              <Col span={3}>
                <TextInput
                  name="sequenciaNumeroTransferencia"
                  label={[
                    {
                      children: t('supply.transfers.detailsForm.transferId'),
                    },
                  ]}
                  rightIcon={{
                    icon: 'copy',
                    copyName: 'sequenciaNumeroTransferencia',
                    toolTipTitle: [
                      t('supply.lot.details.toolTips.copy'),
                      t('supply.lot.details.toolTips.copySuccess'),
                    ],
                  }}
                  disabled
                />
              </Col>
              <Col span={3.5}>
                <TextInput
                  name="dataTransferencia"
                  label={[
                    {
                      children: t(
                        'supply.transfers.detailsForm.dateOfTransfer'
                      ),
                    },
                  ]}
                  disabled
                />
              </Col>
              <Col span={7}>
                {transfers && (
                  <Dropdown
                    name="responsavel"
                    items={[
                      {
                        id: transfers?.responsavel?.usuarioExternalId,
                        avatar: {
                          name: transfers?.responsavel?.usuarioNome || '',
                          lastName: transfers.responsavel?.usuarioSobrenome,
                          email: '',
                        },
                        name: transfers?.responsavel?.usuarioNome,
                      },
                    ]}
                    label={[
                      {
                        children: i18n.t(
                          'supply.transfers.detailsForm.responsible'
                        ),
                      },
                    ]}
                    disabled
                  />
                )}
              </Col>
            </Row>

            <Row gutter={[68, 0]} style={{ position: 'relative' }}>
              <Col span={12}>
                <TextInput
                  name="localDeEstoqueOrigem"
                  label={[
                    {
                      children: t('supply.transfers.detailsForm.placeOfOrigin'),
                    },
                  ]}
                  disabled
                />
              </Col>

              <Icon
                icon={'Arrow-right'}
                color={'text-400'}
                size={'S'}
                style={{
                  position: 'absolute',
                  top: '28px',
                  left: 'calc(50% - 8px)',
                }}
              />

              <Col span={12}>
                <TextInput
                  name="localEstoqueDestino"
                  label={[
                    {
                      children: t('supply.transfers.detailsForm.destination'),
                    },
                  ]}
                  disabled
                />
              </Col>
            </Row>

            <Row justify="space-between">
              <Col span={24}>
                <TextArea
                  name="observacao"
                  label={t('supply.transfers.detailsForm.comments')}
                  maxLength={200}
                  rows={8}
                  disable
                />
              </Col>
            </Row>
          </Card>
        </>
      )}
    </Form>
  );
};
