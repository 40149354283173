import React from 'react';
import { IColumn } from '.';
import { Highlight } from '../Highlight';
import { IPermissionWrapper, PermissionWrapper } from '../PermissionWrapper';

export const AddHighlight = (
  columns: IColumn[],
  search?: string,
  selectedRows?: any[]
) => {
  return columns.map((col) => {
    if (!col.isHighlightable || !search) {
      return col;
    }

    if (col.render) {
      return {
        ...col,
        render: (text: string, record: any, index: number) => (
          <Highlight
            text={text}
            highlight={search}
            type="paragraph2"
            color="text-50"
          >
            {col.render && col.render(text, record, index, selectedRows)}
          </Highlight>
        ),
      };
    }

    col.render = (text) => {
      return (
        <Highlight
          text={text}
          highlight={search}
          type="paragraph2"
          color="text-50"
        />
      );
    };
    return col;
  });
};

export const AddAlphabeticalOrder = (columns: IColumn[]) => {
  columns.forEach((x, index) => {
    if (x.alphabeticalOrder)
      columns[index] = {
        ...columns[index],
        sorter: (a: any, b: any) =>
          a[columns[index].dataIndex].localeCompare(
            b[columns[index].dataIndex]
          ),
      };
  });
  return columns;
};

export const AddNumericalOrder = (columns: IColumn[]) => {
  columns.forEach((x, index) => {
    if (x.numericalOrder)
      columns[index] = {
        ...columns[index],
        sorter: (a: any, b: any) =>
          a[columns[index].dataIndex] - b[columns[index].dataIndex],
      };
  });

  return columns;
};

export const AddPermission = (
  columns: IColumn[],
  permission?: IPermissionWrapper,
  selectedRows?: any[]
): IColumn[] => {
  if (!permission) return columns;

  return columns.map((col) => {
    if (col.render) {
      return {
        ...col,
        render: (text: string, record: any, index: number) => (
          <PermissionWrapper {...permission}>
            {col.render && col.render(text, record, index, selectedRows)}
          </PermissionWrapper>
        ),
      };
    }

    return {
      ...col,
      render: (text: string) => (
        <PermissionWrapper {...permission}>
          <span>{text}</span>
        </PermissionWrapper>
      ),
    };
  });
};
