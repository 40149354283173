import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { DeleteUserModal } from './Modals/DeleteUserModal';
import { PermissionForm } from './Tabs/PermissionForm';
import { DetailForm } from './Tabs/DetailForm';
import { HorizontalTabs } from '../../../../Components';
import { useGetUserType } from '../../../../Hooks/Accountmanager/UserHooks';

export const EditUser: React.FC = () => {
  const [modalDeleteUser, setModalDeleteUser] = useState(false);

  const params: Readonly<Partial<{ externalId: string | undefined }>> =
    useParams();

  const { data: user } = useGetUserType(params.externalId);

  const { t } = useTranslation();

  return (
    <>
      <HorizontalTabs
        type="secondary"
        items={[
          {
            label: t('admin.editUserPage.tab.details'),
            key: 'details',
            children: <DetailForm />,
          },
          {
            label: t('admin.editUserPage.tab.permissions'),
            key: 'permissions',
            children: <PermissionForm />,
          },
        ]}
        dropdownItens={[
          {
            label: t('admin.editUserPage.tab.buttonActions.delete'),
            icon: 'trash',
            permission: {
              permission: 1008,
              tooltip: 'noPermission.administrative.users.delete',
            },
            onClick: () => setModalDeleteUser(true),
          },
        ]}
        dropdownDirection="bottomRight"
        dropdownTitle={t('admin.editUserPage.tab.buttonActions.title')}
        leftIcon="menu-ellipsis-horizontal"
      />
      {user?.externalId ? (
        <DeleteUserModal
          user={user}
          onCancelClick={() => setModalDeleteUser(false)}
          visible={modalDeleteUser}
        />
      ) : null}
    </>
  );
};
