import { UserGroupActionUnion, UserGroupActionKeys } from './Group.actions';
import { IGroupState } from './interfaces/IGroupState';
import { uniqBy } from 'lodash';
import { IModulo } from '../../Data/Interfaces/response/User/IGetUserReponse';

const initialData: IGroupState = {
  isLoading: false,
  formDisabled: true,
  showPermissionDependencyModal: false,
};

export const groupReducer = (
  state = initialData,
  action: UserGroupActionUnion
): IGroupState => {
  switch (action.type) {
    case UserGroupActionKeys.SET_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };

    case UserGroupActionKeys.LIST_USER_GROUP_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: undefined,
      };
    case UserGroupActionKeys.LIST_USER_GROUP_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: undefined,
        currentListUserGroup: action.payload,
      };
    case UserGroupActionKeys.LIST_USER_GROUP_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    case UserGroupActionKeys.LIST_USER_GROUP_SUCCESS_INCREMENTING:
      const uniqueResult = uniqBy(
        [
          ...(state.currentListUserGroupIncrementing?.data || []),
          ...action.payload.data,
        ],
        'ExternalId'
      );

      return {
        ...state,
        isLoading: false,
        error: undefined,
        currentListUserGroupIncrementing: {
          ...action.payload,
          data: uniqueResult,
        },
      };
    case UserGroupActionKeys.LIST_USER_GROUP_FAILURE_INCREMENTING:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    case UserGroupActionKeys.POST_USER_GROUP_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: undefined,
      };
    case UserGroupActionKeys.POST_USER_GROUP_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    case UserGroupActionKeys.PATCH_USER_GROUP_NAME_DESCRIPTION:
      return {
        ...state,
        isLoading: true,
      };

    case UserGroupActionKeys.DELETE_USER_GROUP_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: undefined,
      };
    case UserGroupActionKeys.DELETE_USER_GROUP_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    case UserGroupActionKeys.GET_GROUP_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case UserGroupActionKeys.GET_GROUP_SUCCESS:
      return {
        ...state,
        userGroup: action.payload,
        isLoading: false,
      };

    case UserGroupActionKeys.RESET_USERS_FROM_GROUP_REQUEST:
      return {
        ...state,
        allUsersFromGroup: undefined,
      };

    case UserGroupActionKeys.GET_ALL_USERS_FROM_GROUP_SUCCESS:
      return {
        ...state,
        allUsersFromGroup: {
          ...action.payload,
          data: action.payload.data.map((x) => ({
            ...x,
            key: x.ExternalId,
          })),
        },
        isLoading: false,
      };

    case UserGroupActionKeys.GET_GROUP_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case UserGroupActionKeys.FORM_DISABLED:
      return {
        ...state,
        formDisabled: action.payload,
      };

    case UserGroupActionKeys.ADD_USER_GROUP_REQUEST:
      return {
        ...state,
        isLoading: true,
        addedUser: undefined,
      };
    case UserGroupActionKeys.ADD_USER_GROUP_SUCCESS:
      return {
        ...state,
        isLoading: true,
        addedUser: true,
      };
    case UserGroupActionKeys.ADD_USER_GROUP_FAILURE:
      return {
        ...state,
        isLoading: true,
        error: action.payload,
        addedUser: false,
      };

    case UserGroupActionKeys.REMOVE_USER_GROUP_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case UserGroupActionKeys.REMOVE_USER_GROUP_SUCCESS:
      return {
        ...state,
        isLoading: true,
      };
    case UserGroupActionKeys.REMOVE_USER_GROUP_FAILURE:
      return {
        ...state,
        isLoading: true,
        error: action.payload,
      };

    case UserGroupActionKeys.THERE_IS_USER_GROUP_NAME_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: undefined,
      };
    case UserGroupActionKeys.THERE_IS_USER_GROUP_NAME_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: undefined,
        thereIsUserGroupName: action.payload,
      };
    case UserGroupActionKeys.UPDATE_USER_GROUP_PERMISSION:
      return {
        ...state,
        isLoading: true,
        error: undefined,
      };
    case UserGroupActionKeys.SHOW_PERMISSION_DEPENDENCY_MODAL:
      return {
        ...state,
        showPermissionDependencyModal: true,
        permissionDependencyModalData: action.payload,
      };
    case UserGroupActionKeys.HIDE_PERMISSION_DEPENDENCY_MODAL:
      return {
        ...state,
        showPermissionDependencyModal: false,
        permissionDependencyModalData: undefined,
      };

    case UserGroupActionKeys.UPDATE_USER_GROUP_MODULE_STATUS:
      const modules = state.userGroup?.modulos.slice();
      const updateModule = (module: IModulo) => {
        if (module.permissaoId?.toString() === action.payload.permissionId) {
          module.ativo = action.payload.value;
        }

        if (module.itens?.length && module.itens?.length > 0) {
          module.itens.forEach((x) => updateModule(x));
        }
      };
      modules?.forEach((x) => updateModule(x));
      return {
        ...state,
        userGroup: { ...state.userGroup, modulos: modules! } as any,
      };
    default:
      return state;
  }
};

export default groupReducer;
