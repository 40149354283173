import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { HorizontalTabs } from '../../../../Components';
import { DeleteProductModal } from './Modal/deleteModal';
import { PageLoading } from '../../../../Layouts/PageLoading';
import { StockBalanceTab } from './Tabs/StockBalance';
import { useParams } from 'react-router-dom';
import { history } from '../../../../Store';
import { useProductGet } from '../../../../Hooks/Supply/ProductHooks';
import { DetailsTab } from './Tabs/Details';
import { FinanceTab } from './Tabs/Finance';

export const EditProduct: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [disabledForm, setDisabledForm] = useState(true);
  const [visibleModal, setVisibleModal] = useState(false);
  const params: Readonly<Partial<{ externalId: string; tab: string }>> =
    useParams();

  const { externalId } = useParams<{ externalId: string }>();
  const { data, isLoading } = useProductGet(externalId);

  return (
    <PageLoading loading={isLoading}>
      <HorizontalTabs
        type="secondary"
        items={[
          {
            label: t('common.details'),
            key: 'details',
            children: (
              <DetailsTab
                disabledForm={disabledForm}
                onDisabledForm={() => setDisabledForm(true)}
                toogleDisabled={(x: boolean) => setDisabledForm(x)}
              />
            ),
          },
          {
            label: t('common.financial'),
            key: 'finance',
            children: (
              <FinanceTab
                disabledForm={disabledForm}
                onDisabledForm={() => setDisabledForm(true)}
                toogleDisabled={(x: boolean) => setDisabledForm(x)}
              />
            ),
          },
          {
            label: t('common.inventory'),
            key: 'inventory',
            children: <StockBalanceTab />,
          },
        ]}
        dropdownDirection="bottomRight"
        dropdownTitle={t('admin.editUserPage.tab.buttonActions.title')}
        leftIcon="menu-ellipsis-horizontal"
        leftButton={{
          onClick: () => history.push(`/inventory/products//details`),
          leftIcon: 'Box',
          children: t('supply.lot.details.goToProducts'),
        }}
        rigthButton={{
          onClick: () => setDisabledForm(false),
          isVisible: disabledForm,
          codePermissionEdit: params.tab === 'finance' ? 3005 : 3004,
          messageTooltipPermission:
            params.tab === 'finance'
              ? 'noPermission.supply.products.editFinancial'
              : 'noPermission.supply.products.edit',
        }}
        dropdownItens={[
          {
            label: 'Excluir',
            icon: 'trash',
            onClick: () => setVisibleModal(true),
            permission: {
              permission: 3007,
              tooltip: 'noPermission.supply.products.delete',
            },
          },
        ]}
      />
      <DeleteProductModal
        dispatch={dispatch}
        product={data || undefined}
        visibleModal={visibleModal}
        changeVisibleModal={(value) => setVisibleModal(value)}
      />
    </PageLoading>
  );
};
