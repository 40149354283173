import React, { FC, memo } from 'react';
import splash0 from '../../../../../Static/img/splash-arts/splash0.png';
import splash1 from '../../../../../Static/img/splash-arts/splash1.png';
import splash2 from '../../../../../Static/img/splash-arts/splash2.png';
import splash3 from '../../../../../Static/img/splash-arts/splash3.png';
import splash4 from '../../../../../Static/img/splash-arts/splash4.png';
import splash5 from '../../../../../Static/img/splash-arts/splash5.png';
import splash6 from '../../../../../Static/img/splash-arts/splash6.png';
import splash7 from '../../../../../Static/img/splash-arts/splash7.png';
import splash8 from '../../../../../Static/img/splash-arts/splash8.png';
import { Icon } from '../../../../Icon';

import styles from './RandonBackground.module.scss'

const RandomBackground: FC = () => {
	const min = 0;
	const max = 9;
	const rnd = Math.random() * (max - min) + min;
	const rndInt = parseInt(rnd.toString(), 10);

	const imgs: any = {
		splash0,
		splash1,
		splash2,
		splash3,
		splash4,
		splash5,
		splash6,
		splash7,
		splash8,
	}

	return (
		<>
			<Icon className={styles['icon']} icon='logo-bootys-login-image' color='white' />
			<div className={styles['image']} style={{ backgroundImage: `url(${imgs[`splash${rndInt}`]})` }} />
			<div className={styles['filter']} />
		</>
	);
}


export default memo(RandomBackground);