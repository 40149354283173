import { useMutation, useQuery } from '@tanstack/react-query';
import { i18n } from '../../Components';
import { IPagination } from '../../Components/Table';
import { ConglomerateAPI } from '../../Data/API/Accountmanager/ConglomerateApi';
import {
  messageErrorToast,
  messageSucessToast,
} from '../../Utils/MessageErrorToast';

const CREATE_CONGLOMERATE_TYPE = '[CONGLOMERATE] CREATE_CONGLOMERATE_TYPE';
const DELETE_CONGLOMERATE_TYPE = '[CONGLOMERATE] DELETE_CONGLOMERATE_TYPE';
const EDIT_CONGLOMERATE_TYPE = '[CONGLOMERATE] EDIT_CONGLOMERATE_TYPE';
const CHANGE_STATUS_CONGLOMERATE_TYPE =
  '[CONGLOMERATE] CHANGE_STATUS_CONGLOMERATE_TYPE';

export const GET_CONGLOMERATE_TYPE = '[CONGLOMERATE] GET_CONGLOMERATE_TYPE';
export const LIST_CONGLOMERATE_ADMIN_TYPE =
  '[CONGLOMERATE] LIST_CONGLOMERATE_ADMIN_TYPE';
export const LIST_CONGLOMERATE_ADMIN_FOR_DROPDOWN_TYPE =
  '[CONGLOMERATE] LIST_CONGLOMERATE_ADMIN_FOR_DROPDOWN_TYPE';

export const useConglomerateCreateMutation = () =>
  useMutation([CREATE_CONGLOMERATE_TYPE], ConglomerateAPI.createConglomerate, {
    onError: (x) => messageErrorToast(x),
    onSuccess: () =>
      messageSucessToast(i18n.t('adminBootys.createCompany.messages.success')),
  });
export const useConglomerateDeleteType = () =>
  useMutation([DELETE_CONGLOMERATE_TYPE], ConglomerateAPI.deleteConglomerate);
export const useConglomerateEditType = () =>
  useMutation([EDIT_CONGLOMERATE_TYPE], ConglomerateAPI.editConglomerate, {
    onError: (x: any) => messageErrorToast(x),
  });
export const useConglomerateChangeStatusType = () =>
  useMutation(
    [CHANGE_STATUS_CONGLOMERATE_TYPE],
    ConglomerateAPI.changeStatusConglomerate
  );

export const useConglomerateGetType = (externalId?: string) =>
  useQuery(
    [GET_CONGLOMERATE_TYPE, externalId],
    ConglomerateAPI.getConglomerate
  );
export const useConglomerateAdminListType = (pagination: IPagination) =>
  useQuery(
    [LIST_CONGLOMERATE_ADMIN_TYPE, pagination],
    ConglomerateAPI.listConglomerate
  );
export const useConglomerateAdminListForDropdownType = (
  pagination: IPagination
) =>
  useQuery(
    [LIST_CONGLOMERATE_ADMIN_FOR_DROPDOWN_TYPE, pagination],
    ConglomerateAPI.listConglomerateForDropdown
  );
