export const contactEN = {
  contact: 'Contact',
  contacts: 'Contacts',
  contactType: 'Contact Type',

  observation: 'Observation',

  contactEditTitle: 'Edit contact',
  contactAddTitle: 'Add contact',

  empytDataDescription: 'No contact registered',
};

export const contactPT = {
  contact: 'Contato',
  contacts: 'Contatos',
  contactType: 'Tipo de Contato',

  observation: 'Observações',

  contactEditTitle: 'Editar contato',
  contactAddTitle: 'Adicionar contato',

  empytDataDescription: 'Nenhum contato cadastrado',
};
