import React, { useEffect, FC, useState } from 'react';

import styles from './CompanyBillingsCard.module.scss';
import { FooterButton } from '../Components/FooterButton';
import { useFormikContext } from 'formik';
import { Card, i18n, InputWithSearch } from '../../../Components';
import { Col, Row } from 'antd';
import { patternLabelStyle } from '../../../Components/Inputs/TextInput';
import { useCompanyListForDropdownAdminType } from '../../../Hooks/Accountmanager/CompanyHooks';
import { IQueryListParams } from '../../../Utils/Http';
import { RadioButton } from '../../../Components/StateInputs/RadioButton';
import { RadioGroup } from '../../../Components/StateInputs/RadioButton/RadioGroup';

export enum BillingsTypeEnum {
  ownCompany = 1,
  anotherCompany = 2,
}

interface ICompanyBillingsCard {
  editForm?: boolean;

  onButtonChange: (value: boolean) => void;
  companyGroupExternalId?: string;

  radioCompanyGroup: boolean;

  radioBillings: boolean;
  isLoading?: boolean;
}

export const CompanyBillingsCard: FC<ICompanyBillingsCard> = ({
  editForm,
  onButtonChange,
  radioCompanyGroup,
  companyGroupExternalId,
  radioBillings,
  isLoading,
}) => {
  const [buttonDisabled, setButtonDisabled] = useState(editForm);

  const form: any = useFormikContext();

  const [pagination, setPagination] = useState<IQueryListParams>({
    PageIndex: 0,
    PageSize: 50,
  });

  const { data } = useCompanyListForDropdownAdminType(pagination);

  useEffect(() => {
    setPagination({
      ...pagination,
      filter: [
        {
          filterName: 'conglomeradoExternalId',
          externalId: form?.values?.conglomeradoExternalId
            ? form?.values?.conglomeradoExternalId
            : companyGroupExternalId,
        },
      ],
    });
  }, [
    form?.values?.conglomeradoExternalId,
    companyGroupExternalId,
    pagination,
  ]);

  useEffect(() => {
    !editForm && form?.setFieldValue('empresaPagadoraExternalId', undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [radioCompanyGroup, radioBillings]);

  useEffect(() => {
    !editForm && onButtonChange && onButtonChange(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [radioCompanyGroup]);

  return (
    <Card
      title={i18n.t('adminBootys.createCompany.billing')}
      withoutCollapse={editForm}
    >
      <RadioGroup
        className={styles['radio-button']}
        valueChecked={radioBillings ? 1 : 2}
      >
        <RadioButton
          value={1}
          label={i18n.t('adminBootys.createCompany.billingCard.ownBilling')}
          onChange={() => onButtonChange(true)}
          disabled={buttonDisabled && editForm}
        />
        <RadioButton
          value={2}
          label={i18n.t('adminBootys.createCompany.billingCard.companyBilling')}
          onChange={() => {
            onButtonChange(false);
            form?.setFieldValue('empresaPagadoraExternalId', undefined);
          }}
          disabled={!form?.values.conglomeradoExternalId && !editForm}
          tooltip={
            !buttonDisabled && !form?.values.conglomeradoExternalId
              ? i18n.t('adminBootys.createCompany.billingCard.radioTooltip')
              : ''
          }
        />
      </RadioGroup>
      {!radioBillings && (
        <Row>
          <Col flex="34%">
            <InputWithSearch
              name="empresaPagadoraExternalId"
              items={data?.data?.map((x) => ({
                id: x.externalId,
                label: x.nomeFantasia,
                avatar: {
                  name: x.nomeFantasia,
                },
              }))}
              label={[
                {
                  children: i18n.t(
                    'adminBootys.createCompany.billingCard.companySelect'
                  ),
                  ...patternLabelStyle,
                },
              ]}
              placeHolder={i18n.t(
                'adminBootys.createCompany.billingCard.companySelectPlaceholder'
              )}
              withoutMarginBottom={true}
              disabled={buttonDisabled}
              required
              onScrollEnd={() =>
                data &&
                data?.count >
                  pagination.PageSize * (pagination.PageIndex + 1) &&
                setPagination({
                  ...pagination,
                  PageIndex: pagination.PageIndex + 1,
                })
              }
            />
          </Col>
        </Row>
      )}
      {editForm && (
        <FooterButton
          disabled={!!buttonDisabled}
          editLabel={i18n.t('adminBootys.createCompany.billingCard.edit')}
          onChange={(value) => setButtonDisabled(value)}
          loading={isLoading}
        />
      )}
    </Card>
  );
};
