import { createAction, Action, ActionsUnion } from '../Base/actionHelpers';
import { IQueryParams } from '../../Components/Table';
import { IListProviderResponse } from '../../Data/Interfaces/response/Provider/IProviderResponse';

export enum ProviderActionKeys {
  LIST_PROVIDER_REQUEST = '[PROVIDER] LIST_PROVIDER_REQUEST',
  LIST_PROVIDER_SUCCESS = '[PROVIDER] LIST_PROVIDER_SUCCESS',
  LIST_PROVIDER_FAILURE = '[PROVIDER] LIST_PROVIDER_FAILURE',
}

export const ProviderActions = {
  getListProvider: (body: IQueryParams): ListProviderRequestAction =>
    createAction(ProviderActionKeys.LIST_PROVIDER_REQUEST, body),
  getListProviderSuccess: (
    res: IListProviderResponse
  ): ListProviderSuccessAction =>
    createAction(ProviderActionKeys.LIST_PROVIDER_SUCCESS, res),
  getListProviderFailure: (error: string): ListProviderFailureAction =>
    createAction(ProviderActionKeys.LIST_PROVIDER_FAILURE, error),
};

export type ProviderActionUnion = ActionsUnion<typeof ProviderActions>;

export type ListProviderRequestAction = Action<
  ProviderActionKeys.LIST_PROVIDER_REQUEST,
  IQueryParams
>;
export type ListProviderSuccessAction = Action<
  ProviderActionKeys.LIST_PROVIDER_SUCCESS,
  IListProviderResponse
>;
export type ListProviderFailureAction = Action<
  ProviderActionKeys.LIST_PROVIDER_FAILURE,
  string
>;
