import React, { FC, ReactNode, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Icon, Skeleton } from '..';
import {
  getContextId,
  getModulosSistema,
} from '../../Store/User/User.selector';
import { useLocation } from 'react-router-dom';
import StorageUtils from '../../Utils/StorageUtils';
import { PermissionWrapper } from '../PermissionWrapper';
import { IMenuItem, MenuItem } from './MenuItem';
import { SectorMenu } from './SectorMenu';
import { redirectToDefaultContext } from './SideMenu.helpers';
import { motion } from 'framer-motion';

import styles from './SideMenu.module.scss';

export interface ISideMenu {
  items?: IMenuItem[];
  children: ReactNode;
  onCollapsedChange: (any: any) => any;
}

export const SideMenu: FC<ISideMenu> = ({
  items,
  children,
  onCollapsedChange,
}) => {
  const COLLAPSE_SIDEBAR = 'COLLAPSE-SIDEBAR';
  const location = useLocation();
  const dispatch = useDispatch();
  const modules = useSelector(getModulosSistema);
  const currentContextId = useSelector(getContextId);
  const [isCollapsed, setIsCollapsed] = useState(
    StorageUtils.GetItem(COLLAPSE_SIDEBAR) === 'true'
  );

  const onCollapseClick = () => {
    StorageUtils.SetItem(COLLAPSE_SIDEBAR, (!isCollapsed).toString());

    setIsCollapsed(!isCollapsed);
  };

  useEffect(() => {
    onCollapsedChange(isCollapsed);
  }, [isCollapsed, onCollapsedChange]);

  // Set context with the current url
  useEffect(() => {
    redirectToDefaultContext(dispatch, location, modules);
  }, [location, modules, dispatch]);

  return (
    <main className={styles['Content']}>
      <motion.div animate={{ width: !isCollapsed ? 225 : 65 }}>
        <div
          className={`${styles['sideMenu']} ${
            isCollapsed ? styles['collapsed'] : ''
          }`}
        >
          <div className={styles['sector-menu']}>
            <SectorMenu isCollapsed={isCollapsed} modules={modules} />

            <div className={styles['separator']}></div>
          </div>

          {!!currentContextId ? (
            items &&
            items.map((x, index) => (
              <div
                className={`${styles['side-menu-items']} ${
                  isCollapsed ? styles['collapsed-side-menu-items'] : ''
                }`}
                key={x.id}
              >
                <PermissionWrapper
                  permission={x.permission?.permission}
                  tooltip={x.permission?.tooltip || 'NoPermission'}
                  key={x.name}
                >
                  <MenuItem
                    {...x}
                    isCollapsed={isCollapsed}
                    key={`side-menu-${index}`}
                  />
                </PermissionWrapper>
              </div>
            ))
          ) : (
            <div style={{ width: '225px' }}>
              <Skeleton size="md" />
              <br />
              <Skeleton size="md" />
              <br />
              <Skeleton size="md" />
            </div>
          )}
        </div>
        <div
          className={`${styles['footer']} ${
            isCollapsed ? styles['collapsed-footer'] : ''
          }`}
        >
          <Icon
            icon={isCollapsed ? 'double-chevron-right' : 'double-chevron-left'}
            size="XXL"
            color="text-400"
            onClick={onCollapseClick}
          />
        </div>
      </motion.div>

      <div
        className={`${styles['main']} ${
          isCollapsed ? styles['collapsed-main'] : ''
        }`}
      >
        {children}
      </div>
    </main>
  );
};
