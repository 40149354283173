import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { IPagination, Table } from '../../../../../../Components/Table';
import { useStockBalanceByProductGetList } from '../../../../../../Hooks/Supply/StockBalanceHooks';
import { ContainerPage } from '../../../../../../Layouts/ContainerPage';
import { stockBalanceColumns } from './stock-balance.columns';

export const StockBalanceTab: FC = () => {
  const [pagination, setPagination] = useState<IPagination>({
    PageIndex: 0,
    PageSize: 10,
  });
  const { t } = useTranslation();
  const { externalId } = useParams<{ externalId: string }>();
  const { data: stockBalanceByProduct, isLoading } =
    useStockBalanceByProductGetList(pagination, externalId);

  return (
    <ContainerPage>
      <Table
        columns={stockBalanceColumns(t)}
        data={
          stockBalanceByProduct?.data.map((x) => ({
            ...x,
            key: `stock_${x.sequenciaGroupTenant}`,
          })) || []
        }
        hasSelection={false}
        headerOptions={{
          hasSearch: true,
          searchPlaceholder: t(
            'products.productList.stockBalance.searchPlaceHolder'
          ),
        }}
        loading={isLoading}
        pagination={
          stockBalanceByProduct
            ? {
                Count: stockBalanceByProduct.count,
                PageIndex: stockBalanceByProduct.pageIndex,
                PageSize: stockBalanceByProduct.pageSize,
              }
            : false
        }
        onRefetch={(pagination) => {
          setPagination(pagination);
        }}
      />
    </ContainerPage>
  );
};
