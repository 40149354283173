import { QueryFunctionContext } from '@tanstack/react-query';
import { Http, IQueryListParams } from '../../../Utils/Http';
import {
  IListProviderResponse,
  IProviderGetResponse,
} from '../../Interfaces/response/Provider/IProviderResponse';

export class ProviderAPI {
  public static async listProvider(
    context: QueryFunctionContext
  ): Promise<IListProviderResponse> {
    return await Http.fetcher<IListProviderResponse>(
      Http.AddQueryParams(
        '/pharma/pessoa/v1/Fornecedor/Listar',
        context.queryKey[1] as IQueryListParams
      )
    );
  }

  public static async getProvider(context: QueryFunctionContext) {
    if (!context.queryKey[1]) return null;
    return await Http.fetcher<IProviderGetResponse>(
      `/pharma/pessoa/v1/Fornecedor/${context.queryKey[1]}`
    );
  }
}
