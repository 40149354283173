import React, { useState, useEffect, FC } from 'react';
import { Row, Col } from 'antd';
import { FooterButton } from '../Components/FooterButton';
import { FormikHelpers, FormikValues, useFormikContext } from 'formik';
import { Card, Form, i18n } from '../../../Components';
import { TextInputWithMask } from '../../../Components/Inputs/TextInputWithMask';
import {
  patternLabelStyle,
  TextInput,
} from '../../../Components/Inputs/TextInput';
import { CompanyAdressCardSchema } from './CompanyAdressCard.schema';
import { IChangeCompanyAdressRequest } from '../../../Data/Interfaces/request/Company/ICompanyRequest';
import { RemoveMask } from '../../../Utils/MasksUtils';
import { useCompanyChangeAdressMutation } from '../../../Hooks/Accountmanager/CompanyHooks';
import { initialValues } from './CompanyAdressCard.InitialValues';
import { useGetCEPType } from '../../../Hooks/CEP/CepHooks';
import { IGetCompanyResponse } from '../../../Data/Interfaces/response/Company/ICompanyResponse';

interface ICompanyCard {
  editForm?: boolean;
  buttonDisabled?: boolean;
  onButtonChange?: (value: boolean) => void;
  isLoading?: boolean;
}

export const CompanyCard: FC<ICompanyCard> = ({
  editForm,
  buttonDisabled,
  onButtonChange,
  isLoading,
}) => {
  const [CEP, setCEP] = useState('');
  const form = useFormikContext<FormikValues>();
  const { data: adress, isLoading: cepLoading } = useGetCEPType(CEP);

  useEffect(() => {
    const formAddress = form.values['endereco'];
    const addressDidChange =
      formAddress?.logradouro !== (adress?.logradouro || '') ||
      formAddress?.bairro !== (adress?.bairro || '') ||
      formAddress?.cidade !== (adress?.cidade || '') ||
      formAddress?.estado !== (adress?.estado || '');

    if (!!adress && !!form && addressDidChange) {
      form.setFieldValue('endereco.logradouro', adress.logradouro);
      form.setFieldValue('endereco.bairro', adress.bairro);
      form.setFieldValue('endereco.cidade', adress.cidade);
      form.setFieldValue('endereco.estado', adress.estado);
    } else if (!editForm && !formAddress) {
      form.setFieldValue('endereco.logradouro', '');
      form.setFieldValue('endereco.bairro', '');
      form.setFieldValue('endereco.cidade', '');
      form.setFieldValue('endereco.estado', '');
    }
  }, [adress, form, editForm]);

  return (
    <Card
      title={i18n.t('adminBootys.createCompany.CompanyAdress')}
      withoutCollapse={editForm}
    >
      <Row>
        <Col flex="24%">
          <TextInputWithMask
            name="endereco.cep"
            label={[
              {
                children: i18n.t(
                  'adminBootys.createCompany.CompanyAdressCard.cep'
                ),
                ...patternLabelStyle,
              },
            ]}
            mask="cepMask"
            placeHolder={i18n.t(
              'adminBootys.createCompany.CompanyAdressCard.cepPlaceholder'
            )}
            disabled={buttonDisabled}
            required
            loading={cepLoading}
            onChange={setCEP}
          />
        </Col>
      </Row>
      <Row gutter={[16, 0]}>
        <Col flex="85%">
          <TextInput
            name="endereco.logradouro"
            label={[
              {
                children: i18n.t(
                  'adminBootys.createCompany.CompanyAdressCard.street'
                ),
                ...patternLabelStyle,
              },
            ]}
            placeHolder={i18n.t(
              'adminBootys.createCompany.CompanyAdressCard.streetPlaceholder'
            )}
            disabled={true}
            required
          />
        </Col>
        <Col flex="15%">
          <TextInput
            name="endereco.numero"
            label={[
              {
                children: i18n.t(
                  'adminBootys.createCompany.CompanyAdressCard.houseNumber'
                ),
                ...patternLabelStyle,
              },
            ]}
            placeHolder={i18n.t(
              'adminBootys.createCompany.CompanyAdressCard.houseNumberPlaceholder'
            )}
            type="number"
            disabled={buttonDisabled}
            required
          />
        </Col>
      </Row>
      <Row gutter={[20, 0]}>
        <Col span={6}>
          <TextInput
            name="endereco.complemento"
            withoutMarginBottom={true}
            label={[
              {
                children: i18n.t(
                  'adminBootys.createCompany.CompanyAdressCard.complement'
                ),
                ...patternLabelStyle,
              },
            ]}
            placeHolder={i18n.t(
              'adminBootys.createCompany.CompanyAdressCard.complementPlaceholder'
            )}
            disabled={buttonDisabled}
          />
        </Col>
        <Col span={6}>
          <TextInput
            name="endereco.bairro"
            withoutMarginBottom={true}
            label={[
              {
                children: i18n.t(
                  'adminBootys.createCompany.CompanyAdressCard.neighborhood'
                ),
                ...patternLabelStyle,
              },
            ]}
            placeHolder={i18n.t(
              'adminBootys.createCompany.CompanyAdressCard.neighborhoodPlaceholder'
            )}
            disabled={true}
            required
          />
        </Col>
        <Col span={6}>
          <TextInput
            name="endereco.cidade"
            withoutMarginBottom={true}
            label={[
              {
                children: i18n.t(
                  'adminBootys.createCompany.CompanyAdressCard.city'
                ),
                ...patternLabelStyle,
              },
            ]}
            placeHolder={i18n.t(
              'adminBootys.createCompany.CompanyAdressCard.cityPlaceholder'
            )}
            disabled={true}
            required
          />
        </Col>
        <Col span={6}>
          <TextInput
            name="endereco.estado"
            withoutMarginBottom={true}
            label={[
              {
                children: i18n.t(
                  'adminBootys.createCompany.CompanyAdressCard.state'
                ),
                ...patternLabelStyle,
              },
            ]}
            placeHolder={i18n.t(
              'adminBootys.createCompany.CompanyAdressCard.statePlaceholder'
            )}
            disabled={true}
            required
          />
        </Col>
      </Row>
      {onButtonChange && form && (
        <FooterButton
          disabled={!!buttonDisabled}
          editLabel={i18n.t('adminBootys.createCompany.CompanyAdressCard.edit')}
          onChange={onButtonChange}
          loading={!!isLoading}
        />
      )}
    </Card>
  );
};

interface ICompanyAdressCard {
  editForm?: boolean;
  company: IGetCompanyResponse;
}

export const CompanyAdressCard: React.FC<ICompanyAdressCard> = ({
  editForm,
  company,
}) => {
  const [buttonDisabled, setButtonDisabled] = useState(true);

  const { mutateAsync, isLoading } = useCompanyChangeAdressMutation({
    onSuccess: () => setButtonDisabled(true),
  });

  const onSubmit = async (
    values: IChangeCompanyAdressRequest,
    helpers: FormikHelpers<FormikValues>
  ) => {
    var body: IChangeCompanyAdressRequest = {
      ...values,
      helpers,
    };
    const cep = RemoveMask(body.endereco.cep);
    if (cep) body.endereco.cep = cep;
    await mutateAsync(body);
  };

  return (
    <>
      {editForm ? (
        <Form
          onSubmit={onSubmit}
          initialValues={company && initialValues(company)}
          schema={CompanyAdressCardSchema}
        >
          <CompanyCard
            editForm={editForm}
            onButtonChange={(value) => setButtonDisabled(value)}
            buttonDisabled={buttonDisabled}
            isLoading={isLoading}
          />
        </Form>
      ) : (
        <CompanyCard editForm={!!editForm} />
      )}
    </>
  );
};
