import React from 'react';
import { i18n, Link, Status } from '../../../../../Components';
import { ICurrencyResponse } from '../../../../../Data/Interfaces/response/Currency/ICurrencyResponse';
import { currencyFormater } from '../../../../../Utils/Currency';

export const productCollums = (t: any, currentCurrency?: ICurrencyResponse) => [
  {
    title: t('products.productList.tableTitle.code'),
    dataIndex: 'sequenciaGroupTenant',
    key: 'sequenciaGroupTenant',
    width: '7%',
  },
  {
    title: t('products.productList.tableTitle.productName'),
    dataIndex: 'descricao',
    key: 'descricao',
    sortColumn: 'descricao',
    sorter: true,
    width: '25%',
    defaultSortOrder: 'ascend',
    isHighlightable: true,
    render: (x: any, row: any) => (
      <Link
        type="ui-tiny-bold"
        to={`/inventory/products/${row.externalId}/details`}
        style={{ width: 'fit-content' }}
      >
        {x}
      </Link>
    ),
  },
  {
    title: t('products.productList.tableTitle.status'),
    dataIndex: 'ativo',
    key: 'ativo',
    render: (text: any) => (
      <Status type={text ? 'success' : 'disabled'}>
        {i18n.t(`userGroups.list.statusTypes.${text ? 'active' : 'inactive'}`)}
      </Status>
    ),
  },
  {
    title: t('products.productList.tableTitle.subGroup'),
    dataIndex: 'subGrupoDescricao',
    key: 'subGrupoDescricao',
  },
  {
    title: t('products.productList.tableTitle.costValue'),
    dataIndex: 'valorCusto',
    key: 'valorCusto',
    render: (x: any, row: any) =>
      currencyFormater(x, currentCurrency?.tipoMoedaId, row.unidadeMedida),
  },
  {
    title: t('products.productList.tableTitle.saleValue'),
    dataIndex: 'valorVenda',
    key: 'valorVenda',
    render: (x: any, row: any) =>
      currencyFormater(x, currentCurrency?.tipoMoedaId, row.unidadeMedida),
  },
];
