import { Languages, TypeLanguages } from '../../../Components/i18n'

export const languages = [
	{ id: Languages.ptBR, name: TypeLanguages['pt-BR'] },
	{ id: Languages.enUS, name: TypeLanguages['en-US'] },
]

export const standardDate = [
	{ id: 'dd/mm/yyyy', name: 'dd/mm/yyyy' },
	{ id: 'mm/dd/yyyy', name: 'mm/dd/yyyy' },
]

export const standardTime = [
	{ id: 'h:mm tt', name: '0:00 (h:mm tt)' },
	{ id: 'hh:mm tt', name: '00:00 (hh:mm tt)' },
	{ id: 'H:mm', name: '0:00 (H:mm)' },
	{ id: 'HH:mm', name: '00:00 (HH:mm)' },
	{ id: 'HH:mm "Uhr"', name: '00:00 Uhr (HH:mm "Uhr")' },
	{ id: 'H.mm', name: '0.00 (H.mm)' },
	{ id: 'HH.mm', name: '00.00 (HH.mm)' },
	{ id: 'HH"H"mm', name: '00H00 (HH"H"mm)' },
]
