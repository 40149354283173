import {
  DynamicEditBalanceAdjustmentBreadcrumb,
  DynamicInfoEditBalanceAdjustmentBreadcrumb,
} from './DynamicRoutes/BalanceAdjustments';
import {
  DynamicEditCompanyStatusBreadcrumb,
  DynamicEditCompanyBreadcrumbCNPJ,
  DynamicUserBreadcrumbConglomerate,
  DynamicUserBreadcrumbStatusConglomerate,
  DynamicEditCompanyBreadcrumb,
} from './DynamicRoutes/Company';
import {
  DynamicEditUserGroupNameBreadcrumb,
  DynamicEditUserGroupStatusBreadcrumb,
} from './DynamicRoutes/Group';
import {
  DynamicEditProductBreadcrumb,
  DynamicEditProductTitleBreadcrumb,
  DynamicEditSubGroupOnGroupTitleBreadcrumb,
  DynamicEditSubGroupProductBreadcrumb,
  DynamicEditSubGroupProductTitleBreadcrumb,
} from './DynamicRoutes/Product';
import {
  DynamicEditUserStatusBreadcrumb,
  DynamicUserBreadcrumbEmail,
} from './DynamicRoutes/User';
import {
  DynamicEditStoragePlaceStatusBreadcrumb,
  DynamicEditStorageLocationBreadcrumb,
} from './DynamicRoutes/StorageLocation';
import {
  DynamicEditLosseBreadcrumb,
  DynamicEditLosseBreadcrumbTitle,
} from './DynamicRoutes/Losses';

import {
  DynamicEditLotBreadcrumb,
  DynamicEditLotStatusBreadcrumb,
} from './DynamicRoutes/Lot';
import {
  DynamicInfosTransfersBreadcrumb,
  DynamicTransfersBreadcrumb,
} from './DynamicRoutes/Transfer';

export const titles = [
  { path: '/', breadcrumb: 'breadCrumb.home' },

  { path: '/user-preference', breadcrumb: 'breadCrumb.preference' },
  { path: '/user-preference/currency', breadcrumb: 'breadCrumb.currency' },
  {
    path: '/user-preference/place-and-time',
    breadcrumb: 'breadCrumb.placeAndTime',
  },
  {
    path: '/user-preference/accessibility',
    breadcrumb: 'breadCrumb.accessibility',
  },
  { path: '/user-preference/theme', breadcrumb: 'breadCrumb.theme' },

  { path: '/account-manager', breadcrumb: 'breadCrumb.accountManager' },
  {
    path: '/account-manager/account-details',
    breadcrumb: 'breadCrumb.accountDetails',
  },
  {
    path: '/account-manager/account-security',
    breadcrumb: 'breadCrumb.accountSecurity',
  },
  {
    path: '/account-manager/account-notification',
    breadcrumb: 'breadCrumb.notification',
  },

  { path: '/system-configurations', breadcrumb: 'breadCrumb.systemConfig' },
  {
    path: '/system-configurations/personalization',
    breadcrumb: 'breadCrumb.personalization',
  },
  {
    path: '/system-configurations/currency',
    breadcrumb: 'breadCrumb.currency',
  },

  { path: '/admin-bootys', breadcrumb: 'breadCrumb.adminBootys' },
  {
    path: '/admin-bootys/company',
    breadcrumb: 'breadCrumb.adminBootysRoute.company',
  },
  {
    path: '/admin-bootys/company/create/:externalId',
    breadcrumb: 'breadCrumb.adminBootysRoute.companyPage.create',
  },
  {
    path: '/admin-bootys/company/create',
    breadcrumb: 'breadCrumb.adminBootysRoute.companyPage.create',
  },
  {
    path: '/admin-bootys/company/:externalId',
    breadcrumb: DynamicEditCompanyStatusBreadcrumb,
  },
  {
    path: '/admin-bootys/company/:externalId/:tab',
    breadcrumb: DynamicEditCompanyStatusBreadcrumb,
  },
  {
    path: '/admin-bootys/conglomerate',
    breadcrumb: 'breadCrumb.adminBootysRoute.conglomerate',
  },
  {
    path: '/admin-bootys/conglomerate/:externalId',
    breadcrumb: DynamicUserBreadcrumbStatusConglomerate,
  },
  {
    path: '/admin-bootys/conglomerate/:externalId/details',
    breadcrumb: DynamicUserBreadcrumbStatusConglomerate,
  },
  {
    path: '/admin-bootys/conglomerate/:externalId/Companys',
    breadcrumb: DynamicUserBreadcrumbStatusConglomerate,
  },

  { path: '/admin', breadcrumb: 'breadCrumb.admin' },
  { path: '/admin/user', breadcrumb: 'breadCrumb.adminRoute.user' },
  {
    path: '/admin/user/create',
    breadcrumb: 'breadCrumb.adminRoute.userPage.create',
  },
  {
    path: '/admin/user/:externalId/permissions',
    breadcrumb: DynamicEditUserStatusBreadcrumb,
  },
  {
    path: '/admin/user/:externalId/details',
    breadcrumb: DynamicEditUserStatusBreadcrumb,
  },
  {
    path: '/admin/user/:externalId',
    breadcrumb: DynamicEditUserStatusBreadcrumb,
  },
  {
    path: '/admin/user-group',
    breadcrumb: 'breadCrumb.adminRoute.groupPage.list',
  },
  {
    path: '/admin/user-group/create',
    breadcrumb: 'breadCrumb.adminRoute.groupPage.create',
  },
  {
    path: '/admin/user-group/:externalId',
    breadcrumb: DynamicEditUserGroupStatusBreadcrumb,
  },
  {
    path: '/admin/user-group/:externalId/details',
    breadcrumb: DynamicEditUserGroupStatusBreadcrumb,
  },
  {
    path: '/admin/user-group/:externalId/users',
    breadcrumb: DynamicEditUserGroupStatusBreadcrumb,
  },
  {
    path: '/admin/user-group/:externalId/permissions',
    breadcrumb: DynamicEditUserGroupStatusBreadcrumb,
  },
  {
    path: '/admin/company/:externalId/details',
    breadcrumb: DynamicEditCompanyBreadcrumb,
  },
  {
    path: '/admin/company',
    breadcrumb: 'breadCrumb.adminRoute.company',
  },
  {
    path: '/inventory',
    breadcrumb: 'breadCrumb.supply',
  },
  {
    path: '/inventory/products',
    breadcrumb: 'breadCrumb.products.list',
  },
  {
    path: '/inventory/products/create',
    breadcrumb: 'breadCrumb.products.create',
  },
  {
    path: '/inventory/products/create/:externalId/:group',
    breadcrumb: 'breadCrumb.products.create',
  },
  {
    path: '/inventory/products/:externalId/details',
    breadcrumb: DynamicEditProductBreadcrumb,
  },
  {
    path: '/inventory/products/:externalId/finance',
    breadcrumb: DynamicEditProductBreadcrumb,
  },
  {
    path: '/inventory/products/:externalId/inventory',
    breadcrumb: DynamicEditProductBreadcrumb,
  },
  {
    path: '/inventory/groups',
    breadcrumb: 'breadCrumb.products.group.list',
  },
  {
    path: '/inventory/groups/:externalId',
    breadcrumb: DynamicEditSubGroupOnGroupTitleBreadcrumb,
  },
  {
    path: '/inventory/groups/:externalId/details',
    breadcrumb: DynamicEditSubGroupOnGroupTitleBreadcrumb,
  },
  {
    path: '/inventory/groups/:externalId/subgroups',
    breadcrumb: DynamicEditSubGroupOnGroupTitleBreadcrumb,
  },
  {
    path: '/inventory/groups/:externalId/products',
    breadcrumb: DynamicEditSubGroupOnGroupTitleBreadcrumb,
  },
  {
    path: '/inventory/sub-groups',
    breadcrumb: 'breadCrumb.products.subGroup.list',
  },
  {
    path: '/inventory/sub-groups/:externalId/details',
    breadcrumb: DynamicEditSubGroupProductBreadcrumb,
  },
  {
    path: '/inventory/sub-groups/:externalId/products',
    breadcrumb: DynamicEditSubGroupProductBreadcrumb,
  },
  {
    path: '/inventory/products/create',
    breadcrumb: 'breadCrumb.products.create',
  },
  {
    path: '/inventory/supply-places',
    breadcrumb: 'breadCrumb.supplyRoutes.list',
  },
  {
    path: '/inventory/supply-places/:externalId/details',
    breadcrumb: DynamicEditStoragePlaceStatusBreadcrumb,
  },
  {
    path: '/inventory/supply-places/:externalId/inventory',
    breadcrumb: DynamicEditStoragePlaceStatusBreadcrumb,
  },
  {
    path: '/inventory/losses/:externalId/details',
    breadcrumb: DynamicEditLosseBreadcrumbTitle,
  },

  {
    path: '/inventory/supply-places/:externalId/inventory',
    breadcrumb: 'breadCrumb.supplyPlaces.inventory',
  },
  {
    path: '/inventory/supply-places/:externalId/inventoryMovements',
    breadcrumb: 'breadCrumb.supplyPlaces.inventoryMovements',
  },

  {
    path: '/inventory/lot/:externalId/details',
    breadcrumb: DynamicEditLotStatusBreadcrumb,
  },

  {
    path: '/inventory/lot/:externalId/stockLocations',
    breadcrumb: DynamicEditLotStatusBreadcrumb,
  },

  {
    path: '/inventory/lot',
    breadcrumb: 'breadCrumb.supplyRoutes.lots',
  },
  {
    path: '/inventory/losses',
    breadcrumb: 'breadCrumb.supplyRoutes.lossesList',
  },

  {
    path: '/inventory/lot/create',
    breadcrumb: 'breadCrumb.lots.create',
  },

  {
    path: '/inventory/supply-places/create',
    breadcrumb: 'breadCrumb.supplyPlaces.create',
  },
  {
    path: '/inventory/losses/create',
    breadcrumb: 'breadCrumb.losses.create',
  },

  {
    path: '/inventory/balance-adjustments',
    breadcrumb: 'breadCrumb.balanceAdjustments.list',
  },
  {
    path: '/inventory/balance-adjustments/create',
    breadcrumb: 'breadCrumb.balanceAdjustments.create',
  },
  {
    path: '/inventory/balance-adjustments/:externalId/details',
    breadcrumb: DynamicInfoEditBalanceAdjustmentBreadcrumb,
  },

  {
    path: '/inventory/transfers',
    breadcrumb: 'breadCrumb.supplyRoutes.stockTransfers',
  },
  {
    path: '/inventory/transfers/create',
    breadcrumb: 'breadCrumb.transfers.create.breadCrumb',
  },
  {
    path: '/inventory/transfers/:externalId/details',
    breadcrumb: DynamicInfosTransfersBreadcrumb,
  },

  {
    path: '/purchasing/provider/create',
    breadcrumb: 'breadCrumb.purchasing.provider.create.title',
  },

  {
    path: '/order/purchase',
    breadcrumb: 'breadCrumb.purchasing.purchaseList',
  },
];

export const routes = [
  { path: '/', breadcrumb: 'breadCrumb.home' },

  { path: '/user-preference', breadcrumb: 'breadCrumb.preference' },
  { path: '/user-preference/currency', breadcrumb: 'breadCrumb.currency' },
  {
    path: '/user-preference/place-and-time',
    breadcrumb: 'breadCrumb.placeAndTime',
  },
  {
    path: '/user-preference/accessibility',
    breadcrumb: 'breadCrumb.accessibility',
  },
  { path: '/user-preference/theme', breadcrumb: 'breadCrumb.theme' },

  { path: '/account-manager', breadcrumb: 'breadCrumb.accountManager' },
  {
    path: '/account-manager/account-details',
    breadcrumb: 'breadCrumb.accountDetails',
  },
  {
    path: '/account-manager/account-security',
    breadcrumb: 'breadCrumb.accountSecurity',
  },
  {
    path: '/account-manager/account-notification',
    breadcrumb: 'breadCrumb.notification',
  },

  { path: '/system-configurations', breadcrumb: 'breadCrumb.systemConfig' },
  {
    path: '/system-configurations/personalization',
    breadcrumb: 'breadCrumb.personalization',
  },
  {
    path: '/system-configurations/currency',
    breadcrumb: 'breadCrumb.currency',
  },

  { path: '/admin-bootys', breadcrumb: 'breadCrumb.adminBootys' },
  {
    path: '/admin-bootys/company',
    breadcrumb: 'breadCrumb.adminBootysRoute.company',
  },
  {
    path: '/admin-bootys/company/create',
    breadcrumb: 'breadCrumb.adminBootysRoute.companyPage.create',
  },
  {
    path: '/admin-bootys/company/:externalId',
    breadcrumb: DynamicEditCompanyBreadcrumbCNPJ,
  },
  {
    path: '/admin-bootys/company/:externalId/details',
    breadcrumb: 'breadCrumb.adminBootysRoute.companyPage.details',
  },
  {
    path: '/admin-bootys/conglomerate',
    breadcrumb: 'breadCrumb.adminBootysRoute.conglomerate',
  },
  {
    path: '/admin-bootys/conglomerate/:externalId',
    breadcrumb: DynamicUserBreadcrumbConglomerate,
  },
  {
    path: '/admin-bootys/conglomerate/:externalId/details',
    breadcrumb: 'breadCrumb.adminBootysRoute.conglomeratePage.details',
  },
  {
    path: '/admin-bootys/conglomerate/:externalId/Companys',
    breadcrumb: 'breadCrumb.adminBootysRoute.conglomeratePage.company',
  },

  { path: '/admin', breadcrumb: 'breadCrumb.admin' },
  { path: '/admin/user', breadcrumb: 'breadCrumb.adminRoute.user' },
  {
    path: '/admin/user/create',
    breadcrumb: 'breadCrumb.adminRoute.userPage.create',
  },
  { path: '/admin/user/:externalId', breadcrumb: DynamicUserBreadcrumbEmail },
  {
    path: '/admin/user/:externalId/details',
    breadcrumb: 'breadCrumb.adminRoute.userPage.details',
  },
  {
    path: '/admin/user/:externalId/permissions',
    breadcrumb: 'breadCrumb.adminRoute.userPage.permissions',
  },
  {
    path: '/admin/user-group',
    breadcrumb: 'breadCrumb.adminRoute.groupPage.list',
  },
  {
    path: '/admin/user-group/create',
    breadcrumb: 'breadCrumb.adminRoute.groupPage.create',
  },
  {
    path: '/admin/user-group/:externalId',
    breadcrumb: DynamicEditUserGroupNameBreadcrumb,
  },
  {
    path: '/admin/user-group/:externalId/details',
    breadcrumb: 'breadCrumb.adminRoute.groupPage.edit.tabs.detail',
  },
  {
    path: '/admin/user-group/:externalId/users',
    breadcrumb: 'breadCrumb.adminRoute.groupPage.edit.tabs.users',
  },
  {
    path: '/admin/user-group/:externalId/permissions',
    breadcrumb: 'breadCrumb.adminRoute.groupPage.edit.tabs.permission',
  },

  {
    path: '/admin/company/:externalId/details',
    breadcrumb: 'breadCrumb.adminRoute.companyPage.details',
  },
  {
    path: '/admin/company/:externalId',
    breadcrumb: DynamicEditCompanyBreadcrumb,
  },
  {
    path: '/admin/company',
    breadcrumb: 'breadCrumb.adminRoute.company',
  },

  {
    path: '/inventory',
    breadcrumb: 'breadCrumb.supply',
  },
  {
    path: '/inventory/products',
    breadcrumb: 'breadCrumb.products.list',
  },
  {
    path: '/inventory/products/create',
    breadcrumb: 'breadCrumb.products.create',
  },
  {
    path: '/inventory/products/:externalId',
    breadcrumb: DynamicEditProductTitleBreadcrumb,
  },
  {
    path: '/inventory/products/:externalId/details',
    breadcrumb: 'breadCrumb.products.details',
  },
  {
    path: '/inventory/products/:externalId/finance',
    breadcrumb: 'breadCrumb.products.financial',
  },
  {
    path: '/inventory/products/:externalId/inventory',
    breadcrumb: 'breadCrumb.products.inventory',
  },
  {
    path: '/inventory/groups',
    breadcrumb: 'breadCrumb.products.group.list',
  },
  {
    path: '/inventory/groups/:externalId',
    breadcrumb: DynamicEditSubGroupOnGroupTitleBreadcrumb,
  },
  {
    path: '/inventory/groups/:externalId/details',
    breadcrumb: 'breadCrumb.products.details',
  },
  {
    path: '/inventory/groups/:externalId/subgroups',
    breadcrumb: 'breadCrumb.products.subGroups',
  },
  {
    path: '/inventory/groups/:externalId/products',
    breadcrumb: 'breadCrumb.products.products',
  },
  {
    path: '/inventory/sub-groups',
    breadcrumb: 'breadCrumb.products.subGroup.list',
  },
  {
    path: '/inventory/sub-groups/:externalId',
    breadcrumb: DynamicEditSubGroupProductTitleBreadcrumb,
  },
  {
    path: '/inventory/sub-groups/:externalId/details',
    breadcrumb: 'breadCrumb.products.details',
  },
  {
    path: '/inventory/sub-groups/:externalId/products',
    breadcrumb: 'breadCrumb.products.products',
  },
  {
    path: '/inventory/products/create',
    breadcrumb: 'breadCrumb.products.create',
  },
  {
    path: '/inventory',
    breadcrumb: 'breadCrumb.supply',
  },
  {
    path: '/inventory/supply-places',
    breadcrumb: 'breadCrumb.supplyPlaces.title',
  },
  {
    path: '/inventory/supply-places/create',
    breadcrumb: 'breadCrumb.supplyPlaces.create',
  },
  {
    path: '/inventory/supply-places/:externalId',
    breadcrumb: DynamicEditStorageLocationBreadcrumb,
  },
  {
    path: '/inventory/supply-places/:externalId/:tab',
    breadcrumb: 'breadCrumb.supplyPlaces.detail.title',
  },
  {
    path: '/inventory/losses/create',
    breadcrumb: 'breadCrumb.losses.create',
  },
  {
    path: '/inventory/losses',
    breadcrumb: 'breadCrumb.losses.losses',
  },
  {
    path: '/inventory/losses/:externalId',
    breadcrumb: DynamicEditLosseBreadcrumb,
  },
  {
    path: '/inventory/losses/:externalId/:tab',
    breadcrumb: 'breadCrumb.losses.detail.details',
  },

  {
    path: '/inventory',
    breadcrumb: 'breadCrumb.supply',
  },

  {
    path: '/inventory/lot',
    breadcrumb: 'breadCrumb.supplyRoutes.lots',
  },

  {
    path: '/inventory/lot/create',
    breadcrumb: 'breadCrumb.lots.create',
  },

  {
    path: '/inventory/lot/:externalId',
    breadcrumb: DynamicEditLotBreadcrumb,
  },

  {
    path: '/inventory/lot/:externalId/details',
    breadcrumb: 'breadCrumb.lots.detail.details',
  },

  {
    path: '/inventory/lot/:externalId/stockLocations',
    breadcrumb: 'breadCrumb.lots.detail.stockLocations',
  },

  {
    path: '/inventory/balance-adjustments',
    breadcrumb: 'breadCrumb.balanceAdjustments.list',
  },
  {
    path: '/inventory/balance-adjustments/create',
    breadcrumb: 'breadCrumb.balanceAdjustments.create',
  },
  {
    path: '/inventory/balance-adjustments/:externalId',
    breadcrumb: DynamicEditBalanceAdjustmentBreadcrumb,
  },
  {
    path: '/inventory/balance-adjustments/:externalId/details',
    breadcrumb: 'Detalhes',
  },
  {
    path: '/inventory/transfers',
    breadcrumb: 'breadCrumb.supplyRoutes.transfers',
  },
  {
    path: '/inventory/transfers/create',
    breadcrumb: 'breadCrumb.transfers.create.breadCrumb',
  },
  {
    path: '/inventory/transfers/:externalId',
    breadcrumb: DynamicTransfersBreadcrumb,
  },
  {
    path: '/inventory/transfers/:externalId/details',
    breadcrumb: 'Detalhes',
  },

  {
    path: '/order',
    breadcrumb: 'breadCrumb.purchase',
  },
  {
    path: '/order/purchase',
    breadcrumb: 'breadCrumb.purchasing.purchaseList',
  },
  {
    path: '/purchasing/provider',
    breadcrumb: 'breadCrumb.purchasing.provider.title',
  },
  {
    path: '/purchasing/provider/create',
    breadcrumb: 'breadCrumb.purchasing.provider.create.title',
  },
];
