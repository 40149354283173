import { INumberInput, NumberInput } from '../NumberInput';

export const PercentageInput = (
  props: Omit<
    INumberInput,
    | 'fixedDecimalScale'
    | 'withCurrencyPrefix'
    | 'decimalScale'
    | 'withDecimalSeparator'
    | 'withThousandSeparator'
  >
) => <NumberInput {...props} decimalScale={2} withDecimalSeparator />;
