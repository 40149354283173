import React, { useCallback, useState } from 'react';
import { Link, Skeleton, Text } from '../../../../../..';
import {
  FETCH_GET_PRODUCT,
  useGroupGet,
  useProductGet,
  useProductStatusEdit,
  useSubGroupGet,
} from '../../../../../../../Hooks/Supply/ProductHooks';
import { PermissionWrapper } from '../../../../../../PermissionWrapper';
import { Status } from '../../../Status';
import { useQueryClient } from '@tanstack/react-query';
import { Modal } from '../../../../../../Modal';
import { Divflex } from '../../../../../../../Layouts/DivWhithFlex';
import { useTranslation } from 'react-i18next';

export const DynamicEditSubGroupProductBreadcrumb = ({ match }: any) => {
  const { data } = useSubGroupGet(match.params.externalId);

  if (!data) {
    return (
      <div style={{ width: 200 }}>
        <Skeleton />
      </div>
    );
  }

  return (
    <span style={{ display: 'flex' }}>
      <Link
        type="heading3"
        color="text-300"
        to={`/inventory/groups/${data.grupoExternalId}/details`}
        children={`${data.grupoDescricao} `}
      />
      <Text type="heading3" color="text-300" children={`/ `} />
      {data.subGrupoDescricao}
    </span>
  );
};

export const DynamicEditSubGroupProductTitleBreadcrumb = ({ match }: any) => {
  const { data } = useSubGroupGet(match.params.externalId);

  if (!data) {
    return (
      <div style={{ width: 200 }}>
        <Skeleton />
      </div>
    );
  }

  return <div>{data?.subGrupoDescricao}</div>;
};

export const DynamicEditSubGroupOnGroupTitleBreadcrumb = ({ match }: any) => {
  const { data } = useGroupGet(match.params.externalId);

  if (!data) {
    return (
      <div style={{ width: 200 }}>
        <Skeleton />
      </div>
    );
  }

  return <div>{data.descricao}</div>;
};

export const DynamicEditProductBreadcrumb = ({ match }: any) => {
  const { data } = useProductGet(match.params.externalId);
  const { t } = useTranslation();
  const editProductStatus = useProductStatusEdit();
  const [modalVisible, setModalVisible] = useState(false);
  const [status, setStatus] = useState(undefined);
  const queryClient = useQueryClient();

  const onEditStatus = useCallback(
    async (value: any) => {
      const res = await editProductStatus.mutateAsync({
        produtosExternalId: [match.params.externalId],
        ativo: value,
      });
      if (res.success) {
        queryClient.invalidateQueries([FETCH_GET_PRODUCT]);
      }
    },
    [editProductStatus, match.params.externalId, queryClient]
  );

  if (!data) {
    return (
      <div style={{ width: 100 }}>
        <Skeleton />
      </div>
    );
  }

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {data.descricao}
      <PermissionWrapper
        permission={1005}
        tooltip="noPermission.administrative.users.statusEdit"
      >
        <Status
          type={data?.ativo ? 'success' : 'disabled'}
          ative={data?.ativo || false}
          onClick={(value) => {
            value ? onEditStatus(value) : setModalVisible(true);
            setStatus(value);
          }}
        />
      </PermissionWrapper>
      <Modal
        body={
          <Divflex style={{ padding: '20px' }}>
            <Text
              type="ui-tiny-content"
              children={t('products.edit.status.confirmation')}
            />
            <Text type="ui-tiny-bold" children={`${data?.descricao}`} />
            <Text type="ui-tiny-content" children={'?'} />
          </Divflex>
        }
        title={`${t('products.edit.status.inactivate')} "${data?.descricao}"?`}
        onCancelClick={() => setModalVisible(false)}
        visible={modalVisible}
        onOkClick={() => {
          status !== undefined && onEditStatus(status);
          setModalVisible(false);
        }}
        onClose={() => setModalVisible(false)}
        okButtonName={t('products.edit.status.inactivate')}
      />
    </div>
  );
};

export const DynamicEditProductTitleBreadcrumb = ({ match }: any) => {
  const { data: product } = useProductGet(match.params.externalId);
  return <div>{product?.descricao}</div>;
};
