export const preferencesEN = {
	placeAndTime: 'Place and Time',
	placeAndTimePage: {
		title: 'Change location and time preferences',

		selectLanguage: 'Language',
		selectStandardDate: 'Date pattern',
		selectStandardTime: 'Weather pattern',
		selectTimeZone: 'Timezone',

		button: 'Save',
	},

	accessibility: 'Accessibility',
	accessibilityPage: {
		title: 'System Customization',
		description:
			'Accessibility configurations are available inside Bootys to optimize your experience based on your personal needs',

		amplifyText: 'Amplify Text',
		amplifyBody: 'All text in the system will become amplified',

		boldText: 'Bold Text',
		boldBody: 'All text in the system will become bold',
	},

	theme: 'Theme',
	themePage: {
		title: 'Change theme',
		description: 'Theme Patterns',

		lightMode: 'Light mode',
		midnightMode: 'Midnight mode',
		darkMode: 'Dark mode',
	},
}

export const preferencesPT = {
	placeAndTime: 'Local e Hora',
	placeAndTimePage: {
		title: 'Alterar preferências de local e hora',

		selectLanguage: 'Idioma',
		selectStandardDate: 'Padrão de data',
		selectStandardTime: 'Padrão de tempo',
		selectTimeZone: 'Timezone',

		button: 'Salvar',
	},

	accessibility: 'Acessibilidade',
	accessibilityPage: {
		title: 'Acessibilidade do sistema',
		description:
			'Configurações de acessibilidade estão disponíveis dentro do Bootys para otimizar a sua experiência de acordo com as suas necessidades pessoais.',

		amplifyText: 'Texto Ampliado',
		amplifyBody: 'A dimensão das fontes do sistema serão ampliadas',

		boldText: 'Texto em Negrito',
		boldBody: 'Todos os textos do sistema ficarão em negrito',
	},

	theme: 'Tema',
	themePage: {
		title: 'Alterar tema',
		description: 'Temas padrões',

		lightMode: 'Light mode',
		midnightMode: 'Midnight mode',
		darkMode: 'Dark mode',
	},
}
