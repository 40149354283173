import { Col, Row } from 'antd';
import { useFormikContext } from 'formik';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Tooltip } from '../../../../../Components';
import { IProductResponse } from '../../../../../Data/Interfaces/response/Product/IProductRespose';
import { useUnitMeasurementByClassGetDropdown } from '../../../../../Hooks/Supply/UnitMeasurementHooks';
import { CurrencyInput } from '../../../../../Components/Inputs/CurrencyInput';
import { PercentageInput } from '../../../../../Components/Inputs/PercentageInput';

interface IFinanceSaleCard {
  disabledForm?: boolean;
  product?: IProductResponse;
}

export const FinanceSaleCard: FC<IFinanceSaleCard> = ({
  disabledForm,
  product,
}) => {
  const [cost, setCost] = useState<string>();
  const [price, setPrice] = useState<string>();
  const [profitMargin, setProfitMargin] = useState<string>();

  const form: any = useFormikContext();

  const { data: listunitsMeasurements } = useUnitMeasurementByClassGetDropdown(
    {
      PageIndex: 0,
      PageSize: 10,
    },
    form?.values?.classeProdutoId
  );
  const { t } = useTranslation();

  useEffect(() => {
    const currentFormValorVenda = form?.values?.valorVenda;
    const valorVenda =
      (form?.values.valorCusto * (form?.values.margemLucro + 100)) / 100;

    const hasChanges = currentFormValorVenda !== valorVenda;

    if (hasChanges) {
      form.setFieldValue('valorVenda', valorVenda || form?.values?.valorCusto);
    }
  }, [cost]);

  useEffect(() => {
    const currentFormValorVenda = form?.values?.valorVenda;
    const valorVenda =
      form?.values.valorCusto * (form?.values.margemLucro / 100 + 1);
    const hasChanges = currentFormValorVenda !== valorVenda;

    if (hasChanges) {
      form.setFieldValue('valorVenda', valorVenda || 0);
    }
  }, [profitMargin]);

  useEffect(() => {
    const currentFormMargemLucro = form?.values?.margemLucro;
    const margemLucro =
      (form?.values.valorVenda * 100) / form?.values.valorCusto - 100;
    const hasChanges = currentFormMargemLucro !== margemLucro;

    form?.values.valorCusto > 0 &&
      hasChanges &&
      form.setFieldValue('margemLucro', margemLucro || 0);
  }, [price]);

  useEffect(() => {
    form.resetForm();
  }, [disabledForm]);

  return (
    <>
      <Card title={t('products.create.financeTitle')} withoutCollapse>
        <Row gutter={[16, 0]} justify="start">
          <Col span={4}>
            <Tooltip
              title={`${t('products.toolTips.products.costValue')} ${
                product?.valorCusto
              }`}
              showMe={
                product &&
                product?.valorCusto !== form?.values?.valorCusto &&
                !disabledForm
              }
            >
              <CurrencyInput
                name="valorCusto"
                label={[
                  {
                    children: t('products.create.costPrice'),
                  },
                ]}
                placeHolder={t('products.create.costPricePlaceholder')}
                withoutMarginBottom={true}
                disabled={disabledForm}
                onChange={(x) => setCost(x)}
                required={!product}
                withStatusIcon={!!product}
                supportText={
                  form.values.unidadeEstoqueId
                    ? `/${
                        listunitsMeasurements?.data?.find(
                          (x) => x.id === form.values.unidadeEstoqueId
                        )?.unidadeAbreviacao
                      }`
                    : product && `/${product.unidadeMedida.abreviacao}`
                }
              />
            </Tooltip>
          </Col>
          <Col span={4}>
            <Tooltip
              title={`${t('products.toolTips.products.profitMargin')} ${
                product?.margemLucro
              }`}
              showMe={
                product &&
                product?.margemLucro !== form?.values?.margemLucro &&
                !disabledForm
              }
            >
              <PercentageInput
                name="margemLucro"
                label={[
                  {
                    children: t('products.create.profit'),
                  },
                ]}
                placeHolder={t('products.create.profitPlaceholder')}
                withoutMarginBottom={true}
                disabled={disabledForm}
                onChange={(x) => setProfitMargin(x)}
                required={!product}
                withStatusIcon={!!product}
                suffix="%"
              />
            </Tooltip>
          </Col>

          <Col span={4}>
            <Tooltip
              title={`${t('products.toolTips.products.saleValue')} ${
                product?.valorVenda
              }`}
              showMe={
                product &&
                product?.valorVenda !== form?.values?.valorVenda &&
                !disabledForm
              }
            >
              <CurrencyInput
                name="valorVenda"
                label={[
                  {
                    children: t('products.create.sellPrice'),
                  },
                ]}
                placeHolder={t('products.create.sellPricePlaceholder')}
                withoutMarginBottom={true}
                disabled={disabledForm}
                onChange={(x) => setPrice(x)}
                required={!product}
                withStatusIcon={!!product}
                supportText={
                  form.values.unidadeEstoqueId
                    ? `/${
                        listunitsMeasurements?.data?.find(
                          (x) => x.id === form.values.unidadeEstoqueId
                        )?.unidadeAbreviacao
                      }`
                    : product && `/${product.unidadeMedida.abreviacao}`
                }
              />
            </Tooltip>
          </Col>
        </Row>
      </Card>
    </>
  );
};
