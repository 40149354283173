import React, { FC } from 'react';
import { Skeleton as AntdSkeleton } from 'antd';

import styles from './Skeleton.module.scss';
import './Skeleton.override.scss';

export interface ISkeleton {
  size?: 'md' | 'lg';
}

export const Skeleton: FC<ISkeleton> = ({ size }) => {
  return (
    <AntdSkeleton
      className={`${styles['skeleton']} ${size && styles[`size-${size}`]}`}
      active
    />
  );
};
