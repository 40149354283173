import { IGetUserResponse } from '../../../Data/Interfaces/response/User/IGetUserReponse'
import { Themes } from '../../../Utils/ThemeUtils'

export const receiveDarkmode = (
	preference: any,
	user: IGetUserResponse | undefined
) => {
	// retorna o tema que está no local storage, se não exisitir, retorna o tema recebido pelo get do user
	return preference
		? preference.temaUsuario
		: user
		? user.preferencias.temaUsuario
		: Themes.lightMode
}
