import React, { FC, ReactNode, useCallback, useEffect, useState } from 'react';
import { Select, Spin } from 'antd';
import { Field } from 'formik';
import { IField } from '../interfaces/IField';
import { InputLabel } from '../Label';
import { LocalizaPropriedadePorPath } from '../../../Utils/FormikUtils';

import styles from './Dropdown.module.scss';
import './Dropdown.override.scss';
import { Icon, Text, Tooltip } from '../..';
import { useTranslation } from 'react-i18next';
import Spinner from '../../Spinner';
import { Flag } from '../../Flag';
import { TextType } from '../../Text';
import { Color } from '../../../Utils/ColorUtils';
import { IUserInfo } from '../../HeaderMenu/Components/UserInfo';
import { Avatar } from '../../Avatar';
import { getScrollPercentage } from '../../../Utils/ScrollUtils';
import { Divflex } from '../../../Layouts/DivWhithFlex';
import { IconName } from '../../Icon/IconClasses';
import { uniqBy } from 'lodash';

export interface IInputLabel {
  children: string;
  typeLabel?: TextType;
  colorLabel?: Color;
}

interface ISimpleAvatar {
  name: string;
  lastName?: string;
}

export interface IDropdown {
  items:
    | {
        id: any;
        name?: string;
        code?: string;
        img?: ReactNode;
        avatar?: IUserInfo;
        simpleAvatar?: ISimpleAvatar;
      }[]
    | undefined;
  name: string;
  label?: IInputLabel[] | string;
  placeHolder?: string;

  onFocus?: (event: React.FocusEvent<HTMLElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLElement>) => void;
  onChange?: (value: string, option: any) => void;
  onScrollEnd?: () => void;

  className?: string;

  withoutMarginBottom?: boolean;

  withTooltip?: {
    title: string;
    icon: IconName;
  };

  required?: boolean;
  autoIncrementBy?: string;

  width?: string;
  disabled?: boolean;
  isLoading?: boolean | undefined;

  withStatusIcon?: boolean;
  value?: string;

  nameValue?: boolean;
}

const { Option } = Select;

export const Dropdown: FC<IDropdown> = ({
  items,
  name,
  label,
  withoutMarginBottom,
  withTooltip,
  required,
  width,
  placeHolder,
  isLoading,
  disabled,
  autoIncrementBy,
  onChange,
  onScrollEnd,
  withStatusIcon,
  value,
  nameValue,
}) => {
  const [autoIncrement, setAutoIncrement] = useState(items || []);
  const [open, setOpen] = useState(false);

  const { t } = useTranslation();

  const filtrarDados = (input: any, option: any): boolean => {
    return option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  };

  useEffect(() => {
    if (!autoIncrementBy) return;

    const allItems = [...(autoIncrement || []), ...(items || [])];
    const uniqueItems = uniqBy(allItems, autoIncrementBy);
    if (uniqueItems.length === autoIncrement.length) return;

    setAutoIncrement(uniqueItems);
  }, [autoIncrementBy, autoIncrement, items]);

  const onScroll = useCallback(
    (event: React.UIEvent<HTMLElement>) => {
      const scrollPercentage = getScrollPercentage(event);
      const scrollPercentageThreshold = 80;

      // Call hook when you scroll to 80% or more
      if (scrollPercentage > scrollPercentageThreshold && !isLoading) {
        onScrollEnd && onScrollEnd();
      }
    },
    [onScrollEnd, isLoading]
  );

  return (
    <div className={styles['Dropdown']}>
      <Field name={name}>
        {(props: IField) => {
          const onChangeFunc = (value: string, option: any) => {
            if (nameValue)
              props.form.setFieldValue(name, {
                value: option.children.props.children[1].props.children,
                id: value,
              });
            else props.form.setFieldValue(name, value);
            if (onChange) onChange(value, option);
          };

          const onMouseLeave = (event: any) => {
            if (
              event.target &&
              event.target.classList.contains('ant-select-item-option')
            ) {
              setOpen(false);
            }
          };

          return (
            <>
              <Divflex>
                <InputLabel
                  label={label}
                  required={required}
                  withStatusIcon={withStatusIcon}
                  props={props}
                />
                {withTooltip && (
                  <Tooltip
                    title={withTooltip.title}
                    showMe
                    children={
                      <Icon
                        className={styles['icon-with-tooltip']}
                        icon={withTooltip.icon}
                        color="text-300"
                        size="S"
                      />
                    }
                  />
                )}
              </Divflex>
              <div style={{ display: 'flex' }}>
                <div
                  style={{
                    maxWidth: width,
                    minWidth: width,
                  }}
                  className={`${
                    items && items.find((item) => item.img) ? 'with-flag' : ''
                  } ${styles['divDropdown']} ${
                    withoutMarginBottom ? '' : styles['margin-bottom']
                  }`}
                >
                  {items && items.find((item) => item.img) && (
                    <div className={styles['div-flag']} />
                  )}
                  <Select
                    onPopupScroll={onScroll}
                    disabled={disabled || props.form.isSubmitting}
                    value={props.field.value || value}
                    style={{ width: width || 200 }}
                    placeholder={placeHolder || t('dropDown.placeHolder')}
                    open={open}
                    onChange={onChangeFunc}
                    onKeyUp={() => setOpen(true)}
                    onClick={() => setOpen(!open)}
                    onBlur={(e) => {
                      setOpen(false);
                      props.field.onBlur(e);
                    }}
                    onSelect={() => setOpen(false)}
                    onMouseLeave={onMouseLeave}
                    filterOption={filtrarDados}
                    className={`bootys-dropdown ${
                      isLoading ? 'dropdown-is-loading' : ''
                    }`}
                    allowClear={true}
                    suffixIcon={
                      <Icon icon="chevron-down" color="text-50" size="M" />
                    }
                    notFoundContent={
                      isLoading ? <Spin tip="Buscando..."></Spin> : undefined
                    }
                    getPopupContainer={(trigger) => trigger.parentElement}
                  >
                    {!isLoading && items && items.length === 0 ? (
                      <Text
                        className={'dropdown-empty'}
                        type="small-text"
                        color="text-400"
                        children={t('dropDown.empty')}
                      />
                    ) : (
                      (autoIncrementBy ? autoIncrement || [] : items || []).map(
                        (x, index) =>
                          x.avatar ? (
                            <Option
                              className={`${styles['option']} ${styles['option-avatar']}`}
                              key={`dropdown-${index}`}
                              value={x.id}
                            >
                              <div className={`${styles['divContent']}`}>
                                <Avatar
                                  fisrtName={x.avatar.name}
                                  lastName={x.avatar.lastName}
                                  size="SM"
                                  type="paragraph2-bold"
                                  color="white"
                                />
                                <div className={styles['div-avatar']}>
                                  <Text
                                    type="ui-sub-content"
                                    color="text-50"
                                    children={`${x.avatar.name} ${
                                      x.avatar.lastName || ''
                                    }`}
                                  />
                                  <Text
                                    type="small-text"
                                    color="text-400"
                                    children={x.avatar.email}
                                  />
                                </div>
                              </div>
                            </Option>
                          ) : x.simpleAvatar ? (
                            <Option
                              className={`${styles['option']} ${styles['option-avatar']}`}
                              key={`dropdown-${index}`}
                              value={x.id}
                            >
                              <div className={`${styles['divContent']}`}>
                                <Avatar
                                  fisrtName={x.simpleAvatar.name}
                                  lastName={x.simpleAvatar.lastName}
                                  size="SM"
                                  type="paragraph2-bold"
                                  color="white"
                                />
                                <div className={styles['div-avatar']}>
                                  <Text
                                    className="text-input-simple-avatar"
                                    type="ui-sub-content"
                                    color="text-50"
                                    children={x.simpleAvatar.name}
                                  />
                                </div>
                              </div>
                            </Option>
                          ) : x.code ? (
                            <Option
                              className={styles['option']}
                              key={`dropdown-${index}`}
                              value={x.id}
                            >
                              <div
                                className={`${styles['divContentWithCode']}`}
                              >
                                {x.img && <Flag image={x.img} />}
                                <Text
                                  type="ui-tiny-content"
                                  color="text-50"
                                  children={x.name}
                                />
                                <Text
                                  type="small-text"
                                  color="text-300"
                                  children={x.code}
                                />
                              </div>
                            </Option>
                          ) : (
                            <Option
                              className={styles['option']}
                              key={`dropdown-${index}`}
                              value={x.id}
                            >
                              <div className={`${styles['divContent']}`}>
                                {x.img && <Flag image={x.img} />}
                                <Text
                                  type="ui-sub-content"
                                  color="text-50"
                                  children={x.name}
                                />
                              </div>
                            </Option>
                          )
                      )
                    )}
                  </Select>
                  {isLoading && (
                    <div className={styles['spinner']}>
                      <Spinner />
                    </div>
                  )}

                  {props.form &&
                  props.form.errors &&
                  LocalizaPropriedadePorPath(props.form.errors, name) ? (
                    <p className={styles['errorText']}>
                      {LocalizaPropriedadePorPath(props.form.errors, name)}
                    </p>
                  ) : null}
                </div>
              </div>
            </>
          );
        }}
      </Field>
    </div>
  );
};
