import { IPreferences } from '../Data/Interfaces/response/User/IGetUserReponse';

class StorageUtils {
  REMEMBER_ME_KEY = 'REMEMBER_ME';
  PREFERENCE_KEY = 'USER_PREFERENCE';

  public SetItem(key: string, value: string) {
    localStorage.setItem(key, value);
  }

  public GetItem(key: string): string | null {
    return localStorage.getItem(key);
  }

  public RemoveItem(key: string) {
    localStorage.removeItem(key);
  }

  public SetRememberMe(rememberMe: boolean) {
    localStorage.setItem(this.REMEMBER_ME_KEY, rememberMe.toString());
  }

  public SetPreference(preference: string) {
    localStorage.setItem(this.PREFERENCE_KEY, preference);
  }
  public getPreference(): IPreferences {
    const preferences = localStorage.getItem(this.PREFERENCE_KEY);
    return preferences !== 'undefined' && preferences
      ? JSON.parse(preferences)
      : undefined;
  }
}

const instance = new StorageUtils();
export default instance;
