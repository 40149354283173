import { call, put } from 'redux-saga/effects';
import { i18n } from '../../Components';
import { ConvertDate } from '../../Utils/DateUtils';
import {
  messageErrorToast,
  messageSucessToast,
} from '../../Utils/MessageErrorToast';
import { keepDataStorage } from '../../Utils/ThemeUtils';
import {
  ChangePasswordRequestAction,
  PatchMeRequestAction,
  UserActions,
  GetUserRequestAction,
  UpdatePermissionAction,
} from './User.actions';
import { ChangeLanguage } from '../../Utils/ChangeLanguageUtils';
import { USER_PREFERENCES_CACHE } from './User.selector';
import StorageUtils from '../../Utils/StorageUtils';
import { UserAPI } from '../../Data/API/Accountmanager/UserApi';

export function* getMe() {
  try {
    const { data } = yield call(UserAPI.getCurrentUser);
    if (data.preferencias)
      data.dataNascimento = ConvertDate(
        data.dataNascimento,
        data.preferencias.padraoData
      );
    yield put(UserActions.getMeSuccess(data));
    StorageUtils.SetItem(
      USER_PREFERENCES_CACHE,
      JSON.stringify(data.preferencias)
    );
  } catch (error: any) {
    yield put(UserActions.getMeFailure(error.message));
  }
}

export function* getUser({ payload }: GetUserRequestAction) {
  try {
    const { data } = yield call(UserAPI.getCurrentUser, payload);
    data.dataNascimento = ConvertDate(
      data.dataNascimento,
      data.preferencias.padraoData
    );
    yield put(UserActions.getUserSuccess(data));
  } catch (error: any) {
    yield put(UserActions.getUserFailure(error.message));
  }
}

export function* patchMe({ payload }: PatchMeRequestAction) {
  try {
    yield call(UserAPI.patchCurrentUser, payload);
    yield put(UserActions.setLoading(false));
    keepDataStorage(payload);

    if (payload.data.idioma) ChangeLanguage(payload.data.idioma);

    messageSucessToast(i18n.t('common.toastMessages.updatedPreferenceUser'));
    payload.callback && payload.callback();
    window.location.reload();
    yield getMe();
  } catch (error: any) {
    messageErrorToast(error);

    yield put(UserActions.patchMeFailure(error.message));
  } finally {
    payload.helpers?.setSubmitting(false);
  }
}

export function* changePassword({ payload }: ChangePasswordRequestAction) {
  try {
    yield call(UserAPI.chagePassword, payload);
    yield put(UserActions.setLoading(false));
    messageSucessToast(i18n.t('common.toastMessages.changePassword'));
  } catch (error: any) {
    messageErrorToast(error);

    yield put(UserActions.changePasswordFailure(error.message));
  } finally {
    payload.helpers.setSubmitting(false);
    payload.helpers.resetForm();
  }
}

export function* updatePermission({ payload }: UpdatePermissionAction) {
  try {
    yield call(UserAPI.updatePermission, payload);

    messageSucessToast(i18n.t('admin.editUserPage.permission.sucessAlert'));
  } catch (error: any) {
    if (error.response.status === 400) {
      yield put(
        UserActions.showDependencyModal({
          data: error?.response?.data?.data,
          request: payload,
        })
      );
    } else {
      messageErrorToast(error);

      yield put(UserActions.patchUserStatusFailure(error.message));
    }
  } finally {
    yield put(UserActions.setLoading(false));
    payload.callback && payload.callback();
  }
}
