import { FC } from 'react';
import { PageLoading } from '../../../../Layouts/PageLoading';
import { BalanceAdjustmentCard } from './Cards/BalanceAdjustmentCard';
import { InfoBalanceAdjustmentCard } from './Cards/InfoCard';

export const BalanceAdjustmentDetails: FC = () => {
  return (
    <PageLoading>
      <InfoBalanceAdjustmentCard />
      <BalanceAdjustmentCard />
    </PageLoading>
  );
};

export default BalanceAdjustmentDetails;
