export enum PermissionEnum {
  Administrativo_Usuarios_VerLista = 1001,
  Administrativo_Usuarios_VerDetalhes = 1002,
  Administrativo_Usuarios_Cadastrar = 1003,
  Administrativo_Usuarios_EditarDadosPessoais = 1004,
  Administrativo_Usuarios_AlterarStatus = 1005,
  Administrativo_Usuarios_EditarGrupo = 1006,
  Administrativo_Usuarios_EditarPermissao = 1007,
  Administrativo_Usuarios_Excluir = 1008,
  Administrativo_GrupoUsuarios_VerLista = 1009,
  Administrativo_GrupoUsuarios_VerDetalhes = 1010,
  Administrativo_GrupoUsuarios_Criar = 1011,
  Administrativo_GrupoUsuarios_EditarDetalhes = 1012,
  Administrativo_GrupoUsuarios_AlterarStatus = 1013,
  Administrativo_GrupoUsuarios_EditarPermissao = 1014,
  Administrativo_GrupoUsuarios_Excluir = 1015,
  Administrativo_Empresa_VerLista = 1016,
  Administrativo_Empresa_VerDetalhes = 1017,
  Administrativo_Empresa_EditarDados = 1018,
  Administrativo_Empresa_EditarEndereco = 1019,
  Administrativo_Empresa_EditarUsuarioResponsavel = 1020,
  Administrativo_Empresa_AlterarStatus = 1021,

  Estoque_Lotes_VerLista = 2001,
  Estoque_Lotes_VerDetalhes = 2002,
  Estoque_Lotes_Cadastrar = 2003,
  Estoque_Lotes_EditarInformacoes = 2004,
  Estoque_Lotes_AlterarStatus = 2005,
  Estoque_Lotes_Excluir = 2006,

  Estoque_LocaisEstoque_VerLista = 2007,
  Estoque_LocaisEstoque_VerDetalhess = 2008,
  Estoque_LocaisEstoque_Cadastrar = 2009,
  Estoque_LocaisEstoque_EditarDetalhes = 2010,
  Estoque_LocaisEstoque_AlterarStatus = 2011,
  Estoque_LocaisEstoque_Excluir = 2012,
  Estoque_Transferencias_VerLista = 2013,
  Estoque_Transferencias_VerDetalhes = 2014,
  Estoque_Transferencias_Cadastrar = 2015,
  Estoque_Perdas_VerLista = 2016,
  Estoque_Perdas_VerDetalhes = 2017,
  Estoque_Perdas_Cadastrar = 2018,
  Estoque_Perdas_EditarDetalhes = 2019,
  Estoque_AjusteSaldo_VerLista = 2020,
  Estoque_AjusteSaldo_VerDetalhes = 2021,
  Estoque_AjusteSaldo_Cadastrar = 2022,

  Produtos_Produtos_VerLista = 3001,
  Produtos_Produtos_VerDetalhes = 3002,
  Produtos_Produtos_Cadastrar = 3003,
  Produtos_Produtos_EditarInformacoes = 3004,
  Produtos_Produtos_EditarInformacoesFinanceiras = 3005,
  Produtos_Produtos_AlterarStatus = 3006,
  Produtos_Produtos_Excluir = 3007,
  Produtos_Grupos_VerLista = 3008,
  Produtos_Grupos_VerDetalhes = 3009,
  Produtos_Grupos_Cadastrar = 3010,
  Produtos_Grupos_EditarDetalhes = 3011,
  Produtos_Grupos_Excluir = 3012,
  Produtos_SubGrupos_VerLista = 3013,
  Produtos_SubGrupos_VerDetalhes = 3014,
  Produtos_SubGrupos_Cadastrar = 3015,
  Produtos_SubGrupos_EditarDetalhes = 3016,
  Produtos_SubGrupos_Excluir = 3017,

  Compras_Fornecedores_VerLista = 4001,
  Compras_Fornecedores_VerDetalhes = 4002,
  Compras_Fornecedores_Cadastrar = 4003,
  Compras_Fornecedores_EditarDetalhes = 4004,
  Compras_Fornecedores_EditarContatos = 4005,
  Compras_Fornecedores_EditarEndereco = 4006,
  Compras_Fornecedores_EditarDocumento = 4007,
  Compras_Fornecedores_AlterarStatus = 4008,
  Compras_Fornecedores_Excluir = 4009,
}
