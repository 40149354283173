import { useQueryClient } from '@tanstack/react-query';
import React from 'react';
import { Dispatch, FC } from 'react';
import { i18n } from '../../../../../Components';
import { Modal } from '../../../../../Components/Modal';
import { FooterThisActionInitialStockBalance } from '../../../../../Components/Table/components/Modals/DeleteModal';
import { IGetLotResponse } from '../../../../../Data/Interfaces/response/Lot/IListLot';
import {
  FETCH_LIST_LOTS,
  useLotDelete,
} from '../../../../../Hooks/Supply/LotHooks';
import { handleModalError } from '../../../../../Utils/ErrorHandlingUtils';
import { history } from '../../../../../Store';
import { App } from 'antd';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';

interface IDeleteLotModal {
  dispatch: Dispatch<any>;
  lot?: IGetLotResponse;
  visibleModal: boolean;
  changeVisibleModal: (value: boolean) => void;
  content?: any[];
}

export const DeleteLotModal: FC<IDeleteLotModal> = ({
  lot,
  visibleModal,
  changeVisibleModal,
}) => {
  const app = App.useApp();
  const queryClient = useQueryClient();
  const deletLot = useLotDelete();
  const { t } = useTranslation();

  return (
    <Modal
      title={`${i18n.t('common.delete')} ${i18n.t('common.theLot')} "${
        lot?.numero
      }"?`}
      body={
        <div style={{ padding: '20px', maxWidth: 410 }}>
          <ReactMarkdown>
            {t('modal.deleteModal.single.question', {
              numero: `**${lot?.numero}**`,
              produto: `**${lot?.produto}**`,
            })}
          </ReactMarkdown>
          <FooterThisActionInitialStockBalance />
        </div>
      }
      okButtonColor="status-danger-base"
      visible={visibleModal}
      onOkClick={async () => {
        changeVisibleModal(false);
        if (lot) {
          const res = await deletLot.mutateAsync(
            {
              lotesExternalId: [lot?.externalId] as any,
            },
            {
              onError: handleModalError(app),
            }
          );
          if (res.success) {
            queryClient.invalidateQueries([FETCH_LIST_LOTS]);
            history.push(`/inventory/lot`);
          }
        }
      }}
      okButtonName={i18n.t('common.delete')}
      onCancelClick={() => changeVisibleModal(false)}
      onClose={() => changeVisibleModal(false)}
    />
  );
};
