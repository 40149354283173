import React from 'react';
import InputMask from 'react-input-mask';
import { IMask, Mask } from '../../../Utils/MasksUtils';
import { ITextInput } from '../TextInput';
import { Input } from 'antd';
import { Field } from 'formik';
import { IField } from '../interfaces/IField';
import { InputLabel } from '../Label';
import { Icon } from '../..';
import { LocalizaPropriedadePorPath } from '../../../Utils/FormikUtils';

import './TextInput.override.scss';
import styles from './TextInput.module.scss';
import { Loading } from '../../Loading';

export interface ITextInputWitchMask extends ITextInput {
  mask?: IMask;
  onBlur?: (value: string) => void;
  loading?: boolean;
  customMask?: string;
}

export const TextInputWithMask: React.FC<ITextInputWitchMask> = ({
  mask,
  name,
  label,
  required,
  withoutMarginBottom,
  nulable,
  placeHolder,
  error,
  success,
  rightIcon,
  leftIcon,
  disabled,
  className,
  onBlur,
  loading,
  customMask,
  onChange,
}) => {
  return (
    <div
      className={`${withoutMarginBottom ? '' : styles['margin-bottom']} ${
        styles['Container']
      }`}
    >
      <InputLabel label={label} required={required} />
      <Field name={name}>
        {(props: IField) => {
          const hasError =
            error ||
            !!(
              props.form &&
              props.form.errors &&
              props.meta.touched &&
              LocalizaPropriedadePorPath(props.form.errors, name)
            );

          const classNames = className;

          const onPointerLeave = (event: any) => {
            if (nulable && (event.target === null || event.target.value === ''))
              props.form.setFieldValue(name, null);
          };
          return (
            <div
              className={`
              	${styles['input-container']}
              	${classNames}
              	${leftIcon ? styles['with-left-icon'] : null}
              	${
                  rightIcon || success || hasError
                    ? styles['with-right-icon']
                    : null
                } ${hasError ? styles['error'] : null}
              	${success ? styles['success'] : null}`}
            >
              {leftIcon ? (
                <Icon
                  className={styles['Left-Icon']}
                  icon={leftIcon}
                  size="M"
                  color="text-50"
                />
              ) : null}
              <InputMask
                {...props.field}
                onBlur={(x) => {
                  onBlur && onBlur(props.field.value);
                  props.field && props.field.onBlur(x);
                }}
                mask={customMask ? customMask : Mask(mask ? mask : 'cep')}
                disabled={disabled || props.form.isSubmitting}
                placeholder={placeHolder}
                onChange={(x) => {
                  onChange && onChange(x.target.value);
                  props.field && props.field.onChange(x);
                }}
                children={
                  <Input
                    className={styles['TextInput']}
                    placeholder={placeHolder}
                    onPointerLeave={onPointerLeave}
                    disabled={disabled || props.form.isSubmitting}
                  />
                }
              />

              {hasError ? (
                <Icon
                  className={styles['Icon']}
                  icon="exclamation"
                  size="M"
                  color="status-danger-base"
                />
              ) : null}

              {success ? (
                <Icon
                  className={styles['Icon']}
                  icon="check"
                  size="M"
                  color="status-success-base"
                />
              ) : null}

              {rightIcon?.icon ? (
                <Icon
                  className={styles['Icon']}
                  icon={rightIcon.icon}
                  size="M"
                  color="text-50"
                />
              ) : null}
              {loading ? <Loading className={styles['loading']} /> : null}
              {props.form &&
              props.form.errors &&
              LocalizaPropriedadePorPath(props.form.errors, name) ? (
                <p className={styles['errorText']}>
                  {LocalizaPropriedadePorPath(props.form.errors, name)}
                </p>
              ) : null}
            </div>
          );
        }}
      </Field>
    </div>
  );
};
