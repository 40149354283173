import React from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from '../../../../../../Components';
import { Modal } from '../../../../../../Components/Modal';
import { IGetUserResponse } from '../../../../../../Data/Interfaces/response/User/IGetUserReponse';
import { useUserDeleteMutation } from '../../../../../../Hooks/Accountmanager/UserHooks';
import { Divflex } from '../../../../../../Layouts/DivWhithFlex';

import styles from './DeleteUserModal.module.scss';

interface IEditModal {
  visible: boolean;
  user: IGetUserResponse;
  onCancelClick: () => void;
}

export const DeleteUserModal: React.FC<IEditModal> = ({
  visible,
  user,
  onCancelClick,
}) => {
  const { t } = useTranslation();
  const deleteUser = useUserDeleteMutation({
    onSuccess: () => onCancelClick(),
  });

  const onDelete = async () => {
    await deleteUser.mutateAsync([user.externalId]);
  };

  return (
    <Modal
      onOkClick={() => onDelete()}
      title={`${t('admin.editUserPage.modal.deleteUserModal.title')} “${
        user.nome
      } ${user.sobrenome}”?`}
      body={
        <div className={styles['content']}>
          <Divflex>
            <Text
              type="paragraph2"
              children={t(
                'admin.editUserPage.modal.deleteUserModal.initialDescription'
              )}
              color="text-50"
            />
            <Text
              type="paragraph2-bold"
              children={` ${user.nome} ${user.sobrenome}?`}
              color="text-50"
            />
          </Divflex>
          <Text
            type="paragraph2"
            children={t(
              'admin.editUserPage.modal.deleteUserModal.finalDescription'
            )}
            color="text-50"
          />
        </div>
      }
      visible={visible}
      onCancelClick={onCancelClick}
      htmlType="submit"
      okButtonName={t('admin.editUserPage.modal.deleteUserModal.inativeButton')}
      okButtonColor="status-danger-base"
      onClose={onCancelClick}
      minWidth={400}
      loadingOkButton={deleteUser.isLoading}
    />
  );
};
