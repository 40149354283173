import React from 'react';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { history } from '../../../../../Store';
import { Button } from '../../../../../Components';
import styles from './CompanyForm.module.scss';
import { isFormError } from '../../../../../Utils/ErrorUtils';

interface IFooterButton {
  onClick: (value: boolean) => void;
  loading: boolean;
}

export const FooterButton: React.FC<IFooterButton> = ({ onClick, loading }) => {
  const form: any = useFormikContext();
  const { t } = useTranslation();

  return (
    <div className={styles['footer-button']}>
      <Button
        type="secondary"
        children={t('adminBootys.createCompany.footerButton.cancel')}
        onClick={() => history.push('/admin-bootys/company')}
      />
      <Button
        type="primary"
        children={t('adminBootys.createCompany.footerButton.submit')}
        htmlType="submit"
        disabled={isFormError(form?.errors)}
        onClick={() => onClick(true)}
        loading={loading}
      />
    </div>
  );
};
