import React, { useState, FC } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { IPagination, Table } from '../../../../../Components/Table';
import { subGroupCollums } from './SubGroupForm.columns';
import { Text } from '../../../../../Components';
import { useParams } from 'react-router-dom';
import { DeleteModalOnTable } from '../../../../../Utils/DeleteModalUtils';
import { FooterThisActionIsPermanent } from '../../../../../Components/Table/components/Modals/DeleteModal';
import { Divflex } from '../../../../../Layouts/DivWhithFlex';
import { ContainerPage } from '../../../../../Layouts/ContainerPage';
import {
  FETCH_LIST_SUBGROUPS_GROUP,
  useGroupGet,
  useGroupGetSubgroupsList,
  useSubGroupDelete,
} from '../../../../../Hooks/Supply/ProductHooks';
import { useQueryClient } from '@tanstack/react-query';
import { CreateSubGroupModal } from './Modal/subgroupModal';
import { PageLoading } from '../../../../../Layouts/PageLoading';

export const SubGroupForm: React.FC = () => {
  const [selectedRowsOnTable, setSelectedRowsOnTable] = useState<any>([]);
  const [visibleModal, setVisibleModal] = useState(false);
  const [visibleEditModal, setVisibleEditModal] = useState(false);
  const [pagination, setPagination] = useState<IPagination>({
    PageIndex: 0,
    PageSize: 10,
    Sorter: { column: 'descricao', direction: 'ASC' },
  });

  const { t } = useTranslation();

  const abbreviatedI18n = 'products.subGroupList';

  const deleteSubGroup = useSubGroupDelete();

  const { externalId } = useParams<{ externalId: string }>();
  const { data: subGroupList, isLoading } = useGroupGetSubgroupsList(
    pagination,
    externalId
  );
  const { data: productGroup } = useGroupGet(externalId);

  const queryClient = useQueryClient();

  return (
    <>
      <PageLoading loading={isLoading}>
        <ContainerPage>
          <Table
            columns={subGroupCollums(t)}
            data={
              subGroupList?.data?.map((x) => ({
                key: `table_subGroup_${x.externalId}`,
                ...x,
              })) || []
            }
            headerOptions={{
              hasSearch: true,
              newButton: {
                label: t(`${abbreviatedI18n}.newButton`),
                onClick: () => setVisibleModal(true),
                permission: {
                  permission: 3015,
                  tooltip: 'noPermission.supply.subGroups.create',
                },
              },
              deleteButton: {
                permission: {
                  permission: 3012,
                  tooltip: 'noPermission.supply.groups.delete',
                },
              },
              editButton: {
                editButtonWithoutDropdownClick: () => setVisibleEditModal(true),
                ...(selectedRowsOnTable?.length === 1 && {
                  editButtonWithoutDropdown: true,
                }),
              },

              searchPlaceholder: t(`${abbreviatedI18n}.placeholder`),
              nameKey: 'descricao',
            }}
            selectedRowsOnTable={(x) => setSelectedRowsOnTable(x)}
            loading={isLoading}
            hasSelection={true}
            pagination={
              subGroupList
                ? {
                    Count: subGroupList.count,
                    PageIndex: subGroupList.pageIndex,
                    PageSize: subGroupList.pageSize,
                  }
                : false
            }
            onDelete={(subGroup) =>
              subGroup.map(async (x) => {
                const res = await deleteSubGroup.mutateAsync({
                  subGruposExternalId: subGroup.map((x) => x?.externalId),
                });
                if (res.success)
                  queryClient.invalidateQueries([FETCH_LIST_SUBGROUPS_GROUP]);
              })
            }
            onRefetch={(pagination) => {
              setPagination(pagination);
            }}
            cleanSelectedRows={!!visibleEditModal}
            deleteModal={{
              title:
                selectedRowsOnTable?.length === 1
                  ? `${t('common.delete')} ${t('common.subGroup')} "${
                      selectedRowsOnTable[0]?.descricao
                    }"?`
                  : `${t('common.delete')} ${selectedRowsOnTable?.length} ${t(
                      'common.subGroups'
                    )}?`,
              body:
                selectedRowsOnTable?.length > 1 ? (
                  DeleteModalOnTable(
                    selectedRowsOnTable,
                    `${t('modal.deleteModal.many.title')} ${t(
                      'common.theSubGroups'
                    )}:`,
                    'descricao',
                    t('common.subGroups')
                  )
                ) : (
                  <DeleteModalBody
                    selectedRowsOnTable={selectedRowsOnTable}
                    t={t}
                  />
                ),
              buttons: {
                okButtonColor: 'status-danger-base',
              },
            }}
          />
          {productGroup && (
            <CreateSubGroupModal
              changeVisible={(x) => setVisibleModal(x)}
              visible={visibleModal}
              group={{
                externalId: productGroup.externalId,
                groupName: productGroup.descricao,
              }}
            />
          )}
          {productGroup && (
            <CreateSubGroupModal
              changeVisible={(x) => setVisibleEditModal(x)}
              visible={visibleEditModal}
              group={{
                externalId: productGroup.externalId,
                groupName: productGroup.descricao,
              }}
              title={`${t('common.edit')} "${
                selectedRowsOnTable && selectedRowsOnTable[0]?.descricao
              }"`}
              selectedRowsOnTable={selectedRowsOnTable}
            />
          )}
        </ContainerPage>
      </PageLoading>
    </>
  );
};

interface IDeleteModalBody {
  selectedRowsOnTable: any;
  t: TFunction;
}

const DeleteModalBody: FC<IDeleteModalBody> = ({ selectedRowsOnTable, t }) => (
  <div style={{ padding: '20px' }}>
    <Divflex>
      <Text
        type="ui-tiny-content"
        children={`${t('modal.deleteModal.single.question')} ${t(
          'common.theSubGroup'
        )} `}
      />
      <Text
        type="ui-tiny-bold"
        children={`${
          selectedRowsOnTable ? selectedRowsOnTable[0]?.descricao : ''
        }`}
        style={{ marginBottom: '15px' }}
      />
      <Text type="ui-tiny-content" children={'?'} />
    </Divflex>
    <FooterThisActionIsPermanent />
  </div>
);
