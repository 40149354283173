import { TypeLanguages } from '../Components/i18n';
import { IGetConglomerateResponse } from '../Data/Interfaces/response/Conglomerate/IConglomerateResponde';
import { IGetUserResponse } from '../Data/Interfaces/response/User/IGetUserReponse';

export const CreateInitialValuesForPlaceAndTime = (
  user: IGetUserResponse | undefined
) => {
  if (user?.preferencias) {
    const { timeZone, idioma, padraoHora, padraoData } = user.preferencias;

    const initialValues = {
      timeZone,
      idioma: TypeLanguages[idioma ? idioma : 'pt-BR'],
      padraoData,
      padraoHora,
    };

    return initialValues;
  }
  return undefined;
};

export const CreateInitialValuesForUserGroups = () => {
  const initialValues = {
    nome: '',
    descricao: '',
    usuarios: [],
  };

  return initialValues;
};

export const CreateInitialValuesForConglomerate = (
  conglomerate?: IGetConglomerateResponse
) => {
  if (conglomerate) {
    const index = conglomerate?.empresas.findIndex(
      (x) => x.tipoEmpresaId === 1
    );
    const initialValues = {
      conglomeradoExternalId: conglomerate?.externalId,
      nome: conglomerate?.nome,
      empresaExternalIdMatriz:
        index !== -1 ? conglomerate?.empresas[index].externalId : '',
      ativo: conglomerate?.ativo,
      dataInclusao: conglomerate?.dataInclusao,
    };

    return initialValues;
  } else return {};
};
