import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HorizontalTabs } from '../../../../Components';
import { ChangePasswordModal } from './Modals/ConfirmStatusChangeModal';
import { ConglomerateEditForm } from './Tabs/Details';
import { useParams } from 'react-router';
import {
  DeleteModalBody,
  getDeleteModalTitle,
} from '../List/Modals/DeleteModal';
import { Modal } from '../../../../Components/Modal';
import { useConglomerateGetType } from '../../../../Hooks/Accountmanager/ConglomerateHooks';
import { PageLoading } from '../../../../Layouts/PageLoading';
import { CompanyAdminListTab } from './Tabs/Company';

export const ConglomerateForm: React.FC = () => {
  const [disabled, setDisabled] = useState(true);
  const [visibleModal, setVisibleModal] = useState(false);
  const params: Readonly<
    Partial<{ externalId: string | undefined; tab: string }>
  > = useParams();

  const { data: conglomerate, isLoading: conglomerateLoading } =
    useConglomerateGetType(params.externalId);

  const { t } = useTranslation();

  return (
    <PageLoading loading={conglomerateLoading}>
      <HorizontalTabs
        type="secondary"
        items={[
          {
            label: t('adminBootys.editConglomerate.tab.details'),
            key: 'details',
            children: params.externalId && (
              <ConglomerateEditForm
                disabled={disabled}
                externalId={params.externalId}
                onDisabledEditButton={setDisabled}
              />
            ),
          },
          {
            label: t('adminBootys.editConglomerate.tab.company'),
            key: 'companys',
            children: params?.externalId && (
              <CompanyAdminListTab UrlExternalId={params.externalId} />
            ),
          },
        ]}
        leftIcon="menu-ellipsis-horizontal"
        rigthButton={{
          ...(disabled &&
            params.tab !== 'companys' && {
              onClick: () => setDisabled(false),
              isVisible: disabled,
            }),
        }}
      />

      <ChangePasswordModal groupName={'Farmácias Pague Menos'} />
      <Modal
        visible={visibleModal}
        onCancelClick={() => setVisibleModal(false)}
        onClose={() => setVisibleModal(false)}
        title={getDeleteModalTitle(t, [conglomerate], true)}
        okButtonColor={true ? 'status-danger-base' : undefined}
        okButtonName={
          true
            ? t('common.delete')
            : t('adminBootys.companyList.deleteModal.delete')
        }
        body={
          <DeleteModalBody
            rows={[
              { ...conglomerate, empresas: conglomerate?.empresas.length },
            ]}
            hasCompany={false}
          />
        }
        withoutOkButton={true}
      />
    </PageLoading>
  );
};
