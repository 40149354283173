import React from 'react';
import { i18n, Link } from '../../../../Components';
import { LinkButton } from '../../../../Components/Layouts/LinkButton';

export const subGroupCollums = (t: any) => [
  {
    title: t('products.subGroupList.tableTitle.description'),
    dataIndex: 'descricao',
    key: 'descricao',
    width: '50%',
    sortColumn: 'descricao',
    defaultSortOrder: 'ascend',
    isHighlightable: true,
    render: (x: any, row: any) => (
      <Link
        type="ui-tiny-bold"
        to={`/inventory/sub-groups/${row?.externalId}/details`}
        style={{ width: 'fit-content' }}
      >
        {x}
      </Link>
    ),
  },
  {
    title: t('products.subGroupList.tableTitle.groups'),
    dataIndex: 'grupoNome',
    key: 'grupoNome',
    render: (x: any, row: any) => {
      return (
        <LinkButton
          link={`/inventory/groups/${row?.grupoExternalId}/details`}
          text={x}
        />
      );
    },
  },
  {
    title: t('products.subGroupList.tableTitle.products'),
    dataIndex: 'produtos',
    key: 'produtos',
    render: (x: any, row: any) => {
      return (
        <LinkButton
          link={`/inventory/sub-groups/${row.externalId}/products`}
          text={
            x !== 1
              ? `${x} ${i18n.t('common.products')}`
              : `${x} ${i18n.t('common.product')}`
          }
        />
      );
    },
  },
];
