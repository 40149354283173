import React, { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Form } from '../../../../Components';
import InfoCard from './CardsForm/InfoCard';
import { TechnicalInfoCard } from './CardsForm/TechnicalInfoCard';
import { InitialBalanceCard } from './CardsForm/InitialBalanceCard';
import { history } from '../../../../Store';
import { ICreateLotRequest } from '../../../../Data/Interfaces/request/Lot/ILotResquest';
import { useFormikContext } from 'formik';
import { RawMaterialSchema } from './LotCreate.schema';
import { ConvertDateForApi } from '../../../../Utils/DateUtils';
import { Modal } from '../../../../Components/Modal';
import { NotRawMaterialSchema } from './TechnicalInfo.schema';
import {
  FETCH_LIST_LOTS,
  useLotCreate,
} from '../../../../Hooks/Supply/LotHooks';
import { useQueryClient } from '@tanstack/react-query';
import { useUserPreferencesType } from '../../../../Hooks/Accountmanager/UserHooks';
import { App } from 'antd';

import styles from './LotCreate.module.scss';
import { handleModalError } from '../../../../Utils/ErrorHandlingUtils';

export const CreateLot: FC = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { data: datePattern } = useUserPreferencesType();
  const [isBackModalOpen, setIsBackModalOpen] = useState(false);
  const form: any = useFormikContext();
  const app = App.useApp();

  const createLot = useLotCreate();
  const [classTypeState, setClassTypeState] = useState<number>();
  const onClassTypeSelected = useCallback((classTypeId: number | undefined) => {
    setClassTypeState(classTypeId);
  }, []);

  const onSubmit = async (values: ICreateLotRequest) => {
    var body: any = { ...values };

    delete body.classe;
    delete body.razaoSocial;
    delete body.Cnpj;

    if (!datePattern) return;
    if (body.dataFabricacao)
      body.dataFabricacao = ConvertDateForApi(
        body.dataFabricacao,
        datePattern.preferencias.padraoData
      );
    if (body.dataValidade)
      body.dataValidade = ConvertDateForApi(
        body.dataValidade,
        datePattern.preferencias.padraoData
      );
    const res = await createLot.mutateAsync(
      {
        ...body,
        unidadeId: 1,
      },
      {
        onError: handleModalError(app),
      }
    );
    if (res.success) {
      queryClient.invalidateQueries([FETCH_LIST_LOTS]);
      history.push('/inventory/lot');
    }
  };

  return (
    <>
      <Form
        onSubmit={onSubmit}
        schema={
          form?.values?.classeId === 1
            ? RawMaterialSchema
            : NotRawMaterialSchema
        }
      >
        {(props) => (
          <>
            <InfoCard onClassTypeSelected={onClassTypeSelected} />

            <TechnicalInfoCard />

            <InitialBalanceCard typeClassProduct={classTypeState} />

            <div className={styles['footer-button']}>
              <Button
                type="secondary"
                children={t('supply.lot.create.cancel')}
                onClick={() =>
                  !!props?.dirty
                    ? setIsBackModalOpen(true)
                    : history.push('/inventory/lot')
                }
              />
              <Button
                type="primary"
                children={
                  createLot.isLoading
                    ? t('supply.lot.create.saveLoading')
                    : t('supply.lot.create.save')
                }
                htmlType="submit"
                loading={createLot.isLoading}
                disabled={!props.dirty}
              />
            </div>
          </>
        )}
      </Form>

      <Modal
        visible={isBackModalOpen}
        onOkClick={() => history.push('/inventory/lot')}
        onCancelClick={() => setIsBackModalOpen(false)}
        okButtonName={t('supply.balanceAdjustment.backModal.okButton')}
        title={t('supply.balanceAdjustment.backModal.title')}
        body={
          <div style={{ padding: 20, maxWidth: 400 }}>
            {t('supply.balanceAdjustment.backModal.body')}
          </div>
        }
      />
    </>
  );
};

export default CreateLot;
