import { useQueryClient } from '@tanstack/react-query';
import React from 'react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from '../../../../../Components';
import { Modal } from '../../../../../Components/Modal';
import { FooterThisActionIsPermanent } from '../../../../../Components/Table/components/Modals/DeleteModal';
import { IGetCompanyResponse } from '../../../../../Data/Interfaces/response/Company/ICompanyResponse';
import {
  LIST_COMPANY_ADMIN_TYPE,
  useCompanyDeleteMutation,
} from '../../../../../Hooks/Accountmanager/CompanyHooks';
import { Divflex } from '../../../../../Layouts/DivWhithFlex';
import { history } from '../../../../../Store';

interface IDeleteProductModal {
  company?: IGetCompanyResponse;
  visibleModal: boolean;
  changeVisibleModal: (value: boolean) => void;
}

export const DeleteCompanyModal: FC<IDeleteProductModal> = ({
  company,
  visibleModal,
  changeVisibleModal,
}) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const deleteCompany = useCompanyDeleteMutation();

  return (
    <Modal
      title={`${t('common.delete')} "${company?.nomeFantasia}"?`}
      body={
        <div style={{ padding: '20px' }}>
          <Divflex>
            <Text
              color="text-50"
              type="ui-tiny-content"
              children={`${t('modal.deleteModal.single.question')} ${t(
                'common.theCompany'
              )} `}
            />
            <Text
              color="text-50"
              type="ui-tiny-bold"
              children={`${company?.nomeFantasia}?`}
            />
          </Divflex>
          <FooterThisActionIsPermanent />
        </div>
      }
      okButtonColor="status-danger-base"
      visible={visibleModal}
      onOkClick={async () => {
        if (company) {
          const res = await deleteCompany.mutateAsync({
            empresasExternalId: [company?.externalId],
          });
          if (res.success) {
            queryClient.invalidateQueries([LIST_COMPANY_ADMIN_TYPE]);
            history.push(`/admin-bootys/company`);
          }
        }
      }}
      okButtonName={t('common.delete')}
      onCancelClick={() => changeVisibleModal(false)}
      onClose={() => changeVisibleModal(false)}
    />
  );
};
