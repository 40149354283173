import React, { FC, useState } from 'react';
import { Row, Col } from 'antd';
import { FooterButton } from '../Components/FooterButton';
import { FormikHelpers, FormikValues, useFormikContext } from 'formik';
import { cnaeItems } from './CNAE';
import { CompanyDataCardSchema } from './CompanyDataCard.schema';
import { Card } from '../../../Components/Card';
import { Dropdown, Form, i18n } from '../../../Components';
import { TextInputWithMask } from '../../../Components/Inputs/TextInputWithMask';
import {
  patternLabelStyle,
  TextInput,
} from '../../../Components/Inputs/TextInput';
import { TextInputPhoneMask } from '../../../Components/Inputs/TextInputPhoneMask';
import { IChangeCompanyRequest } from '../../../Data/Interfaces/request/Company/ICompanyRequest';
import { RemoveMask } from '../../../Utils/MasksUtils';
import { useCompanyChangeInfoMutation } from '../../../Hooks/Accountmanager/CompanyHooks';
import { initialValues } from './CompanyDataCard.InitialValues';
import { IGetCompanyResponse } from '../../../Data/Interfaces/response/Company/ICompanyResponse';

interface ICompanyCard {
  editForm?: boolean;
  buttonDisabled?: boolean;
  onButtonChange?: (value: boolean) => void;
  loading?: boolean;
}

export const CompanyDataCard: FC<ICompanyCard> = ({
  editForm,
  buttonDisabled,
  onButtonChange,
  loading,
}) => {
  const form = useFormikContext();

  return (
    <Card
      title={i18n.t('adminBootys.createCompany.companyData')}
      withoutCollapse={editForm}
    >
      <Row>
        <Col flex="30%">
          <TextInputWithMask
            name="cnpj"
            label={[
              {
                children: i18n.t(
                  'adminBootys.createCompany.companyDataCard.cnpj'
                ),
                ...patternLabelStyle,
              },
            ]}
            mask="cnpjMask"
            placeHolder={i18n.t(
              'adminBootys.createCompany.companyDataCard.cnpjPlaceholder'
            )}
            disabled={buttonDisabled}
            required
          />
        </Col>
      </Row>
      <Row gutter={[16, 0]} justify="space-between">
        <Col span={12}>
          <TextInput
            name="razaoSocial"
            label={[
              {
                children: i18n.t(
                  'adminBootys.createCompany.companyDataCard.corporateName'
                ),
                ...patternLabelStyle,
              },
            ]}
            placeHolder={i18n.t(
              'adminBootys.createCompany.companyDataCard.corporateNamePlaceholder'
            )}
            disabled={buttonDisabled}
            required
          />
        </Col>
        <Col span={12}>
          <TextInput
            name="nomeFantasia"
            label={[
              {
                children: i18n.t(
                  'adminBootys.createCompany.companyDataCard.fantasyName'
                ),
                ...patternLabelStyle,
              },
            ]}
            placeHolder={i18n.t(
              'adminBootys.createCompany.companyDataCard.fantasyNamePlaceholder'
            )}
            disabled={buttonDisabled}
            required
          />
        </Col>
      </Row>
      <Row gutter={[20, 0]}>
        <Col span={8}>
          <TextInputWithMask
            name="inscricaoEstadual"
            withoutMarginBottom={true}
            label={[
              {
                children: i18n.t(
                  'adminBootys.createCompany.companyDataCard.stateRegistration'
                ),
                ...patternLabelStyle,
              },
            ]}
            placeHolder={i18n.t(
              'adminBootys.createCompany.companyDataCard.stateRegistrationPlaceholder'
            )}
            mask="stateRegistration"
            disabled={buttonDisabled}
            required
          />
        </Col>
        <Col span={8}>
          <TextInputWithMask
            name="inscricaoMunicipal"
            withoutMarginBottom={true}
            label={[
              {
                children: i18n.t(
                  'adminBootys.createCompany.companyDataCard.municipalRegistration'
                ),
                ...patternLabelStyle,
              },
            ]}
            placeHolder={i18n.t(
              'adminBootys.createCompany.companyDataCard.municipalRegistrationPlaceholder'
            )}
            mask="municipalRegistration"
            disabled={buttonDisabled}
            required
          />
        </Col>
        <Col span={8}>
          <Dropdown
            name="cnae"
            label={[
              {
                children: i18n.t(
                  'adminBootys.createCompany.companyDataCard.cnae'
                ),
                ...patternLabelStyle,
              },
            ]}
            items={cnaeItems}
            placeHolder={i18n.t(
              'adminBootys.createCompany.companyDataCard.cnaePlaceholder'
            )}
            disabled={buttonDisabled}
            required
          />
        </Col>
      </Row>
      <Row gutter={[20, 0]}>
        <Col span={8}>
          <TextInput
            name="email"
            withoutMarginBottom={true}
            label={[
              {
                children: i18n.t(
                  'adminBootys.createCompany.companyDataCard.email'
                ),
                ...patternLabelStyle,
              },
            ]}
            placeHolder={i18n.t(
              'adminBootys.createCompany.companyDataCard.emailPlaceholder'
            )}
            disabled={buttonDisabled}
          />
        </Col>
        <Col span={8}>
          <TextInputPhoneMask
            name="telefone"
            withoutMarginBottom={true}
            label={[
              {
                children: i18n.t(
                  'adminBootys.createCompany.companyDataCard.cell'
                ),
                ...patternLabelStyle,
              },
            ]}
            placeHolder={i18n.t(
              'adminBootys.createCompany.companyDataCard.cellPlaceholder'
            )}
            disabled={buttonDisabled}
          />
        </Col>
        <Col span={8}>
          <TextInput
            name="site"
            withoutMarginBottom={true}
            label={[
              {
                children: i18n.t(
                  'adminBootys.createCompany.companyDataCard.website'
                ),
                ...patternLabelStyle,
              },
            ]}
            placeHolder={i18n.t(
              'adminBootys.createCompany.companyDataCard.websitePlaceholder'
            )}
            disabled={buttonDisabled}
          />
        </Col>
      </Row>
      {onButtonChange && form && (
        <FooterButton
          disabled={!!buttonDisabled}
          editLabel={i18n.t('adminBootys.createCompany.companyDataCard.edit')}
          onChange={(value) => onButtonChange(value)}
          loading={loading}
        />
      )}
    </Card>
  );
};
interface ICompanyDataCard {
  company: IGetCompanyResponse;
}

export const CompanyDataCardWrapper: React.FC<ICompanyDataCard> = ({
  company,
}) => {
  const [buttonDisabled, setButtonDisabled] = useState(true);

  const { isLoading, mutateAsync } = useCompanyChangeInfoMutation();

  const onSubmit = async (
    values: IChangeCompanyRequest,
    helpers: FormikHelpers<FormikValues>
  ) => {
    var body: IChangeCompanyRequest = {
      ...values,
      helpers,
    };
    body.cnpj = RemoveMask(body.cnpj) || '';

    await mutateAsync(body);
  };

  return (
    <>
      <Form
        onSubmit={onSubmit}
        schema={CompanyDataCardSchema}
        initialValues={company && initialValues(company)}
      >
        <CompanyDataCard
          editForm
          buttonDisabled={buttonDisabled}
          onButtonChange={(value) => setButtonDisabled(value)}
          loading={isLoading}
        />
      </Form>
    </>
  );
};
