import { Col, Row } from 'antd';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Card,
  Dropdown,
  Icon,
  Text,
  TextInput,
} from '../../../../../../Components';
import { Divflex } from '../../../../../../Layouts/DivWhithFlex';
import { useFormikContext } from 'formik';
import { useCountryGetList } from '../../../../../../Hooks/Supply/CountryHooks';
import { useLotGetDropdown } from '../../../../../../Hooks/Supply/LotHooks';
import { CountryDropdown } from '../../../../../../Components/Inputs/CountryDropdown';
import { useAlternativeUnitMeasurementDropdown } from '../../../../../../Hooks/Supply/UnitMeasurementHooks';
import { TextInputNumber } from '../../../../../../Components/Inputs/TextInputNumber';

interface ITechnicalInfoCardBody {
  disabledForm?: boolean;
}

export const TechnicalInfoCard: FC<ITechnicalInfoCardBody> = ({
  disabledForm,
}) => {
  const { t } = useTranslation();
  const form: any = useFormikContext();

  const { data: lotList, isLoading: isLotLoading } = useLotGetDropdown({
    PageIndex: 0,
    PageSize: 100,
    filter: [
      {
        filterName: 'produtoExternalId',
        externalId: form?.values?.produtoExternalId,
      },
    ],
  });

  const { data: paisOrigemList } = useCountryGetList({
    PageIndex: 0,
    PageSize: 260,
  });

  const { data: unidadeAlternativaList } =
    useAlternativeUnitMeasurementDropdown({
      PageIndex: 0,
      PageSize: 10,
    });

  return form?.values?.classeId === 1 || !form?.values?.classeId ? (
    <Card
      title={t('supply.lot.create.titleTechnicalInformation')}
      withoutCollapse
    >
      <Row gutter={[16, 0]}>
        <Col span={5}>
          <CountryDropdown
            name="loteInformacaoTecnica.paisOrigemExternalId"
            items={paisOrigemList?.data?.map((x: any) => ({
              id: x.externalId,
              name: x.descricao,
              flag: x.abreviacao,
            }))}
            label={t('supply.lot.create.countryOfOrigin')}
            required
          />
        </Col>
        <Col span={3}>
          <TextInput
            name="loteInformacaoTecnica.densidade"
            label={[
              {
                children: t('supply.lot.create.density'),
              },
            ]}
            placeHolder={t('supply.lot.create.density')}
            required
            type="number"
          />
        </Col>
      </Row>

      <Row gutter={[16, 0]} style={{ marginBottom: '22px' }}>
        <Col span={6}>
          <TextInput
            name="loteInformacaoTecnica.diluicaoFornecedor"
            label={[
              {
                children: t('supply.lot.create.supplierDilution'),
              },
            ]}
            placeHolder={t('supply.lot.create.supplierDilution')}
            required
            withTooltip={{
              icon: 'exclamation-upside-down',
              title: t('supply.lot.create.toolTips.supplierDilution'),
            }}
            type="number"
          />
        </Col>
        <Col span={6}>
          <TextInput
            name="loteInformacaoTecnica.fatorDiluicaoFornecedor"
            label={[
              {
                children: t('supply.lot.create.supplierDilutionFactor'),
              },
            ]}
            placeHolder={t('supply.lot.create.supplierDilutionFactor')}
            required
            withTooltip={{
              icon: 'exclamation-upside-down',
              title: t('supply.lot.create.toolTips.supplierDilutionFactor'),
            }}
            type="number"
          />
        </Col>
        <Col span={6}>
          <TextInput
            name="loteInformacaoTecnica.concentracaoAgua"
            label={[
              {
                children: t('supply.lot.create.waterConcentration'),
              },
            ]}
            placeHolder={t('supply.lot.create.waterConcentration')}
            required
            withTooltip={{
              icon: 'exclamation-upside-down',
              title: t('supply.lot.create.toolTips.waterConcentration'),
            }}
            type="number"
          />
        </Col>
        <Col span={6}>
          <TextInput
            name="loteInformacaoTecnica.fatorConcentracaoAgua"
            label={[
              {
                children: t('supply.lot.create.waterConcentrationFactor'),
              },
            ]}
            placeHolder={t('supply.lot.create.waterConcentrationFactor')}
            required
            withTooltip={{
              icon: 'exclamation-upside-down',
              title: t('supply.lot.create.toolTips.waterConcentrationFactor'),
            }}
            type="number"
          />
        </Col>
      </Row>

      <div style={{ borderTop: '1px solid var(--color-background-04)' }}>
        <Card title={t('supply.lot.create.titleInternalDilution')} insideCard>
          <Row gutter={[16, 0]} style={{ marginTop: '20px' }}>
            <Col span={6}>
              <TextInput
                name="loteInformacaoTecnica.diluicaoInterna"
                label={[
                  {
                    children: t('supply.lot.create.internalDilution'),
                  },
                ]}
                placeHolder={t('supply.lot.create.internalDilution')}
                withTooltip={{
                  icon: 'exclamation-upside-down',
                  title: t('supply.lot.create.toolTips.internalDilution'),
                }}
                type="number"
              />
            </Col>
            <Col span={6}>
              <TextInput
                name="loteInformacaoTecnica.fatorDiluicaoInterna"
                label={[
                  {
                    children: t('supply.lot.create.internalDilutionFactor'),
                  },
                ]}
                placeHolder={t('supply.lot.create.internalDilutionFactor')}
                withTooltip={{
                  icon: 'exclamation-upside-down',
                  title: t('supply.lot.create.toolTips.internalDilutionFactor'),
                }}
                type="number"
              />
            </Col>
            <Col span={6}>
              <Dropdown
                items={lotList?.data?.map((x: any) => ({
                  id: x.externalId,
                  name: x.loteNumero,
                  code: `NF: ${x.numeroNf}`,
                }))}
                name="loteInformacaoTecnica.loteOrigemExternalId"
                label={t('supply.lot.create.lotOrigin')}
                placeHolder={t('supply.lot.create.lotOrigin')}
                disabled={disabledForm}
                isLoading={isLotLoading}
                withTooltip={{
                  icon: 'exclamation-upside-down',
                  title: t('supply.lot.create.toolTips.lotOrigin'),
                }}
              />
            </Col>
          </Row>
        </Card>
      </div>
      <Card title={t('supply.lot.create.titleConversionRates')} insideCard>
        <Row gutter={[16, 0]} style={{ marginTop: '20px' }}>
          <Col span={6}>
            <TextInputNumber
              name="loteInformacaoTecnica.loteUnidadeAlternativa1.quantidadeUnidadeAlternativa"
              label={[
                {
                  children: t('supply.lot.create.alternativeUnitOne'),
                },
              ]}
              dropdownLeft={{
                name: 'loteInformacaoTecnica.loteUnidadeAlternativa1.unidadeAlternativaId',
                options:
                  unidadeAlternativaList?.data.map((x) => ({
                    id: x.id,
                    titleString: x.unidadeAbreviacao,
                  })) || [],
              }}
              rightIcon={{
                titleString: '/g',
                id: 7,
              }}
            />
          </Col>
          <Col span={6}>
            <TextInputNumber
              name="loteInformacaoTecnica.loteUnidadeAlternativa2.quantidadeUnidadeAlternativa"
              label={[
                {
                  children: t('supply.lot.create.alternativeUnitTwo'),
                },
              ]}
              placeHolder={t('supply.lot.create.alternativeUnitTwo')}
              dropdownLeft={{
                name: 'loteInformacaoTecnica.loteUnidadeAlternativa2.unidadeAlternativaId',
                options:
                  unidadeAlternativaList?.data.map((x) => ({
                    id: x.id,
                    titleString: x.unidadeAbreviacao,
                  })) || [],
              }}
              rightIcon={{
                titleString: '/g',
                id: 7,
              }}
            />
          </Col>
        </Row>
      </Card>
    </Card>
  ) : (
    <TechnicalInfoNotRawMaterialCard />
  );
};

export default TechnicalInfoCard;

export const TechnicalInfoNotRawMaterialCard: FC = () => {
  const { t } = useTranslation();

  return (
    <Card
      title={t('supply.lot.create.titleTechnicalInformation')}
      withoutCollapse
    >
      <Divflex>
        <Icon icon={'exclamation-upside-down'} size={'SM'} color={'text-400'} />
        <Text
          type="paragraph2"
          color={'text-400'}
          style={{ marginLeft: '6px', marginTop: '-1px', cursor: 'pointer' }}
        >
          {t('supply.lot.card.notRawMaterialCard')}
        </Text>
      </Divflex>
    </Card>
  );
};
