import { FormikHelpers, FormikValues } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Button, Form } from '../../../Components';
import { patternLabelStyle } from '../../../Components/Inputs/TextInput';
import { TextInputPassword } from '../../../Components/Inputs/TextInputPassword';
import { TableLayout } from '../../../Components/Tabs/TablePreference';
import { IChangePasswordRequest } from '../../../Data/Interfaces/request/User/IUserRequest';
import { UserActions } from '../../../Store/User/User.actions';

import styles from './AccountSecurity.module.scss';
import { SecuritySchema } from './AccountSecurity.schema';

const AccountSecurity: React.FC = () => {

	const dispatch = useDispatch();
 
	const { t } = useTranslation();

	const onSubmit = (values: any, helpers: FormikHelpers<FormikValues>) => {
		const body: IChangePasswordRequest = { ...values, helpers }

		dispatch(UserActions.changePassword(body))
	}

	return (
		<TableLayout
			title={t('accountManager.accountSecurityPage.title')}
			description={t('accountManager.accountSecurityPage.description')}
			body={
				<Form onSubmit={onSubmit} schema={SecuritySchema} >
					<div className={styles['divSecurity']}>
						<TextInputPassword
							className={styles['input']}
							name='oldPassword'
							placeHolder={t('accountManager.accountSecurityPage.oldPassword')}
							label={[{ children: t('accountManager.accountSecurityPage.oldPassword'), ...patternLabelStyle }]}
						/>
						<TextInputPassword
							className={styles['input']}
							name='newPassword'
							placeHolder={t('accountManager.accountSecurityPage.newPassword')}
							label={[{ children: t('accountManager.accountSecurityPage.newPassword'), ...patternLabelStyle }]}
						/>
						<TextInputPassword
							className={styles['last-input']}
							name='confirmPassword'
							placeHolder={t('accountManager.accountSecurityPage.confirmPassword')}
							label={[{ children: t('accountManager.accountSecurityPage.confirmPassword'), ...patternLabelStyle }]}
						/>
						<Button type='primary' children={t('accountManager.accountSecurityPage.securityButton')} htmlType='submit' />
					</div>
				</Form>
			}
		/>
	)
}

export default AccountSecurity;