export const noPermissionEN = {
  administrative: {
    users: {
      route: 'You dont have permission to see the users list',
      create: 'You dont have permission to create new users',
      statusEdit: 'You dont have permission to edit user status',
      delete: 'You dont have permission to delete users',
      detail: 'You dont have permission to see user details',
      edit: 'You dont have permission to edit users personal data',
      editGroup: 'You dont have permission to eit users group',
    },

    userGroups: {
      route: 'You dont have permission to list user groups',
      create: 'You dont have permission to create user groups',
      detail: 'You dont have permission to see user group details',
      delete: 'You dont have permission to delete user groups',
      edit: 'You dont have permission to edit details of user groups',
      statusEdit: 'You dont have permission to edit user group details',
    },

    company: {
      route: 'You dont have permission to see the company list',
      editData: 'You dont have permission to edit the company data',
      editAddress: 'You dont have permission to edit company address',
      editiResponsible:
        'You dont have permission to edit responsable user for this company',
      detail: 'You dont have permission to see company details',
    },
  },
  supply: {
    products: {
      route: 'You dont have permission to view the product list',
      create: 'You dont have permission to register new products',
      statusEdit: 'You dont have permission to change product status',
      delete: 'You dont have permission to delete products',
      detail: 'You dont have permission to access product details',
      edit: 'You dont have permission to edit product information',
      editFinancial:
        'You dont have permission to see financial details of the product',
    },
    groups: {
      route: 'You dont have permission to view the product group list',
      create: 'You dont have permission to register new product groups',
      createSubgroup:
        'You dont have permission to register new product subgroups',
      delete: 'You dont have permission to delete product groups',
      detail: 'You dont have permission to see details of product groups',
      edit: 'You dont have permission to edit product group details',
    },
    subGroups: {
      route: 'You dont have permission to see the product subgroup list',
      create: 'You dont have permission to register new product subgroups',
      createProduct: 'You dont have permission to register new products',
      delete: 'You dont have permission to delete product subgroups',
      detail: 'You dont have permission to see product subgroup details',
      detailGroups:
        'You dont have permission to view details of product groups',
      edit: 'You dont have permission to edit product subgroup details',
    },
    lot: {
      route: 'You dont have permission to see the batch list',
      create: 'You dont have permission to register new batches',
      statusEdit: 'You dont have permission to change batch status',
      delete: 'You dont have permission to delete batches',
      detail: 'You dont have permission to access batch details',
      detailProducts: 'You dont have permission to see product details',
      edit: 'You dont have permission to edit batch information',
    },
    supplyPlaces: {
      route: 'You dont have permission to view the list of stock locations',
      create: 'You dont have permission to register new stock locations',
      statusEdit:
        'You dont have permission to change status of stock locations',
      delete: 'You dont have permission to delete stock locations',
      detail: 'You dont have permission to view details of stock locations',
      edit: 'You dont have permission to edit stock location details',
    },
    transfers: {
      route: 'You dont have permission to view the transfer list',
      create: 'You dont have permission to register new transfers',
      detail: 'You dont have permission to see transfer details',
    },
    losses: {
      route: 'You dont have permission to see the loss list',
      create: 'You dont have permission to register new losses',
      detail: 'You dont have permission to see loss details',
      edit: 'You dont have permission to edit loss details',
    },
    balanceAdjustments: {
      route: 'You dont have permission to see the list of balance adjustments',
      create: 'You dont have permission to register new balance adjustments',
      detail: 'You dont have permission to see details of balance adjustments',
    },
  },

  default: 'You dont have permission to do this action',
};

export const noPermissionPT = {
  administrative: {
    users: {
      route: 'Você não tem permissão de ver a lista de usuários',
      create: 'Você não tem permissão de cadastrar novos usuários',
      statusEdit: 'Você não tem permissão de alterar status dos usuários',
      delete: 'Você não tem permissão de excluir usuários',
      detail: 'Você não tem permissão de acessar os detalhes dos usuários',
      edit: 'Você não tem permissão de editar dados pessoais dos usuários',
      editGroup: 'Você não tem permissão de editar grupos dos usuários',
    },

    userGroups: {
      route: 'Você não tem permissão de ver a lista dos grupos de usuários',
      create: 'Você não tem permissão de criar grupos de usuários',
      detail: 'Você não tem permissão de ver detalhes dos grupos de usuário',
      delete: 'Você não tem permissão de excluir grupos de usuários',
      edit: 'Você não tem permissão de editar detalhes dos grupos de usuários',
      statusEdit:
        'Você não tem permissão de alterar status dos grupos de usuários',
    },

    company: {
      route: 'Você não tem permissão de ver a lista de empresas',
      editData: 'Você não tem permissão de editar dados da empresa',
      editAddress: 'Você não tem permissão de editar endereço da empresa',
      editiResponsible:
        'Você não tem permissão de editar usuário responsável da empresa',
      detail: 'Você não tem permissão de ver detalhes das empresas',
    },
  },
  supply: {
    products: {
      route: 'Você não tem permissão de ver a lista de produtos',
      create: 'Você não tem permissão de cadastrar novos produtos',
      statusEdit: 'Você não tem permissão de alterar status dos produtos',
      delete: 'Você não tem permissão de excluir produtos',
      detail: 'Você não tem permissão de acessar os detalhes dos produtos',
      edit: 'Você não tem permissão de editar informações dos produtos',
      editFinancial:
        'Você não tem permissão de ver detalhes financeiros do produto',
    },
    groups: {
      route: 'Você não tem permissão de ver a lista de grupos de produtos',
      create: 'Você não tem permissão de cadastrar novos grupos de produtos',
      createSubgroup:
        'Você não tem permissão de cadastrar novos subgrupos de produtos',
      delete: 'Você não tem permissão de excluir grupos de produtos',
      detail: 'Você não tem permissão de ver detalhes dos grupos de produtos',
      edit: 'Você não tem permissão de editar detalhes dos grupos de produtos',
    },
    subGroups: {
      route: 'Você não tem permissão de ver a lista de subgrupos de produtos',
      create: 'Você não tem permissão de cadastrar novos subgrupos de produtos',
      createProduct: 'Você não tem permissão de cadastrar novos produtos',
      delete: 'Você não tem permissão de excluir subgrupos de produtos',
      detail:
        'Você não tem permissão de ver detalhes dos subgrupos de produtos',
      detailGroups:
        'Você não tem permissão de ver detalhes dos grupos de produtos',
      edit: 'Você não tem permissão de editar detalhes dos subgrupos de produtos',
    },
    lot: {
      route: 'Você não tem permissão de ver a lista de lotes',
      create: 'Você não tem permissão de cadastrar novos lotes',
      statusEdit: 'Você não tem permissão de alterar status dos lotes',
      delete: 'Você não tem permissão de excluir lotes',
      detail: 'Você não tem permissão de acessar os detalhes dos lotes',
      detailProducts: 'Você não tem permissão de ver detalhes dos produtos',
      edit: 'Você não tem permissão de editar informações dos lotes',
    },
    supplyPlaces: {
      route: 'Você não tem permissão de ver a lista de locais de estoque',
      create: 'Você não tem permissão de cadastrar novos locais de estoque',
      statusEdit:
        'Você não tem permissão de alterar status dos locais de estoque',
      delete: 'Você não tem permissão de excluir locais de estoque',
      detail: 'Você não tem permissão de ver detalhes dos locais de estoque',
      edit: 'Você não tem permissão de editar detalhes dos locais de estoque',
    },
    transfers: {
      route: 'Você não tem permissão de ver a lista de transferências',
      create: 'Você não tem permissão de cadastrar novas transferências',
      detail: 'Você não tem permissão de ver detalhes das transferências',
    },
    losses: {
      route: 'Você não tem permissão de ver a lista de perdas',
      create: 'Você não tem permissão de cadastrar novas perdas',
      detail: 'Você não tem permissão de ver detalhes das perdas',
      edit: 'Você não tem permissão de editar detalhes da perda',
    },
    balanceAdjustments: {
      route: 'Você não tem permissão de ver a lista de ajustes de saldo',
      create: 'Você não tem permissão de cadastrar novos ajustes de saldo',
      detail: 'Você não tem permissão de ver detalhes dos ajustes de saldo',
    },
  },
  default: 'Você não tem permissão para realizar esta ação',
};
