import Select from 'antd/lib/select';
import { Field } from 'formik';
import React, {
  useCallback,
  useState,
  useEffect,
  KeyboardEventHandler,
} from 'react';
import { Icon, Tooltip } from '../..';
import { LocalizaPropriedadePorPath } from '../../../Utils/FormikUtils';
import { Tag, TagType } from '../../Tag';
import { IField } from '../interfaces/IField';
import { ITextInput } from '../TextInput';
import { Text } from '../../../Components';

import styles from './TextInputWithSearch.module.scss';
import './TextInputWithSearch.override.scss';
import Spinner from '../../Spinner';
import { getScrollPercentage } from '../../../Utils/ScrollUtils';
import { IUserInfo } from '../../HeaderMenu/Components/UserInfo';
import { Avatar } from '../../Avatar';
import { InputLabel } from '../Label';
import _, { uniqBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import { IconName } from '../../Icon/IconClasses';
import { Divflex } from '../../../Layouts/DivWhithFlex';

const { Option } = Select;

interface IItems {
  id?: any;
  label?: string;
  subLabel?: string;
  avatar?: IUserInfo;
}

export interface IInputWithSearch extends ITextInput {
  items?: IItems[];
  name: string;

  onChange?: (value: string) => void;
  onInput?:
    | KeyboardEventHandler<HTMLInputElement | HTMLTextAreaElement>
    | undefined;
  onSearch?: (value: string) => void;
  onScrollEnd?: () => void;

  required?: boolean;

  onTag?: boolean;
  typeTag?: TagType;

  isLoading?: boolean | undefined;

  withTooltip?: {
    title: string;
    icon: IconName;
  };
  withStatusIcon?: boolean;
  autoIncrementBy?: string;

  withoutSearchIcon?: boolean;
  notShowSelectedItem?: boolean;
}

export const InputWithSearch: React.FC<IInputWithSearch> = ({
  onTag,
  typeTag,
  items,
  name,
  onChange,
  onInput,
  onSearch,
  label,
  className,
  required,
  disabled,
  error,
  leftIcon,
  placeHolder,
  rightIcon,
  success,
  withoutMarginBottom,
  onScrollEnd,
  isLoading,
  withTooltip,
  withStatusIcon,
  autoIncrementBy,
  withoutSearchIcon,
  notShowSelectedItem,
}) => {
  const [focus, setFocus] = useState(false);
  const [itemsInput, setItemsInput] = useState<IItems[] | undefined>();
  const [autoIncrement, setAutoIncrement] = useState(itemsInput || []);

  const { t } = useTranslation();

  const onScroll = useCallback(
    (event: React.UIEvent<HTMLElement>) => {
      const scrollPercentage = getScrollPercentage(event);
      const scrollPercentageThreshold = 80;

      // Call hook when you scroll to 80% or more
      if (scrollPercentage > scrollPercentageThreshold && !isLoading) {
        onScrollEnd && onScrollEnd();
      }
    },
    [onScrollEnd, isLoading]
  );

  useEffect(() => {
    if (!autoIncrementBy) return;

    const allItems = [
      ...(autoIncrement || []),
      ...(itemsInput || []),
      ...(items || []),
    ];
    const uniqueItems = uniqBy(allItems, autoIncrementBy);

    if (uniqueItems.length === autoIncrement.length) return;

    setAutoIncrement(uniqueItems);
  }, [autoIncrementBy, autoIncrement, itemsInput, items]);

  useEffect(() => {
    setItemsInput(items);
  }, [items]);

  const onChangeFunc = useCallback(
    (value: string, props: IField, selected?: boolean) => {
      !selected && setItemsInput(undefined);
      props.form.setFieldValue(name, value);

      onChange && onChange(value);
    },
    [name, onChange]
  );

  const onSearchFunc = _.debounce(
    (value: string, props: IField, selected?: boolean) => {
      !selected && setItemsInput(undefined);

      onSearch && onSearch(value);
    },
    1000
  );

  return (
    <div
      className={`${withoutMarginBottom ? '' : styles['margin-bottom']} ${
        styles['Container']
      }`}
    >
      <Field name={name}>
        {(props: IField) => {
          const hasError =
            error ||
            !!(
              props.form &&
              props.form.errors &&
              props.meta.touched &&
              LocalizaPropriedadePorPath(props.form.errors, name)
            );
          const classNames = className;

          return (
            <>
              <Divflex>
                <InputLabel
                  label={label}
                  required={required}
                  withStatusIcon={withStatusIcon}
                  props={props}
                />
                {withTooltip && (
                  <Tooltip
                    title={withTooltip.title}
                    showMe
                    children={
                      <Icon
                        className={styles['icon-with-tooltip']}
                        icon={withTooltip.icon}
                        color="text-300"
                        size="S"
                      />
                    }
                  />
                )}
              </Divflex>
              <div
                className={`${styles['input-container']} ${classNames} ${
                  leftIcon ? styles['with-left-icon'] : null
                } ${
                  rightIcon || success || hasError
                    ? styles['with-right-icon']
                    : null
                } ${hasError ? styles['error'] : null} ${
                  success ? styles['success'] : null
                } ${
                  disabled || props.form.isSubmitting
                    ? styles['disabled']
                    : null
                } ${isLoading ? 'input-with-search-loading' : ''}`}
              >
                <Select
                  value={notShowSelectedItem ? null : props.field.value}
                  onPopupScroll={onScroll}
                  mode={onTag ? 'multiple' : undefined}
                  onChange={(x) => onChangeFunc(x, props, true)}
                  className={`${styles['TextInput']} ${
                    withoutSearchIcon
                      ? styles['TextInput-without-searchIcon']
                      : ''
                  }`}
                  showArrow={!notShowSelectedItem}
                  tagRender={(props: any) => (
                    <Tag
                      className={styles['tag']}
                      type={typeTag ? typeTag : 'primary'}
                      closable={props.closable}
                      onClose={props.onClose}
                      children={props.label}
                    />
                  )}
                  disabled={disabled || props.form.isSubmitting}
                  placeholder={placeHolder}
                  showSearch
                  onInputKeyDown={onInput}
                  optionFilterProp="id"
                  suffixIcon={
                    <Icon
                      className={styles['chevron-up']}
                      icon="chevron-down"
                    />
                  }
                  onFocus={() => setFocus(true)}
                  onBlur={() => setFocus(false)}
                  loading={isLoading}
                  onSearch={(x) => onSearchFunc(x, props)}
                  children={(autoIncrementBy
                    ? autoIncrement || []
                    : itemsInput || []
                  )?.map(
                    (x, index) =>
                      x.id &&
                      (x.avatar ? (
                        <Option
                          id={x.label}
                          className={`${styles['option']} ${styles['option-avatar']}`}
                          key={`input-with-search-${index}`}
                          value={x.id}
                          label={x.label}
                        >
                          <div className={`${styles['divContent']}`}>
                            <Avatar
                              fisrtName={x.avatar.name}
                              lastName={x.avatar.lastName}
                              size="SM"
                              type="paragraph2-bold"
                              color="white"
                            />
                            <div className={styles['div-avatar']}>
                              <Text
                                type="ui-sub-content"
                                color="text-50"
                                children={`${x.avatar.name} ${
                                  x.avatar.lastName || ''
                                }`}
                              />
                              <Text
                                type="small-text"
                                color="text-400"
                                children={x.avatar.email}
                              />
                            </div>
                          </div>
                        </Option>
                      ) : (
                        <Option
                          id={x.label}
                          className={styles['option']}
                          key={`input-with-search-${index}`}
                          value={x.id}
                          label={x.label}
                        >
                          <div>
                            <Text
                              type="ui-tiny-content"
                              color="text-50"
                              children={x.label}
                            />
                            {x.subLabel && (
                              <Text
                                className="input-with-search-subLabel"
                                type="small-text"
                                color="text-300"
                                children={x.subLabel}
                              />
                            )}
                          </div>
                        </Option>
                      ))
                  )}
                />
                {focus && isLoading && !itemsInput && (
                  <div className={styles['spinner']}>
                    <Spinner />
                  </div>
                )}
                {focus && !isLoading && itemsInput?.length === 0 && (
                  <div className={styles['empyt']}>
                    <Text
                      type="ui-tiny-content"
                      color="text-300"
                      children={t('dropDown.empty')}
                    />
                  </div>
                )}
                {hasError ? (
                  <Icon
                    className={styles['Icon']}
                    icon="exclamation"
                    size="M"
                    color="status-danger-base"
                  />
                ) : null}
                {success ? (
                  <Icon
                    className={styles['Icon']}
                    icon="check"
                    size="M"
                    color="status-success-base"
                  />
                ) : null}
                {rightIcon?.icon ? (
                  <Icon
                    className={styles['Icon']}
                    icon={rightIcon.icon}
                    size="M"
                    color="text-50"
                  />
                ) : null}
                {!withoutSearchIcon && (
                  <Icon
                    className={styles['search']}
                    icon="search"
                    size="M"
                    color="text-400"
                  />
                )}
                {props.form &&
                props.form.errors &&
                LocalizaPropriedadePorPath(props.form.errors, name) ? (
                  <p className={styles['errorText']}>
                    {LocalizaPropriedadePorPath(props.form.errors, name)}
                  </p>
                ) : null}
              </div>
            </>
          );
        }}
      </Field>
    </div>
  );
};
