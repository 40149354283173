import React, { FC, useCallback, useState } from 'react';
import { Table } from '../../../../Components';
import { history } from '../../../../Store';
import { useTranslation } from 'react-i18next';
import { editTableStatus } from '../../../../Utils/EditModalTableUtils';
import { companyCollums } from './company.columns';
import { DeleteModalOnTable } from '../../../../Utils/DeleteModalUtils';
import {
  LIST_COMPANY_ADMIN_TYPE,
  useCompanyChangeStatusMutation,
  useCompanyDeleteMutation,
  useCompanyListAdminType,
} from '../../../../Hooks/Accountmanager/CompanyHooks';
import { ConfirmEditModal, DeleteModal } from './List.helper';
import { useQueryClient } from '@tanstack/react-query';
import { IQueryListFilter, IQueryListParams } from '../../../../Utils/Http';

interface ICompanyAdminList {
  filter?: IQueryListFilter[];
}

export const CompanyAdminList: FC<ICompanyAdminList> = ({ filter }) => {
  const [selectedRowsOnTable, setSelectedRowsOnTable] = useState<any>([]);
  const [pagination, setPagination] = useState<IQueryListParams>({
    PageIndex: 0,
    PageSize: 10,
    Sorter: { column: 'nomeFantasia', direction: 'ASC' },
    filter,
  });

  const queryClient = useQueryClient();

  const { t } = useTranslation();

  const changeStatus = useCompanyChangeStatusMutation();
  const deleteCompany = useCompanyDeleteMutation();
  const { data, isLoading } = useCompanyListAdminType(pagination);

  const onDeleteCompany = useCallback(
    async (company: any) => {
      await deleteCompany.mutateAsync({
        empresasExternalId: company.map((x: any) => x.externalId),
      });
    },
    [deleteCompany]
  );

  const onEditCompany = useCallback(
    async (company: any, status: any) => {
      const res = await changeStatus.mutateAsync({
        empresasExternalId: company?.map((x: any) => x.externalId),
        ativa: status?.status === 'success',
      });
      if (res.success) queryClient.invalidateQueries([LIST_COMPANY_ADMIN_TYPE]);
    },
    [changeStatus, queryClient]
  );

  return (
    <>
      <Table
        columns={companyCollums(t)}
        data={
          data?.data?.map((x) => ({
            ...x,
            key: `table_companys_${x.externalId}`,
          })) || []
        }
        headerOptions={{
          hasSearch: true,
          newButton: {
            onClick: () => history.push('/admin-bootys/company/create'),
          },
          editButton: {
            options: [
              {
                label: t('common.status'),
                isStatusOnly: true,
              },
            ],
          },
          deleteButton: {},
          searchPlaceholder: t('admin.companyList.searchPlaceholder'),
          nameKey: 'nomeFantasia',
        }}
        loading={isLoading}
        hasSelection={true}
        pagination={
          data
            ? {
                Count: data.count,
                PageIndex: data.pageIndex,
                PageSize: data.pageSize,
              }
            : false
        }
        fieldStatus="ativa"
        editOptions={editTableStatus()}
        onDelete={onDeleteCompany}
        onEdit={onEditCompany}
        confirmEditModal={ConfirmEditModal(selectedRowsOnTable, t)}
        rowsOptions={{
          onRowDoubleClick: (x) =>
            history.push(`/admin-bootys/company/${x.externalId}/details`),
        }}
        onRefetch={(pagination) => setPagination(pagination)}
        selectedRowsOnTable={(x) => setSelectedRowsOnTable(x)}
        deleteModal={{
          customEndTitle: t('common.companys'),
          body:
            selectedRowsOnTable?.length > 1 ? (
              DeleteModalOnTable(
                selectedRowsOnTable,
                `${t('modal.deleteModal.many.title')} ${t(
                  'common.theCompanys'
                )}:`,
                'nomeFantasia',
                t('common.companys')
              )
            ) : (
              <DeleteModal selectedRowsOnTable={selectedRowsOnTable} />
            ),
          buttons: {
            okButtonColor: 'status-danger-base',
          },
        }}
        translations={{
          editModal: { customEndTitle: t('common.companys') },
        }}
      />
    </>
  );
};
