import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { Button, Form } from '../../../../../../Components';
import { IEditFinancialProductRequest } from '../../../../../../Data/Interfaces/request/Product/IProductRequest';
import {
  FETCH_GET_PRODUCT,
  useProductFinancialEdit,
  useProductGet,
} from '../../../../../../Hooks/Supply/ProductHooks';
import { FinanceSaleCard } from '../../../Components/FinanceProductCard';
import { FinanceProductSchema } from './FinanceProduct.schema';
import styles from './Finance.module.scss';
import { FinanceInitialValues } from './InitialValues';

interface IFinanceProductTab {
  disabledForm: boolean;
  onDisabledForm?: () => void;
  toogleDisabled: (x: boolean) => void;
}

export const FinanceTab: React.FC<IFinanceProductTab> = ({
  disabledForm,
  onDisabledForm,
  toogleDisabled,
}) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const editFinancialProduct = useProductFinancialEdit();
  const { externalId } = useParams<{ externalId: string }>();
  const { data: product } = useProductGet(externalId);

  const onSubmit = async (values: IEditFinancialProductRequest) => {
    const body: IEditFinancialProductRequest = { ...values };

    if (product) {
      const res = await editFinancialProduct.mutateAsync({
        ...body,
      });
      if (res.success) {
        queryClient.invalidateQueries([FETCH_GET_PRODUCT]);
        toogleDisabled(!disabledForm);
      }
    }
  };

  return (
    <Form
      initialValues={(product && FinanceInitialValues(product)) || undefined}
      onSubmit={onSubmit}
      schema={FinanceProductSchema}
    >
      {(prop) => {
        return (
          <>
            <FinanceSaleCard
              disabledForm={disabledForm}
              product={product || undefined}
            />
            {!disabledForm && (
              <div className={styles['footer-button']}>
                <Button
                  type="secondary"
                  children={t('products.create.cancel')}
                  onClick={onDisabledForm}
                />
                <Button
                  type="primary"
                  children={
                    editFinancialProduct.isLoading
                      ? t('products.create.saveLoading')
                      : t('products.create.save')
                  }
                  loading={editFinancialProduct.isLoading}
                  htmlType="submit"
                  disabled={!prop.dirty}
                />
              </div>
            )}
          </>
        );
      }}
    </Form>
  );
};
