import { IStockBalanceState } from './interfaces/IStockBalanceState';
import {
  getManyStockBalanceKey,
  StockBalanceActionKeys,
  StockBalanceActionUnion,
} from './StockBalance.actions';

const initialData: IStockBalanceState = {
  isLoading: false,
  manyStockBalanceList: {},
};

export const StockBalanceReducer = (
  state = initialData,
  action: StockBalanceActionUnion
): IStockBalanceState => {
  switch (action.type) {
    case StockBalanceActionKeys.LIST_STOCK_BALANCE_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: undefined,
      };
    case StockBalanceActionKeys.LIST_STOCK_BALANCE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: undefined,
        currentStockBalanceList: action.payload,
      };
    case StockBalanceActionKeys.LIST_STOCK_BALANCE_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    case StockBalanceActionKeys.LIST_STOCK_MANY_BALANCE_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: undefined,
      };
    case StockBalanceActionKeys.LIST_STOCK_MANY_BALANCE_SUCCESS:
      const currentState = { ...state.manyStockBalanceList };
      currentState[
        getManyStockBalanceKey(
          action.payload.productId,
          action.payload.placesIds
        )
      ] = action.payload.response;

      return {
        ...state,
        isLoading: false,
        error: undefined,
        manyStockBalanceList: currentState,
      };
    case StockBalanceActionKeys.LIST_STOCK_MANY_BALANCE_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default StockBalanceReducer;
