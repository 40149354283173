import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Button, Form } from '../../../../../Components';
import {
  ConvertDateForApi,
  formatDate,
  formatTime,
} from '../../../../../Utils/DateUtils';
import { MaskForCNPJ } from '../../../../../Utils/MasksUtils';
import { IEditLotRequest } from '../../../../../Data/Interfaces/request/Lot/IPatchLotStatusRequest';
import { history } from '../../../../../Store';
import { InfoCard } from './InfoCard';
import { TechnicalInfoCard } from './TechnicalInfoCard';
import {
  FETCH_GET_LOT,
  FETCH_LIST_LOTS,
  useLotEdit,
  useLotGet,
} from '../../../../../Hooks/Supply/LotHooks';
import { useUserPreferencesType } from '../../../../../Hooks/Accountmanager/UserHooks';
import { useQueryClient } from '@tanstack/react-query';
import { Modal } from '../../../../../Components/Modal';

import styles from './detailsForm.module.scss';
import { useAlternativeUnitMeasurementDropdown } from '../../../../../Hooks/Supply/UnitMeasurementHooks';

interface IDetailsForm {
  disabled: boolean;
  toogleDisabled: (x: boolean) => void;
}

export const DetailsForm: FC<IDetailsForm> = ({ disabled, toogleDisabled }) => {
  const [isBackModalOpen, setIsBackModalOpen] = useState(false);
  const { t } = useTranslation();
  const { externalId } = useParams<{ externalId: string }>();
  const { data: lots } = useLotGet(externalId);
  const { data: datePattern } = useUserPreferencesType();
  const queryClient = useQueryClient();
  const editLot = useLotEdit();

  const onSubmit = async (values: IEditLotRequest) => {
    var body: any = {
      externalId: values.externalId,
      numero: values.numero,
      produtoExternalId: values.produtoExternalId,
      fornecedorExternalId: values.fornecedorExternalId,
      dataFabricacao: values.dataFabricacao,
      dataValidade: values.dataValidade,
      unidadeId: values.unidadeId,
      numeroNf: values.numeroNf,
      serieNf: values.serieNf,
      loteInformacaoTecnica: values.loteInformacaoTecnica,
    };

    if (!datePattern) return;
    if (body.dataFabricacao)
      body.dataFabricacao = ConvertDateForApi(
        body.dataFabricacao,
        datePattern.preferencias.padraoData
      );
    if (body.dataValidade)
      body.dataValidade = ConvertDateForApi(
        body.dataValidade,
        datePattern.preferencias.padraoData
      );
    if (lots) {
      const res = await editLot.mutateAsync({
        ...body,
      });

      if (res.success) {
        queryClient.invalidateQueries([FETCH_GET_LOT]);
        queryClient.invalidateQueries([FETCH_LIST_LOTS]);
        toogleDisabled(!disabled);
      }
    }
  };

  return (
    <>
      <Form
        initialValues={
          lots && {
            ...lots,
            dataLancamento: `${formatDate(
              lots.dataLancamento,
              datePattern?.preferencias.padraoData || 'dd/MM/yyyy HH:mm'
            )} ${t('common.dateBreakApart')} ${formatTime(
              lots?.dataLancamento
            )}`,

            dataFabricacao: formatDate(
              lots.dataFabricacao,
              datePattern?.preferencias.padraoData.replace('mm', 'MM') ||
                'dd/MM/yyyy'
            ),
            dataValidade: formatDate(
              lots.dataValidade,
              datePattern?.preferencias.padraoData.replace('mm', 'MM') ||
                'dd/MM/yyyy'
            ),
            cnpj: MaskForCNPJ(lots?.cnpj),
            saldoLote: `${lots.saldoLote} ${lots.unidadeMedida.abreviacao}`,
          }
        }
        onSubmit={onSubmit}
      >
        {(props) => (
          <>
            <InfoCard disabled={disabled} />
            <TechnicalInfoCard disabled={disabled} />
            {!disabled && (
              <div className={styles['footer-button']}>
                <Button
                  type="secondary"
                  children={t('admin.editUserPage.footerButtons.cancel')}
                  onClick={() =>
                    !!props?.dirty
                      ? setIsBackModalOpen(true)
                      : toogleDisabled(!disabled)
                  }
                />
                <Button
                  type="primary"
                  children={
                    editLot.isLoading
                      ? t('products.create.saveLoading')
                      : t('products.create.save')
                  }
                  htmlType="submit"
                  loading={editLot.isLoading}
                  disabled={!props.dirty}
                />
              </div>
            )}
          </>
        )}
      </Form>

      <Modal
        visible={isBackModalOpen}
        onOkClick={() => history.push('/inventory/lot')}
        onCancelClick={() => setIsBackModalOpen(false)}
        onClose={() => setIsBackModalOpen(false)}
        okButtonName={t('supply.balanceAdjustment.backModal.okButton')}
        title={t('supply.balanceAdjustment.backModal.title')}
        body={
          <div style={{ padding: 20, maxWidth: 400 }}>
            {t('supply.balanceAdjustment.backModal.body')}
          </div>
        }
      />
    </>
  );
};
