import React from 'react';
import { DeleteModalOnTable } from '../../../../Utils/DeleteModalUtils';
import { Text } from '../../../../Components';
import { Divflex } from '../../../../Layouts/DivWhithFlex';
import { FooterThisActionIsPermanent } from '../../../../Components/Table/components/Modals/DeleteModal';
import { IPagination, IQueryParams } from '../../../../Components/Table';

export const TitleHandler = (selectedRowsOnTable: any, t: any) =>
  selectedRowsOnTable?.length === 1
    ? `${t('common.delete')} ${t('common.subGroup')} "${
        selectedRowsOnTable[0]?.descricao
      }"?`
    : `${t('common.delete')} ${selectedRowsOnTable?.length} ${t(
        'common.subGroups'
      )}?`;

export const BodyHandler = (selectedRowsOnTable: any, t: any) =>
  selectedRowsOnTable?.length > 1 ? (
    DeleteModalOnTable(
      selectedRowsOnTable,
      `${t('modal.deleteModal.many.title')} ${t('common.theSubGroups')}:`,
      'descricao',
      t('common.subGroups')
    )
  ) : (
    <div style={{ padding: '20px' }}>
      <Divflex>
        <Text
          color="text-50"
          type="ui-tiny-content"
          children={`${t('modal.deleteModal.single.question')} ${t(
            'common.theSubGroup'
          )} `}
        />
        <Text
          color="text-50"
          type="ui-tiny-bold"
          children={`${
            selectedRowsOnTable && selectedRowsOnTable[0]?.descricao
          }`}
          style={{ marginBottom: '15px' }}
        />
        <Text type="ui-tiny-content" children={'?'} />
      </Divflex>
      <FooterThisActionIsPermanent />
    </div>
  );

export const fetchSubGroupProductParams = (pagination: IPagination) =>
  ({
    select: 'ExternalId,Descricao',
    orderBy: 'Descricao asc',
    pagination,
    expand: [
      {
        expandTitle: 'Grupo',
        select: 'Descricao, ExternalId',
      },
      {
        expandTitle: 'Produtos',
        count: true,
      },
    ],
  } as IQueryParams);
