export const adminBootysEN = {
  Company: 'Company',
  createCompany: {
    companyData: 'Company data',
    companyDataCard: {
      cnpj: 'CNPJ',
      cnpjPlaceholder: '00.000.000/0000-00',

      corporateName: 'Corporate Name',
      corporateNamePlaceholder: 'Eg: PHARMACY LTDA',

      fantasyName: 'Fantasy name',
      fantasyNamePlaceholder: 'Eg: Pharmacy',

      stateRegistration: 'State registration',
      stateRegistrationPlaceholder: 'Eg: 123.456.789.012',

      municipalRegistration: 'Municipal registration',
      municipalRegistrationPlaceholder: 'Eg: 123456',

      cnae: 'CNAE',
      cnaePlaceholder: 'Search CNAE',

      email: 'E-mail',
      emailPlaceholder: 'your@email.com',

      cell: 'telephone',
      cellPlaceholder: '(99) 9999-9999',

      website: 'Website',
      websitePlaceholder: 'example.com',

      edit: 'Edit data',

      errors: {
        requiredCNPJ: 'CNPJ is required',
        invalidCNPJ: 'Invalid CNPJ',

        requiredSocialReason: 'Social reason is required',

        requiredFantasyName: 'Fantasy name is required',

        requiredStateRegistration: 'State registration is required',
        invalidStateRegistration:
          'State registration must be at least 12 characters',

        requiredMunicipalRegistration: 'Municipal registration is required',
        invalidMunicipalRegistration: 'Invalid municipal registration',

        requiredCNAE: 'CNAE is required',

        invalidMail: 'Invalid email',
      },
    },
    CompanyAdress: 'Companys adress',
    CompanyAdressCard: {
      cep: 'CEP',
      cepPlaceholder: '00000-000',

      street: 'Public place',
      streetPlaceholder: 'Public place',

      houseNumber: 'Number',
      houseNumberPlaceholder: 'Ex: 401',

      complement: 'Complement',
      complementPlaceholder: 'Ex: Sala 01',

      neighborhood: 'Neighborhood',
      neighborhoodPlaceholder: 'Neighborhood',

      city: 'City',
      cityPlaceholder: 'City',

      state: 'State',
      statePlaceholder: 'State',

      edit: 'Edit Address',

      errors: {
        requiredCEP: 'CEP is required',
        requiredNumber: 'Number is required',
        requiredNeighborhood: 'Neighborhood is required',
        requiredCity: 'City is required',
        requiredState: 'State is required',
        requiredStreet: 'Street is required',
        invalidEmail: 'Invalid e-mail',
      },

      success: {
        companyAdressSuccess: 'Updated company address.',
      },
    },

    responsibleUser: 'Responsible user',
    responsibleUserCard: {
      newUser: 'Create new user',

      existingUser: 'Select existing user',

      email: 'E-mail',
      emailPlaceholder: 'email@example.com',

      name: 'Name',
      namePlaceholder: 'First name',

      lastName: 'Last name',
      lastNamePlaceholder: 'Last name',

      cpf: 'CPF',
      cpfPlaceholder: '000.000.000-00',

      bithDate: 'Date of birth',

      cell: 'Telephone',
      cellPlaceholder: '(00) 00000-0000',

      user: 'User',
      userPlaceholder: 'Search for a user by name or email',
      edit: 'Edit user',

      responsibleUserChangeSuccess: 'responsible user successfully changed',
      responsiblesUserChangeSuccess: 'responsibles user successfully changed',

      radioTooltip:
        'It is not possible to select an existing user if the option "Create new company group" is selected.',

      errors: {
        invalidMail: 'Invalid e-mail',
        requiredMail: 'E-mail is required',

        requiredName: 'Name is required',

        requiredLastName: 'Last name is required',

        requiredUser: 'User is required',

        invalidBirthday: 'Invalid birth day',

        invalidCpf: 'Invalid CPF',
      },
    },

    companyGroup: 'Company group',
    companyGroupCard: {
      newCompany: 'Create new company group',

      existingCompany: 'Select existing company group',

      groupName: 'Groups name',
      groupNamePlaceholder: 'Write a name for the company group',

      companyGroup: 'Company group',
      companyGroupPlaceholder: 'Search for a company group',

      subsidiary: 'Function in Group',
      subsidiaryPlaceholder: 'Select a function',

      edit: 'Edit Group',

      roleInGroup: 'Role in the Group',
      branch: 'Branch',
      headquarters: 'Headquarters',

      errors: {
        requiredGroupName: 'Company group name is required',
        requiredGroup: 'Company group is required',
      },
    },

    billing: 'Billing',
    billingCard: {
      ownBilling: 'Own billing',
      companyBilling: 'Select company for billing',
      companySelect: 'Company for invoicing',
      companySelectPlaceholder: 'Select a company',
      edit: 'Edit billing',

      radioTooltip:
        'It is not possible to select a company for billing if the option "Create new company group" is selected.',

      errors: {
        requiredCompanyBilling: 'Company for billing is required',
      },

      success: 'Paying company successfully exchanged',
    },

    messages: {
      success: 'Created company!',
    },

    footerButton: {
      cancel: 'Cancelar',
      submit: 'Cadastrar empresa',
    },
  },

  companyList: {
    searchPlaceholder: 'Search for a company',
    tableTitle: {
      fantasyName: 'Fantasy name',
      cnpj: 'CNPJ',
      city: 'City',
    },
    deleteModal: {
      see: 'See Companies',
      delete: 'Delete',
      titleNotDelete: ' cannot be deleted!',
      titleNotManyPre: 'Could not delete ',
      titleNotManyAfter: ' groups!',
      single: {
        title: 'Unable to delete group ',
        bodyPre: 'The group has ',
        bodyPos:
          ' companies linked to it. Unlink all companies to be able to delete the group.',
      },
      many: {
        title: 'Unable to delete business groups:',
        body: 'The groups have companies linked to them. Unlink all companies to be able to delete them',
      },
    },
    editModal: {
      confirmDescriptionForOneCompany:
        'This action will prevent all users of this company from accessing the system!',
      confirmDescriptionForManyCompanys:
        'This action will prevent all users from accessing the system!',
    },
  },
  companyUpdated: 'Company updated!',

  conglomerateList: {
    searchPlaceholder: 'Search for a company group or parent company',
    tableTitle: {
      name: 'Group name',
      headquarters: 'Headquarters',
      companys: 'Companys',
      inclusionDate: 'Inclusion date',
    },
    deleteConglomerateModal: {
      bodyStart: 'Are you sure you want to delete the company group',
    },
    deleteModal: {
      see: 'See company',
      delete: 'Delete',
      titleNotDelete: ' cannot be deleted!',
      titleNotManyPre: 'We could not delete ',
      titleNotManyAfter: ' groups!',
      single: {
        title: 'We could not delete group ',
        bodyPre: 'The group have ',
        bodyPos:
          ' companies attached to it. Remove all companies to be able to delete this group.',
      },
      many: {
        title: 'We could not delete company groups:',
        body: 'The groups does have companies attached to it. Remove all companies to be able to delete it.',
      },
    },
  },

  editConglomerate: {
    tab: {
      details: 'Details',

      conglomerateNameRequired: 'Conglomerate name is required!',
      conglomerateheadquartersRequired: 'Company matrix is ​​required!',

      company: 'Companys',
    },
    conglomerateData: 'Conglomerate data',
    conglomerateDataCard: {
      conglomerateName: 'Group name',
      inclusionDate: 'Inclusion date',
      headquarters: 'Headquarters',
    },

    successMessage: 'Conglomerado updated!',
    conflictModal: {
      title: 'Change status of',
      content:
        'Are you sure that you want to change the status of the company group',
      okButton: 'Change status',
    },
  },
};

export const adminBootysPT = {
  Company: 'Empresa',
  createCompany: {
    companyData: 'Dados da empresa',
    companyDataCard: {
      cnpj: 'CNPJ',
      cnpjPlaceholder: '00.000.000/0000-00',

      corporateName: 'Razão Social',
      corporateNamePlaceholder: 'Ex: FARMACIA SOUZA E FILHOS LTDA',

      fantasyName: 'Nome Fantasia',
      fantasyNamePlaceholder: 'Ex: Farmácia Souza e Filhos',

      stateRegistration: 'Inscrição Estadual',
      stateRegistrationPlaceholder: 'Ex: 123.456.789.012',

      municipalRegistration: 'Inscrição Municipal',
      municipalRegistrationPlaceholder: 'Ex: 123456',

      cnae: 'CNAE',
      cnaePlaceholder: 'Pesquisar CNAE',

      email: 'E-mail de Contato',
      emailPlaceholder: 'seu@email.com',

      cell: 'Telefone',
      cellPlaceholder: '(99) 9999-9999',

      website: 'Website',
      websitePlaceholder: 'Ex: example.com',

      edit: 'Editar Dados',

      errors: {
        requiredCNPJ: 'CNPJ é obrigatório',
        invalidCNPJ: 'CNPJ inválido',

        requiredSocialReason: 'Razão social é obrigatório',

        requiredFantasyName: 'Nome fantazia é obrigatório',

        requiredStateRegistration: 'Inscrição Estadual é obrigatório',
        invalidStateRegistration:
          'Inscrição Estadual tem menos que 12 caracteres',

        requiredMunicipalRegistration: 'Inscrição Municipal é obrigatório',
        invalidMunicipalRegistration:
          'Inscrição Municipal tem menos que 12 caracteres',

        requiredCNAE: 'CNAE é obrigatório',

        invalidMail: 'E-mail inválido',
      },
    },

    CompanyAdress: 'Endereço da empresa',
    CompanyAdressCard: {
      cep: 'CEP',
      cepPlaceholder: '00000-000',

      street: 'Logradouro',
      streetPlaceholder: 'Logradouro',

      houseNumber: 'Número',
      houseNumberPlaceholder: 'Ex: 401',

      complement: 'Complemento',
      complementPlaceholder: 'Ex: Sala 01',

      neighborhood: 'Bairro',
      neighborhoodPlaceholder: 'Bairro',

      city: 'Cidade',
      cityPlaceholder: 'Cidade',

      state: 'Estado',
      statePlaceholder: 'Estado',

      edit: 'Editar Endereço',

      errors: {
        requiredCEP: 'CEP é obrigatório',
        requiredNumber: 'Numero é obrigatório',
        requiredNeighborhood: 'Bairro é obrigatório',
        requiredCity: 'Cidade é obrigatório',
        requiredState: 'Estado é obrigatório',
        requiredStreet: 'Logradouro é obrigatório',
        invalidEmail: 'E-mail inválido',
      },

      success: {
        companyAdressSuccess: 'Endereço de empresa atualizado.',
      },
    },

    responsibleUser: 'Usuário responsável',
    responsibleUserCard: {
      newUser: 'Cadastrar novo usuário',

      existingUser: 'Selecionar usuário existente',

      email: 'E-mail',
      emailPlaceholder: 'email@example.com',

      name: 'Nome',
      namePlaceholder: 'Primeiro nome',

      lastName: 'Sobrenome',
      lastNamePlaceholder: 'Sobrenome',

      cpf: 'CPF',
      cpfPlaceholder: '000.000.000-00',

      bithDate: 'Data de nascimento',

      cell: 'Telefone',
      cellPlaceholder: '(00) 00000-0000',

      user: 'Usuário',
      userPlaceholder: 'Pesquise um usuário pelo nome ou e-mail',

      edit: 'Editar Usuário',

      responsibleUserChangeSuccess: 'Usuário responsável trocado com sucesso',
      responsiblesUserChangeSuccess:
        'Usuários responsáveis trocado com sucesso',

      radioTooltip:
        'Não é possível selecionar um usuário existente caso a opção de "Criar novo grupo de empresa" esteja selecionada.',

      errors: {
        invalidMail: 'E-mail inválido',

        requiredMail: 'E-mail é obrigatório',

        requiredName: 'Nome é obrigatório',

        requiredLastName: 'Sobrenome é obrigatório',

        requiredUser: 'Usuário é obrigatório',

        invalidBirthday: 'Data de nascimento inválido',

        invalidCpf: 'CPF inválido',
      },
    },

    companyGroup: 'Grupo de empresa',
    companyGroupCard: {
      newCompany: 'Criar novo grupo de empresa',

      existingCompany: 'Selecionar grupo de empresa existente',

      groupName: 'Nome do grupo',
      groupNamePlaceholder: 'Escreva um nome para o grupo de empresa',

      companyGroup: 'Grupo de empresa',
      companyGroupPlaceholder: 'Pesquise por um grupo de empresa',

      subsidiary: 'Função no grupo',
      subsidiaryPlaceholder: 'Selecione uma função',

      edit: 'Editar Grupo',

      roleInGroup: 'Função no Grupo',
      branch: 'Filial',
      headquarters: 'Matriz',

      errors: {
        requiredGroupName: 'Nome do grupo de empresa é obrigatório',
        requiredGroup: 'Grupo de empresa é obrigatório',
      },
    },
    billing: 'Faturamento',
    billingCard: {
      ownBilling: 'Faturamento próprio',
      companyBilling: 'Selecionar empresa para faturamento',
      companySelect: 'Empresa para faturamento',
      companySelectPlaceholder: 'Selecione uma empresa',
      edit: 'Editar dados de faturamento',

      radioTooltip:
        'Não é possível selecionar uma empresa para faturamento caso a opção de "Criar novo grupo de empresa" esteja selecionada.',

      errors: {
        requiredCompanyBilling: 'Empresa para faturamento é obrigatório',
      },

      success: 'Empresa pagadora trocada com sucesso',
    },

    messages: {
      success: 'Empresa criada!',
    },

    footerButton: {
      cancel: 'Cancelar',
      submit: 'Cadastrar empresa',
    },
  },

  adminLabel: 'Admin',
  admin: {
    user: 'Usuários',

    createUserPage: {
      personalData: 'Dados pessoais',
      personalDataCard: {
        name: 'Nome',
        namePlaceholder: 'Primeiro nome',

        lastName: 'Sobrenome',
        lastNamePlaceholder: 'Sobrenome',

        cpf: 'CPF',
        cpfPlaceholder: '000.000.000-00',

        errors: {
          requiredEmail: 'E-mail é obrigatório',
          requiredGroup: 'Grupo é obrigatório',
          requiredDepartament: 'Departamento é obrigatório',
        },
      },

      errors: {
        requiredName: 'Nome é obrigatório',
        requiredLastName: 'Sobrenome é obrigatório',
      },
    },

    Access: 'Acesso',
    AccessDataCard: {
      email: 'E-mail',
      emailPlaceholder: 'email@example.com',

      alternativeEmail: 'E-mail alternativo',
      alternativeEmailPlaceholder: 'email@example.com',

      group: 'Grupos',
      groupPlaceholder: 'Pesquise por um grupo',

      errors: {
        requiredEmail: 'E-mail é obrigatório',
        requiredGroup: 'Grupo é obrigatório',
      },
    },

    footerButton: {
      cancel: 'Cancelar',
      submit: 'Cadastrar operador',
    },
  },

  companyList: {
    searchPlaceholder: 'Pesquise por uma empresa',
    tableTitle: {
      fantasyName: 'Nome Fantasia',
      cnpj: 'CNPJ',
      city: 'Cidade/UF',
    },
    deleteModal: {
      see: 'Ver Empresas',
      delete: 'Excluir',
      titleNotDelete: ' não pode ser excluido!',
      titleNotManyPre: 'Não foi possível excluir ',
      titleNotManyAfter: ' grupos!',
      single: {
        title: 'Não é possível excluir o grupo ',
        bodyPre: 'O grupo tem ',
        bodyPos:
          ' empresas vinculadas a ele. Desvincule todas as empresas para conseguir excluir o grupo.',
      },
      many: {
        title: 'Não foi possível excluir os grupos de empresas:',
        body: 'Os grupos tem empresas vinculadas a eles. Desvincule todas as empresas para conseguir excluí-los',
      },
    },
    editModal: {
      confirmDescriptionForOneCompany:
        'Essa ação impedirá todos os usuários desta empresa de acessar o sistema!',
      confirmDescriptionForManyCompanys:
        'Essa ação impedirá os usuários de acessarem o sistema!',
    },
  },

  companyUpdated: 'Empresa atualizada!',

  conglomerateList: {
    searchPlaceholder: 'Pesquise por um grupo de empresa ou matriz',
    tableTitle: {
      name: 'Nome do Grupo',
      headquarters: 'Matriz',
      companys: 'Empresas',
      inclusionDate: 'Data inclusão',
    },
    deleteConglomerateModal: {
      bodyStart: 'Você tem certeza que deseja excluir o grupo de empresas',
    },
    deleteModal: {
      see: 'Ver empresas',
      delete: 'Deletar',
      titleNotDelete: ' não pode ser deletado!',
      titleNotManyPre: 'Não podemos deletar ',
      titleNotManyAfter: ' grupos!',
      single: {
        title: 'Não foi possível excluir o grupo ',
        bodyPre: 'O grupo tem ',
        bodyPos:
          ' empresas a ele vinculadas. Remova todas as empresas para poder excluir este grupo.',
      },
      many: {
        title: 'Não foi possível excluir grupos de empresas:',
        body: 'Os grupos têm empresas ligadas a ele. Remova todas as empresas para poder excluí-lo.',
      },
    },
  },

  editConglomerate: {
    tab: {
      details: 'Detalhes',

      conglomerateNameRequired: 'Nome do conglomerado é obrigatório!',
      conglomerateheadquartersRequired: 'Matriz é obrigatório!',

      company: 'Empresas',
    },
    conglomerateData: 'Dados do grupo',
    conglomerateDataCard: {
      conglomerateName: 'Nome do grupo',
      inclusionDate: 'Data de inclusão',
      headquarters: 'Matriz',
    },

    successMessage: 'Conglomerado atualizado!',
    conflictModal: {
      title: 'Alterar status de',
      content:
        'Você tem certeza que deseja alterar o status do grupo de empresas',
      okButton: 'Alterar status',
    },
  },
};
