import { useQuery } from '@tanstack/react-query';
import { IPagination } from '../../Components/Table';
import { PurchaseAPI } from '../../Data/API/Purchase/PurchaseOrderApi';

export const FETCH_LIST_PURCHASE_ORDER =
  '[PURCHASE_ORDER] FETCH_LIST_PURCHASE_ORDER';

export const usePurchaseOrderList = (pagination: IPagination) =>
  useQuery(
    [FETCH_LIST_PURCHASE_ORDER, pagination],
    PurchaseAPI.getPurchaseOrderList,
    {
      staleTime: 60000,
    }
  );
