import { useMutation, useQuery } from '@tanstack/react-query';
import { i18n } from '../../Components';
import { IPagination } from '../../Components/Table';
import { CompanyAPI } from '../../Data/API/Accountmanager/CompanyApi';
import { IQueryListParams } from '../../Utils/Http';
import {
  messageErrorToast,
  messageSucessToast,
} from '../../Utils/MessageErrorToast';
import { IBasicMutationOptions } from '../interfaces/IBasicOptions';

const CREATE_COMPANY_TYPE = '[COMPANY] CHANGE_COMPANY_TYPE';
export const GET_COMPANY_ADMIN_TYPE = '[COMPANY] GET_COMPANY_ADMIN_TYPE';
export const GET_COMPANY_TYPE = '[COMPANY] GET_COMPANY_TYPE';

const CHANGE_STATUS_TYPE = '[COMPANY] CHANGE_STATUS_TYPE';
const FETCH_DELETE_TYPE = '[COMPANY] FETCH_DELETE_TYPE';

const CHANGE_INFO_TYPE = '[COMPANY] CHANGE_INFO_TYPE';
const CHANGE_ADRESS_TYPE = '[COMPANY] CHANGE_ADRESS_TYPE';
const CHANGE_RESPONSIBLE_USER_TYPE = '[COMPANY] CHANGE_RESPONSIBLE_USER_TYPE';
const CHANGE_PAYING_TYPE = '[COMPANY] CHANGE_PAYING_TYPE';

export const LIST_COMPANY_TYPE = '[COMPANY] LIST_COMPANY_TYPE';
export const LIST_COMPANY_ADMIN_TYPE = '[COMPANY] LIST_COMPANY_ADMIN_TYPE';
export const LIST_FOR_DORPDOWN_ADMIN_TYPE =
  '[COMPANY] LIST_FOR_DORPDOWN_ADMIN_TYPE';
export const LIST_FOR_DROPDOWN_TYPE = '[COMPANY] LIST_FOR_DORPDOWN_TYPE';

export const useCompanyCreateMutation = () =>
  useMutation([CREATE_COMPANY_TYPE], CompanyAPI.createCompany, {
    onError: (x) => messageErrorToast(x),
    onSuccess: () =>
      messageSucessToast(i18n.t('adminBootys.createCompany.messages.success')),
  });

export const useCompanyAdminTypes = (externalId: string) =>
  useQuery([GET_COMPANY_ADMIN_TYPE, externalId], CompanyAPI.getCompanyAdmin);
export const useCompanyTypes = (externalId: string) =>
  useQuery([GET_COMPANY_TYPE, externalId], CompanyAPI.getCompany);

export const useCompanyChangeStatusMutation = () =>
  useMutation([CHANGE_STATUS_TYPE], CompanyAPI.patchCompanyStatus);
export const useCompanyDeleteMutation = () =>
  useMutation([FETCH_DELETE_TYPE], CompanyAPI.deleteCompany, {
    onError: (x) => messageErrorToast(x),
  });

export const useCompanyChangeInfoMutation = () =>
  useMutation([CHANGE_INFO_TYPE], CompanyAPI.changeInfoCompany);
export const useCompanyChangeAdressMutation = (
  options?: IBasicMutationOptions
) => useMutation([CHANGE_ADRESS_TYPE], CompanyAPI.changeAdressCompany, options);
export const useCompanyChangeResponsibleUserMutation = (
  options?: IBasicMutationOptions
) =>
  useMutation(
    [CHANGE_RESPONSIBLE_USER_TYPE],
    CompanyAPI.changeResponsibilytiUser,
    options
  );
export const useCompanyChangePayingMutation = () =>
  useMutation([CHANGE_PAYING_TYPE], CompanyAPI.changeCompanyPaying);

export const useCompanyListType = (pagination: IQueryListParams) =>
  useQuery([LIST_COMPANY_TYPE, pagination], CompanyAPI.listCompany);
export const useCompanyListAdminType = (pagination: IQueryListParams) =>
  useQuery([LIST_COMPANY_ADMIN_TYPE, pagination], CompanyAPI.listCompanyAdmin);
export const useCompanyListForDropdownAdminType = (
  pagination: IQueryListParams
) =>
  useQuery(
    [LIST_FOR_DORPDOWN_ADMIN_TYPE, pagination],
    CompanyAPI.listCompanyAdminForDropdown
  );
export const useCompanyGetDropdown = (pagination: IPagination) =>
  useQuery(
    [LIST_FOR_DROPDOWN_TYPE, pagination],
    CompanyAPI.listCompanyForDropdown
  );
