import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTransferGet } from '../../../../../../../Hooks/Supply/TransferHooks';

export const DynamicInfosTransfersBreadcrumb = ({ match }: any) => {
  const { data: transfers } = useTransferGet(match.params.externalId);
  const { t } = useTranslation();
  return (
    <span style={{ display: 'flex' }}>
      {`${t('breadCrumb.transfers.detail.title')} | #${
        transfers ? transfers?.sequenciaNumeroTransferencia : ''
      }`}
    </span>
  );
};

export const DynamicTransfersBreadcrumb = ({ match }: any) => {
  const { data: transfers } = useTransferGet(match.params.externalId);
  return (
    <span style={{ display: 'flex' }}>
      {transfers?.sequenciaNumeroTransferencia}
    </span>
  );
};
