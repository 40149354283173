import React from 'react';
import { Tabs } from 'antd';
import { ITabs } from '../Tabs';
import { useLocation } from 'react-router-dom';
import { history } from '../../Store';

import './HorizontalTabs.override.scss';
import styles from './HorizontalTabs.module.scss';
import { IButtonDropdown, ButtonDropdown } from '../Button/ButtonDropdown';
import { Button } from '../Button';
import { IconName } from '../Icon/IconClasses';
import { useTranslation } from 'react-i18next';
import {
  hasPermission,
  PermissionWrapper,
} from '../../Components/PermissionWrapper';

export interface IPermissionEditButon {
  onClick?: () => void;

  leftIcon?: IconName;
  children?: string;
  isVisible?: boolean;
  codePermissionEdit?: number;
  messageTooltipPermission?: string;
}

interface IHorizontalTabs extends ITabs, IButtonDropdown {
  rigthButton?: IPermissionEditButon;
  leftButton?: IPermissionEditButon;
}

export const HorizontalTabs: React.FC<IHorizontalTabs> = ({
  items,
  dropdownItens,
  dropdownTitle,
  leftIcon,
  rigthButton,
  leftButton,
  type,
}) => {
  const { t } = useTranslation();
  const cuurentRoute = useLocation().pathname.split('/');
  const permissionEdit = !hasPermission(rigthButton?.codePermissionEdit);

  return (
    <div style={{ position: 'relative' }}>
      <Tabs
        className={styles['container']}
        activeKey={cuurentRoute[cuurentRoute.length - 1]}
        onChange={(key) => history.push(key)}
        items={items?.map((x) => ({
          label: x.label,
          key: x.key,
          children: x.children,
          disabled: x.disabled,
        }))}
        tabBarExtraContent={
          rigthButton?.isVisible && {
            right: (
              <PermissionWrapper
                permission={rigthButton.codePermissionEdit}
                tooltip={rigthButton.messageTooltipPermission}
              >
                <Button
                  className={`${
                    dropdownItens && !permissionEdit
                      ? styles['button-with-permission']
                      : ''
                  } ${styles['button-edit']}`}
                  type="secondary"
                  children={rigthButton.children || t('common.edit')}
                  leftIcon={rigthButton.leftIcon || 'edit'}
                  onClick={rigthButton.onClick}
                  disabled={permissionEdit}
                />
              </PermissionWrapper>
            ),
          }
        }
      />
      {items[0]?.disabled && (
        <Tabs
          activeKey={cuurentRoute[cuurentRoute.length - 1]}
          onChange={(key) => history.push(key)}
          items={items?.map((x) => ({
            label: x.label,
            key: x.key,
            children: x.children,
            disabled: x.disabled,
          }))}
        />
      )}
      {dropdownItens && (
        <ButtonDropdown
          className={styles['button-action']}
          dropdownDirection="bottomRight"
          dropdownItens={dropdownItens}
          dropdownTitle={dropdownTitle}
          leftIcon={leftIcon}
          type={type}
        />
      )}
      {leftButton?.isVisible && (
        <Button
          className={`${
            !rigthButton?.isVisible
              ? styles['buttonLeft-without-buttonRight']
              : ''
          } ${styles['rigth-button-two']}`}
          type="secondary"
          children={leftButton.children || t('common.edit')}
          leftIcon={leftButton.leftIcon || 'edit'}
          onClick={leftButton.onClick}
        />
      )}
    </div>
  );
};
