import React, { FC, memo, ReactNode, useEffect, useRef } from 'react';
import { IText } from '../Text';

import './Highlight.override.scss';

export interface IHighlight extends IText {
  text: string;
  highlight?: string;
}

export interface IHighlightWithRender extends IText {
  children: ReactNode;
  highlight: string;
}

export const Highlight: FC<IHighlight> = memo(
  ({ children, text, highlight, ...props }) => {
    const contentRef = useRef() as React.MutableRefObject<HTMLInputElement>;

    useEffect(() => {
      if (!text || !highlight || !contentRef?.current?.innerHTML) return;

      const tagOpen = `<span class="highlight">`;
      const tagClose = `</span>`;

      let current = text;

      var regEx = new RegExp(highlight, 'ig');

      const index = text
        .toString()
        .toLocaleUpperCase()
        .indexOf(highlight.toLocaleUpperCase());

      if (index === -1) return;

      const currentHighlight = text.substring(index, index + highlight.length);

      current = current.replaceAll(
        regEx,
        `${tagOpen}${currentHighlight}${tagClose}`
      );

      contentRef.current.innerHTML = contentRef.current.innerHTML.replace(
        text,
        current
      );
    }, [contentRef, highlight, props, text]);
    return <div ref={contentRef}>{children ? children : text}</div>;
  }
);
