import {
  BalanceAdjustmentsActionKeys,
  BalanceAdjustmentsActionUnion,
} from './BalanceAdjustments.actions';
import { IBalanceAdjustmentsState } from './interfaces/IBalanceAdjustmentsState';

const initialData: IBalanceAdjustmentsState = {
  isLoading: false,
};

const balanceAdjustmentsReducer = (
  state = initialData,
  action: BalanceAdjustmentsActionUnion
): IBalanceAdjustmentsState => {
  switch (action.type) {
    case BalanceAdjustmentsActionKeys.SET_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };

    case BalanceAdjustmentsActionKeys.GET_SINGLE_BALANCE_ADJUSTMENT_SUCCESS:
      return {
        ...state,
        singleBalanceAdjustments: action.payload,
      };

    case BalanceAdjustmentsActionKeys.BALANCE_ADJUSTMENTS_LIST_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: undefined,
      };

    case BalanceAdjustmentsActionKeys.BALANCE_ADJUSTMENTS_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: undefined,
        currentBalanceAdjustmentsList: action.payload,
      };
    case BalanceAdjustmentsActionKeys.BALANCE_ADJUSTMENTS_LIST_FALUIRE:
      return {
        ...state,
        isLoading: false,
        error: undefined,
      };

    default:
      return state;
  }
};

export default balanceAdjustmentsReducer;
