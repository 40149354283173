import { Col, Row } from 'antd';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Card,
  Dropdown,
  Form,
  TextInput,
  Tooltip,
} from '../../../../../Components';
import { patternLabelStyle } from '../../../../../Components/Inputs/TextInput';

import styles from './DetailsForm.module.scss';
import { useParams } from 'react-router';
import {
  FETCH_GET_SUBGROUP,
  useGroupGetDropdown,
  useSubGroupEdit,
  useSubGroupGet,
  useSubGroupValidationName,
} from '../../../../../Hooks/Supply/ProductHooks';
import { IEditProductSubGroupRequest } from '../../../../../Data/Interfaces/request/Product/IProductRequest';
import { FormikHelpers, FormikValues, useFormikContext } from 'formik';
import { useQueryClient } from '@tanstack/react-query';
import _ from 'lodash';
import { Modal } from '../../../../../Components/Modal';
import { history } from '../../../../../Store';
import { SubGroupDetailsSchema } from './SubgroupDetails.schema';

interface IDetailsForm {
  disabled: boolean;
  onChangeDisabled: (x: boolean) => void;
}

export const DetailsForm: FC<IDetailsForm> = ({
  disabled,
  onChangeDisabled,
}) => {
  const { externalId } = useParams<{ externalId: string }>();

  const { data } = useSubGroupGet(externalId);
  const editSubGroup = useSubGroupEdit();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [isBackModalOpen, setIsBackModalOpen] = useState(false);

  const onSubmit = async (
    values: IEditProductSubGroupRequest,
    helpers: FormikHelpers<FormikValues>
  ) => {
    const body: IEditProductSubGroupRequest = { ...values, helpers };

    if (data) {
      const res = await editSubGroup.mutateAsync({
        subGrupoExternalId: data?.externalId,
        descricao: body?.descricao,
        grupoPaiExternalId: body?.grupoPaiExternalId,
        helpers,
      });
      if (res.success) {
        queryClient.invalidateQueries([FETCH_GET_SUBGROUP]);
        onChangeDisabled(!disabled);
      }
    }
  };

  return (
    <>
      <Form
        initialValues={{
          ...data,
          descricao: data?.subGrupoDescricao,
          grupoPaiExternalId: data?.grupoExternalId,
        }}
        onSubmit={onSubmit}
        schema={SubGroupDetailsSchema}
      >
        {(props) => {
          return (
            <Card title={t('common.details')} withoutCollapse={true}>
              <Row gutter={[16, 0]}>
                <InputDescriptionSubGroup disabled={disabled} />
              </Row>
              {!disabled && (
                <div className={styles['footer-button']}>
                  <Button
                    type="secondary"
                    children={t('common.cancel')}
                    onClick={() =>
                      !!props?.dirty
                        ? setIsBackModalOpen(true)
                        : onChangeDisabled(!disabled)
                    }
                  />
                  <Button
                    type="primary"
                    children={t('common.save')}
                    htmlType="submit"
                    disabled={!props.dirty}
                  />
                </div>
              )}
            </Card>
          );
        }}
      </Form>

      <Modal
        visible={isBackModalOpen}
        onOkClick={() => history.push('/inventory/sub-groups')}
        onCancelClick={() => setIsBackModalOpen(false)}
        okButtonName={t('supply.balanceAdjustment.backModal.okButton')}
        title={t('supply.balanceAdjustment.backModal.title')}
        body={
          <div style={{ padding: 20, maxWidth: 400 }}>
            {t('supply.balanceAdjustment.backModal.body')}
          </div>
        }
      />
    </>
  );
};

interface IInputDescriptionSubGroup {
  disabled: boolean;
  onDisabledButton?: (value: boolean) => void;
}

const InputDescriptionSubGroup: FC<IInputDescriptionSubGroup> = ({
  disabled,
  onDisabledButton,
}) => {
  const form: any = useFormikContext();
  const { t } = useTranslation();

  const onGroupValidationError = useCallback(() => {
    form?.setErrors({
      ...form.errors,
      descricao: t('products.create.errors.errorSubGroupExist'),
    });
  }, [form, t]);

  const { mutateAsync } = useSubGroupValidationName({
    onError: onGroupValidationError,
  });
  const { externalId } = useParams<{ externalId: string }>();
  const { data } = useSubGroupGet(externalId);
  const { data: dataGroupDropDown } = useGroupGetDropdown({
    PageIndex: 0,
    PageSize: 10,
  });

  useEffect(() => {
    onDisabledButton &&
      onDisabledButton(
        form?.values?.descricao && form?.values?.grupoPaiExternalId
      );
  }, [
    onDisabledButton,
    form?.values?.grupoPaiExternalId,
    form?.values?.descricao,
  ]);

  const fetchProductSubGroupValidationName = useMemo(
    () =>
      _.debounce(async (value?: string, groupExternalId?: string) => {
        return (
          groupExternalId &&
          value &&
          (await mutateAsync({
            descricao: value,
            grupoExternalId: groupExternalId,
          }))
        );
      }, 500),
    [mutateAsync]
  );

  return (
    <>
      <Col span={7}>
        <Tooltip
          title={`${t('products.toolTips.subGroups.nameOfSubGroup')} ${
            data?.subGrupoDescricao
          }`}
          showMe={
            data?.subGrupoDescricao !== form?.values?.descricao && !disabled
          }
        >
          <TextInput
            className={styles['text-input']}
            name="descricao"
            disabled={disabled}
            withoutMarginBottom={disabled}
            withStatusIcon
            required={!disabled}
            label={[
              {
                children: t('products.subGroupList.edit.name'),
                ...patternLabelStyle,
              },
            ]}
            onChange={(x) => {
              fetchProductSubGroupValidationName(x, data?.grupoExternalId);
            }}
          />
        </Tooltip>
      </Col>
      <Col span={7}>
        <Tooltip
          title={`${t('products.toolTips.subGroups.nameOfGroup')} ${
            data?.grupoDescricao
          }`}
          showMe={
            data?.grupoExternalId !== form?.values?.grupoPaiExternalId &&
            !disabled
          }
        >
          <Dropdown
            className={styles['text-input']}
            name="grupoPaiExternalId"
            disabled={disabled}
            withoutMarginBottom={disabled}
            withStatusIcon
            required={!disabled}
            label={[
              {
                children: t('products.subGroupList.edit.group'),
                ...patternLabelStyle,
              },
            ]}
            items={dataGroupDropDown?.data.map((x: any) => ({
              id: x.externalId,
              name: x.descricao,
            }))}
            onChange={(x) => {
              fetchProductSubGroupValidationName(data?.subGrupoDescricao, x);
            }}
          />
        </Tooltip>
      </Col>
    </>
  );
};
