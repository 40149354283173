import { UserActions } from '../../Store/User/User.actions';
import { SideMenuItems } from './SideMenu.items';
import { history } from '../../Store';
import { IMenuItem } from './MenuItem';

export const redirectToDefaultContext = (
  dispatch: any,
  location: any,
  modules?: string[]
) => {
  const searchRoutes = (item: IMenuItem): boolean => {
    const hasLink = location.pathname.includes(item.link);

    if (!hasLink) {
      const childrens = item.children;

      if (!childrens || childrens.length <= 0) {
        return false;
      }

      return childrens.some((items) => searchRoutes(items));
    }
    return hasLink;
  };

  const currentContext = SideMenuItems.find((context) =>
    context.items.some((route) => searchRoutes(route))
  );

  if (!!currentContext) {
    dispatch(UserActions.changeCurrentContext(currentContext.context.id));
  } else {
    if (modules) {
      const context = SideMenuItems.find((c) => modules.includes(c.context.id));
      if (!context) return;

      dispatch(UserActions.changeCurrentContext(context.context.id));
      history.push(context?.items[0].link);
    }
  }
};
