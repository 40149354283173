import { useMutation, useQuery } from '@tanstack/react-query';
import { IPagination } from '../../Components/Table';
import { TransferAPI } from '../../Data/API/Supply/TransferApi';

export const FETCH_LIST_TRANSFER = '[TRANSFER] FETCH_LIST_TRANSFER';
export const FETCH_GET_TRANSFER = '[TRANSFER] FETCH_GET_TRANSFER';

export const CREATE_TRANSFER = '[TRANSFER] CREATE_TRANSFER';

export const useTransferGetList = (pagination: IPagination) =>
  useQuery([FETCH_LIST_TRANSFER, pagination], TransferAPI.listTransfer);

export const useTransferGet = (externalId: string) =>
  useQuery([FETCH_GET_TRANSFER, externalId], TransferAPI.getTransfer);

export const useTransferCreate = () =>
  useMutation([CREATE_TRANSFER], TransferAPI.createTransfer);
