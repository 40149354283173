import { IUserState } from './interfaces/IUserState';
import { UserActionKeys, UserActionUnion } from './User.actions';
import {
  IListUserResponse,
  IModulo,
} from '../../Data/Interfaces/response/User/IGetUserReponse';

const initialData: IUserState = {
  isLoading: false,
  showPermissionDependencyModal: false,
};

const userReducer = (
  state = initialData,
  action: UserActionUnion
): IUserState => {
  switch (action.type) {
    case UserActionKeys.SET_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };

    case UserActionKeys.GET_ME_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: undefined,
      };
    case UserActionKeys.GET_ME_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: undefined,
        currentUser: action.payload,
      };
    case UserActionKeys.GET_ME_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    case UserActionKeys.GET_USER_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: undefined,
      };
    case UserActionKeys.GET_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: undefined,
        currentGetUser: action.payload,
      };
    case UserActionKeys.GET_USER_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    case UserActionKeys.RESET_USER:
      return {
        ...state,
        currentGetUser: undefined,
      };
    case UserActionKeys.RESET_USER_LIST:
      return {
        ...state,
        currentListUser: undefined,
      };

    case UserActionKeys.PUT_USER_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: undefined,
      };
    case UserActionKeys.PUT_USER_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    case UserActionKeys.PATCH_ME_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: undefined,
      };
    case UserActionKeys.PATCH_ME_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    case UserActionKeys.CHANGE_PASSWORD_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: undefined,
      };
    case UserActionKeys.CHANGE_PASSWORD_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case UserActionKeys.CHANGE_CURRENT_CONTEXT:
      return {
        ...state,
        contextName: action.payload,
      };

    case UserActionKeys.CREATE_USER_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: undefined,
      };

    case UserActionKeys.LIST_USER_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: undefined,
      };
    case UserActionKeys.LIST_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: undefined,
        currentListUser: action.payload,
      };
    case UserActionKeys.LIST_USER_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    case UserActionKeys.APPEND_LIST_USER_REQUEST_INCREMENTING:
      if (!state.currentListUserIncrementing)
        return {
          ...state,
          currentListUserIncrementing: {
            count: 1,
            pageIndex: 0,
            pageSize: 1,
            data: [action.payload],
          },
        };

      const i = state.currentListUserIncrementing?.data?.findIndex(
        (item) => item.externalId === action.payload.externalId
      );

      if (i && i === -1)
        return {
          ...state,
          currentListUserIncrementing: {
            ...state.currentListUserIncrementing,
            data: [...state.currentListUserIncrementing.data, action.payload],
          },
        };

      return state;
    case UserActionKeys.LIST_USER_SUCCESS_INCREMENTING:
      return {
        ...state,
        isLoading: false,
        error: undefined,
        currentListUserIncrementing: {
          ...action.payload,
          data: [
            ...(state.currentListUserIncrementing?.data || []),
            ...action.payload.data,
          ],
        },
      };
    case UserActionKeys.LIST_USER_FAILURE_INCREMENTING:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    case UserActionKeys.RESET_USER_LIST_INCREMENTING:
      return {
        ...state,
        currentListUserIncrementing: undefined,
      };

    case UserActionKeys.REMOVE_USER_LIST_INCREMENTING:
      const tmp = (state.currentListUserIncrementing?.data || []).slice();
      const index = tmp.findIndex((item) => item.externalId === action.payload);

      if (index !== -1) {
        tmp.splice(index, 1);
      }

      return {
        ...state,
        currentListUserIncrementing: {
          ...(state.currentListUserIncrementing as IListUserResponse),
          data: tmp,
        },
      };

    case UserActionKeys.PATCH_STATUS_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: undefined,
      };
    case UserActionKeys.PATCH_STATUS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: undefined,
        currentGetUser: {
          ...state.currentGetUser,
          ativo: action.payload.ativa,
          usuariosExternalId: action.payload.usuariosExternalId,
        } as any,
      };
    case UserActionKeys.PATCH_STATUS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    case UserActionKeys.PUT_UPDATE_PERMISSION_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case UserActionKeys.SHOW_PERMISSION_DEPENDENCY_MODAL:
      return {
        ...state,
        showPermissionDependencyModal: true,
        permissionDependencyModalData: action.payload,
      };

    case UserActionKeys.HIDE_PERMISSION_DEPENDENCY_MODAL:
      return {
        ...state,
        showPermissionDependencyModal: false,
      };

    case UserActionKeys.UPDATE_USER_MODULE_STATUS:
      const modules = state.currentGetUser?.modulos.slice();
      const updateModule = (module: IModulo) => {
        if (module.permissaoId?.toString() === action.payload.permissionId) {
          module.ativo = action.payload.active;
          module.herdado = action.payload.inherited;
        }

        if (module.itens?.length && module.itens?.length > 0) {
          module.itens.forEach((x) => updateModule(x));
        }
      };
      modules?.forEach((x) => updateModule(x));
      return {
        ...state,
        currentGetUser: { ...state.currentGetUser, modulos: modules! } as any,
      };
    default:
      return state;
  }
};

export default userReducer;
