import React, { FC } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { Modal } from '../../../../../Components/Modal';
import { IProductSubGroupResponse } from '../../../../../Data/Interfaces/response/Product/IProductRespose';
import { Text } from '../../../../../Components';
import { Divflex } from '../../../../../Layouts/DivWhithFlex';
import { FooterThisActionIsPermanent } from '../../../../../Components/Table/components/Modals/DeleteModal';
import { history } from '../../../../../Store';
import {
  FETCH_LIST_SUBGROUP,
  useSubGroupDelete,
} from '../../../../../Hooks/Supply/ProductHooks';
import { useQueryClient } from '@tanstack/react-query';
import { handleModalError } from '../../../../../Utils/ErrorHandlingUtils';
import { App } from 'antd';

interface IEditModal {
  visible: boolean;
  productSubGroup: IProductSubGroupResponse;
  onCancelClick: () => void;
}

export const DeleteSubGroupModal: React.FC<IEditModal> = ({
  visible,
  productSubGroup,
  onCancelClick,
}) => {
  const { t } = useTranslation();
  const app = App.useApp();

  const deleteSubGroup = useSubGroupDelete();

  const queryClient = useQueryClient();

  const onDelete = async () => {
    const res = await deleteSubGroup.mutateAsync(
      {
        subGruposExternalId: [productSubGroup?.externalId] as any,
      },
      {
        onError: handleModalError(app),
      }
    );
    if (res.success) {
      queryClient.invalidateQueries([FETCH_LIST_SUBGROUP]);
      history.push('/inventory/sub-groups');
    }
  };

  return (
    <Modal
      onOkClick={() => onDelete()}
      title={`${t('common.delete')} ${t('common.subGroup')} “${
        productSubGroup.subGrupoDescricao
      }” ?`}
      body={<BodyModal productSubGroup={productSubGroup} t={t} />}
      visible={visible}
      onCancelClick={onCancelClick}
      htmlType="submit"
      okButtonName={t('modal.delete')}
      okButtonColor="status-danger-base"
      onClose={onCancelClick}
    />
  );
};

interface IBodyModal {
  productSubGroup: IProductSubGroupResponse;
  t: TFunction;
}

const BodyModal: FC<IBodyModal> = ({ productSubGroup, t }) => (
  <div style={{ padding: '20px' }}>
    <Divflex>
      <Text
        color="text-50"
        type="ui-tiny-content"
        children={`${t('modal.deleteModal.single.question')} ${t(
          'common.theSubGroup'
        )} `}
      />
      <Text
        color="text-50"
        type="ui-tiny-bold"
        children={`${productSubGroup.subGrupoDescricao}`}
        style={{ marginBottom: '15px' }}
      />
      <Text type="ui-tiny-content" children={'?'} />
    </Divflex>
    <FooterThisActionIsPermanent />
  </div>
);
