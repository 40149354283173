import { call, put } from 'redux-saga/effects';
import { BalanceAdjustmentsAPI } from '../../Data/API/Supply/BalanceAdjustmentsApi';
import { ICreateBalanceAdjustments } from '../../Data/Interfaces/request/BalanceAdjustments/ICreateBalanceAdjustments';
import {
  messageErrorToast,
  messageSucessToast,
} from '../../Utils/MessageErrorToast';
import { Action } from '../Base/actionHelpers';
import {
  BalanceAdjustmentsActionKeys,
  BalanceAdjustmentsActions,
} from './BalanceAdjustments.actions';
import { history } from '../';
import { i18n } from '../../Components';

export function* getBalanceAdjustments({
  payload,
}: Action<BalanceAdjustmentsActionKeys.GET_SINGLE_BALANCE_ADJUSTMENT, string>) {
  try {
    yield put(BalanceAdjustmentsActions.setLoading(true));

    const { data } = yield call(
      BalanceAdjustmentsAPI.getBalanceAdjustment,
      payload
    );

    yield put(BalanceAdjustmentsActions.getBalanceAdjustmentSuccess(data));
  } catch (error) {
    messageErrorToast(error);
  } finally {
    yield put(BalanceAdjustmentsActions.setLoading(false));
  }
}

export function* createBalanceAdjustment({
  payload,
}: Action<
  BalanceAdjustmentsActionKeys.GET_SINGLE_BALANCE_ADJUSTMENT_SUCCESS,
  ICreateBalanceAdjustments
>) {
  try {
    yield put(BalanceAdjustmentsActions.setLoading(true));

    yield call(BalanceAdjustmentsAPI.postBalanceAdjustment, payload);
    messageSucessToast(i18n.t('supply.balanceAdjustment.createdWithSuccess'));
    history.push('/inventory/balance-adjustments');
  } catch (error) {
    messageErrorToast(error);
  } finally {
    yield put(BalanceAdjustmentsActions.setLoading(false));
  }
}
