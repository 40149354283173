import { QueryFunctionContext } from '@tanstack/react-query';
import { Http, IQueryListParams } from '../../../Utils/Http';
import { IPurchaseOrderList } from '../../Interfaces/response/Purchase/IPurchaseOrderResponse';

export class PurchaseAPI {
  public static async getPurchaseOrderList(
    context: QueryFunctionContext
  ): Promise<IPurchaseOrderList | null> {
    if (!context.queryKey[1]) return null;

    return await Http.fetcher<IPurchaseOrderList>(
      Http.AddQueryParams(
        `/pharma/compras/v1/PedidoCompra/Listar`,
        context.queryKey[1] as IQueryListParams
      )
    );
  }
}
