import { INotificationState } from './interfaces/INotificationState'
import { NotificationActionKeys, NotificationActionUnion } from './Notification.actions'

const initialData: INotificationState = {
    isLoading: false,
}

const notificationReducer = (state = initialData, action: NotificationActionUnion): INotificationState => {
    switch (action.type) {
        case NotificationActionKeys.GET_NOTIFICATIONS_REQUEST:
            return {
                ...state,
                isLoading: true,
                error: undefined,
            }
        case NotificationActionKeys.GET_NOTIFICATIONS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                error: undefined,
                notifications: action.payload,
            }
        case NotificationActionKeys.GET_NOTIFICATIONS_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            }

        default:
            return state
    }
}

export default notificationReducer
