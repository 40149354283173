import { useMutation, useQuery } from '@tanstack/react-query';
import { IPagination } from '../../Components/Table';
import { BalanceAdjustmentsAPI } from '../../Data/API/Supply/BalanceAdjustmentsApi';

export const FETCH_LIST_BALANCE_ADJUSTMENTS =
  '[BALANCE_ADJUSTMENTS] FETCH_LIST_BALANCE_ADJUSTMENTS';

export const FETCH_GET_BALANCE_ADJUSTMENTS =
  '[BALANCE_ADJUSTMENTS] FETCH_GET_BALANCE_ADJUSTMENTS';

export const CREATE_BALANCE_ADJUSTMENTS =
  '[BALANCE_ADJUSTMENTS] CREATE_BALANCE_ADJUSTMENTS';

export const useBalanceAdjustmentsList = (pagination: IPagination) =>
  useQuery(
    [FETCH_LIST_BALANCE_ADJUSTMENTS, pagination],
    BalanceAdjustmentsAPI.getBalanceAdjustmentsList,
    {
      staleTime: 60000,
    }
  );

export const useBalanceAdjustmentsGet = (externalId: string) =>
  useQuery(
    [FETCH_GET_BALANCE_ADJUSTMENTS, externalId],
    BalanceAdjustmentsAPI.getBalanceAdjustment,
    {
      staleTime: 60000,
    }
  );

export const useCreateBalanceAdjustments = () =>
  useMutation(
    [CREATE_BALANCE_ADJUSTMENTS],
    BalanceAdjustmentsAPI.postBalanceAdjustment
  );
