import React, { FC, ReactNode, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Message } from '../../Components/Feedback/Message';
import { HeaderMenu } from '../../Components/HeaderMenu';

import { SideMenu } from '../../Components/SideMenu';
import { getMenuById } from '../../Components/SideMenu/SideMenu.items';
import { getContextId } from '../../Store/User/User.selector';
import StorageUtils from '../../Utils/StorageUtils';
import { PageLayout } from '../PageLayout';

interface IWithSideBar {
  children: ReactNode;
}

const WithSideBar: FC<IWithSideBar> = ({ children }) => {
  const currentContextId = useSelector(getContextId);
  const menu = getMenuById(currentContextId);
  const params: { pathname: string } = useLocation();

  const current = params.pathname.split('/');

  const COLLAPSE_SIDEBAR = 'COLLAPSE-SIDEBAR';
  const [isCollapsed, setIsCollapsed] = useState(
    StorageUtils.GetItem(COLLAPSE_SIDEBAR) === 'true'
  );

  return (
    <div>
      <Message />
      <HeaderMenu
        logo="logo"
        logoCollapsed="logo-collapse"
        isCollapsed={isCollapsed}
      />
      {current[1] === 'account-manager' ||
      current[1] === 'user-preference' ||
      current[1] === 'system-configurations' ? (
        <React.Suspense fallback={<span>Loading...</span>}>
          {children}
        </React.Suspense>
      ) : (
        <SideMenu
          items={menu?.items}
          onCollapsedChange={(collapsed) => setIsCollapsed(collapsed)}
        >
          <React.Suspense fallback={<span>Loading...</span>}>
            <PageLayout children={children} />
          </React.Suspense>
        </SideMenu>
      )}
    </div>
  );
};

export default WithSideBar;
