import { Http } from '../../../Utils/Http';
import { ICreateAddress } from '../../Interfaces/request/Purchasing/Providers/ICreateAddress';
import { ICreateContact } from '../../Interfaces/request/Purchasing/Providers/ICreateContact';
import { ICreateDocument } from '../../Interfaces/request/Purchasing/Providers/ICreateDocument';
import { IProviderRequest } from '../../Interfaces/request/Purchasing/Providers/IProviderRequest';
import { IBaseListResponse } from '../../Interfaces/response/Base/IBase';
import { IContactTypeResponse } from '../../Interfaces/response/Person/ContactTypeResponse';
import { CreateAddressResponse } from '../../Interfaces/response/Person/CreateAddressResponse';
import { ICreateContactResponse } from '../../Interfaces/response/Person/CreateContactResponse';
import { CreateDocumentResponse } from '../../Interfaces/response/Person/CreateDocumentResponse';
import { CreateProviderResponse } from '../../Interfaces/response/Person/CreateProviderResponse';
import { IDocumentTypeResponse } from '../../Interfaces/response/Person/DocumentTypeResponse';
import { IProviderTypeResponse } from '../../Interfaces/response/Person/ProviderTypeResponse';

export class PersonAPI {
  public static async listContactType(): Promise<
    IBaseListResponse<IContactTypeResponse>
  > {
    const res = await Http.fetcher(
      '/pharma/pessoa/v1/TipoContato/Listar?PageIndex=1&PageSize=1000' // Nunca teremos mais que 10 tipos de contato :/
    );

    if (!res) return { data: [], count: 0, pageIndex: 1, pageSize: 10 };

    return res;
  }

  public static async listDocumentTypes(): Promise<
    IBaseListResponse<IDocumentTypeResponse>
  > {
    const res = await Http.fetcher(
      '/pharma/pessoa/v1/TipoDocumento/Listar?PageIndex=1&PageSize=1000' // Nunca teremos mais que 10 tipos de documento :/
    );

    if (!res) return { data: [], count: 0, pageIndex: 1, pageSize: 10 };

    return res;
  }

  public static async listProviderTypes(): Promise<
    IBaseListResponse<IProviderTypeResponse>
  > {
    const res = await Http.fetcher(
      '/pharma/pessoa/v1/TipoFornecedor/Listar?PageIndex=1&PageSize=1000' // Nunca teremos mais que 10 tipos de fornecedor :/
    );

    if (!res) return { data: [], count: 0, pageIndex: 1, pageSize: 10 };

    return res;
  }

  public static async createProvider(
    provider: IProviderRequest
  ): Promise<CreateProviderResponse> {
    const res = await Http.fetcher<CreateProviderResponse>(
      '/pharma/pessoa/v1/Fornecedor',
      {
        method: 'POST',
        body: JSON.stringify({ ...provider, ativo: true }),
      }
    );

    if (!res) return {} as CreateProviderResponse;

    return res;
  }

  public static async createContact(
    contact: ICreateContact
  ): Promise<ICreateContactResponse> {
    const res = await Http.fetcher<ICreateContactResponse>(
      '/pharma/pessoa/v1/Fornecedor/CadastrarContato',
      {
        method: 'POST',
        body: JSON.stringify(contact),
      }
    );

    if (!res) return {} as ICreateContactResponse;

    return res;
  }

  public static async createDocument(
    document: ICreateDocument
  ): Promise<CreateDocumentResponse> {
    const res = await Http.fetcher<CreateDocumentResponse>(
      '/pharma/pessoa/v1/Fornecedor/CadastrarDocumento',
      {
        method: 'POST',
        body: JSON.stringify(document),
      }
    );

    if (!res) return {} as CreateDocumentResponse;

    return res;
  }

  public static async createAddress(
    address: ICreateAddress
  ): Promise<CreateAddressResponse> {
    const res = await Http.fetcher<CreateAddressResponse>(
      '/pharma/pessoa/v1/Fornecedor/CadastrarEndereco',
      {
        method: 'POST',
        body: JSON.stringify(address),
      }
    );

    if (!res) return {} as CreateAddressResponse;

    return res;
  }
}
