import { useMutation, useQuery } from '@tanstack/react-query';
import { IPagination } from '../../Components/Table';
import { ProductAPI } from '../../Data/API/Supply/Product';
import { IBasicMutationOptions } from '../interfaces/IBasicOptions';

const CREATE_PRODUCT = '[PRODUCT] CREATE_PRODUCT';
const DELETE_PRODUCT = '[PRODUCT] DELETE_PRODUCT';
const EDIT_PRODUCT = '[PRODUCT] EDIT_PRODUCT';
const EDIT_FINANCIAL_PRODUCT = '[PRODUCT] EDIT_FINANCIAL_PRODUCT';
const EDIT_STATUS_PRODUCT = '[PRODUCT] EDIT_STATUS_PRODUCT';
export const FETCH_GET_PRODUCT = '[PRODUCT] FETCH_GET_PRODUCT';
export const FETCH_LIST_PRODUCT = '[PRODUCT] FETCH_LIST_PRODUCT';
const VALIDATION_NAME_PRODUCT = '[PRODUCT] VALIDATION_NAME_PRODUCT';
export const FETCH_LIST_FOR_DROPDOWN_TYPE =
  '[PRODUCT] FETCH_LIST_FOR_DROPDOWN_TYPE';

const CREATE_GROUP = '[PRODUCT_GROUP] CREATE_GROUP';
const DELETE_GROUP = '[PRODUCT_GROUP] DELETE_GROUP';
const EDIT_GROUP = '[PRODUCT_GROUP] EDIT_GROUP';
export const FETCH_GET_GROUP = '[PRODUCT_GROUP] FETCH_GET_GROUP';
export const FETCH_LIST_GROUP = '[PRODUCT_GROUP] FETCH_LIST_GROUP';
export const FETCH_LIST_SUBGROUPS_GROUP =
  '[PRODUCT_GROUP] FETCH_LIST_SUBGROUPS_GROUP';
export const FETCH_LIST_PRODUCTS_GROUP =
  '[PRODUCT_GROUP] FETCH_LIST_PRODUCTS_GROUP';
const FETCH_GET_DROPDOWN_GROUP = '[PRODUCT_SUBGROUP] FETCH_GET_DROPDOWN_GROUP';
const VALIDATION_NAME_GROUP = '[PRODUCT_GROUP] VALIDATION_NAME_GROUP';

const CREATE_SUBGROUP = '[PRODUCT_SUBGROUP] CREATE_SUBGROUP';
const DELETE_SUBGROUP = '[PRODUCT_SUBGROUP] DELETE_SUBGROUP';
const EDIT_SUBGROUP = '[PRODUCT_SUBGROUP] EDIT_SUBGROUP';
export const FETCH_GET_SUBGROUP = '[PRODUCT_SUBGROUP] FETCH_GET_SUBGROUP';
export const FETCH_LIST_SUBGROUP = '[PRODUCT_SUBGROUP] FETCH_LIST_SUBGROUP';
export const FETCH_LIST_SUBGROUP_BY_GROUP =
  '[PRODUCT_SUBGROUP] FETCH_LIST_SUBGROUP_BY_GROUP';
export const FETCH_LIST_PRODUCTS_SUBGROUP =
  '[PRODUCT_SUBGROUP] FETCH_LIST_PRODUCTS_SUBGROUP';
const FETCH_GET_DROPDOWN_SUBGROUP =
  '[PRODUCT_SUBGROUP] FETCH_GET_DROPDOWN_SUBGROUP';
const VALIDATION_NAME_SUBGROUP = '[PRODUCT_SUBGROUP] VALIDATION_NAME_SUBGROUP';

export const useProductCreate = () =>
  useMutation([CREATE_PRODUCT], ProductAPI.createProduct);

export const useProductDelete = () =>
  useMutation([DELETE_PRODUCT], ProductAPI.deleteProduct);

export const useProductEdit = () =>
  useMutation([EDIT_PRODUCT], ProductAPI.editProduct);

export const useProductFinancialEdit = () =>
  useMutation([EDIT_FINANCIAL_PRODUCT], ProductAPI.editProductFinancial);

export const useProductStatusEdit = () =>
  useMutation([EDIT_STATUS_PRODUCT], ProductAPI.productStatus);

export const useProductGet = (externalId?: string) =>
  useQuery([FETCH_GET_PRODUCT, externalId || null], ProductAPI.getProduct);

export const useProductGetList = (pagination: IPagination) =>
  useQuery([FETCH_LIST_PRODUCT, pagination], ProductAPI.productList, {
    staleTime: 60000,
  });

export const useProductValidationName = (options?: IBasicMutationOptions) =>
  useMutation(
    [VALIDATION_NAME_PRODUCT],
    ProductAPI.productNameValidation,
    options
  );

export const useProductGetDropdown = (pagination: IPagination) =>
  useQuery(
    [FETCH_LIST_FOR_DROPDOWN_TYPE, pagination],
    ProductAPI.getListProductDropDown
  );

export const useGroupCreate = () =>
  useMutation([CREATE_GROUP], ProductAPI.createGroup);

export const useGroupDelete = () =>
  useMutation([DELETE_GROUP], ProductAPI.deleteProductGroup);

export const useGroupEdit = () =>
  useMutation([EDIT_GROUP], ProductAPI.editProductGroup);

export const useGroupGet = (externalId: string) =>
  useQuery([FETCH_GET_GROUP, externalId], ProductAPI.getProductGroup);

export const useGroupGetList = (pagination: IPagination) =>
  useQuery([FETCH_LIST_GROUP, pagination], ProductAPI.groupList, {
    staleTime: 60000,
  });

export const useGroupGetSubgroupsList = (
  pagination: IPagination,
  externalId: string
) =>
  useQuery(
    [FETCH_LIST_SUBGROUPS_GROUP, pagination, externalId],
    ProductAPI.subGroupsOfGroupsList,
    { staleTime: 60000 }
  );

export const useGroupGetProductsList = (
  pagination: IPagination,
  externalId: string
) =>
  useQuery(
    [FETCH_LIST_PRODUCTS_GROUP, pagination, externalId],
    ProductAPI.productsOfGroupsList,
    { staleTime: 60000 }
  );

export const useGroupGetDropdown = (pagination: IPagination) =>
  useQuery(
    [FETCH_GET_DROPDOWN_GROUP, pagination],
    ProductAPI.getGroupListDropdown,
    {}
  );

export const useGroupValidationName = (options?: IBasicMutationOptions) =>
  useMutation(
    [VALIDATION_NAME_GROUP],
    ProductAPI.productGroupNameValidation,
    options
  );

export const useSubGroupCreate = () =>
  useMutation([CREATE_SUBGROUP], ProductAPI.createSubGroup);

export const useSubGroupDelete = () =>
  useMutation([DELETE_SUBGROUP], ProductAPI.deleteProductSubGroup);

export const useSubGroupEdit = () =>
  useMutation([EDIT_SUBGROUP], ProductAPI.editProductSubGroup);

export const useSubGroupGet = (externalId: string) =>
  useQuery([FETCH_GET_SUBGROUP, externalId], ProductAPI.getproductSubGroup, {});

export const useSubGroupGetList = (pagination: IPagination) =>
  useQuery([FETCH_LIST_SUBGROUP, pagination], ProductAPI.subGroupList, {
    staleTime: 60000,
  });

export const useSubGroupByGroupGetDropdown = (
  pagination: IPagination,
  grupoPaiExternalId: string
) =>
  useQuery(
    [FETCH_LIST_SUBGROUP_BY_GROUP, pagination, grupoPaiExternalId],
    ProductAPI.getSubGroupByGroupDropdown
  );

export const useSubGroupGetProductsList = (
  pagination: IPagination,
  subGrupoExternalId: string
) =>
  useQuery(
    [FETCH_LIST_PRODUCTS_SUBGROUP, pagination, subGrupoExternalId],
    ProductAPI.productsOfSubGroupsList
  );

export const useSubGroupGetDropdown = (pagination: IPagination) =>
  useQuery(
    [FETCH_GET_DROPDOWN_SUBGROUP, pagination],
    ProductAPI.getSubGroupListDropdown,
    {}
  );

export const useSubGroupValidationName = (options?: IBasicMutationOptions) =>
  useMutation(
    [VALIDATION_NAME_SUBGROUP],
    ProductAPI.productSubGroupNameValidation,
    options
  );
