import React, {
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Table as AntdTable } from 'antd';
import {
  AddAlphabeticalOrder,
  AddHighlight,
  AddNumericalOrder,
  AddPermission,
} from './Table.helpers';
import {
  IPagination,
  IPaginationInput,
  Pagination,
} from './components/Pagination';
import { AddDataLoading } from './components/Loading';
import { IHeaderTableOptions, TableHeader } from './components/HeaderTable';
import {
  DeleteModal,
  IDeleteModalTranslations,
} from './components/Modals/DeleteModal';
import './Table.override.scss';
import { Skeleton } from '..';
import { Color } from '../../Utils/ColorUtils';
import {
  EditStatusModal,
  IConfirmEdit,
  IEditModalTranslations,
} from './components/Modals/EditStatusModal';
import { hasPermission, IPermissionWrapper } from '../PermissionWrapper';
import _ from 'lodash';
import { statusTypes } from '../../Utils/StatusUtils';

export type { IPagination } from './components/Pagination';

interface IEditSelectedItem {
  id: any;
  name: string;
  status?: statusTypes | string;
}
export interface IColumn {
  title: ReactNode | string;
  dataIndex: string;
  key: string;

  actionItems?: IActionItem[];

  isHighlightable?: boolean;

  numericalOrder?: boolean;
  alphabeticalOrder?: boolean;
  sortColumn?: string;
  sorter?: any;

  width?: string | number;
  render?: (
    text: string,
    record: any,
    index: number,
    selectedRows?: any[]
  ) => ReactNode;
}

export interface ISorter {
  column: string;
  direction: 'ASC' | 'DESC';
}

export interface IQueryParams {
  pagination?: IPagination;
  sorter?: ISorter;
  select?: string;
  orderBy: string;
  filter?: string;
  expand?: IExpandParams[];
}

export interface IQuery extends Omit<IQueryParams, 'pagination'> {
  pagination?: IPagination;
}

export interface IExpandParams {
  expandTitle: string;
  sorter?: ISorter;
  select?: string;
  orderBy?: string;
  filter?: string;
  count?: boolean;
  pagination?: IPagination;
  expand?: IExpandParams[];
  custom?: string;
}

export interface IActionItem {
  name: string;
  onClick: (record: any) => void;
  onDoubleClick?: (record: any) => void;
}

export type editOptions = {
  id: any;
  name: string;
  status?: statusTypes;
  statusDarkmode?: boolean;
}[];

export interface ITable {
  columns: IColumn[];
  data: any[];
  headerOptions?: IHeaderTableOptions;
  editOptions?: editOptions;
  pagination?: false | IPaginationInput;
  loading?: boolean;

  hasSelection?: boolean;

  translations?: {
    deleteModal?: IDeleteModalTranslations;
    editModal?: IEditModalTranslations;
  };

  className?: string;

  deleteModal?: {
    body?: any;
    title?: string;
    customEndTitle?: string;
    buttons?: {
      okButtonColor?: Color;
    };
    okButtonName?: string;
    className?: string;
  };

  editModal?: {
    body?: any;
    title?: string;
    className?: string;
    okButtonName?: string;
    withForm?: boolean;
  };
  editModalLoading?: boolean;
  confirmEditModal?: IConfirmEdit;

  rowsOptions?: {
    permission?: IPermissionWrapper;
    onRowDoubleClick?: (record: any) => void;
    onRowClick?: (record: any) => void;
  };

  cleanSelectedRows?: boolean;
  selectedRowsOnTable?: (x: any) => void;
  fieldStatus?: string;
  onRefetch?: (pagination: IPagination) => void;
  onDelete?: (rows: any[]) => void;
  onEdit?: (rows: any[], selectedItem: IEditSelectedItem) => void;
}

export const Table: FC<ITable> = ({
  columns,
  data,
  headerOptions,
  pagination,
  loading,
  hasSelection,
  translations,
  editOptions,
  deleteModal,
  className,
  confirmEditModal,
  rowsOptions,
  onRefetch,
  onDelete,
  onEdit,
  selectedRowsOnTable,
  cleanSelectedRows,
  editModal,
  editModalLoading,
  fieldStatus,
}) => {
  const [search, setSearch] = useState<string>();
  const [hasRowSelected, setHasRowSelected] = useState(false);
  const [selectedRows, setSelectedRows] = useState<any[] | undefined>();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  const closeModal = useCallback(() => {
    setShowEditModal(false);
    setTimeout(() => {
      setSelectedRows([]);
      setHasRowSelected(false);
    }, 1);
  }, []);

  const hasAlphabeticalOrder =
    columns && columns.some((x) => x.alphabeticalOrder);
  const hasNumericalOrder = columns && columns.some((x) => x.numericalOrder);
  const hasSorter = columns && columns.some((x) => x.sortColumn);

  let fixedColumns = columns;

  if (hasAlphabeticalOrder) fixedColumns = AddAlphabeticalOrder(columns);
  if (hasNumericalOrder) fixedColumns = AddNumericalOrder(columns);
  if (headerOptions?.hasSearch)
    fixedColumns = AddHighlight(columns, search, selectedRows);
  if (hasSorter)
    fixedColumns = fixedColumns.map((x) => ({
      ...x,
      sorter: !!x.sortColumn,
    }));

  const rowSelection = useMemo(
    () => ({
      selectedRowKeys: selectedRows?.map((x) => x.key),
      onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
        setSelectedRows(selectedRows);
        setHasRowSelected(selectedRows.length > 0);
      },
    }),
    [selectedRows]
  );

  useEffect(() => {
    if (cleanSelectedRows) rowSelection.selectedRowKeys = [];
  }, [cleanSelectedRows, rowSelection]);

  const getColumns = useCallback(
    (loading?: boolean) => {
      const loadingColumns = [...fixedColumns].map((column) => ({
        ...column,
        render: undefined,
      }));
      return loading
        ? loadingColumns
        : AddPermission(fixedColumns, rowsOptions?.permission, selectedRows);
    },
    [fixedColumns, rowsOptions, selectedRows]
  );

  useEffect(() => {
    selectedRowsOnTable && selectedRowsOnTable(selectedRows);
  }, [selectedRows, selectedRowsOnTable]);

  const onSearching = _.debounce((searching: any) => {
    searching = searching.trim();
    setSearch(searching);

    onRefetch &&
      onRefetch({
        Search: searching,
        PageIndex: (pagination && pagination.PageIndex - 1) || 0,
        PageSize: (pagination && pagination.PageSize) || 10,
      });
  }, 1000);

  useEffect(() => {
    !editModalLoading && closeModal();
  }, [editModalLoading, closeModal]);

  return (
    <>
      <TableHeader
        {...headerOptions}
        editButton={
          headerOptions?.editButton && {
            ...headerOptions?.editButton,
            options: headerOptions?.editButton?.options?.map((x) => ({
              ...x,
              onClick: x.isStatusOnly
                ? () => setShowEditModal(true)
                : x.onClick,
            })),
          }
        }
        loading={!!loading}
        deleteButton={
          headerOptions?.deleteButton || deleteModal
            ? {
                ...headerOptions?.deleteButton,
                onClick: headerOptions?.deleteButton?.onClick
                  ? headerOptions?.deleteButton?.onClick
                  : () => setShowDeleteModal(true),
              }
            : undefined
        }
        hasSomeItemSelected={hasRowSelected}
        onRefetch={(searching) => onSearching(searching)}
      />
      <AntdTable
        className={`table ${className} ${!pagination ? 'not-pagination' : ''}`}
        rowSelection={
          hasSelection
            ? {
                type: 'checkbox',
                renderCell: loading
                  ? () => {
                      return <Skeleton />;
                    }
                  : undefined,
                ...rowSelection,
              }
            : undefined
        }
        columns={getColumns(loading) as any}
        showSorterTooltip={false}
        onChange={(_, filters, sorter) => {
          const sort = Array.isArray(sorter) ? sorter[0] : sorter;
          const hasSort = !!sort.column;
          const column = sort.column as any;

          const pag = {
            Search: search,
            PageIndex: (pagination && pagination.PageIndex - 1) || 0,
            PageSize: (pagination && pagination.PageSize) || 10,
          };

          const tmp: ISorter | undefined = hasSort
            ? {
                column: column?.sortColumn as string,
                direction: sort.order === 'ascend' ? 'ASC' : 'DESC',
              }
            : undefined;
          onRefetch && onRefetch({ ...pag, Sorter: tmp });
        }}
        dataSource={
          loading
            ? pagination
              ? AddDataLoading(
                  columns,
                  pagination.PageSize > 10 ? pagination.PageSize : 3
                )
              : AddDataLoading(columns, 3)
            : data
        }
        pagination={false}
        onRow={(record: any) => ({
          onClick: hasPermission(rowsOptions?.permission?.permission)
            ? rowsOptions &&
              rowsOptions?.onRowClick &&
              rowsOptions?.onRowClick.bind({}, record)
            : undefined,
          onDoubleClick: hasPermission(rowsOptions?.permission?.permission)
            ? rowsOptions &&
              rowsOptions?.onRowDoubleClick &&
              rowsOptions?.onRowDoubleClick.bind({}, record)
            : undefined,
        })}
      />
      {pagination ? (
        <Pagination
          loading={!!loading || false}
          onRefetch={onRefetch}
          {...(pagination || {})}
        />
      ) : null}
      <DeleteModal
        visible={showDeleteModal}
        itemNames={
          (selectedRows &&
            selectedRows.map((x) =>
              x.Sobrenome
                ? [x[headerOptions?.nameKey || 'key'], x.Sobrenome]
                : [x[headerOptions?.nameKey || 'key']]
            )) ||
          []
        }
        translations={translations?.deleteModal}
        onCancelClick={() => setShowDeleteModal(false)}
        onOkClick={() => {
          onDelete && onDelete(selectedRows!);
          setShowDeleteModal(false);
          setSelectedRows([]);
          setHasRowSelected(false);
        }}
        okButtonColor={deleteModal?.buttons?.okButtonColor}
        body={deleteModal?.body}
        title={deleteModal?.title}
        customEndTitle={deleteModal?.customEndTitle}
        className={deleteModal?.className}
        okButtonName={deleteModal?.okButtonName}
      />
      <EditStatusModal
        visible={showEditModal}
        items={editOptions}
        translations={translations?.editModal}
        confirmationModal={confirmEditModal}
        itemNames={
          (selectedRows &&
            selectedRows.map((x) =>
              x.Sobrenome
                ? [x[headerOptions?.nameKey || 'key'], x.Sobrenome]
                : [x[headerOptions?.nameKey || 'key']]
            )) ||
          []
        }
        onCancelClick={() => setShowEditModal(false)}
        onOkClick={(selectedItem) =>
          onEdit && onEdit(selectedRows!, selectedItem)
        }
        okButtonName={editModal?.okButtonName}
        withForm={editModal?.withForm}
        body={editModal?.body}
        title={editModal?.title}
        status={
          selectedRows &&
          !!fieldStatus &&
          (selectedRows?.every((x) => x[fieldStatus]) ||
            selectedRows?.every((x) => !x[fieldStatus]))
        }
        statusSelectedOnRow={
          fieldStatus && !!selectedRows && selectedRows?.length > 0
            ? selectedRows[0][fieldStatus]
            : undefined
        }
        loading={editModalLoading}
      />
    </>
  );
};
