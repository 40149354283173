import React, { CSSProperties, FC } from 'react';

import { Avatar as AntAvatar } from 'antd';

import {
  AvatarColors,
  AvatarColor,
  Color,
  Colors,
} from '../../Utils/ColorUtils';

import { Text, TextType } from '../Text';
import { history } from '../../Store';

import styles from './Avatar.module.scss';
import { ShortNameUtils } from '../../Utils/ShortNameUtils';

export interface IAvatarItem {
  type: TextType;
  color?: Color;
  backgroundColor?: Color;

  fisrtName: string;
  lastName?: string;

  routeLink?: string;

  description?: {
    type: TextType;
    description: string;
    color?: Color;
  };
  email?: {
    type: TextType;
    email: string;
    color?: Color;
  };

  image?: string;

  size: 'SS' | 'S' | 'SM' | 'M' | 'LM' | 'L' | 'XL' | 'XXL';

  className?: string;

  onClick?: () => void;
  style?: CSSProperties;
}

export const Avatar: FC<IAvatarItem> = ({
  fisrtName,
  lastName,
  routeLink,
  description,
  email,
  image,
  className,
  size,
  type,
  color,
  backgroundColor,
  onClick,
  style,
}) => {
  const currentSize = styles[size || 'M'];
  return (
    <div
      className={`${styles['content-avatar']} ${
        routeLink ? styles['avatar-with-link'] : ''
      }`}
      style={style}
      onClick={() => routeLink && history.push(routeLink)}
    >
      <AntAvatar
        className={`${styles['avatar']} ${className} ${currentSize} ${
          description ? styles['avatar-with-description'] : ''
        }`}
        style={{
          background: backgroundColor
            ? Colors[backgroundColor]
            : AvatarColors[AvatarColor(ShortNameUtils(fisrtName, lastName))],
        }}
        onClick={() => onClick && onClick()}
      >
        {image ? (
          image
        ) : (
          <Text
            type={type}
            color={color}
            children={ShortNameUtils(fisrtName, lastName)}
          />
        )}
      </AntAvatar>
      {(description || email) && (
        <div className={styles['content-description']}>
          <div>
            {description && (
              <Text
                type={description.type}
                children={description.description}
                color={description.color}
              />
            )}
            {email && (
              <Text
                type={email.type}
                children={email.email}
                color={email.color}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};
