import React from 'react';
import { DeleteModalOnTable } from '../../../../Utils/DeleteModalUtils';
import { Text } from '../../../../Components';
import { Divflex } from '../../../../Layouts/DivWhithFlex';
import { FooterThisActionIsPermanent } from '../../../../Components/Table/components/Modals/DeleteModal';

export const TitleHandler = (selectedRowsOnTable: any, t: any) =>
  selectedRowsOnTable?.length === 1
    ? `${t('common.delete')} ${t('common.group')} "${
        selectedRowsOnTable ? selectedRowsOnTable[0]?.descricao : ''
      }"?`
    : `${t('common.delete')} ${selectedRowsOnTable?.length} ${t(
        'common.groups'
      )}?`;

export const BodyHandler = (selectedRowsOnTable: any, t: any) =>
  selectedRowsOnTable?.length > 1 ? (
    DeleteModalOnTable(
      selectedRowsOnTable,
      `${t('modal.deleteModal.many.title')} ${t('common.theGroups')}:`,
      'descricao',
      t('common.groups')
    )
  ) : (
    <>
      <div style={{ padding: '20px' }}>
        <Divflex>
          <Text
            type="ui-tiny-content"
            children={`${t('modal.deleteModal.single.question')} ${t(
              'common.theGroup'
            )} `}
          />
          <Text
            type="ui-tiny-bold"
            children={`${
              selectedRowsOnTable ? selectedRowsOnTable[0]?.descricao : ''
            }`}
            style={{ marginBottom: '15px' }}
          />
          <Text type="ui-tiny-content" children={'?'} />
        </Divflex>
        <FooterThisActionIsPermanent />
      </div>
    </>
  );
