import React, { CSSProperties, FC, ReactNode, useMemo } from 'react';
import { getCurrentUserRoles } from '../../Utils/PermissionUtils';
import { Tooltip } from 'antd';

import './PermissionWrapper.override.scss';
import { useTranslation } from 'react-i18next';
import styles from './PermissionWrapper.module.scss';

export interface IPermissionWrapper {
  permission?: number;
  tooltip?: string;
  style?: CSSProperties;
  children?: ReactNode;
}

export const hasPermission = (permission?: number) => {
  if (!permission) return true;
  const currentUserPermissions = getCurrentUserRoles();
  return currentUserPermissions.includes(permission);
};

export const PermissionWrapper: FC<IPermissionWrapper> = ({
  permission,
  tooltip,
  children,
  style,
}) => {
  const { t } = useTranslation();
  const currentUserPermissions = useMemo(() => getCurrentUserRoles(), []);

  const hasPermission = useMemo(
    () => permission && currentUserPermissions.includes(permission),
    [currentUserPermissions, permission]
  );

  if (hasPermission || !permission) {
    return <>{children}</>;
  }

  return tooltip ? (
    <Tooltip placement="topLeft" title={t(tooltip)}>
      <div className={styles['content-permission-wrappe']} style={{ ...style }}>
        <ParentComponent hasPermission={hasPermission}>
          {children}
        </ParentComponent>
      </div>
    </Tooltip>
  ) : (
    <ParentComponent hasPermission={hasPermission}>{children}</ParentComponent>
  );
};

export const ParentComponent = (props: any) => {
  const isStringChildren = typeof props.children === 'string';

  if (isStringChildren || !props.children) {
    return props.children;
  }

  return React.cloneElement(props.children, {
    disabled: !props.hasPermission,
    pointerEvents: 'none',
    cursor: 'not-allowed',
  });
};
