import React, { FC, ReactNode } from 'react';
import BreadCrumbLayout from '../../../Layouts/BreadCrumbLayout';

import styles from './UserPreferenceLayout.module.scss';

export interface IPreferenceUserLayout {
	tabs: ReactNode;
	children: ReactNode;
}

export const UserPreferenceLayout: FC<IPreferenceUserLayout> = ({ tabs, children }) => {
	return (
		<div className={styles['Page']}>
			{tabs}
			<div className={styles['rightBar']}>
				<BreadCrumbLayout className={styles['bread-crumb']} />
				{children}
			</div>
		</div>
	)
}