/** @format */

import { IQueryParams } from '../../Components/Table';
import {
  IPatchCurrencyRequest,
  IPatchCompanyStatusRequest,
  ICreateCompanyRequest,
  IPatchCompanyRequest,
  IChangeResponsibilityUserRequest,
  IChangeCompanyRequest,
  IChangeCompanyAdressRequest,
  IChangeCompanyPayingRequest,
  IDeteleCompany,
} from '../../Data/Interfaces/request/Company/ICompanyRequest';
import {
  IGetCurrencyResponse,
  IListCompanyResponse,
  IGetCompanyConfigurationsResponse,
  IGetCompanyResponse,
  IListManagerCompanyResponse,
} from '../../Data/Interfaces/response/Company/ICompanyResponse';
import { createAction, Action, ActionsUnion } from '../Base/actionHelpers';

export enum CompanyActionKeys {
  SET_LOADING = '[COMPANY] SET_LOADING',

  PATCH_CURRENCY_REQUEST = '[COMPANY] PATCH_CURRENCY_REQUEST',
  PATCH_CURRENCY_FAILURE = '[COMPANY] PATCH_CURRENCY_FAILURE',

  GET_COMPANY_CONFIGURATIONS_REQUEST = '[COMPANY] GET_COMPANY_CONFIGURATIONS_REQUEST',
  GET_COMPANY_CONFIGURATIONS_SUCCESS = '[COMPANY] GET_COMPANY_CONFIGURATIONS_SUCCESS',
  GET_COMPANY_CONFIGURATIONS_FAILURE = '[COMPANY] GET_COMPANY_CONFIGURATIONS_FAILURE',

  GET_CURRENCY_REQUEST = '[COMPANY] GET_CURRENCY_REQUEST',
  GET_CURRENCY_SUCCESS = '[COMPANY] GET_CURRENCY_SUCCESS',
  GET_CURRENCY_FAILURE = '[COMPANY] GET_CURRENCY_FAILURE',

  COMPANY_LIST_REQUEST = '[COMPANY] COMPANY_LIST_REQUEST',
  COMPANY_LIST_SUCCESS = '[COMPANY] COMPANY_LIST_SUCCESS',
  COMPANY_LIST_FAILURE = '[COMPANY] COMPANY_LIST_FAILURE',

  LIST_COMPANY_REQUEST_INCREMENTING = '[COMPANY] LIST_COMPANY_REQUEST_INCREMENTING',
  LIST_COMPANY_SUCCESS_INCREMENTING = '[COMPANY] LIST_COMPANY_SUCCESS_INCREMENTING',
  LIST_COMPANY_FAILURE_INCREMENTING = '[COMPANY] LIST_COMPANY_FAILURE_INCREMENTING',

  ALL_COMPANY_LIST_REQUEST = '[COMPANY] ALL_COMPANY_LIST_REQUEST',
  ALL_COMPANY_LIST_SUCCESS = '[COMPANY] ALL_COMPANY_LIST_SUCCESS',
  ALL_COMPANY_LIST_FAILURE = '[COMPANY] ALL_COMPANY_LIST_FAILURE',

  MANAGER_COMPANY_LIST_REQUEST = '[COMPANY] MANAGER_COMPANY_LIST_REQUEST',
  MANAGER_COMPANY_LIST_SUCCESS = '[COMPANY] MANAGER_COMPANY_LIST_SUCCESS',
  MANAGER_COMPANY_LIST_FAILURE = '[COMPANY] MANAGER_COMPANY_LIST_FAILURE',

  PATCH_STATUS_REQUEST = '[COMPANY] PATCH_STATUS_REQUEST',
  PATCH_STATUS_SUCCESS = '[COMPANY] PATCH_STATUS_SUCCESS',
  PATCH_STATUS_FAILURE = '[COMPANY] PATCH_STATUS_FAILURE',

  PATCH_STATUS_COMPANY_ON_CONGLOMERATE_REQUEST = '[COMPANY] PPATCH_STATUS_COMPANY_ON_CONGLOMERATE_REQUEST',
  PATCH_STATUS_COMPANY_ON_CONGLOMERATE_SUCCESS = '[COMPANY] PATCH_STATUS_COMPANY_ON_CONGLOMERATE_SUCCESS',
  PATCH_STATUS_COMPANY_ON_CONGLOMERATE_FAILURE = '[COMPANY] PATCH_STATUS_COMPANY_ON_CONGLOMERATE_FAILURE',

  COMPANY_PATCH_REQUEST = '[COMPANY] COMPANY_PATCH_REQUEST',
  COMPANY_PATCH_SUCCESS = '[COMPANY] COMPANY_PATCH_SUCCESS',
  COMPANY_PATCH_FAILURE = '[COMPANY] COMPANY_PATCH_FAILURE',

  GET_COMPANY_REQUEST = '[COMPANY] GET_COMPANY_REQUEST',
  GET_COMPANY_SUCCESS = '[COMPANY] GET_COMPANY_SUCCESS',
  GET_COMPANY_FAILURE = '[COMPANY] GET_COMPANY_FAILURE',

  GET_COMPANY_ADMIN_REQUEST = '[COMPANY] GET_COMPANY_ADMIN_REQUEST',
  GET_COMPANY_ADMIN_SUCCESS = '[COMPANY] GET_COMPANY_ADMIN_SUCCESS',
  GET_COMPANY_ADMIN_FAILURE = '[COMPANY] GET_COMPANY_ADMIN_FAILURE',

  DELETE_COMPANY_REQUEST = '[COMPANY] DELETE_COMPANY_REQUEST',
  DELETE_COMPANY_FAILURE = '[COMPANY] DELETE_COMPANY_FAILURE',

  CREATE_COMPANY_REQUEST = '[COMPANY] CREATE_COMPANY_REQUEST',
  CREATE_BRANCH_COMPANY_REQUEST = '[COMPANY] CREATE_BRANCH_COMPANY_REQUEST',
  CREATE_COMPANY_SUCCESS = '[COMPANY] CREATE_COMPANY_SUCCESS',
  CREATE_COMPANY_FAILURE = '[COMPANY] CREATE_COMPANY_FAILURE',

  CHANGE_RESPONSIBILYTI_USER_REQUEST = '[COMPANY] CHANGE_RESPONSIBILYTI_USER_REQUEST',
  CHANGE_RESPONSIBILYTI_USER_SUCCESS = '[COMPANY] CHANGE_RESPONSIBILYTI_USER_SUCCESS',
  CHANGE_RESPONSIBILYTI_USER_FAILURE = '[COMPANY] CHANGE_RESPONSIBILYTI_USER_FAILURE',

  CHANGE_COMPANY_REQUEST = '[COMPANY] CHANGE_COMPANY_REQUEST',
  CHANGE_COMPANY_FAILURE = '[COMPANY] CHANGE_COMPANY_FAILURE',

  CHANGE_COMPANY_ADRESS_REQUEST = '[COMPANY] CHANGE_COMPANY_ADRESS_REQUEST',
  CHANGE_COMPANY_ADRESS_FAILURE = '[COMPANY] CHANGE_COMPANY_ADRESS_FAILURE',

  CHANGE_COMPANY_PAYING_REQUEST = '[COMPANY] CHANGE_COMPANY_PAYING_REQUEST',
  CHANGE_COMPANY_PAYING_FAILURE = '[COMPANY] CHANGE_COMPANY_PAYING_FAILURE',

  SHOW_COULD_NOT_DELETE = '[COMPANY] SHOW_COULD_NOT_DELETE',

  RESET_COMPANY = '[COMPANY] RESET_COMPANY',
}

export const CompanyActions = {
  setLoading: (loading: boolean): SetLoadingAction =>
    createAction(CompanyActionKeys.SET_LOADING, loading),

  patchCurrency: (body: IPatchCurrencyRequest): PatchCurrencyRequestAction =>
    createAction(CompanyActionKeys.PATCH_CURRENCY_REQUEST, body),
  patchCurrencyFailure: (error: string): PatchCurrencyFailureAction =>
    createAction(CompanyActionKeys.PATCH_CURRENCY_FAILURE, error),

  getCompanyConfigurations: (): GetConfigurationsCurrencyRequestAction =>
    createAction(CompanyActionKeys.GET_COMPANY_CONFIGURATIONS_REQUEST),
  getCompanyConfigurationsSuccess: (
    res: IGetCompanyConfigurationsResponse
  ): GetConfigurationsSuccessAction =>
    createAction(CompanyActionKeys.GET_COMPANY_CONFIGURATIONS_SUCCESS, res),
  getCompanyConfigurationsFailure: (
    error: string
  ): GetConfigurationsFailureAction =>
    createAction(CompanyActionKeys.GET_COMPANY_CONFIGURATIONS_FAILURE, error),

  getCurrency: () => createAction(CompanyActionKeys.GET_CURRENCY_REQUEST),
  getCurrencySuccess: (res: IGetCurrencyResponse): GetCurrencySuccessAction =>
    createAction(CompanyActionKeys.GET_CURRENCY_SUCCESS, res),
  getCurrencyFailure: (error: string): GetCurrencyFailureAction =>
    createAction(CompanyActionKeys.GET_CURRENCY_FAILURE, error),

  listCompany: (body: IQueryParams): ListCompanyRequestAction =>
    createAction(CompanyActionKeys.COMPANY_LIST_REQUEST, body),
  listCompanySuccess: (res: IListCompanyResponse): ListCompanySuccessAction =>
    createAction(CompanyActionKeys.COMPANY_LIST_SUCCESS, res),
  listCompanyFailure: (error: string): ListCompanyFailureAction =>
    createAction(CompanyActionKeys.COMPANY_LIST_FAILURE, error),

  listCompanyIncrementing: (
    body: IQueryParams
  ): ListCompanyIncrementingRequestAction =>
    createAction(CompanyActionKeys.LIST_COMPANY_REQUEST_INCREMENTING, body),
  listCompanyIncrementingSuccess: (
    res: IListCompanyResponse
  ): ListCompanyIncrementingSuccessAction =>
    createAction(CompanyActionKeys.LIST_COMPANY_SUCCESS_INCREMENTING, res),
  listCompanyIncrementingFailure: (
    error: string
  ): ListCompanyIncrementingFailureAction =>
    createAction(CompanyActionKeys.LIST_COMPANY_FAILURE_INCREMENTING, error),

  listAllCompany: (body: IQueryParams): ListAllCompanyRequestAction =>
    createAction(CompanyActionKeys.ALL_COMPANY_LIST_REQUEST, body),
  listAllCompanySuccess: (
    res: IListCompanyResponse
  ): ListAllCompanySuccessAction =>
    createAction(CompanyActionKeys.ALL_COMPANY_LIST_SUCCESS, res),
  listAllCompanyFailure: (error: string): ListAllCompanyFailureAction =>
    createAction(CompanyActionKeys.ALL_COMPANY_LIST_FAILURE, error),

  listManagerCompany: (body: IQueryParams): ListManagerCompanyRequestAction =>
    createAction(CompanyActionKeys.MANAGER_COMPANY_LIST_REQUEST, body),
  listManagerSuccess: (
    res: IListManagerCompanyResponse
  ): ListManagerCompanySuccessAction =>
    createAction(CompanyActionKeys.MANAGER_COMPANY_LIST_SUCCESS, res),
  listManagerFailure: (error: string): ListManagerCompanyFailureAction =>
    createAction(CompanyActionKeys.MANAGER_COMPANY_LIST_FAILURE, error),

  patchCompanyOnConglomerateStatus: (
    body: IPatchCompanyStatusRequest
  ): PatchCompanyOnConglomerateStatusRequestAction =>
    createAction(
      CompanyActionKeys.PATCH_STATUS_COMPANY_ON_CONGLOMERATE_REQUEST,
      body
    ),
  patchCompanyConglomerateStatusSuccess: (
    res: IPatchCompanyStatusRequest
  ): PatchCompanyOnConglomerateStatusSuccessAction =>
    createAction(
      CompanyActionKeys.PATCH_STATUS_COMPANY_ON_CONGLOMERATE_SUCCESS,
      res
    ),
  patchCompanyConglomerateStatusFailure: (
    error: string
  ): PatchCompanyOnConglomerateStatusFailureAction =>
    createAction(
      CompanyActionKeys.PATCH_STATUS_COMPANY_ON_CONGLOMERATE_FAILURE,
      error
    ),

  patchCompany: (body: IPatchCompanyRequest): PatchCompanyRequestAction =>
    createAction(CompanyActionKeys.COMPANY_PATCH_REQUEST, body),

  getCompany: (externalId: string): GetCompanyRequestAction =>
    createAction(CompanyActionKeys.GET_COMPANY_REQUEST, externalId),
  getCompanySuccess: (res: IGetCompanyResponse): GetCompanySuccessAction =>
    createAction(CompanyActionKeys.GET_COMPANY_SUCCESS, res),
  getCompanyFailure: (error: string): GetCompanyFailureAction =>
    createAction(CompanyActionKeys.GET_COMPANY_FAILURE, error),

  getCompanyAdmin: (externalId: string): GetCompanyAdminRequestAction =>
    createAction(CompanyActionKeys.GET_COMPANY_ADMIN_REQUEST, externalId),
  getCompanyAdminSuccess: (
    res: IGetCompanyResponse
  ): GetCompanyAdminSuccessAction =>
    createAction(CompanyActionKeys.GET_COMPANY_ADMIN_SUCCESS, res),
  getCompanyAdminFailure: (error: string): GetCompanyAdminFailureAction =>
    createAction(CompanyActionKeys.GET_COMPANY_ADMIN_FAILURE, error),

  deleteCompany: (body: IDeteleCompany): DeleteCompanyRequestAction =>
    createAction(CompanyActionKeys.DELETE_COMPANY_REQUEST, body),
  deleteCompanyFailure: (error: string): DeleteCompanyFailureAction =>
    createAction(CompanyActionKeys.DELETE_COMPANY_FAILURE, error),

  createCompany: (body: ICreateCompanyRequest): CreateCompanyRequestAction =>
    createAction(CompanyActionKeys.CREATE_COMPANY_REQUEST, body),
  createBranchCompany: (
    body: ICreateCompanyRequest
  ): CreateBranchCompanyRequestAction =>
    createAction(CompanyActionKeys.CREATE_BRANCH_COMPANY_REQUEST, body),
  createCompanySuccess: (): CreateCompanySuccessAction =>
    createAction(CompanyActionKeys.CREATE_COMPANY_SUCCESS),
  createCompanyFailure: (error: string): CreateCompanyFailureAction =>
    createAction(CompanyActionKeys.CREATE_COMPANY_FAILURE, error),

  changeResponsibilytiUser: (
    body: IChangeResponsibilityUserRequest
  ): ChangeResponsibilyUserRequestAction =>
    createAction(CompanyActionKeys.CHANGE_RESPONSIBILYTI_USER_REQUEST, body),
  changeResponsibilytiUserSuccess: (
    res: IGetCompanyResponse
  ): ChangeResponsibilyUserSuccessAction =>
    createAction(CompanyActionKeys.CHANGE_RESPONSIBILYTI_USER_SUCCESS, res),
  changeResponsibilytiUserFailure: (
    error: string
  ): ChangeResponsibilyUserFailureAction =>
    createAction(CompanyActionKeys.CHANGE_RESPONSIBILYTI_USER_FAILURE, error),

  resetCompany: (): ResetCompanyAction =>
    createAction(CompanyActionKeys.RESET_COMPANY),

  changeCompanyAdress: (
    body: IChangeCompanyAdressRequest
  ): ChangeCompanyAdressRequestAction =>
    createAction(CompanyActionKeys.CHANGE_COMPANY_ADRESS_REQUEST, body),
  changeCompanyAdressFailure: (
    error: string
  ): ChangeCompanyAdressFailureAction =>
    createAction(CompanyActionKeys.CHANGE_COMPANY_ADRESS_FAILURE, error),

  changeCompanyPaying: (
    body: IChangeCompanyPayingRequest
  ): ChangeCompanyPayingRequestAction =>
    createAction(CompanyActionKeys.CHANGE_COMPANY_PAYING_REQUEST, body),
  changeCompanyPayingFailure: (
    error: string
  ): ChangeCompanyPayingFailureAction =>
    createAction(CompanyActionKeys.CHANGE_COMPANY_PAYING_FAILURE, error),

  toggleCouldNotDelete: () =>
    createAction(CompanyActionKeys.SHOW_COULD_NOT_DELETE),
};

export type CompanyActionUnion = ActionsUnion<typeof CompanyActions>;

export type SetLoadingAction = Action<CompanyActionKeys.SET_LOADING, boolean>;

export type PatchCurrencyRequestAction = Action<
  CompanyActionKeys.PATCH_CURRENCY_REQUEST,
  IPatchCurrencyRequest
>;
export type PatchCurrencyFailureAction = Action<
  CompanyActionKeys.PATCH_CURRENCY_FAILURE,
  string
>;

export type GetConfigurationsCurrencyRequestAction =
  Action<CompanyActionKeys.GET_COMPANY_CONFIGURATIONS_REQUEST>;
export type GetConfigurationsSuccessAction = Action<
  CompanyActionKeys.GET_COMPANY_CONFIGURATIONS_SUCCESS,
  IGetCompanyConfigurationsResponse
>;
export type GetConfigurationsFailureAction = Action<
  CompanyActionKeys.GET_COMPANY_CONFIGURATIONS_FAILURE,
  string
>;

export type GetCurrencyRequestAction =
  Action<CompanyActionKeys.GET_CURRENCY_REQUEST>;
export type GetCurrencySuccessAction = Action<
  CompanyActionKeys.GET_CURRENCY_SUCCESS,
  IGetCurrencyResponse
>;
export type GetCurrencyFailureAction = Action<
  CompanyActionKeys.GET_CURRENCY_FAILURE,
  string
>;

export type ListManagerCompanyRequestAction = Action<
  CompanyActionKeys.MANAGER_COMPANY_LIST_REQUEST,
  IQueryParams
>;
export type ListManagerCompanySuccessAction = Action<
  CompanyActionKeys.MANAGER_COMPANY_LIST_SUCCESS,
  IListManagerCompanyResponse
>;
export type ListManagerCompanyFailureAction = Action<
  CompanyActionKeys.MANAGER_COMPANY_LIST_FAILURE,
  string
>;

export type PatchCompanyStatusRequestAction = Action<
  CompanyActionKeys.PATCH_STATUS_REQUEST,
  IPatchCompanyStatusRequest
>;
export type PatchCompanyStatusSuccessAction = Action<
  CompanyActionKeys.PATCH_STATUS_SUCCESS,
  IPatchCompanyStatusRequest
>;
export type PatchCompanyStatusFailureAction = Action<
  CompanyActionKeys.PATCH_STATUS_FAILURE,
  string
>;

export type PatchCompanyRequestAction = Action<
  CompanyActionKeys.COMPANY_PATCH_REQUEST,
  IPatchCompanyRequest
>;

export type PatchCompanyOnConglomerateStatusRequestAction = Action<
  CompanyActionKeys.PATCH_STATUS_COMPANY_ON_CONGLOMERATE_REQUEST,
  IPatchCompanyStatusRequest
>;
export type PatchCompanyOnConglomerateStatusSuccessAction = Action<
  CompanyActionKeys.PATCH_STATUS_COMPANY_ON_CONGLOMERATE_SUCCESS,
  IPatchCompanyStatusRequest
>;
export type PatchCompanyOnConglomerateStatusFailureAction = Action<
  CompanyActionKeys.PATCH_STATUS_COMPANY_ON_CONGLOMERATE_FAILURE,
  string
>;

export type ListCompanyRequestAction = Action<
  CompanyActionKeys.COMPANY_LIST_REQUEST,
  IQueryParams
>;
export type ListCompanySuccessAction = Action<
  CompanyActionKeys.COMPANY_LIST_SUCCESS,
  IListCompanyResponse
>;
export type ListCompanyFailureAction = Action<
  CompanyActionKeys.COMPANY_LIST_FAILURE,
  string
>;

export type ListCompanyIncrementingRequestAction = Action<
  CompanyActionKeys.LIST_COMPANY_REQUEST_INCREMENTING,
  IQueryParams
>;
export type ListCompanyIncrementingSuccessAction = Action<
  CompanyActionKeys.LIST_COMPANY_SUCCESS_INCREMENTING,
  IListCompanyResponse
>;
export type ListCompanyIncrementingFailureAction = Action<
  CompanyActionKeys.LIST_COMPANY_FAILURE_INCREMENTING,
  string
>;

export type ListAllCompanyRequestAction = Action<
  CompanyActionKeys.ALL_COMPANY_LIST_REQUEST,
  IQueryParams
>;
export type ListAllCompanySuccessAction = Action<
  CompanyActionKeys.ALL_COMPANY_LIST_SUCCESS,
  IListCompanyResponse
>;
export type ListAllCompanyFailureAction = Action<
  CompanyActionKeys.ALL_COMPANY_LIST_FAILURE,
  string
>;

export type GetCompanyRequestAction = Action<
  CompanyActionKeys.GET_COMPANY_REQUEST,
  string
>;
export type GetCompanySuccessAction = Action<
  CompanyActionKeys.GET_COMPANY_SUCCESS,
  IGetCompanyResponse
>;
export type GetCompanyFailureAction = Action<
  CompanyActionKeys.GET_COMPANY_FAILURE,
  string
>;

export type GetCompanyAdminRequestAction = Action<
  CompanyActionKeys.GET_COMPANY_ADMIN_REQUEST,
  string
>;
export type GetCompanyAdminSuccessAction = Action<
  CompanyActionKeys.GET_COMPANY_ADMIN_SUCCESS,
  IGetCompanyResponse
>;
export type GetCompanyAdminFailureAction = Action<
  CompanyActionKeys.GET_COMPANY_ADMIN_FAILURE,
  string
>;

export type DeleteCompanyRequestAction = Action<
  CompanyActionKeys.DELETE_COMPANY_REQUEST,
  IDeteleCompany
>;
export type DeleteCompanyFailureAction = Action<
  CompanyActionKeys.DELETE_COMPANY_FAILURE,
  string
>;

export type CreateCompanyRequestAction = Action<
  CompanyActionKeys.CREATE_COMPANY_REQUEST,
  ICreateCompanyRequest
>;
export type CreateBranchCompanyRequestAction = Action<
  CompanyActionKeys.CREATE_BRANCH_COMPANY_REQUEST,
  ICreateCompanyRequest
>;
export type CreateCompanySuccessAction =
  Action<CompanyActionKeys.CREATE_COMPANY_SUCCESS>;
export type CreateCompanyFailureAction = Action<
  CompanyActionKeys.CREATE_COMPANY_FAILURE,
  string
>;

export type ChangeResponsibilyUserRequestAction = Action<
  CompanyActionKeys.CHANGE_RESPONSIBILYTI_USER_REQUEST,
  IChangeResponsibilityUserRequest
>;
export type ChangeResponsibilyUserSuccessAction = Action<
  CompanyActionKeys.CHANGE_RESPONSIBILYTI_USER_SUCCESS,
  IGetCompanyResponse
>;
export type ChangeResponsibilyUserFailureAction = Action<
  CompanyActionKeys.CHANGE_RESPONSIBILYTI_USER_FAILURE,
  string
>;

export type ChangeCompanyRequestAction = Action<
  CompanyActionKeys.CHANGE_COMPANY_REQUEST,
  IChangeCompanyRequest
>;
export type ChangeCompanyFailureAction = Action<
  CompanyActionKeys.CHANGE_COMPANY_FAILURE,
  string
>;

export type ChangeCompanyAdressRequestAction = Action<
  CompanyActionKeys.CHANGE_COMPANY_ADRESS_REQUEST,
  IChangeCompanyAdressRequest
>;
export type ChangeCompanyAdressFailureAction = Action<
  CompanyActionKeys.CHANGE_COMPANY_ADRESS_FAILURE,
  string
>;

export type ChangeCompanyPayingRequestAction = Action<
  CompanyActionKeys.CHANGE_COMPANY_PAYING_REQUEST,
  IChangeCompanyPayingRequest
>;
export type ChangeCompanyPayingFailureAction = Action<
  CompanyActionKeys.CHANGE_COMPANY_PAYING_FAILURE,
  string
>;

export type ResetCompanyAction = Action<CompanyActionKeys.RESET_COMPANY>;
