import * as yup from 'yup';
import { i18n } from '../../../Components';

export const CompanyDataCardSchema = yup.object().shape({
  cnpj: yup
    .string()
    .matches(
      /\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}/,
      i18n.t('adminBootys.createCompany.companyDataCard.errors.invalidCNPJ')
    )
    .required(
      i18n.t('adminBootys.createCompany.companyDataCard.errors.requiredCNPJ')
    ),
  razaoSocial: yup
    .string()
    .required(
      i18n.t(
        'adminBootys.createCompany.companyDataCard.errors.requiredSocialReason'
      )
    ),
  nomeFantasia: yup
    .string()
    .required(
      i18n.t(
        'adminBootys.createCompany.companyDataCard.errors.requiredFantasyName'
      )
    ),
  inscricaoEstadual: yup
    .string()
    .min(
      12,
      i18n.t(
        'adminBootys.createCompany.companyDataCard.errors.invalidStateRegistration'
      )
    )
    .required(
      i18n.t(
        'adminBootys.createCompany.companyDataCard.errors.requiredStateRegistration'
      )
    ),
  inscricaoMunicipal: yup
    .string()
    .min(
      11,
      i18n.t(
        'adminBootys.createCompany.companyDataCard.errors.invalidMunicipalRegistration'
      )
    )
    .required(
      i18n.t(
        'adminBootys.createCompany.companyDataCard.errors.requiredMunicipalRegistration'
      )
    ),
  cnae: yup
    .string()
    .required(
      i18n.t('adminBootys.createCompany.companyDataCard.errors.requiredCNAE')
    ),
});
