import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { HorizontalTabs } from '../../../../Components';
import { useCompanyAdminTypes } from '../../../../Hooks/Accountmanager/CompanyHooks';
import { PageLoading } from '../../../../Layouts/PageLoading';
import { DeleteCompanyModal } from './Modals/deleteModal';
import { CompanyDetails } from './Tabs/Details';
import { history } from '../../../../Store';

export const EditCompany: FC = () => {
  const { t } = useTranslation();

  const [visibleModal, setVisibleModal] = useState(false);

  const { externalId } = useParams<{ externalId: string }>();
  const { data, isLoading } = useCompanyAdminTypes(externalId);

  return (
    <PageLoading loading={isLoading}>
      <HorizontalTabs
        type="secondary"
        items={[
          {
            label: t('common.details'),
            key: 'details',
            children: data && <CompanyDetails company={data} />,
          },
        ]}
        dropdownDirection="bottomRight"
        dropdownTitle={t('admin.editUserPage.tab.buttonActions.title')}
        leftIcon="menu-ellipsis-horizontal"
        leftButton={{
          onClick: () => history.push(`/inventory/products//details`),
          leftIcon: 'Box',
          children: t('supply.lot.details.goToProducts'),
        }}
        dropdownItens={[
          {
            label: 'Excluir',
            icon: 'trash',
            onClick: () => setVisibleModal(true),
          },
        ]}
      />
      {data && (
        <DeleteCompanyModal
          company={data}
          visibleModal={visibleModal}
          changeVisibleModal={(value) => setVisibleModal(value)}
        />
      )}
    </PageLoading>
  );
};
