import { ILoginRequest, INewPasswordRequest, IRecoveryRequest } from '../../Data/Interfaces/request/Auth/ILoginRequest';
import { ILoginResponse } from '../../Data/Interfaces/response/Auth/ILoginResponse';
import { createAction, Action, ActionsUnion } from '../Base/actionHelpers';

export enum AuthActionKeys {
	LOGIN_REQUEST = '[AUTH] LOGIN_REQUEST',
	LOGIN_SUCCESS = '[AUTH] LOGIN_SUCCESS',
	LOGIN_FAILURE = '[AUTH] LOGIN_FAILURE',

	RECOVERY_REQUEST = '[RECOVERY] RECOVERY_REQUEST',
	RECOVERY_SUCCESS = '[RECOVERY] RECOVERY_SUCCESS',
	RECOVERY_FAILURE = '[RECOVERY] RECOVERY_FAILURE',

	NEWPASSWORD_REQUEST = '[NEWPASSWORD] NEWPASSWORD_REQUEST',
	NEWPASSWORD_SUCCESS = '[NEWPASSWORD] NEWPASSWORD_SUCCESS',
	NEWPASSWORD_FAILURE = '[NEWPASSWORD] NEWPASSWORD_FAILURE',
}

export const AuthActions = {
	login: (body: ILoginRequest): LoginRequestAction => createAction(AuthActionKeys.LOGIN_REQUEST, body),
	loginSuccess: (res: ILoginResponse): LoginSuccessAction => createAction(AuthActionKeys.LOGIN_SUCCESS, res),
	loginFailure: (error: string): LoginFailureAction => createAction(AuthActionKeys.LOGIN_FAILURE, error),

	recovery: (body: IRecoveryRequest): RecoveryRequestAction => createAction(AuthActionKeys.RECOVERY_REQUEST, body),
	RecoverySuccess: (): RecoverySuccessAction => createAction(AuthActionKeys.RECOVERY_SUCCESS),
	RecoveryFailure: (error: string): RecoveryFailureAction => createAction(AuthActionKeys.RECOVERY_FAILURE, error),

	newpassword: (body: INewPasswordRequest): NewPasswordRequestAction => createAction(AuthActionKeys.NEWPASSWORD_REQUEST, body),
	NewPasswordSuccess: (): NewPasswordSuccessAction => createAction(AuthActionKeys.NEWPASSWORD_SUCCESS),
	NewPasswordFailure: (error: string): NewPasswordFailureAction => createAction(AuthActionKeys.NEWPASSWORD_FAILURE, error),
};

export type AuthActionUnion = ActionsUnion<typeof AuthActions>;

export type LoginRequestAction = Action<AuthActionKeys.LOGIN_REQUEST, ILoginRequest>;
export type LoginSuccessAction = Action<AuthActionKeys.LOGIN_SUCCESS, ILoginResponse>;
export type LoginFailureAction = Action<AuthActionKeys.LOGIN_FAILURE, string>;

export type RecoveryRequestAction = Action<AuthActionKeys.RECOVERY_REQUEST, IRecoveryRequest>;
export type RecoverySuccessAction = Action<AuthActionKeys.RECOVERY_SUCCESS>;
export type RecoveryFailureAction = Action<AuthActionKeys.RECOVERY_FAILURE, string>;

export type NewPasswordRequestAction = Action<AuthActionKeys.NEWPASSWORD_REQUEST, INewPasswordRequest>;
export type NewPasswordSuccessAction = Action<AuthActionKeys.NEWPASSWORD_SUCCESS>;
export type NewPasswordFailureAction = Action<AuthActionKeys.NEWPASSWORD_FAILURE, string>;