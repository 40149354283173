import React, { useState } from 'react';
import { Table } from '../../../../Components';

import { useTranslation } from 'react-i18next';
import FormGroupModal from './Modal/FormGroupModal';
import { groupCollums } from './group.columns';
import { history } from '../../../../Store';
import { TitleHandler, BodyHandler } from './groupListUtils';
import {
  FETCH_LIST_GROUP,
  useGroupDelete,
  useGroupGetList,
} from '../../../../Hooks/Supply/ProductHooks';
import { useQueryClient } from '@tanstack/react-query';
import { IPagination } from '../../../../Components/Table';
import { PageLoading } from '../../../../Layouts/PageLoading';
import { handleModalError } from '../../../../Utils/ErrorHandlingUtils';
import { App } from 'antd';

export const List: React.FC = () => {
  const [selectedRowsOnTable, setSelectedRowsOnTable] = useState<any>([]);
  const [pagination, setPagination] = useState<IPagination>({
    PageIndex: 0,
    PageSize: 10,
    Sorter: { column: 'descricao', direction: 'ASC' },
  });
  const [visibleModal, setVisibleModal] = useState(false);
  const [visibleEditModal, setVisibleEditModal] = useState(false);
  const abbreviatedI18n = 'products.groupList';
  const { t } = useTranslation();

  const app = App.useApp();

  const deleteGroup = useGroupDelete();

  const { data: groupList, isLoading } = useGroupGetList(pagination);

  const queryClient = useQueryClient();

  return (
    <>
      <PageLoading loading={isLoading}>
        <Table
          columns={groupCollums(t)}
          data={
            groupList?.data?.map((x) => ({
              ...x,
              key: `table_group_${x.externalId}`,
            })) || []
          }
          loading={isLoading}
          headerOptions={{
            hasSearch: true,
            newButton: {
              label: t(`${abbreviatedI18n}.newButton`),
              onClick: () => setVisibleModal(true),
              permission: {
                permission: 3010,
                tooltip: 'noPermission.supply.groups.create',
              },
            },
            editButton:
              selectedRowsOnTable?.length === 1
                ? {
                    editButtonWithoutDropdown: true,
                    editButtonWithoutDropdownClick: () => {
                      setVisibleEditModal(true);
                    },
                    permission: {
                      permission: 3011,
                      tooltip: 'noPermission.supply.groups.edit',
                    },
                  }
                : undefined,
            deleteButton: {
              permission: {
                permission: 3012,
                tooltip: 'noPermission.supply.groups.delete',
              },
            },

            nameKey: 'descricao',
            searchPlaceholder: t(`${abbreviatedI18n}.placeholder`),
          }}
          selectedRowsOnTable={(x) => setSelectedRowsOnTable(x)}
          hasSelection={true}
          onDelete={(group) =>
            group.map(async (x) => {
              const res = await deleteGroup.mutateAsync(
                { gruposExternalId: group.map((x) => x?.externalId) },
                {
                  onError: handleModalError(app),
                }
              );
              if (res.success)
                queryClient.invalidateQueries([FETCH_LIST_GROUP]);
            })
          }
          deleteModal={{
            title: TitleHandler(selectedRowsOnTable, t),
            body: BodyHandler(selectedRowsOnTable, t),
            buttons: {
              okButtonColor: 'status-danger-base',
            },
          }}
          pagination={
            groupList
              ? {
                  Count: groupList.count,
                  PageIndex: groupList.pageIndex,
                  PageSize: groupList.pageSize,
                }
              : false
          }
          rowsOptions={{
            onRowDoubleClick: (x) =>
              history.push(`/inventory/groups/${x.externalId}/details`),
            permission: {
              permission: 3009,
              tooltip: 'noPermission.supply.groups.detail',
            },
          }}
          onRefetch={(pagination) => setPagination(pagination)}
        />
        <FormGroupModal
          changeVisible={(x) => setVisibleModal(x)}
          visible={visibleModal}
        />
        <FormGroupModal
          changeVisible={(x) => setVisibleEditModal(x)}
          visible={visibleEditModal}
          title={`${t('common.edit')} "${
            selectedRowsOnTable && selectedRowsOnTable[0]?.descricao
          }"`}
          selectedRowsOnTable={selectedRowsOnTable}
        />
      </PageLoading>
    </>
  );
};
