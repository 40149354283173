import React from 'react';
import { i18n, Status } from '../../../../Components';
import { Avatar } from '../../../../Components/Avatar';
import { IListUserData } from '../../../../Data/Interfaces/response/User/IGetUserReponse';
import { TagsRender } from '../../../../Utils/TagsUtils/TagsUtils';

export const userCollums = (t: any) => [
  {
    title: t('admin.userList.tableTitle.name'),
    dataIndex: 'nome',
    key: 'nome',
    width: '20%',
    sortColumn: 'nome',
    sorter: true,
    isHighlightable: true,
    defaultSortOrder: 'ascend',
    render: (firstName: string, row: IListUserData) => (
      <Avatar
        type="ui-tiny-bold"
        size="M"
        fisrtName={firstName}
        lastName={row.sobrenome}
        description={{
          description: `${firstName} ${row.sobrenome}`,
          type: 'ui-tiny-bold',
        }}
        routeLink={`/admin/user/${row.externalId}/details`}
      />
    ),
  },
  {
    title: t('admin.userList.tableTitle.email'),
    dataIndex: 'email',
    key: 'email',
    isHighlightable: true,
  },
  {
    title: t('admin.userList.tableTitle.status'),
    dataIndex: 'ativo',
    key: 'ativo',
    width: '20%',
    render: (text: any) => (
      <Status type={text ? 'success' : 'disabled'}>
        {i18n.t(`userGroups.list.statusTypes.${text ? 'active' : 'inactive'}`)}
      </Status>
    ),
  },
  {
    title: t('admin.userList.tableTitle.group'),
    dataIndex: 'grupos',
    key: 'grupos',
    width: 280,
    textWrap: 'word-break',
    render: (groups: any, row: any, _index: any, rowSelected: any) => {
      return (
        <TagsRender
          data={groups}
          width={280}
          rowSelected={rowSelected?.some(
            (rowSelectedCurrent: IListUserData) =>
              row.externalId === rowSelectedCurrent.externalId
          )}
        />
      );
    },
  },
];
