import { createAction, Action, ActionsUnion } from '../Base/actionHelpers';
import { IQueryParams } from '../../Components/Table';
import { IStockBalanceListResponse } from '../../Data/Interfaces/response/StockBalance/IStockBalanceResponse';

export enum StockBalanceActionKeys {
  LIST_STOCK_BALANCE_REQUEST = '[STOCK_BALANCE] LIST_STOCK_BALANCE_REQUEST',
  LIST_STOCK_BALANCE_SUCCESS = '[STOCK_BALANCE] LIST_STOCK_BALANCE_SUCCESS',
  LIST_STOCK_BALANCE_FAILURE = '[STOCK_BALANCE] LIST_STOCK_BALANCE_FAILURE',

  LIST_STOCK_MANY_BALANCE_REQUEST = '[STOCK_BALANCE] LIST_STOCK_MANY_BALANCE_REQUEST',
  LIST_STOCK_MANY_BALANCE_SUCCESS = '[STOCK_BALANCE] LIST_STOCK_MANY_BALANCE_SUCCESS',
  LIST_STOCK_MANY_BALANCE_FAILURE = '[STOCK_BALANCE] LIST_STOCK_MANY_BALANCE_FAILURE',
}

export const StockBalanceActions = {
  getListStockBalance: (body: IQueryParams): ListStockBalanceRequestAction =>
    createAction(StockBalanceActionKeys.LIST_STOCK_BALANCE_REQUEST, body),
  getListStockBalanceSuccess: (
    res: IStockBalanceListResponse
  ): ListStockBalanceSuccessAction =>
    createAction(StockBalanceActionKeys.LIST_STOCK_BALANCE_SUCCESS, res),
  getListStockBalanceFailure: (error: string): ListStockBalanceFailureAction =>
    createAction(StockBalanceActionKeys.LIST_STOCK_BALANCE_FAILURE, error),

  getListManyStockBalance: (query: {
    productId: string;
    placesIds: string[];
    query: IQueryParams;
  }): ListStockManyBalanceRequestAction =>
    createAction(StockBalanceActionKeys.LIST_STOCK_MANY_BALANCE_REQUEST, query),
  getListManyStockBalanceSuccess: (res: {
    productId: string;
    placesIds: string[];
    response: IStockBalanceListResponse;
  }): ListStockManyBalanceSuccessAction =>
    createAction(StockBalanceActionKeys.LIST_STOCK_MANY_BALANCE_SUCCESS, res),
  getListManyStockBalanceFailure: (
    error: string
  ): ListStockManyBalanceFailureAction =>
    createAction(StockBalanceActionKeys.LIST_STOCK_MANY_BALANCE_FAILURE, error),
};

export type StockBalanceActionUnion = ActionsUnion<typeof StockBalanceActions>;

export const getManyStockBalanceKey = (
  productId: string,
  placesIds: string[]
) => `${productId}:${placesIds.join(':')}`;

export type ListStockBalanceRequestAction = Action<
  StockBalanceActionKeys.LIST_STOCK_BALANCE_REQUEST,
  IQueryParams
>;
export type ListStockBalanceSuccessAction = Action<
  StockBalanceActionKeys.LIST_STOCK_BALANCE_SUCCESS,
  IStockBalanceListResponse
>;
export type ListStockBalanceFailureAction = Action<
  StockBalanceActionKeys.LIST_STOCK_BALANCE_FAILURE,
  string
>;

export type ListStockManyBalanceRequestAction = Action<
  StockBalanceActionKeys.LIST_STOCK_MANY_BALANCE_REQUEST,
  { productId: string; placesIds: string[]; query: IQueryParams }
>;
export type ListStockManyBalanceSuccessAction = Action<
  StockBalanceActionKeys.LIST_STOCK_MANY_BALANCE_SUCCESS,
  {
    productId: string;
    placesIds: string[];
    response: IStockBalanceListResponse;
  }
>;
export type ListStockManyBalanceFailureAction = Action<
  StockBalanceActionKeys.LIST_STOCK_MANY_BALANCE_FAILURE,
  string
>;
