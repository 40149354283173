import React, { FC, useEffect, useMemo } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { App as AntdApp } from 'antd';

import { AuthenticatedRoutes, UnauthenticatedRoutes } from './Routes';
import { getAuth } from './Store/Auth/Auth.selector';
import { IGlobalReducerState } from './Store/Base/Interfaces/IGlobalReducerState';
import { UserActions } from './Store/User/User.actions';
import { getMeUser } from './Store/User/User.selector';
import { ChangeLanguage } from './Utils/ChangeLanguageUtils';
import { keepDataStorageLogin } from './Utils/ThemeUtils';

import './Components/NProgress/NProgress.override.scss';
import { CurrencyActions } from './Store/Currency/Currency.actions';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { getCurrentCurrency } from './Store/Currency/Currency.selector';

interface IApp {
  isAuthenticated: boolean;
}

const App: FC<IApp> = ({ isAuthenticated }) => {
  const dispatch = useDispatch();
  const queryClient = useMemo(() => new QueryClient(), []);

  useEffect(() => {
    isAuthenticated && dispatch(UserActions.getMe());
  }, [dispatch, isAuthenticated]);

  useEffect(() => {
    isAuthenticated && dispatch(CurrencyActions.getCurrency());
  }, [dispatch, isAuthenticated]);

  const login = useSelector(getAuth);
  const user = useSelector(getMeUser);
  const currentCurrency = useSelector(getCurrentCurrency);

  useEffect(() => {
    keepDataStorageLogin(login, user, currentCurrency?.tipoMoedaId || 0);
    user?.preferencias && ChangeLanguage(user.preferencias.idioma);
  }, [login, user, currentCurrency]);

  return (
    <QueryClientProvider client={queryClient}>
      <AntdApp>
        {isAuthenticated ? <AuthenticatedRoutes /> : <UnauthenticatedRoutes />}
      </AntdApp>
    </QueryClientProvider>
  );
};

const mapStateToProps = (state: IGlobalReducerState) => {
  const { isAuthenticated } = state.auth;

  return {
    isAuthenticated,
  };
};

export default connect(mapStateToProps)(App);
