import React, { FC } from 'react';
import { Divflex } from '../../../../Layouts/DivWhithFlex';
import { Text } from '../../../../Components';
import { useTranslation } from 'react-i18next';
import { FooterThisActionIsPermanent } from '../../../../Components/Table/components/Modals/DeleteModal';
import { v4 as uuidV4 } from 'uuid';
import { Color } from '../../../../Utils/ColorUtils';

interface IDeleteModal {
  selectedRowsOnTable: any;
}

export const DeleteModal: FC<IDeleteModal> = ({ selectedRowsOnTable }) => {
  const { t } = useTranslation();
  return (
    <div style={{ padding: '20px' }}>
      <Divflex>
        <Text
          color="text-50"
          type="ui-tiny-content"
          children={`${t('modal.deleteModal.single.question')} ${t(
            'common.theCompany'
          )} `}
        />
        <Text
          color="text-50"
          type="ui-tiny-bold"
          children={`${selectedRowsOnTable[0]?.nomeFantasia}?`}
        />
      </Divflex>
      <FooterThisActionIsPermanent />
    </div>
  );
};

export const ConfirmEditModal = (selectedRowsOnTable: any, t: any) => ({
  body:
    selectedRowsOnTable?.length === 1 ? (
      <div style={{ padding: '20px' }}>
        <Divflex>
          <Text
            type="ui-tiny-content"
            children={`${t('modal.editModal.single.confirm.areYouSure')}${t(
              'common.theCompany'
            )} `}
          />
          <Text
            type="ui-tiny-bold"
            children={`${selectedRowsOnTable[0]?.nomeFantasia}`}
          />
          <Text type="ui-tiny-content" children={`?`} />
        </Divflex>
        <Text
          type="ui-tiny-content"
          children={t(
            'adminBootys.companyList.editModal.confirmDescriptionForOneCompany'
          )}
        />
      </div>
    ) : (
      <div style={{ padding: '20px' }}>
        <Text
          type="ui-tiny-content"
          children={`${t('modal.editModal.single.confirm.areYouSure')}${t(
            'common.theCompanys'
          )}:`}
        />
        <ul style={{ paddingInlineStart: '25px' }}>
          {selectedRowsOnTable?.map(
            (x: any, index: any) =>
              index < 4 && (
                <li key={uuidV4()}>
                  <Text color="text-50" type="ui-tiny-bold">
                    {x.nomeFantasia}
                  </Text>
                </li>
              )
          )}
          {selectedRowsOnTable?.length > 4 && (
            <li style={{ height: '18px' }}>
              <Text
                color="text-300"
                type="ui-tiny-content"
                children={`+ ${selectedRowsOnTable?.length - 4} ${t(
                  'common.companys'
                )}`}
              />
            </li>
          )}
        </ul>
        <Text
          type="ui-tiny-content"
          children={t(
            'adminBootys.companyList.editModal.confirmDescriptionForManyCompanys'
          )}
        />
      </div>
    ),
  title: `${t('common.inactivate')} ${
    selectedRowsOnTable?.length === 1
      ? `"${selectedRowsOnTable[0]?.nomeFantasia}"`
      : `${selectedRowsOnTable?.length} ${t('common.companys')}`
  }?`,
  minWidth: 400,
  okButtonColor: 'status-danger-base' as Color,
  okButtonName: t('common.inactivate'),
});
