import { IQueryParams } from '../../Components/Table';
import { IUpdatePermissionRequest } from '../../Data/Interfaces/request/User/IUpdatePermissionRequest';
import {
  IChangePasswordRequest,
  IPatchUserRequest,
  IPutUserRequest,
  ICreateUserRequest,
  IPatchUserStatusRequest,
} from '../../Data/Interfaces/request/User/IUserRequest';
import {
  IGetUserResponse,
  IListUserData,
  IListUserResponse,
} from '../../Data/Interfaces/response/User/IGetUserReponse';
import { IUpdatePermissionResponse } from '../../Data/Interfaces/response/User/IUpdatePermissionResponse';
import { createAction, Action, ActionsUnion } from '../Base/actionHelpers';

export enum UserActionKeys {
  SET_LOADING = '[USER] SET_LOADING',

  APPEND_LIST_USER_REQUEST_INCREMENTING = '[USER] APPEND_LIST_USER_REQUEST_INCREMENTING',

  GET_ME_REQUEST = '[USER] GET_ME_REQUEST',
  GET_ME_SUCCESS = '[USER] GET_ME_SUCCESS',
  GET_ME_FAILURE = '[USER] GET_ME_FAILURE',

  GET_USER_REQUEST = '[USER] GET_USER_REQUEST',
  GET_USER_SUCCESS = '[USER] GET_USER_SUCCESS',
  GET_USER_FAILURE = '[USER] GET_USER_FAILURE',

  RESET_USER = '[USER] RESET_USER',
  RESET_USER_LIST = '[USER] RESET_USER_LIST',

  PUT_USER_REQUEST = '[USER] PUT_USER_REQUEST',
  PUT_USER_FAILURE = '[USER] PUT_USER_FAILURE',

  PATCH_ME_REQUEST = '[USER] PATCH_ME_REQUEST',
  PATCH_ME_FAILURE = '[USER] PATCH_ME_FAILURE',

  CHANGE_CURRENT_CONTEXT = '[USER] CHANGE_CURRENT_CONTEXT',

  CHANGE_PASSWORD_REQUEST = '[USER] CHANGE_PASSWORD_REQUEST',
  CHANGE_PASSWORD_FAILURE = '[USER] CHANGE_PASSWORD_FAILURE',

  CREATE_USER_REQUEST = '[USER] CREATE_USER_REQUEST',

  LIST_USER_REQUEST = '[USER] LIST_USER_REQUEST',
  LIST_USER_SUCCESS = '[USER] LIST_USER_SUCCESS',
  LIST_USER_FAILURE = '[USER] LIST_USER_FAILURE',

  RESET_USER_LIST_INCREMENTING = '[USER] RESET_USER_LIST_INCREMENTING',
  REMOVE_USER_LIST_INCREMENTING = '[USER] REMOVE_USER_LIST_INCREMENTING',
  LIST_USER_REQUEST_INCREMENTING = '[USER] LIST_USER_REQUEST_INCREMENTING',
  LIST_USER_SUCCESS_INCREMENTING = '[USER] LIST_USER_SUCCESS_INCREMENTING',
  LIST_USER_FAILURE_INCREMENTING = '[USER] LIST_USER_FAILURE_INCREMENTING',

  DELETE_USER_REQUEST = '[USER] DELETE_USER_REQUEST',
  DELETE_USER_FAILURE = '[USER] DELETE_USER_FAILURE',

  PATCH_STATUS_REQUEST = '[USER] PATCH_STATUS_REQUEST',
  PATCH_STATUS_SUCCESS = '[USER] PATCH_STATUS_SUCCESS',
  PATCH_STATUS_FAILURE = '[USER] PATCH_STATUS_FAILURE',

  PUT_UPDATE_PERMISSION_REQUEST = '[USER] PUT_UPDATE_PERMISSION_REQUEST',

  SHOW_PERMISSION_DEPENDENCY_MODAL = '[USER] SHOW_PERMISSION_DEPENDENCY_MODAL',
  HIDE_PERMISSION_DEPENDENCY_MODAL = '[USER] HIDE_PERMISSION_DEPENDENCY_MODAL',

  UPDATE_USER_MODULE_STATUS = '[USER] UPDATE_USER_MODULE_STATUS',
}

export const UserActions = {
  setLoading: (loading: boolean): SetLoadingAction =>
    createAction(UserActionKeys.SET_LOADING, loading),
  getMe: (): GetMeRequestAction => createAction(UserActionKeys.GET_ME_REQUEST),
  getMeSuccess: (res: IGetUserResponse): GetMeSuccessAction =>
    createAction(UserActionKeys.GET_ME_SUCCESS, res),
  getMeFailure: (error: string): GetMeFailureAction =>
    createAction(UserActionKeys.GET_ME_FAILURE, error),

  getUser: (externalId: string): GetUserRequestAction =>
    createAction(UserActionKeys.GET_USER_REQUEST, externalId),
  getUserSuccess: (res: IGetUserResponse): GetUserSuccessAction =>
    createAction(UserActionKeys.GET_USER_SUCCESS, res),
  getUserFailure: (error: string): GetUserFailureAction =>
    createAction(UserActionKeys.GET_USER_FAILURE, error),

  resetUser: (): ResetGetUserUserAction =>
    createAction(UserActionKeys.RESET_USER),
  resetUserList: (): ResetUserListAction =>
    createAction(UserActionKeys.RESET_USER_LIST),

  putUser: (body: IPutUserRequest): PutUserRequestAction =>
    createAction(UserActionKeys.PUT_USER_REQUEST, body),
  putUserFailure: (error: string): PutUserFailureAction =>
    createAction(UserActionKeys.PUT_USER_FAILURE, error),

  patchMe: (body: IPatchUserRequest) =>
    createAction(UserActionKeys.PATCH_ME_REQUEST, body),
  patchMeFailure: (error: string): PatchMeFailureAction =>
    createAction(UserActionKeys.PATCH_ME_FAILURE, error),

  changePassword: (body: IChangePasswordRequest): ChangePasswordRequestAction =>
    createAction(UserActionKeys.CHANGE_PASSWORD_REQUEST, body),
  changePasswordFailure: (error: string): ChangePasswordFailureAction =>
    createAction(UserActionKeys.CHANGE_PASSWORD_FAILURE, error),

  changeCurrentContext: (contextName: string): ChangeCurrentContextAction =>
    createAction(UserActionKeys.CHANGE_CURRENT_CONTEXT, contextName),

  createUser: (body: ICreateUserRequest): CreateUserRequestAction =>
    createAction(UserActionKeys.CREATE_USER_REQUEST, body),

  listUser: (body: IQueryParams): ListUserRequestAction =>
    createAction(UserActionKeys.LIST_USER_REQUEST, body),
  listUserSuccess: (res: IListUserResponse): ListUserSuccessAction =>
    createAction(UserActionKeys.LIST_USER_SUCCESS, res),
  listUserFailure: (error: string): ListUserFailureAction =>
    createAction(UserActionKeys.LIST_USER_FAILURE, error),

  resetUserIncrementing: () =>
    createAction(UserActionKeys.RESET_USER_LIST_INCREMENTING),

  appendUserToIncrementingList: (
    body: IListUserData
  ): AppendUserToIncrementingList =>
    createAction(UserActionKeys.APPEND_LIST_USER_REQUEST_INCREMENTING, body),

  removeUserFromListIncrementing: (
    externalId: string
  ): RemoveUserFromListIncrementingRequestAction =>
    createAction(UserActionKeys.REMOVE_USER_LIST_INCREMENTING, externalId),
  listUserIncrementing: (
    body: IQueryParams
  ): ListUserIncrementingRequestAction =>
    createAction(UserActionKeys.LIST_USER_REQUEST_INCREMENTING, body),
  listUserIncrementingSuccess: (
    res: IListUserResponse
  ): ListUserIncrementingSuccessAction =>
    createAction(UserActionKeys.LIST_USER_SUCCESS_INCREMENTING, res),
  listUserIncrementingFailure: (
    error: string
  ): ListUserIncrementingFailureAction =>
    createAction(UserActionKeys.LIST_USER_FAILURE_INCREMENTING, error),

  patchUserStatus: (
    body: IPatchUserStatusRequest
  ): PatchUserStatusRequestAction =>
    createAction(UserActionKeys.PATCH_STATUS_REQUEST, body),

  patchUserStatusSuccess: (
    res: IPatchUserStatusRequest
  ): PatchUserStatusSuccessAction =>
    createAction(UserActionKeys.PATCH_STATUS_SUCCESS, res),

  patchUserStatusFailure: (error: string): PatchUserStatusFailureAction =>
    createAction(UserActionKeys.PATCH_STATUS_FAILURE, error),

  updatePermission: (data: IUpdatePermissionRequest): UpdatePermissionAction =>
    createAction(UserActionKeys.PUT_UPDATE_PERMISSION_REQUEST, data),

  showDependencyModal: (
    data: IUpdatePermissionResponse<IUpdatePermissionRequest>
  ): ShowPermissionDependencyModalAction =>
    createAction(UserActionKeys.SHOW_PERMISSION_DEPENDENCY_MODAL, data),

  hideDependencyModal: () =>
    createAction(UserActionKeys.HIDE_PERMISSION_DEPENDENCY_MODAL),

  updateUserModuleStatus: (payload: {
    permissionId: string;
    active: boolean;
    inherited: boolean;
  }): UpdateUserModuleStatusAction =>
    createAction(UserActionKeys.UPDATE_USER_MODULE_STATUS, payload),
};

export type UserActionUnion = ActionsUnion<typeof UserActions>;

export type SetLoadingAction = Action<UserActionKeys.SET_LOADING, boolean>;

export type UpdateUserModuleStatusAction = Action<
  UserActionKeys.UPDATE_USER_MODULE_STATUS,
  { permissionId: string; active: boolean; inherited: boolean }
>;

export type GetMeRequestAction = Action<UserActionKeys.GET_ME_REQUEST>;
export type GetMeSuccessAction = Action<
  UserActionKeys.GET_ME_SUCCESS,
  IGetUserResponse
>;
export type GetMeFailureAction = Action<UserActionKeys.GET_ME_FAILURE, string>;

export type GetUserRequestAction = Action<
  UserActionKeys.GET_USER_REQUEST,
  string
>;
export type GetUserSuccessAction = Action<
  UserActionKeys.GET_USER_SUCCESS,
  IGetUserResponse
>;
export type GetUserFailureAction = Action<
  UserActionKeys.GET_USER_FAILURE,
  string
>;

export type ResetGetUserUserAction = Action<UserActionKeys.RESET_USER>;
export type ResetUserListAction = Action<UserActionKeys.RESET_USER_LIST>;

export type PutUserRequestAction = Action<
  UserActionKeys.PUT_USER_REQUEST,
  IPutUserRequest
>;
export type PutUserFailureAction = Action<
  UserActionKeys.PUT_USER_FAILURE,
  string
>;

export type PatchMeRequestAction = Action<
  UserActionKeys.PATCH_ME_REQUEST,
  IPatchUserRequest
>;
export type PatchMeFailureAction = Action<
  UserActionKeys.PATCH_ME_FAILURE,
  string
>;

export type ChangePasswordRequestAction = Action<
  UserActionKeys.CHANGE_PASSWORD_REQUEST,
  IChangePasswordRequest
>;
export type ChangePasswordFailureAction = Action<
  UserActionKeys.CHANGE_PASSWORD_FAILURE,
  string
>;

export type ChangeCurrentContextAction = Action<
  UserActionKeys.CHANGE_CURRENT_CONTEXT,
  string
>;

export type CreateUserRequestAction = Action<
  UserActionKeys.CREATE_USER_REQUEST,
  ICreateUserRequest
>;

export type AppendUserToIncrementingList = Action<
  UserActionKeys.APPEND_LIST_USER_REQUEST_INCREMENTING,
  IListUserData
>;

export type ListUserRequestAction = Action<
  UserActionKeys.LIST_USER_REQUEST,
  IQueryParams
>;
export type ListUserSuccessAction = Action<
  UserActionKeys.LIST_USER_SUCCESS,
  IListUserResponse
>;
export type ListUserFailureAction = Action<
  UserActionKeys.LIST_USER_FAILURE,
  string
>;

export type RemoveUserFromListIncrementingRequestAction = Action<
  UserActionKeys.REMOVE_USER_LIST_INCREMENTING,
  string
>;

export type ListUserIncrementingRequestAction = Action<
  UserActionKeys.LIST_USER_REQUEST_INCREMENTING,
  IQueryParams
>;
export type ListUserIncrementingSuccessAction = Action<
  UserActionKeys.LIST_USER_SUCCESS_INCREMENTING,
  IListUserResponse
>;
export type ListUserIncrementingFailureAction = Action<
  UserActionKeys.LIST_USER_FAILURE_INCREMENTING,
  string
>;

export type PatchUserStatusRequestAction = Action<
  UserActionKeys.PATCH_STATUS_REQUEST,
  IPatchUserStatusRequest
>;
export type PatchUserStatusSuccessAction = Action<
  UserActionKeys.PATCH_STATUS_SUCCESS,
  IPatchUserStatusRequest
>;
export type PatchUserStatusFailureAction = Action<
  UserActionKeys.PATCH_STATUS_FAILURE,
  string
>;

export type UpdatePermissionAction = Action<
  UserActionKeys.PUT_UPDATE_PERMISSION_REQUEST,
  IUpdatePermissionRequest
>;

export type ShowPermissionDependencyModalAction = Action<
  UserActionKeys.SHOW_PERMISSION_DEPENDENCY_MODAL,
  IUpdatePermissionResponse<IUpdatePermissionRequest>
>;

export type HidePermissionDependencyModalAction =
  Action<UserActionKeys.HIDE_PERMISSION_DEPENDENCY_MODAL>;
