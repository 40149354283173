import { PermissionWrapper } from '../../../../../../PermissionWrapper';
import { useTranslation } from 'react-i18next';
import { formatDate } from '../../../../../../../Utils/DateUtils';
import { useLosseGet } from '../../../../../../../Hooks/Supply/LosseHooks';
import { useUserPreferencesType } from '../../../../../../../Hooks/Accountmanager/UserHooks';

export const DynamicEditLosseBreadcrumb = ({ match }: any) => {
  const { t } = useTranslation();
  const { data: loss } = useLosseGet(match.params.externalId);
  const { data: datePattern } = useUserPreferencesType();

  if (datePattern?.preferencias.padraoData === 'dd/mm/yyyy') {
    return (
      <span style={{ display: 'flex' }}>
        {`${loss?.descricaoProduto} | ${formatDate(
          loss?.dataLancamento || '',
          'dd/MM/yyyy HH:mm'
        )} ${t('common.complementDateHour')} ${formatDate(
          loss?.dataLancamento || '',
          'HH:mm'
        )}`}
      </span>
    );
  } else {
    return (
      <span style={{ display: 'flex' }}>
        {`${loss?.descricaoProduto} | ${formatDate(
          loss?.dataLancamento || '',
          'MM/dd/yyyy HH:mm'
        )} ${t('common.complementDateHour')} ${formatDate(
          loss?.dataLancamento || '',
          'HH:mm'
        )}`}
      </span>
    );
  }
};

export const DynamicEditLosseBreadcrumbTitle = ({ match }: any) => {
  const { t } = useTranslation();
  const { data: losse } = useLosseGet(match.params.externalId);
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {`${t('supply.losses.detail.title')} | ${losse?.descricaoProduto}`}
      <PermissionWrapper
        permission={1005}
        tooltip="noPermission.administrative.users.statusEdit"
      ></PermissionWrapper>
    </div>
  );
};
