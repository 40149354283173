import { useQueryClient } from '@tanstack/react-query';
import React from 'react';
import { Dispatch, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from '../../../../../Components';
import { Modal } from '../../../../../Components/Modal';
import { FooterThisActionIsPermanent } from '../../../../../Components/Table/components/Modals/DeleteModal';
import { IProductResponse } from '../../../../../Data/Interfaces/response/Product/IProductRespose';
import {
  FETCH_LIST_PRODUCT,
  useProductDelete,
} from '../../../../../Hooks/Supply/ProductHooks';
import { Divflex } from '../../../../../Layouts/DivWhithFlex';
import { history } from '../../../../../Store';

interface IDeleteProductModal {
  dispatch: Dispatch<any>;
  product?: IProductResponse;
  visibleModal: boolean;
  changeVisibleModal: (value: boolean) => void;
}

export const DeleteProductModal: FC<IDeleteProductModal> = ({
  product,
  visibleModal,
  changeVisibleModal,
}) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const deleteProduct = useProductDelete();

  return (
    <Modal
      title={`${t('common.delete')} ${t('common.product')} "${
        product?.descricao
      }"?`}
      body={
        <div style={{ padding: '20px' }}>
          <Divflex>
            <Text
              color="text-50"
              type="ui-tiny-content"
              children={`${t('modal.deleteModal.single.question')} ${t(
                'common.theProduct'
              )} `}
            />
            <Text
              color="text-50"
              type="ui-tiny-bold"
              children={`${product?.descricao}`}
              style={{ marginBottom: '15px' }}
            />
            <Text type="ui-tiny-content" children={'?'} />
          </Divflex>
          <FooterThisActionIsPermanent />
        </div>
      }
      okButtonColor="status-danger-base"
      visible={visibleModal}
      onOkClick={async () => {
        if (product) {
          const res = await deleteProduct.mutateAsync({
            produtosExternalId: [product?.externalId] as any,
          });
          if (res.success) {
            queryClient.invalidateQueries([FETCH_LIST_PRODUCT]);
            history.push(`/inventory/products`);
          }
        }
      }}
      okButtonName={t('common.delete')}
      onCancelClick={() => changeVisibleModal(false)}
      onClose={() => changeVisibleModal(false)}
    />
  );
};
