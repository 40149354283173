export const productsEN = {
  common: {
    product: 'Product',
    products: 'Products',
    subGroup: 'Subgroup',
    subGroups: 'Subgroups',
  },

  toolTips: {
    products: {
      nameOfProduct: 'Original product name:',
      labelDescription: 'Original label description:',
      class: 'Original Class:',
      nameOfGroup: 'Original Group:',
      nameOfSubGroup: 'Original Subgroup name:',
      unitStock: 'Original stock unit:',
      provider: 'Original provider:',
      enabled: 'Enabled',
      disabled: 'Disabled',
      originalValue: 'Original value:',
      costValue: 'Original cost value:',
      profitMargin: 'Original profit margin:',
      saleValue: 'Original sale value:',
    },
    subGroups: {
      nameOfSubGroup: 'Original Subgroup name:',
      nameOfGroup: 'Original Group:',
    },
    groups: {
      nameOfGroup: 'Original group name:',
    },
  },

  modal: {
    products: {
      title: 'It is not possible to disable batch control.',
      theProduct: 'The product ',
      amount: 'have quantity in stock and thats why ',
      desative: 'it is not possible to disable batch control.',
      warningFooter:
        'To deactivate batch control, empty active batches from the system by creating a balance adjustment.',
    },
  },

  productList: {
    searchPlaceholder: 'Search for a product',
    tableTitle: {
      code: 'Code',
      productName: 'Product name',
      class: 'Class',
      group: 'Group',
      subGroup: 'Subgroup',
      saleValue: 'Sale Price',
      costValue: 'Cost price',
      status: 'Status',
    },
    newButton: 'New Product',
    stockBalance: {
      searchPlaceHolder: 'Search for a stock location',
      code: 'Code',
      placeName: 'Place name',
      company: 'Company',
      availableQuantity: 'Available quantity',
    },
  },
  groupList: {
    tableTitle: {
      description: 'Name',
      subGroups: 'Subgroups',
      products: 'Products',
    },
    newButton: 'New group',
    placeholder: 'Search for a group',
    modal: {
      title: 'Group registration',

      label: 'Group name',
      placeholder: 'E.g.: Raw materials',

      okButton: 'Register',

      errorGroupNameExist: 'A group with that name already exists',
    },
  },
  subGroupList: {
    tableTitle: {
      description: 'Name',
      groups: 'Group',
      products: 'Products',
    },
    placeholder: 'Search for a subgroup',
    newButton: 'New subgroup',
    goToGroup: 'Go to Group',
    edit: {
      name: 'Name of Subgrupo',
      group: 'Grupo',

      success: 'Subgroup edited successfully',
    },
    deleted: 'Subgroup deleted',
    modal: {
      title: 'Subgroup registration',

      subGroupLabel: 'Subgroup name',
      subGroupPlaceholder: 'E.g.: Controlled',

      groupLabel: 'Group',
      groupPlaceholder: 'Search for a group',

      okButton: 'Register',

      errorSubGroupNameExist: 'A Subgroup with that name already exists',
    },
  },
  create: {
    titleInformation: 'Information',
    financeTitle: 'Finance',
    productName: 'Product Name',
    descriptionLabel: 'Label Description',
    descriptionScientific: 'Scientific Description',

    productCode: 'Product code',

    class: 'Class',
    classPlaceholder: 'Select a class',

    group: 'Group',
    groupPlaceholder: 'Select a group',

    subGroup: 'Subgroup',
    subGroupPlaceholder: 'Select a sub-group',

    provider: 'Provider',
    providerPlaceholder: 'Search a provider',
    providerTooltip:
      'Inform the supplier only if the product is exclusive to that supplier.',

    batchControlTooltip: 'Activate if the products stock balance is per batch.',
    qualityControlTooltip:
      'Activate if the product needs to carry out quality control after entry through the invoice.',
    continueUseTooltip:
      'Activate if the product is for continuous use or prolonged treatment.',
    tagTooltip:
      'Activate if you want to issue the products laboratory label after receipt of this product through the invoice.',

    measure: 'Unit of Measure',
    measurePlaceholder: 'Select a unit of measure',

    partControl: 'Part control',
    qualityControl: 'Quality control',

    continueUse: 'Continue use',
    tag: 'Tag',

    costPrice: 'Cost Price',
    costPricePlaceholder: 'Eg: BRL 10.00',

    profit: 'Profit (%)',
    profitPlaceholder: 'Eg: 60%',

    sellPrice: 'Sell Price',
    sellPricePlaceholder: 'Eg: BRL 10.00',

    save: 'Save',
    saveLoading: 'Saving',
    register: 'Register',
    cancel: 'Cancel',
    errors: {
      requiredName: 'Name is required',
      requiredProductClass: 'Class is required',
      requiredGroupdId: 'Group is required',
      requiredSubGroupdId: 'Subgroup is required',
      requiredMeasure: 'Measure is required',

      requiredCostPrice: 'Cost Price is required',
      requiredProfit: 'Profit is required',
      requiredSellPrice: 'Sell price is required',

      errorNameExist: 'A product with that name already exists',
      errorProductGroupExist: 'A product group with that name already exists',
      errorProductSubGroupExist:
        'A product subgroup with that name already exists',
      errorSubGroupExist: 'A subgroup with that name already exists',
    },

    success: {
      productCreated: 'Product created!',
      productDeleted: 'Product deleted!',
      productGroupEdited: 'Edited product group',
    },
  },

  edit: {
    status: {
      inactivate: 'Inactivate',
      confirmation: 'Are you sure you want to deactivate the product ',
    },
  },
};

export const productsPT = {
  common: {
    product: 'Produto',
    products: 'Produtos',
    subGroup: 'Subgrupo',
    subGroups: 'Subgrupos',
  },

  toolTips: {
    products: {
      nameOfProduct: 'Nome do produto original:',
      labelDescription: 'Descrição de rótulo original:',
      class: 'Classe original:',
      nameOfGroup: 'Grupo original:',
      nameOfSubGroup: 'Subgrupo original:',
      unitStock: 'Unidade de estoque original:',
      provider: 'Fornecedor original:',
      enabled: 'Ativado',
      disabled: 'Desativado',
      originalValue: 'Valor original:',
      costValue: 'Valor de custo original:',
      profitMargin: 'Margem de lucro original:',
      saleValue: 'Valor de venda original:',
    },
    subGroups: {
      nameOfSubGroup: 'Nome do Subgrupo original:',
      nameOfGroup: 'Grupo original:',
    },
    groups: {
      nameOfGroup: 'Nome do grupo original:',
    },
  },

  modal: {
    products: {
      title: 'Não é possível desativar o controle de lote.',
      theProduct: 'O produto ',
      amount: 'tem quantidade em estoque e por isso ',
      desative: 'não é possível desativar o controle de lote.',
      warningFooter:
        'Para desativar o controle de lote, esvazie os lotes ativos do sistema criando um ajuste de saldo.',
    },
  },

  productList: {
    searchPlaceholder: 'Pesquise por um produto',
    tableTitle: {
      code: 'Código',
      productName: 'Nome do Produto',
      class: 'Classe',
      group: 'Grupo',
      subGroup: 'Subgrupo',
      saleValue: 'Valor de Venda',
      costValue: 'Valor de custo',
      status: 'Status',
    },
    newButton: 'Novo Produto',
    stockBalance: {
      searchPlaceHolder: 'Pesquise por um local de estoque',
      code: 'Código',
      placeName: 'Nome do local',
      company: 'Empresa',
      availableQuantity: 'Quantidade disponível',
    },
  },
  groupList: {
    tableTitle: {
      description: 'Nome',
      subGroups: 'Subgrupos',
      products: 'Produtos',
    },
    placeholder: 'Pesquise por um grupo',
    newButton: 'Novo Grupo',
    modal: {
      title: 'Cadastro de grupo',

      label: 'Nome do grupo',
      placeholder: 'Ex: Matérias-primas',

      okButton: 'Cadastrar',

      errorGroupNameExist: 'Já existe um grupo com esse nome',
    },
  },
  subGroupList: {
    tableTitle: {
      description: 'Nome',
      groups: 'Grupo',
      products: 'Produtos',
    },
    placeholder: 'Pesquise por um subgrupo',
    newButton: 'Novo Subgrupo',
    goToGroup: 'Ir para Grupo',
    edit: {
      name: 'Nome do Subgrupo',
      group: 'Grupo',

      success: 'Subgroup editado com sucesso',
    },
    deleted: 'Subgrupo deletado',
    modal: {
      title: 'Cadastro de subgrupo',

      subGroupLabel: 'Nome do subgrupo',
      subGroupPlaceholder: 'Ex: Controlados',

      groupLabel: 'Grupo',
      groupPlaceholder: 'Procure por um grupo',

      okButton: 'Cadastrar',

      errorSubGroupNameExist: 'Já existe um subgrupo com esse nome',
    },
  },
  create: {
    titleInformation: 'Informações',
    financeTitle: 'Financeiro',
    productName: 'Nome do Produto',
    descriptionLabel: 'Descrição do Rótulo',
    descriptionScientific: 'Descrição Científica',

    productCode: 'Código do produto',

    class: 'Classe',
    classPlaceholder: 'Selecione uma classe',

    group: 'Grupo',
    groupPlaceholder: 'Selecione um grupo',

    subGroup: 'Subgrupo',
    subGroupPlaceholder: 'Selecione um subgrupo',

    provider: 'Fornecedor',
    providerPlaceholder: 'Procure por um fornecedor',
    providerTooltip:
      'Informe o fornecedor somente se o produto for exclusivo desse fornecedor.',

    batchControlTooltip: 'Ativar se o saldo de estoque do produto é por lote.',
    qualityControlTooltip:
      'Ativar se o produto precisa realizar o controle de qualidade após a entrada através da nota fiscal.',
    continueUseTooltip:
      'Ativar se o produto for de uso contínuo ou tratamento prolongado.',
    tagTooltip:
      'Ativar se deseja emitir a etiqueta de laboratório do produto após os recebimentos desse produto através da nota fiscal.',

    measure: 'Unidade de Estoque',
    measurePlaceholder: 'Selecione uma un. de medida',

    partControl: 'Controle de Lote',
    qualityControl: 'Controle de Qualidade',

    continueUse: 'Uso contínuo',
    tag: 'Etiqueta',

    costPrice: 'Valor de Custo',
    costPricePlaceholder: 'Ex: R$10,00',

    profit: 'Margem de Lucro (%)',
    profitPlaceholder: 'Ex: 60%',

    sellPrice: 'Valor de Venda',
    sellPricePlaceholder: 'Ex: R$10,00',

    save: 'Salvar',
    saveLoading: 'Salvando',
    register: 'Cadastrar',

    cancel: 'Cancelar',
    errors: {
      requiredName: 'Nome é obrigatório',
      requiredProductClass: 'Classe é obrigatório',
      requiredGroupdId: 'Grupo é obrigatório',
      requiredSubGroupdId: 'Subgrupo é obrigatório',
      requiredMeasure: 'Unidade de medida é obrigatório',

      requiredCostPrice: 'Valor de custo é obrigatório',
      requiredProfit: 'Margem de lucro é obrigatório',
      requiredSellPrice: 'Valor de venda é obrigatório',

      errorNameExist: 'Já existe um produto com esse nome',
      errorProductGroupExist: 'Já existe um grupo de produto com esse nome',
      errorProductSubGroupExist:
        'Já existe um subgrupo de produto com esse nome',
      errorSubGroupExist: 'Já existe um subgrupo com esse nome',
    },

    success: {
      productCreated: 'Produto criado!',
      productDeleted: 'Produto deletado!',
      productGroupEdited: 'Grupo de produto editado',
    },
  },

  edit: {
    status: {
      inactivate: 'Inativar',
      confirmation: 'Você tem certeza que deseja inativar o produto ',
    },
  },
};
