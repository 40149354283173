import { AxiosResponse } from 'axios';
import { Http } from '../../../Utils/Http';
import { IQueryParams } from '../../../Components/Table';
import {
  IGetConglomerateResponse,
  IListConglomerateResponse,
} from '../../Interfaces/response/Conglomerate/IConglomerateResponde';
import {
  IDeteleConglomerateRequest,
  IPatchConglomerateStatusRequest,
  IPostConglomerateRequest,
  IPutConglomerateRequest,
} from '../../Interfaces/request/Conglomerate/IConglomerate';
import { QueryFunctionContext } from '@tanstack/react-query';
import { ConvertDate } from '../../../Utils/DateUtils';
import StorageUtils from '../../../Utils/StorageUtils';

export class ConglomerateAPI {
  public static async createConglomerate(body: IPostConglomerateRequest) {
    return await Http.fetcher('/pharma/accountmanager/v1/Empresa', {
      method: 'POST',
      body: JSON.stringify(body),
    });
  }
  public static async deleteConglomerate(body: IDeteleConglomerateRequest) {
    return await Http.fetcher('/pharma/accountmanager/v1/Conglomerado', {
      method: 'DELETE',
      body: JSON.stringify(body),
    });
  }
  public static async editConglomerate(body: IPutConglomerateRequest) {
    return await Http.fetcher('/pharma/accountmanager/v1/Conglomerado', {
      method: 'PUT',
      body: JSON.stringify(body),
    });
  }
  public static async changeStatusConglomerate(
    body: IPatchConglomerateStatusRequest
  ) {
    return await Http.fetcher(
      '/pharma/accountmanager/v1/Conglomerado/AtualizarStatus',
      {
        method: 'PUT',
        body: JSON.stringify(body),
      }
    );
  }

  public static async getConglomerate(
    context: QueryFunctionContext
  ): Promise<IGetConglomerateResponse | null> {
    if (!context.queryKey[1]) return null;
    const res: IGetConglomerateResponse = await Http.fetcher(
      `/pharma/accountmanager/v1/Conglomerado/${context.queryKey[1]}`
    );
    const dateFormated = ConvertDate(
      res.dataInclusao,
      StorageUtils.getPreference().padraoData
    );

    if (dateFormated) res.dataInclusao = dateFormated;

    return res;
  }
  public static async listConglomerate(
    context: QueryFunctionContext
  ): Promise<IListConglomerateResponse> {
    return await Http.fetcher(
      Http.AddQueryParams(
        '/pharma/accountmanager/v1/Conglomerado/ListarDetalhadoAdmin',
        context.queryKey[1] as any
      )
    );
  }
  public static async listConglomerateForDropdown(
    context: QueryFunctionContext
  ): Promise<IListConglomerateResponse> {
    return await Http.fetcher(
      Http.AddQueryParams(
        '/pharma/accountmanager/v1/Conglomerado/ListarAdmin',
        context.queryKey[1] as any
      )
    );
  }

  public static async getCompanyForConglomerate(
    body: IQueryParams
  ): Promise<AxiosResponse<any>> {
    const axios = await Http.axios();
    return axios.get<any>(
      Http.AddODataQueryParams('/accountmanager/odata/v1/Admin/Empresa', body)
    );
  }
}
