import { Col, Row } from 'antd';
import React, { FC, useState } from 'react';
import { Button, Card, Form, TextInput } from '../../../../../../Components';
import { Avatar } from '../../../../../../Components/Avatar';
import { InputWithSearch } from '../../../../../../Components/Inputs/InputWithSearch';
import { TextInputPhoneMask } from '../../../../../../Components/Inputs/TextInputPhoneMask';
import { TextInputWithMask } from '../../../../../../Components/Inputs/TextInputWithMask';
import { IPutUserRequest } from '../../../../../../Data/Interfaces/request/User/IUserRequest';
import { RemoveMask } from '../../../../../../Utils/MasksUtils';
import { DetailSchema } from './DetailForm.schema';
import { getCardStatus } from '../../../../../../Components/Card';
import { history } from '../../../../../../Store';
import { useTranslation } from 'react-i18next';
import { PermissionWrapper } from '../../../../../../Components/PermissionWrapper';
import { useParams } from 'react-router';
import {
  useEditUserMutation,
  useGetUserType,
} from '../../../../../../Hooks/Accountmanager/UserHooks';
import { useGetUserListFromUserGroupType } from '../../../../../../Hooks/Accountmanager/UserGroupHooks';
import { IQueryListParams } from '../../../../../../Utils/Http';

import styles from './DetailForm.module.scss';

export const DetailForm: FC = () => {
  const [buttonDisabled, setButtonDisebled] = useState(true);
  const [paginationUserGroup, setPaginationUserGroup] =
    useState<IQueryListParams>({
      PageIndex: 0,
      PageSize: 10,
    });

  const params: Readonly<Partial<{ externalId: string | undefined }>> =
    useParams();

  const { data: user } = useGetUserType(params.externalId);
  const editUser = useEditUserMutation({
    onSuccess: () => setButtonDisebled(true),
  });
  const { data: group } = useGetUserListFromUserGroupType(paginationUserGroup);

  const { t } = useTranslation();

  const onSubmit = async (values: IPutUserRequest) => {
    const body: IPutUserRequest = { ...values };

    body.dataNascimento = RemoveMask(
      body.dataNascimento,
      user?.preferencias.padraoData
    );

    await editUser.mutateAsync(body);
  };

  return (
    <Form
      initialValues={{
        ...user,
        grupos: user?.grupos.map((x) => x.grupoExternalId),
      }}
      onSubmit={onSubmit}
      schema={DetailSchema(user?.preferencias.padraoData)}
    >
      {(props) => (
        <Card
          title={t('admin.createUserPage.personalData')}
          status={getCardStatus(['nome', 'sobrenome'], props)}
          withoutCollapse
        >
          <Row justify="space-between">
            <Col span={24}>
              {user && (
                <Avatar
                  fisrtName={user.nome}
                  lastName={user.sobrenome}
                  className={styles['avatar']}
                  type="heading2"
                  size="XXL"
                />
              )}
            </Col>
          </Row>
          <Row gutter={[16, 0]}>
            <Col span={12}>
              <TextInput
                name="nome"
                label={t('admin.editUserPage.personalDataForm.name')}
                placeHolder={t(
                  'admin.editUserPage.personalDataForm.namePlaceholder'
                )}
                disabled={buttonDisabled}
              />
            </Col>
            <Col span={12}>
              <TextInput
                name="sobrenome"
                label={t('admin.editUserPage.personalDataForm.lastName')}
                placeHolder={t(
                  'admin.editUserPage.personalDataForm.lastNamePlaceholder'
                )}
                disabled={buttonDisabled}
              />
            </Col>
          </Row>
          <Row gutter={[16, 0]}>
            <Col span={12}>
              <TextInputWithMask
                name="dataNascimento"
                label={t('admin.editUserPage.personalDataForm.birthDate')}
                placeHolder={t(
                  'admin.editUserPage.personalDataForm.birthDatePlaceholder'
                )}
                disabled={buttonDisabled}
                mask="dataMask"
              />
            </Col>
            <Col span={12}>
              <TextInputPhoneMask
                name="celular"
                label={t('admin.editUserPage.personalDataForm.telephone')}
                placeHolder={t(
                  'admin.editUserPage.personalDataForm.telephonePlaceholder'
                )}
                disabled={buttonDisabled}
              />
            </Col>
          </Row>
          <Row gutter={[16, 0]}>
            <Col span={12}>
              <TextInput
                name="email"
                label={t('admin.editUserPage.personalDataForm.email')}
                placeHolder={t(
                  'admin.editUserPage.personalDataForm.emailPlaceholder'
                )}
                disabled={buttonDisabled}
              />
            </Col>
            <Col span={12}>
              <TextInput
                name="emailAlternativo"
                label={t(
                  'admin.editUserPage.personalDataForm.alternativeEmail'
                )}
                placeHolder={t(
                  'admin.editUserPage.personalDataForm.alternativeEmailPlaceholder'
                )}
                disabled={buttonDisabled}
              />
            </Col>
          </Row>
          <Row gutter={[16, 0]}>
            <Col span={24}>
              <PermissionWrapper
                permission={1006}
                tooltip="noPermission.administrative.users.editGroup"
              >
                <InputWithSearch
                  name="grupos"
                  label={t('admin.editUserPage.personalDataForm.group')}
                  placeHolder={t(
                    'admin.editUserPage.personalDataForm.groupPlaceholder'
                  )}
                  onTag
                  autoIncrementBy="id"
                  typeTag="link-secondary"
                  items={
                    group
                      ? user && [
                          ...(group?.data.map((x) => ({
                            id: x.externalId,
                            label: x.nome,
                          })) as any),
                          ...(user?.grupos.map((x) => ({
                            id: x.grupoExternalId,
                            label: x.nomeGrupo,
                          })) as any),
                        ]
                      : undefined
                  }
                  onScrollEnd={() =>
                    setPaginationUserGroup({
                      ...paginationUserGroup,
                      PageIndex: paginationUserGroup.PageIndex + 1,
                    })
                  }
                  disabled={buttonDisabled}
                />
              </PermissionWrapper>
            </Col>
          </Row>
          <Row>
            <div className={styles['footer-button']}>
              {buttonDisabled ? (
                <PermissionWrapper
                  permission={1004}
                  tooltip="noPermission.administrative.users.edit"
                >
                  <Button
                    type="secondary"
                    children={t('admin.editUserPage.footerButtons.editDetails')}
                    onClick={() => setButtonDisebled(false)}
                  />
                </PermissionWrapper>
              ) : (
                <>
                  <Button
                    type="secondary"
                    children={t('admin.editUserPage.footerButtons.cancel')}
                    onClick={() => history.push('/admin/user')}
                  />
                  <Button
                    type="primary"
                    children={t('admin.editUserPage.footerButtons.save')}
                    htmlType="submit"
                    loading={editUser.isLoading}
                  />
                </>
              )}
            </div>
          </Row>
        </Card>
      )}
    </Form>
  );
};
