import { IProviderState } from './interfaces/IProviderState';
import { ProviderActionKeys, ProviderActionUnion } from './Provider.actions';

const initialData: IProviderState = {
  isLoading: false,
};

export const ProviderReducer = (
  state = initialData,
  action: ProviderActionUnion
): IProviderState => {
  switch (action.type) {
    case ProviderActionKeys.LIST_PROVIDER_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: undefined,
      };
    case ProviderActionKeys.LIST_PROVIDER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: undefined,
        currentProviderList: action.payload,
      };
    case ProviderActionKeys.LIST_PROVIDER_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default ProviderReducer;
