import { createAction, Action, ActionsUnion } from '../Base/actionHelpers';
import { IQueryParams } from '../../Components/Table';
import {
  IGetCompanyForConglomerateResponse,
  IGetConglomerateResponse,
  IListConglomerateResponse,
} from '../../Data/Interfaces/response/Conglomerate/IConglomerateResponde';
import {
  IDeteleConglomerateRequest,
  IPatchConglomerateStatusRequest,
  IPutConglomerateRequest,
} from '../../Data/Interfaces/request/Conglomerate/IConglomerate';

export enum ConglomerateActionKeys {
  SET_LOADING = '[CONGLOMERATE] SET_LOADING',

  RESET_CONGLOMERATE = '[CONGLOMERATE] RESET_CONGLOMERATE',

  RESET_CONGLOMERATE_INCREMENTING = '[CONGLOMERATE] RESET_CONGLOMERATE_INCREMENTING',

  PARAMS_CONGLOMERATE_ID = '[CONGLOMERATE] PARAMS_CONGLOMERATE_ID',

  LIST_CONGLOMERATE_REQUEST = '[CONGLOMERATE] LIST_CONGLOMERATE_REQUEST',
  LIST_CONGLOMERATE_SUCCESS = '[CONGLOMERATE] LIST_CONGLOMERATE_SUCCESS',
  LIST_CONGLOMERATE_FAILURE = '[CONGLOMERATE] LIST_CONGLOMERATE_FAILURE',

  ALL_LIST_CONGLOMERATE_REQUEST = '[CONGLOMERATE] ALL_LIST_CONGLOMERATE_REQUEST',
  ALL_LIST_CONGLOMERATE_SUCCESS = '[CONGLOMERATE] ALL_LIST_CONGLOMERATE_SUCCESS',
  ALL_LIST_CONGLOMERATE_FAILURE = '[CONGLOMERATE] ALL_LIST_CONGLOMERATE_FAILURE',

  GET_CONGLOMERATE_REQUEST = '[CONGLOMERATE] GET_CONGLOMERATE_REQUEST',
  GET_CONGLOMERATE_SUCCESS = '[CONGLOMERATE] GET_CONGLOMERATE_SUCCESS',
  GET_CONGLOMERATE_FAILURE = '[CONGLOMERATE] GET_CONGLOMERATE_FAILURE',

  GET_COMPANY_FOR_CONGLOMERATE_REQUEST = '[CONGLOMERATE] GET_COMPANY_FOR_CONGLOMERATE_REQUEST',
  GET_COMPANY_FOR_CONGLOMERATE_SUCCESS = '[CONGLOMERATE] GET_COMPANY_FOR_CONGLOMERATE_SUCCESS',
  GET_COMPANY_FOR_CONGLOMERATE_FAILURE = '[CONGLOMERATE] GET_COMPANY_FOR_CONGLOMERATE_FAILURE',

  GET_COMPANY_FOR_CONGLOMERATE_REQUEST_INCREMENTING = '[CONGLOMERATE] GET_COMPANY_FOR_CONGLOMERATE_REQUEST_INCREMENTING',
  GET_COMPANY_FOR_CONGLOMERATE_SUCCESS_INCREMENTING = '[CONGLOMERATE] GET_COMPANY_FOR_CONGLOMERATE_SUCCESS_INCREMENTING',
  GET_COMPANY_FOR_CONGLOMERATE_FAILURE_INCREMENTING = '[CONGLOMERATE] GET_COMPANY_FOR_CONGLOMERATE_FAILURE_INCREMENTING',

  PUT_CONGLOMERATE_REQUEST = '[CONGLOMERATE] PUT_CONGLOMERATE_REQUEST',
  PUT_CONGLOMERATE_SUCCESS = '[CONGLOMERATE] PUT_CONGLOMERATE_SUCCESS',
  PUT_CONGLOMERATE_FAILURE = '[CONGLOMERATE] PUT_CONGLOMERATE_FAILURE',

  DELETE_CONGLOMERATE_REQUEST = '[CONGLOMERATE] DELETE_CONGLOMERATE_REQUEST',
  DELETE_CONGLOMERATE_FAILURE = '[CONGLOMERATE] DELETE_CONGLOMERATE_FAILURE',

  PATCH_STATUS_REQUEST = '[CONGLOMERATE] PATCH_STATUS_REQUEST',
  PATCH_STATUS_SUCCESS = '[CONGLOMERATE] PATCH_STATUS_SUCCESS',

  SET_CONFIRM_STATUS_MODAL_VISIBILITY = '[CONGLOMERATE] SET_CONFIRM_STATUS_MODAL_VISIBILITY',
}

export const ConglomerateActions = {
  setLoading: (loading: boolean): SetLoadingAction =>
    createAction(ConglomerateActionKeys.SET_LOADING, loading),

  resetConglomerate: (): ResetConglomerateAction =>
    createAction(ConglomerateActionKeys.RESET_CONGLOMERATE),

  resetConglomerateIncrementing: (): ResetConglomerateIncrementingAction =>
    createAction(ConglomerateActionKeys.RESET_CONGLOMERATE_INCREMENTING),

  paramsConglomerateId: (body: string): ParamsConglomerateIdAction =>
    createAction(ConglomerateActionKeys.PARAMS_CONGLOMERATE_ID, body),

  getListConglomerate: (body: IQueryParams): ListConglomerateRequestAction =>
    createAction(ConglomerateActionKeys.LIST_CONGLOMERATE_REQUEST, body),
  getListConglomerateSuccess: (
    res: IListConglomerateResponse
  ): ListConglomerateSuccessAction =>
    createAction(ConglomerateActionKeys.LIST_CONGLOMERATE_SUCCESS, res),
  getListConglomerateFailure: (error: string): ListConglomerateFailureAction =>
    createAction(ConglomerateActionKeys.LIST_CONGLOMERATE_FAILURE, error),

  listAllConglomerate: (body: IQueryParams): ListAllConglomerateRequestAction =>
    createAction(ConglomerateActionKeys.ALL_LIST_CONGLOMERATE_REQUEST, body),
  listAllConglomerateSuccess: (
    res: IListConglomerateResponse
  ): ListAllConglomerateSuccessAction =>
    createAction(ConglomerateActionKeys.ALL_LIST_CONGLOMERATE_SUCCESS, res),
  listAllConglomerateFailure: (
    error: string
  ): ListAllConglomerateFailureAction =>
    createAction(ConglomerateActionKeys.ALL_LIST_CONGLOMERATE_FAILURE, error),

  getConglomerate: (externalId: string): GetConglomerateRequestAction =>
    createAction(ConglomerateActionKeys.GET_CONGLOMERATE_REQUEST, externalId),
  getConglomerateSuccess: (
    res: IGetConglomerateResponse
  ): GetConglomerateSuccessAction =>
    createAction(ConglomerateActionKeys.GET_CONGLOMERATE_SUCCESS, res),
  getConglomerateFailure: (error: string): GetConglomerateFailureAction =>
    createAction(ConglomerateActionKeys.GET_CONGLOMERATE_FAILURE, error),

  getCompanyForConglomerate: (
    body: IQueryParams
  ): GetCompanyForConglomerateRequestAction =>
    createAction(
      ConglomerateActionKeys.GET_COMPANY_FOR_CONGLOMERATE_REQUEST,
      body
    ),
  getCompanyForConglomerateSuccess: (
    res: IGetCompanyForConglomerateResponse
  ): GetCompanyForConglomerateSuccessAction =>
    createAction(
      ConglomerateActionKeys.GET_COMPANY_FOR_CONGLOMERATE_SUCCESS,
      res
    ),
  getCompanyForConglomerateFailure: (
    error: string
  ): GetCompanyForConglomerateFailureAction =>
    createAction(
      ConglomerateActionKeys.GET_COMPANY_FOR_CONGLOMERATE_FAILURE,
      error
    ),

  getCompanyForConglomerateIncrementing: (
    body: IQueryParams
  ): GetCompanyForConglomerateIncrementingRequestAction =>
    createAction(
      ConglomerateActionKeys.GET_COMPANY_FOR_CONGLOMERATE_REQUEST_INCREMENTING,
      body
    ),
  getCompanyForConglomerateIncrementingSuccess: (
    res: IGetCompanyForConglomerateResponse
  ): GetCompanyForConglomerateIncrementingSuccessAction =>
    createAction(
      ConglomerateActionKeys.GET_COMPANY_FOR_CONGLOMERATE_SUCCESS_INCREMENTING,
      res
    ),
  getCompanyForConglomerateIncrementingFailure: (
    error: string
  ): GetCompanyForConglomerateIncrementingFailureAction =>
    createAction(
      ConglomerateActionKeys.GET_COMPANY_FOR_CONGLOMERATE_FAILURE_INCREMENTING,
      error
    ),

  putConglomerate: (
    body: IPutConglomerateRequest
  ): PutConglomerateRequestAction =>
    createAction(ConglomerateActionKeys.PUT_CONGLOMERATE_REQUEST, body),
  putConglomerateFailure: (error: string): PutConglomerateFailureAction =>
    createAction(ConglomerateActionKeys.PUT_CONGLOMERATE_FAILURE, error),

  deleteConglomerate: (
    body: IDeteleConglomerateRequest
  ): DeleteConglomerateRequestAction =>
    createAction(ConglomerateActionKeys.DELETE_CONGLOMERATE_REQUEST, body),
  deleteConglomerateFailure: (error: string): DeleteConglomerateFailureAction =>
    createAction(ConglomerateActionKeys.DELETE_CONGLOMERATE_FAILURE, error),

  setConfirmStatusModalVisibility: (value: {
    visible: boolean;
    value: any;
  }): SetConfirmStatusModalVisibilityAction =>
    createAction(
      ConglomerateActionKeys.SET_CONFIRM_STATUS_MODAL_VISIBILITY,
      value
    ),
  patchUserStatus: (
    body: IPatchConglomerateStatusRequest
  ): PatchConglomerateStatusRequestAction =>
    createAction(ConglomerateActionKeys.PATCH_STATUS_REQUEST, body),
  patchUserStatusSuccess: (
    res: IPatchConglomerateStatusRequest
  ): PatchConglomerateStatusSuccessAction =>
    createAction(ConglomerateActionKeys.PATCH_STATUS_SUCCESS, res),
};

export type ConglomerateActionUnion = ActionsUnion<typeof ConglomerateActions>;

export type SetLoadingAction = Action<
  ConglomerateActionKeys.SET_LOADING,
  boolean
>;

export type ResetConglomerateAction =
  Action<ConglomerateActionKeys.RESET_CONGLOMERATE>;

export type ResetConglomerateIncrementingAction =
  Action<ConglomerateActionKeys.RESET_CONGLOMERATE_INCREMENTING>;

export type ParamsConglomerateIdAction = Action<
  ConglomerateActionKeys.PARAMS_CONGLOMERATE_ID,
  string
>;

export type SetConfirmStatusModalVisibilityAction = Action<
  ConglomerateActionKeys.SET_CONFIRM_STATUS_MODAL_VISIBILITY,
  {
    visible: boolean;
    value: any;
  }
>;

export type ListConglomerateRequestAction = Action<
  ConglomerateActionKeys.LIST_CONGLOMERATE_REQUEST,
  IQueryParams
>;
export type ListConglomerateSuccessAction = Action<
  ConglomerateActionKeys.LIST_CONGLOMERATE_SUCCESS,
  IListConglomerateResponse
>;
export type ListConglomerateFailureAction = Action<
  ConglomerateActionKeys.LIST_CONGLOMERATE_FAILURE,
  string
>;

export type ListAllConglomerateRequestAction = Action<
  ConglomerateActionKeys.ALL_LIST_CONGLOMERATE_REQUEST,
  IQueryParams
>;
export type ListAllConglomerateSuccessAction = Action<
  ConglomerateActionKeys.ALL_LIST_CONGLOMERATE_SUCCESS,
  IListConglomerateResponse
>;
export type ListAllConglomerateFailureAction = Action<
  ConglomerateActionKeys.ALL_LIST_CONGLOMERATE_FAILURE,
  string
>;

export type GetConglomerateRequestAction = Action<
  ConglomerateActionKeys.GET_CONGLOMERATE_REQUEST,
  string
>;
export type GetConglomerateSuccessAction = Action<
  ConglomerateActionKeys.GET_CONGLOMERATE_SUCCESS,
  IGetConglomerateResponse
>;
export type GetConglomerateFailureAction = Action<
  ConglomerateActionKeys.GET_CONGLOMERATE_FAILURE,
  string
>;

export type GetCompanyForConglomerateRequestAction = Action<
  ConglomerateActionKeys.GET_COMPANY_FOR_CONGLOMERATE_REQUEST,
  IQueryParams
>;
export type GetCompanyForConglomerateSuccessAction = Action<
  ConglomerateActionKeys.GET_COMPANY_FOR_CONGLOMERATE_SUCCESS,
  IGetCompanyForConglomerateResponse
>;
export type GetCompanyForConglomerateFailureAction = Action<
  ConglomerateActionKeys.GET_COMPANY_FOR_CONGLOMERATE_FAILURE,
  string
>;

export type GetCompanyForConglomerateIncrementingRequestAction = Action<
  ConglomerateActionKeys.GET_COMPANY_FOR_CONGLOMERATE_REQUEST_INCREMENTING,
  IQueryParams
>;
export type GetCompanyForConglomerateIncrementingSuccessAction = Action<
  ConglomerateActionKeys.GET_COMPANY_FOR_CONGLOMERATE_SUCCESS_INCREMENTING,
  IGetCompanyForConglomerateResponse
>;
export type GetCompanyForConglomerateIncrementingFailureAction = Action<
  ConglomerateActionKeys.GET_COMPANY_FOR_CONGLOMERATE_FAILURE_INCREMENTING,
  string
>;

export type PutConglomerateRequestAction = Action<
  ConglomerateActionKeys.PUT_CONGLOMERATE_REQUEST,
  IPutConglomerateRequest
>;
export type PutConglomerateFailureAction = Action<
  ConglomerateActionKeys.PUT_CONGLOMERATE_FAILURE,
  string
>;

export type DeleteConglomerateRequestAction = Action<
  ConglomerateActionKeys.DELETE_CONGLOMERATE_REQUEST,
  IDeteleConglomerateRequest
>;
export type DeleteConglomerateFailureAction = Action<
  ConglomerateActionKeys.DELETE_CONGLOMERATE_FAILURE,
  string
>;

export type PatchConglomerateStatusRequestAction = Action<
  ConglomerateActionKeys.PATCH_STATUS_REQUEST,
  IPatchConglomerateStatusRequest
>;
export type PatchConglomerateStatusSuccessAction = Action<
  ConglomerateActionKeys.PATCH_STATUS_SUCCESS,
  IPatchConglomerateStatusRequest
>;
