import { IProductResponse } from '../../../../../../../Data/Interfaces/response/Product/IProductRespose';

export const DetailsInitialValues = (product?: IProductResponse | null) =>
  product && {
    externalId: product.externalId,
    descricao: product.descricao,
    descricaoRotulo: product.descricaoRotulo,
    classeProdutoId: product.classeProdutoId,
    subGrupoExternalId: product.subGrupoExternalId,
    grupoExternalId: product.grupoExternalId,
    unidadeEstoqueId: product.unidadeEstoqueId,
    fornecedorExternalId: product.fornecedorExternalId,
    controlaLote: product.controlaLote,
    usoContinuo: product.usoContinuo,
    etiqueta: product.etiqueta,
    controlaQualidade: product.controlaQualidade,
  };
