import React, { FC } from 'react';
import { Avatar } from '../Avatar';

import styles from './ManyAvatars.module.scss';

interface IUsersName {
  name: string;
  lastName?: string;
}

interface IManyAvatars {
  users: IUsersName[];
}

export const ManyAvatars: FC<IManyAvatars> = ({ users }) => (
  <div
    className={
      users.length > 1
        ? styles['manyAvatarContainer']
        : styles['oneAvatarContainer']
    }
  >
    {users.length > 1 ? (
      <>
        {users.map((user: IUsersName, index: any) => (
          <Avatar
            fisrtName={user.name}
            lastName={user.lastName}
            key={index}
            size="M"
            type="paragraph2-bold"
            color="white"
            style={{ zIndex: users.length - index }}
          />
        ))}
        {users?.length > 5 ? (
          <Avatar
            fisrtName={`+${users.length - 5}`}
            size="M"
            type="paragraph2-bold"
            color="text-300"
            backgroundColor="text-700"
          />
        ) : null}
      </>
    ) : (
      users && (
        <Avatar
          fisrtName={users[0].name}
          lastName={users[0].lastName}
          size="L"
          type="ui-main-bold"
        />
      )
    )}
  </div>
);
