import React, { FC } from 'react';
import { Input as AntdInput } from 'antd';
import './TextArea.override.scss';
import { Field } from 'formik';
import { IField } from '../interfaces/IField';

import { LocalizaPropriedadePorPath } from '../../../Utils/FormikUtils';
import { InputLabel } from '../Label';
import styles from './TextArea.module.scss';

const AntdTextArea = AntdInput.TextArea;

export interface ITextArea {
  name: string;
  label: string;
  maxLength: number;
  heightTextArea?: number;
  rows?: number;
  placeholder?: string;
  disable?: boolean;
  autoSize?: boolean | { minRows: number; maxRows: number };
  visibleCount?: boolean;
  value?: string;
  onChange?: (value: string) => void;
  withStatusIcon?: boolean;
  required?: boolean;
}

export const TextArea: FC<ITextArea> = ({
  name,
  label,
  maxLength,
  heightTextArea,
  disable,
  rows,
  placeholder,
  autoSize,
  visibleCount,
  value,
  onChange,
  withStatusIcon,
  required,
}) => {
  return (
    <Field type="text" name={name}>
      {(props: IField) => {
        const onChangeFunc: React.ChangeEventHandler<HTMLTextAreaElement> = (
          e
        ) => {
          const value = e.target.value;
          props.form.setFieldValue(name, value);

          if (onChange) onChange(value);
        };

        return (
          <div className={styles['divTextArea']}>
            <div className={styles['divHeaderTextArea']}>
              <InputLabel
                label={label}
                required={required}
                withStatusIcon={withStatusIcon}
                props={props}
              />
              {visibleCount && <div className={styles['border']} />}
              <AntdTextArea
                {...props.field}
                value={value || props.field.value}
                onChange={onChangeFunc}
                className={styles['textArea']}
                showCount={visibleCount}
                maxLength={maxLength}
                rows={rows}
                disabled={props.form.isSubmitting || disable}
                placeholder={placeholder}
                autoSize={autoSize}
                style={{ height: heightTextArea }}
              />

              {props.form &&
              props.form.errors &&
              LocalizaPropriedadePorPath(props.form.errors, name) ? (
                <p className={styles['errorText']}>
                  {LocalizaPropriedadePorPath(props.form.errors, name)}
                </p>
              ) : null}
            </div>
          </div>
        );
      }}
    </Field>
  );
};
