import React, { FC, ReactNode } from 'react';

import { Tooltip as AntdTooltip } from 'antd';

import './Tooltip.override.scss';

export interface ITooltip {
  title: ReactNode;
  placement?: 'topRight' | 'topLeft' | 'bottomLeft' | 'bottomRight';
  children: ReactNode;
  showMe?: boolean;
  copied?: boolean;
}

export const Tooltip: FC<ITooltip> = ({
  title,
  children,
  placement,
  showMe,
  copied,
}) => {
  return showMe ? (
    <AntdTooltip
      placement={placement}
      title={title}
      overlayClassName={copied ? 'tooltip-is-copied' : ''}
    >
      <div>{children}</div>
    </AntdTooltip>
  ) : (
    <>{children}</>
  );
};
