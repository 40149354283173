import { useAppProps } from 'antd/es/app/context';
import { FC } from 'react';

import { Text } from '../Components';
import { CannotDeleteModal as SupplyGroupsCannotDelete } from '../Pages/Products/Groups/List/Modal/CannotDeleteModal';
import { CannotDeleteModal as SupplyStorageLocationCannotDelete } from '../Pages/Supply/SupplyPlaces/List/Modal/CannotDeleteModal';
import { CannotDeleteModal as SupplyProductCannotDelete } from '../Pages/Products/Product/List/Modal/CannotDeleteModal';
import { CannotDeleteModal as SupplySubGroupsCannotDelete } from '../Pages/Products/SubGroups/List/Modal/CannotDeleteModal';
import { CannotDeleteModal as SupplyLotsCannotDelete } from '../Pages/Supply/Lot/List/Modal/CannotDeleteModal/deleteModal';
import { CreateLotErrorToast } from '../Pages/Supply/Lot/CreateForm/Modal/CannotCreateToast/errorCreateToast';
import { useTranslation } from 'react-i18next';

const DefaultErrorToast: FC<ICommonError> = ({ errorCode }) => {
  const { t } = useTranslation();
  return (
    <div style={{ marginLeft: 30, display: 'flex', flexDirection: 'column' }}>
      <Text type="ui-tiny-bold">
        {t('toast.default.title')}
        <span
          style={{
            fontWeight: 'var(--font-weight-bold)',
            fontSize: 'var(--font-size-xs)',
            lineHeight: 'var(--font-line-height-xs)',
            color: 'var(--color-status-danger-base)',
          }}
        >
          {` ${errorCode}`}
        </span>
        .
      </Text>
      <Text type="small-text" color="text-100">
        {t('toast.default.description')}
      </Text>
    </div>
  );
};

export interface IGlobalModal {
  destroy: () => void;
  update: (configUpdate: any) => void;
}

export interface ICommonError {
  errorCode: string;
  content: any[];
  errors: any;
  title: string;
  status: number;
  detail: string;
  instance: string;
}

export const handleModalError =
  (app: useAppProps) => (err: unknown, params: any) => {
    const error = err as ICommonError;
    if (error.status !== 400) return;

    switch (error.errorCode) {
      case 'EstoqueErrorCode-1':
        const modal = app.modal.info({});
        modal.update(SupplyGroupsCannotDelete(error, params, modal));
        break;

      case 'EstoqueErrorCode-2':
        const modal2 = app.modal.info({});
        modal2.update(SupplySubGroupsCannotDelete(error, params, modal2));
        break;

      case 'EstoqueErrorCode-3':
        const modal3 = app.modal.info({});
        modal3.update(SupplyStorageLocationCannotDelete(error, params, modal3));
        break;

      case 'EstoqueErrorCode-4':
        const modal4 = app.modal.info({});
        modal4.update(SupplyProductCannotDelete(error, params, modal4));
        break;

      case 'EstoqueErrorCode-5':
        const modal5 = app.modal.info({});
        modal5.update(SupplyLotsCannotDelete(error, params, modal5));
        break;

      case 'EstoqueErrorCode-6':
        app.notification.error({
          duration: 10,
          message: <CreateLotErrorToast {...error} />,
          placement: 'bottomRight',
          key: error.errorCode,
        });
        break;

      default:
        app.notification.error({
          duration: 10,
          message: <DefaultErrorToast {...error} />,
          placement: 'bottomRight',
        });
        break;
    }
  };

export const emailIsValid = (email: string) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};
