import { FC, ReactNode, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IModal, Modal } from '../../../../Modal';
import { Dropdown } from '../../../../StateInputs/Dropdown';
import { editOptions } from '../../..';
import { statusTypes } from '../../../../../Utils/StatusUtils';

import styles from './EditModal.module.scss';

export interface IEditModalTranslations {
  title?: ITitleTranslations;
  customEndTitle?: string;
}

export interface ITitleTranslations {
  singleItemBefore?: string;

  manyItemsBefore?: string;
  manyItemsAfter?: string;
}

export interface IConfirmEdit
  extends Omit<IModal, 'visible' | 'onOkClick' | 'onClose' | 'onCancelClick'> {}

export interface IEditModal {
  visible: boolean;
  itemNames: string[][];

  items?: editOptions;

  body?: ReactNode;
  title?: string;

  translations?: IEditModalTranslations;
  confirmationModal?: IConfirmEdit;

  onOkClick: (item: { id: any; name: string; status?: statusTypes }) => void;
  onCancelClick: () => void;
  status?: boolean;
  statusSelectedOnRow?: string;

  okButtonName?: string;

  withForm?: boolean;

  loading?: boolean;
}

export const EditStatusModal: FC<IEditModal> = ({
  visible,
  itemNames,
  translations,
  items,
  confirmationModal,
  onOkClick,
  onCancelClick,
  body,
  title,
  status,
  statusSelectedOnRow,
  loading,
  okButtonName,
  withForm,
}) => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const { t } = useTranslation();
  const [selected, setSelected] = useState<any>();
  const isSingleItem = itemNames.length === 1;

  const onOk = useCallback(() => {
    onOkClick(selected);
    setShowConfirmation(false);
  }, [onOkClick, selected]);

  const onCancel = useCallback(() => {
    onCancelClick();
    setShowConfirmation(false);
    setSelected(undefined);
  }, [onCancelClick]);

  useEffect(() => {
    status &&
      statusSelectedOnRow !== undefined &&
      setSelected({ id: statusSelectedOnRow });
  }, [statusSelectedOnRow, status]);

  return (
    <>
      <Modal
        visible={visible || loading}
        loading={loading}
        disabledButton={
          isSingleItem && !body && selected?.id === statusSelectedOnRow
        }
        okButtonName={okButtonName ? okButtonName : 'modal.edit'}
        onClose={onCancel}
        onOkClick={() => {
          if (confirmationModal && selected?.id === 2 && status) {
            setShowConfirmation(true);
          } else {
            onOk();
          }
        }}
        onCancelClick={onCancel}
        title={
          title
            ? title
            : isSingleItem
            ? `${
                translations?.title?.singleItemBefore
                  ? t(translations?.title?.singleItemBefore)
                  : t('modal.editModal.single.title.before')
              } ”${itemNames[0][0]}”`
            : `${
                translations?.title?.manyItemsBefore
                  ? t(translations?.title?.manyItemsBefore)
                  : t('modal.editModal.many.title.before')
              } ${itemNames.length} ${
                translations?.customEndTitle
                  ? translations.customEndTitle
                  : translations?.title?.manyItemsAfter
                  ? t(translations?.title?.manyItemsAfter)
                  : t('modal.editModal.many.title.after')
              }`
        }
        body={
          body ? (
            body
          ) : (
            <div className={styles['EditBody']}>
              <Dropdown
                value={selected?.id}
                label={{
                  typeLabel: 'ui-tiny-semibold',
                  colorLabel: 'text-50',
                  children: 'Status',
                }}
                items={items?.map((x) => ({
                  id: x.id,
                  name: x.name,
                  status: x.status,
                }))}
                placeholder={t('modal.editModal.statusPlaceHolder')}
                onChange={(value) =>
                  setSelected(items?.find((x) => x.id === value))
                }
              />
            </div>
          )
        }
      />
      {confirmationModal && (
        <Modal
          visible={showConfirmation}
          onOkClick={onOk}
          onClose={onCancel}
          onCancelClick={onCancel}
          {...confirmationModal}
        />
      )}
    </>
  );
};
