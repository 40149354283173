import React from 'react';
import { PermissionWrapper } from '../../../../../../PermissionWrapper';
import { Skeleton } from '../../../../../../Skeleton';
import { Status } from '../../../Status';
import { useGetUserGroupType } from '../../../../../../../Hooks/Accountmanager/UserGroupHooks';
import i18n from '../../../../../../i18n';

export const DynamicEditUserGroupStatusBreadcrumb = ({ match }: any) => {
  const { data: userGroup } = useGetUserGroupType(match.params.externalId);

  if (!userGroup) {
    return (
      <div style={{ width: 200 }}>
        <Skeleton />
      </div>
    );
  }

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {`${i18n.t('common.userGroup')} | ${userGroup?.nome}`}
      <PermissionWrapper
        permission={1013}
        tooltip="noPermission.administrative.userGroups.statusEdit"
      >
        <Status
          type={userGroup?.seAtivo ? 'success' : 'disabled'}
          ative={userGroup?.seAtivo || false}
        />
      </PermissionWrapper>
    </div>
  );
};

export const DynamicEditUserGroupNameBreadcrumb = ({ match }: any) => {
  const { data: userGroup } = useGetUserGroupType(match.params.externalId);
  if (!userGroup) {
    return (
      <div style={{ width: 100 }}>
        <Skeleton />
      </div>
    );
  }
  return <span>{userGroup?.nome}</span>;
};
