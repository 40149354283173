import { useMutation, useQuery } from '@tanstack/react-query';
import { IPagination } from '../../Components/Table';
import { StorageLocationApi } from '../../Data/API/Supply/StorageLocationApi';
import { IBasicMutationOptions } from '../interfaces/IBasicOptions';

export const FETCH_LIST_STOCK_LOCATION =
  '[SUPPLY_STOCK_LOCATION] FETCH_LIST_STOCK_LOCATION';
export const FECTH_LIST_STOCK_LOCATION_FOR_DROPDOWN_TYPE =
  '[SUPPLY_STOCK_LOCATION] FECTH_LIST_STOCK_LOCATION_FOR_DROPDOWN_TYPE';
export const CREATE_STOCK_LOCATION =
  '[SUPPLY_STOCK_LOCATION] CREATE_STOCK_LOCATION';
export const DELETE_STOCK_LOCATION =
  '[SUPPLY_STOCK_LOCATION] DELETE_STOCK_LOCATION';
export const FETCH_GET_STOCK_LOCATION =
  '[SUPPLY_STOCK_LOCATION] FETCH_GET_STOCK_LOCATION';
export const EDIT_STOCK_LOCATION =
  '[SUPPLY_STOCK_LOCATION] EDIT_STOCK_LOCATION';
export const EDIT_STATUS_STOCK_LOCATION =
  '[SUPPLY_STOCK_LOCATION] EDIT_STATUS_STOCK_LOCATION';
export const THERE_IS_STOCK_LOCATION_TYPE =
  '[SUPPLY_STOCK_LOCATION] THERE_IS_STOCK_LOCATION_TYPE';
export const FETCH_GET_INVENTORY_BY_STOCK_LOCATION =
  '[SUPPLY_STOCK_LOCATION] FETCH_GET_INVENTORY_BY_STOCK_LOCATION';

export const useStorageLocationGetList = (pagination: IPagination) =>
  useQuery(
    [FETCH_LIST_STOCK_LOCATION, pagination],
    StorageLocationApi.getStorageLocationList,
    {
      staleTime: 60000,
    }
  );

export const useStockLocationtGetDropdown = (pagination: IPagination) =>
  useQuery(
    [FECTH_LIST_STOCK_LOCATION_FOR_DROPDOWN_TYPE, pagination],
    StorageLocationApi.getListStorageLocationDropDown
  );

export const useStockLocationCreate = () =>
  useMutation(
    [CREATE_STOCK_LOCATION],
    StorageLocationApi.createStorageLocation
  );

export const useStockLocationDelete = () =>
  useMutation(
    [DELETE_STOCK_LOCATION],
    StorageLocationApi.deleteStorageLocation
  );

export const useStockLocationGet = (externalId: string) =>
  useQuery(
    [FETCH_GET_STOCK_LOCATION, externalId],
    StorageLocationApi.getCurrentStorageLocation
  );

export const useStockLocationEdit = () =>
  useMutation([EDIT_STOCK_LOCATION], StorageLocationApi.editStorageLocation);

export const useStockLocationEditStatus = () =>
  useMutation(
    [EDIT_STATUS_STOCK_LOCATION],
    StorageLocationApi.patchStorageLocationStatus
  );

export const useThereIsStockLocationNameType = (
  options?: IBasicMutationOptions
) =>
  useMutation(
    [THERE_IS_STOCK_LOCATION_TYPE],
    StorageLocationApi.isStorageLocationNameAlreadyRegistred,
    options
  );

export const useInventoryByStockLocationList = (
  pagination: IPagination,
  localEstoqueExternalId: string
) =>
  useQuery(
    [FETCH_GET_INVENTORY_BY_STOCK_LOCATION, pagination, localEstoqueExternalId],
    StorageLocationApi.getInventoryByStockLocationList,
    { staleTime: 60000 }
  );
