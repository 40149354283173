import { IGlobalReducerState } from '../Base/Interfaces/IGlobalReducerState';

export const USER_PREFERENCES_CACHE = 'CURRENT_PREFERENCES';

export const getMeUser = (state: IGlobalReducerState) => state.user.currentUser;

export const getUser = (state: IGlobalReducerState) =>
  state.user.currentGetUser;

export const userLoading = (state: IGlobalReducerState) => state.user.isLoading;

export const getUsersList = (state: IGlobalReducerState) =>
  state.user.currentListUser;

export const getUsersListIncrementing = (state: IGlobalReducerState) =>
  state.user.currentListUserIncrementing;

export const getUsersLoading = (state: IGlobalReducerState) =>
  state.user.isLoading;

export const getContextId = (state: IGlobalReducerState) =>
  state.user.contextName || state.user.currentUser?.modulosSistema[0];

export const getUsers = (state: IGlobalReducerState) => state.user.currentUser;

export const getUsersPreferences = (state: IGlobalReducerState) =>
  state.user.currentUser?.preferencias;

export const getModulosSistema = (state: IGlobalReducerState) =>
  state.user.currentUser?.modulosSistema;

export const getShowPermissionDependencyModal = (state: IGlobalReducerState) =>
  state.user.showPermissionDependencyModal;

export const getPermissionDependencyModalData = (state: IGlobalReducerState) =>
  state.user.permissionDependencyModalData;
