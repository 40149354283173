import React, { FC } from 'react';
import { Divflex } from '../../../../../Layouts/DivWhithFlex';
import { Text } from '../../../../../Components';
import { useTranslation } from 'react-i18next';
import { FooterThisActionIsPermanent } from '../../../../../Components/Table/components/Modals/DeleteModal';

interface IDeleteModal {
  selectedRowsOnTable: any;
}

export const DeleteModal: FC<IDeleteModal> = ({ selectedRowsOnTable }) => {
  const { t } = useTranslation();
  return (
    <div style={{ padding: '20px' }}>
      <Divflex>
        <Text
          color="text-50"
          type="ui-tiny-content"
          children={`${t('modal.deleteModal.single.question')} ${t(
            'common.theProduct'
          )} `}
        />
        <Text
          color="text-50"
          type="ui-tiny-bold"
          children={`${selectedRowsOnTable[0]?.descricao}`}
          style={{ marginBottom: '15px' }}
        />
        <Text type="ui-tiny-content" children={'?'} />
      </Divflex>
      <FooterThisActionIsPermanent />
    </div>
  );
};
