import { IPagination, IQueryParams } from '../../Components/Table';
import { createAction, Action, ActionsUnion } from '../Base/actionHelpers';
import { ICountryList } from '../../Data/Interfaces/response/Country/ICountryResponse';

export enum CountryActionKeys {
  SET_LOADING = '[COUNTRY] SET_LOADING',

  COUNTRY_LIST_REQUEST = '[COUNTRY] COUNTRY_LIST_REQUEST',
  COUNTRY_LIST_ACCOUNTMANAGER_REQUEST = '[COUNTRY] COUNTRY_LIST_ACCOUNTMANAGER_REQUEST',
  COUNTRY_LIST_SUCCESS = '[COUNTRY] COUNTRY_LIST_SUCCESS',
  COUNTRY_LIST_FALUIRE = '[COUNTRY] COUNTRY_LIST_FALUIRE',
}

export const CountryActions = {
  setLoading: (loading: boolean): SetLoadingAction =>
    createAction(CountryActionKeys.SET_LOADING, loading),

  listCountry: (query: IQueryParams): ListCountryAction =>
    createAction(CountryActionKeys.COUNTRY_LIST_REQUEST, query),
  listCountryAccountmanager: (
    body: IPagination
  ): listCountryAccountmanagerAction =>
    createAction(CountryActionKeys.COUNTRY_LIST_ACCOUNTMANAGER_REQUEST, body),
  listAllCountrySuccess: (res: ICountryList): ListCountrySuccessAction =>
    createAction(CountryActionKeys.COUNTRY_LIST_SUCCESS, res),
  listAllCountryFailure: (error: string): ListCountryFaluireAction =>
    createAction(CountryActionKeys.COUNTRY_LIST_FALUIRE, error),
};

export type CountryActionUnion = ActionsUnion<typeof CountryActions>;

export type SetLoadingAction = Action<CountryActionKeys.SET_LOADING, boolean>;

export type ListCountryAction = Action<
  CountryActionKeys.COUNTRY_LIST_REQUEST,
  IQueryParams
>;

export type listCountryAccountmanagerAction = Action<
  CountryActionKeys.COUNTRY_LIST_ACCOUNTMANAGER_REQUEST,
  IPagination
>;

export type ListCountrySuccessAction = Action<
  CountryActionKeys.COUNTRY_LIST_SUCCESS,
  ICountryList
>;

export type ListCountryFaluireAction = Action<
  CountryActionKeys.COUNTRY_LIST_FALUIRE,
  string
>;
