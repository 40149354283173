import React, { FC, useState } from 'react';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { Button, Card, Form, TextInput } from '../../../../Components';
import { InputWithSearch } from '../../../../Components/Inputs/InputWithSearch';
import { CreateSchema } from './CreateForm.schema';
import { ICreateUserRequest } from '../../../../Data/Interfaces/request/User/IUserRequest';
import { history } from '../../../../Store';
import { TextInputEmailAlreadyExists } from '../../../../Components/Inputs/TextInputEmailAlreadyExists';
import { useGetUserListFromUserGroupType } from '../../../../Hooks/Accountmanager/UserGroupHooks';
import { IQueryListParams } from '../../../../Utils/Http';
import { useCreateUserMutation } from '../../../../Hooks/Accountmanager/UserHooks';

import styles from './CreateForm.module.scss';

export const UserForm: FC = () => {
  const [pagination, setPagination] = useState<IQueryListParams>({
    PageIndex: 0,
    PageSize: 10,
  });

  const { data: userGroups, isLoading: userGroupsLoading } =
    useGetUserListFromUserGroupType(pagination);
  const createUser = useCreateUserMutation();

  const { t } = useTranslation();

  const onSubmit = async (values: ICreateUserRequest) => {
    var body: ICreateUserRequest = { ...values };

    await createUser.mutateAsync(body);
  };

  return (
    <Form
      onSubmit={onSubmit}
      schema={CreateSchema}
      initialValues={{ ativo: true }}
    >
      {() => (
        <>
          <Card title={t('admin.createUserPage.personalData')} withoutCollapse>
            <Row gutter={[16, 0]} justify="space-between">
              <Col span={12}>
                <TextInput
                  name="nome"
                  label={t('admin.createUserPage.personalDataCard.name')}
                  placeHolder={t(
                    'admin.createUserPage.personalDataCard.namePlaceholder'
                  )}
                  required
                  withoutMarginBottom
                />
              </Col>
              <Col span={12}>
                <TextInput
                  name="sobrenome"
                  label={t('admin.createUserPage.personalDataCard.lastName')}
                  placeHolder={t(
                    'admin.createUserPage.personalDataCard.lastNamePlaceholder'
                  )}
                  required
                  withoutMarginBottom
                />
              </Col>
            </Row>
          </Card>
          <Card title={t('admin.createUserPage.Access')} withoutCollapse>
            <Row gutter={[16, 0]}>
              <Col span={12}>
                <TextInputEmailAlreadyExists
                  name="email"
                  label={t('admin.createUserPage.AccessDataCard.email')}
                  placeholder={t(
                    'admin.createUserPage.AccessDataCard.emailPlaceholder'
                  )}
                  required
                />
              </Col>
              <Col span={12}>
                <TextInput
                  name="emailAlternativo"
                  label={t(
                    'admin.createUserPage.AccessDataCard.alternativeEmail'
                  )}
                  placeHolder={t(
                    'admin.createUserPage.AccessDataCard.alternativeEmailPlaceholder'
                  )}
                />
              </Col>
            </Row>
            <Row gutter={[16, 0]}>
              <Col span={24}>
                <InputWithSearch
                  name="grupos"
                  withoutMarginBottom
                  label={t('admin.createUserPage.AccessDataCard.group')}
                  placeHolder={t(
                    'admin.createUserPage.AccessDataCard.groupPlaceholder'
                  )}
                  onTag
                  typeTag="link-secondary"
                  items={userGroups?.data.map((x) => ({
                    id: x.externalId,
                    label: x.nome,
                  }))}
                  required
                  onSearch={(value) =>
                    setPagination({ PageIndex: 0, PageSize: 10, Search: value })
                  }
                  onScrollEnd={() =>
                    setPagination({
                      ...pagination,
                      PageIndex: pagination.PageIndex++,
                    })
                  }
                />
              </Col>
            </Row>
          </Card>
          <div className={styles['footer-button']}>
            <Button
              type="secondary"
              children={t('admin.createUserPage.footerButton.cancel')}
              onClick={() => history.push('/admin/user')}
            />
            <Button
              type="primary"
              children={t('admin.createUserPage.footerButton.submit')}
              htmlType="submit"
              loading={userGroupsLoading}
            />
          </div>
        </>
      )}
    </Form>
  );
};
