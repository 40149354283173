import React, { FC } from 'react';
import { Color, Colors } from '../../Utils/ColorUtils';
import styles from './Icon.module.scss';
import { iconClasses, IconName } from './IconClasses';

export interface IIconProps {
  icon?: IconName;
  className?: string;
  backgroundColor?: Color;
  color?: Color;
  size?: 'SS' | 'SG' | 'S' | 'SM' | 'M' | 'L' | 'XXL' | 'XXX';
  customSize?: number;
  style?: React.CSSProperties;
  customColor?: string;
  id?: string;
  onClick?: (value: any) => void;

  onMouseLeave?: () => void;
}

export const Icon: FC<IIconProps> = ({
  icon,
  size,
  color,
  backgroundColor,
  customSize,
  style,
  className,
  customColor,
  id,
  onClick,
  onMouseLeave,
}) => {
  const currentSize = styles[size || 'xs'];
  return (
    <i
      id={id}
      data-testid="icon"
      onClick={onClick}
      style={{
        fontSize: customSize ? `${customSize}px` : undefined,
        backgroundColor: Colors[backgroundColor || 'transparent'],
        color: customColor ? customColor : Colors[color || 'black'],
        ...style,
      }}
      className={`icon ${icon && iconClasses[icon]} ${
        styles['Icon']
      } ${currentSize} ${className}`}
      onMouseLeave={onMouseLeave}
    />
  );
};
