import React, { CSSProperties, FC, ReactNode } from 'react';
import { Color, Colors } from '../../Utils/ColorUtils';

import styles from './Text.module.scss';
import { Icon } from '../Icon';

export type TextType =
  | 'heading1'
  | 'heading2'
  | 'heading3-bold'
  | 'heading3'
  | 'heading4'
  | 'heading5'
  | 'heading6'
  | 'ui-main-content'
  | 'ui-main-bold'
  | 'ui-sub-content'
  | 'ui-sub-bold'
  | 'paragraph'
  | 'paragraph2'
  | 'paragraph-bold'
  | 'paragraph2-bold'
  | 'ui-tiny-content'
  | 'ui-tiny-semibold'
  | 'ui-tiny-bold'
  | 'link'
  | 'small-text'
  | 'small-text-bold';

export interface IText {
  type: TextType;
  children?: ReactNode;
  color?: Color;
  style?: CSSProperties;
  className?: string;

  onClick?: () => void;

  width?: number;
  favoriteIcon?: boolean;
}

export const Text: FC<IText> = ({
  type,
  style,
  color,
  children,
  className,
  onClick,
  width,
  favoriteIcon,
}) => (
  <div
    style={
      color
        ? { color: Colors[color], width: width, ...style }
        : { width: width, ...style }
    }
    className={`${styles['Text']} ${styles[type]} ${className}`}
    onClick={onClick}
  >
    {children}
    {favoriteIcon && (
      <Icon
        icon="favorite"
        style={{ marginLeft: '5px' }}
        color="users09"
        size="S"
      />
    )}
  </div>
);
