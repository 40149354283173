import React, { useState } from 'react';
import { PopOver } from '../../../../PopOver';
import { Status as ComponentStatus } from '../../../..';
import { Text } from '../../../../Text';

import styles from './Status.module.scss';
import { useTranslation } from 'react-i18next';
import i18n from '../../../../i18n';
import { statusTypes } from '../../../../../Utils/StatusUtils';
import { IListStatusLotResponse } from '../../../../../Data/Interfaces/response/Lot/IListLot';
import { lotStatusTypes } from '../../../../../Pages/Supply/Lot/List/lot.columns';
import { useLotGetStatusList } from '../../../../../Hooks/Supply/LotHooks';

const Statustitle = () => {
  return (
    <div className={styles['divTitle']}>
      <Text
        className={styles['title']}
        type="small-text"
        color="text-400"
        children={
          i18n.t('breadCrumb.adminBootysRoute.status.changeStatus') as string
        }
      />
    </div>
  );
};

const Statuscontent = (
  status: (value: boolean) => void,
  visible: (isVisible: boolean) => void
) => {
  return (
    <div className={styles['divContent']}>
      <div
        className={styles['status']}
        onClick={() => {
          status(true);
          visible(false);
        }}
      >
        <ComponentStatus
          darkmode={false}
          type="success"
          children={i18n.t('breadCrumb.adminBootysRoute.status.ative')}
        />
      </div>
      <div
        className={styles['status']}
        onClick={() => {
          status(false);
          visible(false);
        }}
      >
        <ComponentStatus
          darkmode={false}
          type="disabled"
          children={i18n.t('breadCrumb.adminBootysRoute.status.inative')}
        />
      </div>
    </div>
  );
};

const StatuscontentSituation = (
  status: (value: number) => void,
  visible: (isVisible: boolean) => void
) => {
  const { data: situationLot } = useLotGetStatusList();

  return (
    <div className={styles['divContentSituation']}>
      {situationLot &&
        lotStatusTypes.map((x, index) => (
          <div
            className={styles['status']}
            onClick={() => {
              status(index + 1);
              visible(false);
            }}
          >
            <ComponentStatus
              darkmode={false}
              type={x}
              children={situationLot[index].descricao}
            />
          </div>
        ))}
    </div>
  );
};

interface IStatus {
  type: statusTypes;
  onClick?: (value: any) => void;
  disabled?: boolean;
  ative?: boolean;
  situation?: IListStatusLotResponse;
  withoutDropdown?: boolean;

  loading?: boolean;
}

export const Status: React.FC<IStatus> = ({
  type,
  onClick,
  disabled,
  ative,
  situation,
  withoutDropdown,
  loading,
}) => {
  const [visible, setVisible] = useState<boolean>(false);

  const { t } = useTranslation();

  const StatusContentVerification = () =>
    situation?.id
      ? situation.descricao
      : ative
      ? t('breadCrumb.adminBootysRoute.status.ative')
      : t('breadCrumb.adminBootysRoute.status.inative');

  const StatusType = () =>
    onClick &&
    (situation?.id
      ? StatuscontentSituation(
          (value) => onClick(value),
          () => setVisible(!visible)
        )
      : Statuscontent(
          (value) => onClick(value),
          () => setVisible(!visible)
        ));

  return withoutDropdown ? (
    <ComponentStatus type={type} darkmode={false} disabled={disabled}>
      {StatusContentVerification()}
    </ComponentStatus>
  ) : (
    <div className={styles['content']}>
      <PopOver
        content={StatusType()}
        placement="bottomLeft"
        title={Statustitle}
        trigger="click"
        visible={visible}
        onOpenChange={() => !disabled && setVisible(!visible)}
      >
        <ComponentStatus
          type={type}
          darkmode={false}
          rightIcon={true}
          disabled={disabled}
          loading={loading}
        >
          {StatusContentVerification()}
        </ComponentStatus>
      </PopOver>
    </div>
  );
};
