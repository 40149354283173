export const ExactLink = (link: string) => {
  if (link.match(/(\/admin\/user\/\d+)/i)) return 'details';

  switch (link) {
    case '/user-preference':
      return '/user-preference/place-and-time';

    case '/account-manager':
      return '/account-manager/account-details';

    case '/system-configurations':
      return '/system-configurations/personalization';

    default:
      return link;
  }
};
