import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Avatar } from '../../../../../../Components/Avatar';
import { Text } from '../../../../../../Components/Text';

import styles from './ConfirmModalStatusModal.module.scss';
import ReactMarkdown from 'react-markdown';

export interface IManyItemsBody {
  itemNames: { name: string; lastName: string }[];
}

export const ConfirmBody: FC<IManyItemsBody> = ({ itemNames }) => {
  const { t } = useTranslation();

  const isSingleUser = useMemo(() => itemNames.length === 1, [itemNames]);

  return (
    <div className={styles['content-avatar']}>
      {itemNames.length > 1 ? (
        <div className={styles['manyAvatarContainer']}>
          {itemNames.slice(0, 5).map((name, index) => (
            <Avatar
              style={{ zIndex: itemNames.length - index }}
              fisrtName={name.name}
              lastName={name.lastName}
              size="M"
              type="ui-tiny-bold"
              color="white"
            />
          ))}
          {itemNames.length > 5 ? (
            <Avatar
              fisrtName={`+${itemNames.length - 5}`}
              size="M"
              type="paragraph2-bold"
              color="text-50"
              backgroundColor="text-700"
            />
          ) : null}
        </div>
      ) : (
        itemNames
          .slice(0, 5)
          .map((name) => (
            <Avatar
              className={styles['singleAvatarContainer']}
              fisrtName={name.name}
              lastName={name.lastName}
              size="L"
              type="ui-main-bold"
              color="white"
            />
          ))
      )}
      <Text color="text-50" type="paragraph2">
        <ReactMarkdown>
          {isSingleUser
            ? t('modal.editModal.single.confirm.copy', {
                user: `**${itemNames[0].name}**`,
              })
            : t('modal.editModal.many.confirm.copy', {
                user: `**${itemNames.length}**`,
              })}
        </ReactMarkdown>
      </Text>
    </div>
  );
};
