import { AxiosResponse } from 'axios';
import {
  ILoginRequest,
  INewPasswordRequest,
  IRecoveryRequest,
} from '../../Interfaces/request/Auth/ILoginRequest';
import { Http } from '../../../Utils/Http';
import { ILoginResponse } from '../../Interfaces/response/Auth/ILoginResponse';

export class AuthAPI {
  public static async login(body: ILoginRequest): Promise<ILoginResponse> {
    return await Http.fetcher('/identity/v1/OAuth/login', {
      method: 'POST',
      body: JSON.stringify(body),
    });
  }

  public static async recovery(body: IRecoveryRequest): Promise<AxiosResponse> {
    const axios = await Http.axios();
    return axios.put('/identity/v1/User/ForgetPassword', body);
  }

  public static async newpassword(
    body: INewPasswordRequest
  ): Promise<AxiosResponse> {
    const axios = await Http.axios();
    return axios.put('/identity/v1/User/CreatePassword', body);
  }
}
