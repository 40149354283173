export const commonEN = {
  noResults: 'We could not found what you are searching :(',
  waiting: "You didn't searching anything yet",
  email: 'E-mail',

  delete: 'Delete',
  status: 'Status',

  remove: 'Remove',

  save: 'Save',
  cancel: 'Cancel',

  close: 'Close',

  details: 'Details',
  inventory: 'Inventory',
  financial: 'Financial',

  edit: 'Edit',
  create: 'Create',

  add: 'Add',

  information: 'Information',
  complementDateHour: 'at',
  toastMessages: {
    createdUser: 'Created user!',
    addedUser: 'User added!',
    addedUserErr:
      'An error ocurred while adding an user, try again in some minutes.',
    updatedUser: 'Updated user!',
    updatedPreferenceUser: 'Updated preferences!',
    changePassword: 'Updated password!',
    editUserGroup: 'User Group updated!',
    deletedUserGroup: 'User group deleted!',
    deleteGroupProduct: 'Removed produtc group!',
    deleteSubGroupProduct: 'Removed produtc sub-group!',
  },
  activate: 'Active',
  inactivate: 'Inactive',
  qualityControl: 'Quality Control',
  blocked: 'Blocked',
  company: 'company',
  theCompany: 'the company',
  companys: 'companys',
  theCompanys: 'the companys',
  companyGroups: 'company groups',
  theCompanyGroup: 'the company groups',
  theCompanyGroups: 'the company groups',
  userGroups: 'user groups',
  userGroup: 'User Groups',
  theUserGroups: 'the user groups',
  theUsersGroups: 'the user groups',
  group: 'group',
  groups: 'groups',
  theGroup: 'the group',
  theGroups: 'the groups',
  subGroup: 'sub-group',
  subGroups: 'sub-groups',
  theSubGroup: 'the sub-group',
  theSubGroups: 'the sub-groups',
  product: 'product',
  products: 'products',
  user: 'user',
  users: 'users',
  theProduct: 'the product',
  theProducts: 'the products',
  ofProduct: 'of product',

  lot: 'lot',
  deleteLot: 'Delete lot',
  lots: 'lots',
  theLot: 'the lot',
  theLots: 'the lots',

  stockLocation: 'stock location',
  stockLocationsQuestionModalStatus: 'locations',
  deleteStockLocationDetail: 'Delete stock location',
  deleteStockLocationList: 'Are you sure you want to delete the stock location',
  stockLocations: 'stock locations',
  thestockLocation: 'the stock location',
  thestockLocations: 'the stock locations',

  responsible: 'Responsible',
  changeResponsible: 'Change responsible of ',

  for: 'for',

  dateBreakApart: 'at',

  copy: 'Copy',
  copied: 'Copied!',

  code: 'code',
  name: 'name',

  item: 'Item',
  items: 'Items',

  finalDescription: 'This action is permanent and cannot be undone!',

  permissionType: {
    inherited: 'Inherit from:',
    allow: 'Allow',
    deny: 'Deny',
  },

  error: {
    requiredField: 'This field is required',
    emailAlreadyRegistred: 'Email is already being used',
    invalidEmail: 'Invalid e-mail',
    userGroupNameRegistred: 'Usergroup name is already in use',
  },
};

export const commonPT = {
  noResults: 'Não conseguimos encontrar o que você está procurando :(',
  waiting: 'Você ainda não fez uma pesquisa.',
  email: 'E-mail',

  delete: 'Excluir',
  status: 'Status',

  remove: 'Remover',

  save: 'Salvar',
  cancel: 'Cancelar',

  close: 'Fechar',

  details: 'Detalhes',
  edit: 'Editar',
  create: 'Cadastrar',
  inventory: 'Estoque',
  financial: 'Financeiro',

  add: 'Adicionar',

  information: 'Informações',
  complementDateHour: 'às',
  toastMessages: {
    createdUser: 'Usuário criado!',
    addedUser: 'Usuário adicionado!',
    addedUserErr:
      'Ocorreu um erro ao adicionar o usuário, tente novamente em alguns minutos.',
    updatedUser: 'Usuário atualizado!',
    updatedPreferenceUser: 'Preferências atualizadas!',
    changePassword: 'Senha atualizada!',
    editUserGroup: 'Grupo de usuários atualizado!',
    deletedUserGroup: 'Grupo de usuário deletado!',
    deleteGroupProduct: 'Grupo de produto deletado!',
    deleteSubGroupProduct: 'Subgrupo de produto deletado!',
  },
  activate: 'Ativo',
  inactivate: 'Inativar',
  qualityControl: 'Controle de qualidade',
  blocked: 'Bloqueado',
  company: 'empresa',
  theCompany: 'a empresa',
  companys: 'empresas',
  theCompanys: 'as empresas',
  companyGroups: 'grupos de empresas',
  theCompanyGroup: 'o grupo de empresas',
  theCompanyGroups: 'os grupos de empresas',
  userGroups: 'grupos de usuários',
  userGroup: 'Grupo de Usuários',
  theUserGroups: 'o grupo de usuários',
  theUsersGroups: 'os grupos de usuários',
  group: 'grupo',
  groups: 'grupos',
  theGroup: 'o grupo',
  theGroups: 'os grupos',
  subGroup: 'subgrupo',
  subGroups: 'subgrupos',
  theSubGroup: 'o subgrupo',
  theSubGroups: 'os subgrupos',
  product: 'produto',
  products: 'produtos',
  user: 'usuário',
  users: 'usuários',

  lot: 'lote',
  deleteLot: 'Excluir lote',
  lots: 'lotes',
  theLot: 'o lote',
  theLots: 'os lotes',

  theProduct: 'o produto',
  theProducts: 'os produtos',
  ofProduct: 'do produto',

  stockLocation: 'local de estoque',
  stockLocations: 'locais de estoque',
  stockLocationsQuestionModalStatus: 'locais',
  thestockLocation: 'o local de estoque',
  thestockLocations: 'os locais de estoque',
  deleteStockLocationList:
    'Você tem certeza que deseja excluir o local de estoque',
  deleteStockLocationDetail: 'Excluir o local de estoque',

  responsible: 'Responsável',
  changeResponsible: 'Alterar responsável de ',

  for: 'para',

  dateBreakApart: 'às',

  copy: 'Copiar',
  copied: 'Copiado!',

  code: 'código',
  name: 'nome',

  item: 'Item',
  items: 'Itens',

  finalDescription: 'Essa ação é permanente e não poderá ser desfeita!',

  permissionType: {
    inherited: 'Herdado de:',
    allow: 'Permitir',
    deny: 'Negar',
  },
  error: {
    requiredField: 'Este campo é obrigatório',
    emailAlreadyRegistred: 'E-mail ja esta sendo utilizado',
    invalidEmail: 'Invalid e-mail',
    userGroupNameRegistred: 'Nome do grupo de usuário já está sendo utilizado',
  },
};
