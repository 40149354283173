import React from 'react';

import { Text, Icon } from '../../../../Components';
import { Button as AntdButton } from 'antd';

import { useSelector } from 'react-redux';
import { getMeUser } from '../../../../Store/User/User.selector';
import { Themes } from '../../../../Utils/ThemeUtils';
import Spinner from '../../../../Components/Spinner';

import styles from './Status.module.scss';
import './Status.override.scss';

export interface IStatus {
  typePurchase: number;
  darkmode?: boolean;
  children: string;
  rightIcon?: boolean;
  disabled?: boolean;
  onClick?: (status: any) => void;

  loading?: boolean;
}

export const Status: React.FC<IStatus> = ({
  typePurchase,
  darkmode,
  children,
  disabled,
  rightIcon,
  onClick,
  loading,
}) => {
  const user = useSelector(getMeUser);

  let statusColor;

  switch (typePurchase) {
    case 0:
      statusColor = 'waitingApproval';
      break;
    case 1:
      statusColor = 'disapproved';
      break;
    case 2:
      statusColor = 'approved';
      break;
    case 3:
      statusColor = 'confirmedProvider';
      break;
    case 4:
      statusColor = 'partialReceived';
      break;
    case 5:
      statusColor = 'totalReceived';
      break;
    case 6:
      statusColor = 'drafit';
      break;
    default:
      statusColor = 'waitingApproval';
  }

  return (
    <AntdButton
      className={`${styles[`${statusColor}-status`]} ${
        rightIcon ? styles['status-with-rightIcon'] : ''
      } ${
        user?.preferencias.temaUsuario !== Themes.lightMode ? 'darkmode' : null
      }`}
      disabled={disabled}
      onClick={onClick}
    >
      <Text
        type="ui-tiny-bold"
        children={children}
        style={{ whiteSpace: 'nowrap' }}
      />
      {!loading ? (
        rightIcon && (
          <Icon
            icon="chevron-down"
            size="L"
            color={
              typePurchase === 0
                ? 'status-success-base'
                : typePurchase === 1
                ? 'status-danger-base'
                : typePurchase === 2
                ? 'status-warning-base'
                : typePurchase === 3
                ? 'status-primary-base'
                : typePurchase === 4
                ? 'status-default-base'
                : 'status-disabled-base'
            }
          />
        )
      ) : (
        <Spinner className={styles['button-spinner']} />
      )}
    </AntdButton>
  );
};
