import React, { ReactNode, useEffect, useState } from 'react';

import NProgress from 'nprogress';

export interface IPageLoaging {
  loading?: boolean;
  children: ReactNode;
}

export const PageLoading: React.FC<IPageLoaging> = ({ loading, children }) => {
  const [fisrtLoading, setFirstLoading] = useState(true);
  useEffect(() => {
    if (!fisrtLoading) return;
    loading && NProgress.configure({ minimum: 0.65 });
    loading && NProgress.start();
  }, [loading, fisrtLoading]);

  useEffect(() => {
    if (!fisrtLoading) return;
    if (!loading) {
      NProgress.done();
      setFirstLoading(false);
    }
  }, [loading, fisrtLoading]);

  return <>{(!loading || !fisrtLoading) && children}</>;
};
