import React, { FC } from 'react';

import { Route, Switch, Redirect } from 'react-router-dom';

const Login = React.lazy(() => import('../../Pages/Login'));
const NewPassword = React.lazy(
  () => import('../../Pages/ForgotPassword/NewPassword')
);
const ForgotPassword = React.lazy(
  () => import('../../Pages/ForgotPassword/Request')
);
const ForgotPasswordSuccess = React.lazy(
  () => import('../../Pages/ForgotPassword/Success')
);

const UnauthenticatedApp: FC = () => {
  return (
    <>
      <React.Suspense fallback={<span>Loading...</span>}>
        <Switch>
          <Route path="/login" component={Login} />

          <Route
            path="/forgot-password/success"
            component={ForgotPasswordSuccess}
          />
          <Route path="/forgot-password" component={ForgotPassword} />
          <Route
            path="/new-password/:mail/:externalId/:code"
            component={NewPassword}
          />

          <Redirect to="/login" />
        </Switch>
      </React.Suspense>
    </>
  );
};

export default UnauthenticatedApp;
