export const userGroupsEN = {
    list: {
        name: 'Name',
        status: 'Status',
        users: 'Users',

        statusTypes: {
            active: 'Active',
            inactive: 'Inactive',
        },
    },

    editGroups: {
        modal: {
            user: 'User',
            add: 'Add',
            users: 'users',
            participant: 'participant',
            addBtn: 'Add to group',
        },
        errors: {
            requiredName: 'Name is required',
            requiredDescription: 'Description is required',
            maxDescriptions: 'Description can only have 200 characters',
        },
    },
}

export const userGroupsPT = {
    list: {
        name: 'Nome',
        status: 'Status',
        users: 'Usuários',

        statusTypes: {
            active: 'Ativo',
            inactive: 'Inativo',
        },
    },
    editGroups: {
        modal: {
            user: 'Usuário',
            add: 'Adicionar',
            users: 'participantes',
            participant: 'participante',
            addBtn: 'Adicionar ao grupo',
        },
        errors: {
            requiredName: 'Nome e obrigatorio',
            requiredDescription: 'Descricao e obrigatorio',
            maxDescriptions: 'Descricao so pode ter no maximo 200 caracteres',
        },
    },
}
