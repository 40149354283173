import React, { useState, useEffect, FC, useCallback } from 'react';
import { Row, Col } from 'antd';
import { FooterButton } from '../Components/FooterButton';
import { useFormikContext } from 'formik';
import { Card, Dropdown, i18n, TextInput } from '../../../Components';
import { patternLabelStyle } from '../../../Components/Inputs/TextInput';
import { TextInputWithMask } from '../../../Components/Inputs/TextInputWithMask';
import { TextInputPhoneMask } from '../../../Components/Inputs/TextInputPhoneMask';
import { useTranslation } from 'react-i18next';
import { useThereIsEmailTypes } from '../../../Hooks/Identity/EmailHooks';
import { useUserPreferencesType } from '../../../Hooks/Accountmanager/UserHooks';
import { getDatePlaceholder } from '../../../Utils/DateUtils';
import { RadioGroup } from '../../../Components/StateInputs/RadioButton/RadioGroup';
import { RadioButton } from '../../../Components/StateInputs/RadioButton';
import { IListUserResponse } from '../../../Data/Interfaces/response/User/IGetUserReponse';

import styles from './CompanyResponsibleUserCard.module.scss';

interface ICompanyResponsibleUser {
  editForm?: boolean;
  radioCompanyGroup: boolean;
  radioUser: boolean;
  onChangeRadioUser: (value: boolean) => void;
  isLoading?: boolean;

  users?: IListUserResponse | null;
  onScrollEndUserDropdown: () => void;
}

export const CompanyResponsibleUserCard: FC<ICompanyResponsibleUser> = ({
  editForm,
  radioCompanyGroup,
  radioUser,
  onChangeRadioUser,
  isLoading,
  users,
  onScrollEndUserDropdown,
}) => {
  const form: any = useFormikContext();

  const [buttonDisabled, setButtonDisabled] = useState(editForm);

  const { data: userPreference } = useUserPreferencesType();

  useEffect(() => {
    onChangeRadioUser(true);
  }, [radioCompanyGroup, onChangeRadioUser]);

  useEffect(() => {
    if (editForm) return;
    form?.setFieldValue('usuario.nome', undefined);
    form?.setFieldValue('usuario.sobrenome', undefined);
    form?.setFieldValue('usuario.email', undefined);
    form?.setFieldValue('usuario.dataNascimento', undefined);
    form?.setFieldValue('usuario.cpf', undefined);
    form?.setFieldValue('usuario.ativo', undefined);
    form?.setFieldValue('usuario.celular.tipoId', undefined);
    form?.setFieldValue('usuario.celular.numero', undefined);
    form?.setFieldValue('usuarioResponsavelExternalId', undefined);
    form?.setFieldValue('usuario', undefined);
    if (radioUser) {
      form?.setFieldValue('usuario.ativo', true);
      form?.setFieldValue('usuario.celular.tipoId', 1);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [radioUser]);

  useEffect(() => {
    if (!!form.values.conglomeradoExternalId) onChangeRadioUser(true);
  }, [form?.values?.conglomeradoExternalId, onChangeRadioUser]);

  return (
    <Card
      title={i18n.t('adminBootys.createCompany.responsibleUser')}
      withoutCollapse={editForm}
    >
      {!editForm && (
        <RadioGroup
          className={styles['radio-button']}
          valueChecked={radioUser ? 1 : 2}
        >
          <RadioButton
            value={1}
            label={i18n.t(
              'adminBootys.createCompany.responsibleUserCard.newUser'
            )}
            onChange={() => onChangeRadioUser(true)}
            disabled={buttonDisabled}
          />
          <RadioButton
            value={2}
            label={i18n.t(
              'adminBootys.createCompany.responsibleUserCard.existingUser'
            )}
            onChange={() => onChangeRadioUser(false)}
            disabled={buttonDisabled || !form?.values.conglomeradoExternalId}
            tooltip={
              !buttonDisabled && !form?.values.conglomeradoExternalId
                ? i18n.t(
                    'adminBootys.createCompany.responsibleUserCard.radioTooltip'
                  )
                : ''
            }
          />
        </RadioGroup>
      )}

      {radioUser && !editForm ? (
        <>
          <Row>
            <Col flex="27%">
              <EmailField buttonDisabled={!!buttonDisabled} />
            </Col>
          </Row>
          <Row gutter={[10, 0]}>
            <Col span={12}>
              <TextInput
                name="usuario.nome"
                label={[
                  {
                    children: i18n.t(
                      'adminBootys.createCompany.responsibleUserCard.name'
                    ),
                    ...patternLabelStyle,
                  },
                ]}
                placeHolder={i18n.t(
                  'adminBootys.createCompany.responsibleUserCard.namePlaceholder'
                )}
                disabled={buttonDisabled}
                required
              />
            </Col>
            <Col span={12}>
              <TextInput
                name="usuario.sobrenome"
                label={[
                  {
                    children: i18n.t(
                      'adminBootys.createCompany.responsibleUserCard.lastName'
                    ),
                    ...patternLabelStyle,
                  },
                ]}
                placeHolder={i18n.t(
                  'adminBootys.createCompany.responsibleUserCard.lastNamePlaceholder'
                )}
                disabled={buttonDisabled}
                required
              />
            </Col>
          </Row>
          <Row gutter={[10, 0]}>
            <Col span={8}>
              <TextInputWithMask
                name="usuario.cpf"
                withoutMarginBottom={true}
                label={[
                  {
                    children: i18n.t(
                      'adminBootys.createCompany.responsibleUserCard.cpf'
                    ),
                    ...patternLabelStyle,
                  },
                ]}
                placeHolder={i18n.t(
                  'adminBootys.createCompany.responsibleUserCard.cpfPlaceholder'
                )}
                mask="cpfMask"
                disabled={buttonDisabled}
              />
            </Col>
            <Col span={8}>
              <TextInputWithMask
                name="usuario.dataNascimento"
                withoutMarginBottom={true}
                label={[
                  {
                    children: i18n.t(
                      'adminBootys.createCompany.responsibleUserCard.bithDate'
                    ),
                    ...patternLabelStyle,
                  },
                ]}
                placeHolder={
                  userPreference &&
                  getDatePlaceholder(
                    userPreference?.preferencias?.idioma,
                    userPreference?.preferencias?.padraoData
                  )
                }
                mask="dataMask"
                disabled={buttonDisabled}
              />
            </Col>
            <Col span={8}>
              <TextInputPhoneMask
                name="usuario.celular.numero"
                withoutMarginBottom={true}
                label={[
                  {
                    children: i18n.t(
                      'adminBootys.createCompany.responsibleUserCard.cell'
                    ),
                    ...patternLabelStyle,
                  },
                ]}
                placeHolder={i18n.t(
                  'adminBootys.createCompany.responsibleUserCard.cellPlaceholder'
                )}
                disabled={buttonDisabled}
              />
            </Col>
          </Row>
        </>
      ) : (
        <Row>
          <Col flex="32%">
            <Dropdown
              name="usuarioResponsavelExternalId"
              items={users?.data?.map((x) => ({
                id: x.externalId,
                avatar: {
                  email: x.email,
                  name: x.nome,
                  lastName: x.sobrenome,
                },
              }))}
              placeHolder={i18n.t(
                'adminBootys.createCompany.responsibleUserCard.userPlaceholder'
              )}
              label={[
                {
                  children: i18n.t(
                    'adminBootys.createCompany.responsibleUserCard.user'
                  ),
                  ...patternLabelStyle,
                },
              ]}
              disabled={buttonDisabled}
              required
              withoutMarginBottom
              onScrollEnd={onScrollEndUserDropdown}
            />
          </Col>
        </Row>
      )}
      {editForm && form && (
        <FooterButton
          disabled={!!buttonDisabled}
          editLabel={i18n.t(
            'adminBootys.createCompany.responsibleUserCard.edit'
          )}
          onChange={(value) => setButtonDisabled(value)}
          loading={isLoading}
        />
      )}
    </Card>
  );
};

interface IEmailField {
  buttonDisabled: boolean;
}

const EmailField: FC<IEmailField> = ({ buttonDisabled }) => {
  const [email, setEmail] = useState('');
  const { t } = useTranslation();

  const form: any = useFormikContext();

  const onEmailTypeError = useCallback(() => {
    form.setErrors({
      ...form.errors,
      usuario: {
        email: t('common.error.emailAlreadyRegistred'),
      },
    });
  }, [form, t]);

  const { mutateAsync } = useThereIsEmailTypes({
    onError: onEmailTypeError,
  });

  useEffect(() => {
    mutateAsync(email);
  }, [email, mutateAsync]);

  return (
    <TextInput
      name="usuario.email"
      label={[
        {
          children: i18n.t(
            'adminBootys.createCompany.responsibleUserCard.email'
          ),
          ...patternLabelStyle,
        },
      ]}
      placeHolder={i18n.t(
        'adminBootys.createCompany.responsibleUserCard.emailPlaceholder'
      )}
      disabled={buttonDisabled}
      required
      onBlur={(x) => setEmail(x)}
    />
  );
};
